import React from 'react';
import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconCheckmark(props: IconType) {
    const {
        width = '20',
        height = '20',
        fill = '#4a4a4a',
        viewBox = '0 0 20 20',
        title = 'IconCheckmark',
        ...rest
    } = props
    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <path
                    d="m6.28019324 17.1690821c.32206119 0 .61191626-.1288245.86956521-.3864734l12.01082095-12.19143476c.4684111-.47545491.4764395-1.23637639.0181646-1.72160872-.4390398-.46486562-1.1717999-.48580162-1.6366655-.04676187-.0091321.00862481-.0181233.01739766-.02697.02631508l-11.23491526 11.32479457-3.67149759-3.6714975c-.48024761-.4802476-1.25888282-.4802476-1.73913043 0s-.48024761 1.2588828 0 1.7391304l4.5410628 4.5410628c.25764895.2576489.54750403.3864734.86956522.3864734z"
                />
            )}
        />

    );
}

export default IconCheckmark;