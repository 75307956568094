import React from 'react';
import Sort from "./Sort";
import useCurrency from "@Currency/hooks/useCurrency";
import useValidCurrenciesList from "@Currency/hooks/useValidCurrenciesList";
import {useChangeCurrency} from "@Currency/hooks/useChangeCurrency";

function CurrencySelect() {
    const changeCurrency = useChangeCurrency()
    const selectedCurrency = useCurrency()
    const currencyOptions = useValidCurrenciesList()
    return (
        <Sort
            onSelect={changeCurrency}
            selectedLabel={`${selectedCurrency?.val} ${selectedCurrency?.sign}`}
            selected={selectedCurrency}
            sortingOptions={currencyOptions}
            value="val"
            label="val"
        />
    );
}

export default CurrencySelect;