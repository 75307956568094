import {SearchResultsRtkQueryResponseType} from "@SearchResults/api/types/SearchResultsRtkQueryResponse.type";
import {RouteTransferTypeValuesType} from "@Route/features/transportType/types/routeTransferType.type";
import filterDuplicates from "@Array/filterDuplicates";
import {StationType} from "@SearchResults/types/station/Station.type";

type GetUniqueStationsProps = {
    searchData: SearchResultsRtkQueryResponseType,
    tab: RouteTransferTypeValuesType
    stations: 'departureStation' | 'destinationStation'
}
export default function getUniqueStationsForActiveTab(props: GetUniqueStationsProps): StationType[] {
    const stations = props.searchData.result.singleRoutes[props.tab].map((route) => route.travelInfo[props.stations]);

    return filterDuplicates(stations, 'name');
}