import React from 'react';
import IconChevronDown from "@Icons/IconChevronDown";
import Column from "@Containers/components/Column";

interface FaqCardProps {
    index?: number,
    activeIndex: number,
    setActiveIndex: (value: number) => void,
}

function FaqCard({index, activeIndex, setActiveIndex}: FaqCardProps) {
    const {question, answer}: {
        question: string,
        answer: string
        // @ts-ignore this is how the FaqPortal works, data is actually in twig elements not displayed to user
    } = document.getElementsByClassName('gb__faq-question')[index].dataset;

    const displayAnswer = index === activeIndex;

    const iconProps = {
        width: "20",
        height: "20",
        viewBox: "0 0 16 16",
        ...(displayAnswer ? {fill: '#7ed321'} : {style: {transform: 'rotate(-90deg)'}}),
    };

    return (
        <div
            className={['gb--card', displayAnswer ? 'gb--card--active' : ''].join(' ').trim()}
            onClick={() => {
                // clicked on the same card
                if (index === activeIndex) {
                    setActiveIndex(-1)
                } else {
                    setActiveIndex(index)
                }
            }}
        >
            <IconChevronDown {...iconProps} />
            <Column>
                <span className="gb--emphasize gb--emphasize-default">
                    {question}
                </span>
                {displayAnswer && (
                    <div className="gb__faq-answer" dangerouslySetInnerHTML={{__html: answer}} />
                )}
            </Column>
        </div>
    );
}

export default FaqCard;