import {useCountTicketsQuery} from "@MyProfile/API/myProfileApi";

export default function useCountArchivedTickets() {
    const {
        data,
        isUninitialized,
        isLoading,
    } = useCountTicketsQuery({showArchived: true})

    const archivedTicketsCountLoading = isUninitialized || isLoading

    return {
        archivedTicketsCount: data?.count,
        archivedTicketsCountLoading,
    }
}