import React from 'react';
import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "@/types/Icon.type";

interface IconTicketCardProps extends IconType {
    active?: boolean
}

const IconTicketCard = (props: IconTicketCardProps) => {
    const {
        width = '35',
        height = '35',
        fill = '#7ed321',
        viewBox = '0 0 35 35',
        title = 'IconTicketCard',
        active,
        ...rest
    } = props
    const activeFill = active ? fill : '#b5b7d1';
    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none" transform="matrix(.57357644 -.81915204 .81915204 .57357644 2.38435 23.896365)">
                    <path
                        d="m17.0831973 2.51446814v-.92499867c-.3931244 0-.693749-.30062457-.693749-.69374901h-13.18123115c-1.27187318 0-2.31249669 1.04062351-2.31249669 2.31249669v3.23749537c1.66499762 0 3.0062457 1.34124809 3.0062457 3.00624571 0 1.66499757-1.34124808 3.00624567-3.0062457 3.00624567v2.3124967c0 1.2718732 1.04062351 2.3124967 2.31249669 2.3124967h13.18123115c0-.3931244.3006246-.693749.693749-.693749v-.9249987c-.3931244 0-.693749-.3006245-.693749-.693749 0-.3931244.3006246-.693749.693749-.693749v-.9249987c-.3931244 0-.693749-.3006245-.693749-.693749 0-.3931244.3006246-.693749.693749-.693749v-.9249987c-.3931244 0-.693749-.3006245-.693749-.693749 0-.3931244.3006246-.69374897.693749-.69374897v-.92499868c-.3931244 0-.693749-.30062457-.693749-.69374901s.3006246-.69374901.693749-.69374901v-.92499868c-.3931244 0-.693749-.30062457-.693749-.693749 0-.39312444.3006246-.69374901.693749-.69374901v-.92499868c-.3931244 0-.693749-.30062457-.693749-.69374901s.3006246-.69374901.693749-.69374901z"
                        fill="#fff"
                    />
                    <g fill="#e6e6ef" transform="translate(5.781242 4.624993)">
                        <path
                            d="m7.63123909 1.38749802h-6.93749008c-.39312444 0-.69374901-.30062457-.69374901-.69374901s.30062457-.69374901.69374901-.69374901h6.93749008c.39312444 0 .69374901.30062457.69374901.69374901s-.30062457.69374901-.69374901.69374901z"
                        />
                        <path
                            d="m7.63123909 6.0124914h-6.93749008c-.39312444 0-.69374901-.30062457-.69374901-.693749 0-.39312444.30062457-.69374901.69374901-.69374901h6.93749008c.39312444 0 .69374901.30062457.69374901.69374901 0 .39312443-.30062457.693749-.69374901.693749z"
                        />
                        <path
                            d="m5.3187424 8.3249881h-4.62499339c-.39312444 0-.69374901-.30062457-.69374901-.69374901s.30062457-.69374901.69374901-.69374901h4.62499339c.39312443 0 .693749.30062457.693749.69374901s-.30062457.69374901-.693749.69374901z"
                        />
                        <path
                            d="m7.63123909 8.3249881c-.04624993 0-.09249987 0-.1387498-.02312497-.04624994 0-.09249987-.02312497-.1387498-.04624993-.04624994-.02312497-.0693749-.04624994-.11562484-.0693749-.04624993-.02312497-.0693749-.04624994-.11562483-.09249987-.11562484-.09249987-.18499974-.27749961-.18499974-.46249934 0-.18499974.0693749-.36999947.2081247-.48562431.25437464-.25437463.71687398-.25437463.97124862 0 .1387498.13874981.2081247.30062457.2081247.48562431 0 .18499973-.0693749.36999947-.2081247.48562431-.11562484.1387498-.30062458.2081247-.48562431.2081247z"
                        />
                        <path
                            d="m3.0062457 3.69999471h-2.31249669c-.39312444 0-.69374901-.30062457-.69374901-.69374901s.30062457-.69374901.69374901-.69374901h2.31249669c.39312444 0 .69374901.30062457.69374901.69374901s-.30062457.69374901-.69374901.69374901z"
                        />
                        <path
                            d="m7.63123909 3.69999471h-2.31249669c-.39312444 0-.69374901-.30062457-.69374901-.69374901s.30062457-.69374901.69374901-.69374901h2.31249669c.39312444 0 .69374901.30062457.69374901.69374901s-.30062457.69374901-.69374901.69374901z"
                        />
                    </g>
                    <path
                        d="m26.1312126 3.0062457c0-1.27187318-1.0406235-2.31249669-2.3124967-2.31249669h-6.243741c0 .39312444-.3006246.69374901-.693749.69374901v.92499867c.3931244 0 .693749.30062457.693749.69374901s-.3006246.69374901-.693749.69374901v.92499868c.3931244 0 .693749.30062457.693749.69374901 0 .39312443-.3006246.693749-.693749.693749v.92499868c.3931244 0 .693749.30062457.693749.69374901s-.3006246.69374901-.693749.69374901v.92499868c.3931244 0 .693749.30062457.693749.693749 0 .39312442-.3006246.69374902-.693749.69374902v.9249987c.3931244 0 .693749.3006245.693749.693749 0 .3931244-.3006246.693749-.693749.693749v.9249987c.3931244 0 .693749.3006245.693749.693749 0 .3931244-.3006246.693749-.693749.693749v.9249987c.3931244 0 .693749.3006245.693749.693749h6.243741c1.2718732 0 2.3124967-1.0406235 2.3124967-2.3124967z"
                        fill={activeFill}
                    />
                    <g fill="#070c63">
                        <path
                            d="m23.8187159 17.5749749h-4.6249933c-.3931245 0-.693749-.3006246-.693749-.693749 0-.3931245.3006245-.693749.693749-.693749h4.6249933c.9018738 0 1.6187477-.716874 1.6187477-1.6187477v-11.5624835c0-.90187371-.7168739-1.61874768-1.6187477-1.61874768h-4.6249933c-.3931245 0-.693749-.30062457-.693749-.69374901s.3006245-.69374901.693749-.69374901h4.6249933c1.6649977 0 3.0062457 1.34124808 3.0062457 3.0062457v11.5624835c0 1.6649976-1.341248 3.0062457-3.0062457 3.0062457z"
                        />
                        <path
                            d="m14.5687292 16.1874769h-11.5624835c-.90187371 0-1.61874768-.716874-1.61874768-1.6187477v-1.6881226c1.71124755-.3237496 3.0062457-1.8268724 3.0062457-3.63061982 0-1.80374743-1.29499815-3.30687028-3.0062457-3.63061981v-2.61312127c0-.90187371.71687397-1.61874768 1.61874768-1.61874768h11.5624835c.3931244 0 .693749-.30062457.693749-.69374901s-.3006246-.69374901-.693749-.69374901h-11.5624835c-1.66499762 0-3.0062457 1.34124808-3.0062457 3.0062457v3.23749537c0 .39312444.30062457.69374901.69374901.69374901 1.27187318 0 2.31249669 1.04062351 2.31249669 2.3124967 0 1.27187322-1.04062351 2.31249672-2.31249669 2.31249672-.39312444 0-.69374901.3006245-.69374901.693749v2.3124967c0 1.6649976 1.34124808 3.0062457 3.0062457 3.0062457h11.5624835c.3931244 0 .693749-.3006246.693749-.693749 0-.3931245-.3006246-.693749-.693749-.693749z"
                        />
                    </g>
                </g>
            )}
        />
    );
}

export default IconTicketCard;
