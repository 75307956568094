import {RotationOrientationType} from "@Features/icons/types/RotationOrientationType";

export default function setOrientation(orientation: RotationOrientationType) {
    const rotate = {
        [`${orientation === "up"}`]: "-180deg",
        [`${orientation === "down"}`]: "0deg",
        [`${orientation === "right"}`]: "-90deg",
        [`${orientation === "left"}`]: "90deg",
    }

    return rotate.true ? {transform: `rotate(${rotate.true})`} : {};
}