import {setCheckOrderData, setCheckOrderDataFromUser} from "@CheckOrder/reducers/checkOrderSlice";
import useUser from "@User/hooks/useUser";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import {useNavigate} from "react-router-dom";

export default function useRedirectToCheckOrder() {
    const dispatch = useAppDispatch()
    const user = useUser()
    const navigate = useNavigate()
    // const {prepareIntermodalRoute} = usePrepareIntermodalRoute()

    // TODO define checkOrder-Response-Type
    const redirectToCheckOrder = (response: any, isSuggestion: boolean) => {
        const {ticketCode} = response.checkOrder.ticket
        const checkOrderUrl = `/check-order/${ticketCode}`
        const modifiedResponse = response

        if (response.checkOrder.ticket.isIntermodal) {
            // modifiedResponse.checkOrder.purchasedRoute.singleRoute = prepareIntermodalRoute({
            //     baseRoutes: response.checkOrder.purchasedRoute.singleRoutes,
            //     id: "",
            //     isIntermodalRoute: true,
            // })
        }

        dispatch(setCheckOrderData(modifiedResponse.checkOrder))

        if (user?.email) {
            dispatch(setCheckOrderDataFromUser(user))
        }

        if (isSuggestion) {
            window.open(checkOrderUrl, '_blank', 'noreferrer')
        } else {
            navigate(checkOrderUrl)
        }
    }

    return {
        redirectToCheckOrder,
    }
}