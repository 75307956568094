import React from 'react';
import Translation from "@Translation/components/Translation";
import Link from "../Link";
import IconSlimArrowRight from "../Icons/IconSlimArrowRight";

interface CardMediaFullWidthProps {
    cardImagePath: string,
    cardImageAlt: string,
    cardTitle?: string,
    cardLink?: string,
    cardLinkTranslation: string,
    cardClassName?: string,
}

const CardMediaFullWidth = (props: CardMediaFullWidthProps) => {
    const {
        cardClassName,
        cardImagePath,
        cardImageAlt,
        cardTitle,
        cardLink,
        cardLinkTranslation,
    } = props
    return (
        <div className={`gb--card gb--card-full gb--card-enlarge gb--card-media ${cardClassName}`}>
            <Link href={cardLink} className="gb--emphasize gb--emphasize-invert">
                <img
                    className="gb--card__image"
                    src={cardImagePath}
                    alt={cardImageAlt}
                />
                {cardTitle && (
                    <p>
                        {cardTitle}
                    </p>
                )}
            </Link>
            <div className="gb--card__content">
                {cardLink && (
                    <Link
                        href={cardLink}
                        className="gb--emphasize gb--emphasize-default gb--row gb--row-middle gb--row-justify"
                    >
                        <Translation translationKey={cardLinkTranslation}/>
                        <IconSlimArrowRight/>
                    </Link>
                )}
            </div>
        </div>
    );
};

export default CardMediaFullWidth;