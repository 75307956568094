import CheckOrderStateType from "@CheckOrder/reducers/types/CheckOrderState.type";
import {emptyPhoneCountryObject} from "@Features/input/phoneField/constants/emptyPhoneCountryObject";
import {gdprConsentPreferences} from "@CheckOrder/models/gdprConsentPreferences";

export const checkOrderInitialState: CheckOrderStateType = {
    passengers: [],
    resolvedPassengers: [],
    isLoading: false,
    isCheckOrder: false,
    errorOccurred: false,
    checkOrderRouteUrl: window.location.host + window.location.pathname + window.location.search,
    userConfirmed: false,
    ticketExpired: false,
    phoneNumber: '',
    email: '',
    password: '',
    travel_document: 'id_card',
    phoneCountryObject: emptyPhoneCountryObject,
    // nationalityObject,
    phoneCountrySelectActive: false,
    // Assume true as not all users will have an account
    passwordValidated: true,
    phoneValidated: false,
    emailValidated: false,
    gdprConsentPreferences,
    submitTimestamp: null,
    activeTicket: null,
    ticket: null,
    purchasedRoute: null,
    user: null,
    passengerDataUpdating: false,
    tokens: null,
    passengersResolved: false,
    stripeClientSecret: null,
    stripePublicApiKey: null,
    stripeEnabled: null,
    tripReviewAccepted: true,
    paymentPendingLoader: false,
    ancillaries: null,
    selectedServices: {
        isBuyingPremiumSupport: false,
        isBuyingSMSNotification: false,
        isBuyingTier1FlexibleCancellation: false,
    },
    checkOrderModalsVisible: true,
}