import React from 'react';
import useIsMobile from "@Hooks/selector/useIsMobile";
import SortOptionItem from "./SortOptionItem";

interface SortingOptionsProps {
    onSelection: (any?) => any,
    sortingOptions: any[],
    value?: string,
    label?: string,
    selected?: string,
}

function SortingOptions(props: SortingOptionsProps) {
    const isMobile = useIsMobile()
    const {
        sortingOptions,
        value = "value",
        label = "label",
        onSelection,
        selected,
    } = props

    const setClassName = () => {
        if (isMobile) {
            return "gb--sorting-list"
        }

        return "gb--popover"
    }

    return (
        <ul className={setClassName()}>
            {sortingOptions.map((option) => (
                <SortOptionItem
                    key={option[value]}
                    onClick={onSelection(option)}
                    isSelected={option[value] === selected}
                    label={option[label]}
                />
            ))}
        </ul>
    )
}

export default SortingOptions;