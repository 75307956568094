import React from 'react';
import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "@/types/Icon.type";

const IconMoonStars = (props: IconType) => {
    const {
        width = '20',
        height = '20',
        fill = '#fff',
        viewBox = '0 0 20 20',
        title = 'IconMoonStars',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...{width, height, fill, viewBox, title, ...rest}}
            svgIconPath={(
                <g fill="none" stroke="#070c63" transform="translate(1 1)">
                    <path
                        d="m5.15362796-.47959802c1.9531721-.00863056 3.64976649.67959673 4.86792374 1.78991486 1.2609647 1.14933601 2.0127432 2.75208941 2.0127432 4.5235237 0 1.74986769-.7346258 3.33479906-1.9252088 4.48167016-1.18374484 1.140284-2.81873849 1.8469893-4.62576318 1.8469893-1.28992254 0-2.49085609-.3613993-3.50250026-.9834935-1.04979665-.6455555-1.89560202-1.57207707-2.42696936-2.66787865-.31047239-.64026541-.50909261-1.32903207-.59192983-2.03519502.3443537.57122467.80047543 1.04635729 1.32290494 1.41299174.75380759.52901267 1.67310088.85139547 2.67639764.85139547 1.25885156 0 2.39911028-.49019028 3.22420197-1.28498899.81841139-.78836368 1.32626457-1.87697827 1.32626457-3.08020425 0-.8437172-.25186496-1.62793072-.68582051-2.29124122-.45437684-.69452491-1.10764592-1.25670749-1.88036576-1.61863471l-1.99646775-.93510738z"
                        strokeLinejoin="round"
                        strokeWidth=".991667"
                    />
                    <g strokeWidth=".875">
                        <path
                            d="m4.89904957 5.83333333c.10287133 0 .18774017-.03971354.25460653-.11914062.06686637-.07942708.0925842-.17122396.0771535-.27539063l-.13887629-.8515625.58250888-.6015625c.04372031-.046875.07200993-.10026041.08486884-.16015625.0154307-.0625.01285892-.12434895-.00771535-.18554687-.02057426-.06119792-.05465039-.11197917-.10222837-.15234375-.04757799-.04036458-.10222838-.06575521-.16395118-.07617188l-.79082332-.12109375-.35490607-.76171875c-.02828961-.0625-.07072403-.11067708-.12730326-.14453125-.05400745-.03385416-.11187257-.05078125-.17359537-.05078125-.06172279 0-.11958791.01692709-.17359536.05078125-.05657923.03385417-.09901365.08203125-.12730326.14453125l-.35490608.76171875-.79082331.12109375c-.0617228.01041667-.11637319.0358073-.16395118.07617188-.04757798.04036458-.08165411.09114583-.10222838.15234375-.02057426.06119792-.02378899.12239583-.00964418.18359375.0141448.06119792.04307736.11523437.08679768.16210937l.58250888.6015625-.13887629.8515625c-.0154307.10416667.01028713.19596355.07715349.27539063s.15173521.11914062.25460653.11914062c.05657923 0 .11058668-.01432291.16202234-.04296875l.69823912-.390625.69823913.390625c.05143566.02864584.10544311.04296875.16202233.04296875z"
                        />
                        <path
                            d="m13.549698 5.83333333c.1714522 0 .3129003-.06618923.4243442-.19856771.111444-.13237847.154307-.28537326.1285892-.45898437l-.2314605-1.41927083.9708481-1.00260417c.0728672-.078125.1200166-.16710069.1414481-.26692708.0257178-.10416667.0214315-.20724827-.0128589-.30924479-.0342904-.10199653-.091084-.18663195-.1703806-.25390626-.0792967-.0672743-.1703807-.10959201-.273252-.12695312l-1.3180389-.20182292-.5915101-1.26953125c-.0471493-.10416666-.1178734-.1844618-.2121721-.24088541-.0900124-.05642361-.1864543-.08463542-.2893256-.08463542s-.1993132.02821181-.2893256.08463542c-.0942987.05642361-.1650227.13671875-.2121721.24088541l-.5915101 1.26953125-1.31803888.20182292c-.10287133.01736111-.19395532.05967882-.27325196.12695312-.07929665.06727431-.13609019.15190973-.17038064.25390626-.03429044.10199652-.03964832.20399305-.01607364.30598958s.07179561.19205729.1446628.27018229l.97084812 1.00260417-.2314605 1.41927083c-.0257178.17361111.0171453.3266059.1285892.45898437.1114439.13237848.252892.19856771.4243442.19856771.0942987 0 .1843111-.02387152.2700372-.07161458l1.1637319-.65104167 1.1637319.65104167c.0857261.04774306.1757385.07161458.2700372.07161458z"
                            fill="#fff"
                        />
                        <path
                            d="m3.45514302 14c.13716177 0 .25032023-.0529514.33947538-.1588542.08915515-.1059027.12344559-.2282986.10287132-.3671875l-.18516838-1.1354166.7766785-.8020834c.05829376-.0625.09601324-.1336805.11315846-.2135416.02057427-.0833334.01714522-.1657986-.01028713-.2473959-.02743235-.0815972-.07286719-.1493055-.13630451-.203125-.06343731-.0538194-.1363045-.0876736-.21860156-.1015625l-1.05443109-.1614583-.4732081-1.015625c-.03771948-.08333333-.09429871-.14756944-.16973769-.19270833-.07200992-.04513889-.14916342-.06770834-.23146048-.06770834s-.15945055.02256945-.23146048.06770834c-.07543897.04513889-.1320182.109375-.16973769.19270833l-.4732081 1.015625-1.05443108.1614583c-.08229706.0138889-.15516425.0477431-.21860157.1015625-.06343732.0538195-.10887215.1215278-.13630451.203125-.02743235.0815973-.03171865.1631945-.01285891.2447917s.05743649.1536458.11573024.2161458l.77667851.8020834-.18516839 1.1354166c-.02057426.1388889.01371618.2612848.10287133.3671875.08915515.1059028.2023136.1588542.33947537.1588542.07543897 0 .1474489-.0190972.21602979-.0572917l.93098549-.5208333.9309855.5208333c.06858088.0381945.14059081.0572917.21602978.0572917z"
                            fill="#fff"
                        />
                    </g>
                </g>
            )}
        />
    );
};

export default IconMoonStars;
