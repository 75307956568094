import React from 'react';

interface RoutePlaceholderProps {
    placeholderClass?: string,
}

function RoutePlaceholder({placeholderClass = "gb--route-placeholder"}: RoutePlaceholderProps) {
    return (
        <div className={placeholderClass}>
            <div className="placeholder-results-item">
                <div className="animated-background">
                    <div className="background-mask content-first-end"/>
                    <div className="background-mask content-second-line"/>
                    <div className="background-mask content-second-end"/>
                    <div className="background-mask content-third-line"/>
                    <div className="background-mask content-third-end"/>
                </div>
            </div>
        </div>
    );
}

export default RoutePlaceholder;