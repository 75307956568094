import CheckOrderErrorLabel from "@CheckOrder/components/MainSection/CheckOrderErrorLabel";
import React, {ChangeEvent, HTMLInputTypeAttribute, KeyboardEvent} from "react";
import {TextAdditionalFieldType} from "@CheckOrder/features/TicketAdditionalInfo/types/TextAdditionalField.type";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {NumberAdditionalFieldType} from "@CheckOrder/features/TicketAdditionalInfo/types/NumberAdditionalField.type";
import CheckOrderInputContainer from "@CheckOrder/components/MainSection/CheckOrderInputContainer";

type AdditionalFieldInputProps = {
    field: TextAdditionalFieldType | NumberAdditionalFieldType
    onChange: (event: ChangeEvent<HTMLInputElement>) => void,
    onKeyPress?: (event: KeyboardEvent<HTMLInputElement>) => void,
    value: string | number,
    showError: boolean,
    type: HTMLInputTypeAttribute,
    errorMessage: string,
}

function AdditionalFieldInput(props: AdditionalFieldInputProps) {
    const {
        field,
        onChange,
        value,
        showError,
        type,
        errorMessage,
        onKeyPress,
    } = props

    const {
        passengerDataUpdating,
        userConfirmed,
    } = useAppSelector((state) => state?.checkOrder)

    const {
        title,
        description,
    } = field.translations || {}

    const limit = {
        [field.type === 'text' ? "minLength" : "min"]: field.type === 'text' ? field.minLength : field.min,
        [field.type === 'text' ? "maxLength" : "max"]: field.type === 'text' ? field.maxLength : field.max,
    }

    return (
        <CheckOrderInputContainer
            label={title ?? field.label}
            key={field.key}
        >
            {!!description && (
                description
            )}
            <input
                type={type}
                name={field.label}
                value={value ?? ""}
                onChange={onChange}
                onKeyPress={onKeyPress}
                aria-describedby={field.label}
                required
                {...limit}
                disabled={passengerDataUpdating}
                pattern={field.type === "number" ? "d*" : undefined}
            />
            {userConfirmed && showError && (
                <CheckOrderErrorLabel
                    error={errorMessage}
                    isAbsolute
                />
            )}
        </CheckOrderInputContainer>
    );
}

export default AdditionalFieldInput;