import get from "lodash/get"
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import stringReplacer from "../functions/stringReplacer";

/**
 * Returns translation from messages.yaml, optionally replaces parameters
 */
export function useTranslation(key: string, variableReplacements: object = {}, exact: boolean = false): any {
    const translation = useAppSelector((state) => get(state?.page.translations, key) ?? key)
    const entries = Object.entries(variableReplacements)
    const hasEntries = entries.length > 0

    if (hasEntries) {
        return stringReplacer(translation, variableReplacements, exact)
    }

    return translation
}