import React from 'react';
import Review from "./Review";
import LatestReviewType from "@Sidebar/types/LatestReview.type";

interface ReviewsProps {
    latestReviews: LatestReviewType[],
}

const Reviews = ({latestReviews}: ReviewsProps) => (
    <>
        {latestReviews.map((review) => (
            <Review
                review={review}
                key={review?.ticketId}
            />
        ))}
    </>
);

export default Reviews;