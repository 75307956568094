import React from 'react';
import Translation from "@Translation/components/Translation";
import RatingType from "@Route/types/ratings/Rating.type";
import CompanyScoreRow from "@Route/features/company/components/CompanyScoreRow";

interface RouteBasedRatingProps {
    departure: string,
    destination: string,
    ratings: RatingType,
}

function RouteBasedRating(props: RouteBasedRatingProps) {
    const {
        departure,
        destination,
        ratings: {score, reviewCount},
    } = props;

    return (
        <div className="rating-type-section">
            <CompanyScoreRow rating={`${score}`}>
                <Translation
                    translationKey="c_profile.reviews_from_to"
                    translationVariables={{from: departure, to: destination}}
                />
            </CompanyScoreRow>
            <span className="grade">
                <Translation
                    translationKey="c_profile.based_on_departure"
                    translationVariables={{numberOfRatings: reviewCount}}
                />
            </span>
        </div>
    )
}

export default RouteBasedRating;