import formatDate from "@DateTime/functions/formatDate";

interface DatesProps {
    dates: string[],
}

const Dates = ({dates}: DatesProps) => (
    <>
        {dates.map((date, index) => (
            <span key={date}>
                {formatDate(date, "dd MMMM yyyy")}
                {dates.length - 1 !== index && `, `}
            </span>
        ))}
    </>
)

export default Dates