import {QueryStatus} from "@reduxjs/toolkit/query";
import {LocaleType} from "@/types/Locale.type";
import useLocale from "@Hooks/selector/useLocale";

interface ApiServiceResponse {
    data: any,
    isLoading: boolean,
    status: QueryStatus,
    error: any,
}

export interface ApiServiceRequestMethod {
    locale: LocaleType,
}

interface ApiServiceRequest {
    method: (method: ApiServiceRequestMethod) => ApiServiceResponse,
    params: object,
}

/**
 * Handles api service callbacks from RTK, includes common params
 */
export default function useApiServiceFetcher() {
    const locale = useLocale()

    const fetchFromApiService = ({method, params}: ApiServiceRequest) => {
        const {
            data,
            error,
            isLoading,
            status,
        } = method({
            ...params,
            locale,
        })

        return {
            data,
            error,
            isLoading,
            status,
        }
    }

    return {
        fetchFromApiService,
    }
}