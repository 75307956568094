import usePassengers from "@Hooks/selector/usePassengers";

export default function useGeneratePassengersQueryString() {
    const {
        passengersAdult,
        passengersChildren,
        passengersStudent,
        passengersPensioner,
    } = usePassengers();

    const generatePassengersQueryString = () => {
        let passengersQueryString = '';

        if (passengersAdult) {
            passengersQueryString += `?adults=${passengersAdult}`
        }

        if (passengersChildren) {
            if (!passengersAdult) {
                passengersQueryString += `?children=${passengersChildren}`
            } else {
                passengersQueryString += `&children=${passengersChildren}`
            }
        }
        if (passengersStudent) {
            if (!passengersAdult && !passengersChildren) {
                passengersQueryString += `?students=${passengersStudent}`
            } else {
                passengersQueryString += `&students=${passengersStudent}`
            }
        }
        if (passengersPensioner) {
            if (!passengersAdult && !passengersChildren && !passengersStudent) {
                passengersQueryString += `?pensioners=${passengersPensioner}`
            } else {
                passengersQueryString += `&pensioners=${passengersPensioner}`
            }
        }

        return passengersQueryString
    }

    return generatePassengersQueryString()
}