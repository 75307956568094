import React from 'react';
import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconPen(props: IconType) {
    const {
        width = '14',
        height = '14',
        fill = '#fff',
        viewBox = '0 0 14 14',
        title = 'IconPen',
        ...rest
    } = props
    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <path
                    d="m10.7492221 6.6150128 1.3148515-1.31485149c.1821782-.18217822.2930693-.44356436.2930693-.71287129s-.1108911-.53069307-.2930693-.72079208l-1.8613862-1.85346534c-.190099-.19009901-.45148514-.3009901-.72079207-.3009901s-.53069307.11089109-.71287128.3009901l-1.31485149 1.30693069zm-7.09702974 7.0970297 6.59009904-6.59009901-3.29504953-3.29504951-6.59009901 6.59009902v3.2950495zm-.76039604-3.88118812c-.10297029 0-.17425742-.07128713-.17425742-.17425743 0-.04752475.01584158-.0950495.05544554-.13465346l4.29306931-4.29306931c.03960396-.03960396.08712871-.05544554.13465346-.05544554.1029703 0 .17425743.07128713.17425743.17425742 0 .04752476-.01584158.09504951-.05544555.13465347l-4.2930693 4.29306931c-.03960396.03960396-.08712872.05544554-.13465347.05544554zm.34059406 2.86732672h-.84752475-1.01386139v-1.0138614-.8475247l.72079208-.7207921 1.86138614 1.8613861z"
                    transform="translate(0 -1)"
                />
            )}
        />

    );
}

export default IconPen;
