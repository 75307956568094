import RouteType from "@Route/types/Route.type";

export default class CheckOrderEventModel {
    isChange: boolean;

    numOfLeftoverAllotment: number;

    bookingReference: string;

    transferId: string;

    departureTime: string;

    vehicleClass: string | null;

    constructor(ticketCode: string, route: RouteType) {
        const {
            companyId,
            allotment: {
                totalAllotmentLeft,
            },
            travelInfo: {
                departureStation: {
                    id: departureId,
                    departureDate,
                    departureTime,
                },
                destinationStation: {
                    id: destinationId,
                },
                busChange,
                seatClass,
            },
        } = route

        this.isChange = !!busChange;
        this.numOfLeftoverAllotment = totalAllotmentLeft;
        this.bookingReference = ticketCode;
        this.transferId = `${companyId}-${departureId}-${destinationId}`;
        this.departureTime = `${departureDate}T${departureTime}`;
        this.vehicleClass = seatClass?.className;
    }
}