import NotificationExpandable from "@Features/notification/components/NotificationExpandable";
import IconAlert from "@Icons/IconAlert";
import ConnectionGuaranteedNotification from "@Intermodal/components/ConnectionGuaranteedNotification";
import useIsMobile from "@Hooks/selector/useIsMobile";

function IntermodalNotificationWrapper() {
    const isMobile = useIsMobile()
    if (isMobile) {
        return (
            <NotificationExpandable
                toggleIcon={<IconAlert fill="rgb(246, 173, 52)"/>}
                title=""
            >
                <ConnectionGuaranteedNotification/>
            </NotificationExpandable>
        )
    }
    return (
        <ConnectionGuaranteedNotification/>
    );
}

export default IntermodalNotificationWrapper;