import {useTranslation} from "@Translation/hooks/useTranslation";
import {activateDepartureStationInput, activateDestinationStationInput} from "@/reducers/mobileSlice";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";

/**
 * Returns relevant input methods and props based on direction
 * @param direction {'departure'|'destination'}
 */
export default function useMobileStationInput(direction: "departure" | "destination") {
    const {
        departureStationObject,
        destinationStationObject,
    } = useAppSelector((state) => state?.form)

    const {
        departure_station: departurePlaceholder,
        destination_station: destinationPlaceholder,
    } = useTranslation("form")

    const dispatch = useAppDispatch()
    const isDeparture = direction === 'departure'
    const directionLabel = isDeparture ? 'form.depart_from' : 'form.travel_to'
    const station = isDeparture ? departureStationObject : destinationStationObject
    const placeholder = isDeparture ? departurePlaceholder : destinationPlaceholder
    const className = isDeparture
        ? 'search__select search__select--from'
        : 'search__select search__select--to'

    const onClick = () => {
        dispatch(isDeparture
            ? activateDepartureStationInput(true)
            : activateDestinationStationInput(true))
    }

    return {
        isDeparture,
        directionLabel,
        station,
        className,
        placeholder,
        onClick,
    }
}