import React from 'react';
import ClickOutsideAwareComponent from "@Features/clickOutsideAwareComponent/components/ClickOutsideAwareComponent";
import Translation from "@Translation/components/Translation";
import usePassengers from "@Hooks/selector/usePassengers";
import useQuantityAwareTranslation from "@Translation/hooks/useQuantityAwareTranslation";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {PassengersTypeType} from "@Form/types/PassengersType.type";
import {addPassenger, removePassenger} from "../../reducers/formSlice";
import useTogglePassengerPicker from "../../hooks/useTogglePassengerPicker";
import BusRentNotice from "./BusRentNotice";
import PassengerGroup from "./PassengerGroup";

function PassengerPicker() {
    const dispatch = useAppDispatch()
    const closePicker = useTogglePassengerPicker()

    const handlePassenger = (type: PassengersTypeType, action: "add" | "remove") => {
        if (action === 'add') {
            dispatch(addPassenger(type))
        } else {
            dispatch(removePassenger(type))
        }
    };

    const {
        passengersAdult,
        passengersChildren,
        passengersPensioner,
        passengersStudent,
        passengers,
    } = usePassengers()
    const adultLabel = useQuantityAwareTranslation("sys.adult", passengersAdult)
    const childLabel = useQuantityAwareTranslation("sys.child", passengersChildren)
    const studentLabel = useQuantityAwareTranslation("sys.student", passengersStudent)
    const pensionerLabel = useQuantityAwareTranslation("sys.pensioner", passengersPensioner)

    const {
        departureStationObject,
    } = useAppSelector((state) => state?.form);

    const {
        params,
    } = useAppSelector((state) => (state?.searchResults))

    const minimalNumberOfPassengersToDisplayBusRentForm = departureStationObject?.countryBusRentPaxCountMin
        ?? params?.departureStation?.countryBusRentPaxCountMin

    const showBusRent = !!departureStationObject?.label
        && passengers >= minimalNumberOfPassengersToDisplayBusRentForm

    return (
        <ClickOutsideAwareComponent
            className="search__select search__select--pax-open"
            onClickOutside={closePicker}
        >
            <PassengerGroup
                passengerType="passengersAdult"
                count={passengersAdult}
                handlePassenger={handlePassenger}
                fieldName={adultLabel}
            />
            <PassengerGroup
                passengerType="passengersChildren"
                count={passengersChildren}
                handlePassenger={handlePassenger}
                fieldName={childLabel}
            />
            <PassengerGroup
                passengerType="passengersStudent"
                count={passengersStudent}
                handlePassenger={handlePassenger}
                fieldName={studentLabel}
            />
            <PassengerGroup
                passengerType="passengersPensioner"
                count={passengersPensioner}
                handlePassenger={handlePassenger}
                fieldName={pensionerLabel}
            />
            {showBusRent && (
                <BusRentNotice/>
            )}
            <button
                type="submit"
                className="btn btn-block btn--green"
                onClick={closePicker}
            >
                <Translation translationKey="sys.add"/>
                {' '}
                <Translation translationKey="sys.passengers"/>
            </button>
        </ClickOutsideAwareComponent>
    )
}

export default PassengerPicker;