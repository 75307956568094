import React, {ReactNode} from "react";
import GenericCheckbox from "@Generic/Checkbox/GenericCheckbox";

interface ControlCheckboxProps {
    disableChange?: boolean,
    onChange: () => any,
    className?: string,
    checked: boolean,
    children?: ReactNode,
}

function ControlCheckbox({disableChange = false, onChange, checked, className, children}: ControlCheckboxProps) {
    const onClick = () => {
        if (!disableChange) {
            onChange()
        }
    }

    return (
        <GenericCheckbox
            className={className}
            checked={checked}
            onClick={onClick}
        >
            {children}
        </GenericCheckbox>
    );
}

export default ControlCheckbox;