import {useEffect} from "react";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";

import {
    checkPhoneValidation,
    setPhoneCountryObject as storeCountry,
    setPhoneNumber as storeNumber
} from "@CheckOrder/reducers/checkOrderSlice";
import validatePhone from "@Features/input/phoneField/functions/validatePhone";

export default function useHandlePhoneInput() {
    const {
        phoneNumber,
        phoneCountryObject,
        userConfirmed,
        phoneCountrySelectActive,
        phoneValidated,
        user,
        passengerDataUpdating,
        submitTimestamp,
    } = useAppSelector((state) => state?.checkOrder)
    const dispatch = useAppDispatch()

    const setPhoneNumber = (value) => dispatch(storeNumber(value))
    const setPhoneCountryObject = (value) => dispatch(storeCountry(value))

    const setValidState = () => {
        dispatch(checkPhoneValidation(validatePhone(phoneNumber)))
    }

    useEffect(setValidState, [submitTimestamp])

    return {
        phoneNumber,
        setPhoneNumber,
        phoneCountryObject,
        setPhoneCountryObject,
        userConfirmed,
        phoneCountrySelectActive,
        phoneValidated,
        user,
        passengerDataUpdating,
        submitTimestamp,
        setValidState,
    }
}