import {isAfter, isBefore, isValid, parseISO} from "date-fns";
import addMissingZerosToDate
    from "@CheckOrder/features/TicketAdditionalInfo/features/dateAdditionalField/functions/addMissingZerosToDate";
import parseDate from "@DateTime/functions/parseDate";
import formatDate from "@DateTime/functions/formatDate";
import {
    DateAdditionalFieldType
} from "@CheckOrder/features/TicketAdditionalInfo/features/dateAdditionalField/types/DateAdditionalField.type";
import {useTranslation} from "@Translation/hooks/useTranslation";

export default function useGetDateFieldErrorMessage(field: DateAdditionalFieldType, value: string) {
    const {valueRequired} = useTranslation('sys.required')
    const {error} = field.translations || {}

    const getErrorMessage = () => {
        if (!value) {
            return error ?? valueRequired
        }

        if (!isValid(parseISO(addMissingZerosToDate(value)))) {
            return `Please enter a valid date`
        }

        if (!isBefore(parseISO(value), parseDate(field.max.date))) {
            return `Your date needs to be before ${formatDate(field.max.date, "yyyy-MM-dd")}`
        }

        if (!isAfter(parseISO(value), parseDate(field.min.date))) {
            return `Your date needs to be after ${formatDate(field.min.date, "yyyy-MM-dd")}`
        }
    }

    return {
        getErrorMessage,
    }
}