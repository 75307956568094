import React from 'react'
import FeatureCard from "@Pages/mobileApp/features/appOverview/FeatureCard";
import AppStoreLinksContainer from "@Sidebar/components/AppStoreNotice/AppStoreLinksContainer";
import useIsMobile from "@Hooks/selector/useIsMobile";
import IconBusPlot from "@Icons/FilledOutline/IconBusPlot";
import IconFerryPlot from "@Icons/FilledOutline/IconFerryPlot";
import IconTrainPlot from "@Icons/FilledOutline/IconTrainPlot";
import IconCarPlot from "@Icons/FilledOutline/IconCarPlot";
import IconAirplanePlot from "@Icons/FilledOutline/IconAirplanePlot";
import {useTranslation} from "@Translation/hooks/useTranslation";
import useIsTablet from "@Hooks/selector/useIsTablet";

const MobileAppFeatures = () => {
    const isTablet = useIsTablet()
    const isMobile = useIsMobile()

    const {
        bus,
        ferry,
        train,
        more_to_come: moreToCome,
    } = useTranslation("mobile_app")

    const features = [
        {
            title: bus.title,
            color: "green",
            icons: [<IconBusPlot width="22" height="28" key="bus"/>],
            content: <p className="feature-card-content">
                {bus.travel_comfortably}
                <b>{bus.number_of_departures}</b>
                {bus.additional ?? ""}
                     </p>,
        },
        {
            title: ferry.title,
            color: "blue",
            icons: [<IconFerryPlot width="30" height="28" key="ferry"/>],
            content: <p className="feature-card-content">
                {ferry.experience_reliable}
                <b>{ferry.number_of_departures}</b>
                     </p>,
        },
        {
            title: train.title,
            color: "orange",
            icons: [<IconTrainPlot width="22" height="28" key="train"/>],
            content: <p className="feature-card-content">
                {train.experience_seamless}
                <b>{train.number_of_departures}</b>
                     </p>,
        },
        {
            title: moreToCome.title,
            color: "red",
            icons: [
                <IconCarPlot width="22" height="28" key="car"/>,
                <IconAirplanePlot width="30" height="28" key="airplane"/>,
            ],
            content: <p className="feature-card-content">
                {moreToCome.if_you_need}
                <b>{moreToCome.transfer}</b>
                {moreToCome.want_to}
                <b>{moreToCome.book_a_flight}</b>
                {moreToCome.planning}
                <b>{moreToCome.all_day_tour}</b>
                {moreToCome.stay_tuned}
                     </p>,
        },
    ]

    return (
        <div className="feature-cards-container">
            <div className="feature-cards">
                {features.map((feature) => (
                    <FeatureCard
                        color={feature.color}
                        title={feature.title}
                        icons={feature.icons}
                        key={feature.title}
                    >
                        {feature.content}
                    </FeatureCard>
                ))}
            </div>
            {!(isTablet || isMobile) && (
                <AppStoreLinksContainer/>
            )}
        </div>
    )
}

export default MobileAppFeatures