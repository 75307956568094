import React from 'react';
import IconChevron from "@Icons/IconChevron";
import IconGlobeWithFlag from "@Icons/FilledOutline/IconGlobeWithFlag";
import NationalityType from "@CheckOrder/types/Nationality.type";
import CountryFlag from "@CheckOrder/components/MainSection/BuyerInfo/CountryFlag";

interface SelectedCountryIconProps {
    selectedCountry: NationalityType | string,
}

function SelectedCountryIcon({selectedCountry}: SelectedCountryIconProps) {
    if (typeof (selectedCountry) !== "string" && selectedCountry?.isoCode) {
        return (
            <>
                <CountryFlag isoCode={selectedCountry.isoCode}/>
                <IconChevron fill="#070c63" width="30" height="15"/>
            </>
        )
    }

    return (
        <>
            <IconGlobeWithFlag height="25"/>
            <IconChevron fill="#070c63" width="30" height="15"/>
        </>
    )
}

export default SelectedCountryIcon;