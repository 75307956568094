import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconFood(props: IconType) {
    const {
        width = '35',
        height = '35',
        viewBox = '0 0 35 35',
        title = 'IconFood',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none" fillRule="evenodd" transform="translate(1.933444 5.025284)">
                    <path d="m2.067 13.975h28v5h-28z" fill="#451d08"/>
                    <path d="m2.067 8.975h28v5h-28z" fill="#bd1011"/>
                    <path
                        d="m1.04635657 18.1090086v3.8564254c0 .5522847.44771525 1 1 1h28.02034343c.5522848 0 1-.4477153 1-1v-3.8564254z"
                        fill="#e69542"
                    />
                    <path
                        d="m22.0667 19.1090086c0 1.1045695.8954305 2 2 2h3c1.1045695 0 2-.8954305 2-2z"
                        fill="#f2d787"
                    />
                    <path
                        d="m1.04635657 9.98589083h30.02034343c0-6.65726055-5.0033905-9.98589083-15.0101717-9.98589083-10.00678115 0-15.01017173 3.32863028-15.01017173 9.98589083z"
                        fill="#e69542"
                    />
                    <g transform="translate(0 11.974716)">
                        <g fill="#fcca2e">
                            <path
                                d="m5.03018418 1.65c.82133935.68619039 1.34765498 1.21102268 1.57894687 1.5744969.18129409.28490288.41840034.84322728.71131875 1.67497318l-.00006737.00002373c.05504978.1563146.22639446.23840596.38270906.18335618.07737912-.02725083.14022721-.08499415.17391993-.15979346.18792335-.41719813.36529287-.73979913.53210856-.96780302.25349268-.34647411.95114307-1.11489194 2.09295122-2.30525351z"
                            />
                            <path
                                d="m17.0301842 1.65c.8213393.68619039 1.347655 1.21102268 1.5789468 1.5744969.1812941.28490288.4184004.84322728.7113188 1.67497318l-.0000674.00002373c.0550498.1563146.2263945.23840596.3827091.18335618.0773791-.02725083.1402272-.08499415.1739199-.15979346.1879234-.41719813.3652929-.73979913.5321086-.96780302.2534927-.34647411.9511431-1.11489194 2.0929512-2.30525351z"
                            />
                            <path
                                d="m25.0301842 1.65c.8213393.68619039 1.347655 1.21102268 1.5789468 1.5744969.1812941.28490288.4184004.84322728.7113188 1.67497318l-.0000674.00002373c.0550498.1563146.2263945.23840596.3827091.18335618.0773791-.02725083.1402272-.08499415.1739199-.15979346.1879234-.41719813.3652929-.73979913.5321086-.96780302.2534927-.34647411.9511431-1.11489194 2.0929512-2.30525351z"
                            />
                        </g>
                        <path
                            d="m.82294249 0h1.05759157c.12374751 0 .24722796.01148503.36885274.03430733l5.0128133.94063022c.25806645.04842492.52314269.04556014.78010229-.00843086l4.25123831-.89324782c.2306256-.04845782.4680004-.05577461.7011726-.02161278l6.3922701.93652531c.2706639.0396547.5465854.02336312.8107021-.04786732l2.9773868-.80298038c.3337527-.09001077.6851371-.09198044 1.0198779-.00571687l2.8747551.74083179c.4416065.11380313.9088441.07326995 1.324256-.11488011l1.2792485-.57940253c.259244-.11741788.5405619-.17815598.8251571-.17815598h1.0594452c.2894762 0 .524143.23466683.524143.52414303 0 .32207917-.1792643.61738523-.4650022.76600842l-1.4549525.75677633c-.0635837.03307231-.1288992.06270291-.1956637.08876356l-1.6474284.64305142c-.3695293.14424079-.7736055.1753357-1.1608529.08933124l-3.0809364-.6842506c-.259309-.05759038-.5274923-.0629492-.7888948-.01576365l-3.1931956.57640101c-.197365.03562619-.3989661.04136383-.5980378.01702039l-6.5595348-.80213105c-.1957594-.02393839-.393983-.01879059-.588236.01527627l-4.32031293.75766915c-.24830591.04354632-.50260613.03975617-.749504-.01117078l-5.01293681-1.03400481c-.13292832-.02741876-.26830246-.04123496-.40402912-.04123496h-1.03949465c-.45449859 0-.82294249-.3684439-.82294249-.82294248 0-.45449859.3684439-.82294249.82294249-.82294249z"
                            fill="#66ae16"
                        />
                    </g>
                    <g fill="#f7d474">
                        <g transform="translate(3.752071 1.974716)">
                            <path
                                d="m22.2283758 5.595613c0-.20281951.1644177-.36723721.3672372-.36723721h.3982884c.405639 0 .7344744.3288354.7344744.73447442v.35947442c0 .20710678-.1678932.375-.375.375h-.0077628c-.2028195 0-.3672372-.1644177-.3672372-.36723721s-.1644177-.36723721-.3672372-.36723721h-.0155256c-.2028195 0-.3672372-.1644177-.3672372-.36723721z"
                                transform="matrix(0 1 -1 0 28.941226 -17.015526)"
                            />
                            <path
                                d="m4.22837579 5.595613c0-.20281951.1644177-.36723721.36723721-.36723721h.39828837c.40563902 0 .73447442.3288354.73447442.73447442v.35947442c0 .20710678-.16789322.375-.375.375h-.00776279c-.20281951 0-.36723721-.1644177-.36723721-.36723721s-.1644177-.36723721-.36723721-.36723721h-.01552558c-.20281951 0-.36723721-.1644177-.36723721-.36723721z"
                                transform="matrix(0 1 -1 0 10.941226 .984474)"
                            />
                            <path
                                d="m13 5.36723721c0-.20281951.1644177-.36723721.3672372-.36723721h.3827628c.4142136 0 .75.33578644.75.75v.68651252c0 .20710678-.1678932.375-.375.375s-.375-.16789322-.375-.375v-.3270381c0-.20710678-.1678932-.375-.375-.375h-.0077628c-.2028195 0-.3672372-.1644177-.3672372-.36723721z"
                                transform="matrix(0 1 -1 0 19.655756 -7.844244)"
                            />
                            <path
                                d="m17 3.36723721c0-.20281951.1644177-.36723721.3672372-.36723721h.3827628c.4142136 0 .75.33578644.75.75v.68651252c0 .20710678-.1678932.375-.375.375s-.375-.16789322-.375-.375v-.3270381c0-.20710678-.1678932-.375-.375-.375h-.0077628c-.2028195 0-.3672372-.1644177-.3672372-.36723721z"
                                transform="matrix(0 -1 1 0 13.844244 21.655756)"
                            />
                            <path
                                d="m6 1.36723721c0-.20281951.1644177-.36723721.36723721-.36723721h.38276279c.41421356 0 .75.33578644.75.75v.68651252c0 .20710678-.16789322.375-.375.375s-.375-.16789322-.375-.375v-.3270381c0-.20710678-.16789322-.375-.375-.375h-.00776279c-.20281951 0-.36723721-.1644177-.36723721-.36723721z"
                                transform="matrix(0 -1 1 0 4.844244 8.655756)"
                            />
                            <path
                                d="m1 3.36723721c0-.20281951.1644177-.36723721.36723721-.36723721h.38276279c.41421356 0 .75.33578644.75.75v.68651252c0 .20710678-.16789322.375-.375.375s-.375-.16789322-.375-.375v-.3270381c0-.20710678-.16789322-.375-.375-.375h-.00776279c-.20281951 0-.36723721-.1644177-.36723721-.36723721z"
                                transform="matrix(0 -1 1 0 -2.155756 5.655756)"
                            />
                            <path
                                d="m11 .36723721c0-.20281951.1644177-.36723721.3672372-.36723721h.3827628c.4142136 0 .75.33578644.75.75v.68651252c0 .20710678-.1678932.375-.375.375s-.375-.16789322-.375-.375v-.3270381c0-.20710678-.1678932-.375-.375-.375h-.0077628c-.2028195 0-.3672372-.1644177-.3672372-.36723721z"
                                transform="matrix(0 1 1 0 10.844244 -10.844244)"
                            />
                            <path
                                d="m10.2283758 4.595613c0-.20281951.1644177-.36723721.3672372-.36723721h.3982884c.405639 0 .7344744.3288354.7344744.73447442v.35947442c0 .20710678-.1678932.375-.375.375h-.0077628c-.2028195 0-.3672372-.1644177-.3672372-.36723721s-.1644177-.36723721-.3672372-.36723721h-.0155256c-.2028195 0-.3672372-.1644177-.3672372-.36723721z"
                                transform="matrix(-1 0 0 1 21.956752 0)"
                            />
                        </g>
                        <path
                            d="m23.9804469 4.39921185c0-.27042602.2192236-.48964961.4896497-.48964961h.6611925c.540852 0 .9792992.43844719.9792992.97929922v.44676388c0 .29411115-.2384242.53253534-.5325353.53253534h-.0428858c-.270426 0-.4896496-.2192236-.4896496-.48964961s-.2192236-.48964961-.4896496-.48964961h-.0857714c-.2704261 0-.4896497-.2192236-.4896497-.48964961z"
                        />
                    </g>
                </g>
            )}
        />

    );
}

export default IconFood;
