import useSingleTrackingEvent from "@Events/hooks/useSingleTrackingEvent";
import SearchResultsFilterEventModel from "@Events/models/SearchResultsFilterEventModel";
import React from "react";

export default function useDispatchFilterEvents(filterName: string, filterValue: string) {
    const dispatchUseSearchResultsFilterEvent = useSingleTrackingEvent("use_search_results_filters", SearchResultsFilterEventModel(filterName, filterValue))
    const handleOnBlur: React.FocusEventHandler<HTMLInputElement> = (event) => {
        dispatchUseSearchResultsFilterEvent()
    }

    return handleOnBlur;
}