import React from 'react';
import formatDate from "@DateTime/functions/formatDate";
import {useTranslation} from "@Translation/hooks/useTranslation";
import Column from "@Containers/components/Column";
import {VoucherTypeType} from "@MyVoucher/types/VoucherType.type";

interface VoucherIssuerProps {
    issuedOn: string,
    type: VoucherTypeType,
    ticketCode?: string,
}

const VoucherIssuerInfo = ({issuedOn, type, ticketCode = null}: VoucherIssuerProps) => {
    const cancelledTicketCodeLabel = useTranslation('my_profile.my_vouchers.canceled_ticket_code', {ticketCode})
    const issuedLabel = useTranslation(
        'my_profile.my_vouchers.issued',
        {date: formatDate(issuedOn, "MMM dd, yyyy")}
    )

    const getDescription = () => (type === "promo" ? "Promo voucher" : cancelledTicketCodeLabel)

    return (
        <Column className="voucher-issuer info-field-medium">
            <div className="gb--emphasize-default">{issuedLabel}</div>
            <div>{getDescription()}</div>
        </Column>
    );
};

export default VoucherIssuerInfo;