import React from 'react';
import GetByApsLogomark from "@Generic/Logotypes/GetByApsLogomark";
import {useTranslation} from "@Translation/hooks/useTranslation";
import {capitalize} from "@String/capitalize";
import useActivateMobileFormOverlay from "@Form/hooks/useActivateMobileFormOverlay";

function MobileEditSearchButton() {
    const {toggleFormAction} = useActivateMobileFormOverlay()
    const {edit, search} = useTranslation("sys")
    const editSearch = capitalize(`${edit} ${search}`)

    return (
        <div className="gb--edit-search-bottom-fixed-container">
            <button onClick={toggleFormAction} type="button">
                <GetByApsLogomark/>
                {editSearch}
            </button>
        </div>
    );
}

export default MobileEditSearchButton;