import {useAppSelector} from "@Hooks/selector/useAppSelector"
import usePurchasedRoute from "@CheckOrder/hooks/usePurchasedRoute"
import roundToTwoDecimals from "@Number/roundToTwoDecimals"

/**
 * This is only required for flixbus, EU regulations
 */
export default function useCarrierBookingFees() {
    const {
        ticket: {
            singleBookingFee,
            returnBookingFee,
            bookingFee,
        },
    } = useAppSelector((state) => state?.checkOrder);

    const {
        returnRoute,
        singleRoute,
    } = usePurchasedRoute();

    const isFlixbus = () => {
        return singleRoute.companyId === '488' || returnRoute?.companyId === '488'
    }

    const getCarrierFees = () => {
        if (!isFlixbus()) {
            return null
        }

        const parsedSingleCarrierFee = JSON.parse(singleRoute?.status?.jsonEncodedApiFullIntegrationData)?.gbbTicketData?.carrier_booking_fee
        const parsedReturnCarrierFee = returnRoute ? JSON.parse(returnRoute?.status?.jsonEncodedApiFullIntegrationData)?.gbbTicketData?.carrier_booking_fee : 0
        const carrierFee = parseFloat(parsedSingleCarrierFee) + parseFloat(parsedReturnCarrierFee)
        const bookingFeeCurrency = bookingFee.split(" ")[1]

        if (carrierFee <= 0) {
            return null
        }

        return `${roundToTwoDecimals(carrierFee).toFixed(2)} ${bookingFeeCurrency}`
    }

    return {
        getCarrierFees,
    }
}