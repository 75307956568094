import Cookies from "universal-cookie";

/**
 * Returns cookie value, by default converts strings to booleans
 * @return {boolean}
 */
export default function getCookie(name: string, parseCookie: boolean = true) {
    const cookieValue = new Cookies().get(name) ?? null
    if (parseCookie) {
        return cookieValue === `true`
    }

    return cookieValue
}