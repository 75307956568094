import SearchResultsRequestType from "@SearchResults/api/types/SearchResultsRequest.type";
import {ThunkDispatch} from "@reduxjs/toolkit";
import updateSearchResults from "@SearchResults/api/functions/updateSearchResults";
import RouteSuggestionResponseType from "@SearchResults/api/types/RouteSuggestionResponse.type";
import {SearchResultsRtkQueryResponseType} from "@SearchResults/api/types/SearchResultsRtkQueryResponse.type";

export default function updateSearchResultsWithRouteSuggestions(
    dispatch: ThunkDispatch<any, any, any>,
    searchResultsApi: any,
    searchParams: SearchResultsRequestType,
    routeSuggestionResponse: RouteSuggestionResponseType,
) {
    const updateRecipe = ({result, suggestionData, locale, token, routeTranslations, urls}: SearchResultsRtkQueryResponseType): SearchResultsRtkQueryResponseType => <SearchResultsRtkQueryResponseType>({
        result: {
            ...result,
            resolvedSuggestionsCount: result.resolvedSuggestionsCount + 1,
            suggestedRoutes: [
                ...result.suggestedRoutes,
                ...routeSuggestionResponse.routes,
            ],
            companies: {
                ...result.companies,
                ...routeSuggestionResponse.companies,
            },
            passengerGroups: {
                ...result.passengerGroups,
                ...routeSuggestionResponse.passengerGroups,
            },
            routeIcons: {
                ...result.routeIcons,
                ...routeSuggestionResponse.routeIcons,
            },
        },
        suggestionData: {
            ...suggestionData,
            routeSuggestionHubs: [],
        },
        locale,
        token,
        routeTranslations,
        urls,
    })

    // @ts-ignore
    updateSearchResults(dispatch, searchResultsApi, searchParams, updateRecipe)
}