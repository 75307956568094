import createTabsFromGroups from "@Tabs/functions/createTabsFromGroups";
import {RoutesOrganizedByTransportTypeType} from "@SearchResults/types/RoutesOrganizedByTransportType.type";

export default function useTabsToShow(singleRoutes: RoutesOrganizedByTransportTypeType) {
    const getTabsToShow = () => {
            return createTabsFromGroups(singleRoutes)
    }

    return {
        getTabsToShow,
    }
}