import React from 'react';
import IconUserOutline from "@Icons/IconUserOutline";
import {PassengersTypeType} from "@Form/types/PassengersType.type";
import CountControl from "@/components/CountControl";

interface PassengerGroupProps {
    handlePassenger: (type, action: "add" | "remove") => any,
    passengerType: PassengersTypeType,
    fieldName: string,
    count: number,
}

function PassengerGroup(props: PassengerGroupProps) {
    const {
        handlePassenger,
        passengerType,
        fieldName,
        count,
    } = props;

    const incrementCount = () => {
        handlePassenger(passengerType, "add")
    }

    const decrementCount = () => {
        handlePassenger(passengerType, "remove")
    }

    return (
        <div className="search__select--pax-add gb--row gb--row-middle gb--row-justify">
            <IconUserOutline
                width="20"
                style={{marginLeft: '1.5rem'}}
            />
            <input
                type="text"
                name={fieldName}
                placeholder={fieldName}
                value={`${fieldName}`}
                readOnly
                disabled
            />
            <CountControl
                handleIncrement={incrementCount}
                incrementClassName="pax--counter gb--row gb--row-middle"
                handleDecrement={decrementCount}
                decrementClassName="pax--counter gb--row gb--row-middle"
                count={count}
            />
        </div>
    );
}

export default PassengerGroup;
