import SearchResultsResponseType from "@SearchResults/api/types/SearchResultsResponse.type";
import {ThunkDispatch} from "@reduxjs/toolkit";
import SearchResultsRequestType from "@SearchResults/api/types/SearchResultsRequest.type";
import ApiIntegrationResponseType from "@SearchResults/api/types/ApiIntegrationResponse.type";

export default function updateSearchResults(
    dispatch: ThunkDispatch<any, any, any>,
    searchResultsApi: any,
    searchParams: SearchResultsRequestType,
    updateRecipe: ({result}: SearchResultsResponseType, updatedApiResponse?: ApiIntegrationResponseType) => SearchResultsResponseType
) {
    dispatch(searchResultsApi.util.updateQueryData(
        'getSearchResults',
        searchParams,
        updateRecipe
    ))
}