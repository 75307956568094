import VoucherRestrictionsType from "@MyVoucher/types/VoucherRestrictions.type";
import VoucherCompanyAndCompanyStoreRestrictions
    from "@MyVoucher/features/voucherDetails/components/VoucherCompanyAndCompanyStoreRestrictions";
import VoucherStationRestriction from "@MyVoucher/features/voucherDetails/components/VoucherStationRestriction";
import VoucherRouteRestrictions from "@MyVoucher/features/voucherDetails/components/VoucherRouteRestrictions";

interface VoucherRestrictionsProps {
    limitations: VoucherRestrictionsType,
}

function VoucherRestrictions({limitations}: VoucherRestrictionsProps) {
    const {
        routes,
        restrictedToCompanies,
        companyStores,
        departureStations,
        destinationStations,
    } = limitations

    return (
        <>
            <VoucherRouteRestrictions
                routes={routes}
            />
            <VoucherCompanyAndCompanyStoreRestrictions
                companies={restrictedToCompanies}
                companyStores={companyStores}
            />
            <VoucherStationRestriction
                stations={departureStations}
                titleKey="my_profile.my_vouchers.departures_from_station"
            />
            <VoucherStationRestriction
                stations={destinationStations}
                titleKey="my_profile.my_vouchers.arrivals_to_station"
            />
        </>
    );
}

export default VoucherRestrictions;