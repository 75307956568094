import IconPlus from "@Icons/IconPlus";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import Column from "@Containers/components/Column";
import Row from "@Containers/components/Row";
import IconVehicle from "@Icons/FilledOutline/IconVehicle";
import Translation from "@Translation/components/Translation";

type AddVehicleButtonProps = {
    label: string,
    onClick: () => void,
    isConfirm?: boolean,
}

function AddVehicleButton({label, onClick, isConfirm}: AddVehicleButtonProps) {
    const {passengerDataUpdating} = useAppSelector((state) => state.checkOrder)
    return (
        <button
            disabled={passengerDataUpdating}
            className="addons--add-addon-button"
            onClick={onClick}
            type="button"
        >
            <Column className="addons--add-addon-button_title-wrapper">
                <Row center>
                    <IconPlus
                        className="title-icon"
                        backgroundFill="none"
                        width="35"
                        height="35"
                    />
                    <span className="gb--card-title">
                        {label}
                    </span>
                </Row>
                <span className="addons--add-addon-button_description">
                    <Translation translationKey="check_order.addons.JADL.vehicle.cta_description"/>
                </span>
            </Column>
            <IconVehicle
                width="35"
                height="35"
            />
        </button>
    );
}

export default AddVehicleButton;