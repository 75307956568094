import usePrepareIntermodalRoute from "@Intermodal/hooks/usePrepareIntermodalRoute";
import IntermodalRouteType from "@Intermodal/types/IntermodalRoute.type";
import CompanyType from "@SearchResults/types/company/Company.type";
import PassengerGroupType from "@SearchResults/types/passenger/PassengerGroup.type";
import CurrencyType from "@Currency/types/Currency.type";

type PrepareIntermodalRoutesProps = {
    companies: Record<string, CompanyType>,
    passengerGroups: Record<string, PassengerGroupType>,
    currency: CurrencyType,
    intermodalRoutes: IntermodalRouteType[],
}
export default function usePrepareIntermodalRoutes({companies, passengerGroups, currency, intermodalRoutes}: PrepareIntermodalRoutesProps) {
    const {prepareIntermodalRoute} = usePrepareIntermodalRoute({
        companies,
        currency,
        passengerGroups,
    })

    return intermodalRoutes.map((route) => prepareIntermodalRoute(route))
}