import FilterSection from "@SearchResults/features/sidebar/components/FilterSection";
import CompanyScoreFilterButton
    from "@SearchResults/features/sidebar/features/companyFilters/components/CompanyScoreFilterButton";
import {
    CompanyScoresArray,
} from "@SearchResults/features/sidebar/features/companyFilters/constants/CompanyScoreTreshholds";

type CarrierScoreFilterProps = {}

function CompanyScoreFilter(props: CarrierScoreFilterProps) {
    return (
        <FilterSection
            translationKey="search_results.filter_by.company_score.label"
        >
            {CompanyScoresArray.map((companyScore) => (
                <CompanyScoreFilterButton
                    key={companyScore.label}
                    companyScore={companyScore}
                />
            ))}
        </FilterSection>
    );
}

export default CompanyScoreFilter;