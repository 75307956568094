import React from 'react';
import LogoGetByFerry from "@Generic/Logotypes/LogoGetByFerry";
import LogoGetByBus from "@Generic/Logotypes/LogoGetByBus";
import LogoGetByTransfer from "@Generic/Logotypes/LogoGetByTransfer";
import GetByApsLogomark from "@Generic/Logotypes/GetByApsLogomark";
import {useAppSelector} from "@Hooks/selector/useAppSelector";

function GetByApsLogo() {
    const tag = useAppSelector((state) => state?.searchResults.transportTypeKeyword)
    const components = {
        bus: LogoGetByBus,
        ferry: LogoGetByFerry,
        transfer: LogoGetByTransfer,
    };

    const LogoGetByAps = components[tag] ?? components.bus;
    return (
        <>
            <GetByApsLogomark style={{verticalAlign: 'middle', marginRight: '.5rem'}}/>
            <LogoGetByAps style={{verticalAlign: 'middle'}}/>
        </>
    )
}

export default GetByApsLogo;