import Column from "@Containers/components/Column";
import React, {useRef} from "react";
import {PreparedRouteType} from "@Route/types/PreparedRoute.type";
import CopyVoucherCodeButton from "@MyVouchers/components/CopyVoucherCodeButton";
import Button from "@Generic/Button";
import IconInfoCircle from "@Icons/FilledOutline/IconInfoCircle";

type DebugInfoProps = {
    route: PreparedRouteType
}

function DebugApiRouteInfo(props: DebugInfoProps) {
    const ref = useRef(null)
    const {
        providerId,
        jsonEncodedApiFullIntegrationData,
    } = props.route.status;

    const getPopoverData = (): object => {
        if (!jsonEncodedApiFullIntegrationData) {
            return null
        }
        if (!providerId) {
            return null
        }

        try {
            return JSON.parse(jsonEncodedApiFullIntegrationData)
        } catch (e) {
            return null
        }
    }

    const mapValues = (value) => {
        if (typeof value === 'string' || typeof value === 'number') {
            return (
                <CopyVoucherCodeButton code={value} className="gb--filter-button">
                    {value}
                </CopyVoucherCodeButton>
            )
        }
        return Object.entries(value).map(([key, value]) => (
            <div key={key}>
                <small>
                    {`${key}: `}
                </small>
                <CopyVoucherCodeButton code={value} className="gb--filter-button">
                    {value}
                </CopyVoucherCodeButton>
            </div>
        ))
    }

    const popoverData = getPopoverData()

    return (
        <>
            <span className="gb--badge gb--badge-left gb--badge-green" style={{zIndex: 999}}>
                Route provider:
                {" "}
                {providerId}
            </span>
            {ref && (
                <>
                    <button onClick={() => ref?.current.showModal()}>
                        <IconInfoCircle
                            height={25}
                            width={25}
                            style={{cursor: "pointer"}}
                        />
                    </button>
                    <dialog
                        ref={ref}
                        onCancel={ref?.current?.hide}
                    >
                        <Column>
                            <Button
                                className="red"
                                onClick={(e) => {
                                    ref?.current.close()
                                    e.stopPropagation()
                                }}
                            >
                                close
                            </Button>
                            <br/>
                            <hr/>
                            {popoverData && (
                                <div style={{maxWidth: '40em', fontSize: '22px'}}>
                                    {Object.entries(popoverData).map(([key, value]) => (
                                        <div key={key}>
                                            <h4>{key}</h4>
                                            {Object.entries(value).map(([key, value]) => (
                                                <div key={key}>
                                                    <small>
                                                        {`${key}: `}
                                                    </small>
                                                    {mapValues(value)}
                                                    <br/>
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </Column>
                    </dialog>
                </>
            )}
        </>
    );
}

export default DebugApiRouteInfo;