import {
    RouteTransferTypeKeys,
    RouteTransferTypeValuesType
} from "@Route/features/transportType/types/routeTransferType.type";

export const routeTransferTypes: Record<RouteTransferTypeKeys, RouteTransferTypeValuesType> = {
    0: 'privateTransfer',
    1: 'bus',
    2: 'ferry',
    3: 'train',
    4: 'plane',
    5: 'minivan',
    6: 'privateMinivan',
    7: 'privateBus',
}