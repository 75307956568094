import React from 'react';
import Modal from "@Generic/Modal/Modal";
import useTimeout from "@Hooks/effects/useTimeout";
import {activatePopup} from "@Reducers/pageSlice";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import PopupType from "@Popups/types/Popup.type";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";

/**
 * Generic popup based on modal, hides after 1.5 seconds
 * Do not pass any props directly to this component, accepts props from redux activePopup
 */
function Popup({activePopup}: {activePopup: boolean|PopupType}) {
    const {
        message,
        title,
        buttonText = "OK",
        timeout = 4000,
        handle,
    } = activePopup as PopupType || {}

    const dispatch = useAppDispatch()
    const dismiss = () => dispatch(activatePopup(false))
    useTimeout(dismiss, timeout, activePopup)

    const handlePopup = () => {
        handle()
        dismiss()
    }

    if (activePopup) {
        return (
            <Modal
                buttonText={buttonText}
                handleClick={handle ? handlePopup : dismiss}
                message={message}
                title={title}
            />
        );
    }

    return null
}

export default Popup;