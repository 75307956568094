import TabType from "@Tabs/types/Tab.type";
import {RouteTransferTypeValuesType} from "@Route/features/transportType/types/routeTransferType.type";
import RouteType from "@Route/types/Route.type";

type ShowNoRouteNotificationProps = {
    singleRoutes: RouteType[]
    activeTab: TabType,
    apiRoutesLoading: boolean,
    companyMainTransportType: RouteTransferTypeValuesType,
    suggestionRoutesLoading: boolean,
}
export default function useShowNoRoutesNotification({singleRoutes, activeTab, apiRoutesLoading, companyMainTransportType, suggestionRoutesLoading}: ShowNoRouteNotificationProps) {
    return activeTab?.tabType === 'bus'
        && companyMainTransportType !== 'ferry'
        && !singleRoutes?.length
        && !apiRoutesLoading
        && !suggestionRoutesLoading
}