import React from 'react';
import IconCoins from "@Icons/IconCoins";
import IconWatch from "@Icons/IconWatch";
import IconStar from "@Icons/IconStar";
import IconHourglass from "@Icons/IconHourglass";

interface RouteIconsProps {
    tag?: string
}

function RouteIcons({tag = "info"}: RouteIconsProps) {
    const components = {
        departure: IconWatch,
        rating: IconStar,
        travel_time: IconHourglass,
        price: IconCoins,
    };

    const SortIcon = components[tag];

    return <SortIcon width="18" height="18"/>
}

export default RouteIcons;
