/**
 * Check if single selected company is same as this route company.
 * Used for return route highlighting and pricing
 */
interface IsSameCompanyProps {
    selectedSingleRouteCompanyId: string,
    routeCompanyId: string,
    isReturn: boolean,
}

export default function isSameCompany(props: IsSameCompanyProps): boolean {
    const {
        selectedSingleRouteCompanyId,
        routeCompanyId,
        isReturn,
    } = props

    if (selectedSingleRouteCompanyId) {
        return selectedSingleRouteCompanyId === routeCompanyId && isReturn
    }

    return false
}