import {generatePath, NavLink} from "react-router-dom";
import Translation from "@Translation/components/Translation";
import React from "react";
import {useTranslation} from "@Translation/hooks/useTranslation";
import Button from "@Generic/Button";
import useIsMobile from "@Hooks/selector/useIsMobile";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import ModalLinkButton from "@UserModals/components/ModalLinkButton";
import useLocale from "@Hooks/selector/useLocale";

interface ManageBookingActionButtonProps {
    cancelTicketOptionSelected: boolean,
    unsuccessfulRebook: boolean,
    successfulRebook: boolean,
    isOneCompanyReturnTicket: boolean,
    isFirstLegExpired: boolean,
    resetApiState: () => void
    handleTicketRebook: () => void,
}

function ManageBookingActionButton(props: ManageBookingActionButtonProps) {
    const {
        cancelTicketOptionSelected,
        unsuccessfulRebook,
        successfulRebook,
        isOneCompanyReturnTicket,
        isFirstLegExpired,
        resetApiState,
        handleTicketRebook,
    } = props

    const {
        click_for_support: contactSupportLabel,
        confirm_rebook: confirmRebookLabel,
    } = useTranslation("my_profile.my_tickets.booking_modal")

    const locale = useLocale()

    const {url} = useAppSelector((state) => (state?.page.urlsObject.contact))

    const isMobile = useIsMobile()

    if (cancelTicketOptionSelected || unsuccessfulRebook || (isOneCompanyReturnTicket && isFirstLegExpired)) {
        return (
            <ModalLinkButton
                link={url}
                text={contactSupportLabel}
            />
        )
    }

    if (successfulRebook) {
        return (
            <span className={`btn btn--green btn--${isMobile ? "mobile" : ""}`}>
                <NavLink
                    to={generatePath("/:locale/my-profile/vouchers", {locale})}
                    onClick={resetApiState}
                >
                    <Translation translationKey="sys.my_vouchers"/>
                </NavLink>
            </span>
        )
    }

    return (
        <Button
            onClick={handleTicketRebook}
            className="green"
            additionalClasses={isMobile ? "btn--mobile" : ""}
        >
            {confirmRebookLabel}
        </Button>
    )
}

export default ManageBookingActionButton