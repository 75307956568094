import axios from "axios";
import qs from "qs";
import getClientProperties from "@/features/errors/functions/getClientProperties";

/** Generic ajax handler, defaults to get and params: null
 *
 * @deprecated - DEPRECATED! - use v2 instead
 * @param url {string}
 * @param {('log'|'ignore')} handle - log error || return null
 * @param params {object}
 * @param type {('get'|'post')}
 * @param stringify {boolean}
 * @param cancelable {boolean}
 * @param cancelToken
 * @return {(Promise<*>| null)}
 */
export default async function ajaxHandler(
    url,
    handle,
    params = null,
    type = 'get',
    stringify = true,
    cancelable = true,
    cancelToken = null
) {
    const ajaxConfig = {
        headers: {"X-Requested-With": "XMLHttpRequest"},
        // cancelToken,
    };

    try {
        if (type === 'get') {
            return await axios.get(url, {params, ...ajaxConfig});
        }

        if (type === 'post') {
            if (stringify) {
                return await axios.post(url, qs.stringify({...params}), ajaxConfig);
            }
            await axios.post(url, {...params}, ajaxConfig);
        }
    } catch (err) {
        if (axios.isCancel(err)) {
            // eslint-disable-next-line no-console
            console.log('Request canceled', err.message);
        }
        // if (handle === 'log' && intToBool(window.clientRequestErrorLoggingEnabled)) {
        //     await logError(err)
        //     return null
        // } else {
        return null
        // }
    }
}

/**
 *
 * @param err {object}
 * @return {Promise<void>}
 */
async function logError(err) {
    await axios.post('/ajax/log-request-failed', {
        ...getClientProperties(),
        stack_trace: err,
    })
}