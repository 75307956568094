import React from 'react';
import TabType from "@Tabs/types/Tab.type";
import RouteType from "@Route/types/Route.type";
import Tabs from "@Tabs/components/Tabs";

interface TabsContainerProps {
    activeTab: TabType,
    tabsToShow: TabType[],
    singleSelectedRoute: RouteType,
    isReturnSearch: boolean,
}

function TabsContainer(props: TabsContainerProps) {
    const {
        activeTab,
        tabsToShow,
        singleSelectedRoute,
        isReturnSearch,
    } = props;

    if (tabsToShow.length > 1) {
        if (singleSelectedRoute && isReturnSearch) {
            return null
        }

        return (
            <div className="gb--tabs gb--row">
                <Tabs
                    activeTab={activeTab}
                    tabsToShow={tabsToShow}
                />
            </div>
        )
    }

    return null
}

export default TabsContainer;