import {AllotmentErrorType} from "@Route/features/ticketPurchase/types/AllotmentError.type";
import RouteError from "./RouteError";

/**
 * @constructor
 */
export default class AllotmentError extends RouteError {
    allotmentError: AllotmentErrorType

    constructor(allotmentError: AllotmentErrorType) {
        super('allotmentError');
        this.allotmentError = allotmentError
    }
}