import {
    numberOfDigits as dayNumberOfDigits
} from "@CheckOrder/features/TicketAdditionalInfo/features/dateAdditionalField/constants/dayDigitsConstraints";
import {
    numberOfDigits as monthNumberOfDigits
} from "@CheckOrder/features/TicketAdditionalInfo/features/dateAdditionalField/constants/monthDigitsConstraints";
import {
    numberOfDigits as yearNumberOfDigits
} from "@CheckOrder/features/TicketAdditionalInfo/features/dateAdditionalField/constants/yearDigitsConstraints";

/*
 * completes date to completely fill YYYY-MM-DD format by adding zeros in front of days/months/years if necessary
 */
export default function addMissingZerosToDate(value: string) {
    const addZeroAtTheStart = (value: string, maxLength: number) => (
        value.length < maxLength ? addZeroAtTheStart(`0${value}`, maxLength) : value
    )

    const [year, month, day] = value.split("-")
    return `${addZeroAtTheStart(year, yearNumberOfDigits)}-${addZeroAtTheStart(month, monthNumberOfDigits)}-${addZeroAtTheStart(day, dayNumberOfDigits)}`
}