import React from 'react';
import PriceWithCurrency from "@Route/components/Price/PriceWithCurrency";

interface GroupDiscountProps {
    priceWithDiscount: number,
    priceWithoutDiscount: number,
}

const GroupDiscount = ({priceWithDiscount, priceWithoutDiscount}: GroupDiscountProps) => (
    <div className="discount__price">
        <PriceWithCurrency
            linebreak={false}
            className="base__price"
            price={priceWithoutDiscount}
        />
        <PriceWithCurrency
            linebreak={false}
            className="discounted__price"
            price={priceWithDiscount}
        />
    </div>
);

export default GroupDiscount;