import React, {ReactNode} from 'react';
import {
    calculateCompanyRatingBackgroundColor
} from "@Features/colorGradedNode/hooks/calculateCompanyRatingBackgroundColor";

interface ColorGradedNodeProps {
    rating: string | number,
    className?: string,
    children: ReactNode,
}

function ColorGradedNode(props: ColorGradedNodeProps) {
    const {
        rating,
        children,
        className = "gb--label gb--review-ratings review-rating",
    } = props

    const style = {backgroundColor: calculateCompanyRatingBackgroundColor(rating) ?? "#7ED321"}
    return (
        <span className={className} style={style}>
            {children}
        </span>
    );
}

export default ColorGradedNode;