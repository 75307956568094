import Cookies from "universal-cookie";
import {v4} from 'uuid';
import addMinutes from "date-fns/addMinutes";
import getCookie from "./getCookie";

/**
 * Sets cookie for expireTime minutes
 */
export default function setSelfRefreshingCookie(name: string, expireTime: number) {
    const existingCookie = getCookie(name, false)
    const uuid = v4()
    const now = new Date()
    const expires = addMinutes(now, expireTime)

    return {
        setCookie: () => new Cookies().set(name, existingCookie ?? uuid, {expires, path: "/"}),
        uuid,
    }
}