import {calculateTravelTime} from "@Route/functions/calculateTravelTime";
import IntermodalRouteWithDetailsType from "@Intermodal/types/IntermodalRouteWithDetails.type";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";

type sortRoutesFunction = (
    a: RouteWithAddedPropsAndPricesType | IntermodalRouteWithDetailsType,
    b: RouteWithAddedPropsAndPricesType | IntermodalRouteWithDetailsType
) => number

export function sortByTravelTime(): sortRoutesFunction {
    return function sort(a, b) {
        const travelTimeFirstRoute = calculateTravelTime(a)
        const travelTimeSecondRoute = calculateTravelTime(b)

        let comparison = 0;
        if (travelTimeFirstRoute > travelTimeSecondRoute) {
            comparison = 1;
        } else if (travelTimeFirstRoute < travelTimeSecondRoute) {
            comparison = -1;
        }

        return comparison
    }
}