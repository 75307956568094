import React from 'react';
import useGetByFerrySupportedLocale from "@GetByFerry/hooks/useGetByFerrySupportedLocale";
import {useTranslation} from "@Translation/hooks/useTranslation";
import GetByFerryBanner from "@SearchResults/features/banners/components/GetByFerryBanner";
import ParamsType from "@SearchResults/types/params/Params.type";

type GetByFerryBannerProps = {
    params: ParamsType
}

function GetByFerrySearchResultsBanner({params}: GetByFerryBannerProps) {
    const {
        departureStation: {
            slug: departureSlug,
            id: departureId,
            name: departureName,
        },
        destinationStation: {
            slug: destinationSlug,
            id: destinationId,
            name: destinationName,
        },
        date,
    } = params

    const text = useTranslation("ferry_banner.station_to_station", {
        departureStation: departureName,
        destinationStation: destinationName,
    })
    const ctaText = useTranslation("ferry_banner.book_now")
    const supportedLocale = useGetByFerrySupportedLocale()

    const portTranslations = {
        it: 'tratte-dei-traghetti',
        de: 'fahrrouten',
        hr: 'brodske-linije',
        en: 'ferry-routes',
        "en-gb": 'ferry-routes',
        "en-us": 'ferry-routes',
        fr: 'routes-des-ferries',
    }

    const ferryRoutes = portTranslations[supportedLocale]
    const stations = `${departureSlug}-${departureId}/${departureSlug}-${destinationSlug}-${destinationId}`
    const href = `//getbyferry.com/${supportedLocale}/${ferryRoutes}/${stations}/${date}`

    return (
        <GetByFerryBanner href={href} text={text} ctaText={ctaText}/>
    );
}

export default GetByFerrySearchResultsBanner;