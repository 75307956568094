import {activatePopup as handlePopup} from "@Reducers/pageSlice";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import PopupType from "@Popups/types/Popup.type";

/**
 * Handles popup interaction
 */
export default function useActivatePopup() {
    const dispatch = useAppDispatch()

    const activatePopup = (params: PopupType) => {
        dispatch(handlePopup(params))
    }

    return {
        activatePopup,
    }
}