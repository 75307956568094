import React, {ReactNode, useEffect} from 'react';
import {useScroll} from "@Scroll/hooks/useScroll";

/**
 * Container element that will get scrolled to if it's mounted
 */

interface ScrollToElementProps {
    condition?: any,
    children: ReactNode,
    style?: React.CSSProperties
}

function ScrollToElement({condition, children, style}: ScrollToElementProps) {
    const {executeScroll, elRef} = useScroll({})
    useEffect(executeScroll, [condition])

    return (
        <div ref={elRef} style={style}>
            {children}
        </div>
    )
}

export default ScrollToElement;