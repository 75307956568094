import React, {ReactNode} from 'react';
import Translation from "@Translation/components/Translation";

interface NoResultsNoticeContainerProps {
    title: string,
    smallTitle?: boolean,
    content: string,
    children?: ReactNode,
}

const NoResultsNoticeContainer = ({title, smallTitle, content, children}: NoResultsNoticeContainerProps) => (
    <div className="collect-email">
        {smallTitle ? (
            <h3>
                <Translation translationKey={title}/>
            </h3>
        ) : (
            <h2>
                <Translation translationKey={title}/>
            </h2>
        )}
        <Translation translationKey={content}/>
        {children}
    </div>
);

export default NoResultsNoticeContainer;