import {useLazyGetTicketsQuery, useLoadMoreTicketsMutation} from "@MyProfile/API/myProfileApi";
import {useEffect, useState} from "react";
import {useScroll} from "@Scroll/hooks/useScroll";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {setShowArchivedFilter} from "@MyProfile/reducers/userProfileSlice";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";

export default function useGetValidTickets() {
    const dispatch = useAppDispatch()
    const showValid = useAppSelector((state) => state.userProfile.filter.showValid)

    const [page, setPage] = useState(1)

    const {elRef, executeScroll} = useScroll({block: "start"})

    const [fetchValidTickets, {data, isLoading}] = useLazyGetTicketsQuery()
    const [fetchMoreValidTickets, {isLoading: moreDataLoading}] = useLoadMoreTicketsMutation()

    useEffect(() => {
        if (elRef.current && !isLoading) {
            executeScroll()
        }
    }, [isLoading])

    // On initial render, or if data was changed and data is empty (on clearing state) fetch first batch of tickets
    useEffect(() => {
        if (!data && showValid) {
            fetchValidTickets({page, showValid})
        }
    }, [data, showValid])

    useEffect(() => {
        if (!isLoading && data && data.tickets.length === 0) {
            dispatch((setShowArchivedFilter(true)))
        }
    }, [data])

    // if page changes and data exists, fetch more valid tickets
    useEffect(() => {
        if (!!data && showValid) {
            fetchMoreValidTickets({page, showValid})
        }
    }, [page])

    return {
        data,
        elRef,
        page,
        setPage,
        loading: isLoading,
        batchLoading: moreDataLoading,
    }
}