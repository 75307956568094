import useCompany from "@Route/features/company/hooks/useCompany";
import RouteType from "@Route/types/Route.type";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";

export default function useFilterDisallowedMixedReturnRoutes(singleSelectedRoute: RouteWithAddedPropsAndPricesType) {
    const {getCompany} = useCompany()

    const filterRoutesBasedOnCompanyRules = (returnRoutes: RouteType[]) => {
        if (singleSelectedRoute?.companyId) {
            const {
                id,
                companyRules: {
                    allowsMixedReturnRouteCompanies,
                },
            } = singleSelectedRoute.company

            if (!allowsMixedReturnRouteCompanies) {
                return returnRoutes.filter((route) => route.companyId === id && route)
            }

            if (!singleSelectedRoute.status.allowsMixedReturns) {
                return returnRoutes.filter((route) => route.status.providerId === singleSelectedRoute.status.providerId && route)
            }

            return returnRoutes.filter((route) => {
                const {
                    companyRules: {
                        allowsMixedReturnRouteCompanies,
                    },
                } = getCompany(route.companyId)

                if (allowsMixedReturnRouteCompanies) {
                    return route
                }
            })
        }

        return returnRoutes
    }

    return {
        filterRoutesBasedOnCompanyRules,
    }
}