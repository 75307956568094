import React, {ReactNode} from 'react';
import Row from "@Containers/components/Row";

interface ToggleTagProps {
    label: string | ReactNode,
    onClick: (any?) => any,
    value: any,
    active?: boolean,
    disabled?: boolean,
    activeClass?: string,
    inactiveClass?: string,
    icon?: ReactNode,
    onBlur?: (event) => void,
}

const ToggleTag = (props: ToggleTagProps) => {
    const {
        label,
        onClick,
        value,
        active = false,
        disabled = false,
        activeClass = 'gb--tag gb--tag-green',
        inactiveClass = 'gb--tag gb--tag-grey',
        icon,
        onBlur
    } = props

    return (
        <button
            type="button"
            onClick={() => onClick(value)}
            className={active ? activeClass : inactiveClass}
            disabled={disabled}
            onBlur={onBlur}
        >
            {icon}
            {label}
        </button>
    );
}

export default ToggleTag;