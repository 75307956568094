import React from 'react';
import Accordion from "react-bootstrap/Accordion";
import VoucherType from "@MyVoucher/types/Voucher.type";
import Voucher from "@MyVoucher/components/Voucher";

interface VouchersProps {
    vouchers: VoucherType[],
}

function Vouchers({vouchers}: VouchersProps) {
    return (
        <div className="vouchers">
            <Accordion>
                {vouchers.map((voucher) => (
                    <Voucher
                        key={voucher.id}
                        voucher={voucher}
                    />
                ))}
            </Accordion>
        </div>
    );
}

export default Vouchers;