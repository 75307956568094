import IconBusFull from "@Icons/IconBusFull";
import IconFerryFull from "@Icons/IconFerryFull";
import IconTrainFull from "@Icons/IconTrainFull";
import IconPlaneTab from "@Icons/IconPlaneTab";
import React from "react";
import getRouteTransferType from "@Route/features/transportType/functions/getRouteTransferType";

export default function useGetTransferTypeLabelConfiguration(routeTransferType) {
    const iconStyle = {
        fill: "white",
        height: "11px",
        width: "12px",
    }

    const icons = {
        bus: {
            label: 'Bus ticket',
            color: "#7ed321",
            backgroundColor: "rgb(242, 250, 232)",
            icon: <IconBusFull {...iconStyle}/>,
        },
        ferry: {
            label: 'Ferry ticket',
            color: "#4a79e2",
            backgroundColor: "rgb(240, 245, 255)",
            icon: <IconFerryFull {...iconStyle}/>,
        },
        train: {
            label: 'Train ticket',
            color: "#f5a623",
            backgroundColor: "rgb(254, 246, 233)",
            icon: <IconTrainFull {...iconStyle}/>,
        },
        plane: {
            label: 'Plane ticket',
            color: "#f5a623",
            backgroundColor: "rgb(254, 246, 233)",
            icon: <IconPlaneTab {...iconStyle}/>,
        },
    }

    return icons[getRouteTransferType(routeTransferType ?? 1)]
}