import {useEffect} from "react";

/**
 * Sets timeout that executes a onTimeout function upon timeout expiry, refreshes duration on deps changes
 */
export default function useTimeout(onTimeout: (any?) => any, timeout: number, deps: any) {
    useEffect(() => {
        const timer = setTimeout(() => {
            onTimeout()
        }, timeout)
        return () => clearTimeout(timer);
    }, [deps]);
}