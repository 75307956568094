import hr from 'date-fns/esm/locale/hr'
import en from 'date-fns/esm/locale/en-GB'
import de from 'date-fns/esm/locale/de'
import cs from 'date-fns/esm/locale/cs'
import es from 'date-fns/esm/locale/es'
import it from 'date-fns/esm/locale/it'
import pt from 'date-fns/esm/locale/pt'
import sr from 'date-fns/esm/locale/sr-Latn'
import fr from 'date-fns/esm/locale/fr'
import hu from 'date-fns/esm/locale/hu'
import nl from 'date-fns/esm/locale/nl'
import pl from 'date-fns/esm/locale/pl'
import sl from 'date-fns/esm/locale/sl'
import sv from 'date-fns/esm/locale/sv'

export default function setLocale(): Locale {
    const locale = window.currentLocale
    const allLocales = {
        [`${locale === 'hr'}`]: hr,
        [`${locale === 'en'}`]: en,
        [`${locale === 'de'}`]: de,
        [`${locale === 'cs'}`]: cs,
        [`${locale === 'bs'}`]: hr,
        [`${locale === 'es'}`]: es,
        [`${locale === 'it'}`]: it,
        [`${locale === 'pt'}`]: pt,
        [`${locale === 'sr'}`]: sr,
        [`${locale === 'fr'}`]: fr,
        [`${locale === 'hu'}`]: hu,
        [`${locale === 'nl'}`]: nl,
        [`${locale === 'pl'}`]: pl,
        [`${locale === 'sl'}`]: sl,
        [`${locale === 'sv'}`]: sv,
        [`${locale === 'en-gb'}`]: en,
        [`${locale === 'en-us'}`]: en,
        [`${locale === 'es-ar'}`]: es,
        [`${locale === 'es-mx'}`]: es,
        [`${locale === 'pt-br'}`]: pt,
    }

    return allLocales.true || en
}