import React, {useEffect} from "react";
import useUpdatePassengerField from "@CheckOrder/hooks/useUpdatePassengerField";
import {useInput} from "@Hooks/dispatch/useInput";
import useUpdateOnChange from "@Hooks/lifecycle/useUpdateOnChange";
import CheckOrderErrorLabel from "@CheckOrder/components/MainSection/CheckOrderErrorLabel";
import {useTranslation} from "@Translation/hooks/useTranslation";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import CheckOrderInputContainer from "@CheckOrder/components/MainSection/CheckOrderInputContainer";

interface PassengerInputFieldProps {
    name: string,
    labelText: string,
    errorText?: string,
    value?: string,
    showErrorText: boolean,
    type?: string,
    index: number,
    min?: string,
    max?: string,
    disabled?: boolean,
}

function PassengerInputField(props: PassengerInputFieldProps) {
    const {
        name,
        labelText,
        errorText,
        value,
        showErrorText,
        type,
        index,
        min,
        max,
        disabled,
    } = props;

    const {valueRequired} = useTranslation('sys.required')
    const {passengerDataUpdating} = useAppSelector((state) => state?.checkOrder)
    const {user} = useAppSelector((state) => state?.userProfile)
    const input = useInput(value)
    const updateField = useUpdatePassengerField(index, name, input.value)
    useUpdateOnChange(value, input.value, updateField)

    useEffect(() => {
        input.updateValue(value)
    }, [value && user?.email])

    return (
        <CheckOrderInputContainer label={labelText}>
            <input
                type={type || 'text'}
                name={name}
                value={input.value}
                onChange={input.onChange}
                aria-describedby={name}
                required
                min={min}
                max={max}
                disabled={disabled ?? passengerDataUpdating}
            />
            {showErrorText && <CheckOrderErrorLabel error={errorText || valueRequired}/>}
        </CheckOrderInputContainer>
    )
}

export default PassengerInputField;