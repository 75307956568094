import React from 'react';
import Router from "@Routing/components/Router";
import ErrorBoundary from "@/features/errors/components/ErrorBoundary";
import Popup from "@Popups/components/Popup";
import {useAppSelector} from "@Hooks/selector/useAppSelector";

function Routing() {
    const {activePopup} = useAppSelector((state) => state?.page)
    return (
        <ErrorBoundary shouldReload>
            <Router/>
            {activePopup && (
                <Popup
                    activePopup={activePopup}
                />
            )}
        </ErrorBoundary>
    )
}

export default Routing
