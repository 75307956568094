import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconMinibus(props: IconType) {
    const {
        width = '35',
        height = '35',
        viewBox = '0 0 35 35',
        title = 'IconMinibus',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none" fillRule="evenodd" transform="translate(0 7)">
                    <g fillRule="nonzero">
                        <path
                            d="m2.33787289 0h22.23954221c.6876463 0 1.34042.30214879 1.7846255.82604748 2.8658457 3.37999509 4.8879807 5.71860986 6.066405 7.01584431 1.0498094 1.15565248 1.8170345 2.19213601 2.3016753 3.10945061l-.000006.0000031c.1772493.3354926.2698851.7090521.2698851 1.0883286v3.5643594c0 .6559663-.2766522 1.2816352-.7621886 1.7237447l-.5272683.4801089c-.4306985.3921763-.9926728.6095887-1.5756844.6095887h-29.79698581c-1.29117154 0-2.33787289-1.044669-2.33787289-2.3333334v-13.75080907c0-1.28866441 1.04670135-2.33333333 2.33787289-2.33333333z"
                            fill="#eee"
                        />
                        <path
                            d="m0 11.8932039h34.9366302c0 4.8578959.5765975 6.5242719-1.7487544 6.5242719-10.2848475 0-20.569695 0-30.85454247 0-1.28866441 0-2.33333333-1.044669-2.33333333-2.3333334 0-1.3969795 0-2.793959 0-4.1909385z"
                            fill="#aaa"
                        />
                        <path
                            d="m.00000011 12.9126216h2.04827399v3.1941749c-1.13123049 0-2.04827399-.9432448-2.04827399-2.1067962 0-.3624595 0-.7249191 0-1.0873787z"
                            fill="#d0021b"
                            transform="matrix(-1 0 0 -1 2.048274 29.019418)"
                        />
                        <path
                            d="m0 15.2233009h2.048274v3.1941749c-1.13123049 0-2.048274-.9432448-2.048274-2.1067962 0-.3624595 0-.724919 0-1.0873787z"
                            fill="#f5a623"
                        />
                    </g>
                    <path
                        d="m33 13h2v3c-.1744325 0-.3488651 0-.5232975 0-.8155603 0-1.4767025-.6541088-1.4767025-1.4609929 0-.5130024 0-1.0260047 0-1.5390071z"
                        fill="#f5a623"
                        transform="matrix(1 0 0 -1 0 29)"
                    />
                    <path
                        d="m34.5396173 15.4879613c.6538462.6472317.4603827 2.0235601.4603827 3.000166-.3692307 0-.7384615 0-1.1076923 0-1.5973774 0-2.8923077-1.5509692-2.8923077-3.4641867 1.75 0 2.8857712-.183211 3.5396173.4640207z"
                        fill="#4a4a4a"
                        fillRule="nonzero"
                        transform="matrix(1 0 0 -1 0 33.488128)"
                    />
                    <rect fill="#6a9fff" height="6.592233" rx=".777778" width="4.096548" x="3.171521" y="3.058253"/>
                    <rect height="6.592233" rx=".777778" width="4.096548" x="3.171521" y="3.058253"/>
                    <rect fill="#6a9fff" height="6.592233" rx=".777778" width="4.096548" x="8.457389" y="3.058253"/>
                    <rect height="6.592233" rx=".777778" width="4.096548" x="8.457389" y="3.058253"/>
                    <rect fill="#6a9fff" height="6.592233" rx=".777778" width="4.096548" x="13.743258" y="3.058253"/>
                    <rect height="6.592233" rx=".777778" width="4.096548" x="13.743258" y="3.058253"/>
                    <path
                        d="m20.6075453 3.05825242h3.9800838c.4679902 0 .9096162.22284978 1.1960331.60353265l2.3776412 3.16017416c.2050532.27254075.3163126.60740382.3163126.95202291v.67244917c0 .66498071-.5240992 1.20405413-1.1706083 1.20405413h-6.6994624c-.8352455 0-1.5123457-.69644594-1.5123457-1.55555555v-3.48112191c0-.8591096.6771002-1.55555556 1.5123457-1.55555556z"
                        fill="#6a9fff"
                    />
                    <path
                        d="m20.6075453 3.05825242h3.9800838c.4679902 0 .9096162.22284978 1.1960331.60353265l2.3776412 3.16017416c.2050532.27254075.3163126.60740382.3163126.95202291v.67244917c0 .66498071-.5240992 1.20405413-1.1706083 1.20405413h-6.6994624c-.8352455 0-1.5123457-.69644594-1.5123457-1.55555555v-3.48112191c0-.8591096.6771002-1.55555556 1.5123457-1.55555556z"
                    />
                    <ellipse
                        cx="25.777509"
                        cy="16.718446"
                        fill="#4a4a4a"
                        fillRule="nonzero"
                        rx="3.634034"
                        ry="3.737864"
                    />
                    <ellipse cx="25.777509" cy="16.718446" fill="#6a9fff" fillRule="nonzero" rx="1.9822" ry="2.038835"/>
                    <ellipse
                        cx="6.805556"
                        cy="16.718446"
                        fill="#4a4a4a"
                        fillRule="nonzero"
                        rx="3.634034"
                        ry="3.737864"
                    />
                    <ellipse cx="6.805556" cy="16.718446" fill="#6a9fff" fillRule="nonzero" rx="1.9822" ry="2.038835"/>
                </g>
            )}
        />

    );
}

export default IconMinibus;
