import React from 'react';
import ErrorBoundary from "@/features/errors/components/ErrorBoundary"
import PortalPages from "./PortalPages";
import SidebarPortalContainer from "@Sidebar/components/SidebarPortalContainer";

const PortalPagesContainer = () => (
    <>
        <SidebarPortalContainer/>
        <ErrorBoundary shouldReload={false}>
            <PortalPages/>
        </ErrorBoundary>
    </>
);

export default PortalPagesContainer