import {useEffect, useRef} from "react";
import setSelfRefreshingCookie from "@Cookies/functions/setSelfRefreshingCookie";
import useDispatchTrackingEvent from "./useDispatchTrackingEvent";
import useEventTrackingState from "./useEventTrackingState";
import {useAppSelector} from "@Hooks/selector/useAppSelector";

export default function useTrackingEventProcessor() {
    const {cookiesConsent} = useAppSelector((state) => state?.page)
    const {dispatchEvent} = useDispatchTrackingEvent()
    const {setCookie, uuid} = setSelfRefreshingCookie("visitor_id", 30 * 24 * 60)
    const eventRef = useRef(null)
    const {eventTrackingActive, eventsTrackingAlwaysActive} = useEventTrackingState()

    const setCookies = () => {
        if (eventTrackingActive) {
            setCookie()
        }
    }

    useEffect(setCookies, [cookiesConsent])

    const assignVisitorIdToEventIfNoneExists = (event) => {
        if (!event?.visitorId) {
            eventRef.current = {
                ...event,
                visitorId: uuid,
            }
        }
    }

    const handleEvent = (event) => {
        if (event) {
            eventRef.current = event
            if (eventTrackingActive) {
                assignVisitorIdToEventIfNoneExists(event)

                return dispatchEvent(eventRef.current)
            }
        }
    }

    useEffect(() => {
        if (eventRef.current && !eventsTrackingAlwaysActive) {
            assignVisitorIdToEventIfNoneExists(eventRef.current)
            handleEvent(eventRef.current)
        }
    }, [cookiesConsent, eventRef])

    return (event) => handleEvent(event)
}