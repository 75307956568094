import React from 'react'
import CarouselImage from "@Pages/mobileApp/features/bookingSection/components/CarouselImage";
import Row from "@Containers/components/Row";
import BookingInfoType from "@Pages/mobileApp/features/bookingSection/types/bookingInfo.type";
import Column from "@Containers/components/Column";
import MobileSwiper from "@Features/mobileSwiper/components/MobileSwiper";
import useIsMobile from "@Hooks/selector/useIsMobile";
import useIsSmallTablet from "@Hooks/selector/useIsSmallTablet";

interface ImagesCarouselProps {
    orderedData: BookingInfoType[],
    centralPosition: number
}

const ImagesCarousel = ({orderedData, centralPosition}: ImagesCarouselProps) => {
    const isMobile = useIsMobile()
    const isSmallTablet = useIsSmallTablet()

    return (
        <Column center>
            <Row
                center
                className="image-carousel-container"
            >
                {orderedData.map((item, index) => (
                    <CarouselImage
                        imagePath={item.imagePath}
                        imagePosition={index}
                        centralPosition={centralPosition}
                        key={item.index}
                    />
                ))}
            </Row>
            <div
                className={`mobile-app-landing-page-line-divider mobile-app-landing-page-line-divider-${orderedData[centralPosition].color}`}
            />
            {(isMobile || isSmallTablet) && (
                <span className="gb--emphasize gb--emphasize-default feature-card-title image-carousel-title">
                    {orderedData[centralPosition].label}
                </span>
            )}
        </Column>
    )
}

export default ImagesCarousel