export default class ClientErrorModel {
    /**
     * @param {string} [props.errorMessage] - Error string
     * @param {object} [props.metadata] - Metadata event model
     * @param {string} [props.stackTrace]
     * @param {string} [props.additionalInfo]
     */
    constructor(props) {
        this.errorMessage = props.errorMessage
        this.url = window.location.href
        this.viewPort = `${window.visualViewport.width} x ${window.visualViewport.height}`
        this.metadata = props.metadata
        this.stackTrace = props?.stackTrace
        this.additionalInfo = props?.additionalInfo
    }
}