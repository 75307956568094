import React from "react";
import Column from "@Containers/components/Column";
import useFilter from "@MyProfile/features/filter/hooks/useFilter";
import MyProfileFilterTitle from "@MyProfile/features/filter/components/MyProfileFilterTitle";
import MyProfileFilterSubtitle from "@MyProfile/features/filter/components/MyProfileFilterSubtitle";
import MyProfileFilterRow from "@MyProfile/features/filter/components/MyProfileFilterRow";

function MyProfileFilter() {
    const filters = useFilter()

    return (
        <div className="gb--sidebar-container my-profile-filter">
            <div className="gb--sidebar-section">
                <MyProfileFilterTitle/>
                <Column className="gb--sidebar-section-content">
                    <MyProfileFilterSubtitle/>
                    {filters.map((filter) => (
                        <MyProfileFilterRow
                            label={filter.label}
                            checked={filter.checked}
                            disabled={filter.disableChange}
                            setFilter={filter.setFilter}
                            key={filter.label}
                        />
                    ))}
                </Column>
            </div>
        </div>
    );
}

export default MyProfileFilter