import {useTranslation} from "@Translation/hooks/useTranslation";
import {capitalize} from "@String/capitalize";
import React from "react";
import VoucherRouteRestrictionType from "@MyVoucher/types/VoucherRouteRestriction.type";

interface VoucherRouteRestrictionProps {
    routes: VoucherRouteRestrictionType[];
}

function VoucherRouteRestrictions({routes}: VoucherRouteRestrictionProps) {
    const tripLabel = useTranslation('sys.trip')
    const {
        operated_by: operatedByLabel,
        departing_at: departingAtLabel,
    } = useTranslation('my_profile.my_vouchers')

    return (
        <>
            {routes.map((route) => (
                <React.Fragment key={route.routeId}>
                    {`${capitalize(tripLabel)}: `}
                    <span className="gb--emphasize-info">{route.startStation}</span>
                    &nbsp;-&nbsp;
                    <span className="gb--emphasize-info">{route.endStation}</span>
                    {`, ${departingAtLabel} `}
                    <span className="gb--emphasize-info">{route.startTime}</span>
                    {`, ${operatedByLabel}: `}
                    <span className="gb--emphasize-info">{route.companyName}</span>
                    .&nbsp;
                </React.Fragment>
            ))}
        </>
    );
}

export default VoucherRouteRestrictions;
