import React from 'react'
import Row from "@Containers/components/Row";
import SourcedImage from "@Images/components/SourcedImage";
import useIsSmallTablet from "@Hooks/selector/useIsSmallTablet";
import Column from "@Containers/components/Column";
import useIsMobile from "@Hooks/selector/useIsMobile";
import MobileAppQR from "@Pages/mobileApp/components/MobileAppQR";
import MobileAppFeatures from "@Pages/mobileApp/features/appOverview/MobileAppFeatures";
import AppIntro from "@Pages/mobileApp/features/appOverview/AppIntro";

const AppOverview = () => {
    const isTablet = useIsSmallTablet()
    const isMobile = useIsMobile()

    return (
        <Column center className="mobile-app-landing-page-section gb-container app-overview-section">
            <AppIntro/>
            <Row>
                {!isMobile && (
                    <div className="app-overview-img">
                        <SourcedImage
                            filePath="images/mobile-app-landing-page/all_in_one_app_hero_img"
                            fileType="png"
                        />
                        {!isTablet && (
                            <MobileAppQR className="app-qr-overlay"/>
                        )}
                    </div>
                )}
                <MobileAppFeatures/>
            </Row>
        </Column>

    )
}

export default AppOverview