import Translation from "@Translation/components/Translation";
import Dates from "@SearchResults/components/Popover/StationsPopover/Dates";

interface ListOfDatesProps {
    dates: string[],
    translationKey: string,
}

const ListOfDates = ({dates, translationKey}: ListOfDatesProps) => (
    <div>
        <hr/>
        <span className="gb--emphasize gb--emphasize-info">
            <Translation translationKey={translationKey}/>
        </span>
        <br/>
        <Dates dates={dates}/>
    </div>
)

export default ListOfDates