import {useParams} from "react-router";
import {RouteTransferTypeValuesType} from "@Route/features/transportType/types/routeTransferType.type";
import useIsFerry from "@GetByFerry/hooks/useIsFerry";

export default function useActiveTabFromParams() {
    const {tab} = useParams() as { tab: RouteTransferTypeValuesType }
    const isFerry = useIsFerry()
    const fallbackTab = isFerry ? 'ferry' : 'bus' as RouteTransferTypeValuesType
    const activeTab: RouteTransferTypeValuesType = tab ?? fallbackTab

    return activeTab
}