import {useDispatch} from "react-redux";
import {setCookieState} from "@Reducers/pageSlice";
import useCookie from "./useCookie";

/**
 * Handles combined cookies interaction
 */
export default function useCookies() {
    const dispatch = useDispatch()
    const cookiesConsent = useCookie("cookiesConsent")
    const eventCookie = useCookie("event_cookie")
    const hotjarCookie = useCookie("performance_cookies")

    const acceptAllCookies = () => {
        cookiesConsent.set(true)
        hotjarCookie.set(hotjarCookie.isActive)
        eventCookie.set(eventCookie.isActive)
        dispatch(setCookieState({cookiesConsent: true}))
    }

    return {
        cookiesConsent,
        eventCookie,
        hotjarCookie,
        acceptAllCookies,
    }
}