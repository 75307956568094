import React from 'react';
import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "@/types/Icon.type";

interface IconVoucherProps extends IconType {
    active?: boolean,
}

const IconVoucher = (props: IconVoucherProps) => {
    const {
        width = '35',
        height = '35',
        fill = '#7ed321',
        viewBox = '0 0 35 35',
        title = 'IconVoucher',
        active,
        ...rest
    } = props
    const activeFill = active ? fill : '#b5b7d1';
    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g
                    fill="none"
                    fillRule="evenodd"
                    transform="matrix(-.57357644 .81915204 .81915204 .57357644 18.00255 -.809833)"
                >
                    <g fillRule="nonzero">
                        <path
                            d="m10.3947368 1.05263158-6.99999996 4.57894737c-1.31578947.60526316-.60526316 2.39473684 0 3.71052631l4.71052632 4.71052634 16.36842104 6.0526316 4.4736842-12.36842109zm-2.15789469 8.28947368c-1.02631579-.36842105-1.55263158-1.5-1.18421053-2.52631579s1.5-1.55263158 2.52631579-1.18421052c1.02631583.36842105 1.55263153 1.5 1.18421053 2.52631579-.3684211 1.02631579-1.50000001 1.57894737-2.52631579 1.18421052"
                            fill="#fff"
                        />
                        <path
                            d="m6.9349999 7.48631579c0-.22263158.03789484-.44894737.11736852-.67052632.28894737-.80447368 1.04657895-1.30157894 1.85473684-1.30157894.22289474 0 .44973685.03789473.67157895.11736842.80657899.28947368 1.30447369 1.05078947 1.30160039 1.86157894.0010312.22184211-.0366004.44631579-.1171267.66473685-.2871053.8-1.0381579 1.31210526-1.84131579 1.31210526-.22736843 0-.45868422-.04105263-.685-.12789474-.80473685-.28894737-1.30210527-1.04710526-1.30184221-1.85578947m3.4597369-6.43368421-6.99999996 4.57894737c-.5468421.25157894-.74342131.70763158-.74342131 1.24894737 0 .76157894.3897371 1.69236842.74342131 2.46157894l4.71052632 4.71052634 5.69342104 2.1052631h12.0960526l3.0526316-8.42105259z"
                            fill="#e6e6ef"
                        />
                        <path
                            d="m24.4736842 20.9210526c-.1052631 0-.1842105-.0263158-.2631579-.0526315l-16.36842104-6.0526316c-.10526315-.0526316-.21052631-.1052632-.28947368-.1842106l-4.71052632-4.71052627c-.15789473-.15789474-.2368421-.34210526-.2368421-.55263158v-3.7368421c0-.2631579.13157895-.52631579.36842105-.65789474l7-4.60526316c.21052629-.13157894.47368419-.15789473.71052629-.07894737l18.5526316 6.71052632c.1842105.07894737.3684211.21052632.4473684.39473684.0789474.18421053.1052632.39473684.0263158.60526316l-4.4736842 12.3684211c-.0789474.2105263-.2105263.368421-.4210526.4473684-.1052632.0789473-.2368421.1052631-.3421053.1052631zm-15.92105262-7.5263158 15.44736842 5.7105264 3.9473684-10.89473688-17.4473684-6.31578948-6.31578947 4.15789474v2.97368421z"
                            fill="#070c63"
                        />
                        <path
                            d="m8.10526316.92105263-4.71052632 4.71052632c-1.02631579 1.02631579-1.02631579 2.68421052 0 3.71052631l4.71052632 4.71052634h19.73684214v-13.15789476h-19.73684214zm.78947368 8.55263158c-1.07894737 0-1.97368421-.89473684-1.97368421-1.97368421s.89473684-1.97368421 1.97368421-1.97368421 1.97368426.89473684 1.97368426 1.97368421-.8684211 1.97368421-1.97368426 1.97368421z"
                            fill={activeFill}
                        />
                        <path
                            d="m27.8421053 14.8684211h-19.73684214c-.21052632 0-.42105263-.0789474-.55263158-.2368422l-4.71052632-4.71052627c-.65789473-.65789474-1-1.5-1-2.42105263s.36842106-1.76315789 1-2.42105263l4.71052632-4.71052632c.15789474-.15789473.34210526-.2368421.55263158-.2368421h19.73684214c.4473684 0 .7894736.34210526.7894736.78947368v13.15789477c0 .4473684-.3421052.7894737-.7894736.7894737zm-19.39473688-1.5789474h18.63157898v-11.57894738h-18.63157898l-4.5 4.47368421c-.34210526.34210526-.52631579.81578947-.52631579 1.31578947s.18421053.94736842.52631579 1.28947368z"
                            fill="#070c63"
                        />
                        <path
                            d="m22.3333718 8.21253468h-6.5789474c-.4473684 0-.7894737-.34210527-.7894737-.78947369s.3421053-.78947368.7894737-.78947368h6.5789474c.4473684 0 .7894736.34210526.7894736.78947368s-.3421052.78947369-.7894736.78947369z"
                            fill="#fff"
                            transform="matrix(.96592583 -.25881905 .25881905 .96592583 -1.272324 5.181858)"
                        />
                    </g>
                    <circle cx="19.819152" cy="4.573576" fill="#fff" r="1"/>
                    <circle cx="18.296177" cy="10.235504" fill="#fff" r="1"/>
                    <path
                        d="m1 16.1842105c-.21052632 0-.39473684-.0789473-.55263158-.2368421-.31578947-.3157895-.31578947-.8157895 0-1.1052631l2.39473684-2.3947369c.31578948-.3157895.81578948-.3157895 1.10526316 0 .31578947.3157895.31578947.8157895 0 1.1052632l-2.39473684 2.3947368c-.13157895.1578948-.34210526.2368421-.55263158.2368421z"
                        fill="#070c63"
                        fillRule="nonzero"
                    />
                </g>
            )}
        />
    );
}

export default IconVoucher;
