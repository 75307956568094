import React from 'react';
import Translation from "@Translation/components/Translation";
import {toggleLoginModal} from "@MyProfile/reducers/userProfileSlice";
import IconLogin from "@Icons/IconLogin";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";

function LoginButton() {
    const dispatch = useAppDispatch()
    const onClick = () => dispatch(toggleLoginModal())

    return (
        <button
            type="button"
            onClick={onClick}
        >
            <IconLogin
                width="30"
                height="24"
                style={{verticalAlign: 'middle'}}
            />
            <Translation translationKey="user.sign_in"/>
        </button>
    );
}

export default LoginButton;