import Row from "@Containers/components/Row";
import React from "react";
import IconCheckMarkCircle from "@Icons/IconCheckMarkCircle";
import IconTimes from "@Icons/IconTimes";
import {useTranslation} from "@Translation/hooks/useTranslation";
import useIsMobile from "@Hooks/selector/useIsMobile";
import TicketStatusType from "@MyTicket/types/TicketStatus.type";
import AncillariesType from "@CheckOrder/types/Ancillaries.type";
import AncillariesDetails from "@MyTicket/features/ticketDetails/components/AncillariesDetails";

interface TicketTypeSectionProps {
    ticketStatus: TicketStatusType,
    ancillariesData: AncillariesType
}

function TicketTypeSection({ticketStatus, ancillariesData}: TicketTypeSectionProps) {
    const {
        isReturn,
        isIntermodal,
        isCancelled,
        isPastDeparture,
        isExchangedForVoucher,
        isApiPendingReservation,
        isApiReservationFailedToApprove,
    } = ticketStatus

    const {
        booking_info: bookingInfoLabel,
        outbound_booking_details: outboundBookingDetailsLabel,
        return_booking_details: returnBookingDetailsLabel,
        archived_with_refund: archivedWithRefundLabel,
        archived_with_voucher: archivedWithVoucherLabel,
        archived_past_departure: archivedPastDepartureLabel,
        api_reservation_pending: apiReservationPending,
        archived_failed_api_reservation: archivedFailedApiReservationLabel,
    } = useTranslation('my_profile.my_tickets')

    const validLabel = useTranslation('my_profile.filter.valid')

    const detailsTitle = () => {
        if (isReturn) {
            return returnBookingDetailsLabel
        }
        if (isIntermodal) {
            return bookingInfoLabel
        }
        return outboundBookingDetailsLabel
    }

    const ticketType = () => {
        if (isApiReservationFailedToApprove) {
            return ["orange", archivedFailedApiReservationLabel, <IconTimes fill="white"/>]
        }
        if (isCancelled) {
            return ["orange", archivedWithRefundLabel, <IconTimes fill="white"/>]
        }
        if (isExchangedForVoucher) {
            return ["orange", archivedWithVoucherLabel, <IconTimes fill="white"/>]
        }
        if (isPastDeparture) {
            return ["orange", archivedPastDepartureLabel, <IconTimes fill="white"/>]
        }
        if (isApiPendingReservation) {
            return ["green", apiReservationPending, <IconCheckMarkCircle fill="white"/>]
        }

        return ["green", validLabel, <IconCheckMarkCircle fill="white"/>]
    }

    const [ticketColor, ticketText, ticketIcon] = ticketType()

    const isMobile = useIsMobile()

    if (isMobile && !isIntermodal) {
        return (
            <>
                <Row justify className="ticket-details-section">
                    <div className="ticket-type">{detailsTitle()}</div>
                </Row>
                <Row>
                    {!isIntermodal && (
                        <AncillariesDetails
                            ancillariesData={ancillariesData}
                        />
                    )}
                </Row>
            </>
        )
    }
    return (
        <Row justify className="ticket-details-section">
            <div className="ticket-type">{detailsTitle()}</div>
            {!isIntermodal && (
                <>
                    <AncillariesDetails
                        ancillariesData={ancillariesData}
                    />
                    <div className={`ticket-status my-profile-tag gb--tag-${ticketColor}`}>
                        {ticketIcon}
                        {ticketText}
                    </div>
                </>
            )}
        </Row>
    );
}

export default TicketTypeSection