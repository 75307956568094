import TransfersVehicleType
    from "@SearchResults/features/banners/features/getByTransferBanner/types/TransfersVehicle.type";
import usePassengers from "@Hooks/selector/usePassengers";

// filter out only possible vehicles
// take the cheapest option
export default function useGetCheapestGbtVehicle(vehicles: TransfersVehicleType[]): TransfersVehicleType | undefined {
    const {passengers} = usePassengers()

    const adequateVehicles = vehicles.filter((vehicle) => (
        passengers <= vehicle.maxNumberOfPassengers
    ))

    return adequateVehicles[0]
}