import {ActionSheetPositionType} from "@MobileOverlays/types/ActionSheetPosition.type";

export default function setActionSheetSwipeClassName(position: ActionSheetPositionType) {
    const directionClassNames = {
        1: "gb--mobile-overlay__content-bottom-down",
        2: "gb--mobile-overlay__content-bottom-up",
        0: "gb--mobile-overlay__content-none",
    }

    return directionClassNames[position]
}