import React, {Fragment} from 'react';
import RouteNote from "@SearchResults/components/Notifications/RouteNote";
import RouteNoteType from "@Route/types/notes/RouteNote.type";

interface RouteNotesSectionProps {
    notes: RouteNoteType[]
}

function RouteNotesSection({notes}: RouteNotesSectionProps) {
    return (
        <>
            {notes.filter((note) => note.displayOnSearchResults === true && note).map((note, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Fragment key={index}>
                    <RouteNote note={note}/>
                    <hr/>
                </Fragment>
            ))}
        </>
    )
}

export default RouteNotesSection;