import React from 'react';
import Notification from "@Features/notification/components/Notification";
import IconExclamationCircle from "@Icons/IconExclamationCircle";
import RouteNoteType from "@Route/types/notes/RouteNote.type";

interface RouteNoteProps {
    note: RouteNoteType,
}

function RouteNote({note}: RouteNoteProps) {
    return (
        <Notification
            notificationIcon={<IconExclamationCircle fill={note.notificationType === 'warning' ? "#f03a47" : "#4a79e2"}/>}
            notificationType={note.notificationType === 'warning' ? "red" : "blue"}
            isBorder
            isRow
        >
            <span
                key={note.note}
                /* eslint-disable-next-line react/no-danger */
                dangerouslySetInnerHTML={{__html: note.note}}
            />
        </Notification>
    )
}

export default RouteNote;