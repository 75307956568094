import {
    DateAdditionalFieldType
} from "@CheckOrder/features/TicketAdditionalInfo/features/dateAdditionalField/types/DateAdditionalField.type";
import {isAfter, isBefore, isValid, parseISO} from "date-fns"
import parseDate from "@DateTime/functions/parseDate";
import addMissingZerosToDate
    from "@CheckOrder/features/TicketAdditionalInfo/features/dateAdditionalField/functions/addMissingZerosToDate";

export default function getDateFieldValidation(field: DateAdditionalFieldType) {
    const isDateFieldValid = (value: string) => {
        if (value === "" || !value) {
            return false
        }

        const newDateString = addMissingZerosToDate(value)

        return (
            isValid(parseISO(newDateString))
            && isBefore(parseISO(newDateString), parseDate(field.max.date))
            && isAfter(parseISO(newDateString), parseDate(field.min.date))
        )
    }

    return {
        isDateFieldValid,
    }
}