import {matchLongestValidDialCode} from "@Features/input/phoneField/functions/matchLongestValidDialCode";
import PhoneCountryType from "@CheckOrder/types/PhoneCountry.type";

export default function getCountryBasedOnNumberSetter(setPhoneCountryObject: (value: PhoneCountryType) => void, closePhoneCountrySelect: () => void) {
    const countryBasedOnNumber = (phoneNumber) => {
        const result = matchLongestValidDialCode(phoneNumber)
        if (result) {
            closePhoneCountrySelect()
            setPhoneCountryObject(result)
        }
    }
    return countryBasedOnNumber
}