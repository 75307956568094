import IconFolderArchived from "@Icons/IconFolderArchived";
import IconChevronDouble from "@Icons/IconChevronDouble";
import Button from "@Generic/Button";
import React from "react";
import {setShowArchivedFilter} from "@MyProfile/reducers/userProfileSlice";
import {useTranslation} from "@Translation/hooks/useTranslation";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";

interface AllValidResultsLoadedProps {
    description: string,
}

function MyProfileShowArchivedData({description}: AllValidResultsLoadedProps) {
    const dispatch = useAppDispatch()

    const {showArchived, showValid} = useAppSelector((state) => (state.userProfile.filter))

    const toggleArchivedFilterValue = () => {
        if (showValid !== false) {
            dispatch(setShowArchivedFilter(!showArchived))
        }
    }

    const {see_history: seeHistoryLabel} = useTranslation('my_profile')

    return (
        <Button
            onClick={toggleArchivedFilterValue}
            className="inline"
            additionalClasses="show-archived-cards-button"
        >
            <div className="gb--emphasize gb--emphasize-default archived-cards-description">
                <IconFolderArchived/>
                <span>{description}</span>
            </div>
            <div className="gb--emphasize-info see-history-prompt">
                <span>{seeHistoryLabel}</span>
                <IconChevronDouble chevronOrientation={showArchived === false ? "down" : "up"} height="15px"/>
            </div>
        </Button>
    );
}

export default MyProfileShowArchivedData