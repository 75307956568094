import React from 'react';
import {useTranslation} from "@Translation/hooks/useTranslation";
import useTransportTypeAwareTranslation from "@Translation/hooks/useTransportTypeAwareTranslation";
import StationWithDateTimesType from "@SearchResults/types/station/StationWithDateTimes.type";

interface BusStopPopoverProps {
    /** Array of bus change data */
    busChange: StationWithDateTimesType[],
}

interface TimeWithoutSecondsProps {
    time: string,
}

function BusStopPopover({busChange}: BusStopPopoverProps) {
    const busChangeMessage = useTransportTypeAwareTranslation("sys.bus_change_message")
    const station = useTranslation("sys.station")
    const arrival = useTranslation("sys.arrival")
    const departure = useTranslation("sys.departure")

    const TimeWithoutSeconds = ({time}: TimeWithoutSecondsProps) => {
        const splitTime = time.split(":")
        return (
            <span>
                {`${splitTime[0]}:${splitTime[1]}`}
            </span>
        )
    }

    return (
        <div className="gb--changes-list">
            <p>
                {busChangeMessage}
            </p>
            <div className="gb--column">
                <div className="gb--row gb--row-middle gb--emphasize gb--emphasize-success">
                    <span className="gb--changes-list__stations">
                        {station}
                    </span>
                    <span>
                        {arrival}
                    </span>
                    <span>
                        {departure}
                    </span>
                </div>
                <hr/>
                {busChange.map(({name, arrivalTime, departureTime}) => (
                    <div key={station} className="gb--row gb--row-middle">
                        <span className="gb--changes-list__stations">
                            {name}
                        </span>
                        <TimeWithoutSeconds time={arrivalTime}/>
                        <TimeWithoutSeconds time={departureTime}/>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default BusStopPopover;