/**
 * Removes placeholder nodes set in twig
 */
export default function dismissPlaceholders() {
    const placeholder = document.getElementById('header-placeholder');
    const homepagePlaceholder = document.getElementById('homepage-placeholder');
    const searchResultsPlaceholder = document.getElementById('search-results-preloader');

    if (placeholder) {
        placeholder.parentNode.removeChild(placeholder);
    }

    if (homepagePlaceholder) {
        homepagePlaceholder.parentNode.removeChild(homepagePlaceholder);
    }

    if (searchResultsPlaceholder) {
        searchResultsPlaceholder.parentNode.removeChild(searchResultsPlaceholder);
    }
}