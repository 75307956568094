import {RouteTransferTypeKeys} from "@Route/features/transportType/types/routeTransferType.type"
import {privateRouteTransferTypes} from "@Route/features/transportType/constants/privateRouteTransferTypes"
import getRouteTransferType from "@Route/features/transportType/functions/getRouteTransferType"
import {TabsDisplayedOnSearchResults} from "@Tabs/types/TabsDisplayedOnSearchResults"

export default function getTabBasedOnRouteTransferType(routeTransferType: RouteTransferTypeKeys): TabsDisplayedOnSearchResults {
    if (privateRouteTransferTypes.includes(routeTransferType)) {
        return "privateTransfer"
    }

    // Minivans are sent as buses, this prevents array access failure
    if (routeTransferType === 5) {
        return "bus"
    }

    return <TabsDisplayedOnSearchResults>getRouteTransferType(routeTransferType)
}