import React from 'react';
import NoDirectRoutes from "@SearchResults/components/NoResults/NoDirectRoutes";
import NoResultsNotice from "@SearchResults/components/NoResults/NoResultsNotice";
import ParamsType from "@SearchResults/types/params/Params.type";
import SearchResultsUrlsType from "@SearchResults/types/urls/SearchResultsUrls.type";

interface NoResultsMessageProps {
    toCityName: string,
    nearByCityName: string,
    showNoDirectRoutes: boolean,
    params: ParamsType,
    urls: SearchResultsUrlsType
}

const NoResultsMessage = ({toCityName, nearByCityName, showNoDirectRoutes, params, urls}: NoResultsMessageProps) => (
    (showNoDirectRoutes) ? (
        <NoDirectRoutes
            toCityName={toCityName}
            nearByCityName={nearByCityName}
        />
    ) : (
        <NoResultsNotice
            params={params}
            urls={urls}
        />
    )
);

export default NoResultsMessage;