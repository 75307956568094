import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconExclamationCircle(props: IconType) {
    const {
        width = '16',
        height = '16',
        fill = '#7ed321',
        viewBox = '0 0 20 20',
        title = 'IconExclamationCircle',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <path
                    d="m10 20c1.8098958 0 3.4830729-.4459635 5.0195312-1.3378906 1.5364584-.8919271 2.7506511-2.1061198 3.6425782-3.6425782.8919271-1.5364583 1.3378906-3.2096354 1.3378906-5.0195312 0-1.80989583-.4459635-3.48307292-1.3378906-5.01953125s-2.1061198-2.74739583-3.6425782-3.6328125c-1.5364583-.8984375-3.2096354-1.34765625-5.0195312-1.34765625-1.80989583 0-3.48307292.44596354-5.01953125 1.33789062-1.53645833.89192709-2.75065104 2.1061198-3.64257813 3.64257813-.89192708 1.53645833-1.33789062 3.20963542-1.33789062 5.01953125 0 1.8098958.44596354 3.4830729 1.33789062 5.0195312.89192709 1.5364584 2.1061198 2.7506511 3.64257813 3.6425782s3.20963542 1.3378906 5.01953125 1.3378906zm0-7.5c-.22135417 0-.40690104-.0748698-.55664062-.2246094-.14973959-.1497396-.22460938-.3352864-.22460938-.5566406v-7.20703125c0-.22135417.07486979-.40690104.22460938-.55664063.14973958-.14973958.33528645-.22460937.55664062-.22460937.2213542 0 .406901.07486979.5566406.22460937.1497396.14973959.2246094.33528646.2246094.55664063v7.20703125c0 .2213542-.0748698.406901-.2246094.5566406s-.3352864.2246094-.5566406.2246094zm0 3.0078125c-.24739583 0-.45572917-.0846354-.625-.2539063-.16927083-.1692708-.25390625-.3776041-.25390625-.625 0-.2473958.08463542-.4557291.25390625-.625.16927083-.1692708.37760417-.2539062.625-.2539062.2473958 0 .4557292.0846354.625.2539062.1692708.1692709.2539062.3776042.2539062.625 0 .2473959-.0846354.4557292-.2539062.625-.1692708.1692709-.3776042.2539063-.625.2539063z"
                />
            )}
        />

    );
}

export default IconExclamationCircle;
