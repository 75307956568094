import Link from "@Generic/Link";
import React from "react";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {useTranslation} from "@Translation/hooks/useTranslation";

const Consent = () => {
    const {
        generalTerms: {
            url: generalTermsUrl,
        },
    } = useAppSelector((state) => state?.page.urlsObject)

    const {
        i_have_read_and_accept: accept,
        terms_of_service: terms,
    } = useTranslation('sys')

    return (
        <>
            {accept}
            {''}
            <Link href={generalTermsUrl}>
                {terms}
            </Link>
        </>
    )
}

export default Consent
