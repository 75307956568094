import React from 'react';
import Translation from "@Translation/components/Translation";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import useClickBusRentEvent from "@Events/hooks/useClickBusRentEvent";

function BusRentNotice() {
    const {
        siteUrl,
        locale,
        rentABusRoutePaths,
    } = useAppSelector((state) => (state.page))

    const busRentClicked = useClickBusRentEvent()

    const rentABusUrl = `${siteUrl}${rentABusRoutePaths[locale]}`

    return (
        <div className="rent-a-bus">
            <a
                rel="noreferrer"
                href={rentABusUrl}
                target="_blank"
                onClick={() => {
                    busRentClicked()
                }}
            >
                <Translation translationKey="form.travelling_in_group"/>
                ?
                <br/>
                <Translation translationKey="bus_rental.anchor_title"/>
            </a>
        </div>
    );
}

export default BusRentNotice;