import React from 'react';
import isEmpty from "lodash/isEmpty";
import Row from "@Containers/components/Row";
import Column from "@Containers/components/Column";
import {useTranslation} from "@Translation/hooks/useTranslation";
import RouteActionButton from "@Route/features/ticketPurchase/components/RouteActionButton";
import RouteTotalPrice from "@Route/components/Price/RouteTotalPrice";
import IntermodalTransportTypeIcons from "@Intermodal/components/IntermodalTransportTypeIcons";
import IntermodalRouteWithDetailsType from "@Intermodal/types/IntermodalRouteWithDetails.type";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";
import {isIntermodalRoute} from "@SearchResults/functions/isIntermodalRoute";
import ParamsType from "@SearchResults/types/params/Params.type";

interface RouteTotalPriceSectionProps {
    route: IntermodalRouteWithDetailsType | RouteWithAddedPropsAndPricesType,
    isReturn: boolean,
    showButton: boolean,
    params: ParamsType,
}

function RouteTotalPriceSection({route, isReturn, showButton, params}: RouteTotalPriceSectionProps) {
    const baseTripLabel = useTranslation("sys.trip")
    const priceLabel = useTranslation("sys.price")
    const tripLabel = `${baseTripLabel} ${priceLabel.toLowerCase()}`
    const hasDiscounts = !isIntermodalRoute(route) && !isEmpty(route.prices.discounts)

    return (
        <Row
            className="gb--route-total-price-section"
            justify
        >
            <Column>
                <span className="gb--emphasize-info">
                    {tripLabel}
                </span>
                {hasDiscounts && (
                    // TODO-Add-translation-pliz
                    <span>
                        * Early bird - web discount applied
                    </span>
                )}
            </Column>
            <Row className="total-price-with-button">
                <RouteTotalPrice
                    price={route.prices.price}
                    passengersClassName="gb--row gb--row-middle total-passengers"
                    displayLabel
                    displayPassengers={!route.type.isPrivate}
                    align
                >
                    {!route.status.isSuggestion && isIntermodalRoute(route) && (
                        <IntermodalTransportTypeIcons
                            routeTransferTypeArray={route?.baseRoutes.map((route) => (
                                {
                                    id: route?.id,
                                    transportTypeId: route?.type?.routeTransferType,
                                }
                            ))}
                        />
                    )}
                </RouteTotalPrice>
                {showButton && (
                    <RouteActionButton
                        params={params}
                        route={route}
                        isReturn={isReturn}
                    />
                )}
            </Row>
        </Row>
    );
}

export default RouteTotalPriceSection;