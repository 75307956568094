import isSameCompany from "@Route/features/company/functions/isSameCompany";
import generateRoutePassengerGroups from "@Route/functions/generateRoutePassengerGroups";
import filterActivePassengerGroups from "@Route/functions/filterActivePassengerGroups";
import generateRouteTotalPrice from "@Route/functions/generateRouteTotalPrice";
import useSingleSelectedRoute from "@Route/hooks/useSingleSelectedRoute";
import usePassengers from "@Hooks/selector/usePassengers";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";
import PassengerGroupType from "@SearchResults/types/passenger/PassengerGroup.type";
import CurrencyType from "@Currency/types/Currency.type";
import RouteWithAddedPropsType from "@Route/types/RouteWithAddedProps.type";

export default function useRoutePriceGenerator(passengerGroups: Record<string, PassengerGroupType>, currency: CurrencyType) {
    const {companyId: selectedCompanyId} = useSingleSelectedRoute() || {}
    const passengers = usePassengers()

    const generatePrices = (route: RouteWithAddedPropsType): RouteWithAddedPropsAndPricesType => {
        const {companyId, type} = route
        const sameCompany = isSameCompany({
            selectedSingleRouteCompanyId: selectedCompanyId,
            routeCompanyId: companyId,
            isReturn: route?.status?.isReturn,
        })
        const routePassengerGroups = generateRoutePassengerGroups({
            route,
            // @ts-ignore
            activeCurrencyRate: currency.ratio,
            passengerGroups,
            sameCompany,
        })

        const activeGroups = filterActivePassengerGroups({
            route,
            passengers,
            passengerGroups: routePassengerGroups,
        })

        const {totalPrice, pricesPerGroup} = generateRouteTotalPrice(activeGroups, type.isPrivate)

        return {
            ...route,
            prices: {
                ...route.prices,
                price: totalPrice,
                pricesPerGroup,
            },
        }
    }

    return {
        generatePrices,
    }
}