import {useEffect, MutableRefObject, Dispatch, SetStateAction} from "react";
import {defaultGapBetweenSlidesInPercentage} from "@Features/slider/constants/defaultGapBetweenSlidesInPercentage";

interface UpdateSlideWrapperWidthParams {
    wrapperRef: MutableRefObject<HTMLDivElement>,
    itemWidth: number,
    setItemWidth: Dispatch<SetStateAction<number>>
}

export default function useUpdateSlideWrapperWidth(params: UpdateSlideWrapperWidthParams) {
    const {
        wrapperRef,
        itemWidth,
        setItemWidth,
    } = params

    const updateItemWidth = (itemWidthInPx: number, childWidthInPx: number) => {
        setItemWidth(Math.ceil((itemWidth * childWidthInPx) / itemWidthInPx) + defaultGapBetweenSlidesInPercentage)
    }

    useEffect(() => {
        if (wrapperRef?.current) {
            const itemWidthInPx = wrapperRef.current.clientWidth
            const childWidthInPx = wrapperRef.current?.children.item(0).clientWidth

            const defaultGapBetweenSlidesInPx = (defaultGapBetweenSlidesInPercentage * itemWidthInPx) / itemWidth

            if (childWidthInPx >= itemWidthInPx) {
                updateItemWidth(itemWidthInPx, childWidthInPx)
                return
            }

            /**
             * child is not bigger, but gap is smaller than our minimal slides gap
             */
            if (itemWidthInPx - childWidthInPx <= defaultGapBetweenSlidesInPx) {
                updateItemWidth(itemWidthInPx, childWidthInPx + defaultGapBetweenSlidesInPx)
            }
        }
    }, [wrapperRef, itemWidth]);
}