import React from 'react';
import IconUserOutline from "@Icons/IconUserOutline";
import useIsDesktop from "@Hooks/selector/useIsDesktop";
import {toggleLoginModal} from "@MyProfile/reducers/userProfileSlice";
import useIsMobile from "@Hooks/selector/useIsMobile";
import IconHandPoint from "@Icons/IconHandPoint";
import Translation from "@Translation/components/Translation";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";

function LoginButton() {
    const dispatch = useAppDispatch()
    const isDesktop = useIsDesktop()
    const isMobile = useIsMobile()
    const toggleLogin = () => {
        dispatch(toggleLoginModal())
    }

    const setClassName = () => {
        if (isMobile) {
            return "gb--column gb--column-middle "
        }
    }

    return (
        <button
            type="button"
            className={`${setClassName()} gb--emphasize gb--emphasize-default btn--login`}
            onClick={toggleLogin}
        >
            <IconUserOutline
                width="25"
                height="20"
            />
            {' '}
                &nbsp;
                <Translation translationKey="sys.my_tickets"/>
                {isDesktop && (
                    <IconHandPoint className="btn--login__pointer"/>
                )}
        </button>
    );
}

export default LoginButton;