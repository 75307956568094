import React from 'react';
import useFormMethods from "@Form/hooks/useFormMethods";
import useScrollToTopOnSafari from "@Hooks/effects/useScrollToTopOnSafari";
import FormStationInputField from "@Form/components/FormStationInputField";
import MobileOverlay from "./MobileOverlay";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import useDismissAllOverlays from "@MobileOverlays/hooks/useDismissAllOverlays";

function MobileStationInputContainer() {
    const {departureStationInputActive} = useAppSelector((state) => state?.mobile)
    const dismissAllOverlays = useDismissAllOverlays()
    useScrollToTopOnSafari()

    const {
        departureStationObject,
        destinationStationObject,
        formDepartureStation,
        formDestinationStation,
    } = useFormMethods()

    return (
        <MobileOverlay
            backClick={dismissAllOverlays}
            title={departureStationInputActive ? formDepartureStation : formDestinationStation}
        >
            {departureStationInputActive ? (
                <FormStationInputField
                    direction="departure"
                    stationObject={departureStationObject}
                />
            ) : (
                <FormStationInputField
                    direction="destination"
                    stationObject={destinationStationObject}
                />
            )}
        </MobileOverlay>
    );
}

export default MobileStationInputContainer;