import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconBookmark(props: IconType) {
    const {
        width = '8',
        height = '8',
        fill = '#f5a623',
        viewBox = '0 0 8 8',
        title = 'IconBookmark',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <path
                    d="m6.12423672 0h-4.21995965c-.51242367 0-.90427707.3918534-.90427707.90427707v6.08879892c0 .48228111.54256624.7837068.93441964.48228111l1.71812643-1.20570276c.21099798-.15071285.4822811-.15071285.69327908 0l1.71812643 1.20570276c.3918534.27128312.93441964 0 .93441964-.48228111v-6.08879892c.03014257-.51242367-.36171083-.90427707-.8741345-.90427707z"
                    fill={fill}
                />
            )}
        />

    );
}

export default IconBookmark;
