import MetadataEventModel from "@Events/models/MetadataEventModel";
import ajaxHandler from "@Ajax/v2/ajaxHandler";
import ClientErrorModel from "@Errors/models/ClientErrorModel";

/**
 *
 * @param {object} [params.error] - Error string
 * @param {object} [params.info] - Additional info
 * @param {string} [params.experiments] - Active experiments string
 * @param {number} [params.userId] - User account id
 * @param {boolean} [params.postToSlack = true] - Should the event be dispatched to slack bot-bug
 */
export default function postError(params) {
    const {
        error,
        info,
        experiments,
        userId,
        postToSlack = true,
    } = params || {}

    return async () => {
        const errorModel = new ClientErrorModel({
            metadata: new MetadataEventModel(experiments, userId),
            errorMessage: error?.message ?? 'Error',
            additionalInfo: info?.componentStack,
            stackTrace: error?.stack,
        })

        // eslint-disable-next-line no-console
        console.error(error?.message, errorModel)

        if (postToSlack) {
            await ajaxHandler({
                url: '/ajax/log-error',
                type: "post",
                params: errorModel,
            })
        }
    }
}