import React from 'react';
import {CurrencyVal} from "@Currency/types/Currency.type";

interface PricesInOtherCurrenciesProps {
    pricesInOtherCurrencies: Record<CurrencyVal, number>
}

function PricesInOtherCurrencies({pricesInOtherCurrencies}: PricesInOtherCurrenciesProps) {
    const priceArray = Object.entries(pricesInOtherCurrencies)

    return (
        <div className="prices-in-other-currencies">
            {priceArray.map(({0: currency, 1: price}) => (
                <div className="gb--row gb--row-justify" key={currency}>
                    <small className="gb--emphasize gb--emphasize-success">
                        {currency}
                    </small>
                    <small>
                        {price}
                    </small>
                </div>
            ))}
        </div>
    );
}

export default PricesInOtherCurrencies;