import React from 'react';
import {useTranslation} from "@Translation/hooks/useTranslation";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import useSearchResults from "@SearchResults/hooks/useSearchResults";
import useSearchResultsCount from "@SearchResults/hooks/useSearchResultsCount";
import ActiveFiltersCount from "@SearchResults/components/HeadingContent/ActiveFiltersCount";

interface ActiveFiltersNotificationProps {
    displayFilterCount?: boolean,
}

function ActiveFiltersNotification({displayFilterCount = true}: ActiveFiltersNotificationProps) {
    const {
        filteredRoutesCount,
    } = useAppSelector((state) => state.filter)
    const totalRoutesCount = useSearchResultsCount()
    const {isLoading} = useSearchResults()

    const {
        active: activeLabel,
        results: resultsLabel,
    } = useTranslation("sys")

    const filteredCountLabel = useTranslation("sts_page.showing", {
        count: filteredRoutesCount,
        total: totalRoutesCount,
    })

    const routesFiltered = totalRoutesCount !== filteredRoutesCount
    const label = routesFiltered
        ? filteredCountLabel
        : `${filteredRoutesCount} ${resultsLabel}`

    return (
        <>
            <div className="active-filters-notification" style={{marginRight: "auto"}}>
                {!isLoading && (
                    <span data-notification={label}>
                        {label}
                    </span>
                )}
            </div>
            <ActiveFiltersCount/>
        </>
    );
}

export default ActiveFiltersNotification;