import React from "react";
import {useTranslation} from "@Translation/hooks/useTranslation";
import {VoucherTypeType} from "@MyVoucher/types/VoucherType.type";

interface VoucherTypeInfoProps {
    type: VoucherTypeType,
}

function VoucherTypeInfo({type}: VoucherTypeInfoProps) {
    const {promo: promoLabel, rebook: rebookLabel} = useTranslation('my_profile.my_vouchers')
    return (
        <span className="gb--badge gb--badge-left gb--badge-blue">
            {type === "promo" ? promoLabel : rebookLabel}
        </span>
    );
}

export default VoucherTypeInfo