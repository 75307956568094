import hasMoreItems from "@Features/lazyLoading/functions/hasMoreItems";
import useDebouncedScroll from "@Hooks/eventListeners/useDebouncedScroll";

interface LazyLoadingAttributes {
    isLoading: boolean,
    totalData: number,
    dataPerBatch: number,
    page: number,
    setScroll?: (any: boolean) => void,
    setPage: (any: number) => void,
}

export default function useLazyLoading(props: LazyLoadingAttributes) {
    const {
        isLoading,
        totalData,
        dataPerBatch,
        page,
        setScroll,
        setPage,
    } = props

    const {innerHeight, innerWidth} = window;
    const {offsetHeight} = document.body;
    const offsetToRenderAt = innerWidth >= 1244 ? 500 : 1400

    const userAtThePageBottom = () => ((innerHeight + window.scrollY) >= offsetHeight - offsetToRenderAt)

    const loader = () => {
        if (setScroll) {
            setScroll(innerHeight + window.scrollY >= 3000)
        }

        if (userAtThePageBottom() && hasMoreItems({totalData, page, dataPerBatch,})) {
            // If there's less than showItems data there is no other page to load.
            if (!isLoading) {
                setPage(page + 1)
            }
        }
    }
    useDebouncedScroll(loader)
}