import React, {ForwardedRef, ReactNode} from 'react';
import Row from "@Containers/components/Row";
import BackButton from "@Routing/components/BackButton";
import EditSearchButton from "@Form/components/Mobile/EditSearchButton";
import useDisplayEditButton from "@SearchResults/hooks/useDisplayEditButton";

interface MobileAppTitleProps {
    children: ReactNode,
    filtersButtonVisible?: boolean,
    backClick?: (any) => (any),
    className?: string,
    subtitleComponent?: ReactNode,
    subtitleComponentVisible?: boolean,
    titleRef?: ForwardedRef<null>,
    sticky?: boolean,
}

function MobileAppTitle(props: MobileAppTitleProps) {
    const {
        filtersButtonVisible = false,
        children,
        backClick = undefined,
        className = "gb--app-title__single",
        subtitleComponent = null,
        subtitleComponentVisible = true,
        titleRef = null,
        sticky,
    } = props;

    const displayEditButton = useDisplayEditButton()

    return (
        <div
            style={sticky ? {position: "sticky", top: 0, zIndex: 3} : {}}
            className="gb--mobile__app-title"
            ref={titleRef}
        >
            <Row justify center className="gb--app-title">
                <Row justify center>
                    <BackButton backClick={backClick}/>
                    <span className={className}>
                        {children}
                    </span>
                </Row>
                {displayEditButton && (
                    <EditSearchButton/>
                )}
            </Row>
            {subtitleComponentVisible && subtitleComponent}
        </div>
    );
}

export default MobileAppTitle
