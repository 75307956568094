import React from "react";
import useFixedEurConversion from "@Currency/hooks/useFixedEurConversion";

function EuroConversionNotice({price}: { price: number }) {
    const {shouldDisplayPriceInKn, dualPriceHrkEurConversionRate} = useFixedEurConversion(price)
    const formattedRatio = new Intl.NumberFormat('en-GB', {minimumFractionDigits: 5}).format(parseInt(dualPriceHrkEurConversionRate, 10))
    const pricesInEur = `Prices in euro are calculated based on fixed conversion rate of 1 EUR = ${formattedRatio} kn`

    if (shouldDisplayPriceInKn) {
        return (
            <>
                <br/>
                <span className="gb--emphasize-default">
                            **
                </span>
                {pricesInEur}
            </>
        );
    }
}

export default EuroConversionNotice;