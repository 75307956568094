import PartnerRedirectEventModel from "@Events/models/PartnerRedirectEventModel";
import useRedirectUrlGenerator from "@Route/hooks/useRedirectUrlGenerator";
import useSingleTrackingEvent from "@Events/hooks/useSingleTrackingEvent";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";
import ParamsType from "@SearchResults/types/params/Params.type";

/**
 * Dispatches redirectToPartnerEvent
 */
interface UseRouteRedirectToPartnerEventAttributes {
    route: RouteWithAddedPropsAndPricesType,
    isReturn: boolean,
    params: ParamsType
}

export default function useRouteRedirectToPartnerEvent(props: UseRouteRedirectToPartnerEventAttributes) {
    const {
        route,
        isReturn = false,
        params,
    } = props

    const {generateRedirectUrlPrams} = useRedirectUrlGenerator(params)
    const model = new PartnerRedirectEventModel(generateRedirectUrlPrams(route), isReturn)

    return useSingleTrackingEvent("partner_redirect", model)
}