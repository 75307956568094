import IconCheckMarkCircle from "@Icons/IconCheckMarkCircle";
import React from "react";
import IconTimes from "@Icons/IconTimes";
import Row from "@Containers/components/Row";
import {useTranslation} from "@Translation/hooks/useTranslation";
import VoucherStatusType from "@MyVoucher/types/VoucherStatus.type";

interface VoucherTypeSectionProps {
    status: VoucherStatusType,
}

function VoucherTypeLabel({status}: VoucherTypeSectionProps) {
    const {
        isExpired,
        isUsed,
        isCancelled,
    } = status

    const {
        used: usedLabel,
        expired: expiredLabel,
        cancelled: cancelledLabel,
    } = useTranslation('my_profile.my_vouchers')
    const validLabel = useTranslation('my_profile.filter.valid')

    const voucherType = () => {
        if (isUsed) {
            return ["orange", usedLabel, <IconTimes fill="white"/>]
        }
        if (isExpired) {
            return ["red", expiredLabel, <IconTimes fill="white"/>]
        }
        if (isCancelled) {
            return ["red", cancelledLabel, <IconTimes fill="white"/>]
        }
        return ["green", validLabel, <IconCheckMarkCircle fill="white"/>]
    }
    const [voucherColor, voucherText, voucherIcon] = voucherType()

    return (
        <Row className={`my-profile-tag gb--tag-${voucherColor}`}>
            {voucherIcon}
            {voucherText}
        </Row>
    );
}

export default VoucherTypeLabel