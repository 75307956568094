import useLocale from "@Hooks/selector/useLocale";
import useCompanyStyle from "@Hooks/selector/useCompanyStyle";
import {isAfter, parseISO} from "date-fns";
import ParamsType from "@SearchResults/types/params/Params.type";

/**
 * Returns boolean based on local set in search results params
 * @returns {boolean}
 */

export default function useShowMobileAppPromoNotice(params: ParamsType) {
    const {
        departureStation: {
            countryId: departureStationCountryId,
        },
        destinationStation: {
            countryId: destinationStationCountryId,
        },
        toursAvailable,
    } = params
    const style = useCompanyStyle()
    const showOnLocale = ['hr']
    const locale = useLocale()

    // @ts-ignore
    const isCroatiaLocal = showOnLocale.includes(locale)
    const startsOrEndsInCroatia = departureStationCountryId === 1 || destinationStationCountryId === 1
    const isGbb = style?.id === "1"
    const isStillActive = !isAfter(new Date(), parseISO("2024-10-01")); // up until (including) 2024-09-30

    return isCroatiaLocal && startsOrEndsInCroatia && isGbb && isStillActive && !toursAvailable
}