export const routeIds = {
    homepage: 'homepage',
    searchResults: 'searchResults',
    login: 'login',
    staticPages: 'staticPages',
    additionalInfo: 'additionalInfo',
    checkOrder: 'checkOrder',
    myProfile: 'myProfile',
    tickets: 'tickets',
    vouchers: 'vouchers',
    returnRoutesList: 'returnRoutesList',
    returnAdditionalInfo: 'returnAdditionalInfo',
    successfulPurchase: 'successfulPurchase',
    mobileApp: 'mobileApp',
}