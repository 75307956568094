import {useEffect, useState} from "react";
import {useComponentDidMount} from "@Hooks/lifecycle/useComponentDidMount";
import useLogError from "./useLogError";

/**
 * Handles entire error log process
 * @param postToSlack {boolean}
 * @example
 * function SomeComponent() {
 *     const {setError} = useHandleError()
 *     whateverMethod = () => {
 *          try {
 *              something()
 *          } catch(error) {
 *              setError(error)
 *              // OR
 *              setError({error, additionalInfo: totallyOptional})
 *          }
 *      }
 * }
 */
export default function useHandleError(postToSlack = true) {
    const [error, setError] = useState(null)
    const isMount = useComponentDidMount()

    // #TODO This is stupid clean it up
    const logError = useLogError(
        {
            message: error?.error?.message ?? error?.error ?? error,
            stack: error?.error?.stack,
        },
        {
            componentStack: error?.additionalInfo,
        },
        postToSlack
    )

    useEffect(() => {
        if (!isMount) {
            if (error) {
                logError()
            }
        }
    }, [error])

    return {
        setError,
    }
}