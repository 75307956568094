/**
 * Returns client properties for error logging
 * * url
 * * userAgent
 * * viewPort
 */
export default function getClientProperties() {
    return {
        url: window.location.href,
        userAgent: navigator.userAgent,
        viewPort: `${window.visualViewport.width} x ${window.visualViewport.height}`,
    }
}