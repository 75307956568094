import React from "react";

interface LoaderIconProps {
    loaderIcon: React.ReactElement<any>,
    condition?: boolean,
}

function LoaderIcon({condition = true, loaderIcon}: LoaderIconProps) {
    if (condition) {
        return (
            <>
                {loaderIcon}
            </>
        );
    }
    return null
}

export default LoaderIcon