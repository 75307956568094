import RouteType from "@Route/types/Route.type";
import {PreparedRouteType} from "@Route/types/PreparedRoute.type";

/**
 * Returns dateTime string which can be used by date-fns for comparison
 */

interface GetRouteDateTimeAttributes {
    route: PreparedRouteType | RouteType,
    type: 'departure' | 'arrival',
}

export default function getRouteDateTime({route, type}: GetRouteDateTimeAttributes): string {
    const {
        travelInfo: {
            departureStation: {
                departureDate,
                departureTime,
            },
            destinationStation: {
                arrivalTime,
                arrivalDate,
            },
        },
    } = route
    let dateTime

    if (type === 'departure') {
        dateTime = `${departureDate}T${departureTime}`
    }

    if (type === 'arrival') {
        dateTime = `${arrivalDate}T${arrivalTime}`
    }

    return dateTime
}