import React from "react";
import ErrorMessage from "@User/components/ErrorMessage";

interface NameErrorMessageProps {
    field: string,
    fieldLabel: string
}

const NameErrorMessage = ({field, fieldLabel}: NameErrorMessageProps) => {
    const regex = /^[a-zA-Z ]+$/
    if (!field) {
        // field is required`
        return <ErrorMessage translationKey="field_required" fieldLabel={fieldLabel}/>
    }
    if (!regex.test(field)) {
        // field contains invalid characters`
        return <ErrorMessage translationKey="invalid_characters" fieldLabel={fieldLabel}/>
    }
    if (field.length < 3) {
        // field length must be at least 3 characters`
        return <ErrorMessage translationKey="characters_length" fieldLabel={fieldLabel}/>
    }
    return null
}

export default NameErrorMessage