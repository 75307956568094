import React from 'react';
import IconDiscountLabel from "@Icons/IconDiscountLabel";

interface DiscountIconProps {
    style: object,
    discountAmount: number,
}

const DiscountIcon = ({style, discountAmount}: DiscountIconProps) => (
    <div className="discount-icon-container" style={style}>
        <IconDiscountLabel
            width="40"
            height="40"
            discountText={`${discountAmount}%`}
        />
    </div>
);

export default DiscountIcon;