import LoadDifferentDepartureTimeslots from "@Tabs/components/LoadDifferentDepartureTimeslots";

type LoadEarlierDeparturesProps = {}

function LoadEarlierDepartures(props: LoadEarlierDeparturesProps) {
    return (
        <LoadDifferentDepartureTimeslots
            timeslots="earlier"
        />
    );
}

export default LoadEarlierDepartures;