import React from 'react';
import {setFilterDuration} from "@SearchResults/reducers/filterSlice";
import {useTranslation} from "@Translation/hooks/useTranslation";
import {getFormattedDuration} from "@DateTime/functions/getFormattedDuration";
import {convertMillisecondsToDuration} from "@DateTime/functions/convertMillisecondsToDuration";
import FilterSlider from "../../../components/FilterSlider";
import useFilterSliderValue from "../../../hooks/useFilterSliderValue";
import FilterSliderLabels from "../../../components/FilterSliderLabels";
import FilterSection from "@SearchResults/features/sidebar/components/FilterSection";
import useDispatchFilterEvents from "@SearchResults/features/sidebar/hooks/useDispatchFilterEvent";

function DurationFilter() {
    const label = useTranslation("search_results.filter_by.trip_duration.slider_label")
    const {
        value,
        updateValue,
        min,
        max,
        setValueState,
    } = useFilterSliderValue({
        onChange: setFilterDuration,
        boundValue: "Duration",
    })

    const tripDuration = getFormattedDuration(convertMillisecondsToDuration(value ?? max))
    const handleOnBlur = useDispatchFilterEvents('DurationFilter', tripDuration)

    return (
        <FilterSection translationKey="search_results.filter_by.trip_duration.label">
            <FilterSlider
                onBlur={handleOnBlur}
                onChange={setValueState}
                value={value}
                min={min}
                max={max}
                onRelease={updateValue}
            >
                <FilterSliderLabels
                    label={label}
                    value={tripDuration}
                />
            </FilterSlider>
        </FilterSection>
    )
}

export default DurationFilter;