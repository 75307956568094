import {VehicleAddonResponseType} from "@CheckOrder/api/types/GetAddonData.type";
import VehicleTypeSelect from "@CheckOrder/features/addons/features/jadrolinija/components/VehicleTypeSelect";

type VehiclesProps = {
    addonData: VehicleAddonResponseType[],
}

function Vehicles({addonData}: VehiclesProps) {
    return (
        <div className="addons--container">
            <span className="gb--row gb--row-justify gb--row-wrap gb--card-title">
                Vehicle
            </span>
            <VehicleTypeSelect
                vehicles={addonData}
            />
        </div>
    );
}

export default Vehicles;