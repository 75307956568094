import React from 'react';
import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconShieldWithLock(props: IconType) {
    const {
        width = '22',
        height = '26',
        fill = '',
        viewBox = '0 0 22 26',
        title = 'IconShieldWithLock',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none">
                    <path
                        d="m9.18803419 22.008547c5.29914531-3.1410256 8.54700851-8.7393162 8.54700851-14.97863247v-3.22649573l-8.54700851-3.16239316-8.54700855 3.16239316v3.22649573c0 6.23931627 3.24786325 11.83760687 8.54700855 14.97863247"
                        fill="#fff"
                    />
                    <path
                        d="m9.18803419 19.5726496c-.12820513 0-.27777778-.0427351-.38461539-.1282052-3.39743589-2.5-5.55555555-6.1965812-6.11111111-10.36324782-.04273504-.34188034.19230769-.66239316.55555556-.72649573.34188034-.04273504.66239316.1923077.72649572.55555556.491453 3.65384619 2.32905983 6.90170939 5.21367522 9.18803419 3.39743591-2.6923077 5.34188031-6.7094017 5.34188031-11.08974359v-.98290598l-5.34188031-1.96581197-5.76923077 2.13675214c-.34188034.12820512-.70512821-.04273505-.83333333-.38461539-.12820513-.34188034.04273504-.7051282.38461538-.83333333l6.0042735-2.2008547c.14957265-.04273504.2991453-.04273504.44871795 0l5.98290598 2.22222222c.2564103.08547009.4273504.34188034.4273504.5982906v1.43162393c0 4.95726497-2.2649572 9.48717947-6.23931621 12.41452987-.12820512.0854701-.27777777.1282052-.4059829.1282052z"
                        fill="#4a79e2"
                    />
                    <path
                        d="m9.18803419 22.6388889c-.10683761 0-.21367522-.0213675-.32051282-.0854701-5.55555556-3.2905983-8.86752137-9.1025641-8.86752137-15.53418803v-3.22649573c0-.27777778.17094017-.51282051.42735043-.5982906l8.54700854-3.16239316c.14957265-.04273504.2991453-.04273504.44871795 0l8.52564098 3.16239316c.2564103.08547009.4273505.34188035.4273505.5982906v3.22649573c0 1.6025641-.2136752 3.18376073-.6196581 4.70085473-.0854701.3418803-.448718.5555555-.7905983.4487179-.3418804-.0854701-.5555556-.4487179-.448718-.7905983.3846154-1.41025638.5769231-2.88461536.5769231-4.35897433v-2.77777778l-7.90598291-2.92735043-7.90598291 2.92735043v2.77777778c0 5.98290603 3.07692308 11.36752133 8.22649573 14.42307693.2991453.1709402.4059829.5769231.23504273.8760684-.12820512.2136752-.34188034.3205128-.55555555.3205128z"
                        fill="#444b54"
                    />
                    <path
                        d="m9.18803419 3.37606838v15.55555552c3.78205131-2.7991453 5.98290601-7.1367521 5.98290601-11.90170937v-1.43162393z"
                        fill="#4a79e2"
                    />
                    <g transform="translate(12 16)">
                        <path
                            d="m.01807229 5c0-2.75100402 2.23092369-4.98192771 4.98192771-4.98192771s4.98192771 2.23092369 4.98192771 4.98192771-2.23092369 4.98192771-4.98192771 4.98192771-4.98192771-2.23092369-4.98192771-4.98192771z"
                            fill="#f5a623"
                        />
                        <g transform="translate(2.53012 1.506024)">
                            <path d="m.014056 3.176707h4.911647v3.208835h-4.911647z" fill="#fff"/>
                            <path
                                d="m4.21353157 5.34136546c-.14298671 0-.25997584-.13038417-.25997584-.28974259v-2.81464235c0-.91061959-.66479535-1.65153279-1.48186227-1.65153279-.81706691 0-1.48186227.7409132-1.48186227 1.65153279v2.81464235c0 .15935842-.11698912.28974259-.25997583.28974259s-.25997584-.13038417-.25997584-.28974259v-2.81464235c0-1.22933644.89691663-2.22894839 1.99995697-2.22894839s1.99995697.99961195 1.99995697 2.22894839v2.81464235c.00371394.16142801-.11327518.28974259-.25626189.28974259z"
                                fill="#fff"
                            />
                            <path
                                d="m3.00401606 4.56425703c0-.29518072-.23895582-.53413655-.53413654-.53413655s-.53413655.23895583-.53413655.53413655c0 .18875502.09638554.35341365.24297189.44779116v.2309237c0 .16064257.13052209.29116466.29116466.29116466s.29116466-.13052209.29116466-.29116466c0-.04016065 0-.14457832 0-.2309237.14658634-.09437751.24297188-.26104417.24297188-.44779116z"
                                fill="#e5aa17"
                            />
                        </g>
                    </g>
                </g>
            )}
        />

    );
}

export default IconShieldWithLock;
