import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconSMS(props: IconType) {
    const {
        width = '16',
        height = '16',
        stroke = '#fff',
        viewBox = '0 0 16 16',
        title = 'IconSMS',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            stroke={stroke}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none" stroke={stroke}>
                    <g strokeWidth=".5">
                        <svg viewBox="0 0 18 16" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="m15.35 1.95h-12.7c-.84 0-1.51.68-1.51 1.51v9.07c0 .84.68 1.51 1.51 1.51h12.71c.84 0 1.51-.68 1.51-1.51v-9.07c0-.84-.68-1.51-1.51-1.51z"
                                fill="#4a79e2"
                                stroke={stroke}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.21"
                            />
                            <path
                                d="m10.81 6.97 5.73-4.41v10.89z"
                                fill="#7ed321"
                            />
                            <path
                                d="m7.47 6.97-5.73-4.42v10.89l5.73-6.48z"
                                fill="#7ed321"
                            />
                            <path
                                d="m1.74 2.55 5.33 4.54 1.78 1.51 1.85-1.51 5.56-4.54"
                                fill="#f5a623"
                            />
                            <path
                                d="m1.74 2.55 5.33 4.54m9.19-4.54-5.56 4.54m-3.63 0 1.78 1.51 1.85-1.51m-3.63 0-5.33 6.35m8.96-6.35 5.56 6.35"
                                fill="none"
                                stroke={stroke}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.21"
                            />
                        </svg>
                    </g>
                </g>
            )}
        />

    );
}

export default IconSMS;
