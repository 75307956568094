import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconTimes(props: IconType) {
    const {
        width = '20',
        height = '20',
        fill = '#9b9b9b',
        viewBox = '0 0 20 20',
        title = 'IconTimes',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <path
                    d="m16.1413043 17c.2391305 0 .442029-.0833333.6086957-.25s.25-.3695652.25-.6086957c0-.2391304-.0869565-.4384057-.2608696-.597826l-5.5217391-5.5434783 5.5217391-5.54347826c.1739131-.15942029.2608696-.35869565.2608696-.59782609 0-.23913043-.0833333-.44202898-.25-.60869565s-.3695652-.25-.6086957-.25c-.2391304 0-.4384057.08695652-.597826.26086957l-5.5434783 5.52173913-5.54347826-5.52173913c-.15942029-.17391305-.35869565-.26086957-.59782609-.26086957-.23913043 0-.44202898.08333333-.60869565.25s-.25.36956522-.25.60869565c0 .23913044.08695652.4384058.26086957.59782609l5.52173913 5.54347826-5.52173913 5.5434783c-.17391305.1594203-.26086957.3586956-.26086957.597826 0 .2391305.08333333.442029.25.6086957s.36956522.25.60869565.25c.23913044 0 .4384058-.0869565.59782609-.2608696l5.54347826-5.5434782 5.5434783 5.5434782c.1594203.1739131.3586956.2608696.597826.2608696z"
                />
            )}
        />

    );
}

export default IconTimes;
