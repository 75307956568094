import React from 'react';
import Translation from "@Translation/components/Translation";
import Row from "@Containers/components/Row";
import Column from "@Containers/components/Column";
import PassengerType from "@CheckOrder/types/Passenger.type";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import useHasMultiplePossibleGroups from "@CheckOrder/hooks/useHasMultiplePossibleGroups";
import PriceSelectDropdown
    from "@CheckOrder/features/passengerInfo/features/priceSelect/components/PriceSelectDropdown";
import FirstName from "@CheckOrder/components/MainSection/PassengersInfo/FirstName";
import Surname from "@CheckOrder/components/MainSection/PassengersInfo/Surname";
import PassengerGroup from "@CheckOrder/components/MainSection/PassengersInfo/PassengerGroup";
import PassengerAdditionalFields from "@CheckOrder/features/TicketAdditionalInfo/components/PassengerAdditionalFields";
import Grid from "@Containers/components/Grid";
import {SeatType} from "@CheckOrder/components/MainSection/SeatPicker/types/Seat.type";
import IconSeatbelt from "@Icons/IconLibrary/RouteAmenities/IconSeatbelt";
import getFormattedSeatNumber from "@CheckOrder/components/MainSection/SeatPicker/functions/getFormattedSeatNumber";

interface PassengerProps {
    passenger: PassengerType,
    index: number,
    singleSeatData?: SeatType
    returnSeatData?: SeatType
}

function Passenger({passenger, index, singleSeatData, returnSeatData}: PassengerProps) {
    const {
        ticket: {
            isReturn,
            additionalInfoFields,
        },
        purchasedRoute: {
            passengerGroups,
            returnPassengerGroups,
        },
        userConfirmed,
        passengers,
        passengerDataUpdating,
    } = useAppSelector((state) => state?.checkOrder)

    const {checkForDuplicates} = useHasMultiplePossibleGroups()

    const {
        passengerFirstName,
        passengerLastName,
        singlePassengerGroupId,
        returnPassengerGroupId,
        price,
        singleSelectable,
        returnSelectable,
    } = passenger

    const displaySingleSelect = isReturn ? (singleSelectable || returnSelectable) : singleSelectable
    const displayReturnSelect = isReturn ? (singleSelectable || returnSelectable) : returnSelectable
    const passengerIndex = passengers.length > 1 ? index + 1 : ''
    const hasDuplicateGroups = checkForDuplicates()

    return (
        <Column className="check-order__passenger">
            <Row wrap center justify>
                <span className="gb--row gb--row-justify gb--row-wrap gb--card-title">
                    <Translation translationKey="check_order.check_order_passenger"/>
                    {` ${passengerIndex} `}
                    &nbsp;
                    {(!singleSelectable && !returnSelectable) && (
                        <PassengerGroup
                            price={price}
                            className="gb--emphasize-default"
                            passengerGroupId={singlePassengerGroupId}
                            returnPassengerGroupId={returnPassengerGroupId}
                        />
                    )}
                </span>
                {singleSeatData && (
                    <span className="gb--emphasize gb--emphasize-default">
                        <IconSeatbelt/>
                        {getFormattedSeatNumber(singleSeatData)}
                        {returnSeatData && (
                            <span className="gb--emphasize gb--emphasize-default">
                                {` / `}
                                {getFormattedSeatNumber(returnSeatData)}
                            </span>
                        )}
                    </span>
                )}

            </Row>
            <Grid>
                <FirstName
                    userConfirmed={userConfirmed}
                    value={passengerFirstName}
                    index={index}
                />
                <Surname
                    userConfirmed={userConfirmed}
                    value={passengerLastName}
                    index={index}
                />
                {(hasDuplicateGroups || displaySingleSelect) && (
                    <PriceSelectDropdown
                        hasDuplicateGroups={hasDuplicateGroups}
                        passenger={passenger}
                        tripType="single"
                        disabled={passengerDataUpdating}
                        index={index}
                        passengerGroups={passengerGroups}
                    />
                )}
                {isReturn && (hasDuplicateGroups || displayReturnSelect) && (
                    <PriceSelectDropdown
                        hasDuplicateGroups={hasDuplicateGroups}
                        passenger={passenger}
                        tripType="return"
                        disabled={passengerDataUpdating}
                        index={index}
                        passengerGroups={returnPassengerGroups}
                    />
                )}
                <PassengerAdditionalFields
                    additionalInfoFields={additionalInfoFields.singleAdditionalFields.additionalPassengerFields}
                    userConfirmed={userConfirmed}
                    index={index}
                />
                <PassengerAdditionalFields
                    additionalInfoFields={additionalInfoFields.returnAdditionalFields.additionalPassengerFields}
                    userConfirmed={userConfirmed}
                    index={index}
                    isReturn
                />
            </Grid>
        </Column>
    );
}

export default Passenger
