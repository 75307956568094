import React from 'react'

interface ProgressBarDotProps {
    color: string,
    isActive: boolean
}

const ProgressBarDot = ({color, isActive}: ProgressBarDotProps) => {
    const className = isActive ? `bookings-section-dot-${color}` : ""
    return (
        <div
            className={`bookings-section-dot ${className}`}
        />
    )
}

export default ProgressBarDot