import {useTranslation} from "./useTranslation";
import stringReplacer from "@Translation/functions/stringReplacer";

export default function useQuantityAwareTranslation(translationKey: string, quantity: number, variableReplacements?: object): string {
    const translation = useTranslation(translationKey)
    const translationArray = translation.split("|")

    const quantityArray = translationArray.map((item) => {
        const splitString = item.split("} ")
        const number = splitString[0].replace("{", "")

        const arraySplitString = item.split("] ")
        const arrayNumber = arraySplitString[0].replace("[", "").split(",")
        const arrayMin = arrayNumber[0].includes("{") ? number : arrayNumber[0]
        const arrayMax = arrayNumber[1] ?? number

        const string = splitString[1] ?? arraySplitString[1]

        return {
            string,
            arrayMax: parseInt(arrayMax, 10),
            arrayMin: parseInt(arrayMin, 10),
        }
    })

    const found = quantityArray.find((element) => (
        quantity >= element.arrayMin && quantity <= element.arrayMax
    ))

    if (variableReplacements && Object.entries(variableReplacements)) {
        const replacements = found?.string ?? quantityArray[0].string
        return stringReplacer(replacements, variableReplacements)
    }

    return found?.string ?? quantityArray[0].string
}