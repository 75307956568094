import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconMultimedia(props: IconType) {
    const {
        width = '35',
        height = '35',
        viewBox = '0 0 35 35',
        title = 'IconMultimedia',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none" fillRule="evenodd" transform="translate(1 4)">
                    <g stroke="#2d2d2d">
                        <path
                            d="m1 0h15.9714286v21h-15.9714286c-.55228475 0-1-.4477153-1-1v-19c0-.55228475.44771525-1 1-1z"
                            fill="#4f4f4f"
                        />
                        <path d="m16 0h16c.5522847 0 1 .44771525 1 1v19c0 .5522847-.4477153 1-1 1h-16z" fill="#424242"/>
                    </g>
                    <path d="m9.5 25h13c1.1045695 0 2 .8954305 2 2h-17c0-1.1045695.8954305-2 2-2z" fill="#2d2d2d"/>
                    <path d="m14 21h4v5h-4z" fill="#272827"/>
                    <path
                        d="m11.9746094 14.7246094v-7.0371094h2.7246094v-1.6875h-7.6992188v1.6875h2.72460938v7.0371094zm10.9066406 0 3.1816406-8.7246094h-2.3027343l-2.109375 6.1289062-2.109375-6.1289062h-2.3613282l3.1816407 8.7246094z"
                        fill="#fff"
                        fillRule="nonzero"
                    />
                </g>
            )}
        />

    );
}

export default IconMultimedia;
