import React from "react";
import TicketStatusType from "@MyTicket/types/TicketStatus.type";
import TicketRouteType from "@MyTicket/types/TicketRoute.type";
import IntermodalTransportData from "@MyTicket/components/IntermodalTransportData";
import TicketCompany from "@MyTicket/components/TicketCompany";

interface TransportDataProps {
    status: TicketStatusType,
    outboundRoute: TicketRouteType,
    returnRoute: TicketRouteType,
    baseTickets: TicketRouteType[],
}

function TransportData(props: TransportDataProps) {
    const {
        status,
        outboundRoute,
        returnRoute,
        baseTickets,
    } = props

    const {
        isCancelled,
        isReturn,
        isIntermodal,
    } = status

    const {
        companyName: outboundCompanyName,
        hasMTicket: isOutboundMTicket,
        companyId: outboundCompanyId,
    } = outboundRoute || {}

    const {
        companyName: returnCompanyName,
        hasMTicket: isReturnMTicket,
        companyId: returnCompanyId,
    } = returnRoute || {}

    if (isIntermodal) {
        return (
            <IntermodalTransportData
                baseTickets={baseTickets}
            />
        );
    }

    return (
        <>
            <TicketCompany
                companyName={outboundCompanyName}
                companyId={outboundCompanyId}
                isMTicket={isOutboundMTicket}
                isCancelled={isCancelled}
                className="outbound-transport-data-info"
            />
            {isReturn && (
                <TicketCompany
                    companyName={returnCompanyName}
                    companyId={returnCompanyId}
                    isMTicket={isReturnMTicket}
                    isCancelled={isCancelled}
                    className="return-transport-data-info"
                />
            )}
        </>
    );
}

export default TransportData