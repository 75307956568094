export default function getHandlePhoneInput(setPhoneNumber: (value: string) => void) {
    const handlePhoneInput = ({target: {value}}) => {
        if (value.length >= 2 && value.substring(0, 2) === '00') {
            setPhoneNumber(`+${value.substring(2, value.length)}`)
        } else {
            setPhoneNumber(value)
        }
    }

    return handlePhoneInput
}