type CorridorProps = {
    rowIndex: number
}

function Corridor({rowIndex}: CorridorProps) {
    return (
        <span className="gb--seat-picker-seat gb--seat-picker-seat-label">
            {rowIndex + 1}
        </span>
    );
}

export default Corridor;