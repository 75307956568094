import React from 'react'

import {useTranslation} from "@Translation/hooks/useTranslation";
import Translation from "@Translation/components/Translation";

const PopularLines = ({title, lines, backgroundBlue = false}) => {
    const to = useTranslation('sys.to');
    const className = "gb-container popular-lines__homepage"

    if (!lines || lines.length === 0) {
        return null;
    }

    return (
        <section className={className}>
            <h2 className="lines-title">
                {title}
            </h2>
            <div className="gb--grid-popular-lines popular-lines-list">
                {lines.map((route) => (
                    <div
                        key={`${route.id}-${route.departureStationName}-${route.destinationStationName}`}
                        className="gb--link gb--truncate gb--popular-line-item"
                    >
                            <a
                                href={route.permalinkSlug}
                                key={`${route.id}-${route.departureStationName}-${route.destinationStationName}`}
                            >
                                <Translation translationKey="home_page.most_popular_route" translationVariables={{departureStation: route.departureStationName, destinationStation: route.destinationStationName}}/>
                            </a>
                    </div>
                ))}

            </div>
        </section>
    );
}

export default PopularLines