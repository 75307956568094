import React, {ReactNode} from 'react';
import IconType from "@/types/Icon.type";

interface GenericIconType extends IconType {
    svgIconPath: ReactNode,
}

function GenericSvgIcon(props: GenericIconType) {
    const {
        svgIconPath,
        viewBox,
        width,
        height,
        fill,
        stroke,
        strokeWidth,
        strokeOpacity,
        title,
        style,
        className,
        preserveAspectRatio,
        accessibleTitle,
        transform,
        outline,
    } = props;
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xlinkHref="http://www.w3.org/1999/xlink"
            viewBox={viewBox}
            width={width}
            height={height}
            fill={fill ?? outline}
            stroke={stroke}
            strokeWidth={strokeWidth}
            strokeOpacity={strokeOpacity}
            style={style}
            className={className}
            preserveAspectRatio={preserveAspectRatio}
            transform={transform}
        >

            {accessibleTitle && <title id={title}>{title}</title>}
            {svgIconPath}

        </svg>
    );
}

GenericSvgIcon.defaultProps = {
    accessibleTitle: false,
}

export default GenericSvgIcon;