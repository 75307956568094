import React from 'react';
import GenericSvgIcon from "../GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconLaptopDeals(props: IconType) {
    const {
        width = '70',
        height = '55',
        viewBox = '0 0 70 55',
        title = 'IconLaptopDeals',
        ...rest
    } = props
    return (
        <GenericSvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none">
                    <path
                        d="m62.15 46.2h-55v-39.05c0-3.025 2.475-5.5 5.5-5.5h44c3.025 0 5.5 2.475 5.5 5.5z"
                        fill="#fff"
                    />
                    <path
                        d="m1.65 45.65v1.65c0 3.025 2.475 5.5 5.5 5.5h55c3.025 0 5.5-2.475 5.5-5.5v-1.65z"
                        fill="#ddd"
                    />
                    <g fill="#070c63">
                        <path
                            d="m40.15 47.3h-11c-.935 0-1.65-.715-1.65-1.65s.715-1.65 1.65-1.65h11c.935 0 1.65.715 1.65 1.65s-.715 1.65-1.65 1.65z"
                        />
                        <path
                            d="m62.15 54.45h-55c-3.96 0-7.15-3.19-7.15-7.15v-1.65c0-.935.715-1.65 1.65-1.65h19.25c.935 0 1.65.715 1.65 1.65s-.715 1.65-1.65 1.65h-17.6c0 2.145 1.705 3.85 3.85 3.85h55c2.145 0 3.85-1.705 3.85-3.85h-17.6c-.935 0-1.65-.715-1.65-1.65s.715-1.65 1.65-1.65h19.25c.935 0 1.65.715 1.65 1.65v1.65c0 3.96-3.19 7.15-7.15 7.15z"
                        />
                        <path
                            d="m62.15 41.25c-.935 0-1.65-.715-1.65-1.65v-32.45c0-2.145-1.705-3.85-3.85-3.85h-44c-2.145 0-3.85 1.705-3.85 3.85v32.45c0 .935-.715 1.65-1.65 1.65s-1.65-.715-1.65-1.65v-32.45c0-3.96 3.19-7.15 7.15-7.15h44c3.96 0 7.15 3.19 7.15 7.15v32.45c0 .935-.715 1.65-1.65 1.65z"
                        />
                    </g>
                    <g transform="translate(22 11)">
                        <path
                            d="m29.1388889 29.1944444c-.6944445.6944445-1.8333333.6944445-2.5555556 0l-3.1944444-3.1944444c-.6944445-.6944444-.6944445-1.8611111 0-2.5555556.6944444-.6944444 1.8333333-.6944444 2.5555555 0l3.1944445 3.1944445c.7222222.6944444.7222222 1.8611111 0 2.5555555z"
                            fill="#ddd"
                        />
                        <path
                            d="m12.8888889.83333333c-6.6388889 0-12.00000001 5.38888889-12.00000001 12.02777777 0 6.6388889 5.36111111 12.0277778 12.00000001 12.0277778s12-5.3888889 12-12.0277778c0-6.63888888-5.3888889-12.02777777-12-12.02777777z"
                            fill="#fff"
                        />
                        <path
                            d="m12.8888889 21.8888889c-4.97222223 0-9.00000001-4.0277778-9.00000001-9.0277778 0-4.97222221 4.02777778-9.02777777 9.00000001-9.02777777 4.9722222 0 9 4.05555556 9 9.02777777 0 5-4.0277778 9.0277778-9 9.0277778z"
                            fill="#fff"
                        />
                        <path
                            d="m12.8888889 25.75c-7.08333334 0-12.83333334-5.7777778-12.83333334-12.8611111 0-7.11111112 5.75-12.8888889 12.83333334-12.8888889 7.0833333 0 12.8333333 5.77777778 12.8333333 12.8611111 0 7.1111111-5.7777778 12.8888889-12.8333333 12.8888889zm0-24.08333333c-6.16666668 0-11.16666668 5.02777777-11.16666668 11.19444443 0 6.1666667 5 11.1944445 11.16666668 11.1944445 6.1666667 0 11.1666667-5.0277778 11.1666667-11.1944445 0-6.16666666-5.0277778-11.19444443-11.1666667-11.19444443z"
                            fill="#070c63"
                        />
                        <path
                            d="m12.8888889 21.8888889c-4.97222223 0-9.00000001-4.0277778-9.00000001-9.0277778 0-4.97222221 4.02777778-9.02777777 9.00000001-9.02777777 4.9722222 0 9 4.05555556 9 9.02777777 0 5-4.0277778 9.0277778-9 9.0277778z"
                            fill="#f5a623"
                        />
                        <g fill="#fff">
                            <path
                                d="m10.2222222 17.6388889c-.1666666 0-.33333331-.0555556-.4722222-.1388889-.38888889-.25-.47222222-.7777778-.22222222-1.1666667l5.27777782-7.77777774c.25-.38888889.7777777-.47222223 1.1666666-.22222223s.4722222.77777778.2222222 1.16666667l-5.2777777 7.7777778c-.1666667.2222222-.4166667.3611111-.6944445.3611111z"
                            />
                            <path
                                d="m10.2222222 12.0833333c-1.2222222 0-2.2222222-1-2.2222222-2.22222219 0-1.22222222 1-2.22222222 2.2222222-2.22222222s2.2222222 1 2.2222222 2.22222222c0 1.22222219-1 2.22222219-2.2222222 2.22222219zm0-2.77777774c-.30555553 0-.55555553.25-.55555553.55555555 0 .30555559.25.55555559.55555553.55555559.3055556 0 .5555556-.25.5555556-.55555559 0-.30555555-.25-.55555555-.5555556-.55555555z"
                            />
                            <path
                                d="m15.5 18.1944444c-1.2222222 0-2.2222222-1-2.2222222-2.2222222s1-2.2222222 2.2222222-2.2222222 2.2222222 1 2.2222222 2.2222222-1 2.2222222-2.2222222 2.2222222zm0-2.7777777c-.3055556 0-.5555556.25-.5555556.5555555 0 .3055556.25.5555556.5555556.5555556s.5555556-.25.5555556-.5555556c0-.3055555-.25-.5555555-.5555556-.5555555z"
                            />
                        </g>
                        <path
                            d="m23.4166667 24.2777778c-.2222223 0-.4166667-.0833334-.5833334-.25l-2.0555555-2.0555556c-.3333334-.3333333-.3333334-.8611111 0-1.1666666.3333333-.3333334.8611111-.3333334 1.1666666 0l2.0555556 2.0555555c.3333333.3333333.3333333.8611111 0 1.1666667-.1666667.1666666-.3888889.25-.5833333.25z"
                            fill="#070c63"
                        />
                        <path
                            d="m27.8888889 30.5555556c-.6944445 0-1.3611111-.2777778-1.8611111-.7777778l-3.1944445-3.1944445c-1.0277777-1.0277777-1.0277777-2.6944444 0-3.7222222.5-.5 1.1666667-.7777778 1.8611111-.7777778.6944445 0 1.3611112.2777778 1.8611112.7777778l3.1944444 3.1944445c1.0277778 1.0277777 1.0277778 2.6944444 0 3.7222222-.5.5-1.1666667.7777778-1.8611111.7777778zm-3.1944445-6.8055556c-.25 0-.5.1111111-.6944444.2777778-.3888889.3888889-.3888889 1 0 1.3888889l3.1944444 3.1944444c.3611112.3611111 1 .3611111 1.3611112 0 .3888888-.3888889.3888888-1 0-1.3888889l-3.1944445-3.1944444c-.1666667-.1944445-.4166667-.2777778-.6666667-.2777778z"
                            fill="#070c63"
                        />
                    </g>
                </g>
            )}
        />

    );
}

export default IconLaptopDeals;
