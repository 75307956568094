import LoaderButton from "@Generic/Loaders/LoaderButton";
import LoginButton from "@CheckOrder/components/MainSection/BuyerInfo/LoginButton";
import LogoutButton from "@CheckOrder/components/MainSection/BuyerInfo/LogoutButton";
import {useAppSelector} from "@Hooks/selector/useAppSelector";

function UserCTAButton() {
    const {user, loading} = useAppSelector((state) => state?.userProfile)
    if (!user?.email) {
        return (
            loading ? (
                <LoaderButton fill="#4a79e2"/>
            ) : (
                <LoginButton/>
            )
        )
    }
    return (
        <LogoutButton/>
    )
}

export default UserCTAButton;