import React from 'react';
import IconCalendarWithDate from "@Icons/IconCalendarWithDate";
import Column from "@Containers/components/Column";
import Row from "@Containers/components/Row";
import ActiveResultsCountHeading from "@SearchResults/components/HeadingContent/ActiveResultsCountHeading";
import useSearchResults from "@SearchResults/hooks/useSearchResults";
import IconArrowRight from "@Icons/IconArrowRight";
import useIsMobile from "@Hooks/selector/useIsMobile";
import ApiQueryLink from "@Features/debug/components/ApiQueryLink";
import useIsDebugUser from "@Features/debug/hooks/useIsDebugUser";
import useIsCheckOrder from "@CheckOrder/hooks/useIsCheckOrder";
import useRouteMatch from "@Routing/hooks/useRouteMatch";

interface TravelInfoProps {
    /** Name of departure station based on current locale */
    departureStationName: string,
    /** Name of destination station based on current locale */
    destinationStationName: string,
    /** Date for which we're searching, pass return date if displaying return direction info */
    date: string,
}

function TravelInfo(props: TravelInfoProps) {
    const {
        departureStationName,
        destinationStationName,
        date,
    } = props;

    const {isLoading} = useSearchResults()
    const isMobile = useIsMobile()
    const isDebugUser = useIsDebugUser()
    const isCheckOrder = useRouteMatch('checkOrder')

    return (
        <div className="gb--row gb--row-middle gb--travel-info__title">
            {!isMobile && (
                <IconCalendarWithDate width="5vh" height="4.5vh" date={date}/>
            )}
            <Column>
                <Row center>
                <span className="gb--emphasize gb--emphasize-default">
                    {departureStationName}
                </span>
                <IconArrowRight width="1.3rem" fill="#070c63"/>
                <span className="gb--emphasize gb--emphasize-default">
                    {destinationStationName}
                </span>
                {!isCheckOrder && isDebugUser && (
                    <ApiQueryLink/>
                )}
                </Row>
                {!isLoading ? (
                    <ActiveResultsCountHeading
                        date={date}
                    />
                ) : (
                    <small className="filtered-routes-count">
                        loading...
                    </small>
                )}
            </Column>
        </div>
    );
}

export default TravelInfo;
