import React from 'react';
import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "@/types/Icon.type";

interface IconInfoProps extends IconType {
    bold?: boolean,
}

function IconInfo(props: IconInfoProps) {
    const {
        width = '20',
        height = '20',
        fill = '#9b9b9b',
        viewBox = '0 0 20 20',
        title = 'IconInfo',
        bold,
        ...rest
    } = props

    if (bold) {
        return (
            <GenericSvgIcon
                width={width}
                height={height}
                fill={fill}
                viewBox={viewBox}
                title={title}
                {...rest}
                svgIconPath={(
                    <path
                        d="m8 16c1.46153846 0 2.8141026-.3653846 4.0576923-1.0961538 1.2051282-.6923077 2.1538462-1.6410257 2.8461539-2.8461539.7307692-1.2435897 1.0961538-2.59615384 1.0961538-4.0576923s-.3653846-2.81410256-1.0961538-4.05769231c-.6923077-1.2051282-1.6410257-2.15384615-2.8461539-2.84615384-1.2435897-.73076923-2.59615384-1.09615385-4.0576923-1.09615385-1.44871795 0-2.79487179.36538462-4.03846154 1.09615385-1.2051282.7051282-2.16025641 1.66025641-2.86538461 2.86538461-.73076923 1.24358975-1.09615385 2.58974359-1.09615385 4.03846154s.36538462 2.7948718 1.09615385 4.0384615c.69230769 1.2051282 1.64102564 2.1602564 2.84615384 2.8653847 1.24358975.7307692 2.59615385 1.0961538 4.05769231 1.0961538zm0-1.5769231c-1.15384615 0-2.23076923-.2948718-3.23076923-.8846154-.97435898-.5641025-1.74358974-1.3333333-2.30769231-2.3076923-.58974359-1-.88461538-2.07692305-.88461538-3.2307692s.29487179-2.23076923.88461538-3.23076923c.56410257-.97435898 1.33333333-1.74358974 2.30769231-2.30769231 1-.58974359 2.07692308-.88461538 3.23076923-.88461538s2.2307692.29487179 3.2307692.88461538c.974359.56410257 1.7435898 1.33333333 2.3076923 2.30769231.5897436 1 .8846154 2.07692308.8846154 3.23076923s-.2948718 2.2307692-.8846154 3.2307692c-.5641025.974359-1.3333333 1.7435898-2.3076923 2.3076923-1 .5897436-2.07692305.8846154-3.2307692.8846154zm.78846154-8.82692305v-1.61538462h-1.57692308v1.61538462zm0 6.42307695v-4.80769234h-1.57692308v4.80769234z"
                    />
                )}
            />
        )
    }
    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill={fill}>
                    <path
                        d="m10 15.4716981c-.32075472 0-.56603774-.245283-.56603774-.5660377v-5.22641512c0-.32075471.24528302-.56603773.56603774-.56603773.3207547 0 .5660377.24528302.5660377.56603773v5.22641512c0 .3207547-.245283.5660377-.5660377.5660377z"
                    />
                    <path
                        d="m10 5.98113208c-.32075472 0-.56603774.24528301-.56603774.56603773s.24528302.56603774.56603774.56603774c.3207547 0 .5660377-.24528302.5660377-.56603774s-.245283-.56603773-.5660377-.56603773z"
                    />
                    <path
                        d="m10 20c-5.50943396 0-10-4.490566-10-10 0-5.50943396 4.49056604-10 10-10 5.509434 0 10 4.49056604 10 10 0 5.509434-4.490566 10-10 10zm0-18.86792453c-4.88679245 0-8.86792453 3.98113208-8.86792453 8.86792453 0 4.8867925 3.98113208 8.8679245 8.86792453 8.8679245 4.8867925 0 8.8679245-3.981132 8.8679245-8.8679245 0-4.88679245-3.981132-8.86792453-8.8679245-8.86792453z"
                    />
                </g>
            )}
        />

    );
}

export default IconInfo;
