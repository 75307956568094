export interface FilterBoundsState {
    minPrice: number,
    maxPrice: number,
    minDuration: number,
    maxDuration: number,
    minRating: number,
    maxRating: number,
    minRouteDepartureTime: number,
    maxRouteDepartureTime: number,
    minRouteArrivalTime: number,
    maxRouteArrivalTime: number,
    maxChanges: number,
}

export const filterBounds = {
    minPrice: 999999,
    maxPrice: 0,
    minDuration: 999999999, // Expressed in milliseconds
    maxDuration: 0,
    minRating: 100,
    maxRating: 0,
    minRouteDepartureTime: Date.parse("1970-01-01T00:00:00"),
    maxRouteDepartureTime: Date.parse("2270-01-01T23:59:00"),
    minRouteArrivalTime: Date.parse("1970-01-01T00:00:00"),
    maxRouteArrivalTime: Date.parse("2270-01-01T23:59:00"),
    maxChanges: 0,
} as FilterBoundsState