import React from 'react'
import Row from "@Containers/components/Row";
import {useTranslation} from "@Translation/hooks/useTranslation";
import Carousel from "@Pages/mobileApp/features/bookingSection/components/Carousel";

const BookingsSection = () => {
    const {
        fast_and_simple: fastAndSimple,
        plan_every_detail: planEveryDetail,
        choose_from_list: chooseFromList,
        detailed_route_info: detailedRouteInfo,
        access_info: accessInfo,
        accessible_info: {
            part1: accessibleInfoPart1,
            part2: accessibleInfoPart2,
        },
        my_bookings: myBookings,
        all_in_one_place: allInOnePlace,
        change_of_plans: {
            part1: changeOfPlansPart1,
            part2: changeOfPlansPart2,
            part3: changeOfPlansPart3,
        },
    } = useTranslation("mobile_app.bookings")

    const bookingInfoData = [
        {
            label: fastAndSimple,
            title: planEveryDetail,
            content: <p>{chooseFromList}</p>,
            imagePath: "images/mobile-app-landing-page/carousel_fast_and_simple_booking",
            color: "green",
            index: 0,
        },
        {
            label: detailedRouteInfo,
            title: accessInfo,
            content: <p>
                {accessibleInfoPart1}
                <b>{accessibleInfoPart2}</b>
                     </p>,
            imagePath: "images/mobile-app-landing-page/carousel_detailed_journey_information",
            color: "orange",
            index: 1,
        },
        {
            label: myBookings,
            title: allInOnePlace,
            content: <p>
                {changeOfPlansPart1}
                <b>{changeOfPlansPart2}</b>
                {changeOfPlansPart3}
                     </p>,
            imagePath: "images/mobile-app-landing-page/carousel_my_bookings",
            color: "blue",
            index: 2,
        },
    ]

    return (
        <Row className="bookings-section-background">
            <div className="mobile-app-landing-page-section">
                <Carousel
                    data={bookingInfoData}
                />
            </div>
        </Row>
    )
}

export default BookingsSection