import useIntersectionObserver from "@Scroll/hooks/useIntersectionObserver";
import {useRef, useState} from "react";

export default function useSectionFocusedObserver() {
    const [visible, setVisible] = useState(false)
    const ref = useRef(null)

    const observeCallback = (entries) => {
        const [entry] = entries
        if (entry.isIntersecting) {
            setVisible(true)
        } else {
            setVisible(false)
        }
    }

    const options = {
        root: null,
        rootMargin: "0px 0px 0px 0px",
        threshold: 1,
    }

    const {tooltipRef} = useIntersectionObserver(ref, observeCallback, options)

    return {
        visible,
        tooltipRef,
    }
}