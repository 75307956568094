import React, {ReactNode} from "react";

interface GenericCheckboxProps {
    className?: string,
    checked: boolean,
    onClick: (any?) => any,
    children?: ReactNode,
    onBlur?: (event) => void
}

function GenericCheckbox({className, checked, onClick, children, onBlur}: GenericCheckboxProps) {
    return (
        <div className={`md-checkbox ${className}`}>
            <input
                type="checkbox"
                checked={checked}
                // Return null to silence react error.
                // we're using label as change handler instead of checkbox
                onChange={() => null}
            />
            <button
                type="button"
                className="gb--input-label"
                onClick={onClick}
                onBlur={onBlur}
            >
                {children}
            </button>
        </div>
    );
}

export default GenericCheckbox;