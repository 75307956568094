import React from 'react';
import GetByApsLogo from "@Generic/Logotypes/GetByApsLogo";
import useIsCompanyStore from "@Hooks/selector/useIsCompanyStore";
import useCompanyStyle from "@Hooks/selector/useCompanyStyle";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import NavigationContextAwareNavLink from "@Routing/components/NavigationContextAwareNavLink";

function Logo() {
    const isCompanyStore = useIsCompanyStore()
    const {topLogoImage} = useCompanyStyle()
    const {homePage} = useAppSelector((state) => state?.page.urlsObject)

    return (
        <span className="logo">
            <NavigationContextAwareNavLink
                routeName={homePage.routeName}
                to={homePage.url}
            >
                {isCompanyStore ? (
                    <img
                        width="155"
                        height="80"
                        src={`https://d2bgjx2gb489de.cloudfront.net/admin/public_html/${topLogoImage}`}
                        alt="logo"
                    />
                ) : (
                    <GetByApsLogo/>
                )}
            </NavigationContextAwareNavLink>
        </span>
    );
}

export default Logo;