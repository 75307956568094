import React from 'react';
import IconFerryFull from '@Icons/IconFerryFull';
import IconTrainFilledTab from "@Icons/IconTrainFilledTab";
import IconPlaneTab from "@Icons/IconPlaneTab";
import IconMinibus from "@Icons/RouteIcons/IconMinibus";
import IconMinivanTab from "@Icons/IconMinivanTab";
import IconBusFilledTab from "@Icons//IconBusFilledTab";
import {RouteTransferTypeValuesType} from "@Route/features/transportType/types/routeTransferType.type";

interface TabVehicleIconProps {
    fill: string,
    width: string,
    height: string,
    tag: RouteTransferTypeValuesType,
}

function TabVehicleIcon({fill, width, height, tag = "bus"}: TabVehicleIconProps) {
    const components = {
        bus: IconBusFilledTab,
        ferry: IconFerryFull,
        train: IconTrainFilledTab,
        plane: IconPlaneTab,
        minivan: IconMinibus,
        privateTransfer: IconMinivanTab,
    };

    const IconVehicleTab = components?.[tag] ?? components.bus;

    return <IconVehicleTab width={width} height={height} fill={fill}/>
}

export default TabVehicleIcon;