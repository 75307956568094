import React from 'react';
import IconWindowSeat from "@Icons/FilledOutline/IconWindowSeat";
import Row from "@Containers/components/Row";
import Column from "@Containers/components/Column";
import {useTranslation} from "@Translation/hooks/useTranslation";
import Translation from "@Translation/components/Translation";
import {useAppSelector} from "@Hooks/selector/useAppSelector";

function TravelConnections() {
    const {
        homepageData: {
            connectionsCount,
            routeCount,
        },
    } = useAppSelector((state) => state.page)

    const {
        transportTypeKeyword,
    } = useAppSelector((state) => state.searchResults)

    const {connections, routes} = useTranslation("home_page")
    const youCanFind = useTranslation(
        "home_page.more_than",
        {
            transportType: transportTypeKeyword,
        }
    )

    return (
        <section className="travel-connections gb--row gb--row-middle gb--row-wrap">
            <div className="travel-connections__description">
                <h2>
                    <Translation translationKey="home_page.seats_reserved"/>
                    <br/>
                    {' '}
                    <Translation translationKey="home_page.horizon"/>
                </h2>
                <h3 className="gb--emphasize gb--emphasize-default">
                    {youCanFind}
                </h3>
                <Row center wrap>
                    <Column className="travel-connections__counter">
                        {routeCount}
                        <small className="gb--emphasize-default">{routes}</small>
                    </Column>
                    <Column className="travel-connections__counter">
                        {connectionsCount}
                        <small className="gb--emphasize-default">{connections}</small>
                    </Column>
                </Row>
            </div>
            <IconWindowSeat/>
        </section>
    );
}

export default TravelConnections;