import React from 'react';
import IconCopy from "@Icons/IconCopy";
import {useToggle} from "@Hooks/dispatch/useToggle";
import {copyTextToClipboard} from "@String/copyTextToClipboard";
import Tooltip from "@Popover/components/Tooltip";
import {useTranslation} from "@Translation/hooks/useTranslation";

interface CopyVoucherCodeButtonProps {
    code: string,
    className: string,
    children?: React.ReactNode
}

function CopyVoucherCodeButton({code, className, children}: CopyVoucherCodeButtonProps) {
    const {
        code_copied_to_clipboard: codeCopiedToClipboardLabel,
        copy_code_to_clipboard: copyCodeToClipboardLabel,
    } = useTranslation('my_profile.my_vouchers')

    const {onChange, value: copied} = useToggle()

    const copyCodeToClipboard = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        await copyTextToClipboard(code)
        onChange()
    }

    const trigger = () => (
        <button
            onClick={copyCodeToClipboard}
            type="button"
            className={`gb--label ${className}`}
        >
            {children}
            <IconCopy/>
        </button>
    )

    const overlay = () => (
        <span>
            {copied ? codeCopiedToClipboardLabel : copyCodeToClipboardLabel}
        </span>
    )

    return (
        <Tooltip
            tooltipContent={overlay()}
        >
            {trigger()}
        </Tooltip>

    );
}

export default CopyVoucherCodeButton;