import Notification from "@Features/notification/components/Notification";
import Translation from "@Translation/components/Translation";
import RoutesList from "@RoutesList/components/RoutesList";
import React from "react";
import RoutesProgressBar from "@RoutesList/components/RoutesProgressBar";
import ParamsType from "@SearchResults/types/params/Params.type";
import PassengerGroupType from "@SearchResults/types/passenger/PassengerGroup.type";
import RouteIconType from "@SearchResults/types/routeIcons/RouteIcon.type";
import CompanyType from "@SearchResults/types/company/Company.type";
import IntermodalRouteType from "@Intermodal/types/IntermodalRoute.type";
import {isEmpty} from "@Array/isEmpty";

interface SuggestedRoutesListProps {
    params: ParamsType,
    passengerGroups: Record<string, PassengerGroupType>
    routeIcons: Record<number, RouteIconType>,
    companies: Record<string, CompanyType>,
    suggestedRoutes: IntermodalRouteType[],
    resolvedSuggestionsCount: number,
    totalSuggestionsCount: number,
}

function SuggestedRoutesList(props: SuggestedRoutesListProps) {
    const {
        params,
        passengerGroups,
        routeIcons,
        companies,
        suggestedRoutes,
        resolvedSuggestionsCount,
        totalSuggestionsCount,
    } = props;

    const suggestionRoutesLoading = resolvedSuggestionsCount < totalSuggestionsCount
    return (
        <>
            {suggestionRoutesLoading && (
                <RoutesProgressBar
                    resolvedCount={resolvedSuggestionsCount}
                    totalCount={totalSuggestionsCount}
                    queriedRoutesTitle="suggestions"
                />
            )}
            {!isEmpty(suggestedRoutes) && (
                <>
                    <Notification>
                        <h2>
                            <Translation translationKey="no_res.suggestions_title1"/>
                        </h2>
                        <Translation translationKey="no_res.suggestions_title2"/>
                    </Notification>
                    <RoutesList
                        routes={[]}
                        intermodalRoutes={suggestedRoutes}
                        companies={companies}
                        routeIcons={routeIcons}
                        passengerGroups={passengerGroups}
                        params={params}
                    />
                </>
            )}
        </>
    );
}

export default SuggestedRoutesList;