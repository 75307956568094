import React, {ReactNode} from 'react';
import useCurrency from "@Currency/hooks/useCurrency";
import Row from "@Containers/components/Row";
import useFixedEurConversion from "@Currency/hooks/useFixedEurConversion";

interface PriceWithCurrencyProps {
    price: number,
    children?: ReactNode,
    className?: string,
    round?: boolean,
    linebreak?: boolean,
    displayFromNotice?: boolean,
}

function PriceWithCurrency(props: PriceWithCurrencyProps) {
    const {
        price,
        className = "currency",
        linebreak = true,
        children = null,
        round = true,
        displayFromNotice = false,
    } = props

    const {sign} = useCurrency()
    const displayPrice = (Math.round(Number((price)) * 100) / 100).toFixed(2)
    const {shouldDisplayPriceInKn, priceInKn} = useFixedEurConversion(price)

    const priceWithSign = `${displayPrice} ${sign}`
    const style = {
        fontSize: "12px",
        paddingLeft: ".5rem",
        lineHeight: "1.2",
    }

    const setClassName = () => (!linebreak ? className : `${className} gb--column`)

    if (children) {
        return (
            <Row>
                {children}
                <span className={setClassName()}>
                    {priceWithSign}
                    {shouldDisplayPriceInKn && (
                        <small style={style}>
                            {priceInKn}
                        </small>
                    )}
                </span>
            </Row>
        );
    }

    return (
        <span className={setClassName()}>
            {displayFromNotice && (
                <small style={style}>
                    from
                </small>
            )}
            {priceWithSign}
            {shouldDisplayPriceInKn && (
                <small style={style}>
                    {priceInKn}
                </small>
            )}
        </span>
    );
}

export default PriceWithCurrency;