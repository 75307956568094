import React from "react";
import IconVoucher from "@Icons/IconVoucher";
import IconTicketCard from "@Icons/IconTicketCard";
import useMyVouchersLink from "@MyProfile/features/links/hooks/useMyVouchersLink";
import useMyTicketsLink from "@MyProfile/features/links/hooks/useMyTicketsLink";
import MyProfileLink from "@MyProfile/features/links/components/MyProfileLink";

function MyProfileLinks() {
    const vouchers = useMyVouchersLink()
    const tickets = useMyTicketsLink()
    const routes = [
        {...tickets, icon: <IconTicketCard height="40px" fill="#070c63"/>},
        {...vouchers, icon: <IconVoucher height="50px" fill="#070c63"/>},
    ]

    return (
        <div className="gb--tabs gb--row my-profile-nav-links">
            <ul className="nav nav-tabs gb--tabs-nav">
                {routes.map((route) => (
                    <MyProfileLink
                        key={route.url}
                        url={route.url}
                        isActive={route.isActive()}
                        icon={route.icon}
                        label={route.label}
                        description={route.descriptionText}
                    />
                ))}
            </ul>
        </div>
    );
}

export default MyProfileLinks;
