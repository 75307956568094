import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconGbbBlogs(props: IconType) {
    const {
        width = '40',
        height = '40',
        viewBox = '0 0 40 40',
        title = 'IconGbbBlogs',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none" transform="matrix(.96592583 .25881905 -.25881905 .96592583 8.962148 -.691254)">
                    <path
                        d="m28.1639492 1.1488496h-21.25531912c-1.57978723 0-2.87234042 1.30909091-2.87234042 2.90909091v26.18181819c0 1.6 1.29255319 2.9090909 2.87234042 2.9090909h21.25531912c1.5797873 0 2.8723405-1.3090909 2.8723405-2.9090909v-26.18181819c0-1.6-1.2925532-2.90909091-2.8723405-2.90909091z"
                        fill="#fff"
                    />
                    <path
                        d="m25.7995447 27.7167547h-11.1837249c-.5432095 0-.958605-.4153955-.958605-.958605s.4153955-.958605.958605-.958605h11.1837249c.5432095 0 .958605.4153955.958605.958605s-.4153955.958605-.958605.958605z"
                        fill="#f5a623"
                    />
                    <path
                        d="m25.7995447 8.54465488h-11.1837249c-.5432095 0-.958605-.4153955-.958605-.95860499s.4153955-.95860499.958605-.95860499h11.1837249c.5432095 0 .958605.4153955.958605.95860499s-.4153955.95860499-.958605.95860499z"
                        fill="#d3d8dd"
                    />
                    <path
                        d="m25.7995447 22.9237297h-11.1837249c-.5432095 0-.958605-.4153955-.958605-.958605s.4153955-.958605.958605-.958605h11.1837249c.5432095 0 .958605.4153955.958605.958605s-.4153955.958605-.958605.958605z"
                        fill="#7ed321"
                    />
                    <path
                        d="m25.7995447 13.3376798h-11.1837249c-.5432095 0-.958605-.4153955-.958605-.958605s.4153955-.958605.958605-.958605h11.1837249c.5432095 0 .958605.4153955.958605.958605s-.4153955.958605-.958605.958605z"
                        fill="#d3d8dd"
                    />
                    <path
                        d="m25.7995447 18.1307048h-11.1837249c-.5432095 0-.958605-.4153955-.958605-.958605s.4153955-.958605.958605-.958605h11.1837249c.5432095 0 .958605.4153955.958605.958605s-.4153955.958605-.958605.958605z"
                        fill="#d3d8dd"
                    />
                    <path
                        d="m11.1906707 30.4482877c-.5666667 0-1-.3755555-1-.8666666v-24.2666667c0-.49111111.4333333-.86666666 1-.86666666s1 .37555555 1 .86666666v24.2666667c0 .4911111-.4333333.8666666-1 .8666666z"
                        fill="#4a79e2"
                    />
                    <path
                        d="m28.2181818 0h-21.52727271c-2.09454545 0-3.78181818 1.7-3.78181818 3.81034483v2.05172414h-.58181818c-1.28 0-2.32727273 1.05517241-2.32727273 2.34482758s1.04727273 2.34482755 2.32727273 2.34482755h.58181818v12.8965518h-.58181818c-1.28 0-2.32727273 1.0551724-2.32727273 2.3448275 0 1.2896552 1.04727273 2.3448276 2.32727273 2.3448276h.58181818v2.0517242c0 2.1103448 1.68727273 3.8103448 3.78181818 3.8103448h21.52727271c2.0945455 0 3.7818182-1.7 3.7818182-3.8103448v-26.37931037c0-2.11034483-1.6872727-3.81034483-3.7818182-3.81034483zm2.0363637 30.1896552c0 1.1431034-.9018182 2.0517241-2.0363637 2.0517241h-21.52727271c-1.13454545 0-2.03636364-.9086207-2.03636364-2.0517241v-2.0517242h.58181819c1.28 0 2.32727272-1.0551724 2.32727272-2.3448276 0-.4982758-.37818181-.8793103-.87272727-.8793103-.49454545 0-.87272727.3810345-.87272727.8793103 0 .3224138-.26181818.5862069-.58181818.5862069h-2.90909091c-.32 0-.58181818-.2637931-.58181818-.5862069 0-.3224137.26181818-.5862068.58181818-.5862068h1.45454545c.49454546 0 .87272727-.3810345.87272727-.8793104v-13.7758621h.58181819c1.28 0 2.32727272-1.05517238 2.32727272-2.34482755 0-.49827586-.37818181-.87931034-.87272727-.87931034-.49454545 0-.87272727.38103448-.87272727.87931034 0 .32241379-.26181818.5862069-.58181818.5862069h-2.90909091c-.32 0-.58181818-.26379311-.58181818-.5862069s.26181818-.58620689.58181818-.58620689h1.45454545c.49454546 0 .87272727-.38103449.87272727-.87931035v-2.93103448c0-1.14310345.90181819-2.05172414 2.03636364-2.05172414h21.52727271c1.1345455 0 2.0363637.90862069 2.0363637 2.05172414z"
                        fill="#444b54"
                    />
                </g>
            )}
        />
    );
}

export default IconGbbBlogs;
