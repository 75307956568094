import React from 'react';
import GenericSvgIcon from "../GenericSvgIcon";
import IconType from "@/types/Icon.type";

const IconMap = (props: IconType) => {
    const {
        width = '35',
        height = '35',
        fill = '#e8eaf1',
        viewBox = '0 0 35 35',
        title = 'IconMap',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...{width, height, fill, viewBox, title, ...rest}}
            svgIconPath={(
                <g fill="none">
                    <rect fill={fill} height="35" rx="3.888889" width="35"/>
                    <path
                        d="m13.4604167 14.8034317c.4025-.2138889 10.7742326-2.3249595 10.7742326-2.3249595s1.1306285 3.9686111 1.2366007 5.5056945c.1059722 1.5361111-1.6955556 5.1926389-1.6955556 5.1926389s-10.8451388-5.7230961-11.4809722-6.3589294c-.6358333-.6368056-.5298611-1.1131945 1.1656945-2.0144445z"
                        fill="#b3dd83"
                    />
                    <path
                        d="m0 13.5260196c9.94097222 2.4351949 33.5980556 17.1115627 35 17.8262026v-4.85518c-11.1543056-6.0846004-23.0640574-8.3525349-21.5395833-10.5177249.8324858-1.1823684 20.1901389-2.9291599 21.5395833-4.2847743v-3.67565411c-1.0198611.35816671-16.7893056 5.30391551-21.5395833 5.09646671-3.79944448-.165959-12.31125003-4.03974791-13.4604167-4.40045481z"
                        fill="#6a9fff"
                    />
                    <path
                        d="m0 16.4811111s9.49486892 2.6915972 12.97348 4.9335417c6.9193056 4.4595833 19.5395756 12.1089699 20.2347144 12.8429977-.4839504.2982743-.9094844.4825296-1.2766019.5527662-.495142.09473-1.3629973.1579244-2.6035659.1895833 0 0-15.927962-11.4701766-19.3239413-12.8627292-3.39597941-1.3925526-10.0040853-3.268868-10.0040853-3.268868z"
                        fill="#7ed321"
                        fillOpacity=".497314"
                    />
                    <g fill="#fff">
                        <path
                            d="m16.4995833 29.6683333c-.8818055-.4997222-1.6420833-.9304166-2.2905555-1.2648611-.5551389-.2858333-1.2988889-.6494444-2.0922222-1.0373611-1.8326389-.8963889-4.11250004-2.0115278-4.5743056-2.4373611-.64263889-.5969444-4.27097222 2.4208333-7.5425 5.3890278v.6572222c3.23263889-2.9351389 6.70152778-5.7429167 7.21291667-5.6894444.51333333.4744444 2.63666666 1.5127777 4.69000003 2.5170833.7894444.3859722 1.5302777.7486111 2.0825 1.0325.6397222.3295833 1.3980555.7593055 2.2740277 1.2561111.9294445.5269444 1.9920834 1.1258333 3.1577778 1.7461111-.6669444.7486111-1.8034722 1.9979167-2.8651389 3.1626389h.6581945c1.0169444-1.1151389 2.0552778-2.2594444 2.6522222-2.93125 1.9104167 1.0013889 4.0969444 2.0426389 6.4963889 2.93125h1.49625c-4.6316667-1.5419444-8.5672222-3.7518056-11.3555556-5.3316667z"
                        />
                        <path
                            d="m.61743706 6.67170254 11.07839624-6.28864698 3.3473611 3.78972222-2.4120833.94791666.456447 3.99472904.4357114-.17130652-.4357114-3.43701333 11.6700808-4.63210363 1.3572222 2.63958333c.0418056.08263889.1263889.13222223.2158333.13222223.0194445 0 .0398612-.00194445.0593056-.00680556l5.8430556-1.46125.9751388 4.49295254.4695834-.12347222-1.5118056-6.54823032h-.5026389l.44625 1.70722222-5.6505555 1.41361111-1.2473611-2.42472222 1.7704166-.69611111h-1.3270833l-10.1305556 3.98319444-3.395-3.84416666.2488889-.13902778h-1.0198611l.00875.01069444-10.98797958 6.23809144z"
                        />
                        <path
                            d="m7.39083333 9.65222222c.27416667.66597218 1.20944445-.05833333 1.73638889.20513889.50263889.25083329 3.65652778.33347219 4.36333338.34902779.3509722.84.7573611 1.5798611 1.1919444 1.8977778.3752778.2751389.9926389.3743055 1.6877778.3743055 1.6673611 0 3.7868055-.5697222 4.1115278-.6601389.0038888-.0009722.0058333-.0058333.00875-.0068055l.0009722.0038889c1.8111589-.6649978 2.7247478-1.0330385 2.7407666-1.1041221.0160189-.0710837-.8560885.112883-2.6163222.5518998l-.1341666-1.61097218c-5.170926-.96444444-7.5664815-.9437037-7.1866667.06222222-1.1874074-.02981481-3.1555093-.05055555-5.90430557-.06222222zm12.35986107-1.05875.3956945 2.81263888c-1.3202778.3441667-4.3069445.9459722-5.1761111.3072222-.5425-.3976389-1.0898611-1.7130555-1.5098611-2.99444441z"
                        />
                        <path
                            d="m35 24.3016667-8.6343056-4.0736111c.1147223-.3626389.3305556-1.1306945.4044445-1.8161112 2.4798148-.8257407 3.9115741-1.251574 4.2952778-1.2775.5852777-.0388888 2.5491666-.34125 3.9336111-.5609722v-.4929166c-1.3805556.2197222-3.3940278.5298611-3.9656945.5677777-.569074.0382408-1.986574.463426-4.2525 1.2755556-.0301389-.7758333-.3480555-1.5711111-.5444444-1.9755556l8.7636111-2.5433333v-.5065278l-9.1826389 2.6668056c-.0709722.0204166-.1293055.0738889-.1575.1419444-.0272222.0690278-.0223611.1468056.0145833.2119445.0068056.0126389.6931945 1.225.6368056 2.2361111-.0495833.8827778-.4686111 2.10875-.4734722 2.1213889-.0408334.1176389.0136111.2459722.1263889.2994444l9.0358333 4.2622222z"
                        />
                    </g>
                    <path
                        d="m24.07125 19.9801389c.1283333-1.3825.0602778-3.5281945 0-3.9773611-.105-.7855556-.8127778-2.1806945-.8827778-2.9176389-.13125-1.3893056.9644445-2.1243056 1.8977778-2.4295833.9343056-.30625 9.91375-2.54625004 9.91375-2.54625004v-2.27597223l-11.3020833 2.87486111s-5.7205556-.52013888-9.4966667-.84680555c-3.1606944-.27319445-5.50180556.28-7.71361111.06611111-1.89-.18180556-6.48763889-2.09416667-6.48763889-2.09416667v1.94444445c.69513889.50555555 4.06777778 1.7675 6.22805556 2.04166666 2.16027777.27416666 6.42833334-.21680555 7.87888884-.09333333 1.7655556.15069445 7.6426389.89055559 7.6426389.89055559s-.7680555.8011111-.4579166 2.37125c.3713889 1.56625 1.015 2.7941666 1.1277777 3.4718055.0690278.4131945-.1565277 2.3595834-.3023611 3.5204167-.07.5609722-.3684722 1.0636111-.8254166 1.3970833l-18.66083337 13.6227778h3.16847223l16.47041664-11.7502778c.2343056-.1672222 1.7315278-2.5190278 1.8015278-3.2695833z"
                        fill="#f5a623"
                    />
                    <g transform="matrix(.57357644 .81915204 -.81915204 .57357644 13.593906 23.7505)">
                        <circle cx="3.480227" cy="3.480227" fill="#4a79e2" r="3.480227"/>
                        <path
                            d="m3.48022691 1.49152582-1.49152582 3.97740217 1.49152582-.99435054 1.49152581.99435054z"
                            fill="#fafafa"
                        />
                    </g>
                </g>
            )}
        />

    );
}

export default IconMap;
