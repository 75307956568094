import React, {ReactNode} from 'react';
import {useTranslation} from "@Translation/hooks/useTranslation";
import IconListWithArrow from "@Icons/IconListWithArrow";
import Row from "@Containers/components/Row";

interface TableOfContentsHeaderProps {
    expandToc: boolean,
    callback: any,
}
function TableOfContentsHeader({expandToc, callback}: TableOfContentsHeaderProps) {
    const tocTitle = useTranslation('sys.toc')
    const orientation = (expandToc) ? "up" : "down";
    return (
        <Row onClick={callback} className="gb-toc__title" justify center gap >
            <h2>{tocTitle}</h2>
            <IconListWithArrow chevronOrientation={orientation}/>
        </Row>
    );
}
export default TableOfContentsHeader;