import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconTransportMediaSeats(props: IconType) {
    const {
        width = '260',
        height = '68',
        fill = '#fff',
        viewBox = '0 0 260 68',
        title = 'IconTransportMediaSeats',
        ...rest
    } = props
    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none" fillRule="evenodd">
                    <g stroke="#070c63" strokeLinejoin="round">
                        <rect
                            fill="#fff"
                            height="26.902053"
                            rx="2.152164"
                            strokeWidth="2.152164"
                            width="26.902053"
                            x="34.902053"
                            y="2"
                        />
                        <g strokeWidth="2.30589">
                            <rect
                                fill="#fff"
                                height="20.753012"
                                rx=".76863"
                                width="20.753012"
                                x="37.976573"
                                y="5.07452"
                            />
                            <rect
                                fill="#7ed321"
                                height="12.298081"
                                rx=".76863"
                                width="20.753012"
                                x="37.976573"
                                y="13.529451"
                            />
                        </g>
                    </g>
                    <g fillRule="nonzero">
                        <g transform="translate(11.663996 51.901931)">
                            <path
                                d="m2.09414226 13.152262c-.45855184 0-.87711369-.2106139-1.14904559-.5798548-.27059891-.3665749-.34924588-.8277927-.21461293-1.2636835l3.20852986-10.41205935h20.1149629l3.2085298 10.41339235c.134633.4358908.055986.8957756-.2146129 1.2623505-.2719319.3679079-.6904937.5798548-1.1490456.5798548zm3.1058889-3.99899858h17.61692174l-2.4913761-7.99799716h-12.85544745z"
                                fill="#fff"
                            />
                            <path
                                d="m23.5620996 1.56449711 3.063233 9.94151049c.0973089.3172539-.029326.5558608-.114638.6704988-.085312.1159709-.2772639.3092558-.6118468.3092558h-23.80470554c-.33458288 0-.52786782-.1932849-.61184679-.3092558-.08397897-.114638-.21194692-.3545779-.11463795-.6704988l3.06323291-9.94151049h2.23010821l-1.86220034 6.55835768-.48121283 1.6969084h1.76355837 15.82936936 1.8115464l-.5385318-1.72890039-2.0328243-6.52636569zm.9837537-1.33299952h-21.09871652l-3.35249381 10.88260811c-.41456286 1.3436636.59185179 2.7046561 1.99949929 2.7046561h23.80337254c1.4089805 0 2.4153952-1.3609925 2.0008323-2.7046561zm-18.46337646 8.25526607 1.89152633-6.66499764h11.86102983l2.0754802 6.66499764z"
                                fill="#070c63"
                                stroke="#070c63"
                                strokeWidth=".844233"
                            />
                        </g>
                        <g transform="translate(1 5.07452)">
                            <path
                                d="m12.6634955 49.3156771c-1.8382063 0-3.42980777-1.2530196-3.87103062-3.045904l-8.02065815-31.4921138c-.07198198-.2919269-.10530697-.5451968-.10530697-.7864697 0-1.8368734 1.49562547-3.3324989 3.33249882-3.3324989 1.46496648 0 2.73931403.9504287 3.16987288 2.3660742l10.66266324 28.292915h15.4490202c2.2047812 0 3.9989986 1.7942173 3.9989986 3.9989986 0 2.2047812-1.7942174 3.9989986-3.9989986 3.9989986z"
                                fill="#4a79e2"
                            />
                            <g fill="#b5b7d1" stroke="#b5b7d1" strokeWidth="2.30589" transform="translate(11.529451)">
                                <path
                                    d="m11.1552078 39.3342466c-2.39494529 0-5.37404945-1.5568735-6.09921217-3.9677215l-4.77080737-15.9026912c-.88418963-2.9451784.78718322-6.0477935 3.73236163-6.9303929 2.94040761-.8873701 6.04779351.7887735 6.93039281 3.7323617l5.5394375 15.9026912c.8841896 2.9451784-.7871832 6.0477935-3.7323617 6.9303928-.5311498.1590269-1.0702511.2353599-1.5998107.2353599z"
                                />
                                <path
                                    d="m4.61178046 9.22356091c2.54224397 0 4.61178045-2.06953648 4.61178045-4.61178045 0-2.54224398-2.06953648-4.61178046-4.61178045-4.61178046-2.54224398 0-4.61178046 2.06953648-4.61178046 4.61178046 0 2.54224397 2.06953648 4.61178045 4.61178046 4.61178045"
                                />
                                <path
                                    d="m14.3654311 31.3813107 12.722153 1.5902691c1.459867 0 2.7320823.9939182 3.0851221 2.4092577l4.7708073 19.0832295c.4261921 1.7047685-.6106633 3.4318008-2.3138416 3.8579929-.2592138.0636108-.5184277.0938337-.774461.0938337-1.4248812.0015824-2.7209505-.9637109-3.0819416-2.4092656l-4.8662235-16.6739717h-12.722153"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="m5.21246659 19.2119017c.52747716 1.7367227 1.3186929 4.3418068 2.37364721 7.8152521l11.333848 2.8338596c1.2785764.3196441 2.0546277 1.6157134 1.7349837 2.8926995-.3563721 1.4237151-.8446119 1.808136-2.3122514 1.808136-.1908322 0-.3848451-.023854-.5804482-.0715621l-12.72215296-3.1805382c-.85397452-.2130961-1.52188755-.8810091-1.73498361-1.7349836l-3.25646854-11.5446368"
                                />
                            </g>
                            <path
                                d="m6.53169769 13.2193828.01199699.041323.014663.03999 10.48537432 27.8197002.3265848.8637836h.9224357 14.9878024c1.8382063 0 3.3324988 1.4956255 3.3324988 3.3324989 0 1.8368733-1.4942925 3.3324988-3.3324988 3.3324988h-20.6170594c-1.5302834 0-2.85528498-1.0437386-3.22319285-2.5393641l-.33858188-1.3703235-.01066399.003999-7.67007928-30.1191244c-.05998498-.2439389-.08797797-.4452218-.08797797-.6331747 0-1.4702985 1.19570057-2.6659991 2.66599905-2.6659991 1.16904059 0 2.18745223.7611427 2.53269911 1.8941923zm-6.53169769.7718068c0 .3292509.05065398.6451717.12663496.9517616l7.96200617 31.265504c.011997.0626509.03199199.1226359.04665499.1839539l.00933099.035991c.50120783 2.0368233 2.32741719 3.5537767 4.51886839 3.5537767h20.6170594c2.5766881 0 4.6654983-2.0888102 4.6654983-4.6654983s-2.0888102-4.6654984-4.6654983-4.6654984h-14.9878024l-10.48537427-27.8197001c-.49987482-1.6395894-2.00616429-2.83928901-3.80837965-2.83928901-2.20878021 0-3.99899858 1.79021841-3.99899858 3.99899861z"
                                fill="#070c63"
                                stroke="#070c63"
                                strokeWidth=".844233"
                            />
                        </g>
                        <g transform="translate(11.663996 36.394703)">
                            <path
                                d="m2.66599906 4.66549835c-1.10239061 0-1.9994993-.89710869-1.9994993-1.99949929 0-1.10239061.89710869-1.9994993 1.9994993-1.9994993h11.99699574c2.3460792 0 4.2935915 1.74089739 4.6175104 3.99899859z"
                                fill="#4a79e2"
                            />
                            <path
                                d="m14.6629948 1.33299953c1.7382314 0 3.2205269 1.1143876 3.7710557 2.66599905h-15.76805144c-.73448274 0-1.33299953-.59851679-1.33299953-1.33299952 0-.73448274.59851679-1.33299953 1.33299953-1.33299953zm0-1.33299953h-11.99699574c-1.47296448 0-2.66599906 1.19303458-2.66599906 2.66599906 0 1.47296447 1.19303458 2.66599905 2.66599906 2.66599905h17.32899384c0-2.94459596-2.3874021-5.33199811-5.3319981-5.33199811z"
                                fill="#070c63"
                                stroke="#070c63"
                                strokeWidth=".844233"
                            />
                        </g>
                    </g>
                    <g stroke="#070c63" strokeLinejoin="round">
                        <rect
                            fill="#fff"
                            height="26.902053"
                            rx="2.152164"
                            strokeWidth="2.152164"
                            width="26.902053"
                            x="83.902053"
                            y="2"
                        />
                        <g strokeWidth="2.30589">
                            <rect
                                fill="#fff"
                                height="20.753012"
                                rx=".76863"
                                width="20.753012"
                                x="86.976573"
                                y="5.07452"
                            />
                            <rect
                                fill="#7ed321"
                                height="12.298081"
                                rx=".76863"
                                width="20.753012"
                                x="86.976573"
                                y="13.529451"
                            />
                        </g>
                    </g>
                    <g fillRule="nonzero">
                        <g transform="translate(60.663996 51.901931)">
                            <path
                                d="m2.09414226 13.152262c-.45855184 0-.87711369-.2106139-1.14904559-.5798548-.27059891-.3665749-.34924588-.8277927-.21461293-1.2636835l3.20852986-10.41205935h20.1149629l3.2085298 10.41339235c.134633.4358908.055986.8957756-.2146129 1.2623505-.2719319.3679079-.6904937.5798548-1.1490456.5798548zm3.1058889-3.99899858h17.61692174l-2.4913761-7.99799716h-12.85544745z"
                                fill="#fff"
                            />
                            <path
                                d="m23.5620996 1.56449711 3.063233 9.94151049c.0973089.3172539-.029326.5558608-.114638.6704988-.085312.1159709-.2772639.3092558-.6118468.3092558h-23.80470554c-.33458288 0-.52786782-.1932849-.61184679-.3092558-.08397897-.114638-.21194692-.3545779-.11463795-.6704988l3.06323291-9.94151049h2.23010821l-1.86220034 6.55835768-.48121283 1.6969084h1.76355837 15.82936936 1.8115464l-.5385318-1.72890039-2.0328243-6.52636569zm.9837537-1.33299952h-21.09871652l-3.35249381 10.88260811c-.41456286 1.3436636.59185179 2.7046561 1.99949929 2.7046561h23.80337254c1.4089805 0 2.4153952-1.3609925 2.0008323-2.7046561zm-18.46337646 8.25526607 1.89152633-6.66499764h11.86102983l2.0754802 6.66499764z"
                                fill="#070c63"
                                stroke="#070c63"
                                strokeWidth=".844233"
                            />
                        </g>
                        <g transform="translate(50 5.07452)">
                            <path
                                d="m12.6634955 49.3156771c-1.8382063 0-3.42980777-1.2530196-3.87103062-3.045904l-8.02065815-31.4921138c-.07198198-.2919269-.10530697-.5451968-.10530697-.7864697 0-1.8368734 1.49562547-3.3324989 3.33249882-3.3324989 1.46496648 0 2.73931403.9504287 3.16987288 2.3660742l10.66266324 28.292915h15.4490202c2.2047812 0 3.9989986 1.7942173 3.9989986 3.9989986 0 2.2047812-1.7942174 3.9989986-3.9989986 3.9989986z"
                                fill="#4a79e2"
                            />
                            <g fill="#b5b7d1" stroke="#b5b7d1" strokeWidth="2.30589" transform="translate(11.529451)">
                                <path
                                    d="m11.1552078 39.3342466c-2.39494529 0-5.37404945-1.5568735-6.09921217-3.9677215l-4.77080737-15.9026912c-.88418963-2.9451784.78718322-6.0477935 3.73236163-6.9303929 2.94040761-.8873701 6.04779351.7887735 6.93039281 3.7323617l5.5394375 15.9026912c.8841896 2.9451784-.7871832 6.0477935-3.7323617 6.9303928-.5311498.1590269-1.0702511.2353599-1.5998107.2353599z"
                                />
                                <path
                                    d="m4.61178046 9.22356091c2.54224397 0 4.61178045-2.06953648 4.61178045-4.61178045 0-2.54224398-2.06953648-4.61178046-4.61178045-4.61178046-2.54224398 0-4.61178046 2.06953648-4.61178046 4.61178046 0 2.54224397 2.06953648 4.61178045 4.61178046 4.61178045"
                                />
                                <path
                                    d="m14.3654311 31.3813107 12.722153 1.5902691c1.459867 0 2.7320823.9939182 3.0851221 2.4092577l4.7708073 19.0832295c.4261921 1.7047685-.6106633 3.4318008-2.3138416 3.8579929-.2592138.0636108-.5184277.0938337-.774461.0938337-1.4248812.0015824-2.7209505-.9637109-3.0819416-2.4092656l-4.8662235-16.6739717h-12.722153"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="m5.21246659 19.2119017c.52747716 1.7367227 1.3186929 4.3418068 2.37364721 7.8152521l11.333848 2.8338596c1.2785764.3196441 2.0546277 1.6157134 1.7349837 2.8926995-.3563721 1.4237151-.8446119 1.808136-2.3122514 1.808136-.1908322 0-.3848451-.023854-.5804482-.0715621l-12.72215296-3.1805382c-.85397452-.2130961-1.52188755-.8810091-1.73498361-1.7349836l-3.25646854-11.5446368"
                                />
                            </g>
                            <path
                                d="m6.53169769 13.2193828.01199699.041323.014663.03999 10.48537432 27.8197002.3265848.8637836h.9224357 14.9878024c1.8382063 0 3.3324988 1.4956255 3.3324988 3.3324989 0 1.8368733-1.4942925 3.3324988-3.3324988 3.3324988h-20.6170594c-1.5302834 0-2.85528498-1.0437386-3.22319285-2.5393641l-.33858188-1.3703235-.01066399.003999-7.67007928-30.1191244c-.05998498-.2439389-.08797797-.4452218-.08797797-.6331747 0-1.4702985 1.19570057-2.6659991 2.66599905-2.6659991 1.16904059 0 2.18745223.7611427 2.53269911 1.8941923zm-6.53169769.7718068c0 .3292509.05065398.6451717.12663496.9517616l7.96200617 31.265504c.011997.0626509.03199199.1226359.04665499.1839539l.00933099.035991c.50120783 2.0368233 2.32741719 3.5537767 4.51886839 3.5537767h20.6170594c2.5766881 0 4.6654983-2.0888102 4.6654983-4.6654983s-2.0888102-4.6654984-4.6654983-4.6654984h-14.9878024l-10.48537427-27.8197001c-.49987482-1.6395894-2.00616429-2.83928901-3.80837965-2.83928901-2.20878021 0-3.99899858 1.79021841-3.99899858 3.99899861z"
                                fill="#070c63"
                                stroke="#070c63"
                                strokeWidth=".844233"
                            />
                        </g>
                        <g transform="translate(60.663996 36.394703)">
                            <path
                                d="m2.66599906 4.66549835c-1.10239061 0-1.9994993-.89710869-1.9994993-1.99949929 0-1.10239061.89710869-1.9994993 1.9994993-1.9994993h11.99699574c2.3460792 0 4.2935915 1.74089739 4.6175104 3.99899859z"
                                fill="#4a79e2"
                            />
                            <path
                                d="m14.6629948 1.33299953c1.7382314 0 3.2205269 1.1143876 3.7710557 2.66599905h-15.76805144c-.73448274 0-1.33299953-.59851679-1.33299953-1.33299952 0-.73448274.59851679-1.33299953 1.33299953-1.33299953zm0-1.33299953h-11.99699574c-1.47296448 0-2.66599906 1.19303458-2.66599906 2.66599906 0 1.47296447 1.19303458 2.66599905 2.66599906 2.66599905h17.32899384c0-2.94459596-2.3874021-5.33199811-5.3319981-5.33199811z"
                                fill="#070c63"
                                stroke="#070c63"
                                strokeWidth=".844233"
                            />
                        </g>
                    </g>
                    <g stroke="#070c63" strokeLinejoin="round">
                        <rect
                            fill="#fff"
                            height="26.902053"
                            rx="2.152164"
                            strokeWidth="2.152164"
                            width="26.902053"
                            x="132.902053"
                            y="2"
                        />
                        <g strokeWidth="2.30589">
                            <rect
                                fill="#fff"
                                height="20.753012"
                                rx=".76863"
                                width="20.753012"
                                x="135.976573"
                                y="5.07452"
                            />
                            <rect
                                fill="#7ed321"
                                height="12.298081"
                                rx=".76863"
                                width="20.753012"
                                x="135.976573"
                                y="13.529451"
                            />
                        </g>
                    </g>
                    <g fillRule="nonzero">
                        <g transform="translate(109.663996 51.901931)">
                            <path
                                d="m2.09414226 13.152262c-.45855184 0-.87711369-.2106139-1.14904559-.5798548-.27059891-.3665749-.34924588-.8277927-.21461293-1.2636835l3.20852986-10.41205935h20.1149629l3.2085298 10.41339235c.134633.4358908.055986.8957756-.2146129 1.2623505-.2719319.3679079-.6904937.5798548-1.1490456.5798548zm3.1058889-3.99899858h17.61692174l-2.4913761-7.99799716h-12.85544745z"
                                fill="#fff"
                            />
                            <path
                                d="m23.5620996 1.56449711 3.063233 9.94151049c.0973089.3172539-.029326.5558608-.114638.6704988-.085312.1159709-.2772639.3092558-.6118468.3092558h-23.80470554c-.33458288 0-.52786782-.1932849-.61184679-.3092558-.08397897-.114638-.21194692-.3545779-.11463795-.6704988l3.06323291-9.94151049h2.23010821l-1.86220034 6.55835768-.48121283 1.6969084h1.76355837 15.82936936 1.8115464l-.5385318-1.72890039-2.0328243-6.52636569zm.9837537-1.33299952h-21.09871652l-3.35249381 10.88260811c-.41456286 1.3436636.59185179 2.7046561 1.99949929 2.7046561h23.80337254c1.4089805 0 2.4153952-1.3609925 2.0008323-2.7046561zm-18.46337646 8.25526607 1.89152633-6.66499764h11.86102983l2.0754802 6.66499764z"
                                fill="#070c63"
                                stroke="#070c63"
                                strokeWidth=".844233"
                            />
                        </g>
                        <g transform="translate(99 5.07452)">
                            <path
                                d="m12.6634955 49.3156771c-1.8382063 0-3.42980777-1.2530196-3.87103062-3.045904l-8.02065815-31.4921138c-.07198198-.2919269-.10530697-.5451968-.10530697-.7864697 0-1.8368734 1.49562547-3.3324989 3.33249882-3.3324989 1.46496648 0 2.73931403.9504287 3.16987288 2.3660742l10.66266324 28.292915h15.4490202c2.2047812 0 3.9989986 1.7942173 3.9989986 3.9989986 0 2.2047812-1.7942174 3.9989986-3.9989986 3.9989986z"
                                fill="#4a79e2"
                            />
                            <g stroke="#070c63">
                                <g fill="#fff" strokeWidth="2.30589" transform="translate(11.529451)">
                                    <path
                                        d="m11.1552078 39.3342466c-2.39494529 0-5.37404945-1.5568735-6.09921217-3.9677215l-4.77080737-15.9026912c-.88418963-2.9451784.78718322-6.0477935 3.73236163-6.9303929 2.94040761-.8873701 6.04779351.7887735 6.93039281 3.7323617l5.5394375 15.9026912c.8841896 2.9451784-.7871832 6.0477935-3.7323617 6.9303928-.5311498.1590269-1.0702511.2353599-1.5998107.2353599z"
                                    />
                                    <path
                                        d="m4.61178046 9.22356091c2.54224397 0 4.61178045-2.06953648 4.61178045-4.61178045 0-2.54224398-2.06953648-4.61178046-4.61178045-4.61178046-2.54224398 0-4.61178046 2.06953648-4.61178046 4.61178046 0 2.54224397 2.06953648 4.61178045 4.61178046 4.61178045"
                                    />
                                    <path
                                        d="m14.3654311 31.3813107 12.722153 1.5902691c1.459867 0 2.7320823.9939182 3.0851221 2.4092577l4.7708073 19.0832295c.4261921 1.7047685-.6106633 3.4318008-2.3138416 3.8579929-.2592138.0636108-.5184277.0938337-.774461.0938337-1.4248812.0015824-2.7209505-.9637109-3.0819416-2.4092656l-4.8662235-16.6739717h-12.722153"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="m5.21246659 19.2119017c.52747716 1.7367227 1.3186929 4.3418068 2.37364721 7.8152521l11.333848 2.8338596c1.2785764.3196441 2.0546277 1.6157134 1.7349837 2.8926995-.3563721 1.4237151-.8446119 1.808136-2.3122514 1.808136-.1908322 0-.3848451-.023854-.5804482-.0715621l-12.72215296-3.1805382c-.85397452-.2130961-1.52188755-.8810091-1.73498361-1.7349836l-3.25646854-11.5446368"
                                    />
                                </g>
                                <path
                                    d="m6.53169769 13.2193828.01199699.041323.014663.03999 10.48537432 27.8197002.3265848.8637836h.9224357 14.9878024c1.8382063 0 3.3324988 1.4956255 3.3324988 3.3324989 0 1.8368733-1.4942925 3.3324988-3.3324988 3.3324988h-20.6170594c-1.5302834 0-2.85528498-1.0437386-3.22319285-2.5393641l-.33858188-1.3703235-.01066399.003999-7.67007928-30.1191244c-.05998498-.2439389-.08797797-.4452218-.08797797-.6331747 0-1.4702985 1.19570057-2.6659991 2.66599905-2.6659991 1.16904059 0 2.18745223.7611427 2.53269911 1.8941923zm-6.53169769.7718068c0 .3292509.05065398.6451717.12663496.9517616l7.96200617 31.265504c.011997.0626509.03199199.1226359.04665499.1839539l.00933099.035991c.50120783 2.0368233 2.32741719 3.5537767 4.51886839 3.5537767h20.6170594c2.5766881 0 4.6654983-2.0888102 4.6654983-4.6654983s-2.0888102-4.6654984-4.6654983-4.6654984h-14.9878024l-10.48537427-27.8197001c-.49987482-1.6395894-2.00616429-2.83928901-3.80837965-2.83928901-2.20878021 0-3.99899858 1.79021841-3.99899858 3.99899861z"
                                    fill="#070c63"
                                    strokeWidth=".844233"
                                />
                            </g>
                        </g>
                        <g transform="translate(109.663996 36.394703)">
                            <path
                                d="m2.66599906 4.66549835c-1.10239061 0-1.9994993-.89710869-1.9994993-1.99949929 0-1.10239061.89710869-1.9994993 1.9994993-1.9994993h11.99699574c2.3460792 0 4.2935915 1.74089739 4.6175104 3.99899859z"
                                fill="#4a79e2"
                            />
                            <path
                                d="m14.6629948 1.33299953c1.7382314 0 3.2205269 1.1143876 3.7710557 2.66599905h-15.76805144c-.73448274 0-1.33299953-.59851679-1.33299953-1.33299952 0-.73448274.59851679-1.33299953 1.33299953-1.33299953zm0-1.33299953h-11.99699574c-1.47296448 0-2.66599906 1.19303458-2.66599906 2.66599906 0 1.47296447 1.19303458 2.66599905 2.66599906 2.66599905h17.32899384c0-2.94459596-2.3874021-5.33199811-5.3319981-5.33199811z"
                                fill="#070c63"
                                stroke="#070c63"
                                strokeWidth=".844233"
                            />
                        </g>
                    </g>
                    <g stroke="#070c63" strokeLinejoin="round">
                        <rect
                            fill="#fff"
                            height="26.902053"
                            rx="2.152164"
                            strokeWidth="2.152164"
                            width="26.902053"
                            x="181.902053"
                            y="2"
                        />
                        <g strokeWidth="2.30589">
                            <rect
                                fill="#fff"
                                height="20.753012"
                                rx=".76863"
                                width="20.753012"
                                x="184.976573"
                                y="5.07452"
                            />
                            <rect
                                fill="#7ed321"
                                height="12.298081"
                                rx=".76863"
                                width="20.753012"
                                x="184.976573"
                                y="13.529451"
                            />
                        </g>
                    </g>
                    <g fillRule="nonzero">
                        <g transform="translate(158.663996 51.901931)">
                            <path
                                d="m2.09414226 13.152262c-.45855184 0-.87711369-.2106139-1.14904559-.5798548-.27059891-.3665749-.34924588-.8277927-.21461293-1.2636835l3.20852986-10.41205935h20.1149629l3.2085298 10.41339235c.134633.4358908.055986.8957756-.2146129 1.2623505-.2719319.3679079-.6904937.5798548-1.1490456.5798548zm3.1058889-3.99899858h17.61692174l-2.4913761-7.99799716h-12.85544745z"
                                fill="#fff"
                            />
                            <path
                                d="m23.5620996 1.56449711 3.063233 9.94151049c.0973089.3172539-.029326.5558608-.114638.6704988-.085312.1159709-.2772639.3092558-.6118468.3092558h-23.80470554c-.33458288 0-.52786782-.1932849-.61184679-.3092558-.08397897-.114638-.21194692-.3545779-.11463795-.6704988l3.06323291-9.94151049h2.23010821l-1.86220034 6.55835768-.48121283 1.6969084h1.76355837 15.82936936 1.8115464l-.5385318-1.72890039-2.0328243-6.52636569zm.9837537-1.33299952h-21.09871652l-3.35249381 10.88260811c-.41456286 1.3436636.59185179 2.7046561 1.99949929 2.7046561h23.80337254c1.4089805 0 2.4153952-1.3609925 2.0008323-2.7046561zm-18.46337646 8.25526607 1.89152633-6.66499764h11.86102983l2.0754802 6.66499764z"
                                fill="#070c63"
                                stroke="#070c63"
                                strokeWidth=".844233"
                            />
                        </g>
                        <g transform="translate(148 5.07452)">
                            <path
                                d="m12.6634955 49.3156771c-1.8382063 0-3.42980777-1.2530196-3.87103062-3.045904l-8.02065815-31.4921138c-.07198198-.2919269-.10530697-.5451968-.10530697-.7864697 0-1.8368734 1.49562547-3.3324989 3.33249882-3.3324989 1.46496648 0 2.73931403.9504287 3.16987288 2.3660742l10.66266324 28.292915h15.4490202c2.2047812 0 3.9989986 1.7942173 3.9989986 3.9989986 0 2.2047812-1.7942174 3.9989986-3.9989986 3.9989986z"
                                fill="#4a79e2"
                            />
                            <g fill="#b5b7d1" stroke="#b5b7d1" strokeWidth="2.30589" transform="translate(11.529451)">
                                <path
                                    d="m11.1552078 39.3342466c-2.39494529 0-5.37404945-1.5568735-6.09921217-3.9677215l-4.77080737-15.9026912c-.88418963-2.9451784.78718322-6.0477935 3.73236163-6.9303929 2.94040761-.8873701 6.04779351.7887735 6.93039281 3.7323617l5.5394375 15.9026912c.8841896 2.9451784-.7871832 6.0477935-3.7323617 6.9303928-.5311498.1590269-1.0702511.2353599-1.5998107.2353599z"
                                />
                                <path
                                    d="m4.61178046 9.22356091c2.54224397 0 4.61178045-2.06953648 4.61178045-4.61178045 0-2.54224398-2.06953648-4.61178046-4.61178045-4.61178046-2.54224398 0-4.61178046 2.06953648-4.61178046 4.61178046 0 2.54224397 2.06953648 4.61178045 4.61178046 4.61178045"
                                />
                                <path
                                    d="m14.3654311 31.3813107 12.722153 1.5902691c1.459867 0 2.7320823.9939182 3.0851221 2.4092577l4.7708073 19.0832295c.4261921 1.7047685-.6106633 3.4318008-2.3138416 3.8579929-.2592138.0636108-.5184277.0938337-.774461.0938337-1.4248812.0015824-2.7209505-.9637109-3.0819416-2.4092656l-4.8662235-16.6739717h-12.722153"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="m5.21246659 19.2119017c.52747716 1.7367227 1.3186929 4.3418068 2.37364721 7.8152521l11.333848 2.8338596c1.2785764.3196441 2.0546277 1.6157134 1.7349837 2.8926995-.3563721 1.4237151-.8446119 1.808136-2.3122514 1.808136-.1908322 0-.3848451-.023854-.5804482-.0715621l-12.72215296-3.1805382c-.85397452-.2130961-1.52188755-.8810091-1.73498361-1.7349836l-3.25646854-11.5446368"
                                />
                            </g>
                            <path
                                d="m6.53169769 13.2193828.01199699.041323.014663.03999 10.48537432 27.8197002.3265848.8637836h.9224357 14.9878024c1.8382063 0 3.3324988 1.4956255 3.3324988 3.3324989 0 1.8368733-1.4942925 3.3324988-3.3324988 3.3324988h-20.6170594c-1.5302834 0-2.85528498-1.0437386-3.22319285-2.5393641l-.33858188-1.3703235-.01066399.003999-7.67007928-30.1191244c-.05998498-.2439389-.08797797-.4452218-.08797797-.6331747 0-1.4702985 1.19570057-2.6659991 2.66599905-2.6659991 1.16904059 0 2.18745223.7611427 2.53269911 1.8941923zm-6.53169769.7718068c0 .3292509.05065398.6451717.12663496.9517616l7.96200617 31.265504c.011997.0626509.03199199.1226359.04665499.1839539l.00933099.035991c.50120783 2.0368233 2.32741719 3.5537767 4.51886839 3.5537767h20.6170594c2.5766881 0 4.6654983-2.0888102 4.6654983-4.6654983s-2.0888102-4.6654984-4.6654983-4.6654984h-14.9878024l-10.48537427-27.8197001c-.49987482-1.6395894-2.00616429-2.83928901-3.80837965-2.83928901-2.20878021 0-3.99899858 1.79021841-3.99899858 3.99899861z"
                                fill="#070c63"
                                stroke="#070c63"
                                strokeWidth=".844233"
                            />
                        </g>
                        <g transform="translate(158.663996 36.394703)">
                            <path
                                d="m2.66599906 4.66549835c-1.10239061 0-1.9994993-.89710869-1.9994993-1.99949929 0-1.10239061.89710869-1.9994993 1.9994993-1.9994993h11.99699574c2.3460792 0 4.2935915 1.74089739 4.6175104 3.99899859z"
                                fill="#4a79e2"
                            />
                            <path
                                d="m14.6629948 1.33299953c1.7382314 0 3.2205269 1.1143876 3.7710557 2.66599905h-15.76805144c-.73448274 0-1.33299953-.59851679-1.33299953-1.33299952 0-.73448274.59851679-1.33299953 1.33299953-1.33299953zm0-1.33299953h-11.99699574c-1.47296448 0-2.66599906 1.19303458-2.66599906 2.66599906 0 1.47296447 1.19303458 2.66599905 2.66599906 2.66599905h17.32899384c0-2.94459596-2.3874021-5.33199811-5.3319981-5.33199811z"
                                fill="#070c63"
                                stroke="#070c63"
                                strokeWidth=".844233"
                            />
                        </g>
                    </g>
                    <g stroke="#070c63" strokeLinejoin="round">
                        <rect
                            fill="#fff"
                            height="26.902053"
                            rx="2.152164"
                            strokeWidth="2.152164"
                            width="26.902053"
                            x="230.902053"
                            y="2"
                        />
                        <g strokeWidth="2.30589">
                            <rect
                                fill="#fff"
                                height="20.753012"
                                rx=".76863"
                                width="20.753012"
                                x="233.976573"
                                y="5.07452"
                            />
                            <rect
                                fill="#7ed321"
                                height="12.298081"
                                rx=".76863"
                                width="20.753012"
                                x="233.976573"
                                y="13.529451"
                            />
                        </g>
                    </g>
                    <g fillRule="nonzero">
                        <g transform="translate(207.663996 51.901931)">
                            <path
                                d="m2.09414226 13.152262c-.45855184 0-.87711369-.2106139-1.14904559-.5798548-.27059891-.3665749-.34924588-.8277927-.21461293-1.2636835l3.20852986-10.41205935h20.1149629l3.2085298 10.41339235c.134633.4358908.055986.8957756-.2146129 1.2623505-.2719319.3679079-.6904937.5798548-1.1490456.5798548zm3.1058889-3.99899858h17.61692174l-2.4913761-7.99799716h-12.85544745z"
                                fill="#fff"
                            />
                            <path
                                d="m23.5620996 1.56449711 3.063233 9.94151049c.0973089.3172539-.029326.5558608-.114638.6704988-.085312.1159709-.2772639.3092558-.6118468.3092558h-23.80470554c-.33458288 0-.52786782-.1932849-.61184679-.3092558-.08397897-.114638-.21194692-.3545779-.11463795-.6704988l3.06323291-9.94151049h2.23010821l-1.86220034 6.55835768-.48121283 1.6969084h1.76355837 15.82936936 1.8115464l-.5385318-1.72890039-2.0328243-6.52636569zm.9837537-1.33299952h-21.09871652l-3.35249381 10.88260811c-.41456286 1.3436636.59185179 2.7046561 1.99949929 2.7046561h23.80337254c1.4089805 0 2.4153952-1.3609925 2.0008323-2.7046561zm-18.46337646 8.25526607 1.89152633-6.66499764h11.86102983l2.0754802 6.66499764z"
                                fill="#070c63"
                                stroke="#070c63"
                                strokeWidth=".844233"
                            />
                        </g>
                        <g transform="translate(197 5.07452)">
                            <path
                                d="m12.6634955 49.3156771c-1.8382063 0-3.42980777-1.2530196-3.87103062-3.045904l-8.02065815-31.4921138c-.07198198-.2919269-.10530697-.5451968-.10530697-.7864697 0-1.8368734 1.49562547-3.3324989 3.33249882-3.3324989 1.46496648 0 2.73931403.9504287 3.16987288 2.3660742l10.66266324 28.292915h15.4490202c2.2047812 0 3.9989986 1.7942173 3.9989986 3.9989986 0 2.2047812-1.7942174 3.9989986-3.9989986 3.9989986z"
                                fill="#4a79e2"
                            />
                            <g stroke="#070c63">
                                <g fill="#fff" strokeWidth="2.30589" transform="translate(11.529451)">
                                    <path
                                        d="m11.1552078 39.3342466c-2.39494529 0-5.37404945-1.5568735-6.09921217-3.9677215l-4.77080737-15.9026912c-.88418963-2.9451784.78718322-6.0477935 3.73236163-6.9303929 2.94040761-.8873701 6.04779351.7887735 6.93039281 3.7323617l5.5394375 15.9026912c.8841896 2.9451784-.7871832 6.0477935-3.7323617 6.9303928-.5311498.1590269-1.0702511.2353599-1.5998107.2353599z"
                                    />
                                    <path
                                        d="m4.61178046 9.22356091c2.54224397 0 4.61178045-2.06953648 4.61178045-4.61178045 0-2.54224398-2.06953648-4.61178046-4.61178045-4.61178046-2.54224398 0-4.61178046 2.06953648-4.61178046 4.61178046 0 2.54224397 2.06953648 4.61178045 4.61178046 4.61178045"
                                    />
                                    <path
                                        d="m14.3654311 31.3813107 12.722153 1.5902691c1.459867 0 2.7320823.9939182 3.0851221 2.4092577l4.7708073 19.0832295c.4261921 1.7047685-.6106633 3.4318008-2.3138416 3.8579929-.2592138.0636108-.5184277.0938337-.774461.0938337-1.4248812.0015824-2.7209505-.9637109-3.0819416-2.4092656l-4.8662235-16.6739717h-12.722153"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="m5.21246659 19.2119017c.52747716 1.7367227 1.3186929 4.3418068 2.37364721 7.8152521l11.333848 2.8338596c1.2785764.3196441 2.0546277 1.6157134 1.7349837 2.8926995-.3563721 1.4237151-.8446119 1.808136-2.3122514 1.808136-.1908322 0-.3848451-.023854-.5804482-.0715621l-12.72215296-3.1805382c-.85397452-.2130961-1.52188755-.8810091-1.73498361-1.7349836l-3.25646854-11.5446368"
                                    />
                                </g>
                                <path
                                    d="m6.53169769 13.2193828.01199699.041323.014663.03999 10.48537432 27.8197002.3265848.8637836h.9224357 14.9878024c1.8382063 0 3.3324988 1.4956255 3.3324988 3.3324989 0 1.8368733-1.4942925 3.3324988-3.3324988 3.3324988h-20.6170594c-1.5302834 0-2.85528498-1.0437386-3.22319285-2.5393641l-.33858188-1.3703235-.01066399.003999-7.67007928-30.1191244c-.05998498-.2439389-.08797797-.4452218-.08797797-.6331747 0-1.4702985 1.19570057-2.6659991 2.66599905-2.6659991 1.16904059 0 2.18745223.7611427 2.53269911 1.8941923zm-6.53169769.7718068c0 .3292509.05065398.6451717.12663496.9517616l7.96200617 31.265504c.011997.0626509.03199199.1226359.04665499.1839539l.00933099.035991c.50120783 2.0368233 2.32741719 3.5537767 4.51886839 3.5537767h20.6170594c2.5766881 0 4.6654983-2.0888102 4.6654983-4.6654983s-2.0888102-4.6654984-4.6654983-4.6654984h-14.9878024l-10.48537427-27.8197001c-.49987482-1.6395894-2.00616429-2.83928901-3.80837965-2.83928901-2.20878021 0-3.99899858 1.79021841-3.99899858 3.99899861z"
                                    fill="#070c63"
                                    strokeWidth=".844233"
                                />
                            </g>
                        </g>
                        <g transform="translate(207.663996 36.394703)">
                            <path
                                d="m2.66599906 4.66549835c-1.10239061 0-1.9994993-.89710869-1.9994993-1.99949929 0-1.10239061.89710869-1.9994993 1.9994993-1.9994993h11.99699574c2.3460792 0 4.2935915 1.74089739 4.6175104 3.99899859z"
                                fill="#4a79e2"
                            />
                            <path
                                d="m14.6629948 1.33299953c1.7382314 0 3.2205269 1.1143876 3.7710557 2.66599905h-15.76805144c-.73448274 0-1.33299953-.59851679-1.33299953-1.33299952 0-.73448274.59851679-1.33299953 1.33299953-1.33299953zm0-1.33299953h-11.99699574c-1.47296448 0-2.66599906 1.19303458-2.66599906 2.66599906 0 1.47296447 1.19303458 2.66599905 2.66599906 2.66599905h17.32899384c0-2.94459596-2.3874021-5.33199811-5.3319981-5.33199811z"
                                fill="#070c63"
                                stroke="#070c63"
                                strokeWidth=".844233"
                            />
                        </g>
                    </g>
                </g>
            )}
        />

    );
}

export default IconTransportMediaSeats;
