import RouteNote from "@SearchResults/components/Notifications/RouteNote";
import {useTranslation} from "@Translation/hooks/useTranslation";
import {isEmpty} from "@Array/isEmpty";
import RouteNoteType from "@Route/types/notes/RouteNote.type";

type CompanySpecificTermsProps = {
    notes: RouteNoteType[]
}

function CompanySpecificTerms({notes}: CompanySpecificTermsProps) {
    const fareTermsLabel = useTranslation('sys.fare_terms')
    const validNotes = notes.filter((note) => note.displayOnCheckOrder === true && note)
    if (isEmpty(validNotes)) {
        return null
    }

    return (
        <>
            <hr/>
            <span className="gb--card-title">
                {fareTermsLabel}
            </span>
            {validNotes.map((note, index) => (
                <RouteNote
                    /* eslint-disable-next-line react/no-array-index-key */
                    key={index}
                    note={note}
                />
            ))}
        </>
    );
}

export default CompanySpecificTerms;