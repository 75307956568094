type VoucherAdditionalInfoProps = {}

function VoucherAdditionalInfo(props: VoucherAdditionalInfoProps) {
    return (
        <div>
            a single lonely voucher
        </div>
    );
}

export default VoucherAdditionalInfo;