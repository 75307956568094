import React, {Component} from 'react';
import {createPortal} from 'react-dom';
import PropTypes from 'prop-types';

/**
 * Portal is a component used for replacing elements throughout DOM
 * which are usually not a part of the current React app.
 *
 * Can be used to target a single element through id
 * or multiple elements through class.
 */
export default class Portal extends Component {
    constructor(props) {
        super(props);

        if (!props.id && !props.class) {
            throw new Error('Id and class undefined, at least one should be defined!')
        } else if (!!props.class && undefined === props.index) {
            throw new Error('Class defined, but no index provided!');
        }

        this.portalNode = props.id
            ? document.getElementById(props.id)
            : document.getElementsByClassName(props.class)[props.index];

        if (props.type) {
            this.el = document.createElement(props.type)
        } else {
            this.el = document.createElement('div')
        }
    }

    componentDidMount() {
        this.removeReplacedNode()
        if (this.portalNode) {
            this.portalNode.appendChild(this.el);
        }
    }

    componentWillUnmount() {
        if (this.portalNode) {
            this.portalNode.removeChild(this.el);
        }
    }

    removeReplacedNode() {
        const placeholder = this.props.id
            ? document.getElementById(this.props.id)
            : document.getElementsByClassName(this.props.class)[this.props.index];

        if (placeholder) {
            while (placeholder.firstChild) {
                placeholder.removeChild(placeholder.firstChild);
            }
        }
    }

    render() {
        if (!this.portalNode) {
            return null;
        }

        return createPortal(this.props.children, this.el);
    }
}

Portal.propTypes = {
    // Id for portal to inject itself into
    id: PropTypes.string,
    // Classname for portal to inject itself into
    class: PropTypes.string,
    // Element index, extracted by getElementsByClassName
    index: PropTypes.number,
    // Elements inserted
    children: PropTypes.node.isRequired,
    // Type of element, optional, div by default
    type: PropTypes.string,
};