import {toggleForm} from "@Form/reducers/formSlice";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";

export default function useActivateMobileFormOverlay() {
    const dispatch = useAppDispatch()
    const toggleFormAction = () => {
        dispatch(toggleForm())
    }

    return {
        toggleFormAction,
    }
}