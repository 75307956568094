import IconInfo from "@Icons/IconInfo";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {useTranslation} from "@Translation/hooks/useTranslation";

type TotalLabelProps = {}

function TotalLabel(props: TotalLabelProps) {
    const {
        ticket: {
            paymentCurrency,
            bookingFeeRange,
            totalPrice,
        },
    } = useAppSelector((state) => state?.checkOrder)
    const {total: totalLabel} = useTranslation("sys")
    const checkOrderFeesIncluded = useTranslation("check_order.fees_included")

    return (
        <>
            <strong>
                {totalLabel}
            </strong>
            <small>
                {" "}
                {`(${checkOrderFeesIncluded} ${paymentCurrency})`}
                <IconInfo
                    bold
                    width="20"
                    height="16"
                    fill="#7ed321"
                    style={{verticalAlign: 'middle'}}
                />
            </small>
        </>
    );
}

export default TotalLabel;