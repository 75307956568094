import differenceInMilliseconds from "date-fns/differenceInMilliseconds";
import parseISO from "date-fns/parseISO";
import RouteWithAddedPropsType from "@Route/types/RouteWithAddedProps.type";
import IntermodalRouteWithDetailsType from "@Intermodal/types/IntermodalRouteWithDetails.type";

/**
 * Returns diff in milliseconds
 */
export const calculateTripDurationInMilliseconds = (departureTime: string, arrivalTime: string): number => (
    differenceInMilliseconds(parseISO(arrivalTime), parseISO(departureTime))
)

type RouteType = RouteWithAddedPropsType | IntermodalRouteWithDetailsType

export const calculateTravelTime = ({travelInfo: {departureStation, destinationStation}}: RouteType): number => (
    calculateTripDurationInMilliseconds(
        `${departureStation.departureDate} ${departureStation.departureTime}`,
        `${destinationStation.arrivalDate} ${destinationStation.arrivalTime}`
    )
)