import useRouteMatch from "@Routing/hooks/useRouteMatch";
import {useLayoutEffect} from "react";

/**
 * Changes body className depending on the route
 */
export default function useSetBodyClassName() {
    const isCheckOrder = useRouteMatch('checkOrder')
    const isSearchResults = useRouteMatch('searchResults')
    const isHomepage = useRouteMatch('homepage')

    const setBodyClassName = () => {
        const body = document.getElementsByTagName('body')[0];

        if (!body) {
            return
        }

        if (isSearchResults) {
            body.classList.add('gb--search-results')
            body.classList.add('search-results')
            body.classList.remove('gb--check-order')
            body.classList.remove('homepage')
            body.classList.remove('homepage--gbb')
        }

        if (isHomepage) {
            body.classList.remove('gb--search-results')
            body.classList.remove('search-results')
            body.classList.add('homepage')
            body.classList.add('homepage--gbb')
        }

        if (isCheckOrder) {
            body.classList.add('gb--main-content')
            body.classList.remove('gb--search-results')
            body.classList.remove('search-results')
        }
    }

    useLayoutEffect(() => {
        setBodyClassName()
    }, [isSearchResults, isHomepage, isCheckOrder]);
}