import {useRef} from "react";
import {scrollToRef} from "@Scroll/functions/scrollToRef";
import UseScrollType from "@Scroll/types/UseScroll.type";

/**
 * Handles various scroll interactions
 */

interface UseScrollAttributes {
    block?: 'start' | 'center' | 'end' | 'nearest',
    behavior?: 'smooth' | 'instant' | 'auto',
    offset?: number,
}

export const useScroll = ({block = "center", behavior = "auto", offset = 0}: UseScrollAttributes): UseScrollType => {
    const elRef = useRef(null);
    const executeScroll = () => scrollToRef({ref: elRef, behavior, block})
    // @ts-ignore TODO-Check-With-Luka-Is-This-A-Bug
    const scrollToTop = () => window.scrollTo({top: 0, behavior})
    const isElementVisible = () => {
        const {y, height} = elRef.current?.getBoundingClientRect()
        const position = y - offset

        return position < 1 && !(height + position <= 0)
    }

    const scrollWithOffset = (newOffset = offset) => {
        const {y} = elRef.current?.getBoundingClientRect()
        window.scrollTo({
            top: window.pageYOffset + y - newOffset,
            // @ts-ignore TODO-Check-With-Luka-Is-This-A-Bug
            behavior,
        })
    }

    return {
        executeScroll,
        elRef,
        scrollToTop,
        scrollWithOffset,
        offset,
        isElementVisible,
    };
};