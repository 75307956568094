import {useEffect} from "react";
import {useGetRouteSuggestionsMutation} from "@SearchResults/api/searchResultsApi";
import useApiServiceFetcher from "@SearchResults/api/hooks/useApiServiceFetcher";
import SearchResultsRequestType from "@SearchResults/api/types/SearchResultsRequest.type";
import useSearchParamsExtractedFromRoute from "@SearchResults/hooks/useSearchParamsExtractedFromRoute";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {SearchResultsRtkQueryResponseType} from "@SearchResults/api/types/SearchResultsRtkQueryResponse.type";
import useAsyncLoadedRouteState from "@RoutesList/hooks/useAsyncLoadedRouteState";
import useIsCompanyStore from "@Hooks/selector/useIsCompanyStore";
import useSearchResultsCountPerType from "@SearchResults/hooks/useSearchResultsCountPerType";

export default function useSuggestionRoutesFetcher(data: SearchResultsRtkQueryResponseType) {
    const minRoutesToDisplaySuggestions = 3
    const {
        singleRoutesCount,
        intermodalRoutesCount,
    } = useSearchResultsCountPerType(false)

    const {suggestionHubsEnabled} = useAppSelector((state) => state.searchResults)
    const baseSearchParams = useSearchParamsExtractedFromRoute()
    const [method, routeSuggestions]: any = useGetRouteSuggestionsMutation()
    const isCompanyStore = useIsCompanyStore()
    const {apiRoutesLoading} = useAsyncLoadedRouteState()
    const {fetchFromApiService} = useApiServiceFetcher()

    const suggestionRoutesEnabled = !isCompanyStore
        && suggestionHubsEnabled
        && (singleRoutesCount + intermodalRoutesCount < minRoutesToDisplaySuggestions)
        && !apiRoutesLoading
        && !!data?.suggestionData?.routeSuggestionHubs
        && !!data?.result.params

    const fetchRouteSuggestionMethod = (hubId: number, searchParams: SearchResultsRequestType) => {
        const departingStationId = searchParams?.fromSlug.split("-").at(-1)
        const destinationStationId = searchParams?.toSlug.split("-").at(-1)
        const params = {
            hubId,
            searchParams: baseSearchParams,
            date: data.result.params?.date,
            departingStationId,
            destinationStationId,
        }

        return {
            params,
            method,
        }
    }

    const fetchSuggestionRoutes = (hubId: number, searchParams: SearchResultsRequestType) => {
        const {
            method,
            params,
        } = fetchRouteSuggestionMethod(hubId, searchParams)

        fetchFromApiService({
            method,
            params,
        })
    }

    useEffect(() => {
        if (suggestionRoutesEnabled) {
            data.suggestionData.routeSuggestionHubs.map((hub) => {
                fetchSuggestionRoutes(hub, baseSearchParams)
            })
        }
    }, [data?.suggestionData, suggestionRoutesEnabled, apiRoutesLoading])

    return {
        routeSuggestions,
        fetchSuggestionRoutes,
        suggestionRoutesEnabled,
    }
}