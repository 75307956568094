import {createSlice} from '@reduxjs/toolkit'
import rootExtractor from "@Dataset/rootExtractor";
import intToBool from "@Number/intToBool";
import getMainCompanyTransportType from "@Tabs/functions/getCompanyMainTransportType";
import TabType from "@Tabs/types/Tab.type";
import CurrencyType from "@Currency/types/Currency.type";
import {generateEmptyMainTransportTypeTab} from "@Tabs/functions/generateEmptyMainTransportTypeTab";

const companyMainTransportType = getMainCompanyTransportType(JSON.parse(rootExtractor('companyStore')));
const suggestionHubsEnabled = intToBool(rootExtractor('suggestionHubsEnabled'))

export interface InitialSearchResultsState {
    currency: CurrencyType,
    tabsToShow: TabType[],
    tab: TabType,
    transportTypeKeyword: string,
    searchResultsApiRoute: string,
    stsActiveCurrencyRate: any,
    suggestionHubsEnabled: boolean,
    excludedApiPartnerIds: (string|number)[],
}

const initialState: InitialSearchResultsState = {
    currency: {
        val: 'EUR',
        sign: '€',
        ratio: 1,
    },
    tabsToShow: null,
    tab: generateEmptyMainTransportTypeTab(companyMainTransportType),
    transportTypeKeyword: rootExtractor('transportTypeKeyword'),
    searchResultsApiRoute: rootExtractor('searchResultsApiRoute'),
    stsActiveCurrencyRate: window.currencyData ? JSON.parse(window.currencyData) : null,
    suggestionHubsEnabled,
    excludedApiPartnerIds: [],
}

const searchResultsSlice = createSlice({
    name: 'searchResults',
    initialState,
    reducers: {
        changeCurrency: (state, action) => {
            state.currency = action.payload
        },

        clear: () => initialState,
    },
})

export const {
    changeCurrency,
} = searchResultsSlice.actions

export default searchResultsSlice.reducer