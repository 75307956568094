/**
 * Creates condition object for filter
 * @param bool {any}
 * @param method {function}
 * @returns {{bool, method}}
 */
export const addCondition = (bool, method) => ({
    bool,
    method,
})

/**
 * Iterates upon array with multiple condition objects
 * @param array {array}
 * @param conditions {array: [{bool: boolean, method: function}]}
 */
export const filterArrayByConditions = (array, conditions) => (
    array.filter((item) => {
        const allConditionsMet = conditions.every((condition) => (
            condition.bool ? condition.method(item) : true
        ))

        if (allConditionsMet) {
            return item
        }
    })
)
