import React from 'react';
import formatDate from "@DateTime/functions/formatDate";
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

interface IconCalendarWithDateProps extends IconType {
    date: string,
    isChecked: boolean,
}

function IconCalendarWithDateAndCheckmark(props: IconCalendarWithDateProps) {
    const {
        width = '56',
        height = '56',
        fill = '#fff',
        viewBox = '0 0 56 56',
        title = 'IconCalendarWithDateAndCheckmark',
        date,
        isChecked,
        ...rest
    } = props

    const day = formatDate(date, "dd", true)
    const month = formatDate(date, "MMMM", true)

    return (
        <GenericSvgIcon
            {...{width, height, fill, viewBox, title, ...rest}}
            svgIconPath={(
                <>
                    <path
                        d="m38.9966 45.1175h-31.0456c-1.89724 0-3.44952-1.5523-3.44952-3.4495v-31.0456c0-1.89727 1.55228-3.44955 3.44952-3.44955h34.4951c1.8973 0 3.4496 1.55228 3.4496 3.44955v27.5961c0 3.7945-.9928 6.899-6.8991 6.899z"
                        fill="#fff"
                    />
                    <path
                        d="m38.5824 19.9565h-31.04559c-1.89723 0-3.44951-.5172-3.44951-1.1494v-10.34514c0-.6322 1.55228-1.14946 3.44951-1.14946h34.49519c1.8972 0 3.4495.51726 3.4495 1.14946v11.49454c0 1.2644-.9928 0-6.8991 0z"
                        fill="#b5b7d1"
                    />
                    <g fill="#070c63">
                        <path
                            d="m38.2377 46.2917h-30.70069c-2.48365 0-4.48437-2.0007-4.48437-4.4844v-17.2475c0-.5865.44844-1.0349 1.03486-1.0349.58641 0 1.03485.4484 1.03485 1.0349v17.2475c0 1.3453 1.06935 2.4147 2.41466 2.4147h30.70069c.5864 0 1.0348.4484 1.0348 1.0348 0 .5865-.4484 1.0349-1.0348 1.0349z"
                        />
                        <path
                            d="m45.0677 21.8566h-40.9802c-.58642 0-1.03486-.4484-1.03486-1.0348v-10.0601c0-2.48364 2.00072-4.48436 4.48437-4.48436h5.17429c.5864 0 1.0348.44844 1.0348 1.03486s-.4484 1.03485-1.0348 1.03485h-5.17429c-1.34531 0-2.41466 1.06935-2.41466 2.41465v9.0252h39.94535c.5864 0 1.0349.4484 1.0349 1.0349 0 .5864-.4829 1.0348-1.0349 1.0348z"
                        />
                        <path
                            d="m28.2341 6.27618h-7.5889v-.68991c0-1.51778-1.2419-2.75961-2.7596-2.75961-1.5178 0-2.7597 1.24183-2.7597 2.75961v3.44952c0 1.51781 1.2419 2.06971 2.7597 2.06971.5864 0 1.0348-.4484 1.0348-1.0349 0-.58638-.4484-1.03481-1.0348-1.03481-.3795 0-.3795 0-.6899 0v-3.44952c0-.37944.3104-.6899.6899-.6899.3794 0 .6899.31046.6899.6899v1.72476c0 .58642.4484 1.03485 1.0348 1.03485h8.6238c.5864 0 1.0349-.44843 1.0349-1.03485s-.4485-1.03485-1.0349-1.03485z"
                        />
                        <path
                            d="m42.0322 6.27618h-5.8642v-.68991c0-1.51778-1.2418-2.75961-2.7596-2.75961s-2.7596 1.24183-2.7596 2.75961v3.44952c0 1.51781 1.2418 2.06971 2.7596 2.06971.5864 0 1.0348-.4484 1.0348-1.0349 0-.58638-.4484-1.03481-1.0348-1.03481-.3795 0-.3067 0-.6899 0v-3.44952c0-.37944.3104-.6899.6899-.6899.3794 0 .6899.31046.6899.6899v1.72476c0 .58642.4484 1.03485 1.0348 1.03485h6.8991c1.3453 0 2.4146 1.06935 2.4146 2.41462v19.6623c0 .5864.4485 1.0348 1.0349 1.0348s1.0348-.4484 1.0348-1.0348v-19.6623c0-2.48361-2.0007-4.48432-4.4843-4.48432z"
                        />
                        <path
                            d="m42.0321 46.291h-4.4758v-2.0651h4.4758c1.3453 0 2.4147-1.0215 2.4147-2.2152v-13.7738c0-.5203.4484-.9182 1.0348-.9182.5865 0 1.0349.3979 1.0349.9182v13.7738c0 2.2038-2.0007 4.2803-4.4844 4.2803z"
                        />
                    </g>
                    <text fill="#070c63" fontFamily="FSJoey-Medium, FS Joey" fontSize="8" fontWeight="400">
                        <tspan x="45%" y="18" textAnchor="middle">{month}</tspan>
                    </text>
                    <text
                        fontFamily="HelveticaNeue-Bold, Helvetica Neue"
                        fontSize="24"
                        // fontWeight="bold"
                        fill="#070c63"
                    >
                        <tspan
                            x="45%"
                            y="42"
                            textAnchor="middle"
                        >
                            {day}
                        </tspan>
                    </text>
                    {isChecked ? (
                        <>
                            <circle
                                cx="44.1343"
                                cy="43.1895"
                                fill="#7ed321"
                                r="7.69854"
                                stroke="#fff"
                                strokeWidth="1.811"
                                transform="matrix(.70710678 -.70710678 .70710678 .70710678 -17.612951 43.857574)"
                            />
                            <path
                                clipRule="evenodd"
                                d="m43.0815 44.1543-1.2253-1.7507c-.2648-.3785-.829-.4146-1.3019-.0834-.4728.3313-.6319.8741-.367 1.2526l2.1814 3.117c.2649.3785.829.4146 1.3019.0833.2052-.1437.3512-.3273.4293-.5199l3.4204-4.8876c.4194-.5993.3785-1.3109-.0944-1.6422-.4728-.3313-1.1551-.1263-1.5745.4729z"
                                fill="#fff"
                                fillRule="evenodd"
                            />
                        </>
                    ) : (
                        <>
                            <circle
                                cx="44.1379"
                                cy="43.1982"
                                fill="#ff5576"
                                r="7.70108"
                                stroke="#fff"
                                strokeWidth="1.811"
                                transform="matrix(.70710678 -.70710678 .70710678 .70710678 -17.618049 43.862668)"
                            />
                            <path
                                d="m46.4828 44.2179h-4.7569c-.5777 0-1.0194-.4417-1.0194-1.0194 0-.5776.4417-1.0193 1.0194-1.0193h4.7569c.5777 0 1.0194.4417 1.0194 1.0193 0 .5777-.4417 1.0194-1.0194 1.0194z"
                                fill="#fff"
                            />
                        </>
                    )}
                </>
            )}
        />
    )
}

export default IconCalendarWithDateAndCheckmark;
