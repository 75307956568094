import {isFirstCharacterZero} from "@Features/input/phoneField/functions/isFirstCharacterZero";
import {firstCharacter} from "@Features/input/phoneField/functions/firstCharacter";

export default function getAppendCountryCode(setPhoneNumber: (value: string) => void) {
    const appendCountryCode = (dialCode: string, previousDialCode: string, phoneNumber: string) => {
        // User entered phone number with 0 and changed country
        if (phoneNumber && phoneNumber?.length > 1 && isFirstCharacterZero(phoneNumber)) {
            setPhoneNumber(`${dialCode}${phoneNumber.substring(1, phoneNumber.length)}`)
        }

        if (phoneNumber && phoneNumber?.length > 1 && !isFirstCharacterZero(phoneNumber) && firstCharacter(phoneNumber) !== "+") {
            setPhoneNumber(`${dialCode}${phoneNumber.substring(0, phoneNumber.length)}`)
        }

        // User changed country, there is no phone number
        if (!previousDialCode?.length && !phoneNumber) {
            setPhoneNumber(dialCode)
        }
        // User changed country, there was a phone number already filled.
        // only changes country part
        if (previousDialCode?.length && phoneNumber) {
            setPhoneNumber(`${previousDialCode ? dialCode : ''}${phoneNumber.substring(previousDialCode.length || 0, phoneNumber.length)}`)
        }
    }

    return appendCountryCode
}