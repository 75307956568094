import React from 'react';
import Column from "@Containers/components/Column";
import {isNotEmpty} from "@Array/isNotEmpty";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";
import CompanyType from "@SearchResults/types/company/Company.type";
import RouteAmenities from "@Route/components/Amenities/RouteAmenities";
import StationsList from "@Route/components/Station/StationsList";
import CompanyPopover from "@Route/features/company/components/CompanyPopover";
import RouteIconType from "@SearchResults/types/routeIcons/RouteIcon.type";
import SeatClass from "@Route/components/SeatClass/SeatClass";

interface RouteCompanyAndAmenitiesSectionProps {
    route: RouteWithAddedPropsAndPricesType,
    company: CompanyType,
    routeIcons: Record<number, RouteIconType>,
}

function RouteCompanyAndAmenitiesSection({route, company, routeIcons}: RouteCompanyAndAmenitiesSectionProps) {
    const {
        amenitiesHashMap,
        travelInfo: {
            stations,
            seatClass,
        },
        status: {
            showStations,
        },
    } = route || {}

    const hasStationsToDisplay = isNotEmpty(stations)

    return (
        <Column className="company-and-amenities">
            <CompanyPopover
                company={company}
                route={route}
            />
            {amenitiesHashMap && (
                <RouteAmenities
                    routeIcons={routeIcons}
                    amenities={amenitiesHashMap}
                />
            )}
            {hasStationsToDisplay && showStations && (
                <StationsList
                    route={route}
                />
            )}
        </Column>
    );
}

export default RouteCompanyAndAmenitiesSection;