import Row from "@Containers/components/Row";
import PassengerGroup from "@CheckOrder/components/MainSection/PassengersInfo/PassengerGroup";
import Column from "@Containers/components/Column";
import PassengerInputField from "@CheckOrder/components/MainSection/PassengersInfo/PassengerInputField";
import VehicleAddonType from "@CheckOrder/types/VehicleAddon.type";
import useHandleJadrolinijaVehicleRemoval
    from "@CheckOrder/features/addons/features/jadrolinija/hooks/useHandleJadrolinijaVehicleRemoval";
import Translation from "@Translation/components/Translation";

type VehicleAddonProps = {
    vehicle: VehicleAddonType,
    index: number,
}

function VehicleAddon({vehicle, index}: VehicleAddonProps) {
    const {removeVehicle} = useHandleJadrolinijaVehicleRemoval(vehicle)

    return (
        <Column className="check-order__passenger">
            <Row wrap center justify>
                <span className="gb--row gb--row-justify gb--row-wrap gb--card-title">
                    <Translation translationKey="check_order.addons.JADL.vehicle.title"/>
                    &nbsp;
                    <PassengerGroup
                        price={vehicle.price}
                        className="gb--emphasize-default"
                        passengerGroupId={vehicle.singlePassengerGroupId}
                        returnPassengerGroupId={vehicle.returnPassengerGroupId}
                    />
                </span>
                <button
                    type="button"
                    onClick={removeVehicle}
                    className="gb--emphasize gb--emphasize-warning"
                >
                    <Translation translationKey="sys.remove"/>
                </button>
            </Row>
            {vehicle.registrationNumber && (
                <Row justify wrap>
                    {vehicle.brand && (
                        <PassengerInputField
                            name="brand"
                            labelText="Brand"
                            value={vehicle.brand}
                            showErrorText={false}
                            disabled
                            index={index}
                        />
                    )}
                    {vehicle.model && (
                        <PassengerInputField
                            name="model"
                            labelText="Model"
                            value={vehicle.model}
                            showErrorText={false}
                            disabled
                            index={index}
                        />
                    )}
                    <PassengerInputField
                        name="registrationNumber"
                        labelText="Registration number"
                        value={vehicle.registrationNumber}
                        showErrorText={false}
                        disabled
                        index={index}
                    />
                </Row>
            )}
        </Column>
    );
}

export default VehicleAddon;