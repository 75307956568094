import {isAfter, isValid} from "date-fns";
import {setDate, setReturnDate} from "@Form/reducers/formSlice";
import formatDate from "@DateTime/functions/formatDate";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import useSearchResults from "@SearchResults/hooks/useSearchResults";

/**
 * Sets form/urlParams date/returnDate redux state via dispatch
 */
export default function useSetDate(type: "single" | "return" = 'single') {
    const dispatch = useAppDispatch()
    const {returnDate} = useAppSelector((state) => state?.form.urlParams)
    const searchResults = useSearchResults()
    const dispatchType = type === 'single' ? (date) => setDate(date) : (date) => setReturnDate(date)

    return (date) => {
        if (!date && type === 'return') {
            dispatch(setReturnDate(null))
        }
        if (isValid(date)) {
            if (returnDate && isAfter(date, new Date(returnDate))) {
                dispatch(setReturnDate(null))
            }
            dispatch(dispatchType(formatDate(date)))
        } else if (searchResults?.data) {
            if (type === 'single') {
                dispatch(dispatchType(searchResults.data.result.params.date))
            } else if (!date) {
                dispatch(dispatchType(null))
            } else {
                dispatch(dispatchType(searchResults.data.result.params.returnDate))
            }
        }
    }
}