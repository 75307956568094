import React, {MutableRefObject} from 'react';
import DiscountType from "@Route/types/prices/discounts/Discount.type";
import MobileContentContainer from "@Route/features/mobile/components/MobileContentContainer";
import DiscountDetails from "@Route/components/Price/Discount/DiscountDetails";

interface MobileDiscountsSectionProps {
    discounts: DiscountType[],
    scrollRef: MutableRefObject<any>,
}

const MobileDiscountsSection = ({discounts, scrollRef}: MobileDiscountsSectionProps) => (
    <MobileContentContainer title="Web discounts - Early bird" scrollRef={scrollRef}>
        <DiscountDetails
            discounts={discounts}
        />
    </MobileContentContainer>
);

export default MobileDiscountsSection;