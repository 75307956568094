import React from 'react';
import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "@/types/Icon.type";

interface IconTimeElapseProps extends IconType {
    backgroundFill?: string,
    strokeColor?: string,
}

function IconTimeElapse(props: IconTimeElapseProps) {
    const {
        width = '20',
        height = '20',
        fill = '#f03a47',
        backgroundFill = '#ffffff',
        strokeColor = '#070c63',
        viewBox = '0 0 20 20',
        title = 'IconTimeElapse',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none" transform="translate(1 1)">
                    <circle cx="8.833333" cy="8.833333" fill={backgroundFill} r="8.333333"/>
                    <circle
                        cx="14.166433"
                        cy="14.166667"
                        fill={fill}
                        r="3.333301"
                        transform="matrix(.70710678 -.70710678 .70710678 .70710678 -5.868094 14.166502)"
                    />
                    <path
                        d="m15.3333333 14.6666667h-2.3333333c-.2833333 0-.5-.2166667-.5-.5 0-.2833334.2166667-.5.5-.5h2.3333333c.2833334 0 .5.2166666.5.5 0 .2833333-.2166666.5-.5.5z"
                        fill="#fff"
                        transform="matrix(.70710678 -.70710678 .70710678 .70710678 -5.868026 14.166667)"
                    />
                    <path
                        d="m15.3333333 14.6666667h-2.3333333c-.2833333 0-.5-.2166667-.5-.5 0-.2833334.2166667-.5.5-.5h2.3333333c.2833334 0 .5.2166666.5.5 0 .2833333-.2166666.5-.5.5z"
                        fill="#fff"
                        transform="matrix(.70710678 .70710678 -.70710678 .70710678 14.166667 -5.868026)"
                    />
                    <path
                        d="m8.83333333 17.6666667c-4.86666666 0-8.83333333-3.9666667-8.83333333-8.83333337 0-4.86666666 3.96666667-8.83333333 8.83333333-8.83333333 4.86666667 0 8.83333337 3.96666667 8.83333337 8.83333333 0 .26666667-.0166667.55-.0333334.81666667-.0333333.26666667-.2666666.4833333-.55.45-.2666666-.0333333-.4833333-.26666667-.45-.55.0166667-.23333333.0333334-.48333333.0333334-.71666667 0-4.31666666-3.5166667-7.83333333-7.83333337-7.83333333-4.31666666 0-7.83333333 3.51666667-7.83333333 7.83333333 0 4.31666667 3.51666667 7.83333337 7.83333333 7.83333337.25 0 .5-.0166667.75-.0333334.26666667-.0333333.51666667.1833334.54999997.45.0333334.2666667-.1833333.5166667-.44999997.55-.28333333.0166667-.56666666.0333334-.85.0333334z"
                        fill={strokeColor}
                    />
                    <circle cx="8.833333" cy="8.833333" fill={backgroundFill} r="1.166667"/>
                    <path
                        d="m11.5251131 5.54347802-1.85829444 1.79453412c-.47879679-.2558825-1.0559737-.27774407-1.53183135-.02673821l-2.95229807-3.05719415c-.19681988-.20381295-.50318615-.20916059-.70699909-.01234072s-.20916059.50318614-.01234071.70699909l2.95229807 3.05719415c-.34103955.63054018-.24877015 1.42187359.26064599 1.94938945.63677017.65939485 1.6972688.67790585 2.3566636.04113571.5395049-.52099377.6593352-1.30862498.3285199-1.93910552l1.8582945-1.79453412c.203813-.19681987.2091606-.50318614.0123407-.70699908-.1968198-.20381295-.5031861-.20916059-.7069991-.01234072zm-2.69177977 3.95652198c-.36666666 0-.66666666-.3-.66666666-.66666667 0-.36666666.3-.66666666.66666666-.66666666.36666667 0 .66666667.3.66666667.66666666 0 .36666667-.3.66666667-.66666667.66666667z"
                        fill={strokeColor}
                    />
                </g>
            )}
        />

    );
}

export default IconTimeElapse;