import React from 'react';
import Row from "@Containers/components/Row";
import MyProfileCodeDisplay from "@MyProfile/components/MyProfileCodeDisplay";
import {useTranslation} from "@Translation/hooks/useTranslation";
import VoucherStatusType from "@MyVoucher/types/VoucherStatus.type";
import CopyVoucherCodeButton from "@MyVouchers/components/CopyVoucherCodeButton";

interface VoucherCodeProps {
    code: string,
    status: VoucherStatusType,
    copyButtonClassName?: string,
}

const VoucherCodeInfo = ({code, status, copyButtonClassName = ""}: VoucherCodeProps) => {
    const voucherCodeLabel = useTranslation('my_profile.my_vouchers.code')
    const {
        isExpired,
        isUsed,
        isCancelled,
    } = status

    const isValid = !(isExpired || isUsed || isCancelled)

    return (
        <Row justify className="voucher-code-info info-field-medium">
            <MyProfileCodeDisplay
                code={code}
                subTitle={voucherCodeLabel}
                isInfoField={false}
                className="code-container"
                codeClassName={`voucher-code ${!isValid ? "archived" : ""}`}
                codeDescriptionClassName="code-description"
            />
            {isValid && (
                <CopyVoucherCodeButton
                    code={code}
                    className={copyButtonClassName}
                />
            )}
        </Row>
    );
}
export default VoucherCodeInfo;