import toFloat from "@Number/toFloat";
import IntermodalRouteWithDetailsType from "@Intermodal/types/IntermodalRouteWithDetails.type";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";
import ParamsType from "@SearchResults/types/params/Params.type";

/**
 * Event model dispatched when user clicks on route
 */
export default class ClickTripEventModel {
    from: string;

    to: string;

    fromId: number;

    toId: number;

    fromCountry: string;

    toCountry: string;

    departureTime: string;

    numTicketsLeft: number;

    supplierId: string | number;

    transferId: string;

    routeId: string;

    webDiscount: string

    isFeatured: boolean;

    isInstantConfirmation: boolean;

    constructor(route: IntermodalRouteWithDetailsType | RouteWithAddedPropsAndPricesType, params: ParamsType) {
        const {
            id,
            allotment: {
                totalAllotmentLeft,
            },
            prices: {
                activeDiscount,
            },
            travelInfo: {
                departureStation: {
                    departureTime,
                    departureDate,
                },
            },
            companyId,
            isFeatured,
            status: {
                isInstantConfirmation,
            }
        } = route || {}

        const {
            departureStation: {
                id: departureId,
                name: departureName,
                countryCode: departureCountryCode,
            },
            destinationStation: {
                id: destinationId,
                name: destinationName,
                countryCode: destinationCountryCode,
            },
        } = params

        this.from = departureName
        this.to = destinationName
        this.fromId = departureId
        this.toId = destinationId
        this.fromCountry = departureCountryCode
        this.toCountry = destinationCountryCode
        this.departureTime = `${departureDate}T${departureTime}`
        this.numTicketsLeft = totalAllotmentLeft
        this.supplierId = companyId
        this.transferId = `${companyId}-${departureId}-${destinationId}`
        this.routeId = id
        this.webDiscount = activeDiscount?.discountPercentage ? toFloat(activeDiscount?.discountPercentage) : null
        this.isFeatured = isFeatured;
        this.isInstantConfirmation = isInstantConfirmation;
    }
}