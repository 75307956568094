/**
 * Station to station object
 * @param departureStation {object}
 * @param destinationStation {object}
 * @returns {{fromLocationId: number, toLocation, routeName: string, toLocationId: number, fromLocation}}
 * @constructor
 */
export default function StationToStationEventModel(departureStation, destinationStation) {
    return {
        routeName: window.location.href,
        fromLocation: departureStation.label,
        fromLocationId: parseInt(departureStation.id, 10),
        toLocation: destinationStation.label,
        toLocationId: parseInt(destinationStation.id, 10),
    }
}