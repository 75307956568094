import {useEffect} from "react";
import {useComponentDidMount} from "@Hooks/lifecycle/useComponentDidMount";
import {useScroll} from "./useScroll";

export const useScrollToTopOnMount = () => {
    const isMount = useComponentDidMount()
    const {scrollToTop} = useScroll({block: "center", behavior: "instant"})
    useEffect(() => {
        if (isMount) {
            scrollToTop()
        }
    }, [isMount]);
}