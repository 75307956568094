import formatDate from "@DateTime/functions/formatDate";
import ParamsType from "@SearchResults/types/params/Params.type";
import useCompanyMainTransportType from "@Hooks/selector/useCompanyMainTransportType";
import TabType from "@Tabs/types/Tab.type";

export default function useShowTripDelayNotification(params: ParamsType, activeTab: TabType, hasActiveRoutes: boolean) {
    const companyMainTransportType = useCompanyMainTransportType()
    const country = [1];
    const highSeasonMonths = ['6', '7', '8', '9'];

    const {
        departureStation: {
            countryId: departureCountryId,
        },
        destinationStation: {
            countryId: destinationCountryId,
        },
        date,
    } = params;

    const departureMonth = formatDate(date, 'M', true);
    const isHighSeason = highSeasonMonths.includes(departureMonth);

    return (
        companyMainTransportType !== 'ferry'
        && activeTab.tabType !== 'ferry'
        && hasActiveRoutes
        && isHighSeason
        && country.includes(departureCountryId)
        && country.includes(destinationCountryId)
    )
}