import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconBusFull(props: IconType) {
    const {
        width = '16',
        height = '16',
        fill = '#7ed321',
        viewBox = '0 0 20 20',
        title = 'IconBusFull',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <path
                    d="m8 0c-3.625 0-8 .237-8 4v11c0 .884916.3906671 1.671627 1 2.220703v1.279297c0 .828.672 1.5 1.5 1.5s1.5-.672 1.5-1.5v-.5h10v.5c0 .828.672 1.5 1.5 1.5s1.5-.672 1.5-1.5v-1.279297c.609333-.549076 1-1.335787 1-2.220703v-11c0-3.763-4.375-4-8-4zm-5 4h12c.552 0 1 .448 1 1v4c0 .552-.448 1-1 1h-12c-.552 0-1-.448-1-1v-4c0-.552.448-1 1-1zm.5 9c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5-1.5-.672-1.5-1.5.672-1.5 1.5-1.5zm11 0c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5-1.5-.672-1.5-1.5.672-1.5 1.5-1.5z"
                    transform="translate(1)"
                />
            )}
        />

    );
}

export default IconBusFull;
