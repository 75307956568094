import {CountryAdditionalFieldType} from "@CheckOrder/features/TicketAdditionalInfo/types/CountryAdditionalField.type";
import CheckOrderInputContainer from "@CheckOrder/components/MainSection/CheckOrderInputContainer";
import CheckOrderErrorLabel from "@CheckOrder/components/MainSection/CheckOrderErrorLabel";
import React, {useEffect} from "react";
import CountryFlag from "@CheckOrder/components/MainSection/BuyerInfo/CountryFlag";
import AutoSuggest from "@Features/autosuggest/components/AutoSuggest";
import useCountrySelectAutoSuggest from "@CheckOrder/features/TicketAdditionalInfo/hooks/useCountrySelectAutoSuggest";
import CountryOption from "@CheckOrder/features/TicketAdditionalInfo/components/CountryOption";
import {AdditionalFieldOptionType} from "@CheckOrder/features/TicketAdditionalInfo/types/AdditionalFieldOption.type";
import useMatchCountryToIsoCode from "@CheckOrder/features/TicketAdditionalInfo/hooks/useMatchCountryToIsoCode";
import useCreateCountryFieldOptionFromCountryValue
    from "@CheckOrder/features/TicketAdditionalInfo/hooks/useCreateCountryFieldOptionFromCountryValue";

type CountryAdditionalFieldProps = {
    field: CountryAdditionalFieldType,
    value: AdditionalFieldOptionType | undefined,
    onCountrySelect: (value: AdditionalFieldOptionType) => void,
    userConfirmed: boolean,
}

function CountryAdditionalField({field, value, onCountrySelect, userConfirmed}: CountryAdditionalFieldProps) {
    useCreateCountryFieldOptionFromCountryValue({value, onCountrySelect, field})
    const countrySelectAutoSuggest = useCountrySelectAutoSuggest({
        field,
        value,
        onCountrySelect,
        userConfirmed,
    })

    const {
        title,
        description,
    } = field.translations || {}

    const {matchCountryToIsoCode} = useMatchCountryToIsoCode()

    return (
        <CheckOrderInputContainer label={title ?? field.label} className="phone-container">
            {!!description && (
                description
            )}
            <AutoSuggest
                additionalMainContainerClassName="gb--row gb--input-border"
                inputDecoration={!!value && !!value?.id && value?.id !== "" && (
                    <div className="gb--row gb--row-middle gb--row-justify gb--input__adornment">
                        <CountryFlag isoCode={matchCountryToIsoCode(value)}/>
                    </div>
                )}
                autoSuggestModel={countrySelectAutoSuggest}
                suggestionComponent={(
                    // This works via cloning elements, no props are required
                    // not really possible to type this.
                    // @ts-ignore
                    <CountryOption/>
                )}
            />
            {!value && userConfirmed && (
                <CheckOrderErrorLabel isAbsolute/>
            )}
        </CheckOrderInputContainer>
    );
}

export default CountryAdditionalField;