import {ChangeEvent} from "react";
import {
    dayFirstDigitMaxValue,
    daySecondDigitMaxValue, numberOfDigits
} from "@CheckOrder/features/TicketAdditionalInfo/features/dateAdditionalField/constants/dayDigitsConstraints";
import GetOnChangeFunctionAttributesType
    from "@CheckOrder/features/TicketAdditionalInfo/features/dateAdditionalField/types/GetOnChangeFunctionAttributes.type";

interface OnDayChangeGetterAttributes {
    day: string,
    setDay: (value: string) => void,
}

/*
 * returns getOnDayChange function, which accepts next input field's ref and on change function and
 * updates the day value according to the new value (max value is 31), triggers next input field change if necessary
 */
export default function onDayChangeGetter({day, setDay}: OnDayChangeGetterAttributes) {
    // onChange is activated. this means either the character was deleted, or new character was typed in
    const getOnDayChange = (attr: GetOnChangeFunctionAttributesType) => (e: ChangeEvent<HTMLInputElement>, overflowCharacter?: string) => {
        const {
            nextRef,
            nextFieldOnChange,
        } = attr

        if (overflowCharacter) {
            if (parseInt(overflowCharacter, 10) > dayFirstDigitMaxValue) {
                setDay(`0${overflowCharacter}`)
                return;
            }
            setDay(overflowCharacter)
            return;
        }

        const {target: {value: newValue}} = e
        const characterIsAdded = newValue.length > day.length
        const newCharacter = characterIsAdded ? newValue[newValue.length - 1] : ""

        if (characterIsAdded && !day) {
            if (parseInt(newValue, 10) > dayFirstDigitMaxValue) {
                setDay(`0${newCharacter}`)
                return;
            }
            setDay(newValue)
            return;
        }

        if (characterIsAdded && day.length === numberOfDigits) {
            if (!!nextRef && !!nextFieldOnChange) {
                nextRef.current.focus()
                nextFieldOnChange(e, newCharacter)
            }
            return;
        }

        if (characterIsAdded && day.length === 1 && parseInt(day, 10) === dayFirstDigitMaxValue && parseInt(newCharacter, 10) > daySecondDigitMaxValue) {
            setDay(`0${day}`)
            if (!!nextRef && !!nextFieldOnChange) {
                nextRef.current.focus()
                nextFieldOnChange(e, newCharacter)
            }
            return;
        }

        setDay(newValue)
    }

    return {
        getOnDayChange,
    }
}