import React from 'react';
import {JadrolinijaVehicleModelType} from "@CheckOrder/features/addons/features/jadrolinija/types/JadrolinijaVehicleModel.type";

type VehicleSuggestionProps = {
    suggestion: JadrolinijaVehicleModelType
    onClick: (JadrolinijaVehicleModelType) => void,
}
const VehicleSuggestion = ({suggestion, onClick}: VehicleSuggestionProps) => {
    const onClickHandler = () => {
        // @ts-ignore
        onClick(suggestion)(suggestion)
    }

    return (
        <div
            role="button"
            onClick={onClickHandler}
            className="suggestion-content"
        >
            <span>
                {suggestion.name}
            </span>
        </div>
    )
};

export default VehicleSuggestion;