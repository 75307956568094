import {
    DateAdditionalFieldType
} from "@CheckOrder/features/TicketAdditionalInfo/features/dateAdditionalField/types/DateAdditionalField.type";
import CheckOrderInputContainer from "@CheckOrder/components/MainSection/CheckOrderInputContainer";
import CheckOrderErrorLabel from "@CheckOrder/components/MainSection/CheckOrderErrorLabel";
import React, {Fragment} from "react";
import {isLastElement} from "@Array/isLastElement";
import useDateInputFields
    from "@CheckOrder/features/TicketAdditionalInfo/features/dateAdditionalField/hooks/useDateInputFields";
import DateInput from "@CheckOrder/features/TicketAdditionalInfo/features/dateAdditionalField/components/DateInput";
import getDateFieldValidation
    from "@CheckOrder/features/TicketAdditionalInfo/features/dateAdditionalField/functions/getDateFieldValidation";
import useGetDateFieldErrorMessage
    from "@CheckOrder/features/TicketAdditionalInfo/features/dateAdditionalField/hooks/useGetDateFieldErrorMessage";
import getFocusOnInputFieldHandler
    from "@CheckOrder/features/TicketAdditionalInfo/features/dateAdditionalField/functions/getFocusOnInputFieldHandler";

type DateAdditionalFieldProps = {
    field: DateAdditionalFieldType,
    value: string,
    onClick: (value: string) => void,
    userConfirmed: boolean,
}

function DateAdditionalField({field, value, onClick, userConfirmed}: DateAdditionalFieldProps) {
    const {title, description} = field.translations || {}

    const inputFields = useDateInputFields(onClick, value)
    const {focusOnFieldHandler} = getFocusOnInputFieldHandler(value, inputFields)

    const {isDateFieldValid} = getDateFieldValidation(field)
    const showError = !isDateFieldValid(value)
    const {getErrorMessage} = useGetDateFieldErrorMessage(field, value)

    return (
        <CheckOrderInputContainer
            label={title ?? field.label}
            key={field.key}
        >
            {!!description && (
                description
            )}
            <div className="gb--check-order-date-input" onClick={focusOnFieldHandler}>
                {inputFields.map((inputField, index) => (
                    <Fragment key={`${field.label}-${inputField.type}`}>
                        <DateInput
                            index={index}
                            name={`${field.label}-${inputField.type}`}
                            dateInputField={inputField}
                        />
                        {!isLastElement(inputFields, index) && (
                            <span className={!inputField.value ? "slash-separator__placeholder" : ""}>
                                /
                            </span>
                        )}
                    </Fragment>
                ))}
            </div>
            {userConfirmed && showError && (
                <CheckOrderErrorLabel
                    error={getErrorMessage()}
                    isAbsolute
                />
            )}
        </CheckOrderInputContainer>
    );
}

export default DateAdditionalField;