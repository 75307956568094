import React from 'react';
import assetUrlGenerator from '@Images/functions/assetUrlGenerator'
import {useAppSelector} from "@Hooks/selector/useAppSelector";

/**
 * Component that shows loader.
 */
function LoaderBus() {
    const assetsBaseUrl = useAppSelector((state) => state?.page.assetsBaseUrl)
    const transportTypeKeyword = useAppSelector((state) => state?.searchResults.transportTypeKeyword)

    let iconFileName = 'icons/minibusColor.png';

    if (transportTypeKeyword === 'ferry') {
        iconFileName = 'icons/ferry-side.png';
    }
    return (
        <div className="loader-wrapper">
            <div id="bus-wrapper" className="centerLoader">
                <img
                    src={assetUrlGenerator(assetsBaseUrl, iconFileName)}
                    id="bus"
                    className="centerBus"
                    alt={transportTypeKeyword}
                />
            </div>
            <div id="line" className="centerLoader"/>
        </div>
    );
}

export default LoaderBus;