import React from "react"
import Portal from "@Features/portals/components/Portal";
import Sidebar from "./Sidebar";
import AppStoreNoticeContainer from "./AppStoreNotice/AppStoreNoticeContainer";
import ErrorBoundary from "@Errors/components/ErrorBoundary"

const SidebarPortalContainer = () => (
    <ErrorBoundary shouldReload={false}>
        <Portal id="react-sidebar-portal">
            <Sidebar>
                <AppStoreNoticeContainer/>
            </Sidebar>
        </Portal>
    </ErrorBoundary>
);

export default SidebarPortalContainer;