import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconMinivanOutline(props: IconType) {
    const {
        width = '14',
        height = '10',
        fill = '#4a79e2',
        viewBox = '0 0 14 10',
        title = 'IconMinivanOutline',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <path
                    d="m1.5 0c-.8225314 0-1.5.61588055-1.5 1.36363636v6.36363637c0 .61156091.4552578 1.12936091 1.0703125 1.29971636.1873965.55913273.7569914.97301091 1.4296875.97301091.6468403 0 1.1977678-.38270182 1.40625-.90909091h5.1875c.208482.52638909.759409.90909091 1.40625.90909091s1.197768-.38270182 1.40625-.90909091h.59375c.822531 0 1.5-.61588091 1.5-1.36363636v-3.33451709c0-.29456782-.105271-.58023509-.300781-.81676128l-2.5-3.0308949c-.282969-.34299182-.727605-.54509946-1.199219-.54509946zm0 .90909091h5.5v2.27272727c0 .74775582.6774686 1.36363637 1.5 1.36363637h4.5v3.18181818c0 .25588091-.218531.45454545-.5.45454545h-.59375c-.208482-.52638909-.759409-.90909091-1.40625-.90909091s-1.197768.38270182-1.40625.90909091h-5.1875c-.2084822-.52638909-.7594097-.90909091-1.40625-.90909091-.5932805 0-1.1070577.32202364-1.3496094.78125-.0928001-.08195091-.1503906-.19639454-.1503906-.32670454v-6.36363637c0-.25588054.2185314-.45454545.5-.45454545zm6.5 0h2c.158386 0 .305359.06591818.400391.181108l2.099609 2.54616473h-4c-.2814686 0-.5-.19866491-.5-.45454546zm-5.5 7.27272727c.2820647 0 .5.19812273.5.45454546 0 .25642272-.2179353.45454545-.5.45454545s-.5-.19812273-.5-.45454545c0-.25642273.2179353-.45454546.5-.45454546zm8 0c.282065 0 .5.19812273.5.45454546 0 .25642272-.217935.45454545-.5.45454545s-.5-.19812273-.5-.45454545c0-.25642273.217935-.45454546.5-.45454546z"
                    fill={fill}
                />
            )}
        />

    );
}

export default IconMinivanOutline;
