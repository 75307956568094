import {useEffect} from "react";
import {AdditionalFieldOptionType} from "@CheckOrder/features/TicketAdditionalInfo/types/AdditionalFieldOption.type";
import countryData from "@CheckOrder/data/countryData.json";
import {CountryAdditionalFieldType} from "@CheckOrder/features/TicketAdditionalInfo/types/CountryAdditionalField.type";

interface UseCreateCountryFieldOptionFromCountryValueParams {
    field: CountryAdditionalFieldType,
    value: AdditionalFieldOptionType | string | undefined,
    onCountrySelect: (value: AdditionalFieldOptionType) => void
}

/**
 * when adding addons and amenities page returns updated check order data, which causes country field to "update".
 * value is then a simple string (what we save on BE) and not a AdditionalFieldOptionType. This clears country selected from the field
 *
 * In those situations try to match value returned from BE to options on frontend,
 */
export default function useCreateCountryFieldOptionFromCountryValue(params: UseCreateCountryFieldOptionFromCountryValueParams) {
    const {
        onCountrySelect,
        value,
        field,
    } = params

    const {
        options,
    } = field

    const matchToOptionsByValue = (value: string) => (
        options.filter((country) => (country.value === value))
    )

    useEffect(() => {
        if (typeof value === "string") {
            if (!!options && options.length) {
                const countriesMatchedToStringValue = matchToOptionsByValue(value)

                if (countriesMatchedToStringValue.length) {
                    onCountrySelect(countriesMatchedToStringValue[0])
                }
            } else {
                const countriesMatchedToStringValue = countryData.filter((country) => (
                    country.isoCode === value
                ))

                if (countriesMatchedToStringValue.length) {
                    onCountrySelect({
                        id: value,
                        label: countriesMatchedToStringValue[0].name,
                        value,
                    })
                }
            }
        }
    }, [typeof value]);
}