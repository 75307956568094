import React from "react";
import Notification from "@Features/notification/components/Notification";
import IconExclamationCircle from "@Icons/IconExclamationCircle";
import useIsMobile from "@Hooks/selector/useIsMobile";
import AdditionalNotificationsType from "@MyTicket/types/AdditionalNotifications.type";

interface RadioButtonLabelProps {
    labelHeader: string,
    labelDescription: string,
    notificationType?: "blue" | "orange",
    notificationText?: string,
    selectedOptionId?: string | number,
    currentOptionId?: string | number,
    additionalNotifications?: AdditionalNotificationsType,
}

function RadioButtonLabel(props: RadioButtonLabelProps) {
    const {
        labelDescription,
        labelHeader,
        notificationText,
        additionalNotifications,
        notificationType = "blue",
        selectedOptionId,
        currentOptionId,
    } = props

    const isMobile = useIsMobile()

    return (
        <>
            <div className="gb--emphasize gb--emphasize-info">{labelHeader}</div>
            {additionalNotifications && Object.values(additionalNotifications.additionalNotifications).map((additionalNotification) => {
                if (!currentOptionId || currentOptionId !== selectedOptionId) {
                    return;
                }
                return (
                    <Notification
                        notificationIcon={!isMobile && additionalNotification.icon}
                        additionalClass="gb--row label-notification"
                        notificationType={notificationType}
                    >
                        <div dangerouslySetInnerHTML={{__html: additionalNotification.label}}/>
                    </Notification>
                )
            })}
            {currentOptionId && currentOptionId === selectedOptionId && (
                <Notification
                    notificationIcon={!isMobile && <IconExclamationCircle/>}
                    additionalClass="gb--row label-notification"
                    notificationType={notificationType}
                >
                    <div dangerouslySetInnerHTML={{__html: notificationText}}/>
                </Notification>
            )}
            <p>{labelDescription}</p>
        </>
    );
}

export default RadioButtonLabel;
