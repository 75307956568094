import React from 'react';
import useQuantityAwareTranslation from "../hooks/useQuantityAwareTranslation";

interface QuantityAwareTranslationProps {
    translationKey: string,
    quantity: number,
    variableReplacements?: object
}

function QuantityAwareTranslation({translationKey, quantity, variableReplacements}: QuantityAwareTranslationProps) {
    const translation = useQuantityAwareTranslation(translationKey, quantity, variableReplacements)
    return (
        <>
            {translation}
        </>
    )
}

export default QuantityAwareTranslation;