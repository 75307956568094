/**
 * Strips <span> elements from suggestion values on display
 */
export default function stripTags(station: string): string {
    if (station) {
        let newStation = station;
        if (station.includes("(")) {
            if (station.substr(station.lastIndexOf("("), station.lastIndexOf(")")).length === 4) {
                newStation = station.substr(0, station.lastIndexOf("("));
            }
        }
        const regex = /([ ][<]).*/;
        return newStation.replace(regex, '');
    }
}