import React from 'react';
import GenericPopover from "@Popover/components/GenericPopover";
import IconFire from "@Icons/IconFire";
import Translation from "@Translation/components/Translation";
import useCompanyStyle from "@Hooks/selector/useCompanyStyle";
import usePassengers from "@Hooks/selector/usePassengers";

interface TotalAllotmentLeftProps {
    totalAllotmentLeft: number,
}

function TotalAllotmentLeft({totalAllotmentLeft}: TotalAllotmentLeftProps) {
    const {shortName} = useCompanyStyle()
    const {passengers} = usePassengers()

    const displayOverlay = () => (
        <span className="gb--emphasize gb--emphasize-warning">
            <Translation
                translationKey="sys.only_left_on_getbybus"
                exact
                translationVariables={{
                    "<b>%count%</b>": totalAllotmentLeft,
                    "%store%": shortName,
                }}
            />
        </span>
    )

    const displayTrigger = () => (
        <span className="gb--badge gb--badge-left gb--badge-red">
            <IconFire style={{verticalAlign: 'text-top'}}/>
            <Translation
                translationKey="sys.only_left"
                exact
                translationVariables={{
                    "<b>%count%</b>": totalAllotmentLeft,
                }}
            />
        </span>
    )

    if (totalAllotmentLeft <= 5 + passengers) {
        return (
            <GenericPopover
                overlay={displayOverlay()}
                trigger={displayTrigger()}
                id="TotalAllotmentPopover"
            />
        );
    }

    return null
}

export default TotalAllotmentLeft