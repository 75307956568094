import React, {ReactNode, useState} from 'react';
import MobileAppTitle from "@SearchResults/components/HeadingContent/Mobile/MobileAppTitle";
import SlideInContainer from "@Containers/components/SlideInContainer";
import {ActionSheetPositionType} from "@MobileOverlays/types/ActionSheetPosition.type";
import getActionSheetPositionAdjuster from "@MobileOverlays/functions/getActionSheetPositionAdjuster";
import getSlideInContainerClassName from "@MobileOverlays/functions/getSlideInContainerClassName";
import MobileSwiper from "@Features/mobileSwiper/components/MobileSwiper";
import setActionSheetSwipeClassName from "@MobileOverlays/functions/setActionSheetSwipeClassName";
import useHandleActionSheetCloseOnPull from "@MobileOverlays/hooks/useHandleActionSheetCloseOnPull";

interface MobileOverlayProps {
    backClick?: (any?) => any,
    title: string,
    children: ReactNode,
    className?: string,
    slideInDirection?: 'up' | 'down' | 'left' | 'right' | 'zoom',
    isActionSheet?: boolean,
    wrapperClassName?: string

}

const MobileOverlay = ({title, backClick, children, className, slideInDirection = "zoom", isActionSheet = false, wrapperClassName = ""}: MobileOverlayProps) => {
    // useBrowserHistoryBackInterceptor()
    const [
        position,
        setPosition,
    ] = useState<ActionSheetPositionType>(1)

    useHandleActionSheetCloseOnPull(position, backClick)
    const {adjustPosition} = getActionSheetPositionAdjuster(setPosition)

    if (isActionSheet) {
        return (
                <div className="gb--mobile-overlay gb--mobile-overlay__fit-content">
                    <MobileSwiper
                        className={`${getSlideInContainerClassName(slideInDirection)} gb--mobile-overlay__content gb--mobile-overlay__content-bottom ${setActionSheetSwipeClassName(position)} ${wrapperClassName}`}
                        onSwipe={adjustPosition}
                    >
                        {children}
                    </MobileSwiper>
                </div>
        )
    }

    return (
        <SlideInContainer
            slideInDirection={slideInDirection}
            className="gb--mobile-overlay"
        >
            <MobileAppTitle
                backClick={backClick}
                className={className}
            >
                {title}
            </MobileAppTitle>
            {children}
        </SlideInContainer>
    );
}

export default MobileOverlay;