import {activatePopup} from "@Reducers/pageSlice";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import {useTranslation} from "@Translation/hooks/useTranslation";
import {PreparedRouteType} from "@Route/types/PreparedRoute.type";

export default function useDispatchRoutePopover(route: PreparedRouteType) {
    const dispatch = useAppDispatch()
    const sysRouteNotInSellableRange = useTranslation("sys.route_not_in_sellable_range")
    const sysTicketsSoldOut = useTranslation("sys.tickets_sold_out")
    const SysRouteNotInSellableRangePopup = useTranslation("sys.route_not_in_sellable_range_popup")
    const sysTicketsSoldOutPopup = useTranslation("sys.tickets_sold_out_popup")

    const dispatchSoldOutPopover = () => {
        const {soldOut} = route.status
        const message = soldOut ? sysTicketsSoldOutPopup : SysRouteNotInSellableRangePopup
        const title = soldOut ? sysTicketsSoldOut : sysRouteNotInSellableRange

        dispatch(activatePopup({
            message,
            title,
        }))
    }

    return {
        dispatchSoldOutPopover,
    }
}