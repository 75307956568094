import React from 'react';
import MobileFormOverlay from "./MobileFormOverlay";
import {useAppSelector} from "@Hooks/selector/useAppSelector";

function MobileFormOverlayContainer() {
    const {formVisible} = useAppSelector((state) => state?.form)
    if (formVisible) {
        return (
            <MobileFormOverlay/>
        );
    }

    return null
}

export default MobileFormOverlayContainer;