import {NumberAdditionalFieldType} from "@CheckOrder/features/TicketAdditionalInfo/types/NumberAdditionalField.type";
import getNumberFieldValidation
    from "@CheckOrder/features/TicketAdditionalInfo/functions/getNumberFieldValidation";

export default function allowNumberFieldUpdate(field: NumberAdditionalFieldType) {
    const {isNumberFieldValid} = getNumberFieldValidation(field)

    return (value: string) => (
        isNumberFieldValid(value) || value === ""
    )
}