import IconChevron from "@Icons/IconChevron";

interface SliderArrowProps {
    direction: "left" | "right",
    move: () => void,
    disabled: boolean
}

function SliderArrow({direction, move, disabled}: SliderArrowProps) {
    return (
        <button
            type="button"
            className="slider-arrow"
            onClick={move}
        >
            <IconChevron
                className={disabled ? "slider-arrow--disabled" : ""}
                chevronOrientation={direction}
            />
        </button>
    )
}

export default SliderArrow