import React from 'react';
import Row from "@Containers/components/Row";
import IntermodalTicketLabel from "@Intermodal/components/IntermodalTicketLabel";
import Column from "@Containers/components/Column";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";
import RouteCompanyAndAmenitiesSection from "@Route/components/Sections/RouteCompanyAndAmenitiesSection";
import RoutePriceBreakdownSection from "@Route/components/Sections/RoutePriceBreakdownSection";
import RouteItinerary from "@Route/components/Sections/RouteItinerary";
import RouteIconType from "@SearchResults/types/routeIcons/RouteIcon.type";
import SeatClass from "@Route/components/SeatClass/SeatClass";

interface RouteDetailsProps {
    route: RouteWithAddedPropsAndPricesType,
    routeIcons: Record<number, RouteIconType>,
    index?: number,
}

const RouteDetails = ({route, index, routeIcons}: RouteDetailsProps) => (
    <Row justify className="route-expanded-details-section">
        <RouteItinerary
            route={route}
            companyLetterCode={route.company?.letterCode}
        />
        {route.travelInfo.seatClass ? (
            <SeatClass
                displayImages
                seatClass={route.travelInfo.seatClass }
            />
        ) : (
            <>
                <RouteCompanyAndAmenitiesSection
                    routeIcons={routeIcons}
                    route={route}
                    company={route.company}
                />
                {(route.status.isIntermodal || route.status.isSuggestion) ? (
                    <Column>
                        <IntermodalTicketLabel
                            index={index}
                            route={route}
                        />
                        <RoutePriceBreakdownSection
                            route={route}
                        />
                    </Column>
                ) : (
                    <RoutePriceBreakdownSection
                        route={route}
                    />
                )}
            </>
        )}
    </Row>
);

export default RouteDetails;