import React from 'react'
import AppStoreLink from "@Sidebar/components/AppStoreNotice/AppStoreLink";
import Row from "@Containers/components/Row";
import Column from "@Containers/components/Column";

interface AppStoreLinksContainerProps {
    direction?: string,
    className?: string
}

const AppStoreLinksContainer = ({direction, className}: AppStoreLinksContainerProps) => {
    if (direction === "column") {
        return (
            <Column wrap className={className}>
                <AppStoreLink
                    link={window.appStoreUrl}
                    filePath="images/icons/appstore/appstore-ico-us"
                    fileType="svg"
                    className="app-store-link"
                />
                <AppStoreLink
                    link={window.googleStoreUrl}
                    filePath="images/icons/googlestore/google-play-ico-en"
                    fileType="svg"
                    className="google-play-link"
                />
            </Column>
        )
    }
    return (
        <Row center gap className={className}>
            <AppStoreLink
                link={window.appStoreUrl}
                filePath="images/icons/appstore/appstore-ico-us"
                fileType="svg"
                className="app-store-link"
            />
            <AppStoreLink
                link={window.googleStoreUrl}
                filePath="images/icons/googlestore/google-play-ico-en"
                fileType="svg"
                className="google-play-link"
            />
        </Row>
    )
}

export default AppStoreLinksContainer