import React, {ReactNode} from 'react';
import classNameTypeAggregator from "@Features/badge/functions/classNameTypeAggregator";

interface BadgeProps {
    info?: boolean,
    success?: boolean,
    warning?: boolean,
    error?: boolean,
    left?: boolean,
    right?: boolean,
    className?: string,
    children: ReactNode,
}

const Badge = (props: BadgeProps) => {
    const {
        className = '',
        children,
        info = false,
        success = false,
        warning = false,
        error = false,
        left = false,
        right = true,
    } = props

    const activeClassNameType = classNameTypeAggregator("gb--badge", {
        info,
        success,
        warning,
        error,
        left,
        right,
    })

    return (
        <span className={`${activeClassNameType}${className}`}>
            {children}
        </span>
    );
}

export default Badge;