import React from 'react';
import PropTypes from "prop-types";

/**
 * Component that shows standard GBB loader.
 */
function Loader({small}) {
    return (
        <div className="redirect-view my-profile-loader">
            <div className="row redirect-loader">
                <ul className={`loading ${small ? 'small' : ''}`}>
                    <li/>
                    <li/>
                    <li/>
                </ul>
            </div>
        </div>
    );
}

Loader.propTypes = {
    /** Do we want small loader or standard big one. Pass true for small (false or don't pass prop for big) */
    small: PropTypes.bool,
};

export default Loader;
