import {checkPasswordValidation} from "@CheckOrder/reducers/checkOrderSlice";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";

type ThirdPartyRedirectPartner = "apple"|"google"
export default function useHandleThirdPartyLogin(thirdPartyRedirectPartner: ThirdPartyRedirectPartner) {
    const dispatch = useAppDispatch()
    const handleThirdPartyLogin = (e) => {
        e.preventDefault();
        window.location.assign(`/login/${thirdPartyRedirectPartner}`);
        dispatch(checkPasswordValidation(true))
    }

    return {
        handleThirdPartyLogin,
    }
}