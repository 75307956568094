import React from 'react';
import IconFriends from "@Icons/IconFriends";

interface RouteBannerConnectionProps {
    departureStation: string,
    destinationStation: string,
    connectionInfo: string
}

function RouteBannerConnection(props: RouteBannerConnectionProps) {
    const {
        departureStation,
        destinationStation,
        connectionInfo,
    } = props
    return (
        <div className="banner__heading--route gb--row gb--row-middle">
            <span className="gb--emphasize">{departureStation}</span>
            <div className="gb--column gb--column-middle">
                <small>
                    {connectionInfo}
                    <IconFriends/>
                </small>
                <span className="banner__divider">
                    <span className="banner__divider-circle"/>
                    <span className="banner__divider-line"/>
                    <span className="banner__divider-circle"/>
                </span>
                <br/>
            </div>
            <span className="gb--emphasize">{destinationStation}</span>
        </div>
    );
}

export default RouteBannerConnection;