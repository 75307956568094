import max from "lodash/max";
import {setMaxRoutePrice as dispatchMaxPrice} from "@SearchResults/reducers/filterSlice";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";

/**
 * Sets max price found from routes, used for transfer banner display conditions
 */
export default function useSetMaxRoutePrice() {
    const dispatch = useAppDispatch()

    const setMaxRoutePrice = (routes: RouteWithAddedPropsAndPricesType[]) => {
        dispatch(dispatchMaxPrice(max(routes.map((route) => route.prices.price))))
    }

    return {
        setMaxRoutePrice,
    }
}