import {useRebookTicketMutation} from "@MyProfile/API/myProfileApi";

interface UseRebookModalAttributes {
    ticketId: string,
    setIsManageBookingModalOpen: (value: boolean) => void,
}

export default function useRebookModal({ticketId, setIsManageBookingModalOpen}: UseRebookModalAttributes) {
    const [rebookTicket, {data, isLoading, isError}] = useRebookTicketMutation()

    const voucher = data?.voucher

    const successfulRebook = !!voucher
    const unsuccessfulRebook = voucher === null || isError || !!data?.error

    const handleTicketRebook = () => {
        rebookTicket(ticketId)
    }

    const reFetchTicketsAndCloseModal = () => {
        setIsManageBookingModalOpen(false)
    }

    return {
        handleTicketRebook,
        voucher,
        loading: isLoading,
        resetApiState: successfulRebook
            ? reFetchTicketsAndCloseModal
            : undefined,
        successfulRebook,
        unsuccessfulRebook,
    }
}