import RatingsType from "@Route/types/ratings/Ratings.type";
import {isNumber} from "@Number/isNumber";

/**
 * Returns rating based on available route rating data
 */
export const provideCompanyRating = (ratings: RatingsType): string => {
    const {
        companyRating,
        routeRating,
        stationRating,
    } = ratings
    // If route has ratings prefer returning those
    if (routeRating) {
        const {score, reviewCount} = routeRating
        if (isNumber(score) && score >= 4 && reviewCount >= 10) {
            return score.toFixed(1);
        }
    }

    // There aren't enough reviews for route, return station combination ratings
    if (stationRating) {
        const {score, reviewCount} = stationRating
        if (isNumber(score) && score >= 4 && reviewCount >= 9) {
            return score.toFixed(1);
        }
    }

    // No other rating are available, return overall company score
    if (companyRating) {
        const {score, reviewCount} = companyRating
        if (isNumber(score) && score !== 0 && reviewCount > 4) {
            return score.toFixed(1);
        }
    }

    return '0'
};