import {useParams} from "react-router";
import {generateEmptyMainTransportTypeTab} from "@Tabs/functions/generateEmptyMainTransportTypeTab";
import useCompanyMainTransportType from "@Hooks/selector/useCompanyMainTransportType";

export default function useActiveTab(tabs) {
    const {tab: selectedTabType} = useParams()
    const mainTransportType = useCompanyMainTransportType()

    const selectedTab = tabs.find((tab) => tab.tabType === selectedTabType)

    // active tab is equal to preselected tab
    if (selectedTab) {
        return selectedTab
    }

    const mainTransportTab = tabs.find((tab) => tab.tabType === mainTransportType)

    // if main transport tab has available routes which are not redirect, display it
    if (mainTransportTab.availableRoutes > 0 && mainTransportTab.hasNonRedirectRoutes) {
        return mainTransportTab
    }

    // if some other tab has non redirect routes, display it first
    const currentTab = tabs.find((tab) => tab.tabType !== mainTransportType && tab.availableRoutes > 0 && tab.hasNonRedirectRoutes)

    if (currentTab) {
        return currentTab
    }

    // if main transport tab has available routes, display it
    if (mainTransportTab.availableRoutes > 0) {
        return mainTransportTab
    }

    // display first tab that has available routes, if no tabs have available routes, display empty main transport tab first
    return tabs.find((tab) => tab.tabType !== mainTransportType && tab.availableRoutes > 0) ?? generateEmptyMainTransportTypeTab(mainTransportType)
}