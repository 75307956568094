import Row from "@Containers/components/Row";
import Translation from "@Translation/components/Translation";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import React from "react";

const VoucherSummary = () => {
    const voucherIssuer = useAppSelector((state) => state?.checkOrder.ticket?.voucher?.voucherDetails?.voucherIssuer);
    if (voucherIssuer) {
        return (
            voucherIssuer.map((issuer) => {
                const isFixed = issuer?.currency
                const currencyOrPercentage = isFixed ? ` ${issuer?.currency?.shortCode}` : '%';
                return (
                    <Row justify center className="voucher-value-display">
                        <span>
                            <Translation translationKey="check_order.voucher.voucher"/>
                        </span>
                        <span>
                            {`-${issuer.amount}${currencyOrPercentage}`}
                        </span>
                    </Row>
                )
            })
        )
    }
}
export default VoucherSummary;