import Vehicles from "@CheckOrder/features/addons/features/jadrolinija/components/Vehicles";
import Addon from "@CheckOrder/features/addons/components/Addon";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {useLazyGetAddonQuery} from "@CheckOrder/api/checkOrderApi";

type AddVehicleAddonProps = {
    addon: {
        label: string,
        description: string,
        iconName: string,
    }
}

function AddVehicleAddon(props: AddVehicleAddonProps) {
    const [fetchAddonData, addonData] = useLazyGetAddonQuery()
    const {ticketCode} = useAppSelector((state) => state.checkOrder.ticket)
    const getAddonData = () => {
        fetchAddonData({
            ticketCode,
        })
    }

    return (
        <Addon
            onClick={getAddonData}
            label={props.addon.label}
            description={props.addon.description}
            iconName={props.addon.iconName}
        >
            {addonData?.data && (
                <Vehicles
                    addonData={addonData.data.addonData}
                />
            )}
        </Addon>
    );
}

export default AddVehicleAddon;