import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconStarFull(props: IconType) {
    const {
        width = '14',
        height = '14',
        viewBox = '0 0 20 20',
        title = 'StarFull',
        fill = "#fff",
        stroke = "#a3b1c6",
        strokeOpacity = ".5",
        strokeWidth = ".5",
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            title={title}
            fill={fill}
            stroke={stroke}
            strokeOpacity={strokeOpacity}
            strokeWidth={strokeWidth}
            {...rest}
            svgIconPath={(
                <path
                    d="m14.4750578 19c.5349309 0 .9762489-.2042411 1.3239539-.6127232.3477051-.4084822.4814378-.8805804.4011982-1.4162947l-.7221567-4.3794642 3.0290462-3.09375004c.2273456-.24107143.3744516-.515625.441318-.82366072.0802396-.32142857.0668663-.63950893-.0401198-.95424107-.1069862-.31473214-.2841821-.57589286-.5315876-.78348214-.2474056-.20758929-.5315876-.33816964-.8525461-.39174107l-4.1122813-.62276786-1.8455115-3.91741071c-.147106-.32142858-.367765-.56919643-.661977-.74330358-.2808387-.17410714-.5817374-.26116071-.9026959-.26116071-.32095854 0-.62185717.08705357-.90269589.26116071-.29421199.17410715-.51487098.421875-.66197698.74330358l-1.84551158 3.91741071-4.11228124.62276786c-.32095853.05357143-.60514057.18415178-.85254611.39174107-.24740554.20758928-.4246014.46875-.53158757.78348214-.10698618.31473214-.12370277.62946429-.05014978.94419643.073553.31473214.22400232.59263393.45134795.83370536l3.02904618 3.09375004-.72215671 4.3794642c-.08023963.5357143.05349309 1.0078125.40119817 1.4162947.34770508.4084821.78902307.6127232 1.32395396.6127232.29421199 0 .57505071-.0736607.84251616-.2209821l3.63084344-2.0089286 3.6308434 2.0089286c.2674655.1473214.5483042.2209821.8425162.2209821z"
                />
            )}
        />

    );
}

export default IconStarFull;
