import React, {useEffect, useState} from 'react';
import Translation from "@Translation/components/Translation";
import useSharedImagesPath from "@Images/hooks/useSharedImagesPath";
import IconSleepover from "@Icons/FilledOutline/IconSleepover";
import IconSlimArrowRight from "@Icons/IconSlimArrowRight";
import getAccommodationMap from "@SearchResults/features/banners/functions/getAccommodationMap";
import CurrencyType from "@Currency/types/Currency.type";
import ParamsType from "@SearchResults/types/params/Params.type";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import PassengerCountType from "@Form/types/PassengerCount.type";

type AccommodationBannerProps = {
    passengers: PassengerCountType
    currency: CurrencyType,
    params: ParamsType,
}

const AccommodationBanner = (props: AccommodationBannerProps) => {
    const {
        passengers,
        currency,
        params: {
            accommodation,
            destinationStation: {
                name,
            },
            date,
            returnDate,
        },
    } = props

    const {companyMainTransportType} = useAppSelector((state) => state.page)

    const iconPath = useSharedImagesPath(`images/stay22/${companyMainTransportType}.png`)
    const [{url, bannerUrl}, setBannerUrl] = useState(
        getAccommodationMap(
            accommodation,
            passengers,
            currency?.val,
            date,
            returnDate,
            iconPath
        )
    )

    useEffect(
        () => {
            setBannerUrl(
                getAccommodationMap(
                    accommodation,
                    passengers,
                    currency?.val,
                    date,
                    returnDate,
                    iconPath
                )
            )
        },
        [
            passengers,
            currency?.val,
        ]
    )

    return (
        <a
            className="stay22-banner gb--card gb--row gb--row-middle gb--row-justify gb--row-wrap"
            target="_blank"
            href={url}
            rel="noopener noreferrer"
        >
            <IconSleepover
                className="stay22-banner__icon"
            />
            <div>
                <h2 className="banner-header">
                    <Translation
                        translationKey="succ.staying_overnight"
                        translationVariables={{location: name}}
                    />
                    ?
                </h2>
                <Translation translationKey="succ.accommodation_within"/>
                &nbsp;
                <IconSlimArrowRight/>
            </div>
            <img
                src={bannerUrl}
                className="stay22-banner__map"
                id="stay-banner"
                alt={`${name} " accommodation map"`}
            />
        </a>
    );
}

export default AccommodationBanner;