import React, {useEffect, useState} from "react";
import useHandlePhoneInput from "@CheckOrder/hooks/useHandlePhoneInput";
import CheckOrderErrorLabel from "@CheckOrder/components/MainSection/CheckOrderErrorLabel";
import {useTranslation} from "@Translation/hooks/useTranslation";
import Translation from "@Translation/components/Translation";
import CheckOrderInputContainer from "@CheckOrder/components/MainSection/CheckOrderInputContainer";
import Phone from "@Features/input/phoneField/components/Phone";
import {validatePhoneCountry} from "@Features/input/phoneField/functions/validatePhoneCountry";

function PhoneNumber() {
    const {
        phoneNumber,
        phoneCountryObject,
        userConfirmed,
        submitTimestamp,
        setPhoneNumber,
        setPhoneCountryObject,
        phoneValidated,
        passengerDataUpdating,
        setValidState,
    } = useHandlePhoneInput()

    const [errors, setShowErrors] = useState(false)
    const loadingClass = passengerDataUpdating ? "phone-input-disabled" : ""

    const showErrors = () => ((!phoneValidated || !validatePhoneCountry(phoneCountryObject))
            ? setShowErrors(true)
            : setShowErrors(false)
    )

    const invalidPhoneNumberLabel = useTranslation("check_order.buyer_info_section.invalid_phone_number");
    const invalidPhoneNumberCountryLabel = useTranslation("check_order.buyer_info_section.invalid_phone_number_country");

    const errorString = {
        [`${!phoneValidated && phoneNumber?.length > 0}`]: invalidPhoneNumberLabel,
        [`${!phoneValidated && phoneNumber.length > 4 && !validatePhoneCountry(phoneCountryObject)}`]: invalidPhoneNumberCountryLabel,
        [`${phoneNumber?.length === 0}`]: undefined,
    }

    useEffect(() => {
        if (userConfirmed) {
            showErrors()
        }
    }, [userConfirmed, submitTimestamp])

    useEffect(() => {
        if (phoneValidated) {
            showErrors()
        }
    }, [phoneValidated])

    return (
        <CheckOrderInputContainer
            label={useTranslation('check_order.phone_number_check_order')}
            className="phone-container"
        >
            <small>
                <Translation translationKey="check_order.buyer_info_section.phone_contact_note"/>
            </small>
            <Phone
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
                phoneCountryObject={phoneCountryObject}
                setPhoneCountryObject={setPhoneCountryObject}
                loadingClass={loadingClass}
                passengerDataUpdating={passengerDataUpdating}
                setValidState={setValidState}
                onBlur={showErrors}
            />
            {errors && (
                <CheckOrderErrorLabel error={errorString.true}/>
            )}
        </CheckOrderInputContainer>
    );
}

export default PhoneNumber