import {TextAdditionalFieldType} from "@CheckOrder/features/TicketAdditionalInfo/types/TextAdditionalField.type";

export default function getTextFieldValidation(field: TextAdditionalFieldType) {
    const isTextFieldValid = (value: string) => (
        !!value && value.length >= field.minLength && value.length <= field.maxLength && (field.validationRegex ? new RegExp(field.validationRegex).test(value) : true)
    )

    return {
        isTextFieldValid,
    }
}