import ModalContent from "@Generic/Modal/ModalContent";
import Column from "@Containers/components/Column";
import LoaderButton from "@Generic/Loaders/LoaderButton";
import Translation from "@Translation/components/Translation";
import React from "react";

const PaymentPendingModal = () => (
    <ModalContent style={{padding: "1.5em", borderRadius: 12}}>
        <Column center>
            <LoaderButton fill="#7ed321" width="35" height="35"/>
            <span className="gb--emphasize" style={{padding: "1rem"}}>
                <Translation translationKey="t_summary.please_wait"/>
            </span>
        </Column>
    </ModalContent>
);

export default PaymentPendingModal;