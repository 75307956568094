import React from 'react';
import IntermodalIcon from "@Intermodal/components/IntermodalIcon";
import useGetTransferTypeLabelConfiguration from "@Intermodal/hooks/useGetTransferTypeLabelConfiguration";
import Row from "@Containers/components/Row";
import {RouteTransferTypeKeys} from "@Route/features/transportType/types/routeTransferType.type";

interface IntermodalTransportTypeProps {
    routeTransferType: RouteTransferTypeKeys,
    showLabel?: boolean,
    index?: number,
    justified?: boolean,
}

function IntermodalTransportType({routeTransferType, showLabel, index, justified}: IntermodalTransportTypeProps) {
    const transferType = useGetTransferTypeLabelConfiguration(routeTransferType)

    if (justified) {
        return (
            <Row justify center>
                <span className="gb--card-title">
                    {`${transferType.label} - ${index + 1}.leg`}
                </span>
                <span>
                    <IntermodalIcon
                        transferType={transferType}
                    />
                </span>
            </Row>
        )
    }

    if (showLabel) {
        return (
            <span
                className="gb--emphasize-default gb--intermodal-icon-label"
                style={{backgroundColor: transferType.backgroundColor}}
            >
                {`${transferType.label} - ${index + 1}.leg`}
                <IntermodalIcon
                    transferType={transferType}
                />
            </span>
        )
    }

    return (
        <IntermodalIcon
            transferType={transferType}
        />
    )
}

export default IntermodalTransportType;