import ImageLabelField from "@Generic/ImageLabelField";
import assetUrlGenerator from "@Images/functions/assetUrlGenerator";
import React from "react";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {AdditionalFieldOptionType} from "@CheckOrder/features/TicketAdditionalInfo/types/AdditionalFieldOption.type";
import useMatchCountryToIsoCode from "@CheckOrder/features/TicketAdditionalInfo/hooks/useMatchCountryToIsoCode";

type CountryOptionProps = {
    suggestion: AdditionalFieldOptionType,
    index: number,
    onClick: (value: string) => void,
}

function CountryOption({suggestion, index, onClick}: CountryOptionProps) {
    const assetsBaseUrl = useAppSelector((state) => state?.page.assetsBaseUrl)
    const {matchCountryToIsoCode} = useMatchCountryToIsoCode()
    const onClickHandler = () => {
        // @ts-ignore
        onClick()(suggestion)
    }
    return (
        <ImageLabelField
            imageHeight={50}
            imageUrl={assetUrlGenerator(assetsBaseUrl, `country_flags/${matchCountryToIsoCode(suggestion).toLowerCase()}.png`)}
            label={suggestion.label}
            index={index}
            onClickHandler={onClickHandler}
        />
    );
}

export default CountryOption;