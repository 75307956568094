import React from 'react';
import {useTranslation} from "@Translation/hooks/useTranslation";
import useTransportTypeAwareTranslation from "@Translation/hooks/useTransportTypeAwareTranslation";
import NoResultsNoticeContainer from "./NoResultsNoticeContainer";
import CollectEmail from "./CollectEmail";
import ParamsType from "@SearchResults/types/params/Params.type";
import SearchResultsUrlsType from "@SearchResults/types/urls/SearchResultsUrls.type";

interface NoResultsNoticeProps {
    smallTitle?: boolean,
    params: ParamsType,
    urls: SearchResultsUrlsType,
}

function NoResultsNotice({smallTitle = false, params, urls}: NoResultsNoticeProps) {
    const noResultsTitleLabel = useTransportTypeAwareTranslation('no_res.no_results_title')
    const emailInformLabel = useTranslation('no_res.email_inform')
    return (
        <NoResultsNoticeContainer
            content={emailInformLabel}
            title={noResultsTitleLabel}
            smallTitle={smallTitle}
        >
            <CollectEmail
                params={params}
                urls={urls}
            />
        </NoResultsNoticeContainer>
    );
}

export default NoResultsNotice;