import useRouteMatch from "@Routing/hooks/useRouteMatch";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import useIsMobile from "@Hooks/selector/useIsMobile";

export default function useDisplayEditButton() {
    const isMobile = useIsMobile()
    const additionalInfoActive = useRouteMatch('additionalInfo')
    const returnAdditionalInfoActive = useRouteMatch('returnAdditionalInfo')
    const isSearch = useRouteMatch('searchResults')
    const {mobileOverlayActive} = useAppSelector((state) => state.mobile)

    return isMobile && isSearch && (!additionalInfoActive && !returnAdditionalInfoActive && !mobileOverlayActive)
}