import React from 'react';
import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconFaq(props: IconType) {
    const {
        width = '50',
        height = '50',
        viewBox = '0 0 85 85',
        title = 'IconFaq',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none" fillRule="evenodd">
                    <path
                        d="m36.75 84h-33.75c-.9 0-1.725-.525-2.1-1.35s-.15-1.8.45-2.475l8.25-8.55c-5.7-6.525-8.85-14.85-8.85-23.625 0-19.875 16.125-36 36-36 .975 0 1.875 0 2.85.075 1.275.075 2.175 1.2 2.1 2.4-.075 1.275-1.2 2.175-2.4 2.1-.9-.075-1.725-.075-2.55-.075-17.4 0-31.5 14.1-31.5 31.5 0 8.325 3.225 16.2 9.075 22.125.825.9.9 2.25 0 3.15l-6 6.225h28.425c17.4 0 31.5-14.1 31.5-31.5 0-.825 0-1.65-.075-2.475-.075-1.275.825-2.325 2.1-2.4 1.2-.075 2.325.825 2.4 2.1.075.9.075 1.875.075 2.775 0 19.875-16.125 36-36 36z"
                        fill="#444b54"
                        fillRule="nonzero"
                    />
                    <circle
                        cx="62.999325"
                        cy="21.750375"
                        fill="#7ed321"
                        fillRule="nonzero"
                        r="14.999856"
                        transform="matrix(.70710678 -.70710678 .70710678 .70710678 3.072237 50.917787)"
                    />
                    <path
                        d="m63 12c-3 0-6 2.0438928-6 6h3.46875c0-2.3473275 1.03125-2.9312977 2.53125-2.9312977 1.2011715 0 2.109375.8816801 2.109375 2.1984733 0 2.1984733-3.75 2.4847335-3.75 6.7328244h3.140625c0-3.372137 4.5-2.7938931 4.5-7.1908397 0-2.9312977-2.402343-4.8091603-6-4.8091603z"
                        fill="#fff"
                    />
                    <path
                        d="m63 27c-1.0952381 0-2 .9047619-2 2s.9047619 2 2 2 2-.9047619 2-2-.9047619-2-2-2z"
                        fill="#fff"
                    />
                    <g fill="#4a79e2" fillOpacity=".5" fillRule="nonzero">
                        <path
                            d="m49.5 43.5h-25.5c-1.275 0-2.25-.975-2.25-2.25s.975-2.25 2.25-2.25h25.5c1.275 0 2.25.975 2.25 2.25s-.975 2.25-2.25 2.25z"
                        />
                        <path
                            d="m38.25 57h-14.25c-1.275 0-2.25-.975-2.25-2.25s.975-2.25 2.25-2.25h14.25c1.275 0 2.25.975 2.25 2.25s-.975 2.25-2.25 2.25z"
                        />
                        <path
                            d="m50.25 57h-.75c-1.275 0-2.25-.975-2.25-2.25s.975-2.25 2.25-2.25h.75c1.275 0 2.25.975 2.25 2.25s-.975 2.25-2.25 2.25z"
                        />
                    </g>
                </g>
            )}
        />

    );
}

export default IconFaq;
