import {useParams} from "react-router";
import {SearchParamsFromRouteType} from "@SearchResults/types/SearchParamsFromRouteType";
import usePassengers from "@Hooks/selector/usePassengers"

export default function useSearchParamsExtractedFromRoute() {
    const {
        date,
        returnDate,
        fromSlug,
        toSlug,
        transportTypeRoute,
        locale,
    } = useParams()
    const passengers = usePassengers()

    // This looks idiotic but TS throws errors otherwise as params are resolved as "readonly"
    // maybe fix at some point, low priority
    return <SearchParamsFromRouteType>{
        date,
        returnDate,
        fromSlug,
        toSlug,
        transportTypeRoute,
        locale,
        pax: passengers.passengers,
    }
}