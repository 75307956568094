import {useRef} from "react";
import useEventListener from "@Hooks/eventListeners/useEventListener";

/**
 * Adds event listener to execute onClickOutside on clicking the element outside returned ref
 */
export default function useClickOutside(onClickOutside: () => void) {
    const ref = useRef<any>()
    const handleClick = ({target}) => {
        if (!ref.current.contains(target)) {
            onClickOutside()
        }
    }

    useEventListener('mousedown', handleClick)

    return ref
}