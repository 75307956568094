import parse from "date-fns/parse";
import formatDate from "@DateTime/functions/formatDate";

/**
 * Returns formatted travel time for station, "HH:mm" by default
 * @param travelTime {string}
 * @param formatTo {string} - Format of new datetime string
 * @param parseFormat {string} - String format to parse
 * @returns {string}
 */

interface GetFormattedTravelTimeAttributes {
    travelTime: string,
    formatTo?: string,
    parseFormat?: string,
}

export default function getFormattedTravelTime(props: GetFormattedTravelTimeAttributes) {
    const {
        travelTime,
        formatTo = 'HH:mm',
        parseFormat = 'HH:mm:ss',
    } = props

    return formatDate(parse(travelTime, parseFormat, new Date()), formatTo) ?? null
}