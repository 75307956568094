import React from 'react';
import SidebarSectionContainer from "../Sections/SidebarSectionContainer";
import {useTranslation} from "@Translation/hooks/useTranslation";
import IconGbbBlogs from "@Icons/IconGbbBlogs";
import BlogPosts from "./BlogPosts";
import LatestBlogType from "@Sidebar/types/LatestBlog.type";

interface BlogPostsContainerProps {
    latestBlogPosts: LatestBlogType[]
}

function BlogPostsContainer({latestBlogPosts}: BlogPostsContainerProps) {
    const latestBlogPostsTitle = useTranslation("search_results.latest_blog_posts")

    return (
        <SidebarSectionContainer
            contentArray={latestBlogPosts}
            title={latestBlogPostsTitle}
            icon={<IconGbbBlogs width="45"/>}
        >
            <BlogPosts blogPosts={latestBlogPosts}/>
        </SidebarSectionContainer>
    );
}

export default BlogPostsContainer;