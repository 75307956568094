import useOnMount from "@Hooks/lifecycle/useOnMount";
import {useScroll} from "@Scroll/hooks/useScroll";
import {useParams} from "react-router";
import {PreparedRouteType} from "@Route/types/PreparedRoute.type";

export default function useExpandPreselectedRoute(route: PreparedRouteType) {
    const {routeId, returnRouteId} = useParams()
    const {executeScroll, elRef} = useScroll({})

    const routeIdPreselectHandler = (routeId: string) => {
        if (route.status.status === "open" && routeId === route.id) {
            // eslint-disable-next-line no-console
            console.warn(`preselect handler -> Navigating to ${route.id}`)
            // eslint-disable-next-line no-unused-expressions
            // @ts-ignore
            elRef.current?.click()
            executeScroll()
        }
    }
    /**
     * This is kept for legacy purposes, as old links might still use /#id for preselection
     */
    const hashPreselectHandler = () => {
        let {hash} = window.location;
        if (hash) {
            // eslint-disable-next-line no-console
            console.warn(`legacy hash handler -> Navigating to ${route.id}`)
            const {status: {status}, id} = route;

            hash = hash.substring(2);

            if (hash.split('/').length > 1) {
                // eslint-disable-next-line prefer-destructuring
                hash = hash.split('/')[1]
            }

            if (status === "open" && hash === route.id) {
                // eslint-disable-next-line no-unused-expressions
                // @ts-ignore
                elRef.current?.click()
                executeScroll()
            }
        }
    }
    const simulateClickIfRouteIdExists = () => {
        // Timeout is set to "prioritize" scrolling to element rather than top of page when page is loaded
        setTimeout(() => {
            if (!returnRouteId) {
                hashPreselectHandler()
                routeIdPreselectHandler(routeId)
            } else {
                routeIdPreselectHandler(returnRouteId)
            }
        }, 100)
    }

    useOnMount(simulateClickIfRouteIdExists)

    return {
        elRef,
        executeScroll,
    }
}