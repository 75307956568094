import IconUserFull from "@Icons/IconUserFull";
import React from "react";
import RouteTransferType from "@Route/features/transportType/types/RouteTransfer.type";
import TicketPrice from "@MyTickets/components/TicketPrice";

interface PriceInfoProps {
    isIntermodal: boolean,
    isCancelled: boolean,
    routeTransferTypeArray: RouteTransferType[],
    paidAmountWithCurrency: string,
    numberOfPassengers: number,
}

function PriceInfo(props: PriceInfoProps) {
    const {
        isIntermodal,
        isCancelled,
        routeTransferTypeArray,
        paidAmountWithCurrency,
        numberOfPassengers,
    } = props

    return (
        <TicketPrice
            isIntermodal={isIntermodal}
            routeTransferTypeArray={routeTransferTypeArray}
            paidAmountWithCurrency={paidAmountWithCurrency}
            isCancelled={isCancelled}
        >
            <span className="pax-number gb--label gb--label-default route--price-pax">
                <IconUserFull fill="#070c63"/>
                {numberOfPassengers}
            </span>
        </TicketPrice>
    );
}

export default PriceInfo