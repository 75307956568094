import {useEffect} from "react";
import {useComponentDidMount} from "./useComponentDidMount";

/**
 * Executes function on mount only
 * @param {function} functionToExecute
 */
export default function useOnMount(functionToExecute: () => void) {
    const isMount = useComponentDidMount()
    useEffect(() => {
        if (isMount) {
            functionToExecute();
        }
    }, [functionToExecute, isMount])
}