import {useNavigate} from "react-router";

export default function useGoBack() {
    const navigate = useNavigate()
    const goBack = () => {
        // If user has landed directly on additional info screen there
        // will not be any history to go back to so navigate to previous route in stack
        if (history.length > 3) {
            navigate(-1)
        } else {
            navigate("..")
        }
    }

    return {
        goBack,
    }
}