import React from 'react';
import useFixedEurConversion from "@Currency/hooks/useFixedEurConversion";
import {useAppSelector} from "@Hooks/selector/useAppSelector";

interface PassengerGroupProps {
    passengerGroupId: number,
    returnPassengerGroupId: number,
    price: string,
    className?: string,
}

function PassengerGroup({passengerGroupId, returnPassengerGroupId, price, className}: PassengerGroupProps) {
    const {
        passengerGroups,
        returnPassengerGroups,
    } = useAppSelector((state) => state?.checkOrder?.purchasedRoute)

    const {shouldDisplayPriceInKn, priceInKn} = useFixedEurConversion(
        price,
        true,
        false
    )
    const returnGroup = returnPassengerGroupId !== 0 ? returnPassengerGroups[returnPassengerGroupId]?.name : null
    const singleGroup = passengerGroups[passengerGroupId]?.name
    const activeGroup = returnGroup ? `${singleGroup} / ${returnGroup}` : singleGroup

    const priceString = shouldDisplayPriceInKn
        ? `${activeGroup} - ${price} ${priceInKn}`
        : `${activeGroup} (${price})`

    return (
        <small className={className}>
            {priceString}
        </small>
    );
}

export default PassengerGroup;