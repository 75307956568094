import {provideCompanyRating} from "@Route/features/company/functions/companyRatingProvider";
import {calculateTripDuration} from "@Route/functions/calculateTripDuration";
import checkIsRouteActive from "@Route/functions/checkIsRouteActive";
import RouteType from "@Route/types/Route.type";
import RouteWithAddedPropsType from "@Route/types/RouteWithAddedProps.type";
import CompanyType from "@SearchResults/types/company/Company.type";

/**
 * Adds several missing properties for both api & regular routes
 *
 * TODO: Update this with RTK query + middleware https://github.com/reduxjs/redux-toolkit/releases/tag/v1.8.0
 * * Checks if route is past sale block time and adds appropriate status.
 * * Calculates travel duration for entire list
 * * Checks routes for available rating providers and injects appropriate rating as main
 * * Adds 'uniqueDepartureTime' to route to filter only unique routes (removes api duplicates)
 * @param route {array|object}
 * @param map {boolean} - Should the function map the array or only return passed object
 * @param nestedParameter {string} - Returns nested property of **route**
 * @param isReturn {boolean}
 * @return {array}
 */

interface AddRoutePropertiesAttributes {
    route: RouteType,
    nestedParameter?: any,
    isReturn?: boolean,
    companies: Record<string, CompanyType>
}

export default function addRouteProperties(props: AddRoutePropertiesAttributes) {
    const {
        route,
        nestedParameter = undefined,
        isReturn = false,
        companies,
    } = props

    const addProperties = (route: RouteType): RouteWithAddedPropsType => {
        const isNested = (): RouteType => (nestedParameter ? route?.[nestedParameter] : route)
        const {type: {routeTransferType}} = isNested()
        const activeRoute = checkIsRouteActive(isNested(), isNested().travelInfo.departureStation.departureDate);
        const {
            ratings,
            companyId,
            travelInfo: {
                departureStation: {
                    departureDate,
                    departureTime,
                    id: departureId,
                },
                destinationStation: {
                    arrivalDate,
                    arrivalTime,
                    id: destinationId,
                },
            },
        } = activeRoute

        const travelDuration = calculateTripDuration(
            `${departureDate} ${departureTime}`,
            `${arrivalDate} ${arrivalTime}`
        );

        const rating = provideCompanyRating(ratings)
        const uniqueDepartureTime = `${routeTransferType}-${companyId}-${departureDate}-${departureTime}-${arrivalDate}-${arrivalTime}-${departureId}-${destinationId}`

        return <RouteWithAddedPropsType> {
            ...activeRoute,
            company: companies[companyId],
            uniqueDepartureTime,
            travelInfo: {
                ...activeRoute.travelInfo,
                travelDuration,
            },
            ratings: {
                ...activeRoute.ratings,
                rating,
            },
            status: {
                ...activeRoute.status,
                isReturn,
            },
        }
    }


    return addProperties(route);
}