import React from "react";
import {Outlet} from "react-router";
import {SearchResponseType} from "@SearchResults/types/SearchResponse.type";
import useProcessGbbSearchResults from "@SearchResults/hooks/useProcessGbbSearchResults";
import useSearchResultsEvent from "@Events/hooks/useSearchResultsEvent";
import useMetaGenerator from "@SearchResults/hooks/useMetaGenerator";

type TabsWrapperType = {
    searchQuery: SearchResponseType
    suggestionRoutesEnabled: boolean
}

function TabsWrapper({searchQuery, suggestionRoutesEnabled}: TabsWrapperType) {
    useSearchResultsEvent(searchQuery)
    useProcessGbbSearchResults(searchQuery)
    useMetaGenerator(searchQuery)
    return (
        <Outlet
            context={{...searchQuery, suggestionRoutesEnabled}}
        />
    );
}

export default TabsWrapper
