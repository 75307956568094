import React from 'react';
import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconLogin(props: IconType) {
    const {
        width = '16',
        height = '16',
        viewBox = '0 0 16 16',
        title = 'IconArrowRight',
        fill = "#4a79e2",
        transform = "rotate(180)",
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            title={title}
            fill={fill}
            transform={transform}
            {...rest}
            svgIconPath={(
                <path
                    d="m8 1c1.93750969 0 3.5885348.68228484 4.953125 2.046875s2.046875 3.01561531 2.046875 4.953125-.6822848 3.5885348-2.046875 4.953125-3.01561531 2.046875-4.953125 2.046875c-1.47917406 0-2.81249406-.4166625-4-1.25s-2.0312475-1.91666-2.53125-3.25h1.0625c.29166812.6458366.69791406 1.2291641 1.21875 1.75 1.1666725 1.1666725 2.583325 1.75 4.25 1.75s3.0833275-.5833275 4.25-1.75 1.75-2.583325 1.75-4.25-.5833275-3.0833275-1.75-4.25-2.583325-1.75-4.25-1.75-3.0833275.5833275-4.25 1.75c-.52083594.52083594-.92708188 1.10416344-1.21875 1.75h-1.0625c.5000025-1.33334 1.34374406-2.4166625 2.53125-3.25s2.52082594-1.25 4-1.25zm-1.53125 9.125 1.625-1.625h-7.09375v-1h7.09375l-1.625-1.625.6875-.71875 2.84375 2.84375-2.84375 2.84375z"
                />
            )}
        />

    );
}

export default IconLogin;