export const sliderSetting = {
    accessibility: true,
    infinite: false,
    focusOnSelect: false,
    centerMode: true,
    centerPadding: '0',
    swipeToSlide: true,
    touchThreshold: 15,
    speed: 1000,
    className: 'datestrip-slider',
}