import React from 'react';
import IntermodalTransportTypeIconDetailsType from "@Intermodal/types/IntermodalTransportTypeIconDetails.type";

interface IntermodalIconProps {
    transferType: IntermodalTransportTypeIconDetailsType
}

const IntermodalIcon = ({transferType}: IntermodalIconProps) => (
    <span
        className="gb--column-middle gb--intermodal-icon-container"
        style={{backgroundColor: transferType.color}}
    >
        {transferType.icon}
    </span>
);

export default IntermodalIcon;