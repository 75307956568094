import React from 'react';
import {toggleSorter} from "@/reducers/mobileSlice";
import {useTranslation} from "@Translation/hooks/useTranslation";
import sortingOptions from "@SearchResults/features/sorting/constants/sortingOptions";
import SortingOptions from "@SearchResults/features/sorting/components/SortingOptions";
import {useSort} from "@SearchResults/features/sorting/hooks/useSort";
import MobileOverlay from "./MobileOverlay";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import {useAppSelector} from "@Hooks/selector/useAppSelector";

function MobileSorter() {
    const sortTitle = useTranslation("sys.sort_by")
    const dispatch = useAppDispatch()
    const onSelection = useSort()
    const selectedOption = useAppSelector((state) => state?.filter.sortBy)
    const selectAndClose = (selection) => () => {
        onSelection(selection.value)
        dispatch(toggleSorter())
    }

    return (
        <MobileOverlay
            backClick={() => dispatch(toggleSorter())}
            title={sortTitle}
            slideInDirection="down"
        >
            <SortingOptions
                onSelection={(selection) => selectAndClose(selection)}
                sortingOptions={sortingOptions}
                selected={selectedOption}
            />
        </MobileOverlay>
    );
}

export default MobileSorter;