import React from "react";
import CheckOrderInputContainer from "@CheckOrder/components/MainSection/CheckOrderInputContainer";
import DropdownButton from "@Generic/DropdownButton";
import {DimensionOption, VehicleAddonResponseType} from "@CheckOrder/api/types/GetAddonData.type";
import {useTranslation} from "@Translation/hooks/useTranslation";

interface VehicleAdditionalHeightProps {
    activeOption: { value: number, label: string, activeGroup: VehicleAddonResponseType }
    setHeight: (value: DimensionOption) => void,
    additionalHeight: DimensionOption | null,
}

function VehicleAdditionalHeight({activeOption, setHeight, additionalHeight}: VehicleAdditionalHeightProps) {
    const {
        additional_height: additionalHeightLabel,
        no_additional_height: noAdditionalHeightLabel,
        additional_height_placeholder: additionalHeightPlaceholderLabel,
    } = useTranslation("check_order.addons.JADL.vehicle")

    const getHeightActiveLabel = () => {
        return activeOption.activeGroup.heightOptions.find((option) => (
            option.value === additionalHeight?.value
        ))?.label ?? noAdditionalHeightLabel
    }

    return (
        <CheckOrderInputContainer label={additionalHeightLabel}>
            {additionalHeightPlaceholderLabel}
            <DropdownButton
                label={getHeightActiveLabel()}
                baseClass="gb--select"
                cssButtonClass="gb--select-dropdown"
            >
                {activeOption.activeGroup.heightOptions.map((option) => (
                    <button
                        type="button"
                        key={option.value}
                        value={option.value}
                        onClick={() => setHeight(option)}
                        className="gb--select__option full-width"
                    >
                        {option.label}
                    </button>
                ))}
            </DropdownButton>
        </CheckOrderInputContainer>
    );
}

export default VehicleAdditionalHeight