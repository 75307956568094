import React from 'react';
import IntermodalStatusContainer from "@Intermodal/components/IntermodalStatusContainer";
import IntermodalRouteWithDetailsType from "@Intermodal/types/IntermodalRouteWithDetails.type";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";
import {isIntermodalRoute} from "@SearchResults/functions/isIntermodalRoute";
import SuggestedRouteType from "@Route/types/SuggestedRoute.type";
import RouteStation from "@Route/components/Station/RouteStation";
import RouteConnection from "@Route/components/Connection/RouteConnection";
import RouteMTicket from "@Route/components/MTicket/RouteMTicket";
import RouteCompanyDetails from "@Route/features/company/components/RouteCompanyDetails";
import RouteStatusContainer from "@Route/components/Container/RouteStatusContainer";
import ParamsType from "@SearchResults/types/params/Params.type";
import {isSuggestedRoute} from "@SearchResults/functions/isSuggestedRoute";
import {displayTicketType} from "@/functions/overrides/displayTicketType"
import DebugApiRouteInfo from "@Features/debug/components/DebugApiRouteInfo";
import useShowApiFullIntegrationRouteDebugPopover
    from "@Features/debug/hooks/useShowApiFullIntegrationRouteDebugPopover";

interface RouteItemContainerProps {
    route: IntermodalRouteWithDetailsType | RouteWithAddedPropsAndPricesType | SuggestedRouteType,
    activeHub?: string,
    params: ParamsType
    isReturn: boolean,
}

function RouteItemContainer(props: RouteItemContainerProps) {
    const {
        route,
        activeHub,
        isReturn,
        params,
    } = props

    const {
        id,
        travelInfo: {
            departureStation: {
                name: departureStationName,
                departureTime: departureTravelTime,
                departureDate,
            },
            destinationStation: {
                name: destinationStationName,
                arrivalTime: arrivalTravelTime,
                arrivalDate,
            },
        },
        status: {
            isSuggestion,
        },
    } = route

    const showOperatingDate = departureDate !== arrivalDate
    const showDebug = useShowApiFullIntegrationRouteDebugPopover(route)

    return (
        <>
            <RouteStation
                routeId={id}
                travelTime={departureTravelTime}
                stationName={departureStationName}
                date={departureDate}
                customClassName="gb--route-element gb--route-station"
                showOperatingDate={showOperatingDate}
            />
            <RouteConnection
                route={route}
                showVehicleIcon={false}
                activeHub={activeHub}
                operatingDateShown={showOperatingDate}
            />
            <RouteStation
                routeId={id}
                travelTime={arrivalTravelTime}
                stationName={destinationStationName}
                date={arrivalDate}
                customClassName="gb--route-element gb--route-station"
                showOperatingDate={showOperatingDate}
            />
            {route.status.status !== "unavailable" && (
                <RouteMTicket
                    displayTicketType={displayTicketType(route)}
                    route={route}
                />
            )}
            {(isIntermodalRoute(route) || isSuggestedRoute(route)) ? (
                <IntermodalStatusContainer
                    baseRoutes={route.baseRoutes}
                    busChangeCount={route.travelInfo.busChangeCount}
                />
            ) : (
                <RouteCompanyDetails
                    route={route}
                />
            )}
            <RouteStatusContainer
                isReturn={isReturn}
                params={params}
                route={route}
            />
            {showDebug && (
                <DebugApiRouteInfo
                    route={props.route}
                />
            )}
        </>
    );
}

export default RouteItemContainer