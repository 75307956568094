import roundToTwoDecimals from "@Number/roundToTwoDecimals";
import PassengerGroupType from "@SearchResults/types/passenger/PassengerGroup.type";
import PassengerGroupWithPricesType from "@SearchResults/types/passenger/PassengerGroupWithPrices.type";
import RouteWithAddedPropsType from "@Route/types/RouteWithAddedProps.type";

/**
 * Returns route specific passenger groups
 * @returns {[]}
 */

interface GenerateRoutePassengerGroups {
    route: RouteWithAddedPropsType,
    activeCurrencyRate: any,
    passengerGroups: Record<string, PassengerGroupType>
    sameCompany: boolean,
}

export default function generateRoutePassengerGroups(props: GenerateRoutePassengerGroups): PassengerGroupWithPricesType[] {
    const {
        route,
        activeCurrencyRate,
        passengerGroups,
        sameCompany,
    } = props

    const {prices, status} = route
    const routeActive = status.status === 'open'
    const possibleGroups = routeActive ? Object.keys(prices?.priceWithDiscount[1]) : []

    const getPriceInActiveCurrency = (price) => roundToTwoDecimals(price * activeCurrencyRate)

    const getPriceForGroup = (type, group) => (
        getPriceInActiveCurrency(sameCompany ? prices?.[type][2][group] : prices?.[type][1][group])
    )

    const mapPossibleGroupsToPassengerTypes = (): PassengerGroupWithPricesType[] => (
        possibleGroups.map((group) => ({
            ...passengerGroups[group],
            priceWithDiscount: getPriceForGroup("priceWithDiscount", group),
            priceWithoutDiscount: getPriceForGroup("priceWithoutDiscount", group),
        }))
    )

    const filterActiveGroups = () => (
        mapPossibleGroupsToPassengerTypes().filter((group) => (
            (group.isActive && group.priceWithDiscount > 0) ?? group
        ))
    )

    return filterActiveGroups()
}