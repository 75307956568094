import usePassengers from "@Hooks/selector/usePassengers";
import useRoutePassengerPriceBreakdownGeneratorForPrivateVehicles
    from "@Route/hooks/useRoutePassengerPriceBreakdownGeneratorForPrivateVehicles";
import useRoutePassengerGroups from "./useRoutePassengerGroups";
import filterActivePassengerGroups from "../functions/filterActivePassengerGroups";

/**
 * Returns all route passenger groups that have passengers and are active
 * @param route {object}
 */
export default function useRouteActivePassengerGroups(route) {
    const passengerGroups = useRoutePassengerGroups(route)
    const passengers = usePassengers()

    const activeGroups = filterActivePassengerGroups({route, passengers, passengerGroups})
    const {pricesPerGroup} = useRoutePassengerPriceBreakdownGeneratorForPrivateVehicles(route.type.isPrivate, activeGroups)

    return pricesPerGroup
}