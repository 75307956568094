/**
 * Copies text to user clipboard
 * @param text {string}
 * @returns {Promise<boolean|void>}
 */
export async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
        await navigator.clipboard.writeText(text);
    }

    return document.execCommand('copy', true, text);
}