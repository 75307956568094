import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function LogoGetByBus(props: IconType) {
    const {
        width = '148',
        height = '36',
        viewBox = '0 0 148 36',
        title = 'GetByBusLogo',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none">
                    <g fill="#7ed321">
                        <path
                            d="m6.91138654 6.76964066c.76504395 0 1.55645687.21117 2.37426247.63351643.81780559.42234643 1.49050649 1.18783786 2.01812309 2.2964972h.0791421v-2.37568676h5.2233773v20.23293227c0 2.7452517-.6792961 4.8041597-2.0379086 6.1767855-1.3586125 1.3726259-3.6339246 2.0589286-6.82600449 2.0589286-2.32151267 0-4.10878681-.4949298-5.36187603-1.4848042-1.25308922-.9898745-1.87962443-2.3954751-1.87962443-4.216844h5.22337738c0 .6863129.27699451 1.2274361.83099187 1.6233859.26380824.1847766.54739785.329956.85077736.4355426s.62653978.1583791.96949049.1583791c1.10799468 0 1.88621735-.3563495 2.33469145-1.0690591.448474-.7127095.6727077-1.5969841.6727077-2.6528501v-2.8112294h-.0791421c-.5539974.7918995-1.2332934 1.4254097-2.03790864 1.9005494-.80461516.4751397-1.64219384.7127061-2.5127611.7127061-2.16322768 0-3.79221924-.8248831-4.8870235-2.4746738-1.09480427-1.6497907-1.6421982-4.4147984-1.6421982-8.2951062 0-1.2406427.07254619-2.5076629.21764073-3.8010988.14509454-1.293436.44846948-2.4614703.91013393-3.504138.46166445-1.04266774 1.13436542-1.89394697 2.01812308-2.55386329.88375767-.65991626 2.06428191-.98986945 3.54160814-.98986945zm1.62241269 4.19704664c-.60675901 0-1.09479698.1319812-1.46412852.3959478-.36933153.2639664-.65951631.6929056-.87056291 1.2868303-.21104659.5939246-.34954384 1.3858123-.41549593 2.3756867-.06595203.9898744-.09892758 2.1975031-.09892758 3.6229224 0 .7391062.03297555 1.4386069.09892758 2.0985232.06595209.6599163.19785423 1.247233.39571044 1.7619677.19785615.5147347.47485071.9238767.83099187 1.2274382.35614109.3035615.82439373.4553399 1.40477192.4553399s1.06182099-.1517784 1.44434297-.4553399c.38252193-.3035615.68589693-.7193026.91013393-1.2472356.2242371-.527933.3825196-1.1680422.4748525-1.9203468s.1384987-1.5771875.1384987-2.4746737c0-2.5868718-.2044484-4.4214116-.6133512-5.5036743s-1.15414992-1.6233859-2.23576377-1.6233859z"
                        />
                        <path
                            d="m27.1925798 6.76964066c1.5828495 0 2.8820857.25736346 3.8977475.77209819 1.0156618.51473467 1.8136698 1.2406317 2.3940479 2.17771285.5803782.9370811.9760846 2.0721201 1.1871313 3.405151.2110466 1.3330309.3165683 2.8178203.3165683 4.4544127v1.2274382h-10.8028941v1.7025755c0 .6335196.0461657 1.2472325.1384986 1.8411572.0923329.5939246.2440204 1.1218497.455067 1.5837912.2110466.4619413.4880411.8314889.8309918 1.1086538s.7650377.4157452 1.2662733.4157452c.9233289 0 1.5960299-.3299532 2.0181231-.9898695s.712278-1.662974.8705629-3.0092033h4.9068091c-.1055234 2.4812853-.7650341 4.3686176-1.9785521 5.6620535s-3.1260991 1.9401442-5.7378009 1.9401442c-1.9785619 0-3.5218171-.3299532-4.6298117-.9898695-1.1079947-.6599163-1.9257881-1.5309927-2.4534046-2.6132554-.5276165-1.0822628-.8507768-2.2964905-.9694905-3.6427197-.1187137-1.3462293-.1780697-2.6924383-.1780697-4.0386675 0-1.4254192.0989267-2.7980245.2967828-4.117857.1978562-1.3198326.5935627-2.494466 1.1871312-3.5239354.5935687-1.02946943 1.4443375-1.84775338 2.5523322-2.45487635s2.5852988-.91067989 4.4319566-.91067989zm-.1978552 3.72190924c-.5539974 0-1.0090598.1253822-1.365201.3761504-.3561411.2507682-.6397307.5807214-.8507773.9898695s-.3627341.8644835-.455067 1.3660199c-.0923329.5015363-.1384986 1.0030652-.1384986 1.5046016v.8314903h5.5795167c-.0263808-.8182962-.0791417-1.5375941-.1582842-2.1579154-.0791424-.6203214-.2176397-1.1482465-.4154959-1.5837912-.1978562-.4355448-.4748507-.765498-.8309919-.9898695-.3561411-.2243715-.8112035-.3365556-1.3652008-.3365556z"
                        />
                        <path
                            d="m38.6759892 1.22637153v6.097596h-2.7699729v3.72190927h2.7699729v12.195192c0 1.0822627.0659511 1.9797354.1978552 2.692445s.3759231 1.2868282.7320642 1.7223729c.3561412.4355448.8705596.7259036 1.5432706.8710852.6727111.1451816 1.5366702.2177712 2.5919032.2177712.6331399 0 1.2332946-.0197971 1.8004824-.0593921.5671877-.039595 1.1541523-.0593922 1.7609113-.0593922v-3.8010988c-.1846658.0263966-.382519.0461938-.5935657.0593922-.2110466.0131983-.42209.0197974-.6331366.0197974-.7914247 0-1.3124383-.1781748-1.5630561-.5345295-.2506179-.3563549-.3759249-.9568697-.3759249-1.8015625v-11.5220808h3.1656833v-3.72190927h-3.1656833v-6.097596z"
                        />
                    </g>
                    <path
                        d="m49.4341071.35528639h5.4608037v8.94842009h.0791419c.5803781-.8446928 1.233294-1.47820296 1.9587665-1.90054939.7254731-.42234643 1.602622-.63351643 2.6314742-.63351643 2.2423703 0 3.8911473.87767549 4.9463802 2.6330528 1.055233 1.75537734 1.5828418 4.61277194 1.5828418 8.57226974 0 3.9594977-.5276088 6.7970952-1.5828418 8.5128775-1.0552329 1.7157824-2.7040099 2.5736607-4.9463802 2.5736607-1.1079945 0-2.0379044-.1979719-2.7897582-.5939217-.7518533-.3959498-1.4311495-1.0954505-2.0379086-2.0985233h-.079142v2.2569024h-5.2233775zm5.4608037 17.61967681c0 2.3229053.1780678 4.0716572.534209 5.2463082s1.1409588 1.7619677 2.3544769 1.7619677c1.1871374 0 1.9587648-.5873167 2.314906-1.7619677.3561413-1.174651.5342088-2.9234029.5342088-5.2463082s-.1780675-4.0716573-.5342088-5.2463083c-.3561412-1.174651-1.1277686-1.7619676-2.314906-1.7619676-1.2135181 0-1.9983357.5873166-2.3544769 1.7619676s-.534209 2.923403-.534209 5.2463083z"
                        fill="#f69d00"
                    />
                    <path
                        d="m68.2907429 31.3975934v4.0782623h2.2159785c1.2662797 0 2.3149017-.092387 3.1458973-.2771635.8309961-.1847766 1.5432681-.5741213 2.1368362-1.168046.5935687-.5939247 1.1079874-1.4386048 1.5432709-2.5340659.4352835-1.095461.8903462-2.5274579 1.3652006-4.2960335l5.1442357-19.87657927h-5.4608039l-2.7699725 14.72925787h-.0791423l-3.1656835-14.72925787h-5.6982297l5.9752269 21.30199127c-.0791423.8710895-.3363516 1.5705903-.7716351 2.0985233-.4352835.5279331-1.15415.7918956-2.1566215.7918956-.4748549 0-.9497027-.0395943-1.4245576-.1187843z"
                        fill="#f69d00"
                    />
                    <path
                        d="m85.5119533.35528639h5.4608038v8.94842009h.0791418c.580378-.8446928 1.233294-1.47820296 1.9587665-1.90054939s1.602622-.63351643 2.6314742-.63351643c2.2423703 0 3.8911472.87767549 4.9463804 2.6330528 1.055233 1.75537734 1.582842 4.61277194 1.582842 8.57226974 0 3.9594977-.527609 6.7970952-1.582842 8.5128775-1.0552332 1.7157824-2.7040101 2.5736607-4.9463804 2.5736607-1.1079945 0-2.037905-.1979719-2.7897583-.5939217s-1.4311494-1.0954505-2.0379088-2.0985233h-.0791417v2.2569024h-5.2233775zm5.4608038 17.61967681c0 2.3229053.1780676 4.0716572.5342088 5.2463082s1.1409588 1.7619677 2.354477 1.7619677c1.1871373 0 1.9587648-.5873167 2.314906-1.7619677s.5342088-2.9234029.5342088-5.2463082-.1780676-4.0716573-.5342088-5.2463083-1.1277687-1.7619676-2.314906-1.7619676c-1.2135182 0-1.9983358.5873166-2.354477 1.7619676s-.5342088 2.923403-.5342088 5.2463083z"
                        fill="#4a79e2"
                    />
                    <path
                        d="m114.709643 26.1314877v2.4944711h5.223378v-21.30199127h-5.460804v14.13533617c0 2.3493021-.962886 3.5239354-2.888686 3.5239354-.817806 0-1.411365-.2177691-1.780697-.6533138-.369331-.4355448-.553994-1.2076352-.553994-2.3162946v-14.68966317h-5.460804v15.79831697c0 2.1381287.468253 3.6625125 1.404772 4.573197s2.275326 1.3660199 4.016461 1.3660199c1.187137 0 2.242354-.2243682 3.165683-.6731112.923329-.4487431 1.675171-1.2010364 2.255549-2.2569025z"
                        fill="#4a79e2"
                    />
                    <path
                        d="m126.641347 21.6572776h-4.906809v.7523009c0 1.0294694.118712 1.9599374.356139 2.7914319.237428.8314945.65292 1.5375944 1.246488 2.1183207.593569.5807263 1.398172 1.0162646 2.413833 1.3066277 1.015662.2903632 2.288518.4355426 3.818606.4355426 1.160756 0 2.222569-.1187832 3.185469-.356353.9629-.2375699 1.793884-.6335137 2.492975-1.1878434.699092-.5543297 1.246486-1.2472314 1.642199-2.0787259.395712-.8314945.593565-1.814755.593565-2.9498111 0-1.6101957-.356136-2.883815-1.068418-3.8208962-.712282-.9370811-1.925782-1.6959734-3.640536-2.2766998l-3.284396-1.1086538c-.923329-.2903632-1.549864-.6467127-1.879625-1.0690591-.32976-.4223464-.494638-.897479-.494638-1.425412 0-.7655029.25721-1.3396214.771636-1.7223729s1.11458-.5741243 1.800482-.5741243c.896948 0 1.523484.2573635 1.879625.7720982s.534209 1.3264195.534209 2.4350789h4.906809v-.8710851c0-1.9269556-.59356-3.41834404-1.780697-4.47421009-1.187137-1.05586604-3.020577-1.58379115-5.500375-1.58379115-1.398184 0-2.591898.17157566-3.581179.51473209-.989281.34315648-1.800479.80509093-2.433619 1.3858173-.63314.58072632-1.094797 1.2604299-1.384987 2.03913115-.290189.7787012-.435281 1.6035842-.435281 2.4746737 0 1.5046091.382516 2.7386341 1.14756 3.7021119.765044.9634777 1.899403 1.7091719 3.40311 2.237105l3.838391 1.3858173c.527616.2111732.989274.4949329 1.384986.8512877s.593566.8776808.593566 1.5639938c0 .8446929-.250614 1.478203-.75185 1.9005494s-1.173937.6335165-2.018123.6335165c-.923329 0-1.629005-.2837598-2.117051-.8512878-.488045-.567528-.732064-1.3132222-.732064-2.237105z"
                        fill="#4a79e2"
                    />
                    <path
                        d="m143.191048.34283466c.608 0 1.19.106 1.746.318s1.046.512 1.47.9.762.858 1.014 1.41.378 1.172.378 1.86c0 .68-.126 1.296-.378 1.848s-.59 1.024-1.014 1.416-.914.692-1.47.9-1.138.312-1.746.312-1.19-.104-1.746-.312-1.046-.508-1.47-.9-.762-.864-1.014-1.416-.378-1.168-.378-1.848c0-.688.126-1.308.378-1.86s.59-1.022 1.014-1.41.914-.688 1.47-.9 1.138-.318 1.746-.318zm0 .96c-.472 0-.914.088-1.326.264s-.77.42-1.074.732-.544.684-.72 1.116-.264.904-.264 1.416.088.984.264 1.416.416.804.72 1.116.662.556 1.074.732.854.264 1.326.264c.464 0 .902-.088 1.314-.264s.77-.42 1.074-.732.546-.684.726-1.116.27-.904.27-1.416-.09-.984-.27-1.416-.422-.804-.726-1.116-.662-.556-1.074-.732-.85-.264-1.314-.264zm.144.816c.28 0 .542.04.786.12s.462.198.654.354.352.35.48.582.212.5.252.804h-.936c-.064-.28-.208-.5-.432-.66s-.492-.24-.804-.24c-.456 0-.804.158-1.044.474s-.36.726-.36 1.23c0 .232.034.454.102.666s.164.396.288.552.274.28.45.372.372.138.588.138c.336 0 .612-.084.828-.252s.344-.396.384-.684h.972c-.048.296-.138.562-.27.798s-.296.436-.492.6-.416.288-.66.372-.498.126-.762.126c-.392 0-.746-.068-1.062-.204s-.586-.324-.81-.564-.398-.522-.522-.846-.186-.674-.186-1.05c0-.384.058-.74.174-1.068s.284-.612.504-.852.488-.428.804-.564.674-.204 1.074-.204z"
                        fill="#7ed321"
                    />
                </g>
            )}
        />

    );
}

export default LogoGetByBus;
