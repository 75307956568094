import React from 'react';
import MyTicketsButton from "@User/components/MyTicketsButton";
import useIsCheckOrder from "@CheckOrder/hooks/useIsCheckOrder";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import Logo from "./Logo";
import useIsMobile from "@Hooks/selector/useIsMobile";
import Navbar from "@Header/components/TopBar/Navbar";

function TopBar() {
    const isCheckOrder = useIsCheckOrder()
    const {stripeClientSecret} = useAppSelector((state) => state?.checkOrder)
    const isMobile = useIsMobile()

    return (
        <header className="gb--header gb--row gb--row-middle gb--row-justify">
            <Logo/>
            {!stripeClientSecret && (
                <>
                    {isMobile && (
                        <MyTicketsButton/>
                    )}
                    {!isCheckOrder && (
                        <Navbar/>
                    )}
                    {!isMobile && (
                        <MyTicketsButton/>
                    )}
                </>
            )}
        </header>
    );
}

export default TopBar
