import {provideCompanyRating} from "@Route/features/company/functions/companyRatingProvider";
import {generateTransitPeriods} from "@Intermodal/functions/generateTransitPeriods";
import {calculateRouteTripDuration} from "@Intermodal/functions/calculateRouteTripDuration";
import sum from "lodash/sum";
import min from "lodash/min";
import useRoutePriceGenerator from "@RoutesList/hooks/useRoutePriceGenerator";
import IntermodalRouteType from "@Intermodal/types/IntermodalRoute.type";
import IntermodalRouteWithDetailsType from "@Intermodal/types/IntermodalRouteWithDetails.type";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";
import CompanyType from "@SearchResults/types/company/Company.type";
import PassengerGroupType from "@SearchResults/types/passenger/PassengerGroup.type";
import CurrencyType from "@Currency/types/Currency.type";
import RouteType from "@Route/types/Route.type";
import addRouteProperties from "@RoutesList/functions/addRouteProperties";

type PrepareIntermodalRouteProps = {
    companies: Record<string, CompanyType>,
    passengerGroups: Record<string, PassengerGroupType>,
    currency: CurrencyType,
}

export default function usePrepareIntermodalRoute({passengerGroups, currency, companies}: PrepareIntermodalRouteProps) {
    const {generatePrices} = useRoutePriceGenerator(passengerGroups, currency)

    const prepareIntermodalRoute = (route: IntermodalRouteType): IntermodalRouteWithDetailsType => {
        const {baseRoutes} = route
        const departure = baseRoutes[0]
        const destination = baseRoutes[baseRoutes.length - 1]

        const {
            travelInfo: {
                departureStation,
            },
        } = departure

        const {
            travelInfo: {
                destinationStation,
            },
        } = destination

        const baseRouteWithAddedProps = () => baseRoutes.map((route: RouteType) => (
            addRouteProperties({
                route,
                companies,
            })
        ))

        const pricedAndRatedRoutes = baseRouteWithAddedProps().map((baseRoute): RouteWithAddedPropsAndPricesType => (
            {
                ...generatePrices(baseRoute),
                ratings: {
                    ...baseRoute.ratings,
                    rating: provideCompanyRating(baseRoute.ratings),
                },
            }
        ))

        // TODO: optimize this with reduce at some point
        const statuses = baseRoutes.map((route) => route.status.status)
        // eslint-disable-next-line no-nested-ternary
        const status = statuses.includes("closed") ? "closed" : statuses.includes("unavailable") ? "unavailable" : "open"
        const soldOut = baseRoutes.map((route) => route.status.soldOut).includes(true)
        const transitPeriods = generateTransitPeriods(pricedAndRatedRoutes)
        const travelDuration = calculateRouteTripDuration(departureStation, destinationStation)
        const uniqueDepartureTime = `IEX-${departureStation.departureDate}-${departureStation.departureTime}-${destinationStation.arrivalDate}-${destinationStation.arrivalTime}-${departureStation.id}-${destinationStation.id}`
        const price = !route.isApiIntermodalRoute
            ? sum(pricedAndRatedRoutes.map((route) => (route.prices.price)))
            : pricedAndRatedRoutes[0].prices.price

        return {
            ...departure,
            ...route,
            uniqueDepartureTime,
            baseRoutes: pricedAndRatedRoutes,
            travelInfo: {
                ...departure.travelInfo,
                departureStationIndex: 0,
                destinationStationIndex: 1,
                departureStation,
                destinationStation,
                travelDuration,
                transitPeriods,
                busChangeCount: transitPeriods.length,
            },
            prices: {
                ...departure.prices,
                price,
            },
            status: {
                ...departure.status,
                status,
                soldOut,
                isIntermodal: true,
            },
            allotment: {
                ...departure.allotment,
                totalAllotmentLeft: min(pricedAndRatedRoutes.map((route) => (route.allotment.totalAllotmentLeft))),
            },
        }
    }

    return {
        prepareIntermodalRoute,
    }
}