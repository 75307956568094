import React from 'react';
import GenericSvgIcon from "../GenericSvgIcon";
import IconType from "@/types/Icon.type";

const IconWaitingPax = (props: IconType) => {
    const {
        width = '50',
        height = '50',
        fill = '#fff',
        viewBox = '0 0 50 50',
        title = 'IconWaitingPax',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none" transform="translate(1 2)">
                    <path
                        d="m21.0993533 20.1533587-1.7454787 5.829375-4.235433 6.365625c-.5982004.8990625-.2346954 2.0446875.8121552 2.5584375.3405812.166875.7139106.2465625 1.0806904.2465625.7575748 0 1.4944092-.3384375 1.897212-.945l4.3664258-6.5625c.0873285-.13125.1560997-.27.2041304-.414375l2.3513203-7.078125z"
                        fill="#fff"
                        stroke="#070c63"
                        strokeWidth="2"
                        transform="matrix(.190809 -.98162718 .98162718 .190809 -10.694132 42.333699)"
                    />
                    <path
                        d="m10.1845858 7.84307044c-5.39957511 0-10.1845858 4.68519146-10.1845858 10.45742726 0 2.7522798.48898854 5.3032428 1.75566496 7.2490528 1.26667643 1.9458109 3.39632292 3.2083745 6.21488049 3.2083745h1.77123232 5.46937143l1.7400976 8.7826049c.2548564 1.3792928 1.492056 2.4594701 2.9976035 2.4594701 1.9317281 0 3.3584371-1.7548243 2.9941434-3.5641038l-2.1465813-10.9799579c-.0010956-.0056849-.0022487-.0113589-.0034592-.0170212-.1720912-.7902234-.6212075-1.470777-1.2298304-1.9641715.3447633-.3519953.6809538-.7274378.7697245-1.2510107.2729513-1.6102277-1.0450705-3.0517849-2.6603077-3.0517849h-3.3677627l.7420489-5.1061657c.0058087-.0405983.0086988-.0815493.0086489-.122548 0-1.3071784.0474159-2.7269894-.5915641-3.98451155-.63898-1.25752131-2.089885-2.11565431-4.2793248-2.11565431z"
                        fill="#070c63"
                    />
                    <path
                        d="m17.7123232 0c-2.4350716 0-4.4280808 1.96113441-4.4280808 4.35726136s1.9930092 4.35726136 4.4280808 4.35726136 4.4280808-1.96113441 4.4280808-4.35726136-1.9930092-4.35726136-4.4280808-4.35726136z"
                        fill="#070c63"
                    />
                    <path
                        d="m17.7123232 1.74290454c1.4778268 0 2.6568485 1.16016537 2.6568485 2.61435682 0 1.45419144-1.1790217 2.61435681-2.6568485 2.61435681s-2.6568485-1.16016537-2.6568485-2.61435681c0-1.45419145 1.1790217-2.61435682 2.6568485-2.61435682z"
                        fill="#fff"
                    />
                    <path
                        d="m10.1845858 9.58597499c1.795833 0 2.3375644.44904541 2.6949026 1.15229171.3535539.695798.4002737 1.8799944.4012948 3.170929l-.873508 6.0116586c-.0360586.2500151.0398563.5031292.2080917.693816s.4122509.3001983.6688755.3001842h4.3882972c.5846333 0 1.0105287.4476014.9132916 1.0212331-.065277.3850059-.5034364.7216715-1.0118855.7216715h-.3044306-4.8708888-1.7297191c-.5846749 0-.9881413-.4519029-.920211-.97698.0006334-.0056677.00121026-.0113414.0017305-.0170203l.652104-5.8840048c.0403173-.3127187-.094115-.6224875-.351558-.8100857-.25744295-.1875981-.59776775-.2237838-.88999367-.0946303-.29222592.1291536-.49080227.4035145-.51930303.7174897l-.65037348 5.8669845c-.20129701 1.5559519 1.08189261 2.9411514 2.67760468 2.9411514h1.7297191 4.8708888c.9348175 0 1.6078512.5648701 1.7902589 1.4024935l2.1448517 10.9629376c.0005712.0017036.0011477.0034056.0017296.0051058.1632571.8032254-.3571292 1.485895-1.257505 1.485895-.6199313 0-1.1546566-.4901397-1.2540467-1.0280418-.0005559-.0034076-.0011325-.0068118-.0017296-.0102126l-1.8819343-9.4991696c-.0811992-.4095435-.4460022-.7049968-.8700488-.7046511h-6.19931313-1.77123232c-2.31713055 0-3.73026664-.8830469-4.72213283-2.4067063-.99186618-1.5236593-1.4771803-3.7660237-1.4771803-6.3078164 0-4.8159093 4.07115092-8.71452271 8.41335348-8.71452271z"
                        fill="#fff"
                    />
                    <g transform="matrix(-.70710678 -.70710678 -.70710678 .70710678 48.245022 37.278206)">
                        <path
                            d="m12.556216 19.1156451c-1.6139094 0-2.90503697-.7845842-2.90503697-3.0153145v-14.64624728c0-.75844831.41961647-1.33843819.96834567-1.33843819s.9683456.57998988.9683456 1.33843819v14.64624728c0 .7584483.4196165 1.3384381.9683457 1.3384381s1.1582717-.3918588 1.1582717-1.1503071.4196165-1.3384382.9683457-1.3384382.9683456.5799899.9683456 1.3384382c0 2.2307303-1.4810536 2.8271835-3.094963 2.8271835z"
                            fill="#070c63"
                            transform="matrix(.70710678 .70710678 .70710678 -.70710678 -3.093843 7.469198)"
                        />
                        <path
                            d="m7.44485486 5.52292388c.56078554 0 1.12083248.21424882 1.55108566.64386522.34074144.33784431.75319084.74368919 1.18197908 1.16505751.3275332.32173296.6112087.60027001.839668.82453721l-7.50591126 7.50591128c-.3401486-.3482533-.78610913-.8033631-1.24932442-1.2719786l-.46568697-.46966c-.65701417-.6575652-.92146341-1.2363728-.92080045-1.8330181.00062684-.5591697.21469256-1.1176259.64122922-1.5441625l4.37887203-4.37887239c.42805663-.42743081.98810357-.64167963 1.54888911-.64167963z"
                            fill="#f5a623"
                            stroke="#070c63"
                            strokeWidth="1.75173"
                        />
                        <circle cx="4.379327" cy="16.033305" fill="#070c63" r="1.75173"/>
                    </g>
                </g>
            )}
        />

    );
}

export default IconWaitingPax;
