/**
 * Params for ticket purchase confirm form event
 */
import CheckOrderTicketType from "@CheckOrder/types/CheckOrderTicket.type";

interface PaymentRedirectEventModelAttributes {
    ticket: CheckOrderTicketType,
    email: string,
    terms: {
        tripReviewAccepted: boolean,
        newsletterAccepted: boolean,
        privacyPolicyAccepted: boolean
    }
    hasBoughtPremiumSupport: boolean,
    hasBoughtSMSNotifications: boolean,
}

export default class PaymentRedirectEventModel {
    email: string;

    isVoucherCode: boolean;

    tripReview: boolean;

    newsletters: boolean;

    terms: boolean;

    bookingReference: string;

    hasBoughtPremiumSupport: boolean;

    hasBoughtSMSNotifications: boolean;

    constructor({ticket, email, terms}: PaymentRedirectEventModelAttributes) {
        // see PHP TicketModel
        //  contains voucher instance of VoucherModel
        const {
            ticketCode,
            voucher,
        } = ticket;

        const {
            tripReviewAccepted,
            newsletterAccepted,
            privacyPolicyAccepted,
        } = terms;

        const hasBoughtPremiumSupport = ticket?.ancillariesData?.premiumSupport?.isEnabled;
        const hasBoughtSMSNotifications = ticket?.ancillariesData?.smsNotification?.isEnabled;

        this.email = email;
        this.isVoucherCode = (voucher.ticketVoucher !== null && typeof voucher.ticketVoucher === 'object');
        this.tripReview = tripReviewAccepted;
        this.newsletters = newsletterAccepted;
        this.terms = privacyPolicyAccepted;
        this.bookingReference = ticketCode;
        this.hasBoughtPremiumSupport = hasBoughtPremiumSupport;
        this.hasBoughtSMSNotifications = hasBoughtSMSNotifications;
    }
}