import {useEffect} from "react";
import SearchEventModel from "../models/SearchEventModel";
import useSingleTrackingEvent from "./useSingleTrackingEvent";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import SearchEventOutboundModelType from "@Events/types/SearchEventOutboundModel.type";
import SearchEventReturnModelType from "@Events/types/SearchEventReturnModel.type";
import usePassengers from "@Hooks/selector/usePassengers";
import useCurrency from "@Currency/hooks/useCurrency";
import {SearchResponseType} from "@SearchResults/types/SearchResponse.type";
import useGbtBanner from "@SearchResults/features/banners/features/getByTransferBanner/hooks/useGbtBanner";

/**
 * Handles dispatching search results event
 */
export default function useSearchResultsEvent(searchQuery: SearchResponseType) {
    const {
        data: {
            result: {
                singleRoutes,
                returnRoutes,
                params,
                totalApiCount,
                resolvedApiCount,
                getByFerryData,
                transfersData,
            },
        },
    } = searchQuery

    const currency = useCurrency()
    const {awaitingUserResponse} = useAppSelector((state) => state.userProfile)
    const passengers = usePassengers()

    const {gbtShowBanner} = useGbtBanner({transfersData, getByFerryData})

    // @ts-ignore TODO remove-classes-and-use-functions-like-cool-guys
    let model: SearchEventOutboundModelType = new SearchEventModel({
        params,
        routes: singleRoutes,
        passengers,
        activeCurrencyRate: currency.ratio,
    })

    if (params.isReturn) {
        // @ts-ignore TODO remove-classes-and-use-functions-like-cool-guys
        const returnModel: SearchEventReturnModelType = new SearchEventModel({
            params,
            routes: singleRoutes,
            passengers,
            activeCurrencyRate: currency.ratio,
            returnRoutes,
        })
        model = {
            ...model,
            ...returnModel,
        }
    }

    const searchEvent = useSingleTrackingEvent("search_trips", {...model, banner_shown: gbtShowBanner})
    const sendTrackingEvent = () => {
        if (totalApiCount === resolvedApiCount && !awaitingUserResponse) {
            searchEvent()
        }
    }

    useEffect(sendTrackingEvent, [resolvedApiCount, awaitingUserResponse])
}