import React, {ReactNode} from 'react';

interface CardNumberedListProps {
    cardColorClass?: "blue" | "orange" | "green" | "default",
    cardHeading: string | ReactNode,
    cardDescription: string | ReactNode,
}

function CardNumberedList(props: CardNumberedListProps) {
    const {
        cardColorClass,
        cardHeading,
        cardDescription,
    } = props
    return (
        <div className={`card__numbered-list card__numbered-list--${cardColorClass}`}>
            <h3>{cardHeading}</h3>
            <span>{cardDescription}</span>
        </div>
    );
}

export default CardNumberedList;