import {useTranslation} from "@Translation/hooks/useTranslation";
import CountryType from "@/types/pageSlice/homepageData/Country.type";

export default function useCountryPageUrl(country: CountryType) {
    const {countryPageUrl, slug} = country
    const busStationsHref: string = useTranslation("country_page.country_bus_stations.bus_stations_route", {
        country: slug,
    })

    return countryPageUrl ?? busStationsHref
}