import RouteType from "@Route/types/Route.type";
import addRouteProperties from "@RoutesList/functions/addRouteProperties";
import CompanyType from "@SearchResults/types/company/Company.type";
import PassengerGroupType from "@SearchResults/types/passenger/PassengerGroup.type";
import useRoutePriceGenerator from "@RoutesList/hooks/useRoutePriceGenerator";
import {useAppSelector} from "@Hooks/selector/useAppSelector";

type PrepareRouteProps = {
    companies: Record<number, CompanyType>,
    isReturn: boolean,
    passengerGroups: Record<number, PassengerGroupType>
}

export default function usePrepareRouteForAdditionalInfo({companies, isReturn, passengerGroups}: PrepareRouteProps) {
    const {currency} = useAppSelector((state) => state.searchResults)
    const {generatePrices} = useRoutePriceGenerator(passengerGroups, currency)
    const prepareRoute = (route: RouteType) => {
        const preparedRoute = addRouteProperties({
            companies,
            route,
            isReturn,
        })

        return generatePrices(preparedRoute)
    }

    return {
        prepareRoute,
    }
}