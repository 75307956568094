import React, {ReactNode} from 'react';
import Column from "@Containers/components/Column";

interface CheckOrderInputContainerProps {
    label: string,
    children: ReactNode,
    className?: string,
    style?: React.CSSProperties,
    wrapperRef?: React.MutableRefObject<HTMLDivElement>
}

const CheckOrderInputContainer = ({label, children, className, style, wrapperRef}: CheckOrderInputContainerProps) => (
    <Column className={`gb--check-order-input-container ${className}`} style={style} scrollRef={wrapperRef}>
        <span className="gb--check-order-input-label">
            {label}
        </span>
        {children}
    </Column>
);

export default CheckOrderInputContainer;