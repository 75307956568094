import useCurrency from "@Currency/hooks/useCurrency";
import roundToTwoDecimals from "@Number/roundToTwoDecimals";
import useLocale from "@Hooks/selector/useLocale";
import {useAppSelector} from "@Hooks/selector/useAppSelector";

/**
 * Returns price in converted KN if active currency is EUR
 */
export default function useFixedEurConversion(price: number | string, convertFromString = false, roundPrice = false) {
    const {val} = useCurrency()
    const locale = useLocale()
    const {dualPriceHrkEurConversionRate, isDualPriceRequirementActive} = useAppSelector((state) => state?.page)
    const shouldDisplayPriceInKn = locale === 'hr' && val === 'EUR' && isDualPriceRequirementActive
    const isString = (price: number | string): price is string => (convertFromString)
    const getPriceInEur = () => (isString(price) ? parseFloat(price.split(" ")[0]) : price)
    const round = (price) => (roundPrice ? Math.round(price) : roundToTwoDecimals(price).toFixed(2))
    const priceInKn = val === 'EUR'
        ? `(${round(getPriceInEur() * parseFloat(dualPriceHrkEurConversionRate))} kn)`
        : `(${round(getPriceInEur() / parseFloat(dualPriceHrkEurConversionRate))} €)`

    return {
        shouldDisplayPriceInKn,
        dualPriceHrkEurConversionRate,
        priceInKn,
    }
}