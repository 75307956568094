import {useTranslation} from "@Translation/hooks/useTranslation";
import FilterButton from "@Generic/FilterButton";
import React from "react";
import usePopularFilters from "@SearchResults/features/sidebar/features/popularFilters/hooks/usePopularFilters";
import IconPercent from "@Icons/IconPercent";
import useDispatchFilterEvents from "@SearchResults/features/sidebar/hooks/useDispatchFilterEvent";

function WebDiscountFilter() {
    const label = useTranslation("search_results.filter_by.popular_filters.filters.web_discount")
    const {isActive, setActive} = usePopularFilters("webDiscount")

    const handleOnBlur = useDispatchFilterEvents('webDiscountFilter', isActive.toString());

    return (
        <FilterButton
            label={label}
            active={isActive}
            icon={<IconPercent width={"18"} height={"22"} fontSize={20} fill={"navy"}/>}
            onClick={setActive}
            onBlur={handleOnBlur}
        />
    );
}

export default WebDiscountFilter;