import React from "react";
import TicketRouteType from "@MyTicket/types/TicketRoute.type";
import TicketNotifications from "@MyTicket/features/ticketDetails/components/TicketNotifications";
import TicketIntermodalTravelDetails from "@MyTicket/features/ticketDetails/components/TicketIntermodalTravelDetails";

interface TicketIntermodalDataProps {
    isCancelled: boolean,
    isExchangedForVoucher: boolean,
    isExpired: boolean,
    isMTicket: boolean,
    ticketId: string,
    currentRoute: TicketRouteType,
    nextRoute: TicketRouteType,
    hasTransit: boolean,
}

function TicketIntermodalData(props: TicketIntermodalDataProps) {
    const {
        isCancelled,
        isExchangedForVoucher,
        isExpired,
        isMTicket,
        ticketId,
        currentRoute,
        nextRoute,
        hasTransit,
    } = props

    return (
        <>
            <TicketNotifications
                isCanceled={isCancelled}
                isExchangedForVoucher={isExchangedForVoucher}
                isExpired={isExpired}
                isMTicket={isMTicket}
            />
            <TicketIntermodalTravelDetails
                id={ticketId}
                currentRoute={currentRoute}
                hasTransit={hasTransit}
                nextRoute={hasTransit && nextRoute}
            />
        </>
    );
}

export default TicketIntermodalData;