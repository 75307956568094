import {useScroll} from "@Scroll/hooks/useScroll";
import {activateFormAnimation} from "@/reducers/mobileSlice";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import {useAppSelector} from "@Hooks/selector/useAppSelector";

export default function useScrollToFormAndAnimate() {
    const dispatch = useAppDispatch()
    const {formAnimationActive} = useAppSelector((state) => state?.mobile)
    const {scrollToTop} = useScroll({block: "center", behavior: "instant"})

    const animateForm = () => {
        if (!formAnimationActive) {
            dispatch(activateFormAnimation(true))
            setTimeout(() => dispatch(activateFormAnimation(false)), 2000)
        }
    }

    const scrollToFormAndAnimate = () => {
        scrollToTop()
        animateForm()
    }

    return {
        animateForm,
        formAnimationActive,
        scrollToFormAndAnimate,
    }
}