import useOnMount from "@Hooks/lifecycle/useOnMount";
import dismissPlaceholders from "@Features/domElements/functions/dismissPlaceholders";
import useToggleFooterAndCreditCardElement from "@Features/domElements/hooks/useToggleFooterAndCreditCardElement";
import useToggleMainContentElement from "@Features/domElements/hooks/useToggleMainContentElement";
import useSetBodyClassName from "@Features/domElements/hooks/useSetBodyClassName";

/**
 * This hook is currently required due to css/element interaction outside react-scope
 * This is far from ideal and should be updated at some point.
 */
export default function useToggleDOMElements() {
    // hide/show/classname change side effects
    useOnMount(dismissPlaceholders)

    // hide/show element hooks
    useToggleFooterAndCreditCardElement()
    useToggleMainContentElement()

    // className changes
    useSetBodyClassName()
}