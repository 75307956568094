import React from 'react';
import assetUrlGenerator from "@Images/functions/assetUrlGenerator";
import {useAppSelector} from "@Hooks/selector/useAppSelector";

interface CountryFlagProps {
    isoCode: string,
}

function CountryFlag({isoCode}: CountryFlagProps) {
    const {assetsBaseUrl} = useAppSelector((state) => state?.page)
    return (
        <img
            alt={isoCode}
            src={assetUrlGenerator(assetsBaseUrl, `country_flags/${isoCode.toLowerCase()}.png`)}
        />
    );
}

export default CountryFlag;