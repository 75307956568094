import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useComponentDidMount} from "@Hooks/lifecycle/useComponentDidMount";
import {clearUserData} from "@MyProfile/reducers/userProfileSlice";
import useEventListener from "@Hooks/eventListeners/useEventListener";
import useGetCurrentUser from "./useGetCurrentUser";

export default function useLoginListener() {
    const {user} = useSelector((state) => state?.userProfile)
    const dispatch = useDispatch()
    const isMount = useComponentDidMount()
    const getCurrentUser = useGetCurrentUser()
    const getStatus = () => localStorage.getItem('userLogInStatusChanged') ?? null

    const localStorageListener = () => {
        const newStatus = getStatus()
        if (user?.id !== getStatus) {
            getCurrentUser()
        }

        if (newStatus === 'null') {
            dispatch(clearUserData())
        }
    }

    useEventListener('storage', localStorageListener)
    useEffect(() => {
        if (isMount) {
            getCurrentUser()
        }
    })
}