import React from 'react';
import {isLastElement} from "@Array/isLastElement";
import ConnectingRouteNotification from "@RouteSuggestions/components/ConnectingRouteNotification";
import IntermodalRouteWithDetailsType from "@Intermodal/types/IntermodalRouteWithDetails.type";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";

interface IntermodalRouteNotificationProps {
    index: number,
    baseRoute: RouteWithAddedPropsAndPricesType,
    route: IntermodalRouteWithDetailsType,
}

function IntermodalRouteNotification({route, baseRoute, index}: IntermodalRouteNotificationProps) {
    if (!isLastElement(route.baseRoutes, index)) {
        return (
            <ConnectingRouteNotification
                isIntermodal={route.isIntermodalRoute}
                departureTransportType={route.baseRoutes[index].type.routeTransferType}
                destinationTransportType={route.baseRoutes[index + 1].type.routeTransferType}
                activeHub={baseRoute.travelInfo.destinationStation?.apiStationName ?? baseRoute.travelInfo.destinationStation?.cityName}
                waitingTimeDuration={route.travelInfo.transitPeriods[index]}
            />
        )
    }

    return null
}

export default IntermodalRouteNotification;