import DateInputFieldType
    from "@CheckOrder/features/TicketAdditionalInfo/features/dateAdditionalField/types/DateInputField.type";
import getFieldLengthPerDateInputType
    from "@CheckOrder/features/TicketAdditionalInfo/features/dateAdditionalField/functions/getFieldLengthPerDateInputType";

export default function getFocusOnInputFieldHandler(value: string, inputFields: DateInputFieldType[]) {
    const focusOnFieldHandler = () => {
        // if whole field is empty, send user to the first input field
        if (!value || value === "" || value === "--") {
            inputFields[0].inputRef?.current?.focus()
            return
        }

        // find first field which is empty (if any)
        const firstEmptyField = inputFields.find((inputField) => (
            !inputField.value || inputField.value === "" || inputField.value.length < getFieldLengthPerDateInputType(inputField.type)
        ))
        if (firstEmptyField) {
            firstEmptyField?.inputRef?.current?.focus()
            return
        }

        // if there is value in all fields, send user to the last one
        inputFields[inputFields.length - 1].inputRef?.current?.focus()
    }

    return {
        focusOnFieldHandler,
    }
}