import React, {useState} from 'react';
import useDebouncedScroll from "@Hooks/eventListeners/useDebouncedScroll";
import {scrollToRef} from "@Scroll/functions/scrollToRef";
import {capitalize} from "@String/capitalize";

interface MobileActiveTicketSectionButtonProps {
    section: any,
    initiallyActive?: boolean,
    id: string,
}

function MobileActiveTicketSectionButton({section, initiallyActive, id}: MobileActiveTicketSectionButtonProps) {
    const [active, setActive] = useState(initiallyActive)
    const {
        refs,
        title,
    } = section

    const onClick = () => (
        section.isIntermodal
            ? scrollToRef({ref: section.refs.tooltipRef})
            : refs?.scrollWithOffset
    )

    const handleScroll = () => {
        setActive(section.isIntermodal ? section.refs.visible : refs?.isElementVisible())
    }

    useDebouncedScroll(handleScroll, 10)

    const className = active ? 'gb--tag gb--tag-green' : 'gb--emphasize-default'

    return (
        <button
            className={className}
            type="button"
            onClick={onClick}
        >
            {capitalize(title)}
        </button>
    );
}

export default MobileActiveTicketSectionButton;