import getRouteTransferType from "@Route/features/transportType/functions/getRouteTransferType";
import BusType from "@Route/types/type/Bus.type";
import FerryType from "@Route/types/type/Ferry.type";
import {RouteTransferTypeKeys} from "@Route/features/transportType/types/routeTransferType.type";

/**
 * Checks if provided route is a ferry route
 */
export const isFerryRoute = (vehicle: BusType | FerryType, routeTransferType: RouteTransferTypeKeys): vehicle is FerryType => (
    getRouteTransferType(routeTransferType) === 'ferry'
)