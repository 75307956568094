import React from 'react';
import Translation from "@Translation/components/Translation";
import Badge from "@Features/badge/components/Badge";
import FerryType from "@Route/types/type/Ferry.type";
import {routeVesselTypeResolver} from "@Route/features/transportType/functions/routeVesselTypeResolver";

interface FerryTypeProps {
    ferryVehicle: FerryType,
}

function FerryTypeBadge({ferryVehicle}: FerryTypeProps) {
    const translationKey = routeVesselTypeResolver(ferryVehicle)

    if (translationKey) {
        return (
            <Badge info left className="gb--route-ferry_type">
                <Translation translationKey={translationKey}/>
            </Badge>
        )
    }

    return null
}

export default FerryTypeBadge;