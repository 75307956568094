import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";
import IntermodalRouteWithDetailsType from "@Intermodal/types/IntermodalRouteWithDetails.type";

/**
 * Prevent displaying routes with different destination / return station id - GWN-478
 */

interface DisplayOnlyIdenticalReturnStationsAttributes {
    routes: (RouteWithAddedPropsAndPricesType | IntermodalRouteWithDetailsType)[],
    singleSelectedRoute: RouteWithAddedPropsAndPricesType | IntermodalRouteWithDetailsType,
}

export default function displayOnlyIdenticalReturnStations(props: DisplayOnlyIdenticalReturnStationsAttributes):
    (RouteWithAddedPropsAndPricesType | IntermodalRouteWithDetailsType)[] {
    const {
        routes,
        singleSelectedRoute,
    } = props

    const {
        travelInfo: {
            destinationStation: {
                id: departureId,
            },
            departureStation: {
                id: destinationId,
            },
        },
    } = singleSelectedRoute

    return routes.filter((route) => (
        route.travelInfo.departureStation.id === departureId
        && route.travelInfo.destinationStation.id === destinationId
    ))
}