import React, {Component} from 'react';
import PropTypes from "prop-types";

/**
 * Component that shows loader.
 */
class Loader extends Component {
    render() {
        return (
            <div className={`${this.props.isInline ? "redirect-view my-profile-loader inline" : "redirect-view my-profile-loader"}`}>
                <div className="row redirect-loader">
                    <ul className="loading">
                        <li/>
                        <li/>
                        <li/>
                    </ul>
                </div>
            </div>
        );
    }
}

Loader.propTypes = {
    /** Is this inline loader */
    isInline: PropTypes.bool,
};

export default Loader;
