import React from 'react';
import IconGoogle from "@Icons/IconGoogle";
import Translation from "@Translation/components/Translation";
import useHandleThirdPartyLogin from "@CheckOrder/hooks/useHandleThirdPartyLogin";

function GoogleLogin() {
    const {handleThirdPartyLogin} = useHandleThirdPartyLogin('google')

    return (
        <button
            onClick={handleThirdPartyLogin}
            className="btn btn-default btn--superG"
            type="button"
        >
            <IconGoogle width="24" style={{verticalAlign: 'middle'}}/>
            <Translation translationKey="user.sign_in_with"/>
            {' Google'}
        </button>
    );
}

export default GoogleLogin;