import Row from "@Containers/components/Row";
import React from "react";
import generateEnglishAlphabet from "@Pages/countryPage/functions/generateEnglishAlphabet";

type SeatHeaderProps = {
    corridorIndex: number
    seatColumnLength: number
}

function SeatHeader({corridorIndex, seatColumnLength}: SeatHeaderProps) {
    const alphabet = generateEnglishAlphabet();
    const getLettersForSeatRowCount = () => {
        return [
            ...alphabet.slice(0, corridorIndex),
            ...alphabet.slice(corridorIndex - 1, seatColumnLength - 1),
        ]
    }

    return (
        <Row justify>
            {getLettersForSeatRowCount().map((letter, index) => (
                <span key={index} className="gb--seat-picker-seat-label">
                    {index !== corridorIndex ? letter.letter : ''}
                </span>
            ))}
        </Row>
    );
}

export default SeatHeader;