import useGetValidTickets from "@MyTickets/hooks/useGetValidTickets";
import useGetArchivedTickets from "@MyTickets/hooks/useGetArchivedTickets";
import useCountValidTickets from "@MyTickets/hooks/useCountValidTickets";
import useCountArchivedTickets from "@MyTickets/hooks/useCountArchivedTickets";
import NoResultsNotification from "@MyProfile/components/NoResultsNotification";

function MyTicketsNoResultsNotification() {
    const {
        loading: validTicketsLoading,
    } = useGetValidTickets()

    const {
        loading: archivedTicketsLoading,
    } = useGetArchivedTickets()

    const {
        validTicketsCount,
        validTicketsCountLoading,
    } = useCountValidTickets()

    const {
        archivedTicketsCount,
        archivedTicketsCountLoading,
    } = useCountArchivedTickets()

    const noValidTickets = !validTicketsLoading && !validTicketsCountLoading && !validTicketsCount
    const noArchivedTickets = !archivedTicketsLoading && !archivedTicketsCountLoading && !archivedTicketsCount

    if (noValidTickets && noArchivedTickets) {
        return (
            <NoResultsNotification
                noResultsKey="my_profile.no_tickets"
            />
        )
    }

    if (noValidTickets) {
        return (
            <NoResultsNotification
                noResultsKey="my_profile.no_upcoming_tickets"
            />
        )
    }

    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <></>
    )
}

export default MyTicketsNoResultsNotification