/**
 * Station object
 * @param stationObject {object}
 * @returns {{location: *, locationId: number}}
 * @constructor
 */
export default function StationEventModel(stationObject) {
    return {
        location: stationObject?.label,
        locationId: parseInt(stationObject?.id, 10),
    }
}