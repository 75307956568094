import React from 'react';
import CompanyRating from "@Route/features/company/components/CompanyRating";
import RouteRatingInformation from "@Route/features/company/components/RouteRatingInformation";
import OptionalPopover from "@Popover/components/OptionalPopover";
import useRouteRatings from "@Route/hooks/useRouteRatings";
import CompanyType from "@SearchResults/types/company/Company.type";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";

interface CompanyPopoverProps {
    route: RouteWithAddedPropsAndPricesType,
    company: CompanyType,
}

function CompanyPopover({route, company}: CompanyPopoverProps) {
    const {displayPopover} = useRouteRatings(route)
    const displayTrigger = () => (
        <CompanyRating
            company={company}
            rating={route.ratings.rating}
            highlight={false}
            displayCompanyLogo
            customMainCompanyName={route.customMainCompanyName}
        />
    )

    const displayOverlay = () => (
        <RouteRatingInformation
            route={route}
        />
    )

    return (
        <OptionalPopover
            condition={displayPopover}
            trigger={displayTrigger()}
            overlay={displayOverlay()}
            id="route-ratings-popover"
        />
    );
}

export default CompanyPopover;