import toFloat from "@Number/toFloat";
import ParamsType from "@SearchResults/types/params/Params.type";
import SearchEventOutboundModelType from "@Events/types/SearchEventOutboundModel.type";
import SearchEventReturnModelType from "@Events/types/SearchEventReturnModel.type";
import {RoutesOrganizedByTransportTypeType} from "@SearchResults/types/RoutesOrganizedByTransportType.type";

/**
 * Params for bookaway search event
 * @constructor
 */

type AccType = {
    totalRoutes: number,
    minPrice: string,
    soldOut: number,
    hasMTicket: number,
    notAvailableCount: number,
}

interface SearchEventModelAttributes {
    params: ParamsType,
    routes: RoutesOrganizedByTransportTypeType,
    passengers: any,
    activeCurrencyRate: any,
    returnRoutes?: RoutesOrganizedByTransportTypeType,
}

export default function SearchEventModel(props: SearchEventModelAttributes):
    SearchEventOutboundModelType | SearchEventReturnModelType {
    const {
        params,
        routes,
        passengers,
        activeCurrencyRate,
        returnRoutes = undefined,
    } = props

    const {
        departureStation: {
            cityName: from,
            id: fromId,
            countryCode: fromCountry,
            slug: fromCitySlug,
        },
        destinationStation: {
            cityName: to,
            id: toId,
            countryCode: toCountry,
            slug: toCitySlug,
        },
        date,
        returnDate,
        isReturn,
    } = params

    const {
        passengers: totalPassengers,
    } = passengers

    const getRouteCounts = (routes: RoutesOrganizedByTransportTypeType): AccType => (
        Object.values(routes).flat(20).reduce((acc: AccType, route) => {
            const {
                status,
            } = route
            acc.totalRoutes += 1

            if (route.prices.priceWithDiscount?.["1"]?.["1"]) {
                if (!acc.minPrice) {
                    acc.minPrice = toFloat(route.prices.priceWithDiscount?.["1"]?.["1"])
                }

                if (route.prices.priceWithDiscount?.["1"]?.["1"] < parseFloat(acc.minPrice)) {
                    acc.minPrice = toFloat(route.prices.priceWithDiscount?.["1"]?.["1"])
                }
            }

            if (status.soldOut) {
                acc.soldOut += 1
            }

            if (status.hasMTicket) {
                acc.hasMTicket += 1
            }

            if (status.status === "unavailable") {
                acc.notAvailableCount += 1
            }

            return acc
        }, {
            minPrice: null,
            soldOut: 0,
            notAvailableCount: 0,
            hasMTicket: 0,
            totalRoutes: 0,
        }))

    const {
        minPrice,
        soldOut,
        notAvailableCount,
        hasMTicket,
        totalRoutes,
    } = getRouteCounts(returnRoutes || routes)

    if (returnRoutes) {
        return {
            retFrom: to,
            retTo: from,
            retFromId: toId,
            retToId: fromId,
            retToCountry: fromCountry,
            retFromCountry: toCountry,
            retRoute: `${to}-${from}`,
            retDepartureDate: returnDate,
            retPassengers: totalPassengers,
            retMinPriceOfResults: minPrice,
            retNumberOfResults: totalRoutes,
            retNumOfSoldoutResults: soldOut,
            retNumNotAvailableOnlineResults: notAvailableCount,
            retNumMticketsResults: hasMTicket,
            retRouteSlug: `${toCitySlug}-${fromCitySlug}`,
            retFromCitySlug: toCitySlug,
            retToCitySlug: fromCitySlug,
        }
    }

    return {
        from,
        to,
        fromId,
        toId,
        toCountry,
        fromCountry,
        route: `${from}-${to}`,
        departureDate: date,
        passengers: totalPassengers,
        minPriceOfResults: minPrice,
        numberOfResults: totalRoutes,
        numOfSoldoutResults: soldOut,
        numNotAvailableOnlineResults: notAvailableCount,
        numMticketsResults: hasMTicket,
        routeSlug: `${fromCitySlug}-${toCitySlug}`,
        fromCitySlug,
        toCitySlug,
    }
}