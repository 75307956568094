import React from "react";
import {useTranslation} from "@Translation/hooks/useTranslation";

function UnsuccessfulRebook() {
    const {
        ticket_can_not_rebook: ticketCanNotRebookLabel,
        contact_customer_support: contactCustomerSupportLabel,
    } = useTranslation("my_profile.my_tickets.booking_modal")

    return (
        <div className="manage-booking-modal-body unsuccessful-rebook-notice">
            <h3>{ticketCanNotRebookLabel}</h3>
            <p>{contactCustomerSupportLabel}</p>
        </div>
    );
}

export default UnsuccessfulRebook