import React from 'react';
import ColorGradedNode from "@Features/colorGradedNode/components/ColorGradedNode";
import IconStarFull from "@Icons/IconStarFull";

interface ReviewScoreLabelWithStarProps {
    score: string | number,
}

const ReviewScoreLabelWithStar = ({score}: ReviewScoreLabelWithStarProps) => (
    <ColorGradedNode rating={score}  className={"gb--label gb--review-ratings gb--review-ratings-circle review-rating"}>
        <IconStarFull
            height="12"
            style={{margin: '0 .2rem'}}
        />
        {score}
    </ColorGradedNode>
);

export default ReviewScoreLabelWithStar;