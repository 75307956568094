import Translation from "@Translation/components/Translation";
import formatDate from "@DateTime/functions/formatDate";
import TimetableOperationalIntervalType from "@Route/types/travelInfo/timetable/TimetableOperationalInterval.type";

interface OperationalIntervalsProps {
    operationalIntervals: TimetableOperationalIntervalType[],
}

const OperationalIntervals = ({operationalIntervals}: OperationalIntervalsProps) => (
    <div>
        <hr/>
        <span className="gb--emphasize gb--emphasize-info">
            <Translation translationKey="sys.operational_intervals"/>
        </span>
        <br/>
        {operationalIntervals.map((interval) => (
            <div key={interval.Start}>
                {formatDate(interval.Start, 'dd.MM')}
                {' '}
                -
                {formatDate(interval.End, 'dd.MM')}
            </div>
        ))}
    </div>
)

export default OperationalIntervals