import React from 'react';
import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconLocationArrow(props: IconType) {
    const {
        width = '20',
        height = '20',
        fill = '#9b9b9b',
        viewBox = '0 0 20 20',
        title = 'IconLocationArrow',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <path
                    d="m9.99718994 20.0002302c.34091306 0 .65341666-.1846613.80966846-.4971649l9.0910137-18.18202737c.1846612-.35511772.1136376-.78125899-.1704565-1.05114846-.1704566-.18466122-.3977319-.26988947-.6392119-.26988947-.1420471 0-.2840942.02840942-.4119366.09943296l-18.18202742 9.09101371c-.36932243.18466122-.56818836.61080248-.4687554 1.02273903.09943297.4119366.45455069.6960307.88069196.6960307h8.18191233v8.1819124c0 .4261413.28409418.781259.69603074.8806919.07102354.0142047.14204709.0284095.21307063.0284095z"
                />
            )}
        />

    );
}

export default IconLocationArrow;
