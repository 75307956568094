import React from 'react';
import ErrorLabel from "@Errors/components/ErrorLabel";
import {useAppSelector} from "@Hooks/selector/useAppSelector";

interface CheckOrderErrorLabelProps {
    error?: string,
    isWarning?: boolean,
    isAbsolute?: boolean,
}

function CheckOrderErrorLabel({error, isWarning = false, isAbsolute}: CheckOrderErrorLabelProps) {
    const {submitTimestamp} = useAppSelector((state) => state?.checkOrder)

    return (
        <ErrorLabel
            isAbsolute={isAbsolute}
            error={error}
            isWarning={isWarning}
            condition={submitTimestamp}
        />
    );
}

export default CheckOrderErrorLabel;