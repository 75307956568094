import React from 'react';
import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconArrowRight(props: IconType) {
    const {
        width = '22',
        height = '22',
        fill = '#070c63',
        viewBox = '0 0 22 22',
        title = 'IconArrowRight',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...{width, height, fill, viewBox, title, ...rest}}
            svgIconPath={(
                <path
                    d="m364.845351 294.112472v8.196826l11.154649-11.154649-11.154649-11.154649v8.196826h-10.845351v5.915646z"
                    fillRule="evenodd"
                    transform="translate(-354 -280)"
                />
            )}
        />

    );
}

export default IconArrowRight;
