import React from 'react';
import useQuantityAwareTranslation from "@Translation/hooks/useQuantityAwareTranslation";
import useTransportTypeAwareTranslation from "@Translation/hooks/useTransportTypeAwareTranslation";
import {useTranslation} from "@Translation/hooks/useTranslation";

interface BusChangeProps {
    isIntermodal?: boolean,
    busChanges: number,
}

function BusChange({busChanges, isIntermodal}: BusChangeProps) {
    const quantityAwareTranslation = useQuantityAwareTranslation("sys.bus_change", busChanges)
    const busChangeTranslation = useTransportTypeAwareTranslation("sys.bus_change", quantityAwareTranslation)
    const transit = useTranslation('search_results.transit')

    return (
        <span className="gb--row gb-row-middle gb--connect__bus-change">
            <span className="gb--route-change">
                {busChanges}
            </span>
            <span className="gb--truncate">
                {isIntermodal ? transit : busChangeTranslation}
            </span>
        </span>
    );
}

export default BusChange;