import React from 'react';
import Translation from "@Translation/components/Translation";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import Row from "@Containers/components/Row";
import PhoneNumber from "@CheckOrder/components/MainSection/BuyerInfo/PhoneNumber";
import EmailInputField from "@CheckOrder/components/MainSection/BuyerInfo/EmailInputField";
import LoginFields from "@CheckOrder/components/MainSection/BuyerInfo/LoginFields";
import UserCTAButton from "@CheckOrder/components/MainSection/BuyerInfo/UserCTAButton";

function BuyerInfoSection() {
    const {user} = useAppSelector((state) => (state?.userProfile))

    return (
        <div className="gb--card gb--check-order-buyer-info">
            <Row center justify className="gb--card-title">
                <span>
                    <Translation translationKey="check_order.buyer_info_section.title"/>
                </span>
                <UserCTAButton/>
            </Row>
            <form method="post">
                <Row justify wrap>
                    <div className="check-order__login">
                        <EmailInputField/>
                        {user?.userLoginMethods?.status && !user?.email && (
                            <LoginFields userLoginMethods={user.userLoginMethods}/>
                        )}
                    </div>
                    <PhoneNumber/>
                </Row>
            </form>
        </div>
    );
}

export default BuyerInfoSection
