import React from 'react';
import {NavLink} from 'react-router-dom';
import IconTag from "@Icons/IconTag";
import IconClockFull from "@Icons/IconClockFull";
import TabVehicleIcon from "@Tabs/components/TabVehicleIcon";
import Translation from "@Translation/components/Translation";
import useIsMobile from "@Hooks/selector/useIsMobile";
import useCurrency from "@Currency/hooks/useCurrency";
import PriceWithCurrency from "@Route/components/Price/PriceWithCurrency";
import useTabLabelConfigurator from "@Tabs/hooks/useTabLabelConfigurator";
import TabType from "@Tabs/types/Tab.type";
import useGenerateSearchUrl from "@SearchResults/hooks/useGenerateSearchUrl";
import useResetTabSpecificFilters from "@SearchResults/features/sidebar/hooks/useResetTabSpecificFilters";

interface TabProps {
    activeTab: TabType,
    tab: TabType,
}

function Tab({tab, activeTab}: TabProps) {
    const active = tab.tabType === activeTab.tabType;
    const isMobile = useIsMobile()
    const {ratio} = useCurrency()
    const tabTranslation = useTabLabelConfigurator(tab.tabType)
    const {generateSearchUrl} = useGenerateSearchUrl()
    const {resetTabSpecificFilters} = useResetTabSpecificFilters()
    const resetFilter = () => {
        if (tab !== activeTab) {
            resetTabSpecificFilters()
        }
    }
    return (
        <li
            className={`nav-item gb--row ${active ? 'active' : 'inactive'}`}
        >
            <NavLink
                onClick={resetFilter}
                replace
                to={generateSearchUrl({tab: tab.tabType})}
                className="nav-link gb--row-middle"
            >
                <TabVehicleIcon
                    tag={tab.tabType}
                    fill="#2A2E79"
                    width={isMobile ? '2.5rem' : '4rem'}
                    height={isMobile ? '2.5rem' : '3.5rem'}
                />
                <span className="gb--tabs-type gb--column">
                    <span>
                        <Translation translationKey={tabTranslation}/>
                    </span>
                    <span className="route-count">
                        x
                        {tab.routes}
                    </span>
                </span>
                {!isMobile && (
                    <span className="gb--tabs-details gb--column">
                        {tab.minPrice > 0 && tab.minPrice < Infinity && (
                            <span className="gb--tabs-deal">
                                <IconTag width="20" height="10"/>
                                <Translation translationKey="search_results.from"/>
                                <PriceWithCurrency
                                    linebreak={false}
                                    price={Math.round(tab.minPrice * ratio)}
                                />
                            </span>
                        )}
                        {tab.fastestRoute !== '0' && (
                            <span className="gb--tabs-deal">
                                <IconClockFull width="20" height="10"/>
                                <Translation translationKey="search_results.from"/>
                                <span>
                                    {tab.fastestRoute}
                                </span>
                            </span>
                        )}
                    </span>
                )}
            </NavLink>
        </li>
    );
}

export default Tab