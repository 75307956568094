import React from 'react';
import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconSafety(props: IconType) {
    const {
        width = '50',
        height = '50',
        viewBox = '0 0 85 85',
        title = 'IconSafety',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none" transform="translate(3)">
                    <path
                        d="m31.1505682 76.8322332c-.3622159 0-.7244318-.0724832-1.0866477-.289933-18.8352273-11.1624187-30.0639205-30.8778597-30.0639205-52.6953146v-10.9449691c0-.9422821.57954546-1.7395977 1.44886364-2.0295307l28.97727276-10.72751932c.5071022-.14496648 1.0142045-.14496648 1.5213068 0l28.9048295 10.72751932c.8693182.289933 1.4488637 1.1597319 1.4488637 2.0295307v10.9449691c0 5.6536926-.796875 11.3073853-2.3181819 16.7436282-.2897727 1.1597318-1.5213068 1.8120809-2.6803977 1.522148-1.1590909-.289933-1.8110795-1.522148-1.5213068-2.6818799 1.4488636-5.0013434 2.1008523-10.2201366 2.1008523-15.5114131v-9.422821l-26.7315341-10.00268699-26.80397729 9.93020369v9.4228211c0 20.2953069 10.43181819 38.5610831 27.89062499 48.9261862 1.0142046.5798659 1.3764205 1.9570475.796875 2.9718128-.4346591.7248324-1.1590909 1.0872486-1.8835227 1.0872486z"
                        fill="#444b54"
                    />
                    <path
                        d="m31.1505682 76.8322332c-.7244318 0-1.4488637-.3624162-1.8835227-1.0872486-.5795455-1.0147653-.2897728-2.3919469.796875-2.9718128 2.3181818-1.3771815 4.5639204-2.8993295 6.6647727-4.566444.9417613-.7248324 2.3181818-.5798659 3.0426136.3624162s.5795455 2.3194636-.3622159 3.044296c-2.2457386 1.812081-4.6363636 3.4791955-7.171875 4.9288602-.3622159.2174498-.7244318.289933-1.0866477.289933z"
                        fill="#444b54"
                    />
                    <path
                        d="m31.1505682 66.3946468c-.4346591 0-.9417614-.1449665-1.3039773-.4348994-11.5184659-8.480539-18.8352273-21.0201393-20.71874999-35.1543709-.14488636-1.1597318.65198864-2.2469804 1.88352269-2.4644301 1.1590909-.1449665 2.2457387.6523491 2.4630682 1.8845642 1.6661932 12.3946339 7.8963068 23.4120862 17.6761364 31.1677927 11.5184659-9.1328881 18.1107954-22.759737 18.1107954-37.6188009v-3.334229l-18.1107954-6.668458-19.5596591 7.2483239c-1.1590909.4348994-2.39062501-.1449665-2.8252841-1.3046983s.14488636-2.3919469 1.3039773-2.8268463l20.3565341-7.46577365c.5071022-.14496647 1.0142045-.14496647 1.5213068 0l20.2840909 7.53825685c.8693182.289933 1.4488636 1.1597318 1.4488636 2.0295307v4.856377c0 16.8161114-7.6789772 32.182558-21.1534091 42.1127618-.4346591.2899329-.9417613.4348994-1.3764204.4348994z"
                        fill="#4a79e2"
                        fillOpacity=".5"
                    />
                    <path
                        d="m31.1505682 11.4523517v52.7677979c12.8224432-9.4953043 20.2840909-24.2094017 20.2840909-40.373164v-4.856377z"
                        fill="#4a79e2"
                        fillOpacity=".5"
                    />
                    <g transform="translate(36.704545 41.557057)">
                        <circle
                            cx="21.249861"
                            cy="21.261611"
                            fill="#7ed321"
                            r="14.492504"
                            transform="matrix(.70710678 -.70710678 .70710678 .70710678 -8.810289 21.253302)"
                        />
                        <path
                            d="m24.9128881 14.8762085c.8947468-1.2785371 2.3504106-1.7157712 3.3592243-1.0090018 1.0088136.7067696 1.0960413 2.2249454.2012947 3.5034826l-7.2975161 10.4300546c-.1665684.4106067-.478053.8017275-.9153239 1.1080773-1.0088138.7067694-2.2123972.6297424-2.7775004-.1777548l-4.6537906-6.6499768c-.5651031-.8074972-.2257951-1.9654659.7830185-2.6722355 1.0088138-.7067694 2.2123972-.6297424 2.7775004.1777548l2.6130232 3.7345957z"
                            fill="#fff"
                        />
                    </g>
                </g>
            )}
        />

    );
}

export default IconSafety;
