import ajaxHandler from "../../../utils/ajax/v2/ajaxHandler";
import {useAppSelector} from "@Hooks/selector/useAppSelector";

/**
 * Dispatches ajax post for events
 * @returns {{dispatchEvent: (function(*=): Promise|null|*)}}
 */
export default function useDispatchTrackingEvent() {
    const {
        eventToken,
        bawApiUrl,
    } = useAppSelector((state) => state?.page)

    const dispatchEvent = (event) => ajaxHandler({
        url: bawApiUrl,
        handle: "ignore",
        type: "post",
        params: event,
        withCredentials: true,
        headers: {
            "csrf-token": eventToken,
            "Content-Type": "application/json; charset=UTF-8",
        },
    })

    return {
        dispatchEvent,
    }
}