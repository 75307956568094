import {NavLink} from "react-router-dom";
import Column from "@Containers/components/Column";
import React from "react";

interface MyProfileLinkProps {
    url: string,
    isActive: boolean,
    icon: React.ReactElement,
    label: string,
    description: string,
}

function MyProfileLink({url, isActive, icon, label, description}: MyProfileLinkProps) {
    return (
        // TODO: this is stupid, we should refactor css instead, as NavLink already is aware if it's active
        <li className={`nav-item my-profile-nav-item gb--row ${isActive ? "active" : ''}`}>
            <NavLink
                to={url}
                className={({isActive}) => (isActive ? 'nav-link my-profile-nav-item active' : 'nav-link')}
            >
                {icon}
                <Column alignStart className="nav-link-description">
                    <p>{label}</p>
                    <p>{description}</p>
                </Column>
            </NavLink>
        </li>
    );
}

export default MyProfileLink