import useSingleTrackingEvent from "@Events/hooks/useSingleTrackingEvent";
import ClickBusRentEventModel from "@Events/models/ClickBusRentEventModel";
import {useAppSelector} from "@Hooks/selector/useAppSelector";

export default function useClickBusRentEvent() {
    const {
        departureStationObject,
        destinationStationObject,
        passengers: {
            passengers,
        },
        urlParams: {
            date,
            returnDate,
        },

    } = useAppSelector((state) => (state.form))

    return useSingleTrackingEvent(
        "click_bus_rent",
        ClickBusRentEventModel({departureStationObject, destinationStationObject, passengers, date, returnDate})
    )
}