import {Dispatch, SetStateAction, useEffect} from "react";

interface UseSliderArrowsParams {
    setSlideIndex: Dispatch<SetStateAction<number>>,
    slideIndex: number,
    setTranslateX: Dispatch<SetStateAction<number>>,
    dataLength: number,
    moveStep: number,
}

export default function useSliderArrows(params: UseSliderArrowsParams) {
    const {
        setSlideIndex,
        slideIndex,
        setTranslateX,
        dataLength,
        moveStep,
    } = params

    /**
     * When we update slide index, transition slider to that slide
     */
    useEffect(() => {
        setTranslateX(moveStep * slideIndex)
    }, [slideIndex]);

    const moveToLeft = () => {
        setSlideIndex((prevSlideIndex) => (prevSlideIndex - 1 >= 0 ? prevSlideIndex - 1 : prevSlideIndex));
    };

    const moveToRight = () => {
        setSlideIndex((prevSlideIndex) => (prevSlideIndex + 1 < dataLength ? prevSlideIndex + 1 : prevSlideIndex))
    };

    return {
        moveToLeft,
        moveToRight,
    }
}