import React from 'react';
import Row from "@Containers/components/Row";
import DiscountType from "@Route/types/prices/discounts/Discount.type";
import ActiveDiscount from "@Route/components/Price/Discount/ActiveDiscount";
import InactiveDiscount from "@Route/components/Price/Discount/InactiveDiscount";

interface DiscountDetailsProps {
    discounts: DiscountType[],
}

const DiscountDetails = ({discounts}: DiscountDetailsProps) => (
    <>
        {discounts.map((discount) => {
            const {
                id,
                isActive,
                isApplied,
            } = discount

            const style = {opacity: isApplied ? 1 : 0.5}

            return (
                <Row className="gb--discount-details" key={id}>
                    {isActive ? (
                        <ActiveDiscount
                            style={style}
                            discount={discount}
                        />
                    ) : (
                        <InactiveDiscount
                            discount={discount}
                            style={style}
                        />
                    )}
                </Row>
            )
        })}
    </>
);

export default DiscountDetails;