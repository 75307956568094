import sum from "lodash/sum";
import updateArrayMember from "@Array/updateArrayMember";
import RouteType from "@Route/types/Route.type";
import PassengerGroupWithPricesType from "@SearchResults/types/passenger/PassengerGroupWithPrices.type";
import PassengerGroupWithPricesAndCountType from "@SearchResults/types/passenger/PassengerGroupWithPricesAndCount.type";

/**
 * Returns active passenger groups
 * @param route {object}
 * @param passengers {object}
 * @param passengerGroups {array}
 * @returns {*|*[]}
 */

interface FilterActivePassengerGroupsAttributes {
    route: RouteType,
    passengers: any,
    passengerGroups: PassengerGroupWithPricesType[],
}

export default function filterActivePassengerGroups(props: FilterActivePassengerGroupsAttributes) {
    const {
        route,
        passengers,
        passengerGroups,
    } = props

    const routeActive = route.status.status === 'open'
    const {
        passengersAdult,
        passengersPensioner,
        passengersChildren,
        passengersStudent,
        passengers: totalPassengers,
    } = passengers

    const getNumberOfPassengersForGroup = (group): number => {
        const {
            isNormal,
            isPensioner,
            isChild,
            isStudent,
        } = group

        const groupTypes = {
            [isNormal]: passengersAdult,
            [isPensioner]: passengersPensioner,
            [isChild]: passengersChildren,
            [isStudent]: passengersStudent,
            [isStudent && isChild]: passengersStudent + passengersChildren,
            [isStudent && isPensioner]: passengersStudent + passengersPensioner,
            [isStudent && isPensioner && isChild]: passengersStudent + passengersPensioner + passengersChildren,
        }

        // @ts-ignore
        return groupTypes.true
    }

    const addPassengerCountToGroups = () => (
        passengerGroups.map((group): PassengerGroupWithPricesAndCountType => ({
            ...group,
            numberOfPassengers: getNumberOfPassengersForGroup(group),
        }))
    )

    const addMissingPassengerGroupsAsNormal = () => {
        let passengerGroups = addPassengerCountToGroups()
        const totalPassengersInGroups = sum(passengerGroups.map((group) => (group.numberOfPassengers)))

        if (totalPassengersInGroups < totalPassengers) {
            passengerGroups = updateArrayMember(
                passengerGroups,
                0,
                'numberOfPassengers',
                totalPassengers - totalPassengersInGroups + passengerGroups[0].numberOfPassengers
            )
        }

        return passengerGroups
    }

    const filterOnlyGroupsWithPassengers = () => (
        addMissingPassengerGroupsAsNormal().filter((group) => group.numberOfPassengers > 0 ?? group)
    )

    return routeActive ? filterOnlyGroupsWithPassengers() : []
}