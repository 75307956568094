import React from 'react';
import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "@/types/Icon.type";

const IconMoon = (props: IconType) => {
    const {
        width = '20',
        height = '20',
        fill = '#fff',
        viewBox = '0 0 20 20',
        title = 'IconMoon',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...{width, height, fill, viewBox, title, ...rest}}
            svgIconPath={(
                <path
                    d="m7.2531267.42448225c2.36712079-.01034475 4.4231836.81488373 5.8994786 2.14570081 1.5305898 1.37976146 2.4423947 3.30406023 2.4423947 5.43042558 0 2.10053946-.8910828 4.00344946-2.336267 5.38027616-1.4344965 1.3666444-3.41570855 2.2141152-5.60563452 2.2141152-1.56293327 0-3.01800055-.4331993-4.24374083-1.1786655-1.27337972-.7744394-2.29908677-1.8861275-2.94359855-3.2006531-.37162493-.7579543-.61232629-1.5748017-.71728286-2.41428577.4208474.67234611.96831202 1.23150287 1.59078758 1.66354787.91640215.6360523 2.03403562 1.0234406 3.25369046 1.0234406 1.53000376 0 2.916098-.5888039 3.91898989-1.54425881.99239545-.94545491 1.60885393-2.25079991 1.60885393-3.69397313 0-1.01157049-.3054988-1.95172502-.83154857-2.74696753-.55179469-.83416176-1.3452709-1.50914754-2.28361656-1.94382103l-2.42470048-1.12320337z"
                    fill="none"
                    stroke="#070c63"
                    strokeLinejoin="round"
                    strokeWidth="1.19"
                />
            )}
        />
    );
};

export default IconMoon;
