import React from 'react';
import Row from "@Containers/components/Row";
import Translation from "@Translation/components/Translation";
import Notification from "@Features/notification/components/Notification";
import Column from "@Containers/components/Column";
import IconPen from "@Icons/IconPen";
import IconMailing from "@Icons/IconMailing";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import {clearPassengerSubmissionData} from "@CheckOrder/reducers/checkOrderSlice";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import PaymentSummary from "@CheckOrder/features/paymentSummary/components/PaymentSummary";

function PaymentInfoSection() {
    const {
        passengers,
        email,
        purchasedRoute: {
            passengerGroups,
        },
    } = useAppSelector((state) => state?.checkOrder)
    const dispatch = useAppDispatch()

    const PassengersList = () => (
        <>
            {passengers.map((passenger) => (
                <span className="passenger--summary">
                    {`${passenger.passengerFirstName} ${passenger.passengerLastName || passengerGroups[passenger.singlePassengerGroupId].name}`}
                </span>
            ))}
        </>
    )

    const onClick = () => {
        dispatch(clearPassengerSubmissionData())
    }

    return (
        <>
            <div className="gb--card">
                <Row center justify className="gb--card-title">
                    <Translation translationKey="sys.passengers"/>
                    <button
                        onClick={onClick}
                        type="button"
                    >
                        <IconPen fill="#4a79e2" style={{marginRight: '.5rem'}}/>
                        <Translation translationKey="sys.edit"/>
                    </button>
                </Row>
                <Row wrap>
                    <PassengersList/>
                </Row>
                <hr/>
                <Notification
                    notificationIcon={<IconMailing width="40"/>}
                    notificationType="blue"
                    isFullWidth
                    isBorder
                    isRow
                >
                    <Column>
                        <span className="gb--emphasize gb--emphasize-info">{email}</span>
                        <Translation translationKey="check_order.buyer_info_section.email_note"/>
                    </Column>
                </Notification>
            </div>
            <div className="gb--card">
                <Row center justify className="gb--card-title">
                    <Translation translationKey="sys.total"/>
                </Row>
                <PaymentSummary/>
            </div>
        </>
    )
}

export default PaymentInfoSection