import React from 'react';
import Row from "@Containers/components/Row";
import {useTranslation} from "@Translation/hooks/useTranslation";
import CompanyLogo from "@Route/features/company/components/CompanyLogo";
import Column from "@Containers/components/Column";
import IntermodalTicketLabel from "@Intermodal/components/IntermodalTicketLabel";
import RouteTicketTypeSection from "@Route/components/Sections/RouteTicketTypeSection";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";
import {displayTicketType} from "@/functions/overrides/displayTicketType";

interface IntermodalTitleProps {
    route: RouteWithAddedPropsAndPricesType,
    index: number,
}

function IntermodalTitle({route, index}: IntermodalTitleProps) {
    const ticketInfoLabel = useTranslation("sys.ticket_info")
    const {
        travelInfo: {
            departureStation: {
                cityName: departure,
            },
            destinationStation: {
                cityName: destination,
            },
        },
    } = route

    return (
        <Column className="mobile-content-title">
            <Row justify>
                <IntermodalTicketLabel
                    route={route}
                    index={index}
                />
                <CompanyLogo
                    companyId={route.companyId}
                />
            </Row>
            <Row justify>
                <h4 className="gb--emphasize-default">
                    {`${ticketInfoLabel} ${departure} - ${destination}`}
                </h4>
            </Row>
            {displayTicketType(route) && (
                <RouteTicketTypeSection
                    hasMTicket={route.status.hasMTicket}
                />
            )}
        </Column>
    )
}

export default IntermodalTitle;