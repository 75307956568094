import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconPeopleGrouped(props: IconType) {
    const {
        width = '100',
        height = '85',
        viewBox = '0 0 100 85',
        title = 'IconPeopleGrouped',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none">
                    <g transform="translate(2 21)">
                        <g fill="#b5b7d1" transform="translate(0 4.236842)">
                            <path
                                d="m15.7857143 15.9473684h-9.71428573c-3.33928571 0-6.07142857 2.4230769-6.07142857 5.3846154v11.8461539c0 1.9923076 1.57857143 3.6615384 3.64285714 4.1461538v17.3923077c0 1.7769231 1.63928572 3.2307692 3.64285715 3.2307692h7.28571431c2.0035714 0 3.6428571-1.4538461 3.6428571-3.2307692v-17.2307692h3.6428572v-16.1538462c0-2.9615385-2.7321429-5.3846154-6.0714286-5.3846154z"
                            />
                            <ellipse cx="10.928571" cy="6.052632" rx="6.071429" ry="6.052632"/>
                        </g>
                        <path
                            d="m29.1428571 62.1842105c-2.0035714 0-3.6428571-1.6342105-3.6428571-3.6315789v-25.4210527h7.2857143v25.4210527c0 1.9973684-1.6392857 3.6315789-3.6428572 3.6315789z"
                            fill="#fff"
                        />
                        <path
                            d="m30.3571429 18h-9.7142858c-3.3392857 0-6.0714285 2.5416324-6.0714285 5.6480719v16.9442158c2.0035714 0 3.6428571 1.5249794 3.6428571 3.3888432v14.5155448c0 1.8638637 1.5178572 3.5018046 3.5214286 3.5018046 2.0642857.0564807 3.7642857-1.4684987 3.7642857-3.3888432v-18.0173494h7.2857143 3.6428571v-16.9442158c0-3.1064395-2.7321428-5.6480719-6.0714285-5.6480719z"
                            fill="#fff"
                        />
                        <path
                            d="m29.1428571 64c-3.0357142 0-5.4642857-2.2857143-5.4642857-5.1428571v-18.2857143c0-.9714286.7892857-1.7142857 1.8214286-1.7142857s1.8214286.7428571 1.8214286 1.7142857v18.2857143c0 .9714285.7892857 1.7142857 1.8214285 1.7142857 1.0321429 0 1.8214286-.7428572 1.8214286-1.7142857v-18.2857143c0-.9714286.7892857-1.7142857 1.8214286-1.7142857h1.8214286v-15.4285715c0-2.2285714-1.8821429-4-4.25-4h-9.7142858c-2.3678571 0-4.25 1.7714286-4.25 4v17.1428572c0 .9714285-.7892857 1.7142857-1.8214285 1.7142857-1.0321429 0-1.8214286-.7428572-1.8214286-1.7142857v-17.1428572c0-4.1142857 3.5214286-7.4285714 7.8928571-7.4285714h9.7142858c4.3714285 0 7.8928571 3.3142857 7.8928571 7.4285714v17.1428572c0 .9714285-.7892857 1.7142857-1.8214286 1.7142857h-1.8214285v16.5714286c0 2.8571428-2.4285715 5.1428571-5.4642858 5.1428571z"
                            fill="#070c63"
                        />
                        <ellipse cx="25.5" cy="6.868421" fill="#fff" rx="6.071429" ry="6.052632"/>
                        <path
                            d="m25 14c-3.8769231 0-7-3.1230769-7-7 0-3.87692308 3.1230769-7 7-7s7 3.12307692 7 7c0 3.8769231-3.1230769 7-7 7zm0-10.76923077c-2.1 0-3.7692308 1.66923077-3.7692308 3.76923077s1.6692308 3.7692308 3.7692308 3.7692308 3.7692308-1.6692308 3.7692308-3.7692308-1.6692308-3.76923077-3.7692308-3.76923077z"
                            fill="#070c63"
                        />
                        <path
                            d="m21.8571429 64c-3.0357143 0-5.4642858-2.3157895-5.4642858-5.2105263v-15.0526316c0-.9842105.7892858-1.7368421 1.8214286-1.7368421 1.0321429 0 1.8214286.7526316 1.8214286 1.7368421v15.0526316c0 .9842105.7892857 1.7368421 1.8214286 1.7368421 1.0321428 0 1.8214285-.7526316 1.8214285-1.7368421s.7892857-1.7368421 1.8214286-1.7368421 1.8214286.7526316 1.8214286 1.7368421c0 2.8947368-2.4285715 5.2105263-5.4642857 5.2105263z"
                            fill="#070c63"
                        />
                        <path
                            d="m54 64c-.1290323 0-.2580645 0-.3870968-.0647773-.1290322 0-.2580645-.0647774-.3870967-.1295547-.1290323-.0647773-.1935484-.1295546-.3225807-.194332-.1290323-.0647773-.1935484-.1295546-.3225806-.2591093-.3870968-.3886639-.5806452-.8421052-.5806452-1.3603239 0-.5182186.1935484-1.0364372.5806452-1.3603239.0645161-.0647773.1935483-.1943319.3225806-.2591093.1290323-.0647773.1935484-.1295546.3225807-.1943319.1290322-.0647774.2580645-.0647774.3870967-.1295547.2580645-.0647773.5161291-.0647773.7741936 0 .1290322 0 .2580645.0647773.3870967.1295547.1290323.0647773.2580646.1295546.3225807.1943319.1290323.0647774.1935484.1295547.3225806.2591093.3870968.388664.5806452.8421053.5806452 1.3603239 0 .5182187-.1935484 1.0364373-.5806452 1.3603239-.0645161.0647773-.1935483.194332-.3225806.2591093-.1290323.0647774-.1935484.1295547-.3225807.194332-.1290322.0647773-.2580645.0647773-.3870967.1295547-.1290323.0647773-.2580645.0647773-.3870968.0647773z"
                            fill="#444b54"
                        />
                    </g>
                    <g transform="matrix(-1 0 0 1 98 16)">
                        <g transform="matrix(-1 0 0 1 37.3 2)">
                            <ellipse cx="25.071429" cy="8.052632" fill="#fff" rx="6.071429" ry="6.052632"/>
                            <path
                                d="m24.8928571 15.7368421c-4.3714285 0-7.8928571-3.5105263-7.8928571-7.86842105 0-4.35789473 3.5214286-7.86842105 7.8928571-7.86842105 4.3714286 0 7.8928572 3.51052632 7.8928572 7.86842105 0 4.35789475-3.5214286 7.86842105-7.8928572 7.86842105zm0-12.10526315c-2.3678571 0-4.25 1.87631579-4.25 4.2368421 0 2.36052635 1.8821429 4.23684215 4.25 4.23684215 2.3678572 0 4.25-1.8763158 4.25-4.23684215 0-2.36052631-1.8821428-4.2368421-4.25-4.2368421z"
                                fill="#070c63"
                            />
                            <path d="m21.919 31.052-3.2175 10.626-2.508-3.0525 3.366-11.1375z" fill="#1976d2"/>
                            <g fill="#fff" stroke="#070c63" strokeWidth="3.3">
                                <path
                                    d="m10.17595 39.5-2.63835 10.2597-6.402 11.2035c-.9042 1.58235-.35475 3.59865 1.2276 4.50285.5148.2937 1.0791.43395 1.6335.43395 1.1451 0 2.25885-.59565 2.8677-1.6632l6.6-11.55c.132-.231.23595-.4752.30855-.7293l3.5541-12.4575z"
                                />
                                <path
                                    d="m35.1685 33.2465-5.808-2.904-6.4812-9.67725c-.7161-1.09065-1.782-1.9602-3.12675-2.36775-.55275-.16665-1.11045-.24585-1.6599-.247594-2.4882-.008156-4.785 1.600594-5.544 4.098694l-5.00115 16.5c-.627 2.07075-.00825 4.1976 1.3827 5.66115 2.28855 2.40735 10.0848 8.1576 10.0848 8.1576l1.518 10.593c.231 1.65 1.65 2.838 3.267 2.838.1485 0 .3135-.0165.462-.033 1.65825-.2409 2.84625-1.6533 2.838-3.267 0-.1518-.0099-.3069-.033-.462l-1.65-11.55c-.0825-.627-.3465-1.2045-.759-1.683l-5.9565-7.2303 3.21915-10.6227 2.42385 3.663c.33.495.759.8745 1.287 1.1385l6.6 3.3c.462.23265.9735.34815 1.4685.34815 1.2045 0 2.376-.66 2.9535-1.8315.8085-1.617.1485-3.597-1.485-4.422z"
                                    strokeLinejoin="round"
                                />
                            </g>
                        </g>
                        <g fill="#b5b7d1" transform="translate(21 4.236842)">
                            <path
                                d="m15.7857143 15.7368421h-9.71428573c-3.33928571 0-6.07142857 2.7236842-6.07142857 6.0526316v13.3157895c0 2.2394736 1.57857143 4.1157894 3.64285714 4.6605263v19.55c0 1.9973684 1.63928572 3.6315789 3.64285715 3.6315789h7.28571431c2.0035714 0 3.6428571-1.6342105 3.6428571-3.6315789v-19.3684211h3.6428572v-18.1578947c0-3.3289474-2.7321429-6.0526316-6.0714286-6.0526316z"
                            />
                            <ellipse cx="10.928571" cy="6.052632" rx="6.071429" ry="6.052632"/>
                        </g>
                        <g fill="#b5b7d1" transform="translate(44.142857 4.236842)">
                            <path
                                d="m15.7857143 15.7368421h-9.71428573c-3.33928571 0-6.07142857 2.7236842-6.07142857 6.0526316v13.3157895c0 2.2394736 1.57857143 4.1157894 3.64285714 4.6605263v19.368421c0 1.9973684 1.51785715 3.7526316 3.52142857 3.7526316 2.06428572.0605263 5.46428569.0605263 7.52857139 0 2.0035715-.0605263 3.5214286-1.8157895 3.5214286-3.7526316v-19.1868421c2.0035714 0 3.6428572-1.6342105 3.6428572-3.6315789v-14.5263158c0-3.3289474-2.7321429-6.0526316-6.0714286-6.0526316z"
                            />
                            <ellipse cx="10.928571" cy="6.052632" rx="6.071429" ry="6.052632"/>
                        </g>
                        <path
                            d="m44.1428571 67.1842105c-2.0035714 0-3.6428571-1.6342105-3.6428571-3.6315789v-25.4210527h7.2857143v25.4210527c0 1.9973684-1.6392857 3.6315789-3.6428572 3.6315789z"
                            fill="#fff"
                        />
                        <path
                            d="m45.3571429 19.9736842h-9.7142858c-3.3392857 0-6.0714285 2.7236842-6.0714285 6.0526316v18.1578947c2.0035714 0 3.6428571 1.6342106 3.6428571 3.631579v15.5552631c0 1.9973685 1.5178572 3.7526316 3.5214286 3.7526316 2.0642857.0605263 3.7642857-1.5736842 3.7642857-3.6315789v-19.3078948h7.2857143 3.6428571v-18.1578947c0-3.3289474-2.7321428-6.0526316-6.0714285-6.0526316z"
                            fill="#fff"
                        />
                        <path
                            d="m44.1428571 69c-3.0357142 0-5.4642857-2.4210526-5.4642857-5.4473684v-19.3684211c0-1.0289473.7892857-1.8157894 1.8214286-1.8157894s1.8214286.7868421 1.8214286 1.8157894v19.3684211c0 1.0289473.7892857 1.8157895 1.8214285 1.8157895 1.0321429 0 1.8214286-.7868422 1.8214286-1.8157895v-19.3684211c0-1.0289473 2.4869094-1.2548139 3.0437859-1.6954311.5568765-.4406171.5990713-1.6765204.5990713-2.4546014 0-3.1128583 0-7.7821457 0-14.0078622 0-2.3605263-1.8821429-4.2368421-4.25-4.2368421h-9.7142858c-2.3678571 0-4.25 1.8763158-4.25 4.2368421v18.1578947c0 1.0289474-.7892857 1.8157895-1.8214285 1.8157895-1.0321429 0-1.8214286-.7868421-1.8214286-1.8157895v-18.1578947c0-4.3578947 3.5214286-7.8684211 7.8928571-7.8684211h9.7142858c4.3714285 0 7.8928571 3.5105264 7.8928571 7.8684211v18.1578947c0 1.0289474-1.1174371 1.3712675-2.3801471 1.8157895-.8418067.296348-1.26271.981501-1.26271 2.0554589v15.4971727c0 3.0263158-2.4285715 5.4473684-5.4642858 5.4473684z"
                            fill="#070c63"
                        />
                        <ellipse cx="40.5" cy="7.868421" fill="#fff" rx="6.071429" ry="6.052632"/>
                        <g fill="#070c63">
                            <path
                                d="m40.5 15.7368421c-4.3714286 0-7.8928571-3.5105263-7.8928571-7.86842105 0-4.35789473 3.5214285-7.86842105 7.8928571-7.86842105s7.8928571 3.51052632 7.8928571 7.86842105c0 4.35789475-3.5214285 7.86842105-7.8928571 7.86842105zm0-12.10526315c-2.3678571 0-4.25 1.87631579-4.25 4.2368421 0 2.36052635 1.8821429 4.23684215 4.25 4.23684215s4.25-1.8763158 4.25-4.23684215c0-2.36052631-1.8821429-4.2368421-4.25-4.2368421z"
                            />
                            <path
                                d="m36.8571429 69c-3.0357143 0-5.4642858-2.4210526-5.4642858-5.4473684v-15.7368421c0-1.0289474.7892858-1.8157895 1.8214286-1.8157895 1.0321429 0 1.8214286.7868421 1.8214286 1.8157895v15.7368421c0 1.0289473.7892857 1.8157895 1.8214286 1.8157895 1.0321428 0 1.8214285-.7868422 1.8214285-1.8157895 0-1.0289474.7892857-1.8157895 1.8214286-1.8157895s1.8214286.7868421 1.8214286 1.8157895c0 3.0263158-2.4285715 5.4473684-5.4642857 5.4473684z"
                            />
                        </g>
                    </g>
                </g>
            )}
        />

    );
}

export default IconPeopleGrouped;
