import React from "react";
import {useTranslation} from "@Translation/hooks/useTranslation";
import useIsMobile from "@Hooks/selector/useIsMobile";
import Row from "@Containers/components/Row";
import VoucherStatusType from "@MyVoucher/types/VoucherStatus.type";
import VoucherTypeLabel from "@MyVoucher/components/VoucherTypeLabel";

interface VoucherDetailsTitleSectionProps {
    status: VoucherStatusType
}

function VoucherDetailsTitleSection({status}: VoucherDetailsTitleSectionProps) {
    const conditionsOfUseLabel = useTranslation('my_profile.my_vouchers.conditions_of_use')
    const isMobile = useIsMobile()
    if (!isMobile) {
        return (
            <Row justify>
                {conditionsOfUseLabel}
                <VoucherTypeLabel status={status}/>
            </Row>
        );
    }
    return null;
}

export default VoucherDetailsTitleSection;