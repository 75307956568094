import postError from "@Errors/functions/postError";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import useUser from "@User/hooks/useUser";

export default function useLogError(error, info, postToSlack = true) {
    const {experiments} = useAppSelector((state) => state.page)
    const user = useUser()

    return async () => {
        await postError({
            error,
            info,
            postToSlack,
            experiments,
            userId: user?.id,
        })()
    }
}