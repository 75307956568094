import React from 'react';
import Filters from "@SearchResults/features/sidebar/components/Filters";
import Button from "@Generic/Button";
import {toggleFiltersOverlay} from "@/reducers/mobileSlice";
import MobileOverlay from "./MobileOverlay";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import useSearchResults from "@SearchResults/hooks/useSearchResults";
import Sorter from "@SearchResults/features/sorting/components/Sorter";
import FilterSection from "@SearchResults/features/sidebar/components/FilterSection";
import Row from "@Containers/components/Row";
import SidebarSectionContainerItem from "@Sidebar/components/Sections/SidebarSectionContainerItem";
import useClearAllFilters from "@SearchResults/features/sidebar/hooks/useClearAllFilters";
import useActiveFiltersCount from "@SearchResults/hooks/useActiveFiltersCount";
import Translation from "@Translation/components/Translation";
import QuantityAwareTranslation from "@Translation/components/QuantityAwareTranslation";

function MobileFilters() {
    const dispatch = useAppDispatch()
    const toggleFilters = () => dispatch(toggleFiltersOverlay())
    const searchResults = useSearchResults()
    const count = useActiveFiltersCount()
    const {clearFilters} = useClearAllFilters()

    return (
        <MobileOverlay title="Filters" backClick={toggleFilters} slideInDirection="down">
            <FilterSection translationKey="sys.sort_by">
                <Sorter
                    hideFilterButton
                />
            </FilterSection>
            {searchResults?.data && (
                <Filters
                    searchData={searchResults.data}
                />
            )}
            <SidebarSectionContainerItem className="gb--mobile-overlay-filter-cta">
                <Row center justify>
                    <Button
                        className="dismiss"
                        additionalClasses="gb--mobile-overlay-confirm-button"
                        onClick={() => {
                            clearFilters();
                            toggleFilters();
                        }}
                    >
                        <Translation translationKey="search_results.clear_all"/>
                    </Button>
                    <Button
                        className="green"
                        additionalClasses="gb--mobile-overlay-confirm-button"
                        onClick={toggleFilters}
                    >
                        <QuantityAwareTranslation
                            translationKey="search_results.apply_filter_count"
                            quantity={count}
                            variableReplacements={{
                                count,
                            }}
                        />
                    </Button>
                </Row>
            </SidebarSectionContainerItem>
        </MobileOverlay>
    );
}

export default MobileFilters;