import React from 'react';
import RouteDetails from "@Route/components/Container/RouteDetails";
import IntermodalRouteDetails from "@Intermodal/components/IntermodalRouteDetails";
import IntermodalRouteWithDetailsType from "@Intermodal/types/IntermodalRouteWithDetails.type";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";
import {isIntermodalRoute} from "@SearchResults/functions/isIntermodalRoute";
import RouteIconType from "@SearchResults/types/routeIcons/RouteIcon.type";
import ParamsType from "@SearchResults/types/params/Params.type";
import {isSuggestedRoute} from "@SearchResults/functions/isSuggestedRoute";

interface RouteDetailsContainerProps {
    route: IntermodalRouteWithDetailsType | RouteWithAddedPropsAndPricesType,
    routeIcons: Record<number, RouteIconType>,
    params: ParamsType
}

function RouteDetailsContainer(props: RouteDetailsContainerProps) {
    const {
        route,
        routeIcons,
        params,
    } = props

    if (isIntermodalRoute(route) || isSuggestedRoute(route)) {
        return (
            <IntermodalRouteDetails
                params={params}
                routeIcons={routeIcons}
                route={route}
            />
        )
    }

    return (
        <RouteDetails
            routeIcons={routeIcons}
            route={route}
        />
    )
}

export default RouteDetailsContainer;