import React from 'react';
import IconCheckmark from "@Icons/IconCheckmark";
import Translation from "@Translation/components/Translation";

interface SortOptionItemProps {
    onClick: (any?) => (any),
    isSelected: boolean,
    label: string,
}

function SortOptionItem(props: SortOptionItemProps) {
    const {
        onClick,
        isSelected,
        label,
    } = props

    const setActiveClassName = () => {
        let className = 'gb--row gb--row-middle gb--row-justify sort-list__item '
        if (isSelected) {
            className += 'sort-list__item--selected'
        }
        return className
    }

    return (
        <li className={setActiveClassName()} onClick={onClick}>
            <span role="button" className="sort-list__item--action">
                <Translation translationKey={label}/>
            </span>
            {isSelected && <IconCheckmark width="16" height="16" fill="#7ed321"/>}
        </li>
    );
}

export default SortOptionItem;