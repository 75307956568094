import React from "react";
import DateSlickContainer from "@SearchResults/features/dateSlick/components/DateSlickContainer";
import MobileFormOverlayContainer from "@Form/components/Mobile/MobileFormOverlayContainer";
import useActiveTab from "@Tabs/hooks/useActiveTab";
import useIsMobile from "@Hooks/selector/useIsMobile";
import useTabsToShow from "@Tabs/hooks/useTabsToShow";
import useActiveSliderDate from "@SearchResults/features/dateSlick/hooks/useActiveSliderDate";
import usePrepareTabContentData from "@Tabs/hooks/usePrepareTabContentData";
import {SearchResponseType} from "@SearchResults/types/SearchResponse.type";
import RoutesList from "@RoutesList/components/RoutesList";
import SingleSelectedRoute from "@RoutesList/components/SingleSelectedRoute";
import {useTranslation} from "@Translation/hooks/useTranslation";
import usePrepareValidReturnRoutes from "@RoutesList/hooks/usePrepareValidReturnRoutes";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";
import RoutesProgressBar from "@RoutesList/components/RoutesProgressBar";
import {isEmpty} from "@Array/isEmpty";
import NoReturnRoutesNotification from "@RoutesList/components/NoReturnRoutesNotification";

type ReturnTabContentProps = {
    searchQuery: SearchResponseType,
    singleSelectedRoute: RouteWithAddedPropsAndPricesType,
}

function ReturnTabContent({searchQuery, singleSelectedRoute}: ReturnTabContentProps) {
    const {
        data,
    } = searchQuery

    const {
        result: {
            params,
            singleRoutes,
            resolvedReturnApiCount,
            totalApiCount,
        },
    } = data

    const apiRoutesLoading = resolvedReturnApiCount < totalApiCount
    const searchResultsSelectYourReturnRoute = useTranslation("search_results.select_your_return_route")
    const isMobile = useIsMobile()
    const {getTabsToShow} = useTabsToShow(singleRoutes)
    const tabsToShow = getTabsToShow()
    const activeTab = useActiveTab(tabsToShow)
    const {getActiveSliderDate} = useActiveSliderDate(true)
    const preparedTabData = usePrepareTabContentData({
        data,
        tabsToShow,
        activeTab,
        suggestionRoutesEnabled: false,
    })
    const {
        possibleReturnRoutes,
    } = usePrepareValidReturnRoutes(params, preparedTabData, singleSelectedRoute)

    return (
        <>
            <DateSlickContainer
                isReturn
                isLoading={searchQuery.isLoading}
                date={getActiveSliderDate(searchQuery.data.result.params.returnDate)}
            />
            <SingleSelectedRoute
                singleSelectedRoute={singleSelectedRoute}
                params={params}
                routeIcons={data.result.routeIcons}
            />
            <div>
                <RoutesList
                    singleSelectedRoute={singleSelectedRoute}
                    intermodalRoutes={data.result.intermodalRoutes}
                    companies={data.result.companies}
                    routeIcons={data.result.routeIcons}
                    passengerGroups={data.result.passengerGroups}
                    routes={possibleReturnRoutes}
                    isReturn
                    params={data.result.params}
                    routesTitle={searchResultsSelectYourReturnRoute}
                />
                {apiRoutesLoading && (
                    <RoutesProgressBar
                        resolvedCount={resolvedReturnApiCount}
                        totalCount={totalApiCount}
                        queriedRoutesTitle="providers"
                    />
                )}
                {!apiRoutesLoading && isEmpty(possibleReturnRoutes) && (
                    <NoReturnRoutesNotification
                        params={params}
                        suggestionsData={data.suggestionData}
                        singleSelectedRoute={singleSelectedRoute}
                    />
                )}
            </div>
            {isMobile && (
                <MobileFormOverlayContainer/>
            )}
        </>
    );
}

export default ReturnTabContent;