/**
 * We have common pattern with parseInt due to booleans in database being stored as tinyInt strings, reduces clutter
 * @param int {(string|number)}
 * @returns {boolean}
 * @constructor
 */
export default function intToBool(int: string | number): boolean {
    if (!!int && typeof int !== "string" && typeof int !== "number") {
        throw new TypeError(`Provided value (${int}) is neither a string or a number`);
    }

    // @ts-ignore
    return !!parseInt(int, 10)
}