import React from 'react';
import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconTimeAlert(props: IconType) {
    const {
        width = '25',
        height = '25',
        viewBox = '0 0 25 25',
        title = 'IconTimeAlert',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none">
                    <g fill="#4a4a4a" transform="translate(0 1.5)">
                        <path
                            d="m10.9775026 21.9550052c-6.04798256 0-10.9775026-4.92952-10.9775026-10.9775026 0-6.04798256 4.92952004-10.9775026 10.9775026-10.9775026 6.0479826 0 10.9775026 4.92952004 10.9775026 10.9775026 0 .3521086-.2692595.6213681-.6213681.6213681-.3521085 0-.621368-.2692595-.621368-.6213681 0-5.36447768-4.3702888-9.73476646-9.7347665-9.73476646-5.36447768 0-9.73476646 4.37028878-9.73476646 9.73476646 0 5.3644777 4.37028878 9.7347665 9.73476646 9.7347665.3521086 0 .6213681.2692595.6213681.621368 0 .3521086-.2692595.6213681-.6213681.6213681z"
                        />
                        <path
                            d="m16.1555699 10.3561345h-3.2104017c-.2071227-.64208031-.7042172-1.15988704-1.3462975-1.34629746v-5.28162861c0-.35210857-.2692595-.62136807-.6213681-.62136807s-.6213681.2692595-.6213681.62136807v5.28162861c-.849203.2692595-1.4498588 1.05632576-1.4498588 1.96766556 0 1.1391748.93205211 2.0712269 2.0712269 2.0712269.9320521 0 1.7191183-.6006558 1.9676656-1.4498588h3.2104017c.3521085 0 .621368-.2692595.621368-.6213681s-.2692595-.6213681-.621368-.6213681zm-5.1780673 1.4498589c-.4556699 0-.8284908-.3728209-.8284908-.8284908s.3728209-.8284908.8284908-.8284908.8284908.3728209.8284908.8284908-.3728209.8284908-.8284908.8284908z"
                        />
                    </g>
                    <path
                        d="m6.29260225.16314154c.41950681 0 .79240176.20975341 1.00215517.55934242l4.94085808 8.22699479c.2097534.37289495.2097534.8157077 0 1.16529675-.2097534.3728949-.6059543.5826483-1.0254611.5826483h-9.85841021c-.44281275 0-.8157077-.2097534-1.02546111-.5826483-.20975341-.37289499-.18644747-.7924018.02330593-1.16529675l4.94085806-8.22699479c.20975341-.34958901.58264836-.55934242 1.00215518-.55934242zm.46611868 7.71426423c-.25636528-.25636527-.72248396-.25636527-.97884924 0-.1398356.11652968-.20975341.30297715-.20975341.48942462l.02330594.13983561.04661187.1398356.0699178.11652968c.02330593.04661186.0699178.09322373.09322374.11652967.11652967.1398356.30297714.2097534.48942462.2097534.18644747 0 .37289494-.0699178.44281275-.2097534.04661186-.04661187.0699178-.06991781.09322373-.11652967.02330594-.02330594.04661187-.06991781.06991781-.11652968.02330593-.04661186.04661186-.09322373.04661186-.1398356.02330594-.04661187.02330594-.09322374.02330594-.13983561 0-.18644747-.0699178-.34958901-.20975341-.48942462zm-.46611868-4.84763432c-.39620089 0-.69917803.30297715-.69917803.69917803v2.33059342c0 .39620089.30297714.69917803.69917803.69917803.39620088 0 .69917802-.30297714.69917802-.69917803v-2.33059342c0-.39620088-.30297714-.69917803-.69917802-.69917803z"
                        fill="#f5a623"
                        transform="translate(12.5 12.648649)"
                    />
                </g>
            )}
        />

    );
}

export default IconTimeAlert;
