import React from "react";
import {useTranslation} from "@Translation/hooks/useTranslation";

interface PasswordErrorMessageProps {
    fields: {
        firstName: string,
        lastName: string,
        email: string,
        password: string,
        confirmPassword: string,
    },
}

const PasswordErrorMessage = ({fields}: PasswordErrorMessageProps) => {
    const passwordTooShortLabel = useTranslation('log_in.password_too_short')
    const passwordDoNotMatchLabel = useTranslation('user.user_messages.error.passwords_dont_match')

    if (fields.confirmPassword.length < 6) {
        return <>{passwordTooShortLabel}</>
    }
    if (fields.password !== fields.confirmPassword) {
        return <>{passwordDoNotMatchLabel}</>
    }
    return null
}

export default PasswordErrorMessage