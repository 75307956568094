import React from 'react';
import {useAppSelector} from "@Hooks/selector/useAppSelector";

interface HiddenSubmissionFormProps {
    ipgFormRef: React.ForwardedRef<any>,
    privacyPolicyAccepted: boolean,
    newsletterAccepted: boolean,
}

function HiddenSubmissionForm({newsletterAccepted, privacyPolicyAccepted, ipgFormRef}: HiddenSubmissionFormProps) {
    const {tripReviewAccepted} = useAppSelector((state) => state.checkOrder);
    const {isBuyingPremiumSupport} = useAppSelector((state) => state.checkOrder.selectedServices);
    const {
        email,
        phoneNumber,
        tokens: {
            ipgFormToken,
        },
        ticket,
        phoneCountryObject,
        stripeEnabled,
    } = useAppSelector((state) => state?.checkOrder)

    const {
        ticketCode,
        priceInHrk,
        purchaseMaxTimestamp,
    } = ticket

    return (
        <form
            ref={ipgFormRef}
            name="check_order_submit"
            method="post"
            action={`/check-order-submit/${ticketCode}`}
            id="IpgForm"
        >
            <div id="check_order_submit">
                <input
                    type="hidden"
                    id="check_order_submit_order_number"
                    name="check_order_submit[order_number]"
                    value={ticketCode}
                />
                <input
                    type="hidden"
                    id="check_order_submit_customer_email"
                    name="check_order_submit[customer_email]"
                    required
                    value={email}
                />
                <input
                    type="hidden"
                    id="check_order_submit_amount"
                    name="check_order_submit[amount]"
                    value={priceInHrk}
                />
                <input
                    type="hidden"
                    id="check_order_submit_best_before"
                    name="check_order_submit[best_before]"
                    value={purchaseMaxTimestamp}
                />
                <input
                    type="hidden"
                    id="check_order_submit_customer_phone"
                    name="check_order_submit[customer_phone]"
                    required
                    value={phoneNumber}
                />
                <input
                    type="hidden"
                    id="check_order_submit_customer_country"
                    name="check_order_submit[country]"
                    required
                    value={phoneCountryObject?.isoCode}
                />
                <input
                    type="hidden"
                    id="check_order_submit_stripe_enabled"
                    name="check_order_submit[stripe_enabled]"
                    required
                    value={stripeEnabled ? 1 : 0}
                />
                <input
                    type="hidden"
                    id="check_order_submit_proceed_to_ipg_payment_form"
                    name="check_order_submit[proceed_to_ipg_payment_form]"
                    value={1}
                />
                <input
                    type="hidden"
                    id="check_order_submit_gbb_tos_and_privacy_policy"
                    name="check_order_submit[gbb_tos_and_privacy_policy]"
                    value={privacyPolicyAccepted ? 1 : 0}
                />
                <input
                    type="hidden"
                    id="check_order_submit_gbb_ticket_trip_review"
                    name="check_order_submit[gbb_ticket_trip_review]"
                    value={tripReviewAccepted ? 1 : 0}
                />
                <input
                    type="hidden"
                    id="check_order_submit_gbb_newsletter"
                    name="check_order_submit[gbb_newsletter]"
                    value={newsletterAccepted ? 1 : 0}
                />
                <input
                    type="hidden"
                    id="check_order_submit__token"
                    name="check_order_submit[_token]"
                    value={ipgFormToken}
                />
                <input
                    type="hidden"
                    id="check_order_submit_gbb_premium_support"
                    name="check_order_submit[gbb_premium_support]"
                    value={isBuyingPremiumSupport ? 1 : 0}
                />
            </div>
        </form>
    );
}

export default HiddenSubmissionForm;