import UAParser from "ua-parser-js"
import getCookie from "@Cookies/functions/getCookie";
import loadCurrency from "@Currency/functions/loadCurrency";
import {CurrencyVal} from "@Currency/types/Currency.type";
import {LocaleType} from "@/types/Locale.type";

/**
 * Metadata injected in all events
 * @constructor
 */

interface MetadataEventModelType {
    userAgent: string,
    locale: string,
    device: string | null,
    referrer: string | null,
    channel: string | null,
    browser: string | null,
    browserVersion: string | null,
    os: string | null,
    osVersion: string | null,
    utmSource: string | null,
    utmMedium: string | null,
    utmCampaign: string | null,
    gclid: string | null,
    currency: CurrencyVal | null,
    language: LocaleType,
    accountId: number | null,
    experiments: string
}

export default function MetadataEventModel(experiments: string, accountId = null): MetadataEventModelType {
    const {
        currentLocale,
        navigator,
    } = window

    const userAgent = new UAParser([navigator.userAgent])
    const browser = userAgent.getBrowser()
    const device = userAgent.getDevice()
    const os = userAgent.getOS()
    const searchParams = new URLSearchParams(window.location.search);
    const {vendor, type, model} = device
    const deviceString = (vendor || type || model) ? `${vendor} ${type} ${model}` : null

    return {
        userAgent: navigator.userAgent,
        locale: navigator.language,
        device: deviceString,
        referrer: document.referrer,
        channel: document.domain,
        browser: browser?.name,
        browserVersion: browser?.version,
        os: os.name,
        osVersion: os.version,
        utmSource: searchParams.get('utm_source'),
        utmMedium: searchParams.get('utm_medium'),
        utmCampaign: searchParams.get('utm_campaign'),
        gclid: searchParams.get('gclid'),
        currency: getCookie("currency", false),
        language: currentLocale,
        accountId,
        experiments,
    }
}