import {useAppSelector} from "@Hooks/selector/useAppSelector";
import AddVehicleAddon from "@CheckOrder/features/addons/features/jadrolinija/components/AddVehicleAddon";

function AddonsSection() {
    const {addons, singleSelectedAddons} = useAppSelector((state) => state.checkOrder.ticket)
    return (
        <>
            {!singleSelectedAddons?.length && addons?.singleRouteAddons && addons?.singleRouteAddons.type === 'jadrolinijaVehicleAddonType' && (
                <AddVehicleAddon
                    addon={addons.singleRouteAddons}
                />
            )}
        </>
    );
}

export default AddonsSection;