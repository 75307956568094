import {
    RouteTransferTypeKeys,
    RouteTransferTypeValuesType
} from "@Route/features/transportType/types/routeTransferType.type";
import {routeTransferTypes} from "@Route/features/transportType/constants/routeTransferTypes";

/**
 * Returns transport type for specific route
 * @return {'bus'|'ferry'|'train'|'plane'|'minivan'|'privateMinivan'|'privateBus'|'privateTransfer'}
 * @param routeTransferType {number}
 */
export default function getRouteTransferType(routeTransferType: RouteTransferTypeKeys): RouteTransferTypeValuesType {
    return routeTransferTypes[routeTransferType]
}