import React, {StrictMode} from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import store from 'store';
import App from './App';

const container = document.getElementById('root');
const root = createRoot(container)

__webpack_public_path__ = container.dataset.assetBaseReact;

root.render(
    <Provider store={store}>
        <App/>
    </Provider>
);
