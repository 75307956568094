import React, {ReactNode} from "react";
import ModalContent from "./ModalContent";
import ModalHeader from "./ModalHeader";
import ModalBody from "./ModalBody";
import ModalFooter from "./ModalFooter";

interface ModalProps {
    /** Handler */
    handleClick: (any?) => any,
    /** Content of modal header */
    title?: ReactNode | string,
    /** Content of modal */
    message: ReactNode | string,
    buttonText: string,
    /** Optional */
    alternativeActionText?: string,
    alternativeActionUrl?: string,
    alternativeActionLinkTarget?: React.HTMLAttributeAnchorTarget,
    closeAction?: (any?) => any,
    messageIcon?: string,
    titleIcon?: ReactNode | string,
    closeIcon?: ReactNode | string,
    noticeType?: string,
    className?: string,
    // DangerouslySetInnerHtml
    isInnerHtml?: boolean,
}

function Modal(props: ModalProps) {
    const {
        title,
        titleIcon,
        message,
        messageIcon,
        handleClick,
        buttonText,
        closeAction,
        closeIcon,
        noticeType,
        alternativeActionUrl,
        alternativeActionLinkTarget,
        alternativeActionText,
        className,
        isInnerHtml = false,
    } = props;

    return (
        <ModalContent className={className}>
            {title && (
                <ModalHeader
                    closeAction={closeAction}
                    title={title}
                    titleIcon={titleIcon}
                    closeIcon={closeIcon}
                    noticeType={noticeType}
                />
            )}
            <ModalBody
                isInnerHtml={isInnerHtml}
                message={message}
                messageIcon={messageIcon}
            />
            <ModalFooter
                buttonText={buttonText}
                handleClick={handleClick}
                alternativeActionText={alternativeActionText}
                alternativeActionUrl={alternativeActionUrl}
                alternativeActionLinkTarget={alternativeActionLinkTarget}
            />
        </ModalContent>
    )
}

export default Modal;