import React from 'react';
import {useTranslation} from "@Translation/hooks/useTranslation";
import NoResultsNoticeContainer from "./NoResultsNoticeContainer";

interface NoDirectRoutesProps {
    toCityName: string,
    nearByCityName: string,
}

function NoDirectRoutes({toCityName, nearByCityName}: NoDirectRoutesProps) {
    const noDirectBusesTitleLabel = useTranslation('no_res.no_direct_buses.title')
    const noDirectBusesContentLabel = useTranslation('no_res.no_direct_buses.content', {
        destinationStation: toCityName,
        nearbyCity: nearByCityName,
    })

    return (
        <NoResultsNoticeContainer
            content={noDirectBusesContentLabel}
            title={noDirectBusesTitleLabel}
        />
    );
}

export default NoDirectRoutes;