import React from 'react';
import IconLeaf from "@Icons/IconLeaf";

interface MTicketEcoFriendlyLabelProps {
    isSmall?: boolean,
}

const MTicketEcoFriendlyLabel = ({isSmall = false}: MTicketEcoFriendlyLabelProps) => (
    <span className="gb--ticket_label gb--emphasize-default">
        <span>
            M-ticket
        </span>
        {!isSmall && (
            <>
                <span>
                    <IconLeaf/>
                    eco
                    {" "}
                </span>
                <span>
                    friendly
                </span>
            </>
        )}
    </span>
);

export default MTicketEcoFriendlyLabel;