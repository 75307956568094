import Translation from "@Translation/components/Translation";
import Notification from "@Features/notification/components/Notification";
import React from "react";

interface GetByTransferNoResultsNotificationProps {
    fromName: string,
    toName: string,
}

function GetByTransferNoResultsNotification({fromName, toName}: GetByTransferNoResultsNotificationProps) {
    return (
        <Notification
            notificationType="blue"
            additionalClass="no-results__gbt-notification"
        >
            <h2>
                <Translation translationKey="transfers_banner.no_results_title"/>
            </h2>
            <Translation
                translationKey="transfers_banner.no_results_notification"
                translationVariables={{
                    departureStation: fromName,
                    destinationStation: toName,
                }}
            />
        </Notification>
    )
}

export default GetByTransferNoResultsNotification