import {KeyboardEvent, MutableRefObject} from "react";
import filterLetterInput from "@Features/input/functions/filterLetterInput";
import moveThroughDateInput
    from "@CheckOrder/features/TicketAdditionalInfo/features/dateAdditionalField/functions/moveThroughDateInput";
import {
    DateInputFieldTypeType
} from "@CheckOrder/features/TicketAdditionalInfo/features/dateAdditionalField/types/DateInputFieldType.type";

interface OnKeyPressGetterDateInputAttributes {
    type: DateInputFieldTypeType,
    currentRef: MutableRefObject<HTMLInputElement>,
    currentValue: string,
}

interface GetOnKeyPressAttributes {
    previousRef?: MutableRefObject<HTMLInputElement>,
    nextRef?: MutableRefObject<HTMLInputElement>,
}

/*
 * returns getOnKeyPress function which receives previous input field ref and the next input field ref and
 * returns function to handle each key press, filtering out characters only allowing users to type numbers.
 * Also handles moving to previous (if exists) and next (if exists) input fields if user clicks right/left arrow keys
 */
export default function onKeyPressGetterDateInput({type, currentRef, currentValue}: OnKeyPressGetterDateInputAttributes) {
    const getOnKeyPress = (attr: GetOnKeyPressAttributes) => (event: KeyboardEvent<HTMLInputElement>) => {
        const {
            previousRef,
            nextRef,
        } = attr

        filterLetterInput(event, ["Tab"])
        moveThroughDateInput({
            event,
            currentType: type,
            currentValue,
            currentRef,
            nextRef,
            previousRef,
        })
    }

    return {
        getOnKeyPress,
    }
}