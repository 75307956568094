import React from 'react';
import IconMenu from "@Icons/IconMenu";
import IconTimes from "@Icons/IconTimes";
import {setFormVisibility} from "@Form/reducers/formSlice";
import CurrencySelect from "@Pages/searchResults/features/sorting/components/CurrencySelect";
import useIsDesktop from "@Hooks/selector/useIsDesktop";
import {activateMobileMenu} from "@/reducers/mobileSlice";
import useIsMobile from "@Hooks/selector/useIsMobile";
import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher";
import MainMenu from "./MainMenu";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import useRouteMatch from "@Routing/hooks/useRouteMatch";

function Navbar() {
    const isSearchResults = useRouteMatch('searchResults')
    const isDesktop = useIsDesktop()
    const isMobile = useIsMobile()
    const dispatch = useAppDispatch()
    const showMainMenu = !isSearchResults
    const openMobileMenu = () => dispatch(activateMobileMenu(true))
    const closeForm = () => dispatch(setFormVisibility(false))

    if (isDesktop) {
        return (
            <ul className="gb--menu gb--menu__nav gb--row gb--row-middle gb--row-end">
                <MainMenu/>
                {isSearchResults && (
                    <li className="gb--menu__item">
                        <CurrencySelect/>
                    </li>
                )}
                <LanguageSwitcher/>
            </ul>
        )
    }

    if (showMainMenu) {
        return (
            <IconMenu
                height="35"
                className="gb--menu-toggle"
                onClick={openMobileMenu}
            />
        )
    }

    if (isMobile) {
        return (
            <button type="submit" onClick={closeForm}>
                <IconTimes/>
            </button>
        )
    }

    return null
}

export default Navbar;