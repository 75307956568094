import {useEffect, useRef, useState} from "react";

/**
 * @returns {number}
 * @param expireTime {number}
 */
export default function useCountdown(expireTime:number = 20) {
    const interval = useRef(null)
    const [countdown, setCountdown] = useState<number>(expireTime > 0 ? expireTime : 0)
    const tick = () => setCountdown(countdown - 1)

    useEffect(() => {
        if (countdown > 0) {
            interval.current = setInterval(tick, 1000)
        }

        return () => clearInterval(interval.current)
    }, [countdown]);

    return countdown
}