import {changeCurrency} from "@SearchResults/reducers/searchResultsSlice";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import saveInLocalStorage from "@Currency/functions/saveInLocalStorage";

/**
 * Sets searchResults/currency redux state via dispatch
 */
export function useChangeCurrency() {
    const dispatch = useAppDispatch()
    return (currency) => {
        dispatch(changeCurrency(currency))
        saveInLocalStorage(currency.val)
    }
}