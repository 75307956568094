import {useAppSelector} from "@Hooks/selector/useAppSelector";
import useIsSameCompany from "@Route/features/company/hooks/useIsSameCompany";
import generateRoutePassengerGroups from "@Route/functions/generateRoutePassengerGroups";
import useSearchResults from "@SearchResults/hooks/useSearchResults";

/**
 * Returns all active passenger groups for provided route
 * @param route {object}
 * @returns {Object[]} passengerGroups
 */
export default function useRoutePassengerGroups(route) {
    const sameCompany = useIsSameCompany(route)
    const {data: {result: {passengerGroups}}} = useSearchResults()
    const {
        currency: {ratio: activeCurrencyRate},
    } = useAppSelector((state) => state?.searchResults)

    return generateRoutePassengerGroups({
        route,
        activeCurrencyRate,
        passengerGroups,
        sameCompany,
    })
}