import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconTicketAndPass(props: IconType) {
    const {
        width = '40',
        height = '40',
        viewBox = '0 0 40 40',
        title = 'IconTicketAndPass',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none" transform="translate(1)">
                    <g transform="matrix(.25881905 -.96592583 .96592583 .25881905 13.481488 30.184074)">
                        <path
                            d="m19.2993412 2.26606746v-.90642698c-.4494367 0-.7931236-.29458877-.7931236-.67982024l-1.9505269-.04440535-11.66474528-.30878916c-1.45405995 0-4.09782181 1.37292487-4.09782181 2.61926197v3.17249444c1.90349667 0 3.43686898 1.31431913 3.43686898 2.9458877 0 1.63156856-1.53337231 2.94588766-3.43686898 2.94588766v2.2660675c0 1.2463371 1.18968542 2.2660675 2.64374537 2.2660675h15.06934862c0-.3852315.3436869-.6798203.7931236-.6798203v-.906427c-.4494367 0-.7931236-.2945887-.7931236-.6798202s.3436869-.6798202.7931236-.6798202v-.906427c-.4494367 0-.7931236-.2945888-.7931236-.6798203 0-.3852314.3436869-.6798202.7931236-.6798202v-.906427c-.4494367 0-.7931236-.2945888-.7931236-.67982022 0-.38523147.3436869-.67982024.7931236-.67982024v-.90642698c-.4494367 0-.7931236-.29458877-.7931236-.67982024s.3436869-.67982024.7931236-.67982024v-.90642698c-.4494367 0-.7931236-.29458877-.7931236-.67982024s.3436869-.67982024.7931236-.67982024v-.90642698c-.4494367 0-.7931236-.29458877-.7931236-.67982024s.3436869-.67982024.7931236-.67982024z"
                            fill="#fff"
                        />
                        <path
                            d="m29.8743227 2.9458877c0-1.2463371-1.1896854-2.26606746-2.6437454-2.26606746h-7.1381125c0 .38523147-.3436869.67982024-.7931236.67982024v.90642698c.4494367 0 .7931236.29458877.7931236.67982024s-.3436869.67982024-.7931236.67982024v.90642698c.4494367 0 .7931236.29458877.7931236.67982024s-.3436869.67982024-.7931236.67982024v.90642698c.4494367 0 .7931236.29458877.7931236.67982024s-.3436869.67982024-.7931236.67982024v.90642698c.4494367 0 .7931236.29458877.7931236.67982024 0 .38523142-.3436869.67982022-.7931236.67982022v.906427c.4494367 0 .7931236.2945888.7931236.6798202 0 .3852315-.3436869.6798203-.7931236.6798203v.906427c.4494367 0 .7931236.2945887.7931236.6798202s-.3436869.6798202-.7931236.6798202v.906427c.4494367 0 .7931236.2945888.7931236.6798203h7.1381125c1.45406 0 2.6437454-1.0197304 2.6437454-2.2660675z"
                            fill="#7ed321"
                        />
                        <g fill="#4a4a4a">
                            <path
                                d="m27.2305773 17.2221127h-5.2874907c-.4494367 0-.7931236-.2945888-.7931236-.6798202 0-.3852315.3436869-.6798203.7931236-.6798203h5.2874907c1.0310607 0 1.8506218-.7024809 1.8506218-1.5862472v-11.3303373c0-.88376631-.8195611-1.58624722-1.8506218-1.58624722h-5.2874907c-.4494367 0-.7931236-.29458877-.7931236-.67982024s.3436869-.67982024.7931236-.67982024h5.2874907c1.9034967 0 3.436869 1.31431913 3.436869 2.9458877v11.3303373c0 1.6315686-1.5333723 2.9458877-3.436869 2.9458877z"
                            />
                            <path
                                d="m16.6555958 15.8624722h-13.21872682c-1.03106069 0-1.85062176-.7024809-1.85062176-1.5862472v-1.6542292c1.95637158-.3172495 3.43686898-1.7901933 3.43686898-3.55772596 0-1.76753262-1.4804974-3.24047647-3.43686898-3.55772591v-2.56065623c0-.88376631.81956107-1.58624722 1.85062176-1.58624722v-1.35964048c-1.90349667 0-3.43686898 1.31431913-3.43686898 2.9458877v3.17249444c0 .38523147.3436869.67982024.79312361.67982024 1.45405995 0 2.64374537 1.01973036 2.64374537 2.26606746 0 1.24633706-1.18968542 2.26606746-2.64374537 2.26606746-.44943671 0-.79312361.2945888-.79312361.6798202v2.2660675c0 1.6315686 1.53337231 2.9458877 3.43686898 2.9458877h13.21872682c.4494367 0 .7931236-.2945888.7931236-.6798202 0-.3852315-.3436869-.6798203-.7931236-.6798203z"
                            />
                        </g>
                        <g fill="#e6e6ef" transform="translate(6.609363 4.532135)">
                            <path
                                d="m8.72435972 1.35964048h-7.93123611c-.44943671 0-.79312361-.29458877-.79312361-.67982024s.3436869-.67982024.79312361-.67982024h7.93123611c.44943671 0 .79312361.29458877.79312361.67982024s-.3436869.67982024-.79312361.67982024z"
                            />
                            <path
                                d="m8.72435972 5.8917754h-7.93123611c-.44943671 0-.79312361-.29458877-.79312361-.67982024s.3436869-.67982024.79312361-.67982024h7.93123611c.44943671 0 .79312361.29458877.79312361.67982024s-.3436869.67982024-.79312361.67982024z"
                            />
                            <path
                                d="m6.08061435 8.15784286h-5.28749074c-.44943671 0-.79312361-.29458877-.79312361-.67982024s.3436869-.67982024.79312361-.67982024h5.28749074c.44943671 0 .79312361.29458877.79312361.67982024s-.3436869.67982024-.79312361.67982024z"
                            />
                            <path
                                d="m8.72435972 8.15784286c-.05287491 0-.10574981 0-.15862472-.02266068-.05287491 0-.10574982-.02266067-.15862472-.04532135-.05287491-.02266067-.07931236-.04532135-.13218727-.06798202-.05287491-.02266068-.07931236-.04532135-.13218727-.0906427-.13218727-.0906427-.21149963-.2719281-.21149963-.45321349 0-.1812854.07931236-.3625708.23793708-.47587417.29081199-.24926742.81956107-.24926742 1.11037306 0 .15862472.13596405.23793708.29458877.23793708.47587417 0 .18128539-.07931236.36257079-.23793708.47587416-.13218727.13596405-.3436869.20394608-.55518653.20394608z"
                            />
                            <path
                                d="m3.43686898 3.62570794h-2.64374537c-.44943671 0-.79312361-.29458877-.79312361-.67982024s.3436869-.67982024.79312361-.67982024h2.64374537c.44943671 0 .79312361.29458877.79312361.67982024s-.3436869.67982024-.79312361.67982024z"
                            />
                            <path
                                d="m8.72435972 3.62570794h-2.64374537c-.44943671 0-.79312361-.29458877-.79312361-.67982024s.3436869-.67982024.79312361-.67982024h2.64374537c.44943671 0 .79312361.29458877.79312361.67982024s-.3436869.67982024-.79312361.67982024z"
                            />
                        </g>
                    </g>
                    <g transform="matrix(.96592583 .25881905 -.25881905 .96592583 7.351593 8.390577)">
                        <path
                            d="m18.0093555 25.3768191h-17.19074843v-24.55821203h17.19074843c1.5007796 0 2.7286902 1.2279106 2.7286902 2.72869023v19.1008316c0 1.5007796-1.2279106 2.7286902-2.7286902 2.7286902z"
                            fill="#fff"
                        />
                        <path
                            d="m18.0093555 25.3768191h-17.19074843v-24.55821203h17.19074843c1.5007796 0 2.7286902 1.2279106 2.7286902 2.72869023v19.1008316c0 1.5007796-1.2279106 2.7286902-2.7286902 2.7286902z"
                            stroke="#4a4a4a"
                            strokeWidth="1.6"
                        />
                        <path
                            d="m18.0093555 26.1954262h-17.19074843c-.46387734 0-.81860707-.3547297-.81860707-.8186071v-24.55821203c0-.46387734.35472973-.81860707.81860707-.81860707h17.19074843c1.964657 0 3.5472973 1.58264033 3.5472973 3.5472973v19.1008316c0 1.964657-1.5826403 3.5472973-3.5472973 3.5472973zm-16.37214136-1.6372141h16.37214136c1.0641892 0 1.9100832-.845894 1.9100832-1.9100832v-19.1008316c0-1.06418919-.845894-1.91008316-1.9100832-1.91008316h-16.37214136z"
                        />
                        <g fill="#f5a623">
                            <path
                                d="m14.0527547 19.3737006h-6.54885657c-.46387734 0-.81860707-.3547297-.81860707-.818607 0-.4638774.35472973-.8186071.81860707-.8186071h6.54885657c.4638773 0 .818607.3547297.818607.8186071 0 .4638773-.3547297.818607-.818607.818607z"
                                fillOpacity=".5"
                            />
                            <circle cx="10.778326" cy="11.869803" r="4.093035"/>
                        </g>
                    </g>
                </g>
            )}
        />
    );
}

export default IconTicketAndPass;
