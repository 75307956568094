import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function GetByApsLogomark(props: IconType) {
    const {
        width = '40',
        height = '40',
        fill = '#fff',
        viewBox = '0 0 40 40',
        title = 'GetByApsLogomark',
        ...rest
    } = props
    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none" fillRule="evenodd" transform="translate(2 2)">
                    <g strokeDasharray="30.089963 5.338542" strokeLinecap="round" strokeWidth="2.42661">
                        <path
                            d="m16.9520224 0c9.3623435 0 16.9520225 7.58967896 16.9520225 16.9520224 0 9.3623435-7.589679 16.9520225-16.9520225 16.9520225-9.36234344 0-16.9520224-7.589679-16.9520224-16.9520225 0-9.36234344 7.58967896-16.9520224 16.9520224-16.9520224z"
                            stroke="#7ed321"
                        />
                        <path
                            d="m16.9520224 5.54793461c6.2983038 0 11.4040878 5.10578399 11.4040878 11.40408779s-5.105784 11.4040878-11.4040878 11.4040878-11.40408779-5.105784-11.40408779-11.4040878 5.10578399-11.40408779 11.40408779-11.40408779z"
                            stroke="#f5a623"
                            transform="matrix(.25881905 .96592583 -.96592583 .25881905 28.938912 -3.80988)"
                        />
                        <path
                            d="m16.9520224 11.0958692c3.2342641 0 5.8561532 2.6218891 5.8561532 5.8561532s-2.6218891 5.8561532-5.8561532 5.8561532-5.8561532-2.6218891-5.8561532-5.8561532 2.6218891-5.8561532 5.8561532-5.8561532z"
                            stroke="#4a79e2"
                        />
                    </g>
                    <rect
                        fill="#4a79e2"
                        height="1.715065"
                        rx=".857533"
                        stroke="#4a79e2"
                        strokeWidth="1.095888"
                        width="1.715065"
                        x="30.273915"
                        y="16.164353"
                    />
                </g>
            )}
        />

    );
}

export default GetByApsLogomark;
