import FilterSection from "@SearchResults/features/sidebar/components/FilterSection";
import React from "react";
import DepartureTimeFilter
    from "@SearchResults/features/sidebar/features/departureTimeFilters/components/DepartureTimeFilter";
import IconRisingSun from "@Icons/IconRisingSun";
import IconSun from "@Icons/IconSun";
import IconMoon from "@Icons/IconMoon";
import IconMoonStars from "@Icons/IconMoonStars";
import formatDate from "@DateTime/functions/formatDate";
import useLocale from "@Hooks/selector/useLocale";
import useCurrency from "@Currency/hooks/useCurrency";

function DepartureTimeFilters() {
    const locale = useLocale()
    const currency = useCurrency()
    const hourFormat = (locale === 'en-us' || currency?.val === 'USD') ? 'H aa' : 'HH:mm'
    const morning = formatDate(new Date(0, 0, 0, 5, 0, 0), hourFormat);
    const afternoon = formatDate(new Date(0, 0, 0, 12, 0, 0), hourFormat);
    const evening = formatDate(new Date(0, 0, 0, 19, 0, 0), hourFormat);
    const night = formatDate(new Date(0, 0, 0, 0, 0, 0), hourFormat);

    return (
        <FilterSection translationKey="search_results.filter_by.departure_time.label">
            <DepartureTimeFilter
                departureTime="morning"
                icon={<IconRisingSun height="18" width="18"/>}
                suffix={`${morning} - ${afternoon}`}
            />
            <DepartureTimeFilter
                departureTime="afternoon"
                icon={<IconSun height="18" width="18"/>}
                suffix={`${afternoon} - ${evening}`}
            />
            <DepartureTimeFilter
                departureTime="evening"
                icon={<IconMoon height="18" width="18"/>}
                suffix={`${evening} - ${night}`}
            />
            <DepartureTimeFilter
                departureTime="night"
                icon={<IconMoonStars height="18" width="18"/>}
                suffix={`${night} - ${morning}`}
            />
        </FilterSection>
    );
}

export default DepartureTimeFilters;