import intervalToDuration from "date-fns/intervalToDuration";
import addSeconds from "date-fns/addSeconds";

/**
 * Converts milliseconds to duration object (date-fns)
 */
export const convertMillisecondsToDuration = (value: number) => (
    intervalToDuration({
        start: new Date(),
        end: addSeconds(new Date(), value / 1000),
    })
)