import React, {ForwardedRef, ReactNode} from 'react';
import classNamesAggregator from "@Containers/functions/classNamesAggregator";
import useIsMobile from "@Hooks/selector/useIsMobile";

interface RowProps {
    className?: string,
    children: ReactNode,
    style?: object,
    reverse?: boolean,
    justify?: boolean,
    center?: boolean,
    wrap?: boolean,
    scrollRef?: ForwardedRef<HTMLDivElement>,
    gap?: boolean,
    onClick?: (any?) => (any),
    onlyDesktop?: boolean,
    onlyMobile?: boolean,
}

const Row = (props: RowProps) => {
    const isMobile = useIsMobile()

    const {
        className = '',
        children,
        style,
        reverse = false,
        justify = false,
        center = false,
        wrap = false,
        gap = false,
        onlyMobile = false,
        onlyDesktop = false,
        scrollRef,
        onClick,
    } = props
    const activeClasses = classNamesAggregator("gb--row", {reverse, justify, center, wrap, gap})

    if ((onlyDesktop && isMobile) || (onlyMobile && !isMobile)) {
        return (
            <>
                {children}
            </>
        )
    }

    return (
        <div className={`${activeClasses}${className}`} style={style} ref={scrollRef} onClick={onClick}>
            {children}
        </div>
    )
};

export default Row;