import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconTrainFilledTab(props: IconType) {
    const {
        width = '35',
        height = '35',
        // fill: '#fff',
        viewBox = '0 0 35 35',
        title = 'IconTrainFilledTab',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none">
                    <g fill="#b5b7d1" transform="translate(2 27.421429)">
                        <path
                            d="m27.5105594 4.48386302h-24.08093476c-.4992389 0-.88100981-.41635871-.88100981-.96082779s.38177091-.96082779.88100981-.96082779h24.08093476c.4992389 0 .8810098.41635871.8810098.96082779s-.3817709.96082779-.8810098.96082779z"
                        />
                        <path
                            d="m25.839479 1.92165558h-20.99363544c-.43523391 0-.76805984-.41635871-.76805984-.96082779s.33282593-.96082779.76805984-.96082779h20.99363544c.4352339 0 .7680598.41635871.7680598.96082779s-.3328259.96082779-.7680598.96082779z"
                        />
                        <path
                            d="m29.5407628 7.34170978h-28.49814764c-.59081526 0-1.04261516-.41635871-1.04261516-.96082779s.4517999-.96082779 1.04261516-.96082779h28.49814764c.5908152 0 1.0426151.41635871 1.0426151.96082779s-.4517999.96082779-1.0426151.96082779z"
                        />
                    </g>
                    <g transform="translate(6.114286 -.35)">
                        <path d="m18.4328188 1.8132545v-1.34515891h-14.06302497v1.34515891"/>
                        <path
                            d="m11.183386 31.8964923c-7.06518142 0-10.45249189-3.9201774-10.16193144-11.7605322 4.54572291 2.4340711 8.00567348 3.6511067 10.37985174 3.6511067 2.3741782 0 5.8341288-1.2170356 10.3798517-3.6511067 0 7.8403548-3.5325907 11.7605322-10.597772 11.7605322z"
                            fill="#777aaa"
                        />
                        <path
                            d="m1.02145456 3.67085489v4.80413895h20.75970344v-4.80413895c-5.6921767-3.10667652-15.06752669-3.10667652-20.75970344 0z"
                            fill="#b5b7d1"
                        />
                        <path
                            d="m21.781158 7.83444198v-4.16358709c-3.6162064-1.98571077-7.0315124-1.92165558-7.0315124-1.92165558v1.60137965c0 .54446908-.4352841.96082779-1.0045018.96082779h-2.3438375-2.3438375c-.56921767 0-1.00450178.41635871-1.00450178.96082779 0 .54446908.43528411.96082779 1.00450178.96082779h2.3438375v1.60137965z"
                            fill="#070c63"
                        />
                        <path
                            d="m20.7766562 21.7048534c-4.1515701 1.1444527-7.2795561 1.716679-9.383958 1.716679s-5.22664918-.5423524-9.36674186-1.6270571v-14.18834712c0-.77807065.4352841-1.37306585 1.00450178-1.37306585h16.74169638c.5692176 0 1.0045017.5949952 1.0045017 1.37306585z"
                            fill="#fff"
                        />
                        <path
                            d="m7.04846524 28.6523774c-.26786714 0-.53573428-.0960827-.70315125-.2882483-.20090035-.1921656-.30135053-.4163587-.30135053-.6725795 0-.2562207.10045018-.5124414.30135053-.6725794.36831732-.3523035 1.03798518-.3523035 1.4063025 0 .20090035.1921655.30135053.4163587.30135053.6725794 0 .2562208-.10045018.5124415-.30135053.6725795-.16741697.1921656-.43528411.2882483-.70315125.2882483z"
                            fill="#fff"
                        />
                        <path
                            d="m16.0889813 28.6523774c-.2678672 0-.5357343-.0960827-.7031513-.2882483-.2009003-.1921656-.3013505-.4163587-.3013505-.6725795 0-.2562207.1004502-.5124414.3013505-.6725794l.1339336-.1281104c.0669668-.0320276.1004502-.0640552.167417-.0960828.0669667-.0320276.1339335-.0320276.2009003-.0640551.3348339-.0640552.6696679.0320275.9040516.2562207.2009004.1921656.3013505.4163587.3013505.6725794 0 .2562208-.1004501.5124415-.3013505.6725795-.167417.2241932-.4352841.3202759-.7031512.3202759z"
                            fill="#fff"
                        />
                        <g fill="#070c63">
                            <path
                                d="m22.7856598 3.67085489c0-.35230352-.2009004-.67257945-.5022509-.83271742-.8705682-.48041389-1.8415866-.8967726-2.8460884-1.21704853v-.16752895c0-.54446908-.4352841-.96082779-1.0045017-.96082779h-14.06302497c-.56921768 0-1.00450179.41635871-1.00450179.96082779v.16752895c-1.00450178.35230352-1.97552016.73663464-2.84608837 1.21704853-.30135054.16013797-.50225089.4804139-.50225089.83271742l-.01695278 18.53045121c.70869367 7.4497497 4.51606009 11.0947278 11.4220993 10.9349344 6.9060391-.1597935 10.6938926-3.9702559 11.3635605-11.4313871zm-2.0090036 1.23766922v14.87496059c-4.159889 2.1210009-7.2850057 3.1815013-9.3753499 3.1815013-2.09034422 0-5.21546087-.8298875-9.37534996-2.4896623v-15.56679959c5.22340926-3.47579843 13.52729066-3.47579843 18.75069986 0zm-18.75069986 17.28906149c3.82250846 1.7693139 6.9545561 2.6539708 9.39614296 2.6539708 2.4415868 0 5.5597724-1.048901 9.3545569-3.146703-.3172515 6.3103466-3.4423682 9.5202463-9.3753499 9.6296994-5.93298176.109453-9.05809841-2.9362027-9.37534996-9.1369672z"
                            />
                            <path
                                d="m16.2953777 32.8616683.0430105-1.9729135c2.415488-.528345 3.6394103-.3776245 3.671767.4521617.0323568.8297861-1.2059024 1.3367034-3.7147775 1.5207518z"
                                transform="matrix(.64278761 .76604444 -.76604444 .64278761 30.784244 -2.574907)"
                            />
                            <path
                                d="m2.93840466 32.8616683.04301048-1.9729135c2.41548799-.528345 3.63941035-.3776245 3.67176706.4521617.03235672.8297861-1.20590246 1.3367034-3.71477754 1.5207518z"
                                transform="matrix(-.64278761 .76604444 .76604444 .64278761 -16.420763 7.657128)"
                            />
                        </g>
                    </g>
                </g>
            )}
        />

    );
}

export default IconTrainFilledTab;
