import {useAppSelector} from "@Hooks/selector/useAppSelector";

export default function useHasMultiplePossibleGroups() {
    const {passengerGroups} = useAppSelector((state) => state?.checkOrder?.purchasedRoute)

    const checkForDuplicates = () => {
        const groupSum = Object.values(passengerGroups).reduce((acc, group) => {
            if (group.isNormal) {
                acc.normal += 1
            }
            if (group.isPensioner) {
                acc.pensioner += 1
            }
            if (group.isChild) {
                acc.child += 1
            }
            if (group.isStudent) {
                acc.student += 1
            }

            return acc
        }, {
            normal: 0,
            pensioner: 0,
            child: 0,
            student: 0,
        })

        return Object.values(groupSum).some((group) => group > 1)
    }

    return {
        checkForDuplicates,
    }
}