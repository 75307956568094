import React from 'react';
import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconHome(props: IconType) {
    const {
        width = '20',
        height = '20',
        fill = '#9b9b9b',
        viewBox = '0 0 20 20',
        title = 'IconHome',
        ...rest
    } = props
    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <path
                    d="m1.39070061 9.6196868 8.60929939-8.00894854 8.6092994 8.00894854c.1697174.14914243.3702924.21625653.6017252.20134228.2314328-.01491424.4242934-.1043997.578582-.26845637.1542885-.16405668.2237183-.35794184.2082895-.58165548-.0154289-.22371365-.108002-.41014169-.2777194-.55928412l-8.6092994-8.00894855c-.308577-.26845637-.6788694-.40268456-1.1108773-.40268456-.43200785 0-.8023003.13422819-1.11087734.40268456l-8.60929939 8.00894855c-.16971737.14914243-.26229048.33557047-.27771934.55928412-.01542885.22371364.05400099.4175988.20828951.58165548.15428852.16405667.34714917.25354213.57858194.26845637.23143278.01491425.43200786-.05219985.60172523-.20134228zm15.20513359 10.3803132c.4628656 0 .8563013-.1565996 1.1803072-.4697987s.4860088-.6935123.4860088-1.1409396v-6.196868c0-.2237136-.0810015-.4138702-.2430044-.5704698-.1620029-.1565995-.3587208-.2348993-.5901536-.2348993s-.4281506.0782998-.5901536.2348993c-.1620029.1565996-.2430044.3467562-.2430044.5704698v6.196868h-3.3094887v-5.3691275c0-.4474273-.162003-.8277405-.4860089-1.1409396s-.7174416-.4697986-1.1803071-.4697986h-3.24005896c-.46286555 0-.85630128.1565995-1.18030717.4697986s-.48600884.6935123-.48600884 1.1409396v5.3691275h-3.30948874v-6.196868c0-.2237136-.08100147-.4138702-.24300442-.5704698-.16200294-.1565995-.35872081-.2348993-.59015359-.2348993-.23143277 0-.42815064.0782998-.59015358.2348993-.16200295.1565996-.24300442.3467562-.24300442.5704698v6.196868c0 .4474273.16200294.8277405.48600884 1.1409396.32400589.3131991.71744161.4697987 1.18030717.4697987zm-4.9758047-1.6107383h-3.24005896v-5.3691275h3.24005896z"
                />
            )}
        />

    );
}

export default IconHome;
