import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import {clearArrayFilter} from "@SearchResults/reducers/filterSlice";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {isEmpty} from "@Array/isEmpty";

export default function useResetTabSpecificFilters() {
    const dispatch = useAppDispatch()
    const {
        filterCompanies,
        filterDepartureStation,
        filterDestinationStation,
    } = useAppSelector((state) => state.filter)

    const resetTabSpecificFilters = () => {
        if (!isEmpty(filterDepartureStation)) {
            dispatch(clearArrayFilter('filterDepartureStation'))
        }
        if (!isEmpty(filterDestinationStation)) {
            dispatch(clearArrayFilter('filterDestinationStation'))
        }
        if (!isEmpty(filterCompanies)) {
            dispatch(clearArrayFilter('filterCompanies'))
        }
    }

    return {
        resetTabSpecificFilters,
    }
}