import UrlParamsType from "@Form/types/UrlParams.type";

export default function googleTagManagerSearchEvent(urlParams: UrlParamsType) {
    window.dataLayer.push({
        event: 'search_form',
        departure: urlParams.fromSlug,
        destination: urlParams.toSlug,
        date: urlParams.date,
        returnDate: urlParams.returnDate,
    });
}