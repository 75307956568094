import {useAppSelector} from "@Hooks/selector/useAppSelector";

interface MakeDescriptionAttributes {
    validCountLoading: boolean,
    archivedCountLoading: boolean,
    showAll?: boolean,
    loadingText?: string,
    allText: string,
    validText: string,
    archivedText: string,
}

export default function useMakeDescription() {
    const {showValid, showArchived} = useAppSelector((state) => state.userProfile.filter)

    function makeDescription(props: MakeDescriptionAttributes) {
        const {
            validCountLoading,
            archivedCountLoading,
            showAll = false,
            loadingText = "loading",
            allText,
            validText,
            archivedText,
        } = props

        const loading = () => {
            if ((showValid && showArchived) || showAll) {
                return validCountLoading || archivedCountLoading
            }
            if (showValid) {
                return validCountLoading
            }
            return archivedCountLoading
        }

        if (loading()) {
            return loadingText
        }
        if ((showValid && showArchived) || showAll) {
            return allText
        }
        if (showValid) {
            return validText
        }
        if (showArchived) {
            return archivedText
        }
        return null
    }

    return {makeDescription};
}