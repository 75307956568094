import Row from "@Containers/components/Row";
import SeatClass from "@Route/components/SeatClass/SeatClass";
import Column from "@Containers/components/Column";
import RouteTotalPriceContainer from "@Route/components/Price/RouteTotalPriceContainer";
import BuyButton from "@Route/features/ticketPurchase/components/BuyButton";
import ParamsType from "@SearchResults/types/params/Params.type";
import {PreparedRouteType} from "@Route/types/PreparedRoute.type";
import useIsMobile from "@Hooks/selector/useIsMobile";
import AdditionalInfoButton from "@Route/features/ticketPurchase/components/AdditionalInfoButton";
import useShowApiFullIntegrationRouteDebugPopover
    from "@Features/debug/hooks/useShowApiFullIntegrationRouteDebugPopover";
import DebugApiRouteInfo from "@Features/debug/components/DebugApiRouteInfo";

type PassengerClassProps = {
    passengerClass: PreparedRouteType,
    params: ParamsType,
    isReturn: boolean
    displayImages?: boolean,
    passengerClassIndex: number
}

function PassengerClass({passengerClass, params, displayImages, isReturn, passengerClassIndex}: PassengerClassProps) {
    const isMobile = useIsMobile()
    const showDebug = useShowApiFullIntegrationRouteDebugPopover(passengerClass)

    return (
        <Row justify>
            {showDebug && (
                <DebugApiRouteInfo
                    route={passengerClass}
                />
            )}
            <SeatClass
                displayImages={displayImages}
                seatClass={passengerClass.travelInfo.seatClass}
            />
            <Column className="gb--price_per_class">
                <RouteTotalPriceContainer
                    route={passengerClass}
                    totalPrice={passengerClass.prices.price}
                />
                {isMobile ? (
                    <AdditionalInfoButton
                        passengerClassIndex={passengerClassIndex}
                        isReturn={isReturn}
                        route={passengerClass}
                        params={params}
                    />
                ) : (
                    <BuyButton
                        route={passengerClass}
                        params={params}
                    />
                )}
            </Column>
        </Row>
    );
}

export default PassengerClass;