import {setShowArchivedFilter, setShowValidFilter} from "@MyProfile/reducers/userProfileSlice";
import {isTicketsPage, isVouchersPage} from "@MyProfile/functions/isActivePage";
import useMakeDescription from "@MyProfile/hooks/useMakeDescription";
import {useTranslation} from "@Translation/hooks/useTranslation";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import useCountValidTickets from "@MyProfile/features/tickets/hooks/useCountValidTickets";
import useCountArchivedTickets from "@MyProfile/features/tickets/hooks/useCountArchivedTickets";
import useCountValidVouchers from "@MyProfile/features/vouchers/hooks/useCountValidVouchers";
import useCountArchivedVouchers from "@MyProfile/features/vouchers/hooks/useCountArchivedVouchers";

export default function useAvatarSection() {
    const dispatch = useAppDispatch()

    const user = useAppSelector((state) => state.userProfile.user)
    const {showValid, showArchived} = useAppSelector((state) => state.userProfile.filter)

    const {makeDescription} = useMakeDescription()

    const setFiltersTrue = () => {
        dispatch(setShowValidFilter(true))
        dispatch(setShowArchivedFilter(true))
    }

    const {
        validTicketsCount,
        validTicketsCountLoading,
    } = useCountValidTickets()

    const {
        archivedTicketsCount,
        archivedTicketsCountLoading,
    } = useCountArchivedTickets()

    const {
        validVouchersCount,
        validVouchersCountLoading,
    } = useCountValidVouchers()

    const {
        archivedVouchersCount,
        archivedVouchersCountLoading,
    } = useCountArchivedVouchers()

    const {
        show_all_vouchers: showAllVouchersLabel,
        show_all_tickets: showAllTicketsLabel,
    } = useTranslation('my_profile.avatar_section')
    const showValidTicketsLabel = useTranslation(
        'my_profile.avatar_section.show_valid_tickets',
        {count: validTicketsCount}
    )
    const showArchivedTicketsLabel = useTranslation(
        'my_profile.avatar_section.show_archived_tickets',
        {count: archivedTicketsCount}
    )
    const showValidVouchersLabel = useTranslation(
        'my_profile.avatar_section.show_valid_vouchers',
        {count: validVouchersCount}
    )
    const showArchivedVouchersLabel = useTranslation(
        'my_profile.avatar_section.show_archived_vouchers',
        {count: archivedVouchersCount}
    )

    const descriptionText = () => {
        if (isTicketsPage()) {
            return makeDescription({
                validCountLoading: validTicketsCountLoading,
                archivedCountLoading: archivedTicketsCountLoading,
                allText: showAllTicketsLabel,
                validText: showValidTicketsLabel,
                archivedText: showArchivedTicketsLabel,
            })
        }
        if (isVouchersPage()) {
            return makeDescription({
                validCountLoading: validVouchersCountLoading,
                archivedCountLoading: archivedVouchersCountLoading,
                allText: showAllVouchersLabel,
                validText: showValidVouchersLabel,
                archivedText: showArchivedVouchersLabel,
            })
        }
        return ""
    }

    return {
        realName: user?.realName,
        username: user?.username,
        showMoreButton: !showValid || !showArchived,
        setFiltersTrue,
        descriptionText,
    }
}