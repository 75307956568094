import {useTranslation} from "@Translation/hooks/useTranslation";
import getRouteTransferType from "@Route/features/transportType/functions/getRouteTransferType";
import {capitalize} from "@String/capitalize";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";

export default function useTripTitle() {
    const tripLabel = useTranslation("sys.trip")
    const generateTripTitle = ({travelInfo, type}: RouteWithAddedPropsAndPricesType): string => {
        const {
            departureStation: {
                cityName: departure,
            },
            destinationStation: {
                cityName: destination,
            },
        } = travelInfo

        const transportType = getRouteTransferType(type?.routeTransferType ?? 1)

        return `${capitalize(transportType)} ${tripLabel.toLowerCase()} ${departure} - ${destination}`
    }

    return {
        generateTripTitle,
    }
}