import React from 'react';
import Translation from "@Translation/components/Translation";
import IconLocationMarker from "@Icons/IconLocationMarker";
import StationSwitchButton from "@Form/components/StationSwitchButton";
import useMobileStationInput from "@Form/hooks/useMobileStationInput";
import FormInputError from "@Form/components/FormInputError";

interface MobileStationInputButtonProps {
    direction: "departure" | "destination"
}

function MobileStationInputButton({direction}: MobileStationInputButtonProps) {
    const {
        className,
        onClick,
        directionLabel,
        placeholder,
        station,
        isDeparture,
    } = useMobileStationInput(direction)

    return (
        <div
            className={className}
            onClick={onClick}
            role="button"
        >
            <span className="search__select--label gb--emphasize gb--emphasize-default">
                <Translation translationKey={directionLabel}/>
            </span>
            <IconLocationMarker className="search__select--icon" height="14"/>
            <input
                placeholder={placeholder}
                readOnly
                type="text"
                value={station?.label ?? ''}
            />
            <FormInputError
                direction={direction}
            />
            {isDeparture && (
                <StationSwitchButton/>
            )}
        </div>
    );
}

export default MobileStationInputButton;