import React from 'react';
import HeroVectorLarge from "@Form/components/HeroVectorLarge";
import HeroVectorSmall from "@Form/components/HeroVectorSmall";
import useRouteMatch from "@Routing/hooks/useRouteMatch";
import {useAppSelector} from "@Hooks/selector/useAppSelector";

function HeroBackgroundMain() {
    const isHomepage = useRouteMatch('homepage')
    const {is404Page} = useAppSelector((state) => (state.page))
    if (isHomepage && !is404Page) {
        return (
            <HeroVectorLarge/>
        )
    }

    return (
        <HeroVectorSmall/>
    )
}

export default HeroBackgroundMain
