import React, {ReactNode} from 'react';

interface RadioFieldProps {
    label: string | ReactNode,
    labelClassName?: string,
    value: string | number,
    // Value to check if the field is selected
    comparisonValue: string | number,
    onClick: (any?)=>any,
    required: boolean,
    passengerId: number,
    disabled?: boolean,
}

function RadioField(props: RadioFieldProps) {
    const {
        label,
        labelClassName = "",
        value,
        comparisonValue,
        onClick,
        required,
        passengerId,
        disabled = false,
    } = props

    return (
        <div className="md-radio md-radio-inline">
            <input
                name={`${passengerId}`}
                type="radio"
                checked={value === comparisonValue}
                required={required}
                // React error silence as label is doing onclick
                onChange={() => null}
                onClick={!disabled ? onClick : null}
                value={value}
                disabled={disabled}
            />
            <label
                onClick={!disabled ? onClick : null}
                className={`gb-card--label ${labelClassName}`}
                htmlFor={`${label}`}
                aria-disabled={disabled}
            >
                {label}
            </label>
        </div>
    );
}

export default RadioField;