import {
    numberOfDigits as dayNumberOfDigits
} from "@CheckOrder/features/TicketAdditionalInfo/features/dateAdditionalField/constants/dayDigitsConstraints";
import {
    numberOfDigits as monthNumberOfDigits
} from "@CheckOrder/features/TicketAdditionalInfo/features/dateAdditionalField/constants/monthDigitsConstraints";
import {
    numberOfDigits as yearNumberOfDigits
} from "@CheckOrder/features/TicketAdditionalInfo/features/dateAdditionalField/constants/yearDigitsConstraints";
import {
    DateInputFieldTypeType
} from "@CheckOrder/features/TicketAdditionalInfo/features/dateAdditionalField/types/DateInputFieldType.type";

export default function getFieldLengthPerDateInputType(type: DateInputFieldTypeType) {
    const fieldLengths = {
        day: dayNumberOfDigits,
        month: monthNumberOfDigits,
        year: yearNumberOfDigits,
    }

    return fieldLengths[type]
}