import React, {useContext} from 'react';
import IconDownload from "@Icons/IconDownload";
import IconPen from "@Icons/IconPen";
import {useTranslation} from "@Translation/hooks/useTranslation";
import TicketType from "@MyTicket/types/Ticket.type";
import TicketStatusType from "@MyTicket/types/TicketStatus.type";
import TicketsContext from "@MyTickets/context/functions/TicketsContext";
import prepareTicketModalData from "@MyTicket/features/ticketDetails/functions/prepareTicketModalData";
import TicketActionButton from "@MyTicket/features/ticketDetails/components/TicketActionButton";

interface TicketActionsSectionProps {
    ticket: TicketType,
    status: TicketStatusType,
}

function TicketActionsSection({ticket, status}: TicketActionsSectionProps) {
    const {
        setIsDownloadDocumentsModalOpen,
        setIsManageBookingModalOpen,
        setModalData,
    } = useContext(TicketsContext)

    const {
        isPastDeparture,
        isIntermodal,
        isRebookable,
        isCancelable,
        isCancelled,
    } = status

    const {
        download_documents: downloadDocumentsLabel,
        standard_download_options: standardDownloadOptionsLabel,
        storno_download_options: stornoDownloadOptionsLabel,
        manage_booking: manageBookingLabel,
        booking_options: bookingOptionsLabel,
    } = useTranslation('my_profile.my_tickets')

    const openDownloadDocumentsModal = () => {
        setModalData(prepareTicketModalData(ticket))
        setIsDownloadDocumentsModalOpen(true)
    }

    const openManageBookingsModal = () => {
        setModalData(prepareTicketModalData(ticket))
        setIsManageBookingModalOpen(true)
    }

    return (
        <div className="ticket-actions-section">
            <TicketActionButton
                mainLabel={downloadDocumentsLabel}
                descriptionLabel={isCancelled ? stornoDownloadOptionsLabel : standardDownloadOptionsLabel}
                notificationColor="green"
                notificationIcon={<IconDownload fill="#7ed321"/>}
                onClick={openDownloadDocumentsModal}
            />
            {!isPastDeparture && !isIntermodal && (isRebookable || isCancelable) && (
                <TicketActionButton
                    mainLabel={manageBookingLabel}
                    descriptionLabel={bookingOptionsLabel}
                    notificationColor="blue"
                    notificationIcon={<IconPen fill="#4a79e2"/>}
                    onClick={openManageBookingsModal}
                />
            )}
        </div>
    );
}

export default TicketActionsSection;