import React, {useEffect} from 'react';
import useCurrency from "@Currency/hooks/useCurrency";
import {useTranslation} from "@Translation/hooks/useTranslation";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import {setFilterPrice} from "@SearchResults/reducers/filterSlice";
import FilterSlider from "@SearchResults/features/sidebar/components/FilterSlider";
import useFilterSliderValue from "@SearchResults/features/sidebar/hooks/useFilterSliderValue";
import FilterSliderLabels from "@SearchResults/features/sidebar/components/FilterSliderLabels";
import FilterSection from "@SearchResults/features/sidebar/components/FilterSection";
import useDispatchFilterEvents from "@SearchResults/features/sidebar/hooks/useDispatchFilterEvent";

function PriceFilter() {
    const dispatch = useAppDispatch()
    const {sign} = useCurrency()
    const {
        value,
        updateValue,
        max,
        min,
        setValueState,
    } = useFilterSliderValue({
        onChange: setFilterPrice,
        boundValue: "Price",
    })

    useEffect(() => {
        dispatch(setFilterPrice(null))
    }, [sign])

    const displayValue = value ? `${value} ${sign}` : `${max} ${sign}`

    const handleOnBlur = useDispatchFilterEvents('PriceFilter', String(displayValue))

    return (
        <FilterSection translationKey="search_results.filter_by.price.label">
            <FilterSlider
                onChange={setValueState}
                value={value}
                min={min}
                max={max}
                onRelease={updateValue}
                onBlur={handleOnBlur}
            >
                <FilterSliderLabels
                    label={useTranslation("search_results.filters.less_than")}
                    value={displayValue}
                />
            </FilterSlider>
        </FilterSection>
    );
}

export default PriceFilter;