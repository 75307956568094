import isAfter from "date-fns/isAfter";
import getDateTimeStringFromStation from "@Route/functions/getDateTimeStringFromStation";
import ParamsType from "@SearchResults/types/params/Params.type";
import RouteType from "@Route/types/Route.type";

/**
 * Returns past & upcoming departure arrays
 */
export default function useFilterBySaleBlock(routes: RouteType[], params: ParamsType) {
    const {
        showUpcomingDeparturesHeader,
        showPastDeparturesHeader,
    } = params

    const showSingleUpcomingDeparturesFilter = (route: RouteType) => (
        isAfter(new Date(getDateTimeStringFromStation(route.travelInfo.departureStation)), new Date())
    );

    const showSinglePastDeparturesFilter = (route: RouteType) => (
        !showSingleUpcomingDeparturesFilter(route)
    );

    let upcomingDepartures = routes
    let pastDepartures: RouteType[] = []

    if (showUpcomingDeparturesHeader) {
        upcomingDepartures = routes.filter((route) => showSingleUpcomingDeparturesFilter(route))
    }

    if (showPastDeparturesHeader) {
        pastDepartures = routes.filter((route) => showSinglePastDeparturesFilter(route))
    }

    return {
        pastDepartures,
        upcomingDepartures,
    }
}