import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconTrainPlot(props: IconType) {
    const {
        width = '60',
        height = '85',
        viewBox = '0 0 60 85',
        title = 'IconBusPlot',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g transform="translate(0.000000, 12.000000)" fillRule="nonzero">
                        <g id="tracks" transform="translate(4.700275, 55.558723)" fill="#B5B7D1">
                            <path
                                d="M47.2720608,9.69996713 L1.90004982,9.69996713 C0.959410565,9.69996713 0.240098196,8.88843523 0.240098196,7.8272012 C0.240098196,6.76596717 0.959410565,5.95443527 1.90004982,5.95443527 L47.2720608,5.95443527 C48.2127,5.95443527 48.9320124,6.76596717 48.9320124,7.8272012 C48.9320124,8.88843523 48.2127,9.69996713 47.2720608,9.69996713 Z"
                                id="Path-Copy"
                            />
                            <path
                                d="M44.3635985,3.74553186 L4.80851206,3.74553186 C3.98846758,3.74553186 3.36137475,2.93399996 3.36137475,1.87276593 C3.36137475,0.811531904 3.98846758,0 4.80851206,0 L44.3635985,0 C45.183643,0 45.8107359,0.811531904 45.8107359,1.87276593 C45.8107359,2.93399996 45.183643,3.74553186 44.3635985,3.74553186 Z"
                                id="Path-Copy-2"
                            />
                        </g>
                        <g transform="translate(0.576236, 0.000000)">
                            <g transform="translate(4.604236, 0.192079)">
                                <polyline
                                    id="Path"
                                    points="34.3656484 4.49463823 34.3656484 1.87276593 8.14692533 1.87276593 8.14692533 4.49463823"
                                />
                                <path
                                    d="M20.8500021,63.1303932 C7.67786915,63.1303932 1.3626592,55.2808868 1.90437223,39.5818739 C10.379295,44.4556984 16.8299332,46.8926106 21.2562869,46.8926106 C25.6826405,46.8926106 32.1332787,44.4556984 40.6082015,39.5818739 C40.6082015,55.2808868 34.022135,63.1303932 20.8500021,63.1303932 Z"
                                    id="Path"
                                    fill="#F6C67A"
                                />
                                <path
                                    d="M1.90437223,8.11531904 L1.90437223,17.4791487 L40.6082015,17.4791487 L40.6082015,8.11531904 C29.9958612,2.06004252 12.5167125,2.06004252 1.90437223,8.11531904 Z"
                                    id="Path"
                                    fill="#B5B7D1"
                                />
                                <path
                                    d="M40.6082015,18.3664844 L40.6082015,8.11531904 C33.8662441,4.24493611 27.49884,4.36978717 27.49884,4.36978717 C27.49884,4.36978717 27.49884,6.4298297 27.49884,7.49106372 C27.49884,8.55229775 26.6873081,9.36382966 25.626074,9.36382966 L21.2562869,9.36382966 L16.8864997,9.36382966 C15.8252657,9.36382966 15.0137337,10.1753616 15.0137337,11.2365956 C15.0137337,12.2978296 15.8252657,13.1093615 16.8864997,13.1093615 L21.2562869,13.1093615 L40.6082015,18.3664844 Z"
                                    id="Path"
                                    fill="#070C63"
                                />
                                <path
                                    d="M38.8314748,43.8419306 C31.0701358,45.9841374 25.2223821,47.0552408 21.2882137,47.0552408 C17.3540452,47.0552408 11.5170201,45.9841374 3.77713816,43.8419306 L3.77713816,18.2474629 C3.77713816,15.0650012 6.35703314,12.4851062 9.53949487,12.4851062 L33.0691181,12.4851062 C36.2515798,12.4851062 38.8314748,15.0650012 38.8314748,18.2474629 L38.8314748,43.8419306 L38.8314748,43.8419306 Z"
                                    id="Path"
                                    fill="#FFF"
                                />
                                <path
                                    d="M40.6082015,19.3519146 L34.9899037,19.3519146 C33.9286697,19.3519146 32.8050101,20.332835 32.3368186,22.133742 C32.024691,23.3343466 28.643308,31.3159589 22.1926698,46.0785787 L42.4809674,46.2429126 L42.4809674,22.133742 C42.4809674,20.5573732 41.6694355,19.3519146 40.6082015,19.3519146 Z"
                                    id="Path"
                                    fill="#E9EEF4"
                                />
                                <path
                                    d="M37.2443498,39.3332425 L35.8921282,39.6206661 L22.4082295,49.4717308 L10.8664425,49.4717308 C9.42788077,49.4717308 8.0938612,50.3853285 8.09125161,51.6252064 C8.08891759,52.8650843 9.62755766,53.8962699 11.0661193,53.8962699 L19.0037365,53.8962699 C19.6932699,53.8962699 20.2522471,54.4552471 20.2522471,55.1447805 C20.2522471,55.2229939 20.2448976,55.3010342 20.2302955,55.3778724 L18.2612696,65.7391351 L18.2612696,65.7391351 C22.5142427,66.4050759 26.4772925,65.6474127 30.1504193,63.4661453 C33.823546,61.2848779 36.9535961,56.8415321 39.5405697,50.1361078 L37.2443498,39.3332425 Z"
                                    id="Path"
                                    fill="#F5A623"
                                    transform="translate(23.815911, 52.653008) rotate(12.000000) translate(-23.815911, -52.653008)"
                                />
                                <path
                                    d="M13.1409678,56.8072332 C12.6415636,56.8072332 12.1421593,56.6199567 11.8300317,56.2454035 C11.4554785,55.8708503 11.2682019,55.4338716 11.2682019,54.9344673 C11.2682019,54.4350631 11.4554785,53.9356588 11.8300317,53.6235312 C12.5167125,52.9368503 13.7652231,52.9368503 14.451904,53.6235312 C14.8264572,53.9980844 15.0137337,54.4350631 15.0137337,54.9344673 C15.0137337,55.4338716 14.8264572,55.9332758 14.451904,56.2454035 C14.1397763,56.6199567 13.6403721,56.8072332 13.1409678,56.8072332 Z"
                                    id="Path"
                                    fill="#FFF"
                                />
                                <path
                                    d="M29.9958612,56.8072332 C29.4964569,56.8072332 28.9970527,56.6199567 28.684925,56.2454035 C28.3103719,55.8708503 28.1230953,55.4338716 28.1230953,54.9344673 C28.1230953,54.4350631 28.3103719,53.9356588 28.684925,53.6235312 C28.7473506,53.5611056 28.8722016,53.4362546 28.9346272,53.373829 C29.0594782,53.3114035 29.1219038,53.248978 29.2467548,53.1865524 C29.3716059,53.1241269 29.4964569,53.1241269 29.621308,53.0617014 C30.2455633,52.9368503 30.8698186,53.1241269 31.3067973,53.5611056 C31.6813505,53.9356588 31.8686271,54.3726375 31.8686271,54.8720418 C31.8686271,55.371446 31.6813505,55.8708503 31.3067973,56.1829779 C30.9946697,56.6199567 30.4952654,56.8072332 29.9958612,56.8072332 Z"
                                    id="Path"
                                    fill="#FFF"
                                />
                                <path
                                    d="M42.4809674,8.11531904 C42.4809674,7.42863819 42.1064142,6.80438288 41.5445844,6.49225523 C39.9215206,5.55587226 38.1111802,4.74434036 36.2384143,4.12008505 L36.2384143,1.87276593 C36.2384143,0.811531904 35.4268824,0 34.3656484,0 L8.14692533,0 C7.08569131,0 6.2741594,0.811531904 6.2741594,1.87276593 L6.2741594,4.12008505 C4.40139347,4.80676589 2.59105307,5.55587226 0.967989263,6.49225523 C0.406159484,6.80438288 0.0316062976,7.42863819 0.0316062976,8.11531904 L-3.41060513e-13,44.2333394 C1.32126929,58.7537744 8.41962019,65.8582638 21.2950527,65.5468076 C34.1704852,65.2353514 41.2324568,57.8083139 42.4809674,43.265695 L42.4809674,8.11531904 Z M38.7354355,10.5276813 L38.7354355,39.5207223 C30.9798509,43.6548014 25.153468,45.721841 21.2562869,45.721841 C17.3591057,45.721841 11.5327228,44.1042931 3.77713816,40.8691974 L3.77713816,10.5276813 C13.515521,3.752943 28.9970527,3.752943 38.7354355,10.5276813 Z M3.77713816,44.2260878 C10.9037195,47.6746878 16.7430243,49.3989878 21.2950527,49.3989878 C25.8470811,49.3989878 31.660542,47.3545569 38.7354355,43.265695 C38.1439603,55.5652997 32.3175774,61.8217705 21.2562869,62.0351073 C10.1949963,62.2484441 4.36861336,56.3121042 3.77713816,44.2260878 Z"
                                    id="Shape"
                                    fill="#070C63"
                                />
                            </g>
                            <path
                                d="M49.6405709,68.664994 L35.3315892,68.1932959 C35.0903997,68.1932959 34.9059606,67.381764 34.9059606,66.32053 C34.9059606,65.259296 35.0903997,64.4477641 35.3315892,64.4477641 L49.6405709,64.9194622 C49.8817605,64.9194622 51.5968941,65.622491 51.5968941,66.683725 C51.5968941,67.744959 49.8817605,68.664994 49.6405709,68.664994 Z"
                                id="Path-Copy-3"
                                fill="#070C63"
                                transform="translate(43.251427, 66.556379) rotate(35.000000) translate(-43.251427, -66.556379)"
                            />
                            <path
                                d="M15.7840814,68.5422805 L0.137533249,67.8347333 C-0.103656303,67.8347333 0.68305141,63.5383428 0.924240962,63.5383428 L15.7840814,64.7967486 C16.0252709,64.7967486 17.6672342,65.6949441 17.6672342,66.7561781 C17.6672342,67.8174121 16.0252709,68.5422805 15.7840814,68.5422805 Z"
                                id="Path-Copy-4"
                                fill="#070C63"
                                transform="translate(8.879680, 66.040312) scale(-1, 1) rotate(35.000000) translate(-8.879680, -66.040312)"
                            />
                        </g>
                    </g>
                </g>
            )}
        />

    );
}

export default IconTrainPlot;
