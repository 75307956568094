import {setFormVisibility} from "@Form/reducers/formSlice";
import useForm from "@Form/hooks/useForm";
import useIsMobile from "@Hooks/selector/useIsMobile";
import useIsSmallTablet from "@Hooks/selector/useIsSmallTablet";
import useDismissAllOverlays from "@MobileOverlays/hooks/useDismissAllOverlays";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import UrlParamsType from "@Form/types/UrlParams.type";
import toggleStaticMainContentElements from "@Features/domElements/functions/toggleStaticMainContentElements";
import useClearAllFilters from "@SearchResults/features/sidebar/hooks/useClearAllFilters";
import useIsClientsideRoutingSupported from "@Routing/hooks/useIsClientsideRoutingSupported";
import useNavigateWithStoredState from "@Routing/hooks/useNavigateWithStoredState";
import useGenerateSearchUrl from "@SearchResults/hooks/useGenerateSearchUrl";
import {useLocation} from "react-router";

/**
 * * Redirects to new url via react router or regular location.href depending on location
 * * Pushes new state from url params & passengers
 * * Sets search results loading
 * * By default unselects all selected routes - pass clear boolean to override
 */
export default function usePushNewUrl() {
    const dismissAllOverlays = useDismissAllOverlays()
    const {navigateWithStoredState} = useNavigateWithStoredState()
    const {parseInitialParamsAndGenerateSearchUrl} = useGenerateSearchUrl()
    const dispatch = useAppDispatch()
    const isMobile = useIsMobile()
    const isSmallTablet = useIsSmallTablet()
    const reloadOnSearch = useForm("reloadOnSearch")
    const {clearFilters} = useClearAllFilters()
    const isClientSideRoutingSupported = useIsClientsideRoutingSupported(window.currentRoute)
    const {state: oldState} = useLocation()

    const {
        singleConfirmedRoute,
    } = oldState ?? {}

    return (urlParams: UrlParamsType) => {
        const url = parseInitialParamsAndGenerateSearchUrl(urlParams, true)
        dismissAllOverlays()
        document.getElementsByTagName('body')[0].classList.remove('has-overlay');
        toggleStaticMainContentElements(false)

        if (!reloadOnSearch) {
            const {pathname, search} = window.location;

            // if departure or destination station in search changes, remove singleConfirmedRoute (outbound route) from state, so it isn't preselected
            let newState = {}
            if (singleConfirmedRoute && singleConfirmedRoute.travelInfo.departureStation.id === urlParams.fromId && singleConfirmedRoute.travelInfo.destinationStation.id === urlParams.toId) {
                newState = {singleConfirmedRoute}
            } else {
                newState = {singleConfirmedRoute: null}
            }

            if (`${pathname}${search}#/` !== url) {
                if (isClientSideRoutingSupported) {
                    navigateWithStoredState(url, newState)
                } else {
                    window.location.href = url
                }
                clearFilters()
                if (isMobile || isSmallTablet) {
                    dispatch(setFormVisibility(false))
                }
            }
        } else {
            window.location.href = url
        }
    }
}
