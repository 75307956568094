import React, {useState} from 'react';
import ImageLabelField from "@Generic/ImageLabelField";
import assetUrlGenerator from "@Images/functions/assetUrlGenerator";
import Autocomplete from "@Generic/Autocomplete";
import filterByKeys from "@Array/filterByKeys";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import countryData from "@CheckOrder/data/countryData.json"
import {useTranslation} from "@Translation/hooks/useTranslation";
import PhoneCountryType from "@CheckOrder/types/PhoneCountry.type";

interface CountrySelectProps {
    handler?: (any?) => any,
    setPhoneCountryObject?: (value: PhoneCountryType) => void,
}

function CountrySelect({handler, setPhoneCountryObject}: CountrySelectProps) {
    const [filteredCountries, setFilteredCountries] = useState(
        filterByKeys(countryData, '', ['isoCode', 'dialCode', 'name'])
    )

    const assetsBaseUrl = useAppSelector((state) => state?.page.assetsBaseUrl)

    const updateValue = (value) => {
        setFilteredCountries(filterByKeys(countryData, value, ['isoCode', 'dialCode', 'name']))
    }

    const handleCountrySelect = (country) => () => {
        setPhoneCountryObject(country)
    }

    const renderCountries = () => filteredCountries.map((country, index) => {
        const {isoCode, name} = country

        return (
            // TODO change this into TS functional component
            <ImageLabelField
                imageHeight={50}
                imageUrl={assetUrlGenerator(assetsBaseUrl, `country_flags/${isoCode.toLowerCase()}.png`)}
                label={name}
                key={isoCode}
                index={index}
                onClickHandler={handler ? handler(country) : handleCountrySelect(country)}
            />
        )
    })

    return (
        <Autocomplete
            className="gb--country-select-container"
            suggestions={renderCountries()}
            onChange={updateValue}
            placeholder={useTranslation("check_order.buyer_info_section.select_country")}
        />
    );
}

export default CountrySelect