import React from 'react';
import TicketInfo from "@CheckOrder/components/Sidebar/TicketInfo";
import RouteType from "@Route/types/Route.type";
import CompanyType from "@SearchResults/types/company/Company.type";

interface PurchaseRouteInfoProps {
    isIntermodal?: boolean,
    singleRoute: RouteType,
    returnRoute?: RouteType,
    companies: Record<number, CompanyType>,
}

const PurchaseRouteInfo = ({singleRoute, returnRoute, companies, isIntermodal}: PurchaseRouteInfoProps) => (
    <>
        <TicketInfo
            isIntermodal={isIntermodal}
            company={companies[singleRoute.companyId]}
            route={singleRoute}
        />
        {returnRoute && (
            <TicketInfo
                company={companies[returnRoute.companyId]}
                route={returnRoute}
                isReturn
            />
        )}
    </>
);

export default PurchaseRouteInfo