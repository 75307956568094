import {useLocation} from "react-router";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";

/**
 * Returns singleSelectedRoute object from routes state
 */
export default function useSingleSelectedRoute(): RouteWithAddedPropsAndPricesType|null {
    const {state} = useLocation()

    return state?.route ?? null
}