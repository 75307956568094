import SearchResultsRequestType from "@SearchResults/api/types/SearchResultsRequest.type";
import {ThunkDispatch} from "@reduxjs/toolkit";
import ApiIntegrationResponseType from "@SearchResults/api/types/ApiIntegrationResponse.type";
import updateSearchResults from "@SearchResults/api/functions/updateSearchResults";
import addApiRoutes from "@SearchResults/api/functions/addApiRoutes";
import {SearchResultsRtkQueryResponseType} from "@SearchResults/api/types/SearchResultsRtkQueryResponse.type";

export default function updateSearchResultsWithApiRoutes(
    dispatch: ThunkDispatch<any, any, any>,
    searchResultsApi: any,
    searchParams: SearchResultsRequestType,
    updatedApiResponse: ApiIntegrationResponseType,
    isReturn: boolean
) {
    const updateRecipe = ({result, suggestionData, locale, token, routeTranslations, urls}: SearchResultsRtkQueryResponseType): SearchResultsRtkQueryResponseType => <SearchResultsRtkQueryResponseType>({
        result: {
            ...result,
            resolvedApiCount: !isReturn ? result.resolvedApiCount + 1 : result.resolvedApiCount,
            resolvedReturnApiCount: isReturn ? result.resolvedReturnApiCount + 1 : result.resolvedReturnApiCount,
            singleRoutes: !isReturn ? addApiRoutes(result.singleRoutes, updatedApiResponse.apiRoutes) : result.singleRoutes,
            returnRoutes: isReturn ? addApiRoutes(result.returnRoutes, updatedApiResponse.apiRoutes) : result.returnRoutes,
            companies: {
                ...result.companies,
                ...updatedApiResponse.companies,
            },
            passengerGroups: {
                ...result.passengerGroups,
                ...updatedApiResponse.passengerGroups,
            },
            routeIcons: {
                ...result.routeIcons,
                ...updatedApiResponse.routeIcons,
            },
            intermodalRoutes: [...result.intermodalRoutes, ...updatedApiResponse.intermodalRoutes],
            availableReturnApiProviders: isReturn ? [] : result.availableReturnApiProviders,
            availableSingleApiProviders: !isReturn ? [] : result.availableSingleApiProviders,
        },
        suggestionData,
        locale,
        token,
        routeTranslations,
        urls,
    })

    // @ts-ignore
    updateSearchResults(dispatch, searchResultsApi, searchParams, updateRecipe)
}