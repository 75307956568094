import {setFilteredRoutesCount, setTotalRoutesCount} from "@SearchResults/reducers/filterSlice";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";

/**
 * Sets number of total and filtered routes
 */
export default function useSetRouteCounts() {
    const dispatch = useAppDispatch()

    const setRouteCounts = (totalRoutesCount: number, filteredRoutesCount: number) => {
        dispatch(setTotalRoutesCount(totalRoutesCount))
        dispatch(setFilteredRoutesCount(filteredRoutesCount))
    }

    return {
        setRouteCounts,
    }
}