import LoadMoreButton from "@Generic/Loaders/LoadMoreButton";
import React from "react";
import hasMoreItems from "@Features/lazyLoading/functions/hasMoreItems";

interface LazyLoadingLoadMoreProps {
    totalDataCount: number,
    dataPerBatch: number,
    batchLoading: boolean,
    page: number,
    setPage: (any) => void,
}

function LazyLoadingLoadMoreButton(props: LazyLoadingLoadMoreProps) {
    const {
        totalDataCount,
        dataPerBatch,
        batchLoading,
        page,
        setPage,
    } = props

    if (hasMoreItems({totalData: totalDataCount, dataPerBatch, page}) && !batchLoading) {
        return (
            <LoadMoreButton
                onClick={() => {
                    setPage(page + 1)
                }}
            />
        );
    }

    return null
}

export default LazyLoadingLoadMoreButton