import {useTranslation} from "@Translation/hooks/useTranslation";

interface VoucherStationRestrictionProps {
    stations: string[],
    titleKey: string,
}

function VoucherStationRestriction({stations, titleKey}: VoucherStationRestrictionProps) {
    const titleLabel = useTranslation(titleKey)

    if (stations.length !== 0) {
        return (
            <>
                {`${titleLabel}: `}
                <span className="gb--emphasize-info">
                    {stations.join(", ")}
                </span>
                .&nbsp;
            </>
        )
    }
    return null;
}

export default VoucherStationRestriction