import PaymentRedirectEventModel from "@Events/models/PaymentRedirectEventModel";
import useSingleTrackingEvent from "@Events/hooks/useSingleTrackingEvent";
import useHandleError from "@Errors/hooks/useHandleError";
import {useAppSelector} from "@Hooks/selector/useAppSelector";

/**
 * Dispatches event when user goes to corvus
 * @param tripReviewAccepted {boolean}
 * @param newsletterAccepted {boolean}
 * @param privacyPolicyAccepted {boolean}
 */

interface UseCheckOrderSubmissionEventAttributes {
    tripReviewAccepted: boolean,
    newsletterAccepted: boolean,
    privacyPolicyAccepted: boolean,
}

export default function useCheckOrderSubmissionEvent(params: UseCheckOrderSubmissionEventAttributes) {
    const {
        email,
        ticket,
    } = useAppSelector((state) => state?.checkOrder)

    const {setError} = useHandleError()
    const paymentModel = new PaymentRedirectEventModel({ticket, email, terms: params})
    const paymentEvent = useSingleTrackingEvent("payment_redirect", paymentModel)
    const dispatchRedirectToPaymentEvent = () => {
        try {
            return paymentEvent()
        } catch (error) {
            setError("Check order submission event failed")
        }
    }

    return {
        dispatchRedirectToPaymentEvent,
    }
}