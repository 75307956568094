import {LocaleType} from "@/types/Locale.type";
import {mappedLocales} from "@Features/locale/constants/mappedLocales";

/**
 * check if locale is valid (mapped)
 *
 * used for Locale protector route wrapper
 */
export default function validateLocale(locale: string): locale is LocaleType {
    // @ts-ignore
    return mappedLocales.includes(locale)
}