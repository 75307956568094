import {useTranslation} from "@Translation/hooks/useTranslation";

interface VoucherCompanyAndCompanyStoreRestrictionsProps {
    companies: string[],
    companyStores: string[],
}

function VoucherCompanyAndCompanyStoreRestrictions(props: VoucherCompanyAndCompanyStoreRestrictionsProps) {
    const {
        companies,
        companyStores,
    } = props
    const tripsOperatedByLabel = useTranslation('my_profile.my_vouchers.trips_operated_by')

    if (companies.length !== 0 || companyStores.length !== 0) {
        return (
            <>
                {`${tripsOperatedByLabel}: `}
                <span className="gb--emphasize-info">
                    {companies.join(", ")}
                </span>
                <span className="gb--emphasize-info">
                    {companyStores.length !== 0 && `(${companyStores.join(", ")})`}
                </span>
                .&nbsp;
            </>
        );
    }

    return null;
}

export default VoucherCompanyAndCompanyStoreRestrictions