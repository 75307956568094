import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconHighway(props: IconType) {
    const {
        width = '35',
        height = '35',
        viewBox = '0 0 35 35',
        title = 'IconHighway',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none" fillRule="evenodd" transform="translate(1 3.002091)">
                    <path
                        d="m2.50994719 0c3.76461037 1.33193934 8.26129461 1.33193934 13.49005281 0 6.6209938 1.33193934 11.3987716 1.33193934 14.3333333 0 1.8285342 5.91846846 2.8170333 13.5285924 0 18.9134766-1.8780221 3.5899229-6.5692633 7.618067-14.0737235 12.0844324-6.82647697-3.5351115-11.40969782-7.5632557-13.74966261-12.0844324-3.50994719-6.781765-1.9512761-13.53782971 0-18.9134766z"
                        fill="#62cdfc"
                        stroke="#fff"
                    />
                    <g transform="translate(0 5.997909)">
                        <g fill="#6b94a9">
                            <path d="m3.57721725 0-3.57721725 5.66911587h7.1544345z"/>
                            <path d="m9 .66911587-3 5h6z"/>
                            <path d="m20 .66911587-3 5h6z"/>
                        </g>
                        <path d="m3.57721725 0-1.27638033 2h2.54143838z" fill="#fff"/>
                    </g>
                    <g transform="translate(20.5 4.997909)">
                        <path d="m4.11379984 0-4.11379984 6.51948325h8.22759968z" fill="#6b94a9"/>
                        <path d="m4.11379984 0-1.46783738 2.3h2.92265414z" fill="#fff"/>
                    </g>
                    <g transform="translate(12 8.797909)">
                        <path d="m1.78860863 0-1.78860863 2.83455793h3.57721725z" fill="#6b94a9"/>
                        <path d="m1.78860863 0-.63819017 1h1.27071919z" fill="#fff"/>
                    </g>
                    <g transform="translate(27 7.997909)">
                        <path d="m2.41462165 0-2.41462165 3.82665321h4.82924329z" fill="#6b94a9"/>
                        <path d="m2.41462165 0-.86155673 1.35h1.71547091z" fill="#fff"/>
                    </g>
                    <path
                        d="m.42184285 11.5281439h31.67287215c.7037677 1.3336323.1166405 3.7954099-1.7613817 7.3853327-1.8780221 3.5899229-6.5692633 7.618067-14.0737235 12.0844324-6.82647697-3.5351115-11.40969782-7.5632557-13.74966261-12.0844324-2.33996479-4.5211766-3.03599957-6.9829542-2.08810434-7.3853327z"
                        fill="#d79b67"
                    />
                    <path
                        d="m16 11.5281439-7.47556722 13.9998402c1.57949312 1.3575886 2.80086602 2.3171242 3.66411872 2.8786069.8632528.5614826 2.1994685 1.265471 4.0086472 2.111965 1.9621306-1.0256698 3.4007365-1.8421384 4.3158178-2.4494058.9150813-.6072675 1.9832122-1.4543229 3.2043926-2.5411661z"
                        fill="#53616a"
                    />
                    <path
                        d="m2.50994719 0c3.76461037 1.33193934 8.26129461 1.33193934 13.49005281 0 6.6209938 1.33193934 11.3987716 1.33193934 14.3333333 0 1.8285342 5.91846846 2.8170333 13.5285924 0 18.9134766-1.8780221 3.5899229-6.5692633 7.618067-14.0737235 12.0844324-6.82647697-3.5351115-11.40969782-7.5632557-13.74966261-12.0844324-3.50994719-6.781765-1.9512761-13.53782971 0-18.9134766z"
                        stroke="#fff"
                    />
                    <g fillRule="nonzero">
                        <path
                            d="m27.4333333 3.21290902c.0176471-.06.0176471-.105.0176471-.165 0-.585-.5470588-1.05-1.2352941-1.05-.4941177 0-.9176471.24-1.1117647.6-.1764706-.18-.4411765-.3-.7411765-.3-.5294118 0-.9705882.375-.9705882.825v.075c-.582353 0-1.0588236.405-1.0588236.9s.4764706.48335697 1.0588236.48335697h.017647 3.8647059c.5823529 0 1.0588235.01164303 1.0588235-.48335697 0-.45-.3882353-.825-.9-.885z"
                            fill="#fff"
                        />
                        <path
                            d="m18.7333333 5.80790902c.0117647-.04.0117647-.07.0117647-.11 0-.39-.3647058-.7-.8235294-.7-.3294117 0-.6117647.16-.7411764.4-.1176471-.12-.2941177-.2-.4941177-.2-.3529412 0-.6470588.25-.6470588.55v.05c-.3882353 0-.7058824.27-.7058824.6s.3176471.32223798.7058824.32223798h.0117647 2.5764706c.3882353 0 .7058823.00776202.7058823-.32223798 0-.3-.2588235-.55-.6-.59z"
                            fill="#fff"
                        />
                        <path
                            d="m12.1333333 4.21290902c.0235294-.06.0235294-.105.0235294-.165 0-.585-.7294117-1.05-1.6470588-1.05-.65882351 0-1.22352939.24-1.48235292.6-.23529412-.18-.58823529-.3-.98823529-.3-.70588236 0-1.29411765.375-1.29411765.825v.075c-.77647059 0-1.41176471.405-1.41176471.9s.63529412.48335697 1.41176471.48335697h.02352941 5.15294115c.7764706 0 1.4117647.01164303 1.4117647-.48335697 0-.45-.517647-.825-1.2-.885z"
                            fill="#fff"
                            transform="matrix(-1 0 0 1 18.666666 0)"
                        />
                        <g transform="translate(13.625438 12.654159)">
                            <path d="m2.70806225 0h-.667l-.6665 18h2z" fill="#fff"/>
                            <g fill="#53616a">
                                <path d="m1 3.28125h3v1.3125h-3z"/>
                                <path d="m0 7.1875h5v1.96875h-5z"/>
                                <path d="m0 12.1875h5v2.2h-5z"/>
                            </g>
                        </g>
                    </g>
                </g>
            )}
        />

    );
}

export default IconHighway;
