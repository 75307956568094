import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {useState} from "react";
import {useLazyUpdateSeatSelectionQuery} from "@CheckOrder/api/checkOrderApi";
import {useDispatch} from "react-redux";
import {setConfirmedSeats} from "@CheckOrder/reducers/checkOrderSlice";
import {SeatSelectionDialogProps} from "@CheckOrder/components/MainSection/SeatPicker/components/SeatSelectionDialog";

export default function useSeatSelection({seatPickerDirectionData, isReturn, dialogRef}: SeatSelectionDialogProps) {
    const {
        selectedSeatData,
    } = seatPickerDirectionData

    const {
        passengers,
        ticket: {
            ticketCode,
        },
    } = useAppSelector((state) => state.checkOrder)
    const [selectedDeckIndex, setSelectedDeckIndex] = useState(0);
    const [updateSeatSelection, updatedSeatSelectionResponse] = useLazyUpdateSeatSelectionQuery();
    const dispatch = useDispatch();
    const onClick = (value: number) => {
        setSelectedDeckIndex(value);
    }

    const onContinueClick = async () => {
        try {
            updateSeatSelection({
                ticketCode,
                isReturn,
                selectedSeatData,
            }).then((response) => {
                dispatch(setConfirmedSeats({...response.data, isReturn}))
                if (!response.isError) {
                    dialogRef.current.close();
                }
            })
        } catch (e) {
            console.error(e);
        }
    }

    return {
        passengers,
        selectedDeckIndex,
        onClick,
        onContinueClick,
        updatedSeatSelectionResponse,
    }
}