type SelfTransferNoticeProps = {}

const SelfTransferNotice = (props: SelfTransferNoticeProps) => (
    <div>
        <span style={{color: "#3a3b7e"}}>
            This is a
        </span>
        <span>
            {` self-transfer `}
        </span>
        <span style={{color: "#3a3b7e"}}>
            trip with multi-ticket itinerary!
        </span>
    </div>
);

export default SelfTransferNotice;