import React from 'react';
import MyProfileShowArchivedData from "@MyProfile/components/MyProfileShowArchivedData";
import {useTranslation} from "@Translation/hooks/useTranslation";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import useGetValidVouchers from "@MyVouchers/hooks/useGetValidVouchers";
import useGetArchivedVouchers from "@MyVouchers/hooks/useGetArchivedVouchers";
import useCountValidVouchers from "@MyVouchers/hooks/useCountValidVouchers";
import useCountArchivedVouchers from "@MyVouchers/hooks/useCountArchivedVouchers";
import MyVouchers from "@MyVouchers/components/MyVouchers";

function MyVouchersOutlet() {
    const {
        showValid,
        showArchived,
    } = useAppSelector((state) => state.userProfile.filter)

    const {
        data: validVouchers,
        elRef: validVouchersRef,
        page: validVouchersPage,
        setPage: setValidVouchersPage,
        loading: validVouchersLoading,
        batchLoading: validVouchersBatchLoading,
    } = useGetValidVouchers(showValid)

    const {
        data: archivedVouchers,
        elRef: archivedVouchersRef,
        page: archivedVouchersPage,
        setPage: SetArchivedVouchersPage,
        loading: archivedVouchersLoading,
        batchLoading: archivedVouchersBatchLoading,
    } = useGetArchivedVouchers(showArchived)

    const {
        validVouchersCount,
        validVouchersCountLoading,
    } = useCountValidVouchers()

    const {
        archivedVouchersCount,
        archivedVouchersCountLoading,
    } = useCountArchivedVouchers()

    const {
        show_archived_vouchers: showArchivedVouchersLabel,
    } = useTranslation('my_profile')

    return (
        <>
            {(showValid || (showArchived && validVouchersCount === 0 && archivedVouchersCount === 0)) && (
                <MyVouchers
                    vouchers={validVouchers?.vouchers}
                    elRef={validVouchersRef}
                    page={validVouchersPage}
                    setPage={setValidVouchersPage}
                    totalCount={validVouchersCount}
                    totalCountLoading={validVouchersCountLoading}
                    loading={validVouchersLoading}
                    batchLoading={validVouchersBatchLoading}
                    noResultsNotificationKey={archivedVouchersCount === 0 ? "my_profile.no_vouchers" : "my_profile.no_active_vouchers"}
                />
            )}
            {(!archivedVouchersCountLoading && archivedVouchersCount !== 0) && (
                <MyProfileShowArchivedData
                    description={showArchivedVouchersLabel}
                />
            )}
            {(showArchived && !validVouchersLoading && !archivedVouchersCountLoading && archivedVouchersCount !== 0) && (
                <MyVouchers
                    vouchers={archivedVouchers?.vouchers}
                    elRef={archivedVouchersRef}
                    page={archivedVouchersPage}
                    setPage={SetArchivedVouchersPage}
                    totalCount={archivedVouchersCount}
                    totalCountLoading={archivedVouchersCountLoading}
                    loading={archivedVouchersLoading}
                    batchLoading={archivedVouchersBatchLoading}
                />
            )}
        </>
    );
}

export default MyVouchersOutlet;