import isEqual from "lodash/isEqual";
import {useAppSelector} from "@Hooks/selector/useAppSelector";

export default function useMultipleGroupSelects() {
    const {
        ticket: {
            isReturn,
        },
        purchasedRoute: {
            passengerGroups,
            returnPassengerGroups,
        },
    } = useAppSelector((state) => state?.checkOrder)

    return isReturn && !isEqual(passengerGroups, returnPassengerGroups)
}