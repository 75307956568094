export default function getSlidesClassName(date, searchedDate, isDisabled) {
    let className = 'datestrip__item '
    if (searchedDate === date) {
        className += 'datestrip__item--active'
        return className
    }

    if (isDisabled) {
        className += 'datestrip__item--inactive'
        return className
    }
    return className
}