import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconDrinks(props: IconType) {
    const {
        width = '35',
        height = '35',
        viewBox = '0 0 35 35',
        title = 'IconDrinks',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none" fillRule="evenodd" transform="translate(6.749015)">
                    <path
                        d="m.148339 6.05044476 4.09663654 28.94955524h12.95098576l3.2352983-28.94955524z"
                        fill="#d1504a"
                    />
                    <path
                        d="m13.4102377 0-1.1008858 8h1.8759289l.8679672-6.26493949h4.1623061l.5747898-1.73506051z"
                        fill="#505050"
                    />
                    <path
                        d="m2.11996964 7c2.43833662 5.5249672 3.80392741 10.216777 4.09677239 14.0754296.29284498 3.8586525-.36441052 8.500176-1.97176649 13.9245704h3.91972171c.91774302-4.0108601 1.02527518-8.6775268.32259648-14-.70267871-5.3224732-2.82512007-9.9891399-6.36732409-14z"
                        fill="#fff"
                        fillOpacity=".75"
                    />
                    <path d="m0 6h20.4312596l-.1802746 2h-19.88857589z" fill="#d8d8d8"/>
                </g>
            )}
        />

    );
}

export default IconDrinks;
