import React from 'react';
import Column from "@Containers/components/Column";
import MobileFormOverlayContainer from "@Form/components/Mobile/MobileFormOverlayContainer";
import useIsMobile from "@Hooks/selector/useIsMobile";
import NoResultsMessage from "@SearchResults/components/NoResults/NoResultsMessage";
import {
    CityType,
    TransfersDataType
} from "@SearchResults/features/banners/features/getByTransferBanner/types/TransfersData.type";
import GetByFerrySearchResultsBanner from "@SearchResults/features/banners/components/GetByFerrySearchResultsBanner";
import useGbtBanner from "@SearchResults/features/banners/features/getByTransferBanner/hooks/useGbtBanner";
import ParamsType from "@SearchResults/types/params/Params.type";
import SearchResultsUrlsType from "@SearchResults/types/urls/SearchResultsUrls.type";
import {
    GetByFerryDataType
} from "@SearchResults/features/banners/features/getByTransferBanner/types/GetByFerryData.type";
import NoResultsWithGbtPromo from "@SearchResults/components/NoResults/NoResultsWithGbtPromo";

interface NoResultsProps {
    getByFerryData: GetByFerryDataType,
    transfersData: TransfersDataType,
    urls: SearchResultsUrlsType,
    params: ParamsType,
}

function NoResults({urls, params, getByFerryData, transfersData}: NoResultsProps) {
    const {
        gbtBannerType,
        gbtShowBanner,
        gbtShowNoDirectRoutesNotification,
    } = useGbtBanner({transfersData, getByFerryData, numberOfResults: 0})

    const isMobile = useIsMobile()

    function hasNearbyCity(object: CityType | null): object is CityType {
        return 'id' in object
    }

    const showNoDirectRoutes = transfersData?.noDirectBuses
        && !!transfersData?.departureNearbyCity
        && hasNearbyCity(transfersData.departureNearbyCity)
        && transfersData?.departureNearbyCity?.id !== `${params.destinationStation.id}`

    return (
        <Column className="no-results__content" reverse={showNoDirectRoutes && !(!getByFerryData && gbtShowBanner)}>
            {params && getByFerryData && (
                <GetByFerrySearchResultsBanner
                    params={params}
                />
            )}
            {!getByFerryData && gbtShowBanner ? (
                <NoResultsWithGbtPromo
                    bannerType={gbtBannerType}
                    urls={urls}
                    params={params}
                    transfersData={transfersData}
                    showNoDirectRoutesNotification={gbtShowNoDirectRoutesNotification}
                />
            ) : (
                <>
                    <br/>
                    <NoResultsMessage
                        params={params}
                        urls={urls}
                        toCityName={transfersData?.toName ?? ""}
                        nearByCityName={(showNoDirectRoutes && hasNearbyCity(transfersData?.departureNearbyCity)) ? transfersData?.departureNearbyCity?.name : ""}
                        showNoDirectRoutes={showNoDirectRoutes}
                    />
                </>
            )}
            {isMobile && (
                <MobileFormOverlayContainer/>
            )}
        </Column>
    );
}

export default NoResults;
