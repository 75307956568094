import React, {useState} from 'react';
import IconChevronDown from "@Icons/IconChevronDown";
import ClickOutsideAwareComponent from "@Features/clickOutsideAwareComponent/components/ClickOutsideAwareComponent";
import Translation from "@Translation/components/Translation";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import MenuItem from "@Header/components/TopBar/MenuItem";
import {PagesType} from "@/types/pageSlice/Pages.type";
import UrlObjectType from "@/types/pageSlice/UrlObject.type";
import useCompanyStyle from "@Hooks/selector/useCompanyStyle";

function MainMenu() {
    const {currentRoute} = window
    const urlsObject = useAppSelector((state) => state?.page.urlsObject)
    const [showDropdown, setDropdown] = useState(false)
    const toggleDropdown = () => () => setDropdown(!showDropdown)

    let charLength = 0;
    const moreMenuList: [PagesType, UrlObjectType][] = [];
    const visibleMenuList: [PagesType, UrlObjectType][] = [];
    const {company_id: companyId} = useCompanyStyle()

    Object.entries(urlsObject).map((entry: [PagesType, UrlObjectType]) => {
        const [key, urlObject] = entry
        // on GBB don't display FAQ in menu
        if (companyId === "815" && urlObject.routeName === "gbb_front_faq") {
            return
        }
        if (urlObject.text !== '') {
            if (charLength > 33) {
                moreMenuList.push(entry)
            } else {
                visibleMenuList.push(entry)
            }
        }
        charLength += urlObject.text.length;
    })

    return (
        <>
            {visibleMenuList.map((entry) => (
                <MenuItem
                    entry={entry}
                    currentRoute={currentRoute}
                    visible
                    key={entry[0]}
                />
            ))}
            {moreMenuList.length > 0 && (
                <ClickOutsideAwareComponent onClickOutside={() => setDropdown(false)}>
                    <ul className="gb--menu__item">
                        <li>
                            <span onClick={toggleDropdown()} className="gb--emphasize-default">
                                <Translation translationKey="sys.more"/>
                                <IconChevronDown
                                    stroke="#070c63"
                                    style={{
                                        verticalAlign: 'middle',
                                        marginLeft: '.5rem',
                                    }}
                                />
                            </span>
                            <ul className={`select__list ${showDropdown ? 'select__list--opened' : ''}`}>
                                {moreMenuList.map((entry) => (
                                    <MenuItem
                                        entry={entry}
                                        currentRoute={currentRoute}
                                        key={entry[0]}
                                    />
                                ))}
                            </ul>
                        </li>
                    </ul>
                </ClickOutsideAwareComponent>
            )}
        </>
    );
}

export default MainMenu;
