import React from 'react';
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import PassengerTypeList from "@CheckOrder/features/paymentSummary/features/passengersSummary/components/PassengerTypeList";
import usePurchasedRoute from "@CheckOrder/hooks/usePurchasedRoute";

function PassengersSummary() {
    const {passengers} = useAppSelector((state) => state?.checkOrder)
    const {
        passengerGroups,
        returnPassengerGroups,
    } = usePurchasedRoute()

    return (
        <PassengerTypeList
            passengerGroups={{...passengerGroups, ...returnPassengerGroups}}
            passengers={passengers}
        />
    );
}

export default PassengersSummary