import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {userProfileInitialState} from "@MyProfile/reducers/constants/userProfileInitialState"
import {userLoginMethods} from "@User/constants/userLoginMethods";

/**
 * Handles user profile, vouchers & tickets within myProfile
 */
const userProfileSlice = createSlice({
    name: 'userProfile',
    initialState: userProfileInitialState,
    reducers: {
        setUser: (state, action) => {
            localStorage.setItem('userLogInStatusChanged', action.payload?.id ?? null)
            return {...state, user: {...state.user, ...action.payload}}
        },

        setUserLoginMethods: (state, action) => (
            {
                ...state,
                user: {
                    ...state.user,
                    userLoginMethods: {...action.payload},
                },
            }
        ),

        clearUserLoginMethods: (state) => (
            {
                ...state,
                user: {
                    ...state.user,
                    userLoginMethods,
                },
            }
        ),

        setErrors: (state, action) => (
            {...state, errors: action.payload}
        ),

        fetchingUserFinished: (state, action) => (
            {...state, userFetchingFinished: action.payload}
        ),
        setShowValidFilter: (state, action: PayloadAction<boolean>) => (
            {...state, filter: {...state.filter, showValid: action.payload}}
        ),
        setShowArchivedFilter: (state, action: PayloadAction<boolean>) => (
            {...state, filter: {...state.filter, showArchived: action.payload}}
        ),
        setLoading: (state, action: PayloadAction<boolean>) => (
            {...state, isLoading: action.payload}
        ),

        toggleLoginModal: (state) => (
            {...state, loginModalActive: !state.loginModalActive}
        ),

        activateRebookModal: (state, action) => (
            {...state, activeTicketModal: action.payload}
        ),

        activateTicketRelatedToVoucherModal: (state, action) => (
            {...state, activeTicketRelatedToVoucher: action.payload}
        ),

        toggleSplashLoader: (state) => (
            {...state, activeSplashLoader: !state.activeSplashLoader}
        ),

        dismissModals: (state) => (
            {...state, loginModalActive: false}
        ),

        setAwaitingUserResponse: (state, action: PayloadAction<boolean>) => (
            {...state, awaitingUserResponse: action.payload}
        ),

        // Removes all user data from redux and sets to initial state. Used on logout
        clearUserData: () => {
            localStorage.setItem('userLogInStatusChanged', null)
            return {
                ...userProfileInitialState,
                awaitingUserResponse: false,
            }
        },
    },
})

export const {
    setLoading,
    fetchingUserFinished,
    setShowValidFilter,
    setShowArchivedFilter,
    setUser,
    toggleLoginModal,
    clearUserData,
    dismissModals,
    setErrors,
    setUserLoginMethods,
    clearUserLoginMethods,
    activateRebookModal,
    activateTicketRelatedToVoucherModal,
    toggleSplashLoader,
    setAwaitingUserResponse,
} = userProfileSlice.actions

export default userProfileSlice.reducer