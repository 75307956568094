import React from "react";
import useQuantityAwareTranslation from "@Translation/hooks/useQuantityAwareTranslation";
import IconUserFull from "@Icons/IconUserFull";
import {useAppSelector} from "@Hooks/selector/useAppSelector";

interface TotalPassengersProps {
    className?: string,
    displayLabel?: boolean,
}

function TotalPassengers(props: TotalPassengersProps) {
    const {
        className = "gb--row gb--row-middle gb--row-end route--price-pax",
        displayLabel = false,
    } = props
    // @ts-ignore
    const total = useAppSelector((state) => state?.form.passengers.passengers)
    const passengerLabel = useQuantityAwareTranslation("sys.passenger", total)

    return (
        <span className={className}>
            <IconUserFull fill="#070c63"/>
            {total}
            {displayLabel && ` ${passengerLabel}`}
        </span>
    )
}

export default TotalPassengers;