import {useEffect} from "react";

/**
 * @param tooltipRef
 * @param observeCallback {function}
 * @param options {object}
 * @return {{tooltipRef: React.MutableRefObject<null>, direction: unknown}}
 */
export default function useIntersectionObserver(
    tooltipRef,
    observeCallback,
    options = {
        root: null,
        rootMargin: "0px 0px 0px 0px",
        threshold: 0.5,
    }
) {
    const handleObservation = () => {
        const intersectionObserver = new IntersectionObserver(observeCallback, options)
        if (tooltipRef.current) {
            intersectionObserver.observe(tooltipRef.current)
        }

        return () => {
            if (tooltipRef.current) {
                intersectionObserver.unobserve(tooltipRef.current)
            }
        }
    }

    useEffect(handleObservation, [tooltipRef.current])

    return {
        tooltipRef,
    }
}