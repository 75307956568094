import React, {ReactNode} from 'react'

interface ToggleableComponentProps {
    display: boolean,
    children: ReactNode,
}

export default function ToggleableComponent({display, children}: ToggleableComponentProps) {
    if (display) {
        return (<>{children}</>);
    }

    return null
}