import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconAppleSymbol(props: IconType) {
    const {
        width = '16',
        height = '16',
        fill = '#000000',
        viewBox = '0 0 16 16',
        title = 'IconAppleSymbol',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none">
                    <path d="m0 0h16v16h-16z" />
                    <path
                        d="m12.364 8.509a3.642 3.642 0 0 1 1.731-3.051 3.721 3.721 0 0 0 -2.932-1.585c-1.234-.13-2.43.738-3.059.738-.641 0-1.609-.726-2.651-.704a3.91 3.91 0 0 0 -3.287 2.004c-1.421 2.461-.361 6.076 1 8.065.681.974 1.477 2.062 2.519 2.023 1.019-.042 1.4-.65 2.63-.65 1.219 0 1.576.65 2.638.625 1.094-.017 1.783-.978 2.44-1.961a8.063 8.063 0 0 0 1.116-2.272 3.508 3.508 0 0 1 -2.145-3.232zm-2.007-5.944c.597-.716.89-1.636.819-2.565a3.647 3.647 0 0 0 -2.357 1.219 3.408 3.408 0 0 0 -.84 2.47c.456.005.907-.094 1.318-.289.412-.194.775-.48 1.06-.835z"
                        fill={fill}
                    />
                </g>
            )}
        />

    );
}

export default IconAppleSymbol;
