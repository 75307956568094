import React from 'react';
import {useTranslation} from "@Translation/hooks/useTranslation";
import IconHandRight from "@Icons/IconHandRight";
import IconChevron from "@Icons/IconChevron";

interface VoucherNoticeProps {
    onClick: (any?) => any,
}

function VoucherNotice({onClick}: VoucherNoticeProps) {
    const {click_here_to_apply: clickToApply} = useTranslation("sys")

    return (
        <div
            onClick={onClick}
            className="gb--row gb--row-justify gb--check-order-voucher-notice"
            role="button"
        >
            <span className="gb--emphasize gb--emphasize-default">
                <IconHandRight
                    width="50"
                    style={{verticalAlign: 'bottom'}}
                />
                {clickToApply}
            </span>
            <IconChevron
                width="20"
                height="20"
                fill="#070c63"
                chevronOrientation="right"
                style={{stroke: '#070c63'}}
            />
        </div>
    );
}

export default VoucherNotice;