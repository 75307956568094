import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconBicycle(props: IconType) {
    const {
        width = '35',
        height = '35',
        viewBox = '0 0 35 35',
        title = 'IconBicycle',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none" fillRule="evenodd" transform="translate(1 6.4)">
                    <circle cx="26" cy="14.6" r="6" stroke="#070707"/>
                    <circle cx="26" cy="14.6" r="1" stroke="#c0c0c0"/>
                    <circle cx="6" cy="14.6" r="6" stroke="#070707"/>
                    <circle cx="6" cy="14.6" r="1" stroke="#c0c0c0"/>
                    <g fill="#c0c0c0">
                        <rect
                            height="3"
                            rx=".5"
                            transform="matrix(.95630476 -.2923717 .2923717 .95630476 -.524893 3.149145)"
                            width="1"
                            x="9.923264"
                            y="1.830643"
                        />
                        <rect height="3" rx=".5" width="1" x="22.55" y="1.6"/>
                        <rect height="3" rx=".5" transform="matrix(0 1 -1 0 25 -22)" width="1" x="23.15"/>
                    </g>
                    <path
                        d="m22.9051338 4.23422674v1.33914395h-11.8842911l-.3186616-1.33914395-4.7021811 10.36577326 8 .9462316-.5065974-1.4641563-2.4725599-8.28233716"
                        stroke="#fb9200"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="m13.7776421 15.5462316 2.0762023.0537684 7.0512894-7.82216121v-2.2044681c.6599853 3.07344698 1.2365308 5.24479771 1.7296362 6.51405231.4931054 1.2692545.9481821 2.1067802 1.36523 2.512577"
                        stroke="#fb9200"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="m8.80804454 2.3085853h3.90798106c.1550338 0 .2807136-.12567977.2807136-.28071363 0-.11602557-.0713813-.22009552-.1796221-.26187905l-1.3964016-.53904437c-.0061274-.00236531-.0122075-.00485112-.0182369-.007456l-1.85780632-.80262195c-.25350523-.10960551-.54783187.00713091-.6573662.2606669-.01900942.04400056-.03159424.09050553-.03736827.13808776-.02027859.16711056-.04449757.28090552-.07265692.34138488-.02174454.04670191-.11293349.13166587-.27356685.25489189-.21906666.16809155-.26042607.48194056-.0923596.70102645.09462078.12334453.24123283.19565712.3966901.19565712z"
                        fill="#070707"
                    />
                    <path
                        d="m23.25 1.4999784h1.4969019c.9554063.06668107 1.4331095.4866771 1.4331095 1.25998809 0 .98284017-.4883352 1.52833813-1.4650057 1.63649389"
                        stroke="#070707"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth=".7"
                    />
                    <circle cx="14.35" cy="15.75" fill="#d8d8d8" r="1.35" stroke="#c0c0c0" strokeWidth="1.25"/>
                    <path
                        d="m6.42577467 12.9692946 8.09002943.9288203c1.083644.1244137 1.8612538 1.103738 1.7368401 2.187382-.0008405.0073203-.0017219.0146359-.0026442.0219464-.1268224 1.0052056-1.0445124 1.7172759-2.049718 1.5904534-.0515037-.006498-.1027084-.0151792-.1534759-.0260201l-8.29893729-1.772171c-.75972596-.1622334-1.24408943-.909629-1.08185609-1.669355.0019654-.0092038.00402312-.0183877.00617286-.0275502.18688716-.7965442.94075424-1.3268277 1.75358909-1.2335058z"
                        stroke="#070707"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth=".5"
                    />
                    <path
                        d="m13.7441318 16.638209 3.6914698.4122866.4312877 1.1849532"
                        stroke="#070707"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth=".7"
                        transform="matrix(.34202014 .93969262 .93969262 -.34202014 -5.985552 8.548254)"
                    />
                </g>
            )}
        />

    );
}

export default IconBicycle;
