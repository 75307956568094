import React from "react";
import {useTranslation} from "@Translation/hooks/useTranslation";
import useIsMobile from "@Hooks/selector/useIsMobile";
import useActivateRoute from "@Route/hooks/useActivateRoute";
import IntermodalRouteWithDetailsType from "@Intermodal/types/IntermodalRouteWithDetails.type";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";
import ParamsType from "@SearchResults/types/params/Params.type";

interface ShowOptionsButtonProps {
    route: IntermodalRouteWithDetailsType | RouteWithAddedPropsAndPricesType,
    isReturn: boolean,
    params: ParamsType,
}

const ShowOptionsButton = ({route, params, isReturn}: ShowOptionsButtonProps) => {
    const isMobile = useIsMobile()
    const showOptionsLabel = useTranslation("search_results.show_options")
    const buttonCompact = showOptionsLabel.length >= 15 ? 'btn--compact' : ''

    const {
        activateRoute,
    } = useActivateRoute({
        route,
        isCurrentRouteSingle: !isReturn,
        params,
        routeExpandable: true,
    })

    return (
        <button
            type="submit"
            className={`btn btn--blue ${buttonCompact}  ${isMobile ? 'btn--mobile' : ''} routes-list__cta routes-list__cta--operator`}
            onClick={activateRoute}
        >
            {showOptionsLabel}
        </button>
    );
}

export default ShowOptionsButton