import RouteType from "@Route/types/Route.type";
import {useEffect, useState} from "react";
import {useAppSelector} from "@Hooks/selector/useAppSelector";

export default function useLoadMoreRoutes(routes: RouteType[]) {
    const {excludedApiPartnerIds} = useAppSelector((state) => state.searchResults)
    const [loadedMoreRoutes, setLoadMoreRoutes] = useState(false)

    const filterRoutesByTypes = () => {
        return routes.reduce((acc, route) => {
            if (excludedApiPartnerIds.includes(route.companyId) || route.status.isJadrolinijaStateLine) {
                acc.excludedRoutes.push(route)
                return acc
            }
            if (!route.status.isApi && !route.status.isApiFullIntegration) {
                acc.regularRoutes.push(route)
            }
            if (route.status.isApiFullIntegration) {
                acc.fullAPiIntegrationRoutes.push(route)
            }
            if (route.status.isApi) {
                acc.redirectRoutes.push(route)
            }

            return acc
        }, {
            fullAPiIntegrationRoutes: <RouteType[]>[],
            redirectRoutes: <RouteType[]>[],
            regularRoutes: <RouteType[]>[],
            excludedRoutes: <RouteType[]>[],
        })
    }

    const getExcludedRouteCount = () => {
        const routes = filterRoutesByTypes()
        if (routes.fullAPiIntegrationRoutes.length > 0) {
            return routes.redirectRoutes.length
        }

        return routes.excludedRoutes.length
    }

    const [showLoadMoreButton, setShowLoadMoreButton] = useState(
        getExcludedRouteCount() > 0
    )

    useEffect(() => {
        if (!loadedMoreRoutes) {
            setShowLoadMoreButton(getExcludedRouteCount() > 0)
        }
    }, [routes]);

    const getDisplayedRoutes = () => {
        const routes = filterRoutesByTypes()
        if (!showLoadMoreButton) {
            return Object.values(routes).flat(10)
        }
        return [...routes.regularRoutes, ...routes.fullAPiIntegrationRoutes]
    }

    const loadMoreRoutes = () => {
        setShowLoadMoreButton(false)
        setLoadMoreRoutes(true)
    }

    return {
        setShowLoadMoreButton,
        loadMoreRoutes,
        showLoadMoreButton,
        routes: getDisplayedRoutes(),
        excludedRouteCount: getExcludedRouteCount(),
    }
}