import StationModel from "@Form/models/StationModel";
import UrlParamsType from "@Form/types/UrlParams.type";

export default class DepartureStationModel extends StationModel {
    urlParams: UrlParamsType;

    fromId: string;

    fromSlug: string;

    departureStationObject: {
        slug: string;
        id: number | null;
        countryCode: string;
        countryBusRentPaxCountMin: number | null;
        label: string
    };

    /**
     * Departure Station
     * @param props {object}
     * @param props.payload {object} - updated state
     * @param props.urlParams {object} - existing urlParams state
     */
    constructor(props) {
        super(props);
        const {urlParams} = props

        this.urlParams = {...urlParams}
        this.urlParams.fromId = this.station.id
        this.urlParams.fromSlug = this.station.slug
        this.departureStationObject = this.station
    }
}