import {VehicleAddonResponseType} from "@CheckOrder/api/types/GetAddonData.type";
import Row from "@Containers/components/Row";
import useHandleJadrolinijaVehicleSelection
    from "@CheckOrder/features/addons/features/jadrolinija/hooks/useHandleJadrolinijaVehicleSelection";
import React from "react";
import VehicleModel
    from "@CheckOrder/features/addons/features/jadrolinija/features/vehicleModel/components/VehicleModel";
import VehicleAdditionalLength
    from "@CheckOrder/features/addons/features/jadrolinija/features/vehicleAdditionalLength/components/VehicleAdditionalLength";
import VehicleAdditionalHeight
    from "@CheckOrder/features/addons/features/jadrolinija/features/vehicleAdditionalHeight/components/VehicleAdditionalHeight";
import VehicleRegistration
    from "@CheckOrder/features/addons/features/jadrolinija/features/vehicleRegistration/components/VehicleRegistration";
import VehicleType from "@CheckOrder/features/addons/features/jadrolinija/features/vehicleType/components/VehicleType";
import Grid from "@Containers/components/Grid";
import AddAdditionalServiceButton from "@CheckOrder/components/AddAdditionalServiceButton";
import IconVehicle from "@Icons/FilledOutline/IconVehicle";
import {
    bikePassengerCode,
    carPassengerCode, motorcyclePassengerCode
} from "@CheckOrder/features/addons/features/jadrolinija/constants/passengerCodes";

type VehicleTypeSelectProps = {
    vehicles: VehicleAddonResponseType[],
}

function VehicleTypeSelect({vehicles}: VehicleTypeSelectProps) {
    const {
        updateTicketWithAddon,
        setRegistrationNumber,
        registrationNumber,
        showErrors,
        selectOptions,
        activeOption,
        setVehicleType,
        selectedVehicle,
        setVehicle,
        autoSuggestModels,
        additionalHeight,
        setHeight,
        additionalLength,
        setLength,
        isRental,
        toggleRental,
    } = useHandleJadrolinijaVehicleSelection(vehicles)

    const isBike = activeOption.value === bikePassengerCode
    const isMotorcycle = activeOption.value === motorcyclePassengerCode
    const isCar = activeOption.value === carPassengerCode

    return (
        <>
            <Grid autofill={isBike}>
                <VehicleType
                    selectOptions={selectOptions}
                    activeOption={activeOption}
                    setVehicleType={setVehicleType}
                />
                {isCar && (
                    <VehicleModel
                        selectedVehicle={selectedVehicle}
                        setVehicle={setVehicle}
                        autoSuggestModels={autoSuggestModels}
                        showErrors={showErrors}
                    />
                )}
                {activeOption.activeGroup.lengthOptions && (
                    <VehicleAdditionalLength
                        activeOption={activeOption}
                        setLength={setLength}
                        additionalLength={additionalLength}
                        isMotorcycle={isMotorcycle}
                    />
                )}
                {activeOption.activeGroup.heightOptions && (
                    <VehicleAdditionalHeight
                        activeOption={activeOption}
                        setHeight={setHeight}
                        additionalHeight={additionalHeight}
                    />
                )}
                {!isBike && (
                    <VehicleRegistration
                        isRental={isRental}
                        registrationNumber={registrationNumber}
                        setRegistrationNumber={setRegistrationNumber}
                        toggleRental={toggleRental}
                        showErrors={showErrors}
                    />
                )}
            </Grid>
            <Row>
                <AddAdditionalServiceButton
                    closeModal={updateTicketWithAddon}
                    cta={`Confirm my ${selectedVehicle?.name ?? activeOption?.label}`}
                    total="calculating total"
                    icon={<IconVehicle/>}
                />
            </Row>
        </>
    );
}

export default VehicleTypeSelect;