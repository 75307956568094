import PartnerRedirectEventModel from "@Events/models/PartnerRedirectEventModel";
import useRedirectUrlGenerator from "@Route/hooks/useRedirectUrlGenerator";
import useSingleTrackingEvent from "@Events/hooks/useSingleTrackingEvent";
import ParamsType from "@SearchResults/types/params/Params.type";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";

/**
 * Dispatches redirectToPartnerEvent
 */
export default function useSingleSelectedRouteRedirectToPartnerEvent(route: RouteWithAddedPropsAndPricesType, params: ParamsType) {
    const {generateRedirectUrlPrams} = useRedirectUrlGenerator(params)

    return useSingleTrackingEvent(
        "partner_redirect",
        new PartnerRedirectEventModel(generateRedirectUrlPrams(route))
    )
}