
import AncillaryType from "@CheckOrder/types/Ancillary.type";
import Row from "@Containers/components/Row";
import IconDiamond from "@Icons/FilledOutline/IconDiamond";
import React from "react";
import IconSMS from "@Icons/FilledOutline/IconSMS";
import IconFlexCancellation from "@Icons/IconFlexCancellation";
import fetchIconForAncillaryId from "@CheckOrder/functions/fetchIconForAncillaryId";

interface AncillaryDetailsProps {
    ancillaryData: AncillaryType
}

function Ancillary({ancillaryData}: AncillaryDetailsProps) {
    if (!ancillaryData.isEnabled) {
        return;
    }

    return (
        <Row className="ancillary-pill" justify>
            <div className="ticket-ancillary-icon">
                {fetchIconForAncillaryId(ancillaryData.id)}
            </div>
            <div className="ticket-ancillary-name">
                {ancillaryData.name}
            </div>
        </Row>
    );
}

export default Ancillary