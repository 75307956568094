import IconMTicketQrShifted from "@Icons/IconMTicketQrShifted";
import IconLeaf from "@Icons/IconLeaf";
import {useTranslation} from "@Translation/hooks/useTranslation";
import MyProfileNotification from "@MyProfile/components/MyProfileNotification";
import IconTimes from "@Icons/IconTimes";
import IconPaperTicket from "@Icons/IconPaperTicket";

interface TicketNotificationsProps {
    isCanceled: boolean,
    isExchangedForVoucher: boolean,
    isExpired: boolean,
    isMTicket: boolean,
}

function TicketNotifications(props: TicketNotificationsProps) {
    const {
        isExpired,
        isExchangedForVoucher,
        isCanceled,
        isMTicket,
    } = props

    const {
        has_mticket_desc: gbbTransportTypeLabel,
        ticket_has_to_be_printed: paperTicketDescriptionLabel,
    } = useTranslation("search_results")
    const {
        not_valid: notValidLabel,
        cancelled: cancelledLabel,
        ticket_automatically_archived: ticketAutomaticallyArchivedLabel,
        ticket_exchanged_for_voucher: ticketExchangedForVoucherLabel,
        ticket_refund: ticketRefundLabel,
    } = useTranslation('my_profile.my_tickets')

    return (
        <>
            {isExpired && (
                <MyProfileNotification
                    title={notValidLabel}
                    description={ticketAutomaticallyArchivedLabel}
                    className="ticket-notification"
                    notificationIcon={<IconTimes fill="#f5a623"/>}
                    notificationType="orange"
                />
            )}
            {isExchangedForVoucher && (
                <MyProfileNotification
                    title={cancelledLabel}
                    description={ticketExchangedForVoucherLabel}
                    className="ticket-notification"
                    notificationIcon={<IconTimes fill="#f5a623"/>}
                    notificationType="orange"
                />
            )}
            {isCanceled && (
                <MyProfileNotification
                    title={notValidLabel}
                    description={ticketRefundLabel}
                    className="ticket-notification"
                    notificationIcon={<IconTimes fill="#f5a623"/>}
                    notificationType="orange"
                />
            )}
            {isMTicket ? (
                <MyProfileNotification
                    title={(
                        <>
                            M-ticket
                            <IconLeaf/>
                            eco friendly
                        </>
                    )}
                    description={gbbTransportTypeLabel}
                    className="ticket-notification"
                    notificationIcon={<IconMTicketQrShifted fill="#7ed321"/>}
                    notificationType="green"
                />
            ) : (
                <MyProfileNotification
                    title="Paper ticket"
                    description={paperTicketDescriptionLabel}
                    className="ticket-notification"
                    notificationIcon={<IconPaperTicket fill="#f5a623"/>}
                    notificationType="orange"
                />
            )}
            <hr/>
        </>
    );
}

export default TicketNotifications;