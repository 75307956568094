import CompanyType from "@SearchResults/types/company/Company.type";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import addOrRemoveCompanyFromFilteredList
    from "@SearchResults/features/sidebar/features/companyFilters/functions/addOrRemoveCompanyFromFilteredList";
import {setCompanyFilter} from "@SearchResults/reducers/filterSlice";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";
import {isEmpty} from "@Array/isEmpty";

export default function useFilterByCompany() {
    const dispatch = useAppDispatch()
    const filteredCompanies = useAppSelector((state) => state.filter.filterCompanies) as CompanyType[]

    const onPress = (carrier: CompanyType) => {
        dispatch(setCompanyFilter(
            addOrRemoveCompanyFromFilteredList(filteredCompanies, carrier)
        ))
    }

    const filterByCompany = (route: RouteWithAddedPropsAndPricesType) => {
        return filteredCompanies.map(({id}) => id).includes(route.companyId)
    }

    return {
        onPress,
        filteredCompanies,
        filterByCompany,
        hasFilteredCompanies: !isEmpty(filteredCompanies),
    }
}