import React, {Fragment, ReactNode} from 'react';

interface RepeatElementProps {
    children: ReactNode,
    /** Number of times to insert the element */
    numTimes: number,
}

function RepeatElement({children, numTimes}: RepeatElementProps) {
    const repeatedElements = [...Array(numTimes)].map((e, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={i}>{children}</Fragment>
    ))

    return (
        <>
            {repeatedElements}
        </>
    );
}

export default RepeatElement;