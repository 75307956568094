import React, {useState} from 'react';
import Portal from "@Portals/components/Portal";
import TableOfContents from "@Generic/TableOfContents/TableOfContents";

function TableOfContentsPortal() {
    if (window.tableOfContents) {
        return (
            <Portal id="table_ofs">
                <TableOfContents/>
            </Portal>
        );
    }
}
export default TableOfContentsPortal;