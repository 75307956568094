import React from 'react';
import BusStopPopover from "@SearchResults/components/Popover/BusStopPopover";
import useIsMobile from "@Hooks/selector/useIsMobile";
import BusChange from "@Route/components/Connection/BusChange";
import GenericPopover from "@Popover/components/GenericPopover";
import StationWithDateTimesType from "@SearchResults/types/station/StationWithDateTimes.type";

interface RouteListMustChangeBusProps {
    busChange: StationWithDateTimesType[],
}

function RouteListMustChangeBus({busChange}: RouteListMustChangeBusProps) {
    const isMobile = useIsMobile()

    const displayOverlay = () => (
        <BusStopPopover
            busChange={busChange}
        />
    )

    const displayTrigger = () => (
        <BusChange busChanges={busChange.length}/>
    )

    const renderPopover = () => (
        <GenericPopover
            trigger={displayTrigger()}
            overlay={displayOverlay()}
            id={busChange.length}
        />
    )

    return !isMobile ? renderPopover() : displayTrigger()
}

export default RouteListMustChangeBus;