import React from 'react';
import IconLocationMarker from "@Icons/IconLocationMarker";

interface FormSuggestionProps {
    suggestion?: any,
    onClick?: (any?) => any
}

const FormSuggestion = ({suggestion, onClick}: FormSuggestionProps) => {
    const onClickHandler = () => {
        onClick(suggestion)(suggestion)
    }

    return (
        <div
            role="button"
            onClick={onClickHandler}
            className="suggestion-content"
        >
            <IconLocationMarker style={{marginRight: '1rem'}}/>
            <p dangerouslySetInnerHTML={{__html: suggestion.label}}/>
        </div>
    )
};

export default FormSuggestion;