import VehicleAutoSuggest
    from "@CheckOrder/features/addons/features/jadrolinija/features/vehicleModel/components/VehicleAutoSuggest";
import CheckOrderInputContainer from "@CheckOrder/components/MainSection/CheckOrderInputContainer";
import React from "react";
import {
    JadrolinijaVehicleModelType
} from "@CheckOrder/features/addons/features/jadrolinija/types/JadrolinijaVehicleModel.type";
import {useTranslation} from "@Translation/hooks/useTranslation";
import CheckOrderErrorLabel from "@CheckOrder/components/MainSection/CheckOrderErrorLabel";

interface VehicleModelProps {
    selectedVehicle: JadrolinijaVehicleModelType,
    setVehicle: (value: JadrolinijaVehicleModelType) => void
    autoSuggestModels: JadrolinijaVehicleModelType[],
    showErrors: boolean,
}

function VehicleModel({selectedVehicle, setVehicle, autoSuggestModels, showErrors}: VehicleModelProps) {
    const {
        vehicle_model: vehicleModelLabel,
        // "you must select your vehicle model"
    } = useTranslation("check_order.addons.JADL.vehicle")

    return (
        <CheckOrderInputContainer label={vehicleModelLabel}>
            <VehicleAutoSuggest
                selectedVehicle={selectedVehicle}
                setVehicle={setVehicle}
                models={autoSuggestModels ?? []}
            />
            {autoSuggestModels && !selectedVehicle && showErrors && (
                <CheckOrderErrorLabel
                    error="You must select your vehicle model"
                    isAbsolute
                />
            )}
        </CheckOrderInputContainer>
    )
}

export default VehicleModel