import useCompany from "@Route/features/company/hooks/useCompany"
import {useTranslation} from "@Translation/hooks/useTranslation"
import {isPrivateMinivanRoute} from "@Route/features/transportType/functions/isPrivateMinivanRoute"
import {isPrivateBusRoute} from "@Route/features/transportType/functions/isPrivateBusRoute"
import {PreparedRouteType} from "@Route/types/PreparedRoute.type"
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type"

export default function useBuyTicketButtonLabelConfigurator(route: RouteWithAddedPropsAndPricesType) {
    const {
        company,
        status: {
            showBuyFromLabel,
            isBookAway,
            isAsia12Go,
            isP10,
            redirectLink,
            hasRedirectLink,
        },
        type: {
            routeTransferType,
        },
    } = route

    const isPrivateMinivan = isPrivateMinivanRoute(routeTransferType)
    const isPrivateBus = isPrivateBusRoute(routeTransferType)
    const {
        buy_from: buyFromLabel,
        buy_from_operator: buyFromOperatorLabel,
    } = useTranslation("sys")

    const buyTicketLabel = useTranslation("search_results.buy_ticket")
    const bookMinivanLabel = useTranslation("search_results.transport_labels.minivan.book_minivan")
    const bookLabel = isPrivateBus ? 'Book a Bus' : bookMinivanLabel
    const operatorClassName = showBuyFromLabel ? 'btn--blue ' : 'btn--orange '
    const redirectButtonLabel = showBuyFromLabel ? buyTicketLabel : buyFromOperatorLabel
    const compactButtonClassName = isPrivateBus || isPrivateMinivan || redirectButtonLabel.length >= 15 ? 'btn--compact ' : ''

    // TODO: this should also be forwarded from serverside akin to useRedirectInSameTab
    const getCompanyLabel = () => {
        const companyNames = {
            [`${isBookAway}`]: 'Bookaway',
            [`${isAsia12Go}`]: '12Go',
            [`${isP10}`]: 'P10',
        };

        return companyNames.true ?? company.name
    }

    return {
        capitalize: !isPrivateBus,
        buyFromLabel: `${buyFromLabel} ${getCompanyLabel()}`,
        showBuyFromLabel: showBuyFromLabel && (!!redirectLink || hasRedirectLink),
        operatorClassName,
        compactButtonClassName,
        buttonLabel: (isPrivateMinivan || isPrivateBus) ? bookLabel : redirectButtonLabel,
    }
}