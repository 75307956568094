import React, {useEffect, useState} from 'react';
import useTimeout from "@Hooks/effects/useTimeout";
import Translation from "@Translation/components/Translation";
import {useAppSelector} from "@Hooks/selector/useAppSelector";

interface FadingErrorProps {
    error: boolean,
}

function FadingError({error}: FadingErrorProps) {
    const {submitTimestamp} = useAppSelector((state) => state?.form)
    const [visible, setVisible] = useState(false)
    const handleVisibility = () => setVisible(false)
    useTimeout(handleVisibility, 3000, submitTimestamp)
    const setVisibleOnSubmit = () => {
        if (submitTimestamp) {
            setVisible(true)
        }
    }
    useEffect(setVisibleOnSubmit, [submitTimestamp])

    if (error && visible) {
        return (
            <div
                className="popover fade top in"
                style={{top: "6vh"}}
            >
                <div className="arrow" style={{left: "50%"}}/>
                <div className="popover-content">
                    <Translation translationKey="sys.please_enter_the_required_fields_first"/>
                </div>
            </div>
        );
    }

    return null
}

export default FadingError;