import {useComponentDidMount} from "@Hooks/lifecycle/useComponentDidMount";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import {useEffect} from "react";
import {
    setCheckOrderPassengers,
    setIsBuyingPremiumSupport, setIsBuyingSMSNotification,
    setPassengerDataUpdating,
    setTicket
} from "@CheckOrder/reducers/checkOrderSlice";
import updateArrayMember from "@Array/updateArrayMember";
import useUpdatePassengerGroup
    from "@CheckOrder/features/passengerInfo/features/priceSelect/hooks/useUpdatePassengerGroup";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import PassengerType from "@CheckOrder/types/Passenger.type";

interface UsePassengerGroupChangedAttributes {
    passenger: PassengerType,
    passengerGroup: number,
    tripType: 'single' | 'return',
    updateBothTripSegments: boolean
    index: number,
}

export default function usePassengerGroupChanged(attributes: UsePassengerGroupChangedAttributes) {
    const {
        passenger,
        passengerGroup,
        tripType,
        updateBothTripSegments,
        index,
    } = attributes

    const isMount = useComponentDidMount()
    const dispatch = useAppDispatch()
    const {updatePassengerGroup} = useUpdatePassengerGroup()

    const {
        ticket,
        passengers,
    } = useAppSelector((state) => state?.checkOrder)

    useEffect(() => {
        const updateGroups = async () => {
            dispatch(setPassengerDataUpdating(true))
            try {
                const {
                    passenger: updatedPassenger,
                    ticket: updatedTicket,
                } = await updatePassengerGroup({
                    passenger,
                    ticket,
                    passengerGroup,
                    tripType,
                    updateBothTripSegments,
                })

                const newPassengerArray = updateArrayMember(passengers, index, 'group', updatedPassenger, true)
                dispatch(setCheckOrderPassengers(newPassengerArray))
                dispatch(setTicket(updatedTicket))
                dispatch(setIsBuyingPremiumSupport(false))
                dispatch(setIsBuyingSMSNotification(false))
            } catch (error) {
                dispatch(setPassengerDataUpdating(false))
            }
        }
        if (!isMount) {
            updateGroups().then(() => dispatch(setPassengerDataUpdating(false)))
        }
    }, [passengerGroup])
}