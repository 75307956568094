import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconPowerSocket(props: IconType) {
    const {
        width = '35',
        height = '35',
        viewBox = '0 0 35 35',
        title = 'IconPowerSocket',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none" fillRule="evenodd" transform="translate(6)">
                    <path
                        d="m6 0c.82842712 0 1.5.67157288 1.5 1.5v7.5h-3v-7.5c0-.82842712.67157288-1.5 1.5-1.5z"
                        fill="#e7e7e7"
                    />
                    <path
                        d="m16 0c.8284271 0 1.5.67157288 1.5 1.5v7.5h-3v-7.5c0-.82842712.6715729-1.5 1.5-1.5z"
                        fill="#e7e7e7"
                    />
                    <g transform="translate(1 8)">
                        <path
                            d="m1 0h9v18h-3c-3.86599325 0-7-3.1340068-7-7v-10c0-.55228475.44771525-1 1-1z"
                            fill="#505050"
                        />
                        <path
                            d="m15 0h4c.5522847 0 1 .44771525 1 1v10c0 3.8659932-3.1340068 7-7 7h-3v-13c0-2.76142375 2.2385763-5 5-5z"
                            fill="#464646"
                        />
                    </g>
                    <g transform="translate(0 8)">
                        <path
                            d="m1 0h10v5h-10c-.55228475 0-1-.44771525-1-1v-3c0-.55228475.44771525-1 1-1z"
                            fill="#505050"
                        />
                        <path d="m11 0h10c.5522847 0 1 .44771525 1 1v3c0 .55228475-.4477153 1-1 1h-10z" fill="#464646"/>
                    </g>
                    <rect fill="#3c3c3c" height="1" rx=".2" width="10" x="11" y="13"/>
                    <rect fill="#464646" height="1" rx=".2" width="10" x="1" y="13"/>
                    <g transform="translate(7 26)">
                        <path
                            d="m.2 0h3.8v1h-3.8c-.11045695 0-.2-.08954305-.2-.2v-.6c0-.11045695.08954305-.2.2-.2z"
                            fill="#505050"
                        />
                        <path
                            d="m1.2 1h2.8v1h-2.8c-.11045695 0-.2-.08954305-.2-.2v-.6c0-.11045695.08954305-.2.2-.2z"
                            fill="#505050"
                        />
                        <g fill="#464646">
                            <path
                                d="m2.2 2h1.8v1h-1.8c-.11045695 0-.2-.08954305-.2-.2v-.6c0-.11045695.08954305-.2.2-.2z"
                            />
                            <path
                                d="m2.2 3h1.8v1h-1.8c-.11045695 0-.2-.08954305-.2-.2v-.6c0-.11045695.08954305-.2.2-.2z"
                            />
                        </g>
                    </g>
                    <g transform="matrix(-1 0 0 1 15 26)">
                        <path
                            d="m.2 0h3.8v1h-3.8c-.11045695 0-.2-.08954305-.2-.2v-.6c0-.11045695.08954305-.2.2-.2z"
                            fill="#464646"
                        />
                        <path
                            d="m1.2 1h2.8v1h-2.8c-.11045695 0-.2-.08954305-.2-.2v-.6c0-.11045695.08954305-.2.2-.2z"
                            fill="#464646"
                        />
                        <g fill="#3c3c3c">
                            <path
                                d="m2.2 2h1.8v1h-1.8c-.11045695 0-.2-.08954305-.2-.2v-.6c0-.11045695.08954305-.2.2-.2z"
                            />
                            <path
                                d="m2.2 3h1.8v1h-1.8c-.11045695 0-.2-.08954305-.2-.2v-.6c0-.11045695.08954305-.2.2-.2z"
                            />
                        </g>
                    </g>
                    <path
                        d="m9.2 30h1.8v5h-1.8c-.11045695 0-.2-.0895431-.2-.2v-4.6c0-.1104569.08954305-.2.2-.2z"
                        fill="#505050"
                    />
                    <path
                        d="m11.2 30h1.8v5h-1.8c-.1104569 0-.2-.0895431-.2-.2v-4.6c0-.1104569.0895431-.2.2-.2z"
                        fill="#464646"
                        transform="matrix(-1 0 0 1 24 0)"
                    />
                </g>
            )}
        />

    );
}

export default IconPowerSocket;
