import CompanyType from "@SearchResults/types/company/Company.type";
import Row from "@Containers/components/Row";
import useFilterByCompany from "@SearchResults/features/sidebar/features/companyFilters/hooks/useFilterByCompany";
import {isEmpty} from "@Array/isEmpty";
import GenericCheckbox from "@Generic/Checkbox/GenericCheckbox";
import useDispatchFilterEvents from "@SearchResults/features/sidebar/hooks/useDispatchFilterEvent";

type CarrierProps = {
    company: CompanyType
}

function Company({company}: CarrierProps) {
    const {
        onPress,
        filteredCompanies,
    } = useFilterByCompany()

    const isChecked = isEmpty(filteredCompanies)
        ? false
        : filteredCompanies.map(({id}) => id).includes(company.id)

    const handleOnBlur = useDispatchFilterEvents('CompanyFilter', company.name)

    return (
        <Row center>
            <GenericCheckbox
                checked={isChecked}
                onClick={() => onPress(company)}
                onBlur={handleOnBlur}
            />
            <span>
                {company.name}
            </span>
        </Row>
    );
}

export default Company;