import Row from "@Containers/components/Row";
import Translation from "@Translation/components/Translation";

type AncillarySummaryProps = {
    price: number
    currencySymbol: string,
    ancillaryNameKey: string,
}
const AncillarySummary = ({price, currencySymbol, ancillaryNameKey}: AncillarySummaryProps) => (
    <Row justify center className="premium-support-price-display">
            <span>
                <Translation translationKey={ancillaryNameKey}/>
            </span>
        <span>
            {price} {currencySymbol}
        </span>
    </Row>
)
export default AncillarySummary;