import useIsSmallTablet from "@Hooks/selector/useIsSmallTablet";
import useIsMobile from "@Hooks/selector/useIsMobile";
import useRouteMatch from "@Routing/hooks/useRouteMatch";
import useHasActiveOverlayOnSmallScreen from "@Features/domElements/hooks/useHasActiveOverlayOnSmallScreen";
import {useLayoutEffect} from "react";

/**
 * Toggles footer and credit card DOM elements
 */
export default function useToggleFooterAndCreditCardElement() {
    const hasActiveOverlayOnSmallScreen = useHasActiveOverlayOnSmallScreen()
    const isCheckOrder = useRouteMatch('checkOrder')
    const isMyProfile = useRouteMatch('myProfile')

    const isMobile = useIsMobile()
    const isSmallTablet = useIsSmallTablet()
    const isSmallScreen = isMobile || isSmallTablet

    const toggleFooterAndCreditCardElement = () => {
        const footer = document.getElementById('footer');
        const creditCards = document.getElementsByClassName('gb-credit__cards')[0];

        if (!footer || !creditCards) {
            return;
        }

        if (hasActiveOverlayOnSmallScreen || isCheckOrder || (isMyProfile && isSmallScreen)) {
            footer.classList.add('hide')
            creditCards.classList.add('hide')
        } else {
            footer.classList.remove('hide')
            creditCards.classList.remove('hide')
        }
    }

    useLayoutEffect(() => {
        toggleFooterAndCreditCardElement()
    }, [hasActiveOverlayOnSmallScreen, isCheckOrder, isMyProfile, isSmallScreen]);
}