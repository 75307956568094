import React from 'react';
import Tab from "@Tabs/components/Tab";
import TabType from "@Tabs/types/Tab.type";

interface TabsProps {
    tabsToShow: TabType[],
    activeTab: TabType,
}

const Tabs = ({tabsToShow, activeTab}: TabsProps) => (
    <ul className="nav nav-tabs gb--tabs-nav">
        {tabsToShow.map((tabToShow) => (
            <Tab
                key={tabToShow.tabType}
                activeTab={activeTab}
                tab={tabToShow}
            />
        ))}
    </ul>
)

export default Tabs
