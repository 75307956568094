import axios from "axios";
import {useEffect, useRef} from "react";
import {useLocation} from "react-router";
import {useComponentDidMount} from "../lifecycle/useComponentDidMount";
import useEventListener from "../eventListeners/useEventListener";

const {CancelToken} = axios;

interface UseCancelTokenAttributes {
    cancelMessage?: string,       // error message shown via ajax
    cancelOnUnload?: boolean,     // cancel ajax when page unloads (http request)
    cancelOnNavigation?: boolean, // cancel ajax on react router navigation
}

export const useCancelToken = (properties: UseCancelTokenAttributes) => {
    const {
        cancelMessage = 'User changed query',
        cancelOnUnload = true,
        cancelOnNavigation = true,
    } = properties

    const token = useRef(CancelToken.source())
    const isUpdate = !useComponentDidMount()
    const {state} = useLocation()

    const cancelRequest = () => {
        if (isUpdate) {
            token.current.cancel(cancelMessage)
            token.current = CancelToken.source()
        }
    }

    const cancelRequestOnUnload = () => {
        if (cancelOnUnload) {
            cancelRequest()
        }
    }

    const cancelRequestOnNavigation = () => {
        if (cancelOnNavigation) {
            cancelRequest()
        }
    }

    useEffect(cancelRequestOnNavigation, [state])
    useEventListener('beforeunload', cancelRequestOnUnload)

    return {
        cancelToken: token.current.token,
        cancelRequest,
    }
};
