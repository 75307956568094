import CurrencyType, {CurrencyVal} from "@Currency/types/Currency.type";
import saveInLocalStorage from "./saveInLocalStorage";
import {LocaleType} from "@/types/Locale.type";

/**
 * Loads currency from local storage if it exists, otherwise sets it based on locale
 */

type CurrencyProps = {
    locale: LocaleType,
    supportedCurrencies: CurrencyVal[],
}

export default function loadCurrency({locale, supportedCurrencies}: CurrencyProps): CurrencyType {
    const setCurrency = localStorage.getItem('currency') as CurrencyVal
    const isValid = supportedCurrencies.includes(setCurrency)
    const currencyFromParam = new URLSearchParams(window.location.search).get('currency') as CurrencyVal

    const setCurrencyObject = (currency: CurrencyVal): CurrencyType => ({
        val: currency,
        sign: '$',
        ratio: 1,
    })

    if (currencyFromParam && supportedCurrencies.includes(currencyFromParam)) {
        saveInLocalStorage(currencyFromParam)
        return setCurrencyObject(currencyFromParam)
    }

    if (!setCurrency || !isValid) {
        switch (locale.toUpperCase()) {
            case 'CS':
                saveInLocalStorage('CZK')
                return setCurrencyObject('CZK')
            case 'PL':
                saveInLocalStorage('PLN')
                return setCurrencyObject('PLN')
            case 'BS':
                saveInLocalStorage('BAM')
                return setCurrencyObject('BAM')
            case 'NO':
                saveInLocalStorage('NOK')
                return setCurrencyObject('NOK')
            case 'SR':
                saveInLocalStorage('RSD')
                return setCurrencyObject('RSD')
            case 'EN-GB':
                saveInLocalStorage('GBP')
                return setCurrencyObject('GBP')
            case 'EN-US':
                saveInLocalStorage('USD')
                return setCurrencyObject('USD')
            default:
                saveInLocalStorage('EUR')
                return setCurrencyObject('EUR')
        }
    } else {
        return {
            val: setCurrency,
            sign: '$',
            ratio: 1,
        }
    }
}