import React, {createContext, ReactNode} from 'react';
import {useCancelToken} from "@Hooks/promises/useCancelToken";

interface PromiseTokenContextProps {
    children: ReactNode
}

const promiseTokenContextInitialValue = {
    cancelToken: null,
    cancelRequest: null,
}

const PromiseTokenContext = createContext(promiseTokenContextInitialValue);
const {Provider} = PromiseTokenContext

function PromiseTokenProvider({children}: PromiseTokenContextProps) {
    const token = useCancelToken({
        cancelMessage: 'Reached max number of suggestions or navigated away',
    })

    return (
        <Provider value={token}>
            {children}
        </Provider>
    );
}

export {PromiseTokenProvider, PromiseTokenContext};