/**
 * Get station airport slug
 * @return {boolean}
 * @param slugToMatch {string}
 */
export default function getAirportSlug(slugToMatch: string): string {
    const airportSlugs = {
        en: [`${slugToMatch}-airport`],
        hr: [`${slugToMatch}-zracna-luka`],
        it: [`${slugToMatch}-aeroporto`],
        de: [`${slugToMatch}-flughafen`],
        pl: [`${slugToMatch}-lotnisko`],
        bs: [`${slugToMatch}-aerodrom`],
        sr: [`${slugToMatch}-aerodrom`],
        da: [`${slugToMatch}-lufthavn`],
        es: [`${slugToMatch}-aeropuerto`],
        fr: [`${slugToMatch}-aeroporto`],
        pt: [`${slugToMatch}-aeroporto`],
        sv: [`${slugToMatch}-flygplats`],
        sl: [`${slugToMatch}-letalisce`],
        cs: [`${slugToMatch}-letiste`],
        hu: [`${slugToMatch}-repueloter`],
        nl: [`${slugToMatch}-luchthaven`],
        no: [`${slugToMatch}-flygplats`],
    }

    return airportSlugs[window.currentLocale ?? 'en']
}