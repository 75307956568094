import useIsMobile from "@Hooks/selector/useIsMobile";
import useIsSmallTablet from "@Hooks/selector/useIsSmallTablet";
import useRouteMatch from "@Routing/hooks/useRouteMatch";
import {useAppSelector} from "@Hooks/selector/useAppSelector";

export default function useHasActiveOverlayOnSmallScreen() {
    const isMobile = useIsMobile()
    const isSmallTablet = useIsSmallTablet()

    const isSmallScreen = isMobile || isSmallTablet

    const isAdditionalInfo = useRouteMatch('additionalInfo')
    const {
        mobileOverlayActive,
        formInputSelected,
        mobileLanguagesActive,
        sortingOptionsActive,
    } = useAppSelector((state) => (state?.mobile))

    const someKindOfOverlayActive = !!mobileOverlayActive
        || !!formInputSelected
        || !!mobileLanguagesActive
        || !!isAdditionalInfo
        || !!sortingOptionsActive

    return isSmallScreen && someKindOfOverlayActive
}