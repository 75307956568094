import React from 'react';
import formatDate from "@DateTime/functions/formatDate";
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

interface IconCalendarWithDateProps extends IconType {
    date: string
}

function IconCalendarWithDate(props: IconCalendarWithDateProps) {
    const {
        width = '56',
        height = '55',
        fill = '#fff',
        viewBox = '0 0 56 55',
        title = 'IconCalendarWithDate',
        date,
        ...rest
    } = props

    const day = formatDate(date, "dd", true)
    const month = formatDate(date, "MMMM", true)

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none" fillRule="evenodd">
                    <g fillRule="nonzero">
                        <path
                            d="m44.6507937 54.015873h-39.28571433c-2.40079366 0-4.36507937-1.9642857-4.36507937-4.3650793v-39.2857143c0-2.40079369 1.96428571-4.3650794 4.36507937-4.3650794h43.65079363c2.4007937 0 4.3650794 1.96428571 4.3650794 4.3650794v34.9206349c0 4.8015873-1.2562991 8.7301587-8.7301587 8.7301587z"
                            fill="#fff"
                        />
                        <path
                            d="m44.9603175 21.6746032h-39.28571433c-2.40079365 0-4.36507936-.6545455-4.36507936-1.4545455v-13.09090907c0-.8 1.96428571-1.45454546 4.36507936-1.45454546h43.65079363c2.4007937 0 4.3650794.65454546 4.3650794 1.45454546v14.54545457c0 1.6-1.2562991 0-8.7301587 0z"
                            fill="#b5b7d1"
                        />
                        <path
                            d="m44.5238095 55h-38.84920633c-3.14285714 0-5.67460317-2.531746-5.67460317-5.6746032v-21.8253968c0-.7420635.56746032-1.3095238 1.30952381-1.3095238s1.30952381.5674603 1.30952381 1.3095238v21.8253968c0 1.702381 1.3531746 3.0555556 3.05555555 3.0555556h38.84920633c.7420635 0 1.3095238.5674603 1.3095238 1.3095238s-.5674603 1.3095238-1.3095238 1.3095238z"
                            fill="#070c63"
                        />
                        <path
                            d="m53.1666667 24.0793651h-51.85714289c-.74206349 0-1.30952381-.5674603-1.30952381-1.3095238v-12.7301588c0-3.1428571 2.53174603-5.67460313 5.67460317-5.67460313h6.54761903c.7420635 0 1.3095238.56746031 1.3095238 1.3095238 0 .7420635-.5674603 1.30952381-1.3095238 1.30952381h-6.54761903c-1.70238095 0-3.05555555 1.35317461-3.05555555 3.05555552v11.420635h50.54761908c.7420635 0 1.3095238.5674603 1.3095238 1.3095238s-.6111111 1.3095238-1.3095238 1.3095238z"
                            fill="#070c63"
                        />
                        <g fill="#070c63" transform="translate(15.277778)">
                            <path
                                d="m16.5873016 4.36507937h-9.60317462v-.87301588c0-1.92063492-1.57142857-3.49206349-3.49206349-3.49206349s-3.49206349 1.57142857-3.49206349 3.49206349v4.36507937c0 1.92063492 1.57142857 2.61904764 3.49206349 2.61904764.74206349 0 1.30952381-.56746034 1.30952381-1.30952383 0-.7420635-.56746032-1.30952381-1.30952381-1.30952381-.48015873 0-.48015873 0-.87301587 0v-4.36507937c0-.48015873.39285714-.87301587.87301587-.87301587s.87301588.39285714.87301588.87301587v2.18253968c0 .7420635.56746031 1.30952381 1.3095238 1.30952381h10.91269843c.7420635 0 1.3095238-.56746031 1.3095238-1.30952381 0-.74206349-.5674603-1.3095238-1.3095238-1.3095238z"
                            />
                            <path
                                d="m34.047619 4.36507937h-7.4206349v-.87301588c0-1.92063492-1.5714285-3.49206349-3.4920635-3.49206349-1.9206349 0-3.4920635 1.57142857-3.4920635 3.49206349v4.36507937c0 1.92063492 1.5714286 2.61904764 3.4920635 2.61904764.7420635 0 1.3095238-.56746034 1.3095238-1.30952383 0-.7420635-.5674603-1.30952381-1.3095238-1.30952381-.4801587 0-.388028 0-.8730158 0v-4.36507937c0-.48015873.3928571-.87301587.8730158-.87301587.4801588 0 .8730159.39285714.8730159.87301587v2.18253968c0 .7420635.5674603 1.30952381 1.3095238 1.30952381h8.7301587c1.702381 0 3.0555556 1.35317461 3.0555556 3.05555552v24.8809524c0 .7420635.5674603 1.3095238 1.3095238 1.3095238s1.3095238-.5674603 1.3095238-1.3095238v-24.8809524c0-3.1428571-2.531746-5.67460313-5.6746032-5.67460313z"
                            />
                            <path
                                d="m34.047619 30.9920635h-5.6637951v2.6132178h5.6637951c1.702381 0 3.0555556 1.292606 3.0555556 2.8031694v17.4295775c0 .6584507.5674603 1.1619718 1.3095238 1.1619718s1.3095238-.5035211 1.3095238-1.1619718v-17.4295775c0-2.7887324-2.531746-5.4163872-5.6746032-5.4163872z"
                                transform="matrix(1 0 0 -1 0 85.992064)"
                            />
                        </g>
                    </g>
                    <text fill="#070c63" fontFamily="FSJoey-Medium, FS Joey" fontSize="8" fontWeight="400">
                        <tspan x="50%" y="18" textAnchor="middle">{month}</tspan>
                    </text>
                    <text
                        fill="#070c63"
                        fontFamily="HelveticaNeue-Bold, Helvetica Neue"
                        fontSize="24"
                        fontWeight="bold"
                    >
                        <tspan x="50%" y="47" textAnchor="middle">{day}</tspan>
                    </text>
                </g>
            )}
        />
    )
}

export default IconCalendarWithDate;
