import useSetFilterBoundaries from "@SearchResults/features/sidebar/hooks/useSetFilterBoundaries";
import useSetMaxRoutePrice from "@SearchResults/features/banners/hooks/useSetMaxRoutePrice";
import useSetRouteCounts from "@SearchResults/hooks/useSetRouteCounts";
import {useCallback, useEffect} from "react";
import CurrencyType from "@Currency/types/Currency.type";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";
import RouteType from "@Route/types/Route.type";
import useResetTabSpecificFilters from "@SearchResults/features/sidebar/hooks/useResetTabSpecificFilters";
import useActiveTabFromParams from "@SearchResults/hooks/useActiveTabFromParams";
import useIsMobile from "@Hooks/selector/useIsMobile";

type SideEffectParams = {
    preparedRoutes: RouteWithAddedPropsAndPricesType[],
    allRoutes: RouteType[],
    isPastDeparture: boolean,
    searchDate: string,
    currency: CurrencyType,
}

/**
 * This hook invokes various side effects on page which cause full re-renders as they propagated via redux
 * Must be explicitly used with a callback as otherwise it would cause render infinite loop
 */
export default function useRoutesListSideEffects(sideEffectParams: SideEffectParams) {
    const {
        preparedRoutes,
        allRoutes,
        searchDate,
        isPastDeparture,
        currency,
    } = sideEffectParams

    const {setFilterLimits} = useSetFilterBoundaries(searchDate)
    const {setMaxRoutePrice} = useSetMaxRoutePrice()
    const {setRouteCounts} = useSetRouteCounts()

    const updateSideEffectValues = useCallback(() => {
        if (!isPastDeparture) {
            setFilterLimits(preparedRoutes)
            setMaxRoutePrice(preparedRoutes)
        }
    }, [
        preparedRoutes.length,
        allRoutes.length,
        currency,
    ])

    useEffect(updateSideEffectValues, [allRoutes.length])

    useEffect(() => {
        setRouteCounts(allRoutes.length, preparedRoutes.length)
    }, [preparedRoutes.length, allRoutes.length])
}