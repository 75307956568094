import {useState} from "react";

export default function useFormFields(initialState) {
    const [fields, setFieldValues] = useState(initialState);

    return [
        fields,
        function (e) {
            setFieldValues({
                ...fields,
                [e.target.id]: e.target.value,
            });
        },
    ];
}