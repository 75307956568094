import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconAlert(props: IconType) {
    const {
        width = '14',
        height = '14',
        fill = '#f03a47',
        viewBox = '0 0 54 46',
        title = 'IconAlert',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none">
                    <path
                        d="m27 .7c1.8 0 3.4.9 4.3 2.4l21.2 35.3c.9 1.6.9 3.5 0 5-.9 1.6-2.6 2.5-4.4 2.5h-42.3c-1.9 0-3.5-.9-4.4-2.5s-.8-3.4.1-5l21.2-35.3c.9-1.5 2.5-2.4 4.3-2.4zm2 33.1c-1.1-1.1-3.1-1.1-4.2 0-.6.5-.9 1.3-.9 2.1 0 .2.1.4.1.6.1.2.1.4.2.6s.2.3.3.5.3.4.4.5c.5.6 1.3.9 2.1.9s1.6-.3 1.9-.9c.2-.2.3-.3.4-.5.1-.1.2-.3.3-.5s.2-.4.2-.6c.1-.2.1-.4.1-.6 0-.8-.3-1.5-.9-2.1zm-2-20.8c-1.7 0-3 1.3-3 3v10c0 1.7 1.3 3 3 3s3-1.3 3-3v-10c0-1.7-1.3-3-3-3z"
                        fill={fill}
                    />
                </g>
            )}
        />

    );
}

export default IconAlert;
