import {routeTransferTypes} from "@Route/features/transportType/constants/routeTransferTypes";
import {RouteTransferTypeValuesType} from "@Route/features/transportType/types/routeTransferType.type";

/**
 * Returns main company transport type
 * @see main/symfony/src/Core/Entity/CompanyStore.php
 * @param company
 * @returns ('bus'|'ferry'|'train'|'plane'|'minivan'|'privateMinivan'|'privateBus')
 */
export default function getMainCompanyTransportType(company):RouteTransferTypeValuesType {
    return routeTransferTypes[company?.style?.main_transport_type_id] ?? 'bus'
}