import {useState} from "react";

export default function useHandleVoucherCardDisplay() {
    const [accordionOpen, setAccordionOpen] = useState(false)
    const applyCardOpenedClassName = () => {
        setAccordionOpen(true)
    }

    const applyCardClosedClassName = () => {
        setAccordionOpen(false)
    }

    const cardClassName = `gb--card${accordionOpen ? "-expanded opened" : ""}`

    return {
        applyCardOpenedClassName,
        applyCardClosedClassName,
        cardClassName,
        accordionOpen,
    }
}