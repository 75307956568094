import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {SearchResponseType} from "@SearchResults/types/SearchResponse.type";
import RouteType from "@Route/types/Route.type";
import IntermodalRouteType from "@Intermodal/types/IntermodalRoute.type";

export default function useNavigateBackIfProvidedRouteDoesNotExist(singleSelectedRoute: RouteType|IntermodalRouteType, searchResults: SearchResponseType) {
    const {
        totalApiCount,
        resolvedApiCount,
    } = searchResults.data.result;
    const navigate = useNavigate()
    const apiRoutesLoading = resolvedApiCount < totalApiCount

    useEffect(() => {
        if (!singleSelectedRoute && !apiRoutesLoading) {
            // eslint-disable-next-line no-console
            console.warn("single selected route does not exist, navigating back")
            navigate('..')
        }
    }, [searchResults])
}