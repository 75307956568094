export function googleTagManagerSelectItemEvent(ticket) {
    window.dataLayer.push({
        event: 'select_item',
        ecommerce: {
            select_item: {
                items: [
                    {
                        item_id: ticket.id,
                        priceInHrk: ticket.priceInHrk, // todo #hrk2eur #llalic
                    },
                ],
            },
        },
    })
}

export function googleTagManagerBeginCheckoutEvent(ticket) {
    window.dataLayer.push({
        event: 'begin_checkout',
        ecommerce: {
            begin_checkout: {
                items: [
                    ticket,
                ],
            },
        },
    });
}