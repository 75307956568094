import React from 'react';
import GenericSvgIcon from "../GenericSvgIcon";
import IconType from "@/types/Icon.type";

interface IconInfoCircleProps extends IconType {
    backgroundFill?: string,
}

function IconInfoCircle(props: IconInfoCircleProps) {
    const {
        width = '20',
        height = '20',
        fill = '#fff',
        backgroundFill = '#4a79e2',
        viewBox = '0 0 20 20',
        title = 'IconInfoCircle',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none" fillRule="evenodd">
                    <circle cx="10" cy="10" fill={fill} fillRule="nonzero" r="9"/>
                    <circle cx="10" cy="10" fill={backgroundFill} fillRule="nonzero" r="7.3125"/>
                    <g
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeWidth="1.25"
                        transform="translate(6.707107 4.578427)"
                    >
                        <path
                            d="m1 5.12868 4.242641 4.24264"
                            transform="matrix(.70710678 .70710678 -.70710678 .70710678 6.040738 -.083631)"
                        />
                        <path
                            d="m2.944544 1.323223.353553.353554"
                            transform="matrix(.70710678 .70710678 -.70710678 .70710678 1.974874 -1.767767)"
                        />
                    </g>
                    <circle cx="10" cy="10" r="9" stroke="#070c63" strokeWidth="1.125"/>
                </g>
            )}
        />

    );
}

export default IconInfoCircle;
