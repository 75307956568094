import React from 'react';
import IconExchange from "@Icons/IconExchange";
import useFormMethods from "@Form/hooks/useFormMethods";
import useCompanyStyle from "@Hooks/selector/useCompanyStyle";
import useIsCompanyStore from "@Hooks/selector/useIsCompanyStore";

function StationSwitchButton() {
    const {toggleStationHandler} = useFormMethods()
    const isCompanyStore = useIsCompanyStore()
    const {checkOrderStepsBackgroundColor} = useCompanyStyle()
    const btnToggleCompanyColor = isCompanyStore ? checkOrderStepsBackgroundColor : '#7ed321';

    return (
        <button
            type="button"
            className="react-autosuggest__switch-button"
            onClick={toggleStationHandler}
        >
            <IconExchange
                fill={btnToggleCompanyColor}
                width="18"
                height="18"
                style={{verticalAlign: 'middle', background: '#fff'}}
            />
        </button>
    );
}

export default StationSwitchButton;