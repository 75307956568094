import React, {ReactNode} from 'react';
import {toggleForm} from "@Form/reducers/formSlice";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";

interface TravelInfoClickInterceptorsProps {
    children: ReactNode,
}

function TravelInfoClickInterceptor({children}: TravelInfoClickInterceptorsProps) {
    const dispatch = useAppDispatch()

    const toggleFormAction = () => {
        dispatch(toggleForm())
    }

    return (
        <div onClick={toggleFormAction} role="button">
            {children}
        </div>
    );
}

export default TravelInfoClickInterceptor;