import React, {MutableRefObject} from 'react';
import Column from "@Containers/components/Column";
import useDirectionAwareTicketTypeTranslation from "@Translation/hooks/useDirectionAwareTicketTypeTranslation";
import RouteActionButton from "@Route/features/ticketPurchase/components/RouteActionButton";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";
import CompanyType from "@SearchResults/types/company/Company.type";
import RouteNotesAndTicketType from "@Route/components/Container/RouteNotesAndTicketType";
import MobileContentContainer from "@Route/features/mobile/components/MobileContentContainer";
import RouteItinerary from "@Route/components/Sections/RouteItinerary";
import ParamsType from "@SearchResults/types/params/Params.type";
import { displayTicketType } from '@/functions/overrides/displayTicketType';
import {isEmpty} from "@Array/isEmpty";

interface MobileRouteTravelDetailsProps {
    route: RouteWithAddedPropsAndPricesType,
    company: CompanyType,
    scrollRef: MutableRefObject<any>,
    params: ParamsType,
    isReturn: boolean,
}

const MobileRouteTravelDetails = ({route, company, scrollRef, params, isReturn}: MobileRouteTravelDetailsProps) => {
    const title = useDirectionAwareTicketTypeTranslation()

    return (
        <MobileContentContainer title={title} scrollRef={scrollRef}>
            <Column className="gb--route-details">
                <RouteNotesAndTicketType
                    displayTicketPrintType={displayTicketType(route)}
                    displayTicketType={false}
                    hasMTicket={route.status.hasMTicket}
                    notes={route.travelInfo.notes}
                    isCurrentRouteSingle={route.status.isReturn}
                />
                <div className="route-expanded-details-section">
                    <RouteItinerary
                        route={route}
                        companyLetterCode={company?.letterCode}
                    />
                </div>
                {isEmpty(route?.passengerClasses) && (
                    <RouteActionButton
                        isReturn={isReturn}
                        params={params}
                        hideLabels
                        route={route}
                    />
                )}
            </Column>
        </MobileContentContainer>
    );
}

export default MobileRouteTravelDetails;