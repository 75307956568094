import React from 'react';
import {useTranslation} from "@Translation/hooks/useTranslation";
import Column from "@Containers/components/Column";
import Row from "@Containers/components/Row";
import useIsMobile from "@Hooks/selector/useIsMobile";
import IconChevron from "@Icons/IconChevron";

interface VoucherStatusProps {
    companyList: string[]
    isOpened: boolean,
}

function VoucherStatusInfo({companyList, isOpened}: VoucherStatusProps) {
    const {
        redeem: redeemLabel,
        limitations_may_apply: limitationsMayApplyLabel,
    } = useTranslation('my_profile.my_vouchers')

    const isMobile = useIsMobile()

    return (
        <Column className="voucher-status info-field-medium">
            <Row className="gb--emphasize-default" justify>
                {`${redeemLabel} ${companyList.length !== 0 ? companyList.join(", ") : "GetByBus, GetByFerry"}`}
                {isMobile && (
                    <IconChevron
                        chevronOrientation={isOpened ? "up" : "down"}
                        height="22px"
                        width="22px"
                    />
                )}
            </Row>
            {!isMobile && (
                <div>{limitationsMayApplyLabel}</div>
            )}
        </Column>
    );
}

export default VoucherStatusInfo;