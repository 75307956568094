import React from 'react';

interface RoutesListTitleProps {
    title: string,
}

const RoutesListTitle = ({title}: RoutesListTitleProps) => (
    <div className="gb--notification gb--notification-green gb--notification-large gb--notification-title">
        <span>
            {title}
        </span>
    </div>
);

export default RoutesListTitle;