import React, {ReactNode, useState} from 'react';
import Notification from "@Features/notification/components/Notification";
import InputAdornment from "./InputAdornment";
import {
    strengthColor,
    strengthIndicator,
    strengthLabel,
    strengthLength
} from "@User/functions/validatePasswordStrength";
import {useTranslation} from "@Translation/hooks/useTranslation";

interface PasswordProps {
    id: string,
    value: string,
    label: string,
    showError: boolean,
    errorMessage?: string | ReactNode,
    onChange: (any?) => any,
    onBlur?: (any?) => any,
    strengthValidator?: any,
}

function Password(props: PasswordProps) {
    const {
        id,
        value,
        label,
        showError,
        errorMessage,
        onChange,
        onBlur,
        strengthValidator,
    } = props

    const {show, hide} = useTranslation('sys')

    const [showPassword, setShowPassword] = useState(false)
    const togglePasswordVisibility = () => setShowPassword(!showPassword)

    const strength = strengthIndicator(value)
    const color = strengthColor(strength)
    const width = strengthLength(strength)
    const strengthText = strengthLabel(strength)

    const style = {
        width,
        backgroundColor: color,
        borderColor: color,
    }

    return (
        <div className="form__group">
            <label htmlFor={id} className={strengthValidator ? 'gb--label__password' : ''}>
                {label}
                {strengthValidator && !!strength && (
                    <>
                        {' '} - {strengthText}
                        <span
                            className="gb--input-standalone__password"
                        >
                            <span style={style}/>
                        </span>
                    </>
                )}
            </label>

            <div className="form__input">
                <input
                    className="gb--input-standalone"
                    id={id}
                    type={showPassword ? 'text' : 'password'}
                    name={id}
                    onChange={onChange}
                    value={value}
                    placeholder={label}
                    onBlur={onBlur}
                />
                <InputAdornment
                    onClick={togglePasswordVisibility}
                    text={!showPassword ? show : hide}
                />
            </div>

            {showError && (
                <Notification>
                    {errorMessage}
                </Notification>
            )}
        </div>
    );
}

export default Password;