import {useEffect, useRef, useState} from "react";
import useAsyncEffect from "@Hooks/promises/useAsyncEffect";
import {isNotEmpty} from "@Array/isNotEmpty";
import {isEmpty} from "@Array/isEmpty";
import AutoSuggestType from "@Features/autosuggest/types/AutoSuggest.type";

/**
 * Generic autoSuggest component, used in conjunction with useAutoSuggest
 */
export default function useAutoSuggest(autoSuggest: AutoSuggestType) {
    const {
        suggestionsHandler,
        selection,
        suggestionClickHandler,
        updateContext,
        updateContextHandler,
        clearHandler,
        inputHandler,
        decorationDisplayHandler,
        suggestionLengthFetchLimit,
        focusHandler,
    } = autoSuggest

    const [input, setInput] = useState(selection ?? '')
    const [suggestions, setSuggestions] = useState([])
    const [contextSuggestions, setContextSuggestions] = useState([])
    const [position, setPosition] = useState(0)
    const [showSuggestions, setShowSuggestions] = useState(true)
    const [decoration, setDecoration] = useState(false)
    const inFocus = useRef(false)
    const fieldRef = useRef(null)

    const updateSuggestions = async () => {
        if (inFocus?.current && input?.length > suggestionLengthFetchLimit && input !== selection) {
            try {
                const response = await suggestionsHandler(input)
                if (response) {
                    setShowSuggestions(true)
                    setSuggestions(response)
                }
            } catch {
                setSuggestions(contextSuggestions)
            }
        }

        if (isNotEmpty(contextSuggestions) && input?.length <= suggestionLengthFetchLimit) {
            setSuggestions(contextSuggestions)
        }
    }

    const updateDecorationStatus = () => {
        if (decorationDisplayHandler) {
            setDecoration(
                (decorationDisplayHandler(input ?? '')
                    || contextSuggestions.length === suggestions.length)
                && isNotEmpty(contextSuggestions)
            )
        }
    }

    const updateOutsideContext = async () => {
        if (updateContext?.id && !selection) {
            try {
                const response = await updateContextHandler()
                if (response) {
                    setSuggestions(response)
                    setShowSuggestions(true)
                    setContextSuggestions(response)
                }
            } catch {
                setSuggestions([])
            }
        } else if (!updateContext?.id) {
            setSuggestions([])
            setContextSuggestions([])
        }
    }

    const clearSuggestions = () => {
        setPosition(0)
        setInput('')
        setSuggestions(contextSuggestions)
        clearHandler()
    }

    useEffect(() => {
        if (selection && fieldRef.current) {
            setInput(selection)
        }
    }, [selection])

    useEffect(() => {
        updateDecorationStatus()
        setPosition(0)
    }, [input, contextSuggestions, suggestions])

    useEffect(() => {
        if (focusHandler) {
            focusHandler(fieldRef?.current)
        }
    }, [updateContext?.id])
    useAsyncEffect(updateSuggestions, [input, selection])
    useAsyncEffect(updateOutsideContext, [updateContext?.id, selection])

    const setActiveSuggestionAsSelection = (position) => {
        suggestionClickHandler(suggestions[position])
    }

    const handleKeyDown = (e) => {
        const {keyCode} = e
        // Tab || enter
        if (keyCode === 9 || keyCode === 13) {
            setActiveSuggestionAsSelection(position)
            e.target.blur()
        }

        // Backspace
        if (keyCode === 8) {
            if (selection && selection !== input) {
                clearHandler()
            }
        }

        // Escape
        if (keyCode === 27) {
            clearSuggestions()
        }

        // Arrow left
        if (keyCode === 37) {
            e.target.blur();
        }
        // Arrow right
        if (keyCode === 39) {
            e.target.blur();
        }

        // Arrow up
        if (keyCode === 38) {
            if (position === 0) {
                setPosition(suggestions.length)
            } else {
                setPosition(position - 1)
            }
        }

        // Arrow down
        if (keyCode === 40) {
            if (position === suggestions.length) {
                setPosition(0)
            } else {
                setPosition(position + 1)
            }
        }
    }

    const onChange = ({target: {value}}) => {
        inFocus.current = true
        setInput(value)
    }

    const onBlur = () => {
        updateDecorationStatus()
        inFocus.current = false
        setTimeout(() => setShowSuggestions(false), 200)
    }

    const onClick = async () => {
        updateDecorationStatus()
        setShowSuggestions(true)
        if (isEmpty(contextSuggestions)) {
            await updateOutsideContext()
        }
    }

    const onFocus = () => {
        inFocus.current = true
    }

    const onSuggestionClick = () => {
        setShowSuggestions(false)

        return suggestionClickHandler
    }

    return {
        fieldRef,
        showSuggestions,
        input,
        position,
        suggestions,
        handleKeyDown,
        onChange,
        onClick,
        onBlur,
        onSuggestionClick,
        onFocus,
        shouldDisplayDecoration: decoration,
        setShowSuggestions,
        setSuggestions,
        suggestionsHandler,
    }
}