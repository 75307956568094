import React from 'react';
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import IconBusPlot from "@Icons/FilledOutline/IconBusPlot";
import IconFerryPlot from "@Icons/FilledOutline/IconFerryPlot";
import IconTrainPlot from "@Icons/FilledOutline/IconTrainPlot";
import IconCarPlot from "@Icons/FilledOutline/IconCarPlot";
import IconAirplanePlot from "@Icons/FilledOutline/IconAirplanePlot";

interface TransportationIconsProps {
    viewBox: string,
    width: string,
    preserveAspectRatio: string,
}

function TransportationIcons({viewBox, width, preserveAspectRatio}: TransportationIconsProps) {
    const transportTypeKeyword = useAppSelector((state) => state?.searchResults.transportTypeKeyword)

    const components = [
        {
            transportType: 'bus',
            icon: <IconBusPlot viewBox={viewBox} width={width} preserveAspectRatio={preserveAspectRatio}/>,
        },
        {
            transportType: 'train',
            icon: <IconTrainPlot viewBox={viewBox} width={width} preserveAspectRatio={preserveAspectRatio}/>,
        },
        {
            transportType: 'ferry',
            icon: <IconFerryPlot width={width} preserveAspectRatio={preserveAspectRatio}/>,
        },
        {
            transportType: 'transfer',
            icon: <IconCarPlot viewBox={viewBox} width={width} preserveAspectRatio={preserveAspectRatio}/>,
        },
    ]

    const setClassName = (component) => {
        let className = 'hero__icons--transport'
        if (transportTypeKeyword === component.transportType) {
            return className += ` hero__icons--transport-main ${component.transportType}__main`
        }
        if (component.transportType === 'ferry') {
            return className += '-ferry'
        }
        return className
    }

    const TransportTypeIcon = ({components}) => (
        components.map((component) => (
            <span className={setClassName(component)} key={component.transportType}>
                {component.icon}
            </span>
        ))
    )

    return (
        <>
            <TransportTypeIcon
                components={components}
            />
            <span className="hero__icons--transport">
                <IconAirplanePlot viewBox="0 0 155 85" width="100%" preserveAspectRatio="xMinYMax meet"/>
            </span>
        </>
    )
}

export default TransportationIcons;