import addMinutes from "date-fns/addMinutes";
import parseDate from "./parseDate";
import getServerTimeZoneDifferenceInMinutes from "./getServerTimeZoneDifferenceInMinutes";

/**
 * Returns date with added minute to server time (Europe/Zagreb)
 */
export default function convertToServerTime(date: Date | string): Date {
    const convertedDate = parseDate(date)
    const timezoneOffset = convertedDate.getTimezoneOffset();

    return addMinutes(convertedDate, timezoneOffset + getServerTimeZoneDifferenceInMinutes())
}