import {useEffect, useRef} from 'react';

/**
 * hook version of componentDidMount
 * @example
 *     const isMount = useComponentDidMount()
 *     useEffect(() => {
        if (isMount) {
            console.log('First Render');
        } else {
            console.log('Subsequent Render');
        }
    },[dependency]);
 */
export const useComponentDidMount = (): boolean => {
    const isMountRef = useRef(true);
    useEffect(() => {
        isMountRef.current = false;
    }, []);

    return isMountRef.current;
};
