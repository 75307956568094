import escapeRegexCharacters from "@String/escapeRegexCharacters";
import ajaxHandler from "@Ajax/v2/ajaxHandler";
import useLocale from "@Hooks/selector/useLocale";
import stripTags from "@Form/functions/stripTags";
import {setDepartureStationObject, setDestinationStationObject} from "@Form/reducers/formSlice";
import sortSuggestions from "@Form/functions/sortSuggestions";
import useIsMobile from "@Hooks/selector/useIsMobile";
import {isNotEmpty} from "@Array/isNotEmpty";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import StationInputFieldType from "@Form/types/StationInputField.type";
import AutoSuggestType from "@Features/autosuggest/types/AutoSuggest.type";
import {dismissAllOverlays} from "@/reducers/mobileSlice";

/**
 * Updates form input & displays suggestions
 * @param direction {'departure'|'destination'}
 * @param stationObject {object}
 */
export default function useFormStationInputAutoSuggest(direction: string, stationObject: StationInputFieldType): AutoSuggestType {
    const {
        departureStationObject,
        destinationStationObject,
    } = useAppSelector((state) => state?.form)

    const updateContext = (direction === 'departure') ? destinationStationObject : departureStationObject
    const directionInt = (direction === 'departure') ? 1 : 2
    const dispatch = useAppDispatch()
    const isMobile = useIsMobile()
    const locale = useLocale()
    const suggestionLengthFetchLimit = 2

    /**
     * Filters already selected station in other input
     * @param results {array}
     * @returns {*}
     */
    const filterSelectedStation = (results) => {
        if (updateContext?.id && isNotEmpty(results)) {
            return results.filter(((result) => (parseInt(result.value, 10) !== updateContext.id)))
        }

        return results
    }

    /**
     * Fetches new suggestions array
     * @param input
     * @returns {Promise<*[]|*>}
     */
    const fetchSuggestions = async (input) => {
        if (input) {
            const value = escapeRegexCharacters((typeof input === "string") ? input : input?.label)
            const url = `/stations.php?Lang=${locale}&term=${value}&fullCountry=1&v=${window.searchFormVersion ?? '1'}`

            if (value.length > 2) {
                try {
                    const response = await ajaxHandler({
                        url,
                    })

                    if (response.status === 200) {
                        return sortSuggestions(filterSelectedStation(response?.data ?? []), input)
                    }
                } catch (error) {
                    // eslint-disable-next-line no-console
                    console.log(error)
                    return []
                }
            }
        }
    }

    /**
     * Fetches top station when station on one of input fields is selected and other is blank
     * @param direction
     * @param stationId
     * @returns {Promise<FlatArray<[]|*, number>[]|*[]>}
     */
    const fetchTopStations = async (direction, stationId) => {
        if (stationId) {
            try {
                const response = await ajaxHandler({
                    url: `/ajax/get-top-selling-stations?direction=${direction}&stationId=${stationId}`,
                })

                return response.data.stations.flat(Infinity)
            } catch {
                return []
            }
        }
    }

    /**
     * Handles <input/> field decoration
     * @param input
     */
    const inputHandler = (input) => (stripTags(input))

    /**
     * Updates redux with new station object
     * @param station {object}
     */
    const onSuggestionClick = (station) => {
        if (direction === 'departure') {
            dispatch(setDepartureStationObject(station))
        } else {
            dispatch(setDestinationStationObject(station))
        }

        if (isMobile) {
            dispatch(dismissAllOverlays())
        }
    }

    const clearHandler = () => {
        if (direction === 'departure') {
            dispatch(setDepartureStationObject(null))
        } else {
            dispatch(setDestinationStationObject(null))
        }
    }

    const focusHandler = (ref) => {
        if (!stationObject?.id && updateContext.id) {
            ref.focus()
        }
    }

    const decorationDisplayHandler = (input) => (
        input?.length <= suggestionLengthFetchLimit && !!updateContext?.id
    )

    return ({
        suggestionsHandler: fetchSuggestions,
        selection: stationObject?.label,
        suggestionClickHandler: onSuggestionClick,
        updateContext,
        updateContextHandler: () => fetchTopStations(directionInt, updateContext?.id),
        clearHandler,
        inputHandler,
        decorationDisplayHandler,
        suggestionLengthFetchLimit,
        focusHandler,
    })
}