import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {PreparedRouteType} from "@Route/types/PreparedRoute.type";

export default function useRedirectInNewTab(route: PreparedRouteType, isReturn: boolean) {
    const {sameTabRedirectProviders} = useAppSelector((state) => state.page)
    if (route.status.isSuggestion) {
        return true
    }

    return !sameTabRedirectProviders.includes(route.status.providerId) || isReturn
}