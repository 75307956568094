import React from 'react';
import LazyLoading from "@Features/lazyLoading/components/LazyLoading";
import TicketType from "@MyTicket/types/Ticket.type";
import Tickets from "@MyTickets/components/Tickets";

interface MyTicketsProps {
    tickets: TicketType[],
    elRef: React.Ref<any>,
    page: number,
    setPage: (value: number) => void,
    totalCount: number,
    loading: boolean,
    batchLoading: boolean,
}

function MyTickets(props: MyTicketsProps) {
    const {
        tickets,
        elRef,
        page,
        setPage,
        totalCount,
        loading,
        batchLoading,
    } = props

    return (
        <LazyLoading
            loading={loading}
            batchLoading={batchLoading}
            fetchedDataLength={tickets?.length}
            totalDataLength={totalCount}
            page={page}
            setPage={setPage}
        >
            <Tickets
                tickets={tickets}
            />
        </LazyLoading>
    )
}

export default MyTickets
