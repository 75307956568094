import React from 'react';
import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconRoundArrow(props: IconType) {
    const {
        width = '16',
        height = '16',
        fill = '#b5b7d1',
        viewBox = '0 0 16 16',
        title = 'IconRoundArrow',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <path
                    d="m7.99997061.99997061c-.21951751-.0029846-.4237017.11234786-.53436644.30195424s-.11066474.42411095 0 .61371733.31484893.30493884.53436644.30183433c3.20086379 0 5.78260869 2.5817336 5.78260869 5.78258344 0 3.20084985-2.5817449 5.78258345-5.78260869 5.78258345-3.20086382 0-5.78260869-2.5817336-5.78260869-5.78258345 0-1.66654024.7024401-3.16273653 1.82608695-4.21686345.48396564 1.21228456.78915085 1.78153876.91555566 1.70776259.18960721-.11066426.30494017-.31484755.30183565-.53436411v-3.34781147h-3.34782609c-.21951751-.00310451-.4237017.11222796-.53436644.30183433-.07377649.12640426.56344325.43158814 1.91165923.91555166-1.40568799 1.2811405-2.29033627 3.12634613-2.29033627 5.17389045 0 3.85877155 3.14121165 6.99996945 7 6.99996945 3.85878839 0 6.99999999-3.1411979 6.99999999-6.99996945 0-3.85877149-3.1412116-7.00008934-6.99999999-7.00008934z"
                    fill={fill}
                    stroke={fill}
                    strokeWidth="1.05"
                    transform="matrix(0 -1 1 0 -.000029 15.999971)"
                />
            )}
        />

    );
}

export default IconRoundArrow;