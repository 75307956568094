import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {isEmpty} from "@Array/isEmpty";

export default function useActiveFiltersCount(): number {
    const {
        filterDuration,
        filterPrice,
        filterRating,
        filterRouteDepartureTime,
        filterRouteArrivalTime,
        filterRouteChange,
        filterDepartureTimeSlots,
        filterCompanies,
        filterCompanyScores,
        filterDepartureStation,
        filterDestinationStation,
        filterPopularFilters: {
            mTicket,
            instantConfirmation,
            webDiscount,
        },
    } = useAppSelector((state) => state?.filter)

    const allFilters = {
        filterDuration,
        filterPrice,
        filterRating,
        filterRouteDepartureTime,
        filterRouteArrivalTime,
        filterRouteChange,
        filterDepartureTimeSlots: Object.values(filterDepartureTimeSlots).some((value) => value === true),
        filterCompanies: !isEmpty(filterCompanies),
        filterCompanyScores: !isEmpty(filterCompanyScores),
        filterDepartureStation: !isEmpty(filterDepartureStation),
        filterDestinationStation: !isEmpty(filterDestinationStation),
        mTicket,
        instantConfirmation,
        webDiscount,
    }

    return allFilters ? Object.values(allFilters).filter((value) => (!!value)).length : 0
}