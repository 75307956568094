import React from 'react';
import LogoGetByTransfer from "@Generic/Logotypes/LogoGetByTransfer";
import {
    servicesTranslationKeys
} from "@SearchResults/features/banners/features/getByTransferBanner/constants/servicesTranslationKeys";
import useGetCheapestGbtVehicle
    from "@SearchResults/features/banners/features/getByTransferBanner/hooks/useGetCheapestGbtVehicle";
import Link from "@Generic/Link";
import Row from "@Containers/components/Row";
import Column from "@Containers/components/Column";
import Translation from "@Translation/components/Translation";
import IconChevronDouble from "@Icons/IconChevronDouble";
import IconUserFull from "@Icons/IconUserFull";
import useIsMobile from "@Hooks/selector/useIsMobile";
import IconCheckmark from "@Icons/IconCheckmark";
import IconArrow from "@Icons/IconArrow";
import {useClickBannerEvent} from "@Events/hooks/useClickBannerEvent";
import {useTranslation} from "@Translation/hooks/useTranslation";
import {TransfersDataType} from "@SearchResults/features/banners/features/getByTransferBanner/types/TransfersData.type";

interface GetByTransferBannerProps {
    transfersData: TransfersDataType,
}

function GetByTransferBanner({transfersData}: GetByTransferBannerProps) {
    const {
        fromName,
        toName,
        vehicles,
        distanceInTime,
    } = transfersData

    const {
        travel_faster_and_with_style: ctaLabel,
        up_to: upToLabel,
        book_a_transfer: bookATransferCtaLabel,
    } = useTranslation("transfers_banner")

    const isMobile = useIsMobile()
    const vehicle = useGetCheapestGbtVehicle(vehicles)

    const bannerClicked = useClickBannerEvent({
        bannerType: 'banner',
        bannerName: "transfer",
        bannerLink: vehicle?.link,
        bannerVehicleType: null,
        bannerPrice: vehicle?.price,
    })

    // this is just for typescript not to cry, the show/don't show logic is in useShowTransfersBanner
    if (!vehicle) {
        return null
    }

    const {
        maxNumberOfPassengers,
        priceWithCurrency,
        link,
        imageUrl,
    } = vehicle

    return (
        <Link
            href={link}
            className="banner__component gbt-banner"
            onClick={() => {
                bannerClicked()
            }}
        >
            <Column className="information-section" justify>
                <Row className="gb--emphasize gb--emphasize-default information-section--cta">
                    <LogoGetByTransfer/>
                    {ctaLabel}
                </Row>
                {isMobile ? (
                    <div className="gb--tag gb--tag-green information-section--tag">
                        <IconCheckmark className="tag--icon-checkmark" fill="#7ed321" height="13" width="13"/>
                        Flex start
                    </div>
                ) : (
                    <Row className="information-section--details">
                        <Row className="gbt-travel-details">
                            <span className="gb--emphasize gbt-travel-details--city">
                                {fromName}
                            </span>
                            <span className="gbt-travel-details--time">
                                {`~ ${distanceInTime} ~`}
                            </span>
                            <span className="gb--emphasize gbt-travel-details--city">
                                {toName}
                            </span>
                        </Row>
                        <Row className="gbt-amenities" gap>
                            {servicesTranslationKeys.map((translationKey) => (
                                <Row className="gbt-amenities--service" center key={translationKey}>
                                    <IconChevronDouble chevronOrientation="right" width="14"/>
                                    <Translation translationKey={translationKey}/>
                                </Row>
                            ))}
                        </Row>
                    </Row>
                )}
            </Column>
            {isMobile && (
                <div className="gbt-banner--border">
                    <div className="gbt-banner--border-blue"/>
                    <div className="gbt-banner--border-orange"/>
                    <div className="gbt-banner--border-green"/>
                </div>
            )}
            <Row className="vehicle-data-container">
                <div className="vehicle-section">
                    <img
                        className=""
                        alt="Transfer vehicle"
                        src={imageUrl}
                    />
                    {!isMobile && (
                        <div className="vehicle-section--background-wrapper">
                            <div className="vehicle-section--background"/>
                        </div>
                    )}
                </div>
                <Row gap className="price-section-container">
                    <Column alignEnd className="price-section">
                        <Row className="price-section--info">
                            {isMobile ? (
                                <>per vehicle</>
                            ) : (
                                <>
                                    {`${upToLabel} ${maxNumberOfPassengers}`}
                                    <IconUserFull/>
                                </>
                            )}
                        </Row>
                        <div className="price-section--price">
                            {`${priceWithCurrency}`}
                        </div>
                    </Column>
                    {isMobile ? (
                        <IconArrow
                            fill="#ffffff"
                            className="price-section-container--icon-arrow"
                            width="25"
                            height="25"
                        />
                    ) : (
                        <div className="btn btn--blue btn--compact price-section-container--cta">
                            {bookATransferCtaLabel}
                        </div>
                    )}
                </Row>
            </Row>
        </Link>
    );
}

export default GetByTransferBanner;