import React from 'react';
import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "@/types/Icon.type";

const IconCoins = (props: IconType) => {
    const {
        width = '20',
        height = '20',
        fill = '#fff',
        viewBox = '0 0 20 20',
        title = 'IconCoins',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...{width, height, fill, viewBox, title, ...rest}}
            svgIconPath={(
                <g fill="none" transform="translate(0 2)">
                    <path
                        d="m5.79521269 9.47309431c-.00519657-.00331147-.0072752-.00815131-.01169228-.01197223-.02416404.01477425-.04650928.03260523-.07093315.04687002-.8576935.4995224-1.77202959.6477743-2.64479314.42845317-2.12331757-.53391072-3.2961829-3.11914921-2.61439322-5.76348469.38090842-1.47767942 1.27082064-2.71642361 2.44134752-3.39833153.44378688-.25854932.90290364-.42233968 1.36357936-.49035216.07093315-.10596701.16083378-.20352799.26346599-.28427689-.59448735.03719035-1.18949435.2233968-1.75981766.55556263-1.22950792.71629625-2.16281148 2.01184487-2.56060874 3.55498949-.71660669 2.77985099.54044304 5.50391645 2.80198931 6.07247028.28399243.0713239.57188228.1069859.86055162.1069859.66775895 0 1.34019481-.1897727 1.98119144-.5632045.06028018-.03515252.11614329-.07769217.17512433-.1156467-.07664937-.0430491-.15173978-.08890021-.22501138-.13806279z"
                        fill="#070c63"
                        stroke="#070c63"
                        strokeWidth=".1875"
                        transform="translate(1.7462 1.6134)"
                    />
                    <path
                        d="m4.57287204 4.57186525c.03351787-.18951793.07976732-.37648857.13355179-.56167611-.52017642.55199643-.92784716 1.29198242-1.14142609 2.12061389-.0997741.38693243-.15563721.77895944-.16577051 1.16487296-.01065297.4055276.02884095.79780933.1171826 1.16589186.01636919.06852194.08626302.11157104.15641669.09475897.06989383-.01579316.11302534-.08456983.09665616-.15309177-.08340492-.34694007-.12056038-.71756989-.11042707-1.10119085.00961365-.36706362.06261864-.74024072.15771583-1.10908744.15641669-.6054894.42066216-1.15952366.7561006-1.62109151z"
                        fill="#070c63"
                        stroke="#070c63"
                        strokeWidth=".1875"
                    />
                    <g transform="translate(.3292 5.2176)">
                        <path d="m.12978.135951 1.602622.478617" fill="#000"/>
                        <path
                            d="m1.73266133.75c-.01195211 0-.02390421-.00162518-.03585632-.00541728l-1.60262148-.47861709c-.06911435-.02058569-.1088681-.09561508-.08912114-.1673941.01948713-.07204988.09119976-.11457557.16057394-.09290643l1.60262149.47861709c.06911435.02058568.10886809.09561508.08912113.1673941-.01636918.05959013-.06833486.09832371-.12471762.09832371z"
                            fill="#070c63"
                            stroke="#070c63"
                            strokeWidth=".1875"
                        />
                    </g>
                    <g transform="translate(1.2503 3.198)">
                        <path d="m.12978.13611 1.602881.478493" fill="#000"/>
                        <path
                            d="m1.73266133.75c-.01195211 0-.02390421-.00162476-.03585632-.00541588l-1.60262148-.47876387c-.06911435-.02058035-.1088681-.0955903-.08912114-.16735072.01974696-.07230201.09197925-.11427508.16057394-.09288236l1.60262149.47876387c.06911435.02058035.10886809.0955903.08912113.16735072-.01610936.05984549-.06833486.09829824-.12471762.09829824z"
                            fill="#070c63"
                            stroke="#070c63"
                            strokeWidth=".1875"
                        />
                    </g>
                    <g transform="translate(.1731 7.452)">
                        <path d="m.12978.136031 1.602622.478555" fill="#000"/>
                        <path
                            d="m1.73266133.75c-.01195211 0-.02390421-.00162497-.03585632-.00541658l-1.60262148-.47855479c-.06911435-.020583-.1088681-.09560262-.08912114-.1673723.01948713-.07204051.09145959-.11483148.16057394-.09289434l1.60262149.47855479c.06911435.020583.10886809.09560263.08912113.1673723-.01636918.05958238-.06833486.09831092-.12471762.09831092z"
                            fill="#070c63"
                            stroke="#070c63"
                            strokeWidth=".1875"
                        />
                    </g>
                    <g fill="#070c63">
                        <path
                            d="m3.54525078 1.78819334c.65216925-.27077629 1.34175378-.32758072 1.99392303-.16379036l.03299821.00891549c.00753502.00203783.01507004.00382093.02260507.00509457l.21357893.05374769c.14524407-.14392154.29958213-.27918232.45521933-.41189581l-.55889086-.14010062c-.00493674-.00127364-.00961365-.00229256-.01429056-.00331147-.00649571-.0017831-.02078627-.0053493-.02234524-.00585875-.76363563-.19130103-1.56780448-.12660002-2.32572388.18798955-.13199282.05502134-.19383198.20454691-.13796887.33394893.05638276.12940203.20838236.19002738.34089484.13526078z"
                        />
                        <path
                            d="m7.31977774 11.0292301c-.70257596.3876966-1.47868334.5491944-2.24543691.4136789-.02130593-.0038209-.0420922-.0045851-.06287847-.002802l-1.84244308-.4630962-.00051966.0025472c-.01169228-.0048398-.02390421-.0086607-.03611615-.0117175-2.02770072-.5097115-3.08572191-2.96452903-2.40912879-5.58874096.27619757-1.07189709.79975177-2.00802395 1.51402-2.70776285.1015929-.09934408.1015929-.26058715.00025983-.36018595-.10133307-.09959881-.26606426-.09959881-.36739734 0-.78052447.76443991-1.35162726 1.78207985-1.65094956 2.94313188-.74752627 2.90033752.47522612 5.62695028 2.78146287 6.20645738l.0265025.0061135c.00415725.0015283.00857433.0025473.01273159.0038209l1.9203916.482965c.03169906.0078966.06417761.0086608.09587667.0033115.18629695.029039.37259391.0430491.5583712.0430491.805468 0 1.59716509-.268229 2.30285899-.739986-.20188666-.0631726-.4009152-.1390817-.59760529-.2307839z"
                        />
                        <path
                            d="m11.0160964 11.652041c-2.53072852 0-4.65170763-2.49277207-4.75200139-5.61599693-.05638276-1.75788066.53264819-3.41285105 1.61639239-4.54053366.80884576-.84136789 1.80268934-1.3016621 2.8739618-1.3312106 2.5821745-.06622938 4.7564184 2.44793989 4.8577515 5.61421385.0563827 1.75813539-.5326482 3.41310578-1.6166522 4.54078834-.808586.8413679-1.8024296 1.3016621-2.8739618 1.3312106-.034817.001019-.0704135.0015284-.1054903.0015284zm-.1553774-11.23428643c-.0329982 0-.0657366.00050946-.0987348.00152837-1.00085895.02725594-1.93182405.46029421-2.69208191 1.25122588-1.03671526 1.07852003-1.60028303 2.6670063-1.5459789 4.35763867.09561685 2.9854168 2.10097233 5.36891041 4.49191311 5.36916511.0329982 0 .0657366-.0005094.0987348-.0015284 1.000859-.0272559 1.9318241-.4602942 2.6920819-1.2512258 1.0367153-1.07852006 1.6002831-2.66700634 1.5459789-4.35789343-.0956168-2.9854168-2.1009723-5.3689104-4.4919131-5.3689104z"
                            stroke="#070c63"
                            strokeWidth=".1875"
                        />
                        <path
                            d="m11.2252582 10.1234159c-1.64887093 0-3.03869296-1.83913901-3.11248423-4.13806278-.04391099-1.37196714.39545881-2.67541234 1.17572345-3.48697701.49757136-.5176081 1.09491678-.80035662 1.72759898-.81767815.0205264-.00050946.0410528-.00076419.0610596-.00076419 1.6486111 0 3.0384332 1.83888429 3.1122244 4.13780806.0304.9526842-.1626525 1.85773419-.5588908 2.61682481-.0324786.06266319-.1109467.08762657-.1748645.05553079-.0639178-.03209577-.0891212-.10876902-.0566426-.17143221.3762315-.72113609.5596703-1.58339171.5305695-2.49302681-.0701536-2.18735273-1.3724135-3.94421448-2.9064403-3.89021206-.5625284.01553843-1.09699542.27052156-1.54597887.73769344-.73401519.763421-1.14714233 1.99885372-1.10530996 3.30459148.07015367 2.18735273 1.37709045 3.92230783 2.90644033 3.89046679.3855853-.01044387.7561006-.13245877 1.100633-.36196906.0597606-.0394829.1403074-.02470865.1805808.03362415.0402734.05833281.0249435.13729861-.0342974.17678151-.3866246.25778514-.8036492.39431954-1.2396412.40629184-.0194871.0002547-.0400136.0005094-.0602802.0005094z"
                            stroke="#070c63"
                            strokeWidth=".1875"
                        />
                        <path
                            d="m6.3729631 6.39139018h-1.8187987c-.07171263 0-.12991419-.11411832-.12991419-.2547284 0-.14061007.05820156-.25472839.12991419-.25472839h1.8187987c.07171264 0 .12991419.11411832.12991419.25472839 0 .14061008-.05820155.2547284-.12991419.2547284z"
                        />
                        <path
                            d="m6.69774858 4.09883462h-1.8187987c-.07171263 0-.12991419-.11411832-.12991419-.2547284 0-.14061007.05820156-.25472839.12991419-.25472839h1.8187987c.07171264 0 .1299142.11411832.1299142.25472839 0 .14061008-.05820156.2547284-.1299142.2547284z"
                        />
                        <path
                            d="m6.95757697 8.93867414h-1.8187987c-.07171264 0-.12991419-.11411832-.12991419-.2547284 0-.14061007.05820155-.25472839.12991419-.25472839h1.8187987c.07171263 0 .12991419.11411832.12991419.25472839 0 .14061008-.05820156.2547284-.12991419.2547284z"
                        />
                        <path
                            d="m9.06140741 11.803095c-2.58607192.0002547-4.6654785-2.50066871-4.76940985-5.75100304-.05898104-1.83633701.5336875-3.54148889 1.62600604-4.67783226.79689366-.82939566 1.78787912-1.28332166 2.86564727-1.31287016l.00051965.00331147c.01351108-.00254728.02728198-.00407565.04131272-.00458511l1.81386196-.05094568c.0384546-.00254728.077169-.00534929.1161433-.00611348.0381948-.00127364.0782083-.00050946.1184817 0l.0342974.00050946.1117262-.0035662c.0262427.00127364.0519657.00280201.0763895.00917022 2.5138397.14544992 4.551154 2.64968478 4.6498888 5.73138891.098475 3.07380755-1.765274 5.68044327-4.2557291 5.97592817-.0301401.0145195-.0623588.023435-.0958767.0244539l-2.19477036.0601159-.02806147.0007642c-.0366358.0007642-.07379126.0012737-.11042706.0012737zm-.26320615-11.23250339c-.93642151.02572757-1.80190986.4243775-2.50188753 1.15264599-.99826066 1.0387824-1.53948319 2.6104566-1.48491923 4.31280647.09509719 2.97064255 1.94845307 5.25810353 4.23780098 5.25810353.03169906 0 .06287847-.0005094.09457753-.0015284l.04183237-.0017831 2.11084582-.0573138c.0205264-.0081514.0420922-.0137554.0646973-.0157932 2.2586881-.2231421 3.9569264-2.62217411 3.8657267-5.46137681-.0911998-2.83996689-1.9406582-5.14245686-4.2107788-5.24129147-.0127316-.00050946-.0249436-.00203783-.0371555-.00433039l-.0659964.00203783-.0475486-.00050946c-.0316991-.00050945-.0633981-.00127364-.0948374-.00025472-.0327383.00101891-.0639178.00331147-.0948373.00534929l-.0394939.00280201-1.77592706.04865313c-.00753502.00076418-.01507005.00127364-.02260507.00152837z"
                        />
                    </g>
                </g>
            )}
        />
    );
};

export default IconCoins;
