import React, {Fragment} from 'react';
import {calculateTripDuration} from "@Route/functions/calculateTripDuration";
import Column from "@Containers/components/Column";
import StationWithDateTimesType from "@SearchResults/types/station/StationWithDateTimes.type";
import RouteTravelDuration from "@Route/components/Connection/RouteTravelDuration";
import TransportTypeChangeLabel from "@Route/components/Connection/TransportTypeChangeLabel";

interface RouteBusLayoverProps {
    busChange: StationWithDateTimesType[],
    departureDate: string,
    departureTime: string,
    arrivalDate: string,
    arrivalTime: string,
}

function RouteBusLayover({busChange, departureDate, departureTime, arrivalDate, arrivalTime}: RouteBusLayoverProps) {
    return (
        <>
            {busChange.map((stop) => {
                const {
                    arrivalDate: stopArrivalDate,
                    arrivalTime: stopArrivalTime,
                    departureDate: stopDepartureDate,
                    departureTime: stopDepartureTime,
                    id,
                } = stop

                const tripDurationBeforeLayover = calculateTripDuration(
                    `${departureDate} ${departureTime}`,
                    `${stopArrivalDate} ${stopArrivalTime}`
                )
                const calculatedLayover = calculateTripDuration(
                    `${stopArrivalDate} ${stopArrivalTime}`,
                    `${stopDepartureDate} ${stopDepartureTime}`
                )

                const tripDurationAfterLayover = calculateTripDuration(
                    `${stopDepartureDate} ${stopDepartureTime}`,
                    `${arrivalDate} ${arrivalTime}`
                )

                return (
                    <Fragment key={id}>
                        <RouteTravelDuration
                            travelDuration={tripDurationBeforeLayover}
                            customClassName="gb--label gb--label-green"
                        />
                        <Column>
                    <span className="gb--emphasize-default">
                        <TransportTypeChangeLabel
                            numberOfChanges={busChange.length}
                        />
                    </span>
                            <small>
                                {`${calculatedLayover} layover`}
                            </small>
                        </Column>
                        <RouteTravelDuration
                            travelDuration={tripDurationAfterLayover}
                            customClassName="gb--label gb--label-green"
                        />
                    </Fragment>
                )
            })}
        </>
    );
}

export default RouteBusLayover;