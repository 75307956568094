import React from 'react';
import SidebarSectionContainer from "../Sections/SidebarSectionContainer";
import {useTranslation} from "@Translation/hooks/useTranslation";
import Tickets from "./Tickets";
import IconTicketAndPass from "@Icons/IconTicketAndPass";
import LatestTicketType from "@Sidebar/types/LatestTicket.type";

/**
 * @see Bus/FrontBundle/Resources/views/Widgets/latest_tickets.html.twig
 */

interface TicketsContainerProps {
    latestTickets: LatestTicketType[],
}

function TicketsContainer({latestTickets}: TicketsContainerProps) {
    const {latest_tickets_bought: latestTicketsBought} = useTranslation("search_results")

    return (
        <SidebarSectionContainer
            contentArray={latestTickets}
            title={latestTicketsBought}
            icon={<IconTicketAndPass width="45"/>}
        >
            <Tickets latestTickets={latestTickets}/>
        </SidebarSectionContainer>
    );
}

export default TicketsContainer;