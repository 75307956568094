import useIsMobile from "@Hooks/selector/useIsMobile";
import useClickTripEvent from "@Events/hooks/useClickTripEvent";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import ParamsType from "@SearchResults/types/params/Params.type";
import useDispatchRoutePopover from "@Route/hooks/useDispatchRoutePopover";
import {PreparedRouteType} from "@Route/types/PreparedRoute.type";
import useRedirectToUrlWithRouteId from "@SearchResults/hooks/useRedirectToUrlWithRouteId";
import useExpandPreselectedRoute from "@Route/hooks/useExpandPreselectedRoute";
import useNavigateWithGeneratedSearchParams from "@Routing/hooks/useNavigateWithGeneratedSearchParams";
import getTabBasedOnRouteTransferType from "@Route/features/transportType/functions/getTabBasedOnRouteTransferType";
import {useLocation} from "react-router";
import {useState} from "react";
import {isEmpty} from "@Array/isEmpty";
import {isNumber} from "@Number/isNumber";

/**
 * Handles route click interaction and scrolling to element
 */

type UseActivateRouteProps = {
    route: PreparedRouteType,
    isCurrentRouteSingle: boolean,
    params: ParamsType,
    routeExpandable: boolean,
    passengerClassIndex?: number
}

export default function useActivateRoute(props: UseActivateRouteProps) {
    const {
        route,
        isCurrentRouteSingle,
        routeExpandable,
        params,
        passengerClassIndex,
    } = props

    const {state: oldState} = useLocation()
    const {navigateWithSearchParams} = useNavigateWithGeneratedSearchParams()
    const {redirectToUrlWithRouteId} = useRedirectToUrlWithRouteId()
    const activateTripEvent = useClickTripEvent({route, params})
    const isMobile = useIsMobile()
    const {dispatchSoldOutPopover} = useDispatchRoutePopover(route)
    const activeTab = useAppSelector((state) => state.searchResults.tab)
    const {elRef, executeScroll} = useExpandPreselectedRoute(route)

    // This is a hack, but any other solution would currently require entire route params refactoring
    const routeId = isNumber(passengerClassIndex) ? `${route.id}---${passengerClassIndex}` : route.id

    const activateRoute = () => {
        activateTripEvent()
        if (!routeExpandable) {
            return dispatchSoldOutPopover()
        }

        // Prevent triggering route url update on class expand
        if (!isEmpty(route?.passengerClasses)) {
            return
        }

        if (isMobile || params.isReturn) {
            navigateWithSearchParams(
                getTabBasedOnRouteTransferType(route.type.routeTransferType),
                routeId,
                {
                    route,
                    singleConfirmedRoute: (!isMobile && params.isReturn && isCurrentRouteSingle) ? route : oldState?.singleConfirmedRoute ?? undefined,
                },
                !isCurrentRouteSingle
            )
        } else {
            // we're using raw history here as the method above updates the router state
            // and causes re-render which is not wanted on desktop
            if (isCurrentRouteSingle) {
                redirectToUrlWithRouteId(getTabBasedOnRouteTransferType(route.type.routeTransferType), route.id)
            }
            executeScroll()
        }
    }

    return {
        activateRoute,
        elRef,
    }
}