import React, {MutableRefObject} from 'react';
import useTransportTypeAwareTranslation from "@Translation/hooks/useTransportTypeAwareTranslation";
import {capitalize} from "@String/capitalize";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";
import CompanyType from "@SearchResults/types/company/Company.type";
import MobileContentContainer from "@Route/features/mobile/components/MobileContentContainer";
import CompanyDetails from "@Route/features/company/components/CompanyDetails";

interface MobileCompanyDetailsProps {
    route: RouteWithAddedPropsAndPricesType,
    company: CompanyType,
    scrollRef: MutableRefObject<any>,
}

function MobileCompanyDetails({route, company, scrollRef}: MobileCompanyDetailsProps) {
    const companyType = useTransportTypeAwareTranslation('search_results.bus_company')
    const title = `${capitalize(companyType)} - ${company.letterCode}`

    return (
        <MobileContentContainer title={title} scrollRef={scrollRef}>
            <CompanyDetails
                company={company}
                route={route}
            />
        </MobileContentContainer>
    );
}

export default MobileCompanyDetails;