import useSearchParamsExtractedFromRoute from "@SearchResults/hooks/useSearchParamsExtractedFromRoute"
import {useEffect, useState} from "react"
import {useGetSearchResultsQuery} from "@SearchResults/api/searchResultsApi"

export default function useIsFetchingData() {
    const params = useSearchParamsExtractedFromRoute()
    const [oldParams, updateParams] = useState(params)

    const {
        data,
    } = useGetSearchResultsQuery(params)

    const isFetchingData = () => (
        params.date !== oldParams.date
        || params.returnDate !== oldParams.returnDate
        || params.toSlug !== oldParams.toSlug
        || params.fromSlug !== oldParams.fromSlug
    )

    useEffect(() => {
        if (data) {
            if (isFetchingData()) {
                updateParams(params)
            }
        }
    }, [data])

    return {
        params,
        oldParams,
        isFetchingData: isFetchingData(),
    }
}