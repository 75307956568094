export default function parseExperiments(experiments: string) {
    const parsedArray = [];

    const experimentsArray = experiments.split(',');

    experimentsArray.forEach((value) => {
        const experiment = value.split('-');
        parsedArray[experiment[0]] = experiment[1];
    });

    return parsedArray;
}
