import VoucherType from "@MyVoucher/types/Voucher.type";

export default function getUpdateVouchers(oldVouchers: VoucherType[]) {
    const updateVouchers = ({vouchers}: { vouchers: VoucherType[] }) => ({
        vouchers: [...vouchers, ...oldVouchers],
    })

    return {
        updateVouchers,
    }
}