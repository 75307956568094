import SeatPicker from "@CheckOrder/components/MainSection/SeatPicker/components/SeatPicker";
import React from "react";
import {SeatPickerDataType} from "@CheckOrder/components/MainSection/SeatPicker/types/SeatPickerData.type";
import Translation from "@Translation/components/Translation";
import Row from "@Containers/components/Row";

type SeatPickerSectionProps = {
    seatPickerData: SeatPickerDataType,
}

function SeatPickerSection({seatPickerData}: SeatPickerSectionProps) {
    return (
        <div className="gb--card">
            <span className="gb--card-title">
                <Translation translationKey="check_order.seat_picker.title"/>
            </span>
            <Row>
                <small>
                    <Translation translationKey="check_order.seat_picker.subtitle"/>
                </small>
            </Row>
            {seatPickerData?.singleSeatPicker.seatPicker && (
                <SeatPicker
                    seatPickerDirectionData={seatPickerData.singleSeatPicker}
                />
            )}
            {seatPickerData?.returnSeatPicker.seatPicker && (
                <SeatPicker
                    seatPickerDirectionData={seatPickerData.returnSeatPicker}
                    isReturn
                />
            )}
        </div>
    )
}

export default SeatPickerSection;