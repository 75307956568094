import {CountryAdditionalFieldType} from "@CheckOrder/features/TicketAdditionalInfo/types/CountryAdditionalField.type";
import filterByKeys from "@Array/filterByKeys";
import {AdditionalFieldOptionType} from "@CheckOrder/features/TicketAdditionalInfo/types/AdditionalFieldOption.type";
import AutoSuggestType from "@Features/autosuggest/types/AutoSuggest.type";
import {isEmpty} from "@Array/isEmpty";
import countryData from "@CheckOrder/data/countryData.json";

interface CountryAdditionalFieldProps {
    field: CountryAdditionalFieldType,
    value: AdditionalFieldOptionType | null,
    onCountrySelect: (value: AdditionalFieldOptionType) => void,
    userConfirmed: boolean,
}

type CountryAutoSuggestType = AutoSuggestType & { suggestions: AdditionalFieldOptionType[] }
export default function useCountrySelectAutoSuggest(props: CountryAdditionalFieldProps): CountryAutoSuggestType {
    const {
        field,
        onCountrySelect,
        value,
    } = props

    const createSuggestions = (): AdditionalFieldOptionType[] => (countryData.map(((country) => ({
        id: country.isoCode,
        value: country.isoCode,
        label: country.name,
    }))))

    const suggestions = !isEmpty(field.options) ? field.options : createSuggestions()

    const suggestionsHandler = async (input: string) => {
        if (input) {
            return filterByKeys(suggestions, input, ['id', 'value', 'label'])
        }
        return suggestions
    }

    const updateContextHandler = () => (field.options)

    const onSuggestionClick = (option: AdditionalFieldOptionType) => {
        onCountrySelect(option)
    }

    const clearHandler = () => {
        onCountrySelect({value: "", label: "", id: ""})
    }

    return {
        suggestionsHandler,
        selection: value?.label ?? "",
        suggestionClickHandler: onSuggestionClick,
        updateContext: field.key,
        updateContextHandler,
        clearHandler,
        inputHandler: null,
        decorationDisplayHandler: null,
        suggestionLengthFetchLimit: 0,
        focusHandler: suggestionsHandler,
        suggestions,
    }
}