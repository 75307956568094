import React from 'react';
import formatDate from "@DateTime/functions/formatDate";

interface DateProps {
    date: string,
    format?: string,
    className?: string,
}

const Date = ({date, format = "dd.MM", className = "gb--emphasize gb--emphasize-default"}: DateProps) => (
    <span className={className}>
        {`${formatDate(date, format, true)}`}
    </span>
);

export default Date;