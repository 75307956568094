import React from 'react'
import Row from "@Containers/components/Row";
import IconMTicketQrLeaf from "@Icons/IconMTicketQrLeaf";
import useIsMobile from "@Hooks/selector/useIsMobile";
import {useTranslation} from "@Translation/hooks/useTranslation";
import SourcedImage from "@Images/components/SourcedImage";
import Column from "@Containers/components/Column";

const MTicketSection = () => {
    const isMobile = useIsMobile()
    const {
        title,
        content_first_part: contentFirstPart,
        qr_code: qrCode,
        content_second_part: contentSecondPart,
    } = useTranslation("mobile_app.m_ticket")

    return (
        <div className="m-ticket-background">
            <Row
                justify
                gap
                center
                className="gb-container mobile-app-landing-page-section m-ticket-section"
            >
                <div className="m-ticket-content">
                    <IconMTicketQrLeaf
                        width={isMobile ? "56" : "80"}
                        height={isMobile ? "44" : "64"}
                    />
                    <h2 className="m-ticket-title">
                        {title}
                    </h2>
                    <p>
                        {contentFirstPart}
                        <b>{qrCode}</b>
                        {contentSecondPart}
                    </p>
                </div>
                <Column
                    center
                    className="m-ticket-img-container"
                >
                    <div className="m-ticket-img">
                        <SourcedImage
                            filePath="images/mobile-app-landing-page/travel_green_with_m_ticket"
                            fileType="png"
                        />
                    </div>
                    <div className="m-ticket-circle"/>
                </Column>
            </Row>
        </div>
    )
}

export default MTicketSection