import {useEffect} from "react";
import SuccessfulPurchaseEventModel from "@Events/models/SuccessfulPurchaseEventModel";
import UserEventModel from "../models/UserEventModel";
import permalinkTypeId from "../../../constants/PermalinkTypeId";
import StationEventModel from "../models/StationEventModel";
import StationToStationEventModel from "../models/StationToStationEventModel";
import CountryPageEventModel from "../models/CountryPageEventModel";
import useTrackingEventProcessor from "./useTrackingEventProcessor";
import CompanyPageEventModel from "../models/CompanyPageEventModel";
import {useAppSelector} from "@Hooks/selector/useAppSelector";

/**
 * Handles all events except search results event (as that one requires modified routes state)
 */
export default function useTrackingEvents() {
    const {
        experiments,
    } = useAppSelector((state) => state.page)

    const {
        departureStationObject,
        destinationStationObject,
    } = useAppSelector((state) => state.form)

    const {
        user,
        awaitingUserResponse,
    } = useAppSelector((state) => state.userProfile)

    const {
        currentRoute,
    } = window

    const type = window?.permalinkTypeId ?? null

    const {
        stationToStation,
        portToPort,
        station,
        port,
        busCountry,
        busCompany,
    } = permalinkTypeId

    const userId = user?.id
    const portOrStation = type === port || type === station
    const portToPortOrStationToStation = type === stationToStation || type === portToPort
    const processEvent = useTrackingEventProcessor()

    const homepageEvent = UserEventModel(
        "view_homepage",
        userId,
        experiments
    )

    const stationEvent = UserEventModel(
        "view_station_landing_page",
        userId,
        experiments,
        new StationEventModel(departureStationObject)
    )

    const stationToStationEvent = UserEventModel(
        "view_trip_landing_page",
        userId,
        experiments,
        new StationToStationEventModel(departureStationObject, destinationStationObject)
    )

    const countryEvent = UserEventModel(
        "view_country_landing_page",
        userId,
        experiments,
        new CountryPageEventModel()
    )

    const companyEvent = UserEventModel(
        "view_supplier_page",
        userId,
        experiments,
        CompanyPageEventModel()
    )

    const purchaseEvent = UserEventModel(
        "book_transfer",
        userId,
        experiments,
        new SuccessfulPurchaseEventModel()
    )

    const isSuccessfulPurchasePage = currentRoute.includes("successful_purchase") || window.location.href.includes('successful-purchase')
    const events = {
        [`${currentRoute === "front_homepage"}`]: homepageEvent,
        [`${portOrStation}`]: stationEvent,
        [`${portToPortOrStationToStation}`]: stationToStationEvent,
        [`${type === busCountry}`]: countryEvent,
        [`${type === busCompany}`]: companyEvent,
        [`${isSuccessfulPurchasePage}`]: purchaseEvent,
    }

    const handleEvent = () => {
        if (events?.true) {
            processEvent(events?.true)
        }
    }

    useEffect(() => {
        if (!awaitingUserResponse) {
            handleEvent()
        }
    }, [user?.id, awaitingUserResponse])
}