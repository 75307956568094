import React from 'react';
import Station from "@SearchResults/components/Popover/StationsPopover/Station";
import StationWithDateTimesType from "@SearchResults/types/station/StationWithDateTimes.type";

interface StationsProps {
    stations: StationWithDateTimesType[],
    departureStationIndex: number,
    destinationStationIndex: number,
}

function Stations({stations, departureStationIndex, destinationStationIndex}: StationsProps) {
    let hadBorder = false

    return (
        <>
            {stations.map((station, index) => {
                const {hasBorder} = station
                const highlight = index >= departureStationIndex && index <= destinationStationIndex
                hadBorder = !hadBorder && hasBorder

                return (
                    <Station
                        key={station.id}
                        station={station}
                        highlight={highlight}
                        hadBorder={hadBorder}
                    />
                )
            })}
        </>
    );
}

export default Stations;