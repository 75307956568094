import React, {ReactNode} from 'react';

interface InputAdornmentProps {
    onClick: (any?) => any,
    icon?: ReactNode,
    text: string,
    position?: "end"
}

function InputAdornment(props: InputAdornmentProps) {
    const {onClick, icon, text, position = "end"} = props
    return (
        <span onClick={onClick} className={`gb--input__adornment gb--input__adornment--${position}`}>
            {icon} {text}
        </span>
    );
}

export default InputAdornment;