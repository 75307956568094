import React, {ReactNode} from 'react';

interface LinkProps {
    className?: string,
    children: ReactNode,
    href: string,
    opensInNewWindow?: boolean,
    onClick?: (any?) => any,
    includeRel?: boolean,
}

function Link(props: LinkProps) {
    const {
        children,
        href,
        opensInNewWindow = true,
        className = "gb--emphasize gb--emphasize-info",
        onClick,
        includeRel = true,
    } = props

    return (
        <a
            onClick={onClick}
            className={className}
            href={href}
            target={opensInNewWindow ? "_blank" : "_self"}
            rel={includeRel ? "noreferrer" : undefined}
        >
            {children}
        </a>
    );
}

export default Link;