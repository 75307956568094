import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconSeatBelt(props: IconType) {
    const {
        width = '35',
        height = '35',
        viewBox = '0 0 35 35',
        title = 'IconSeatBelt',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none" fillRule="evenodd" transform="translate(2 .56527)">
                    <path
                        d="m0 0h26v24c0 2.209139-1.790861 4-4 4h-18c-2.209139 0-4-1.790861-4-4z"
                        fill="#696969"
                        transform="matrix(1 0 0 -1 2 34.43473)"
                    />
                    <path
                        d="m2 2h22v21c0 1.6568542-1.3431458 3-3 3h-16c-1.65685425 0-3-1.3431458-3-3z"
                        stroke="#ccc"
                        strokeWidth=".5"
                        transform="matrix(1 0 0 -1 2 34.43473)"
                    />
                    <path
                        d="m3.81390532 21.7910575c5.54299485-.9042186 9.27169308-1.356328 11.18609468-1.356328 1.8975482 0 5.5775892.4441841 11.0401232 1.3325523 2.1804942.3546092 3.6606636 2.4097185 3.3060514 4.5902122-.0008413.0051731-.0016928.0103446-.0025544.0155143l-1.3436202 8.0617212h-26.45663503l-1.05278013-8.1855956c-.27510126-2.1389198 1.19491526-4.1108735 3.32332048-4.4580764z"
                        fill="#070c63"
                    />
                    <path d="m12.5 16.43473h5v5h-5z" fill="#efb085"/>
                    <path
                        d="m11.8933758 19.2254733 2.4953528 2.0208963c.368483.2984212.895884.2970347 1.2627929-.0033197l2.4589191-2.0128894c.2327354-.190519.5758509-.1562958.7663699.0764397.0796628.0973149.1231895.219202.1231895.3449651v.7506018c0 .2561821-.0983191.5025942-.2746772.6884089l-3 3.1608662c-.3801977.4005848-1.013147.4171117-1.4137317.036914-.0126238-.0119813-.0249326-.0242902-.0369139-.036914l-3-3.1608662c-.1763581-.1858147-.2746772-.4322268-.2746772-.6884089v-.7506018c0-.3028174.245482-.5482993.5482993-.5482993.1256292 0 .2474481.0431416.3450765.1222073z"
                        fill="#6a9fff"
                    />
                    <path
                        d="m9.27627163 25.2708205c5.42590487.155337 10.01255607.2330055 13.75995357.2330055 3.3828258 0 6.7650686-.1585026 10.1467284-.4755078.549867-.0515305 1.0374073.3524375 1.088953.9023031.0029084.0310256.004365.0621702.004365.0933319v.9066021c.0000123.5158137-.3923156.9469995-.9058375.9955692-3.4832624.3294524-6.9471746.4941786-10.3917365.4941786-3.591041 0-6.9826959-.0186021-10.1749647-.0558063-.2592356-.003062-.5071655-.1066474-.6914979-.2889494z"
                        fill="#bbb"
                        transform="matrix(.70710678 -.70710678 .70710678 .70710678 -12.48063 23.2097)"
                    />
                    <rect
                        fill="#000"
                        height="4"
                        rx="1"
                        transform="matrix(.70710678 -.70710678 .70710678 .70710678 -4.291419 26.488242)"
                        width="4"
                        x="27.828427"
                        y="16.424322"
                    />
                    <g transform="translate(5.63006)">
                        <path
                            d="m3.86993962 3.43472953h10.99999998c0 5.42474344-.1017429 8.39141007-.3052288 8.89999997-1.1272741 3.1370067-3.3515006 6.4055372-5.19477118 6.4055372-1.84327055 0-3.64098284-1.8897753-5.26370865-6.4055372-.28379559-1.0217355-.36255937-3.98840214-.23629135-8.89999997z"
                            fill="#efb286"
                        />
                        <g fill="#5e2d02">
                            <path
                                d="m11.3699396 5.27347723c2.5973295-.15306301 4.5086635-1.90574065 5.7340019-5.25803292-2.9303451.84677673-4.841679.98653847-5.7340019.41928522-1.49180828-.94834857-4.99999998-.29916745-4.99999998 1.5s1.10400577 3.56834222 4.99999998 3.3387477z"
                            />
                            <path
                                d="m3.94244158 6.46879664c1.47195095-.08674342 2.6985643-1.39761058 3.67984005-3.93260148-1.95388158 1.40266782-3.21678034 1.73907895-3.78869628 1.00923337-1.02628752-1.30968793-2.83358535-.1695433-2.83358535.85007561 0 1.00301291 1.12117857 2.66499057 3.24269558 2.57299907.03512406-.00152302-.33621333-.49758746-.300254-.49970657z"
                                transform="matrix(-.51503807 .8571673 -.8571673 -.51503807 10.606671 3.507396)"
                            />
                            <path
                                d="m14.2791274 6.58227089c1.1401293-.06718887 2.0902274-1.08254744 2.8502943-3.04607573-1.5134184 1.08646463-2.491622 1.34703864-2.9346111.78172205-.7949316-1.01444519-2.1948106-.13132318-2.1948106.65844319 0 .77690386.8684315 2.06422215 2.5116952 1.99296828.0272061-.00117968-.2604208-.38541639-.2325678-.38705779z"
                                transform="matrix(.51503807 .8571673 .8571673 -.51503807 2.559618 -4.52411)"
                            />
                            <path
                                d="m4.53845863 12.008656c.55686492.7445355 1.08147309 1.3935347 1.57382454 1.9469976 1.35765645 1.5261709 1.63850677 2.1102139 3.25765645 2.1102139s1.99999998-.6046211 3.23269178-2.1102139c.4493748-.5488602.9487771-1.1983623 1.4982068-1.9485063.0958558-.1309333.2797108-.159329.4106231-.0634446.1087348.0796408.1493394.2230492.0984968.3478728l-.9599816 2.356846c-.8164631 1.7961504-1.5085251 2.9111139-2.0761862 3.3448906-.520553.3977792-1.1896755.6946732-2.00736752.890682l-.00001009-.0000421c-.15005422.0359694-.30639702.0367243-.45679157.0022054-.75843458-.1740774-1.40553621-.4716926-1.94130488-.8928453-.5864182-.460967-1.30144228-1.5669763-2.14507223-3.3180278-.00847775-.0175491-.01642184-.0353507-.02384066-.053373l-.95927546-2.3303358c-.0607088-.1474778.00963142-.3162465.15710926-.3769553.12186645-.0501659.26223626-.0114601.34122148.0940358z"
                            />
                        </g>
                        <rect fill="#eeb185" height="1" rx=".5" width="4" x="7.36994" y="13.43473"/>
                        <rect
                            fill="#eeb185"
                            height="1"
                            rx=".5"
                            transform="matrix(0 1 -1 0 25.00467 -5.13521)"
                            width="4"
                            x="13.06994"
                            y="9.43473"
                        />
                        <rect
                            fill="#eeb185"
                            height="1"
                            rx=".5"
                            transform="matrix(0 1 -1 0 13.50467 6.36479)"
                            width="4"
                            x="1.56994"
                            y="9.43473"
                        />
                        <rect fill="#f5d2b8" height="1.7" rx=".5" width="4" x="9.86994" y="8.73473"/>
                        <rect fill="#5e2d02" height="1" rx=".5" width="2.5" x="10.56994" y="8.03473"/>
                        <rect fill="#9b663b" height="1" rx=".5" width="1.3" x="10.56994" y="7.33473"/>
                        <rect fill="#af774b" height="1" rx=".5" width="1.5" x="8.61994" y="12.08473"/>
                        <g transform="matrix(-1 0 0 1 9.16994 7.33473)">
                            <rect fill="#f5d2b8" height="1.7" rx=".5" width="4" y="1.4"/>
                            <rect fill="#5e2d02" height="1" rx=".5" width="2.5" x=".7" y=".7"/>
                            <rect fill="#9b663b" height="1" rx=".5" width="1.3" x=".7"/>
                        </g>
                        <path
                            d="m7.32748394 13.1024347 2.04245568-.4172444 2.07282028.4157633c.1008102.0202203.1661411.118335.1459208.2191451-.0056402.0281194-.0177007.0545555-.0352406.077246l-.0333771.0431782c-.0946849.1224888-.2407704.1942066-.3955887.1942066h-3.49044033c-.16524579 0-.31981944-.081644-.41297545-.2181289l-.01314282-.0192559c-.05948733-.0871562-.03705728-.2060342.05009891-.2655216.02097346-.0143151.04459005-.024306.06946933-.0293884z"
                            fill="#5e2d02"
                        />
                        <rect fill="#5e2d02" height="1" rx=".5" width="2" x="8.36994" y="14.43473"/>
                    </g>
                </g>
            )}
        />

    );
}

export default IconSeatBelt;
