import React from 'react';
import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "@/types/Icon.type";

const IconThunderbolt = (props: IconType) => {
    const {
        width = '7',
        height = '11',
        fill = '#fff',
        viewBox = '0 0 7 11',
        title = 'IconThunderbolt',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...{width, height, fill, viewBox, title, ...rest}}
            svgIconPath={(
                <path
                    d="m8.8046875 15.5 4.375-6.125h-2.625l.8203125-4.375-4.375 6.125h2.625z"
                    fill="#f03a47"
                    transform="translate(-7 -5)"
                />
            )}
        />

    );
}

export default IconThunderbolt;
