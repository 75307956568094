import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconAirplanePlot(props: IconType) {
    const {
        width = '150',
        height = '85',
        viewBox = '0 0 155 85',
        title = 'IconAirplanePlot',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none" fillRule="evenodd" transform="translate(3 11)">
                    <path
                        d="m72.037605 0 6.512395 32.5h-13z"
                        fill="#070c63"
                        stroke="#070c63"
                        strokeLinejoin="round"
                        strokeWidth="3.9"
                    />
                    <path
                        d="m102.334091 27.95h-60.5681819c-1.2556818 0-2.2159091-.845-2.2159091-1.95s.9602273-1.95 2.2159091-1.95h60.5681819c1.255682 0 2.215909.845 2.215909 1.95s-.960227 1.95-2.215909 1.95z"
                        fill="#070c63"
                        fillRule="nonzero"
                    />
                    <g fillRule="nonzero" transform="translate(52.55 17.55)">
                        <path
                            d="m0 19.5h39c-3.3153812-13-9.9205409-19.34791267-19.815479-19.04373802-9.89493817.30417466-16.28977848 6.65208733-19.184521 19.04373802z"
                            fill="#b5b7d1"
                        />
                        <path
                            d="m38.6903157 17.55-3.2924397-11.4467354c-6.8487805-3.74678601-9.0649492-4.1532646-9.0649492-4.1532646v3.02161064c0 1.02734455-.8243902 1.81296097-1.902439 1.81296097h-4.4390244-4.4390244c-1.0780488 0-1.902439.78561642-1.902439 1.81296098 0 1.02734455.8243902 1.81296101 1.902439 1.81296101h4.4390244v3.0216016z"
                            fill="#070c63"
                        />
                    </g>
                    <g transform="translate(0 18.85)">
                        <path
                            d="m81.9971631 29.8961768-9.9512062 2.6038232-18.1959569-4.7632526v-7.5867474c0-6.1027465 4.9472535-11.05 11.05-11.05h16.0316263c5.1463957 0 9.3183737 4.171978 9.3183737 9.3183737v.7876965c0 5.0254501-3.3910634 9.4179796-8.2528369 10.6901066z"
                            fill="#fff"
                            fillRule="nonzero"
                        />
                        <path
                            d="m89.9311657 15.6h-4.7611657c-1.088 0-2.24.6787985-2.72 2.2580593-.32 1.0528406-3.7866667 8.052118-10.4 20.9978322l20.15.1441085v-21.1419407c0-1.3823576-1.1808343-2.2580593-2.2688343-2.2580593z"
                            fill="#e9eef4"
                            fillRule="nonzero"
                        />
                        <path
                            d="m52.55 27.3c8.7885904 3.4666667 15.2889906 5.2 19.5012006 5.2 4.2122101 0 10.7118099-1.8166667 19.4987994-5.45l-2.8699259 17.0789895c-6.8007637 3.514007-12.3437215 5.2710105-16.6288735 5.2710105-4.2851519 0-9.9379547-1.7570035-16.9584085-5.2710105z"
                            fill="#97bcff"
                        />
                        <path
                            d="m86.8867197 22.6060984c-8.2424781 6.1267898-13.7980099 10.3445645-16.6665952 12.6533244-1.6207581.0538142-4.0518953.1345356-7.2934115.2421641-1.4978889 0-2.6433333.7862535-2.6433333 1.8144312s1.3254219 1.8872004 2.8233108 1.8872004l5.3175067-.2756434c.7170075-.0371675 1.3283871.5139518 1.3655546 1.2309593.0041152.0793875.00094.1589851-.0094856.2377926l-1.352086 10.2204168c5.3542665-1.4145667 9.4262678-2.896654 12.2160041-4.4462619 2.7897363-1.5496078 5.9589505-5.7094097 9.5076428-12.4794056z"
                            fill="#6a9fff"
                            fillRule="nonzero"
                            transform="matrix(.93969262 .34202014 -.34202014 .93969262 17.05802 -23.517997)"
                        />
                        <path
                            d="m72.05 44.85c-.52 0-1.04-.1974434-1.365-.5923301-.39-.3948867-.585-.8555879-.585-1.3821035s.195-1.0530313.585-1.3821035c.715-.7246172 2.015-.7246172 2.73 0 .39.3948867.585.8555879.585 1.3821035s-.195 1.0530312-.585 1.3821035c-.325.3948867-.845.5923301-1.365.5923301z"
                            fill="#fff"
                            fillRule="nonzero"
                        />
                        <path
                            d="m72.05 0c11.1285377 0 20.15 9.02146229 20.15 20.15v11.7c0 8.9746272-7.2753728 16.25-16.25 16.25h-7.8c-8.9746272 0-16.25-7.2753728-16.25-16.25v-11.7c0-11.12853771 9.0214623-20.15 20.15-20.15z"
                            stroke="#070c63"
                            strokeWidth="3.9"
                        />
                        <path
                            d="m72.05 49.875v.75"
                            stroke="#070c63"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="3.9"
                        />
                        <g transform="translate(84.749535 26.17875)">
                            <path
                                d="m3.05072391 16.72125c-1.97843528 1.768878-2.99534325 2.6755441-3.05072391 2.719998 3.48481806-1.7695901 6.99626235-1.2229752 12.3447618-3.044998 9.5402883-3.25 25.4253678-8.71541667 47.6552382-16.39625l-52.74953522 8.04375c-.08138321 1.66833333-.5363486 3.2608333-1.36489618 4.7775s-1.77349581 2.8166667-2.83484469 3.9z"
                                fill="#6a9fff"
                                stroke="#070c63"
                                strokeLinejoin="round"
                                strokeWidth="3.9"
                            />
                            <g transform="translate(19.150465 5.67125)">
                                <circle cx="7.8" cy="7.8" fill="#fff" r="7.8" stroke="#070c63" strokeWidth="3.9"/>
                                <path
                                    d="m7.8 9.75c-.52 0-1.04-.19744336-1.365-.59233008-.39-.39488673-.585-.85558791-.585-1.38210354s.195-1.05303126.585-1.38210353c.715-.72461713 2.015-.72461713 2.73 0 .39.39488673.585.8555879.585 1.38210353s-.195 1.05303127-.585 1.38210354c-.325.39488672-.845.59233008-1.365.59233008z"
                                    fill="#070c63"
                                    fillRule="nonzero"
                                />
                            </g>
                            <path
                                d="m7.450465 18.44625v8.25"
                                stroke="#070c63"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="3.9"
                            />
                        </g>
                        <g transform="matrix(-1 0 0 1 59.399535 26.17875)">
                            <path
                                d="m2.99987851 16.72125c-1.94546136 1.768878-2.94542086 2.6755441-2.99987851 2.719998 3.42673776-1.7695901 6.87965798-1.2229752 12.1390157-3.044998 9.3812836-3.25 25.0016117-8.71541667 46.8609843-16.39625l-51.60046478 8.04375c-.25996784 1.66833333-.79732098 3.2608333-1.61205943 4.7775s-1.74393755 2.8166667-2.78759728 3.9z"
                                fill="#97bcff"
                                stroke="#070c63"
                                strokeLinejoin="round"
                                strokeWidth="3.9"
                            />
                            <g transform="translate(19.150465 5.67125)">
                                <circle cx="7.8" cy="7.8" fill="#fff" r="7.8" stroke="#070c63" strokeWidth="3.9"/>
                                <path
                                    d="m7.8 9.75c-.52 0-1.04-.19744336-1.365-.59233008-.39-.39488673-.585-.85558791-.585-1.38210354s.195-1.05303126.585-1.38210353c.715-.72461713 2.015-.72461713 2.73 0 .39.39488673.585.8555879.585 1.38210353s-.195 1.05303127-.585 1.38210354c-.325.39488672-.845.59233008-1.365.59233008z"
                                    fill="#070c63"
                                    fillRule="nonzero"
                                />
                            </g>
                            <path
                                d="m7.450465 18.44625v8.25"
                                stroke="#070c63"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="3.9"
                            />
                        </g>
                    </g>
                    <path
                        d="m52.225 45.02875 19.825 6.647899 19.1903157-6.647899"
                        stroke="#070c63"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="3.9"
                    />
                </g>
            )}
        />

    );
}

export default IconAirplanePlot;
