import React from 'react';
import FerryTypeBadge from "@Route/components/Ferry/FerryTypeBadge";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";
import {isFerryRoute} from "@Route/features/transportType/functions/isFerryRoute";
import RouteCompany from "@Route/features/company/components/RouteCompany";

interface RouteCompanyDetailsProps {
    route: RouteWithAddedPropsAndPricesType,
    displayCompanyLogo?: boolean,
}

function RouteCompanyDetails(props: RouteCompanyDetailsProps) {
    const {
        route,
        displayCompanyLogo = false,
    } = props;
    const {company} = route
    const isFerry = isFerryRoute(route.type.vehicleType, route.type.routeTransferType)

    const setClassName = () => {
        let className = ''

        if (isFerry) {
            className += 'gb--route-ferry'
        }

        return className
    }

    if (route.status.status !== "unavailable") {
        return (
            <div className={`gb--route-element gb--route-ratings ${setClassName()}`}>
                <RouteCompany
                    route={route}
                />
                {isFerryRoute(route.type.vehicleType, route.type.routeTransferType) && (
                    <FerryTypeBadge
                        ferryVehicle={route.type.vehicleType}
                    />
                )}
            </div>
        )
    }

    return (
        <div className="gb--route-element gb--row gb--row-middle gb--route-ratings"/>
    );
}

export default RouteCompanyDetails;