import {clearAllFilters} from "@SearchResults/reducers/filterSlice";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";

export default function useClearAllFilters() {
    const dispatch = useAppDispatch()
    const clearFilters = () => {
        dispatch(clearAllFilters())
    }

    return {
        clearFilters,
    }
}