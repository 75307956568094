import {useAppSelector} from "@Hooks/selector/useAppSelector";
import roundToTwoDecimals from "@Number/roundToTwoDecimals";
import {CurrencyVal} from "@Currency/types/Currency.type";

/**
 *
 * @param returnWithCurrency
 */
export default function useGetPricesInOtherCurrencies(returnWithCurrency: boolean = false) {
    const {pricesInOtherCurrencies} = useAppSelector((state) => state?.checkOrder.ticket);
    const premiumSupportPricesInOtherCurr = useAppSelector((state) => state?.checkOrder.ancillaries.premiumSupport.pricesInOtherCurrencies);
    const {isBuyingPremiumSupport} = useAppSelector((state) => state?.checkOrder);
    const pricesInOtherCurrenciesWithAncillaries: Record<CurrencyVal, number> | {} = {};
    const checkCurrencyValType = (pricesFiltered: Record<CurrencyVal, number> | {}, defaultPrices: Record<CurrencyVal, number>): pricesFiltered is Record<CurrencyVal, number> => {
        return ((Object.keys(pricesFiltered).length === Object.keys(defaultPrices).length));
    }

    Object.entries(pricesInOtherCurrencies).map(({0: currency, 1: price}) => {
        pricesInOtherCurrenciesWithAncillaries[currency] = (isBuyingPremiumSupport)
            ? roundToTwoDecimals(price + premiumSupportPricesInOtherCurr[currency])
            : price;
    });

    return checkCurrencyValType(pricesInOtherCurrenciesWithAncillaries, pricesInOtherCurrencies)
        ? pricesInOtherCurrenciesWithAncillaries
        : pricesInOtherCurrencies
}