import TabType from "@Tabs/types/Tab.type";
import {SearchResultsRtkQueryResponseType} from "@SearchResults/api/types/SearchResultsRtkQueryResponse.type";
import {PreparedTabContentDataType} from "@Tabs/types/PreparedTabContentData.type";

type PrepareTabContentProps = {
    data: SearchResultsRtkQueryResponseType,
    activeTab: TabType,
    tabsToShow: TabType[],
    suggestionRoutesEnabled: boolean,
}

export default function usePrepareTabContentData({data, activeTab, tabsToShow, suggestionRoutesEnabled}: PrepareTabContentProps): PreparedTabContentDataType {
    return {
        ...data,
        activeTab,
        tabsToShow,
        singleRoutes: data.result.singleRoutes[activeTab.tabType],
        returnRoutes: data.result.returnRoutes[activeTab.tabType],
        suggestedRoutes: data.result.suggestedRoutes,
        suggestionRoutesEnabled,
    }
}