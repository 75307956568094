import {SearchResponseType} from "@SearchResults/types/SearchResponse.type";
import {useOutletContext} from "react-router";
import ReturnTabContent from "@RoutesList/components/ReturnTabContent";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import useFindSelectedRouteFromSearchParams
    from "@SearchResults/features/additionalInfo/hooks/useFindSelectedRouteFromSearchParams";
import useSingleConfirmedRoute from "@Route/hooks/useSingleConfirmedRoute";
import RouteType from "@Route/types/Route.type";

function ReturnTabContentOutlet() {
    const searchQuery: SearchResponseType = useOutletContext()
    const singleSelectedRoute = useSingleConfirmedRoute()
    const routeFromParams = useFindSelectedRouteFromSearchParams(searchQuery, false)
    const navigate = useNavigate()

    useEffect(() => {
        if (!singleSelectedRoute && !routeFromParams) {
            // eslint-disable-next-line no-console
            console.warn("could not find route with provided id, navigating back")
            navigate("..")
        }
    }, [singleSelectedRoute, routeFromParams])

    let newSingleSelectedRoute = singleSelectedRoute ?? routeFromParams as RouteType

    if (newSingleSelectedRoute) {
        newSingleSelectedRoute = {
            ...newSingleSelectedRoute,
            company: newSingleSelectedRoute?.company ?? searchQuery.data.result.companies[newSingleSelectedRoute?.companyId] ?? {},
        }
    }

    if (singleSelectedRoute ?? routeFromParams) {
        return (
            <ReturnTabContent
                searchQuery={searchQuery}
                singleSelectedRoute={newSingleSelectedRoute}
            />
        );
    }

    return null
}

export default ReturnTabContentOutlet;