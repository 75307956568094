import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import TicketsRequestType from "@MyProfile/API/types/TicketsRequest.type"
import VouchersRequestType from "@MyProfile/API/types/VouchersRequest.type"
import RebookTicketResponseType from "@MyProfile/API/types/RebookTicketResponse.type";
import CountRequestType from "@MyProfile/API/types/CountRequest.type";
import CountResponseType from "@MyProfile/API/types/CountResponse.type";
import TicketsType from "@MyProfile/features/tickets/types/Tickets.type";
import VouchersType from "@MyProfile/features/vouchers/types/Vouchers.type";
import GetCurrentUserResponseType from "@MyProfile/API/types/GetCurrentUserResponse.type";
import getUpdateTickets from "@MyProfile/API/functions/getUpdateTickets"
import getUpdateVouchers from "@MyProfile/API/functions/getUpdateVouchers"
import originalArchivedQuery from "@MyProfile/API/constants/originalArchivedQuery"
import originalValidQuery from "@MyProfile/API/constants/originalValidQuery"

// TODO add catch/ error handling logic
export const myProfileApi = createApi({
    reducerPath: "myProfileApi",
    baseQuery: fetchBaseQuery({
        baseUrl: "/ajax/",
        prepareHeaders: (headers) => {
            headers.set("X-Requested-With", "XMLHttpRequest")
            return headers
        },
    }),
    tagTypes: ["Tickets", "Vouchers"],
    endpoints: (builder) => ({
        getCurrentUser: builder.query<GetCurrentUserResponseType, null>({
            query: () => ({
                url: "get-current-user",
                method: 'GET',
            }),
        }),
        getTickets: builder.query<TicketsType, TicketsRequestType>({
            providesTags: ["Tickets"],
            query: (params) => ({
                url: 'get-my-tickets',
                method: 'GET',
                params,
            }),
        }),
        loadMoreTickets: builder.mutation<TicketsType, TicketsRequestType>({
            query: (params) => ({
                url: 'get-my-tickets',
                method: 'GET',
                params,
            }),
            async onQueryStarted(request, {dispatch, queryFulfilled}) {
                try {
                    const response = await queryFulfilled
                    const {updateTickets} = getUpdateTickets(response.data.tickets)

                    dispatch(myProfileApi.util.updateQueryData(
                        'getTickets',
                        request.showValid ? originalValidQuery : originalArchivedQuery,
                        updateTickets
                    ))
                } catch (error) {
                }
            },
        }),
        countTickets: builder.query<CountResponseType, CountRequestType>({
            providesTags: ["Tickets"],
            query: (params) => ({
                url: 'count-user-tickets',
                method: 'GET',
                params,
            }),
        }),
        getVouchers: builder.query<VouchersType, VouchersRequestType>({
            providesTags: ["Vouchers"],
            query: (params) => ({
                url: 'get-my-vouchers',
                method: 'GET',
                params,
            }),
        }),
        loadMoreVouchers: builder.mutation<VouchersType, VouchersRequestType>({
            query: (params) => ({
                url: 'get-my-vouchers',
                method: 'GET',
                params,
            }),
            async onQueryStarted(request, {dispatch, queryFulfilled}) {
                try {
                    const response = await queryFulfilled
                    const {updateVouchers} = getUpdateVouchers(response.data.vouchers)

                    dispatch(myProfileApi.util.updateQueryData(
                        'getVouchers',
                        request.showValid ? originalValidQuery : originalArchivedQuery,
                        updateVouchers
                    ))
                } catch (error) {
                }
            },
        }),
        countVouchers: builder.query<CountResponseType, CountRequestType>({
            providesTags: ["Vouchers"],
            query: (params) => ({
                url: 'count-user-vouchers',
                method: 'GET',
                params,
            }),
        }),
        rebookTicket: builder.mutation<RebookTicketResponseType, string>({
            invalidatesTags: ["Tickets", "Vouchers"],
            query: (ticketId) => ({
                url: `rebook-ticket`,
                method: 'POST',
                params: {
                    ticketId,
                },
            }),
        }),
    }),
})

export const {
    useLazyGetCurrentUserQuery,
    useLazyGetTicketsQuery,
    useLoadMoreTicketsMutation,
    useCountTicketsQuery,
    useLazyGetVouchersQuery,
    useLoadMoreVouchersMutation,
    useCountVouchersQuery,
    useRebookTicketMutation,
} = myProfileApi