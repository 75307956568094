import React from 'react';
import {useTranslation} from "@Translation/hooks/useTranslation";
import TermWithCheckbox from "@Generic/TermWithCheckbox";

interface TicketPrintConfirmationProps {
    onClick: () => void,
    value: boolean,
    userConfirmed: boolean,
}

function TicketPrintConfirmation({onClick, value, userConfirmed}: TicketPrintConfirmationProps) {
    const ticketPrintLabel = useTranslation("t_summary.ticket_has_to_be_printed")

    return (
        <TermWithCheckbox
            onClick={onClick}
            value={value}
            label={ticketPrintLabel}
            errorLabelText="You must acknowledge the fact that this ticket has to be printed"
            userConfirmed={userConfirmed}
        />
    );
}

export default TicketPrintConfirmation;