import {useEffect} from "react";

/**
 * Event listener hook with built in cleanup
 */
export default function useEventListener(type: string, handler: (any?) => any) {
    useEffect(() => {
        window.addEventListener(type, handler);

        // cleanup this component
        return () => {
            window.removeEventListener(type, handler);
        };
    }, [handler, type]);
}