import React, {ReactNode} from 'react';
import Row from "@Containers/components/Row";

interface CompanyScoreRowProps {
    children: ReactNode,
    rating: string,
}

const CompanyScoreRow = ({rating, children}: CompanyScoreRowProps) => (
    <Row
        className="gb--emphasize-default company-score"
        center
        justify
    >
        <span>
            {children}
        </span>
        <span>
            {`${rating} / 10`}
        </span>
    </Row>
);

export default CompanyScoreRow;