import SearchResultsRequestType from "@SearchResults/api/types/SearchResultsRequest.type";
import updateSearchResults from "@SearchResults/api/functions/updateSearchResults";
import {ThunkDispatch} from "@reduxjs/toolkit";
import {SearchResultsRtkQueryResponseType} from "@SearchResults/api/types/SearchResultsRtkQueryResponse.type";

export default function updateResolvedSuggestedRouteCount(
    dispatch: ThunkDispatch<any, any, any>,
    searchResultsApi: any,
    searchParams: SearchResultsRequestType
) {
    const updateRecipe = (data: SearchResultsRtkQueryResponseType) => ({
        ...data,
        result: {
            ...data.result,
            resolvedSuggestionsCount: (data?.result?.resolvedSuggestionsCount ?? 0) + 1,
        },
    })

    // @ts-ignore
    updateSearchResults(dispatch, searchResultsApi, searchParams, updateRecipe)
}