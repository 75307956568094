import React from 'react';
import Notification from "@Features/notification/components/Notification";
import IconMTicketQrShifted from "@Icons/IconMTicketQrShifted";
import Column from "@Containers/components/Column";
import {useTranslation} from "@Translation/hooks/useTranslation";
import MTicketEcoFriendlyLabel from "@Route/components/MTicket/MTicketEcoFriendlyLabel";
import IconPaperTicket from "@Icons/IconPaperTicket";

interface TicketTypeNotificationProps {
    hasMTicket: boolean,
}

function TicketTypeNotification({hasMTicket}: TicketTypeNotificationProps) {
    const {
        ticket_has_to_be_printed: paperTicketDescription,
        has_mticket_desc: mTicketDescription,
    } = useTranslation("search_results")

    if (hasMTicket) {
        return (
            <Notification
                notificationIcon={<IconMTicketQrShifted width="30" height="30"/>}
                notificationType="green"
                isBorder
                isRow
            >
                <Column>
                    <MTicketEcoFriendlyLabel/>
                    {mTicketDescription}
                </Column>
            </Notification>
        )
    }
    return (
        <Notification
            notificationIcon={<IconPaperTicket width="30" height="30"/>}
            notificationType="orange"
            isBorder
            isRow
        >
            <Column>
                <span className="gb--emphasize-warning">Paper ticket</span>
                {paperTicketDescription}
            </Column>
        </Notification>
    );
}

export default TicketTypeNotification;