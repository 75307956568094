import addDays from "date-fns/addDays";
import isToday from "date-fns/isToday";
import {useTranslation} from "@Translation/hooks/useTranslation";
import isSameDay from "date-fns/isSameDay";
import stringReplacer from "@Translation/functions/stringReplacer";
import useIsCompanyStore from "@Hooks/selector/useIsCompanyStore";
import {SearchResponseType} from "@SearchResults/types/SearchResponse.type";
import {useEffect} from "react";

export default function useMetaGenerator(searchQuery: SearchResponseType) {
    const {
        isLoading,
        data: {
            result: {
                params,
            },
        },
    } = searchQuery

    const {
        meta_title_today_single: metaTitleTodaySingle,
        meta_title_tomorrow_single: metaTitleTomorrowSingle,
        meta_title_the_day_after_tomorrow_single: metaTitleTheDayAfterTomorrowSingle,
        meta_title_single: metaTitleSingle,
        meta_title_return: metaTitleReturn,
        meta_description_today_single: metaDescriptionTodaySingle,
        meta_description_tomorrow_single: metaDescriptionTomorrowSingle,
        meta_description_the_day_after_tomorrow_single: metaDescriptionTheDayAfterTomorrowSingle,
        meta_description_single: metaDescriptionSingle,
        meta_description_return: metaDescriptionReturn,
    } = useTranslation("search_results")

    const isCompanyStore = useIsCompanyStore()

    const updateMeta = () => {
        if (!isCompanyStore && params && !isLoading) {
            const {
                isReturn,
                date,
                returnDate,
                departureStation: {
                    name: departureStationName,
                },
                destinationStation: {
                    name: destinationStationName,
                },
            } = params

            const departureDate = new Date(date)
            const today = isToday(departureDate)
            const tomorrow = isSameDay(departureDate, addDays(new Date(), 1))
            const dayAfterTomorrow = isSameDay(departureDate, addDays(new Date(), 2))

            const singleMetaTitles = {
                // @ts-ignore
                [today]: metaTitleTodaySingle,
                // @ts-ignore
                [tomorrow]: metaTitleTomorrowSingle,
                // @ts-ignore
                [dayAfterTomorrow]: metaTitleTheDayAfterTomorrowSingle,
            }

            // @ts-ignore
            const singleMetaDescription = {
                // @ts-ignore
                [today]: metaDescriptionTodaySingle,
                // @ts-ignore
                [tomorrow]: metaDescriptionTomorrowSingle,
                // @ts-ignore
                [dayAfterTomorrow]: metaDescriptionTheDayAfterTomorrowSingle,
            }

            const metaTitle = isReturn
                ? metaTitleReturn
                // @ts-ignore
                : singleMetaTitles.true
                ?? metaTitleSingle

            const metaDescription = isReturn
                ? metaDescriptionReturn
                // @ts-ignore
                : singleMetaDescription.true
                ?? metaDescriptionSingle

            const replacements = {
                Station1: departureStationName,
                Station2: destinationStationName,
                departureDate: date,
                returnDate,
            }

            const appendRouteDetails = (string) => stringReplacer(string, replacements, false, true)
            document.title = appendRouteDetails(metaTitle)
        }
    }

    useEffect(() => {
        updateMeta()
    }, [params])
}