import {useRef, useState} from "react";

export default function useDialog(isFullScreen = true) {
    const dialogRef = useRef<HTMLDialogElement>(null)

    const closeModal = () => {
        dialogRef?.current?.close()
    }

    const openModal = () => {
        if (isFullScreen) {
            dialogRef?.current?.showModal()
        } else {
            dialogRef?.current.show()
        }
    }

    return {
        dialogRef,
        closeModal,
        openModal,
    }
}