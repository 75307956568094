import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconBusFilledTab(props: IconType) {
    const {
        width = '35',
        height = '35',
        // fill: '#fff',
        viewBox = '0 0 35 35',
        title = 'IconBusFilledTab',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none" transform="translate(3 1)">
                    <path
                        d="m27.2 29.4666667h-26.06666667v-21.15555559c0-3.32444444 3.35142857-6.04444444 7.44761905-6.04444444h11.17142862c4.0961904 0 7.447619 2.72 7.447619 6.04444444z"
                        fill="#777aaa"
                    />
                    <path
                        d="m19.752381 1.13333333h-11.17142862c-4.09619048 0-7.44761905 2.89-7.44761905 6.42222223v12.84444444h26.06666667v-12.84444444c0-3.53222223-3.3514286-6.42222223-7.447619-6.42222223z"
                        fill="#b5b7d1"
                    />
                    <path
                        d="m19.6444444 1.13333333h-5.6666666v.63376608h3.7777778v1.26753214c0 .53870117-.4911112.95064912-1.1333334.95064912h-5.2888889c-.6422222 0-1.1333333.41194794-1.1333333.95064911 0 .53870116.4911111.95064911 1.1333333.95064911h2.6444445v14.51342111h13.2222222v-12.92900593c0-3.4857134-3.4-6.33766074-7.5555556-6.33766074z"
                        fill="#070c63"
                    />
                    <g fill="#fff">
                        <path
                            d="m26.0666667 20.4h-23.80000003v-12.97936508c0-.98222222.818125-1.75396825 1.859375-1.75396825h20.08125003c1.04125 0 1.859375.77174603 1.859375 1.75396825z"
                        />
                        <path
                            d="m4.49677419 24.9333333c-.07311828 0-.14623656 0-.21935484-.0362039-.07311827 0-.14623655-.036204-.21935483-.072408-.07311828-.0362039-.10967742-.0724079-.1827957-.1086118-.07311828-.036204-.10967742-.072408-.14623656-.1448159-.21935484-.2172237-.32903226-.4706515-.32903226-.7602832 0-.0724079 0-.1448158.03655914-.2172237 0-.072408.03655914-.1448159.07311828-.2172238s.07311828-.1448159.10967742-.1810198c.03655914-.0724079.07311828-.1086119.14623656-.1810198s.10967742-.1086119.14623656-.1448159c.07311828-.0362039.10967742-.0724079.1827957-.1086118.07311828-.036204.14623656-.036204.21935483-.072408.3655914-.0724079.7311828.036204.98709678.2896317.03655914.036204.10967742.1086119.14623656.1810198s.07311828.1086119.10967742.1810198c.03655914.072408.03655914.1448159.07311828.2172238 0 .0724079.03655914.1448158.03655914.2172238 0 .0724079 0 .1448158-.03655914.2172237 0 .072408-.03655914.1448159-.07311828.2172238s-.07311828.1448159-.10967742.1810198c-.03655914.0724079-.07311828.1086119-.14623656.1448159-.21935484.2896316-.51182796.3982435-.80430108.3982435z"
                        />
                        <path
                            d="m23.7634409 24.8965417c-.2924732 0-.5849463-.1103748-.767742-.3311245-.2193548-.2207496-.3290322-.4782909-.3290322-.7726238 0-.0735832 0-.1471664.0365591-.2207496 0-.0735832.0365591-.1471664.0731183-.2207497.0365591-.0735832.0731183-.1471664.1096774-.183958.0365591-.0735832.0731183-.1103748.1462366-.1839581.2559139-.2575412.6215053-.3679161.9870967-.2943328.0731183 0 .1462366.0367916.2193549.0735832s.1462365.0735832.1827957.1103748c.0731183.0367916.1096774.0735832.1462365.1471664.0365592.0367917.1096775.1103749.1462366.1839581s.0731183.1103748.1096774.183958c.0365592.0735833.0365592.1471665.0731183.2207497 0 .0735832.0365591.1471664.0365591.2207497 0 .2943328-.1096774.5886657-.3290322.7726237-.0365592.0367917-.1096774.1103749-.1462366.1471665-.0731183.0367916-.1096774.0735832-.1827957.1103748s-.1462365.0367916-.2193548.0735832c-.1462366-.0367916-.2193549-.0367916-.2924731-.0367916z"
                        />
                    </g>
                    <path
                        d="m20.1666667 0c-.1716024 0-.3440526 0-.5173504 0-3.2281454 0-6.750447 0-10.56690468 0-.11247239 0-.2817558 0-.50785022 0-4.73464912 0-8.5745614 3.30377358-8.5745614 7.37735849v22.45283021c0 .545283.48464912.9622641 1.11842105.9622641h1.11842106v2.245283c0 .5452831.48464912.9622642 1.11842105.9622642h1.86403509c.63377193 0 1.11842105-.4169811 1.11842105-.9622642v-2.245283h15.6578947v2.245283c0 .5452831.4846492.9622642 1.1184211.9622642h1.8640351c.6337719 0 1.118421-.4169811 1.118421-.9622642v-2.245283h1.1184211c.6337719 0 1.118421-.4169811 1.118421-.9622641v-22.45283021c0-3.36792453-2.1161048-7.37735849-8.1666666-7.37735849zm3.1666666 28.8190476h-17.99999997-3v-7.7714286h23.66666667v7.7714286zm2.6666667-9.7142857h-23.66666667v-11.75908289c0-2.98744268 2.85-5.40282187 6.375-5.40282187h11.24999997c3.525 0 6.0416667 2.41537919 6.0416667 5.40282187z"
                        fill="#070c63"
                    />
                </g>
            )}
        />

    );
}

export default IconBusFilledTab;
