import React from 'react';
import LanguageFlag from "./LanguageFlag";
import IconCheckmark from "@Icons/IconCheckmark";
import {LocaleType} from "@/types/Locale.type";
import Language from "@Header/components/LanguageSwitcher/Language";

interface LanguageListItemProps {
    locale: LocaleType,
    linkActiveLanguage: string,
    isActive: boolean,
}

function LanguageListItem(props: LanguageListItemProps) {
    const {
        linkActiveLanguage,
        isActive,
        locale,
    } = props;

    function setClassName() {
        let className = 'select__item '
        if (isActive) {
            className += 'select__item--selected'
        }
        if (!linkActiveLanguage) {
            className += 'select__item--disabled'
        }
        return className
    }

    return (
        <li className={setClassName()}>
            <Language linkActiveLanguage={linkActiveLanguage}>
                <span>
                    <LanguageFlag locale={locale}/>
                </span>
                {isActive && <IconCheckmark width="16" height="16" fill="#7ed321"/>}
            </Language>
        </li>
    );
}

export default LanguageListItem