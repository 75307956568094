import React, {ReactNode} from 'react'

interface TicketRouteTitleProps {
    departureStationName: string,
    destinationStationName: string,
    routeIcon: ReactNode,
}

const TicketRouteTitle = ({departureStationName, destinationStationName, routeIcon}: TicketRouteTitleProps) => (
    <div>
        <span className="station-info gb--emphasize-default">
            {departureStationName}
        </span>
        &nbsp;
        {routeIcon}
        &nbsp;
        <span className="station-info gb--emphasize-default">
            {destinationStationName}
        </span>
    </div>
)

export default TicketRouteTitle