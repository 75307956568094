import React from 'react';
import LazyLoading from "@Features/lazyLoading/components/LazyLoading";
import NoResultsNotification from "@MyProfile/components/NoResultsNotification";
import VoucherType from "@MyVoucher/types/Voucher.type";
import Vouchers from "@MyVouchers/components/Vouchers";

interface MyVouchersProps {
    vouchers: VoucherType[],
    elRef: React.Ref<any>,
    page: number,
    setPage: (value: number) => void,
    totalCount: number,
    totalCountLoading: boolean,
    loading: boolean,
    batchLoading: boolean,
    noResultsNotificationKey?: string,
}

function MyVouchers(props: MyVouchersProps) {
    const {
        vouchers = [],
        elRef,
        page,
        setPage,
        totalCount,
        totalCountLoading,
        loading,
        batchLoading,
        noResultsNotificationKey,
    } = props

    return (
        <LazyLoading
            loading={loading || totalCountLoading}
            batchLoading={batchLoading}
            fetchedDataLength={vouchers?.length}
            totalDataLength={totalCount}
            page={page}
            setPage={setPage}
            noResultsNotification={(
                <NoResultsNotification
                    noResultsKey={noResultsNotificationKey}
                />
            )}
        >
            <Vouchers
                vouchers={vouchers}
            />
        </LazyLoading>
    )
}

export default MyVouchers
