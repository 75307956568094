import React from 'react';
import getFormattedTravelTime from "@Route/functions/getFormattedTravelTime";

interface TimeProps {
    time: string,
    format?: string,
    className?: string,
}

const Time = ({time, format = 'HH:mm', className = "gb--emphasize gb--emphasize-default"}: TimeProps) => (
    <span className={className}>
        {getFormattedTravelTime({travelTime: time, formatTo: format})}
    </span>
);

export default Time;