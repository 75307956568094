import FerryType from "@Route/types/type/Ferry.type";

/**
 * Returns vehicle type translation for ferry
 */
export const routeVesselTypeResolver = (ferryVehicle: FerryType): string => {
    const vehicleTypes = {
        [`${ferryVehicle?.isCatamaran}`]: 'c_profile.transport_subtype.fast_ferry',
        [`${ferryVehicle?.isCatamaranWithCar}`]: 'c_profile.transport_subtype.car_catamaran',
        [`${ferryVehicle?.isCarFerry}`]: 'c_profile.transport_subtype.car_ferry',
        [`${ferryVehicle?.isShip}`]: 'c_profile.transport_subtype.ferry',
    }

    return vehicleTypes.true
}