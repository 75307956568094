import useHasActiveOverlayOnSmallScreen from "@Features/domElements/hooks/useHasActiveOverlayOnSmallScreen";
import useRouteMatch from "@Routing/hooks/useRouteMatch";
import useIsMobile from "@Hooks/selector/useIsMobile";
import useIsSmallTablet from "@Hooks/selector/useIsSmallTablet";
import {useLayoutEffect} from "react";

/**
 * Toggles main content DOM element
 */
export default function useToggleMainContentElement() {
    const hasActiveOverlayOnSmallScreen = useHasActiveOverlayOnSmallScreen()
    const isMyProfile = useRouteMatch('myProfile')

    const isMobile = useIsMobile()
    const isSmallTablet = useIsSmallTablet()
    const isSmallScreen = isMobile || isSmallTablet

    const toggleMainContentElement = () => {
        const mainContent = document.getElementById('main-content')

        if (!mainContent) {
            return
        }

        if (hasActiveOverlayOnSmallScreen || (isMyProfile && isSmallScreen)) {
            mainContent.classList.add('hide')
        } else {
            mainContent.classList.remove('hide')
        }
    }

    useLayoutEffect(() => {
        toggleMainContentElement()
    }, [hasActiveOverlayOnSmallScreen, isMyProfile, isSmallScreen]);
}