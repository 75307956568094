import format from "date-fns/format"
import setLocale from "./setLocale";
import convertToServerTime from "./convertToServerTime";

/**
 * Returns localized date in yyyy-MM-dd or custom format
 * * WARNING: 'DD' & 'YYYY' are not valid in Date object, use yyyy && dd
 * @param stringOrDate {string|Date}
 * @param customFormat {string}
 * @param convertToUTC {boolean} - Should the return date have UTC+2 timezone
 */
export default function formatDate(stringOrDate: string | Date, customFormat: string = undefined, convertToUTC: boolean = false): string {
    const locale = setLocale()
    let date;

    if (typeof stringOrDate === 'string') {
        date = new Date(stringOrDate.replace(" ", "T"))
    } else {
        date = stringOrDate
    }

    if (convertToUTC) {
        date = convertToServerTime(date)
    }

    return format(date, customFormat || 'yyyy-MM-dd', {locale})
}