import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {
    DepartureTimeSlotsType
} from "@SearchResults/features/sidebar/features/departureTimeFilters/types/DepartureTimeSlots.type";
import {
    DepartureTimeType
} from "@SearchResults/features/sidebar/features/departureTimeFilters/types/DepartureTime.type";
import setHours from "date-fns/setHours";
import addDays from "date-fns/addDays";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";
import getRouteDateTime from "@Route/functions/getRouteDateTime";
import isBefore from "date-fns/isBefore";
import isAfter from "date-fns/isAfter";
import isEqual from "lodash/isEqual";

export default function useFilterByDepartureTimeSlots() {
    const {filterDepartureTimeSlots}: {
        filterDepartureTimeSlots: DepartureTimeSlotsType
    } = useAppSelector((state) => state.filter);

    function parseDateTimeForDepartureTimeSlot(comparisonDate: Date) {
        const parseDateByLabel = (label: DepartureTimeType, comparisonDate: Date) => {
            switch (label) {
                case 'morning':
                    return {
                        start: setHours(comparisonDate, 5),
                        end: setHours(comparisonDate, 12),
                    }
                case 'afternoon':
                    return {
                        start: setHours(comparisonDate, 12),
                        end: setHours(comparisonDate, 19),
                    }
                case 'evening':
                    return {
                        start: setHours(comparisonDate, 19),
                        end: addDays(setHours(comparisonDate, 0), 1),
                    }
                case 'night':
                    return {
                        start: setHours(comparisonDate, 0),
                        end: setHours(comparisonDate, 5),
                    }
                default:
                    return {
                        start: comparisonDate,
                        end: comparisonDate,
                    }
            }
        }

        return Object.entries(filterDepartureTimeSlots).filter((departureTimeSlot) => {
            if (departureTimeSlot[1]) {
                return departureTimeSlot[0]
            }
        }).map((departureTimeSlot) => (
            parseDateByLabel(departureTimeSlot[0] as DepartureTimeType, comparisonDate)
        ))
    }

    const filterByDepartureTimeSlots = (route: RouteWithAddedPropsAndPricesType) => {
        const departureTime = new Date(getRouteDateTime({
            route,
            type: "departure",
        }))
        const departureDate = new Date(route.travelInfo.departureStation.departureDate)
        const validHours = parseDateTimeForDepartureTimeSlot(departureDate)

        return validHours.some((date) => {
            return (isBefore(departureTime, date.end) && isAfter(departureTime, date.start)) || isEqual(departureTime, date.start) || isEqual(departureTime, date.end)
        })
    }

    return {
        filterDepartureTimeSlots,
        filterByDepartureTimeSlots,
        anySlotFiltered: Object.values(filterDepartureTimeSlots).some((slot) => slot),
    }
}