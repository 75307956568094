import {ChangeEvent} from "react";
import GetOnChangeFunctionAttributesType
    from "@CheckOrder/features/TicketAdditionalInfo/features/dateAdditionalField/types/GetOnChangeFunctionAttributes.type";
import {
    numberOfDigits
} from "@CheckOrder/features/TicketAdditionalInfo/features/dateAdditionalField/constants/yearDigitsConstraints";

interface OnYearChangeGetterAttributes {
    year: string,
    setYear: (value: string) => void,
}

/*
 * returns getOnYearChange function, which accepts next input field's ref and on change function and
 * updates the year value according to the new value (max 4 digits), triggers next input field change if necessary
 */
export default function onYearChangeGetter({year, setYear}: OnYearChangeGetterAttributes) {
    const getOnYearChange = (attr: GetOnChangeFunctionAttributesType) => (e: ChangeEvent<HTMLInputElement>, overflowCharacter?: string) => {
        const {
            nextRef,
            nextFieldOnChange,
        } = attr

        if (overflowCharacter) {
            setYear(overflowCharacter)
            return;
        }

        const {target: {value: newValue}} = e
        const characterIsAdded = newValue.length > year.length
        const newCharacter = characterIsAdded ? newValue[newValue.length - 1] : ""

        if (characterIsAdded && !year) {
            setYear(newValue)
            return;
        }

        if (characterIsAdded && year.length === numberOfDigits) {
            if (!!nextRef && !!nextFieldOnChange) {
                nextRef.current.focus()
                nextFieldOnChange(e, newCharacter)
            }
            return;
        }

        setYear(newValue)
    }

    return {
        getOnYearChange,
    }
}