import {searchResultsApi} from "@SearchResults/api/searchResultsApi";
import {useMemo} from "react";
import useSearchParamsExtractedFromRoute from "@SearchResults/hooks/useSearchParamsExtractedFromRoute";
import {useAppSelector} from "@Hooks/selector/useAppSelector";

export default function useSearchResults() {
    const params = useSearchParamsExtractedFromRoute()
    const searchResults = useMemo(
        () => searchResultsApi.endpoints.getSearchResults.select(params),
        [params]
    )
    const {data, isLoading} = useAppSelector(searchResults)

    return {
        data,
        isLoading,
    }
}