import React, {ReactNode} from 'react';
import Portal from "./Portal";

interface PortalMapProps {
    // Classname for portal to inject itself into
    className: string,
    // Elements inserted
    children: ReactNode,
    // Type of element, optional, div by default
    type?: string,
}

function PortalMap({className, children, type}: PortalMapProps) {
    const elements = document.getElementsByClassName(className)
    // @ts-ignore
    const elementsArray = [...elements]
    return (
        <>
            {elementsArray.map((element, index) => {
                // @ts-ignore
                const indexedChild = React.cloneElement(children, {index});
                return (
                    <Portal
                        class={className}
                        type={type}
                        index={index}
                        /* eslint-disable-next-line react/no-array-index-key */
                        key={index}
                    >
                        {indexedChild}
                    </Portal>
                )
            })}
        </>
    );
}

export default PortalMap;