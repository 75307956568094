import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconPaperTicket(props: IconType) {
    const {
        width = '20',
        height = '20',
        fill = '#f5a623',
        viewBox = '0 0 20 20',
        title = 'IconPaperTicket',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none">
                    <path
                        d="m0 2.63636364v14.72727276c0 .9.69642857 1.6363636 1.54761905 1.6363636h9.88928575c.8511904 0 1.547619-.7363636 1.547619-1.6363636l.0154762-13.09090913c0-1.8-1.377381-3.27272727-3.0952381-3.27272727h-8.35714285c-.85119048 0-1.54761905.73636364-1.54761905 1.63636364z"
                    />
                    <path
                        d="m10.4106548 0c.9633741 0 1.8490566.39310345 2.5327414 1.09741379.6526082.70431035 1.0255272 1.62155173 1.0410655 2.60431035 0 .01637931 0 .04913793.0155383.06551724 0 .06551724-.0155383.13103448-.0466149.19655172-.0776914.16379311-.2486126.29482759-.4350721.29482759h-1.5538291c-1.1187569 0-2.0199778-.95-2.0199778-2.12931035v-1.14655172h-7.9245283c-.60599334 0-1.08768035.50775862-1.08768035 1.14655172v14.74137936c0 .6551724.51276359 1.1465517.77691454 1.1465517h3.10759287c.26415094 0 .46614872.212931.46614872.4913793s-.20199778.4913793-.46614872.4913793h-3.10759287c-.79245283 0-1.70921199-.9336207-1.70921199-2.1293103v-14.74137936c0-1.17931034.90122087-2.12931034 2.0199778-2.12931034zm.4661488 1.03189655v1.09741379c0 .63879311.481687 1.14655173 1.0876803 1.14655173h1.0410655c-.0932298-.55689655-.3418424-1.06465517-.7302997-1.47413793-.3884572-.40948276-.8701443-.67155173-1.3984461-.76982759z"
                        fill={fill}
                    />
                    <g transform="translate(4.664488 5.664488)">
                        <path
                            d="m.67973856 11.1023965v-2.49237471c0-1.24618737 1.01960785-2.26579521 2.26579521-2.26579521h9.06318083c1.2461874 0 2.2657952 1.01960784 2.2657952 2.26579521v2.49237471"
                        />
                        <path d="m2.945534 9.51634h9.063181v3.398693h-9.063181z"/>
                        <g fill={fill}>
                            <path
                                d="m12.0087146 0c.3851852 0 .6797386.29455338.6797386.67973856v5.66448802c0 .38518518-.2945534.67973856-.6797386.67973856h-9.06318083c-.38518519 0-.67973856-.29455338-.67973856-.67973856v-5.66448802c0-.38518518.29455337-.67973856.67973856-.67973856zm-.6797386 1.35947712h-7.70370367v4.3050109h7.70370367z"
                            />
                            <path
                                d="m12.0087146 8.83660131c.3851852 0 .6797386.29455337.6797386.67973856v3.39869283c0 .3851852-.2945534.6797385-.6797386.6797385h-9.06318083c-.38518519 0-.67973856-.2945533-.67973856-.6797385v-3.39869283c0-.38518519.29455337-.67973856.67973856-.67973856zm-.6797386 1.35947709h-7.70370367v2.0392157h7.70370367z"
                            />
                            <path
                                d="m14.2745098 11.1023965c-.3851852 0-.6797386-.2945534-.6797386-.6797385v-1.81263621c0-.88366013-.7023965-1.58605665-1.5860566-1.58605665h-9.06318083c-.88366013 0-1.58605665.70239652-1.58605665 1.58605665v1.81263621c0 .3851851-.29455337.6797385-.67973856.6797385-.38518518 0-.67973856-.2945534-.67973856-.6797385v-1.81263621c0-1.63137255 1.31416122-2.94553377 2.94553377-2.94553377h9.06318083c1.6313725 0 2.9455338 1.31416122 2.9455338 2.94553377v1.81263621c0 .3851851-.2945534.6797385-.6797386.6797385z"
                            />
                        </g>
                    </g>
                </g>
            )}
        />

    );
}

export default IconPaperTicket;
