import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconMoonOutline(props: IconType) {
    const {
        width = '12',
        height = '12',
        fill = '#4a79e2',
        viewBox = '0 0 12 12',
        title = 'IconMoonOutline',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <path
                    d="m6.25 11.9925483c2.32813493 0 4.4687625-1.3671892 5.4453261-3.48437918.0859376-.18750022.0468751-.41406299-.1015626-.56250067s-.3671879-.18750022-.5625006-.10156262c-.5703132.26562532-1.16406394.39843798-1.78125217.39843798-2.3437528 0-4.25000507-1.90625228-4.25000507-4.25000507 0-1.20312644.49218809-2.31250276 1.38281415-3.12500373.14843768-.14062516.20312524-.36718793.11718764-.56250067-.07812509-.18750022-.25781281-.31250037-.47656307-.30468786-3.23437885.11718764-5.77344438 2.75000328-5.77344438 5.99219464 0 3.30469144 2.69531571 6.00000718 6 6.00000718zm.00000119-1.0000012c-2.75780983 0-5-2.24219019-5-5.00000598 0-2.2656277 1.48437677-4.17187997 3.56250425-4.78906821-.52343813.82812599-.81250097 1.79687715-.81250097 2.78906583 0 2.89844095 2.3515653 5.25000626 5.24999672 5.25000626.28907238 0 .57813523-.02343753.85938561-.07031259-.93750116 1.13281389-2.35156535 1.82031469-3.85938561 1.82031469z"
                    fill={fill}
                />
            )}
        />

    );
}

export default IconMoonOutline;
