import React, {useState} from 'react';

interface TooltipProps {
    tooltipRef?: any,
    children: any,
    className?: string,
    direction?: "top" | "right" | "bottom" | "left",
    delay?: number,
    tooltipContent: any,
}

const Tooltip = (props: TooltipProps) => {
    const {
        children,
        direction = "top",
        delay = 300,
        tooltipContent,
        className = "",
        tooltipRef,
    } = props

    let timeout;
    const [active, setActive] = useState(false)

    const showTooltip = () => {
        timeout = setTimeout(() => {
            setActive(true)
        }, delay)
    }

    const hideTooltip = () => {
        clearInterval(timeout)
        setActive(false)
    }

    return (
        <div
            ref={tooltipRef}
            className={`tooltip__wrapper ${className}`}
            onMouseEnter={showTooltip}
            onMouseLeave={hideTooltip}
        >
            {children}
            {active && (
                <div className={`tooltip tooltip--${direction}`}>
                    {tooltipContent}
                </div>
            )}
        </div>
    );
}

export default Tooltip;