import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {toggleCookiePreferences} from "@Reducers/pageSlice";
import Modal from "@Generic/Modal/Modal";
import CookieTabs from "./CookieTabs";
import {useTranslation} from "@Translation/hooks/useTranslation";
import useLocale from "@Hooks/selector/useLocale";
import useCookies from "../hooks/useCookies";

function CookiePreferences() {
    const {
        agree_proceed: agreeAndProceedLabel,
        privacy_policy: privacyPolicyLabel,
        your_preferences: yourPreferencesLabel,
    } = useTranslation("cookie.preferences")

    const dispatch = useDispatch()
    const {siteUrl, cookiePreferencesActive} = useSelector((state) => state?.page)
    const locale = useLocale()
    const policyUrl = `${siteUrl}/${locale}/privacy-policy`
    const {
        eventCookie,
        hotjarCookie,
        acceptAllCookies,
    } = useCookies()

    const handleCookieAcceptance = () => {
        acceptAllCookies()
        dispatch(toggleCookiePreferences())
    }

    const handleClickOnElementOutsideOfReact = ({target: {id}}) => {
        if (id === 'activate-cookie-preferences') {
            dispatch(toggleCookiePreferences())
        }
    };

    useEffect(() => {
        window.addEventListener('click', handleClickOnElementOutsideOfReact);

        // cleanup this component
        return () => {
            window.removeEventListener('click', handleClickOnElementOutsideOfReact);
        };
    }, []);

    if (cookiePreferencesActive) {
        return (
            <Modal
                title={yourPreferencesLabel}
                alternativeActionUrl={policyUrl}
                alternativeActionText={privacyPolicyLabel}
                buttonText={agreeAndProceedLabel}
                handleClick={handleCookieAcceptance}
                message={(
                    <CookieTabs
                        eventCookie={eventCookie}
                        hotjarCookie={hotjarCookie}
                    />
                )}
                className="modal-dialog gb--cookie-config-modal"
                closeAction={() => dispatch(toggleCookiePreferences())}
            />
        );
    }

    return null
}

export default CookiePreferences