/**
 * for tickets where user refreshes page on check order (either manually or by some action (e.g. add-vehicle action)),
 * where passenger's data is set, initialize input field states from data returned from BE (previously set date)
 */
export default function getDateFieldStateInitializer(value: string) {
    const fieldStateInitializer = (state: "D" | "M" | "Y") => {
        const dateFormatMapper = {
            Y: 0,
            M: 1,
            D: 2,
        }

        return value ? value.split("-")[dateFormatMapper[state]] : ""
    }

    return {
        fieldStateInitializer,
    }
}