import Row from "@Containers/components/Row";
import Translation from "@Translation/components/Translation";
import React from "react";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import {ArrayFilterState, clearArrayFilter} from "@SearchResults/reducers/filterSlice";
import {isEmpty} from "@Array/isEmpty";

type FilterSectionTitleProps = {
    hasSelection?: boolean,
    translationKey: string
    filterDataKey?: keyof ArrayFilterState
}

function FilterSectionTitle({translationKey, filterDataKey}: FilterSectionTitleProps) {
    const filterData = useAppSelector((state) => state.filter)
    const selectedFilterData: [] = filterDataKey ? filterData[filterDataKey] : null
    const dispatch = useAppDispatch()
    const onPress = () => {
        dispatch(clearArrayFilter(filterDataKey))
    }

    return (
        <Row className="gb--sidebar-section-content-item-title">
            <span className="gb--emphasize">
                <Translation translationKey={translationKey}/>
            </span>
            {!isEmpty(selectedFilterData) && (
                <>
                    <span className="gb--emphasize divider">
                        |
                    </span>
                    <button className="gb--emphasize-info" type='button' onClick={onPress}>
                        <Translation translationKey="search_results.clear_selection"/>
                    </button>
                </>
            )}
        </Row>
    );
}

export default FilterSectionTitle;