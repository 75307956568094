import React from 'react';
import TicketsProvider from "@MyTickets/context/components/TicketsProvider";
import MyTicketsContainer from "@MyTickets/components/MyTicketsContainer";

function MyTicketsOutlet() {
    return (
        <TicketsProvider>
            <MyTicketsContainer/>
        </TicketsProvider>
    );
}

export default MyTicketsOutlet;