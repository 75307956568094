import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconSlimArrowRight(props: IconType) {
    const {
        width = '17',
        height = '9',
        fill = '#4a79e2',
        viewBox = '0 0 17 9',
        title = 'IconSlimArrowRight',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <path
                    d="m15.2877736 2.71336113c-.3850209.29790911-.4608139.7670667-.114567 1.12427712l.0893263.08096321 2.1154671 1.80839854-12.003.00097927c-.50216912 0-.875.2884771-.875.79015545 0 .43737921.29886842.73469909.73217624.78259495l.11706641.0068966 12.02975735.00037373-2.1100853 1.80412009c-.4273392.32990559-.4273392.86554335.0188589 1.21078821.1627769.1259482.3610719.1770917.5872264.1770917.1611051 0 .3072306-.0260918.4390575-.0872922l.1000084-.0568415 3.8162564-3.23935636c.1746641-.13445916.2696777-.35500879.2696777-.59837522 0-.20280537-.0659816-.38976573-.1995265-.53276467l-3.8199351-3.25713637c-.385979-.29935406-.8250978-.29935406-1.1927648-.01487255z"
                    fill={fill}
                    transform="translate(-4 -2)"
                />
            )}
        />

    );
}

export default IconSlimArrowRight;
