import React from 'react';
import IntermodalRouteTravelDetails from "@Intermodal/components/mobile/IntermodalRouteTravelDetails";
import MobileSummarySection from "@Route/features/mobile/components/MobileSummarySection";
import ParamsType from "@SearchResults/types/params/Params.type";
import usePrepareIntermodalRoute from "@Intermodal/hooks/usePrepareIntermodalRoute";
import CompanyType from "@SearchResults/types/company/Company.type";
import PassengerGroupType from "@SearchResults/types/passenger/PassengerGroup.type";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import IntermodalRouteType from "@Intermodal/types/IntermodalRoute.type";

interface IntermodalAdditionalInfoProps {
    route: IntermodalRouteType,
    params: ParamsType,
    isReturn: boolean,
    companies: Record<number, CompanyType>,
    passengerGroups: Record<number, PassengerGroupType>,
}

function IntermodalAdditionalInfo({route, params, isReturn, passengerGroups, companies}: IntermodalAdditionalInfoProps) {
    const {currency} = useAppSelector((state) => state.searchResults)
    const {prepareIntermodalRoute} = usePrepareIntermodalRoute({
        passengerGroups,
        currency,
        companies,
    })
    const preparedIntermodalRoute = prepareIntermodalRoute(route)

    return (
        <>
            <IntermodalRouteTravelDetails
                route={preparedIntermodalRoute}
            />
            <MobileSummarySection
                isReturn={isReturn}
                route={preparedIntermodalRoute}
                params={params}
            />
        </>
    );
}

export default IntermodalAdditionalInfo;