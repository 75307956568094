import React from 'react';
import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "@/types/Icon.type";

const IconStar = (props: IconType) => {
    const {
        width = '20',
        height = '20',
        fill = '#fff',
        viewBox = '0 0 20 20',
        title = 'IconStar',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...props}
            svgIconPath={(
                <path
                    d="m11.4806005 15c.4160574 0 .7593047-.1588542 1.029742-.4765625s.3744516-.6848958.312043-1.1015625l-.5616774-3.40625 2.3559248-2.40625c.1768243-.1875.2912401-.40104167.3432473-.640625.0624086-.25.0520072-.49739583-.0312043-.7421875s-.2210305-.44791667-.413457-.609375c-.1924266-.16145833-.413457-.26302083-.6630914-.3046875l-3.198441-.484375-1.4353979-3.046875c-.11441578-.25-.28603944-.44270833-.51487099-.578125-.21843011-.13541667-.45246238-.203125-.70209679-.203125-.24963442 0-.48366669.06770833-.7020968.203125-.22883155.13541667-.40045521.328125-.51487098.578125l-1.4353979 3.046875-3.19844096.484375c-.24963442.04166667-.47066489.14322917-.66309142.3046875s-.33024553.36458333-.41345701.609375c-.08321147.24479167-.09621326.48958333-.03900537.734375.05720788.24479167.17422402.4609375.35104839.6484375l2.35592481 2.40625-.56167744 3.40625c-.0624086.4166667.04160574.7838542.31204303 1.1015625.27043728.3177083.6136846.4765625 1.02974196.4765625.22883155 0 .44726167-.0572917.65529035-.171875l2.82398934-1.5625 2.82398938 1.5625c.2080286.1145833.4264588.171875.6552903.171875z"
                    fill="none" stroke="#070c63"/>
            )}
        />
    );
};

export default IconStar;
