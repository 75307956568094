import React from 'react';
import {useToggle} from "@Hooks/dispatch/useToggle";
import {useTranslation} from "@Translation/hooks/useTranslation";
import Column from "@Containers/components/Column";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import VoucherInputField from "@CheckOrder/components/MainSection/Voucher/VoucherInputField";
import VoucherNotice from "@CheckOrder/components/MainSection/Voucher/VoucherNotice";

function Voucher() {
    const {voucher: {voucherDetails}} = useAppSelector((state) => state?.checkOrder.ticket)
    const toggle = useToggle(!!voucherDetails)
    const {
        have_a_voucher: voucherTitle,
        voucher_code: voucherCode,
    } = useTranslation("sys")
    const {apply} = useTranslation("check_order")

    const VoucherHeading = ({heading}) => (
        <span className="gb--card-title">
            {heading}
        </span>
    )

    return (
        <Column className="gb--card gb--check-order-voucher">
            {!toggle.value ? (
                <>
                    <VoucherHeading
                        heading={voucherTitle}
                    />
                    <VoucherNotice onClick={toggle.onChange}/>
                </>
            ) : (
                <>
                    <VoucherHeading
                        heading={`${apply} ${voucherCode}`}
                    />
                    <VoucherInputField onCancelClick={toggle.onChange}/>
                </>
            )}
        </Column>
    );
}

export default Voucher;