import {useAppSelector} from "@Hooks/selector/useAppSelector";
import StationWithDateTimesType from "@SearchResults/types/station/StationWithDateTimes.type";
import useGenerateSearchUrl from "@SearchResults/hooks/useGenerateSearchUrl";
import UrlParamsType from "@Form/types/UrlParams.type";
import useIsFerry from "@GetByFerry/hooks/useIsFerry";
import {useNavigate} from "react-router-dom";
import useLocale from "@Hooks/selector/useLocale";

export default function useResolvePreviousRouteFromCheckOrderData() {
    const {parseInitialParamsAndGenerateSearchUrl} = useGenerateSearchUrl()
    const navigate = useNavigate()
    const locale = useLocale()
    const isGetByFerry = useIsFerry()
    const {
        ticket,
        purchasedRoute,
    } = useAppSelector((state) => state.checkOrder)

    const getParamsFromStations = (departureStation: StationWithDateTimesType, destinationStation: StationWithDateTimesType): UrlParamsType => {
        return {
            locale,
            transportType: isGetByFerry ? 'ferry' : 'bus',
            date: departureStation.departureDate,
            fromSlug: departureStation.slug,
            fromId: departureStation.id,
            toId: destinationStation.id,
            toSlug: destinationStation.slug,
        }
    }

    const resolveParamsFromTravelInfo = () => {
        if (ticket.isIntermodal) {
            const {departureStation} = purchasedRoute.singleRoutes[0].travelInfo
            const {destinationStation} = purchasedRoute.singleRoutes.at(-1).travelInfo

            return getParamsFromStations(departureStation, destinationStation)
        }

        if (ticket.isReturn) {
            const {departureStation, destinationStation} = purchasedRoute.singleRoute.travelInfo
            const {departureStation: returnDepartureStation} = purchasedRoute.returnRoute.travelInfo

            return {
                ...getParamsFromStations(departureStation, destinationStation),
                returnDate: returnDepartureStation.departureDate,
            }
        }

        const {departureStation, destinationStation} = purchasedRoute.singleRoute.travelInfo

        return getParamsFromStations(departureStation, destinationStation)
    }

    const resolvePreviousRouteFromCheckOrderData = () => {
        return parseInitialParamsAndGenerateSearchUrl(resolveParamsFromTravelInfo())
    }

    const goBack = () => {
        return navigate(resolvePreviousRouteFromCheckOrderData())
    }

    return {
        resolvePreviousRouteFromCheckOrderData,
        // Only use this method when we land on new tabs that have no history
        goBack: history.length > 2 ? undefined : goBack,
    }
}