import {useTranslation} from "@Translation/hooks/useTranslation";
import useMakeDescription from "@MyProfile/hooks/useMakeDescription";
import {isTicketsPage, isVouchersPage, ticketsUrl} from "@MyProfile/functions/isActivePage";
import useCountArchivedTickets from "@MyProfile/features/tickets/hooks/useCountArchivedTickets";
import useCountValidTickets from "@MyProfile/features/tickets/hooks/useCountValidTickets";

export default function useMyTicketsLink() {
    const ticketsLabel = useTranslation("sys.my_tickets")

    const {
        validTicketsCount,
        validTicketsCountLoading,
    } = useCountValidTickets()

    const {
        archivedTicketsCount,
        archivedTicketsCountLoading,
    } = useCountArchivedTickets()

    const {makeDescription} = useMakeDescription()

    const validLabel = useTranslation(
        'my_profile.links.valid_tickets',
        {
            count: validTicketsCount,
        }
    )
    const archivedLabel = useTranslation(
        'my_profile.links.archived_tickets',
        {
            count: archivedTicketsCount,
        }
    )

    const ticketsDescription = makeDescription({
        validCountLoading: validTicketsCountLoading,
        archivedCountLoading: archivedTicketsCountLoading,
        showAll: isVouchersPage(),
        allText: `X ${validTicketsCount + archivedTicketsCount}`,
        validText: `X ${validLabel}`,
        archivedText: `X ${archivedLabel}`,
    })
    return {
        url: ticketsUrl,
        isActive: isTicketsPage,
        label: ticketsLabel,
        descriptionText: ticketsDescription,

    }
}