import React from 'react';
import Translation from "@Translation/components/Translation";
import IconMinivanOutline from "@Icons/IconMinivanOuline";

function MinivanLabel({isPrivate}: {isPrivate: boolean}) {
    const translationKey = isPrivate ? 'private_minivan' : 'shared_minivan'

    return (
        <span className="gb--badge gb--badge-left gb--badge-blue">
            <IconMinivanOutline/>
            <Translation translationKey={`search_results.transport_labels.minivan.${translationKey}`}/>
        </span>
    )
}

export default MinivanLabel;