import React from 'react';
import SlideInContainer from "@Containers/components/SlideInContainer";
import Row from "@Containers/components/Row";
import IconTimes from "@Icons/IconTimes";
import GetByApsLogo from "@Generic/Logotypes/GetByApsLogo";
import Form from "../Form";
import {setFormVisibility} from "../../reducers/formSlice";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";

function MobileFormOverlay() {
    const dispatch = useAppDispatch()
    const closeForm = () => dispatch(setFormVisibility(false))

    return (
        <SlideInContainer className="gb--mobile-form-overlay-container" slideInDirection="up">
            <Row center justify>
                <span>
                    <GetByApsLogo/>
                </span>
                <button type="button" onClick={closeForm}>
                    <IconTimes/>
                </button>
            </Row>
            <Form/>
        </SlideInContainer>
    );
}

export default MobileFormOverlay;