import React from 'react';

interface ProgressBarProps {
    percentage: any,
    displayValue?: boolean,
    displaySign?: boolean,
    convertToPercentage?: boolean,
}

function ProgressBar(props: ProgressBarProps) {
    const {
        percentage,
        displayValue = false,
        displaySign = false,
        convertToPercentage = false,
    } = props

    const width = convertToPercentage ? `${parseFloat(percentage) * 10}%` : `${percentage}%`
    return (
        <>
            <div className="gb--progress-bar-wrapper">
                <div className="gb--progress-bar gb--progress-bar-green" style={{width}}/>
            </div>
            {displayValue && (
                <span>
                    {percentage}
                    {displaySign && '%'}
                </span>
            )}
        </>
    )
}

export default ProgressBar;