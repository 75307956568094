import {memo} from 'react';
import CardMediaFullWidth from "@Generic/Cards/CardMediaFullWidth";
import useCountryPageUrl from "@Pages/homepage/hooks/useCountryPageUrl";
import CountryType from "@/types/pageSlice/homepageData/Country.type";

interface CountryHomeProps {
    country: CountryType
}

const CountryHome = ({country}: CountryHomeProps) => (
    <CardMediaFullWidth
        cardImagePath={country.imagePath}
        cardImageAlt={country.name}
        cardTitle={country.name}
        cardLink={useCountryPageUrl(country)}
        cardLinkTranslation="country_page.country_index.travel_information"
    />
);

export default memo(CountryHome);