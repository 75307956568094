import sub from "date-fns/sub"
import parseISO from "date-fns/parseISO"
import isBefore from "date-fns/isBefore"
import RouteType from "@Route/types/Route.type";
import IntermodalRouteWithDetailsType from "@Intermodal/types/IntermodalRouteWithDetails.type";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";

/**
 * Method will check if route is past sale block time and return bool value.
 */
interface IsPastRouteSaleBlockTimeAttributes {
    route: RouteType | RouteWithAddedPropsAndPricesType | IntermodalRouteWithDetailsType,
    date: string,
}

export const isRoutePastSaleBlockTime = ({route: {allotment}, date}: IsPastRouteSaleBlockTimeAttributes): boolean => {
    if (allotment?.startTime && allotment?.saleBlockTime) {
        const dateTime = parseISO(`${date} ${allotment.startTime}`)
        const addedSaleBlockTime = sub(dateTime, {
            hours: +allotment.saleBlockTime.split(':')[0],
            minutes: +allotment.saleBlockTime.split(':')[1],
        })

        return isBefore(addedSaleBlockTime, new Date())
    }

    return false;
};
