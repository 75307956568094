import React, {useState} from 'react';
import useDismissAllOverlays from "@MobileOverlays/hooks/useDismissAllOverlays";
import {useTranslation} from "@Translation/hooks/useTranslation";
import SignIn from "./SignIn";
import ModalContent from "@Generic/Modal/ModalContent";
import ModalHeader from "@Generic/Modal/ModalHeader";
import ModalBody from "@Generic/Modal/ModalBody";
import SignUp from "./SignUp";
import UserModalTitle from "@User/components/UserModalTitle";

function UserAccount() {
    const dismissAllOverlays = useDismissAllOverlays()
    const toggleModal = () => () => dismissAllOverlays()
    const [activeContent, setActiveContent] = useState('signIn')
    const {
        sign_in: signIn,
        sign_up: signUp,
        dont_have_account: noAccount,
        have_account: haveAccount,
    } = useTranslation('user')

    const setTitle = () => {
        if (activeContent === 'signIn') {
            return (
                <UserModalTitle
                    title={signIn}
                    subtitle={noAccount}
                    onClick={() => setActiveContent('signUp')}
                    switchToContent={signUp}
                />
            )
        }

        if (activeContent === 'signUp') {
            return (
                <UserModalTitle
                    title={signUp}
                    subtitle={haveAccount}
                    onClick={() => setActiveContent('signIn')}
                    switchToContent={signIn}
                />
            )
        }
    }

    const setContent = () => {
        if (activeContent === 'signIn') {
            return <SignIn/>
        }

        if (activeContent === 'signUp') {
            return <SignUp/>
        }
    }

    return (
        <ModalContent className="modal-dialog gb-modal-dialog user-account__modal">
            <ModalHeader
                closeAction={toggleModal()}
                title={setTitle()}
            />
            <ModalBody
                message={setContent()}
            />
        </ModalContent>
    )
}

export default UserAccount;