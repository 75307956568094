import React, {ReactNode} from 'react';

interface SlideInContainerProps {
    children: ReactNode,
    className?: string,
    slideInDirection?: 'up' | 'down' | 'left' | 'right' | 'zoom',
}

const SlideInContainer = ({children, className = "", slideInDirection = "down"}: SlideInContainerProps) => (
    <div className={`gb--slide-in-container gb--slide-in-${slideInDirection} ${className} `}>
        {children}
    </div>
);

export default SlideInContainer;