import React from 'react';

const FullScreenLoader = () => (
    <div id="gbLoader" style={{width: '200px' || 200, margin: "0 auto"}}>
        <svg height="200" width="200" preserveAspectRatio="xMidYMid meet">
            <g transform="translate(100, 100)">
                <circle cx="0" cy="0" r="40" stroke="#E6EAEE" strokeWidth="3" fill="transparent"/>
                <circle
                    cx="0"
                    cy="0"
                    r="40"
                    fill="transparent"
                    stroke="#7ed321"
                    strokeWidth="3"
                    strokeDasharray="45, 360"
                    strokeLinecap="round"
                >
                    <animateTransform
                        attributeType="xml"
                        attributeName="transform"
                        type="rotate"
                        from="0"
                        to="360"
                        begin="0"
                        dur="1.2s"
                        repeatCount="indefinite"
                    />
                </circle>
                <circle
                    cx="0"
                    cy="0"
                    r="40"
                    fill="transparent"
                    stroke="#4a79e2"
                    strokeWidth="3"
                    strokeDasharray="45, 360"
                    strokeLinecap="round"
                >
                    <animateTransform
                        attributeType="xml"
                        attributeName="transform"
                        type="rotate"
                        from="0"
                        to="360"
                        begin="-0.4s"
                        dur="1.2s"
                        repeatCount="indefinite"
                    />
                </circle>
                <circle
                    cx="0"
                    cy="0"
                    r="40"
                    fill="transparent"
                    stroke="#f5a623"
                    strokeWidth="3"
                    strokeDasharray="45, 360"
                    strokeLinecap="round"
                >
                    <animateTransform
                        attributeType="xml"
                        attributeName="transform"
                        type="rotate"
                        from="0"
                        to="360"
                        begin="-0.8s"
                        dur="1.2s"
                        repeatCount="indefinite"
                    />
                </circle>
            </g>
        </svg>
    </div>

);

export default FullScreenLoader;