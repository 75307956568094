import formatDate from "@DateTime/functions/formatDate";
import usePassengers from "@Hooks/selector/usePassengers";
import {generateQueryStringFromObject} from "@Object/generateQueryStringFromObject";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";
import ParamsType from "@SearchResults/types/params/Params.type";

export default function useRedirectUrlGenerator(params: ParamsType) {
    const {
        passengersAdult: adults,
        passengersChildren: children,
        passengersPensioner: students,
        passengersStudent: pensioners,
    } = usePassengers()

    const passengers = generateQueryStringFromObject({
        adults,
        children,
        students,
        pensioners,
    })

    const {
        isReturn,
        departureStation,
        destinationStation,
    } = params

    const generateRedirectUrlPrams = (route: RouteWithAddedPropsAndPricesType) => {
        const {
            company,
            id,
            companyId,
            apiCompanyId,
            status: {
                redirectLink,
                providerId,
            },
            travelInfo: {
                departureStation: {
                    departureTime,
                    departureDate,
                },
                destinationStation: {
                    arrivalTime,
                },
            },
        } = route

        const routeTime = (time: string) => (formatDate(new Date(`2000-01-01T${time}`), 'HH:mm'))
        const {
            name: partner,
            apiCompanyId: carrierId,
        } = company

        const redirectUrl = `//${window.location.hostname}/redirect?redirect_link=${encodeURIComponent(redirectLink)}`
            + `&departure=${departureStation.id}`
            + `&destination=${destinationStation.id}`
            + `&date=${departureDate}`
            + `&departureTime=${routeTime(departureTime)}`
            + `&destinationTime=${routeTime(arrivalTime)}`
            + `&partner=${partner}`
            + `&carrierId=${apiCompanyId}`
            + `&sourceLanguage=en`
            + `&sourceUrl=${encodeURIComponent(document.location.href)}`
            + `&providerId=${providerId}`
            + `&isReturnSearch=${isReturn ? 1 : 0}`
            + `&${passengers}`;

        return {
            redirectUrl,
            id,
            departureStation,
            destinationStation,
            departureDate,
            departureTime,
            arrivalTime,
            partner,
            carrierId,
            providerId,
            isReturn,
            companyId,
        }
    }

    return {
        generateRedirectUrlPrams,
        isReturn,
    }
}