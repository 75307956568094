import React from 'react';
import Translation from "@Translation/components/Translation";
import Column from "@Containers/components/Column";
import PaymentSummary from "@CheckOrder/features/paymentSummary/components/PaymentSummary";

const PaymentSection = () => (
    <Column className="gb--card gb--check-order-payment">
        <span className="gb--card-title">
            <Translation translationKey="sys.total"/>
        </span>
        <div className="gb--check-order-payment-breakdown">
            <PaymentSummary/>
        </div>
    </Column>
);

export default PaymentSection
