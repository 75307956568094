import {calculateTripDuration} from "@Route/functions/calculateTripDuration";
import RouteType from "@Route/types/Route.type";
import getMinimalPrice from "@Tabs/functions/getMinimalPrice";

/**
 * Generates tab based on type passed
 */

interface GenerateTabAttributes {
    routes: RouteType[],
    type: string,
}

export default function generateTab({routes, type}: GenerateTabAttributes) {
    const {travelInfo: {departureStation, destinationStation}} = routes[0];
    const activeRoutes = routes.filter((route) => route.status.status === 'open' && routes)
    const availableRoutes = routes.filter((route) => route.status.status !== 'unavailable' && routes)
    const minPrice = getMinimalPrice(activeRoutes)
    const redirectRoutes = routes.filter((route) => route.status.isApi && route.status.hasRedirectLink && routes)
    const duration = calculateTripDuration(
        `${departureStation.departureDate} ${departureStation.departureTime}`,
        `${destinationStation.arrivalDate} ${destinationStation.arrivalTime}`
    )

    return {
        tabType: type,
        routes: routes.length,
        availableRoutes: availableRoutes.length,
        hasNonRedirectRoutes: !!(routes.length - redirectRoutes.length),
        minPrice,
        fastestRoute: duration,
    }
}