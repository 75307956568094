import FilterSection from "@SearchResults/features/sidebar/components/FilterSection";
import React from "react";
import {SearchResultsRtkQueryResponseType} from "@SearchResults/api/types/SearchResultsRtkQueryResponse.type";
import MTicketEcoFriendlyFilter
    from "@SearchResults/features/sidebar/features/popularFilters/components/MTicketEcoFriendlyFilter";
import WebDiscountFilter from "@SearchResults/features/sidebar/features/popularFilters/components/WebDiscountFilter";
import InstantConfirmationFilter
    from "@SearchResults/features/sidebar/features/popularFilters/components/InstantConfirmationFilter";
import useDisplayPopularFilters
    from "@SearchResults/features/sidebar/features/popularFilters/hooks/useDisplayPopularFilters";

type PopularFiltersProps = {
    searchData: SearchResultsRtkQueryResponseType
}

function PopularFilters({searchData}: PopularFiltersProps) {
    const {
        displayMTicketFilter,
        displayInstantConfirmationFilter,
        displayWebDiscountFilter,
    } = useDisplayPopularFilters(searchData)

    if (!displayMTicketFilter && !displayInstantConfirmationFilter && !displayWebDiscountFilter) {
        return null;
    }

    return (
        <FilterSection translationKey="search_results.filter_by.popular_filters.label">
            {displayInstantConfirmationFilter && (
                <InstantConfirmationFilter/>
            )}
            {displayMTicketFilter && (
                <MTicketEcoFriendlyFilter/>
            )}
            {displayWebDiscountFilter && (
                <WebDiscountFilter/>
            )}
        </FilterSection>
    );
}

export default PopularFilters;