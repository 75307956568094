import {Dispatch, SetStateAction} from "react";
import {ActionSheetPositionType} from "@MobileOverlays/types/ActionSheetPosition.type";

export default function getActionSheetPositionAdjuster(setPosition: Dispatch<SetStateAction<ActionSheetPositionType>>) {
    const adjustPosition = (deltaX: number, deltaY: number) => {
        if (deltaY > 50) {
            setPosition((prevPosition) => (prevPosition !== 0 ? prevPosition - 1 : prevPosition) as ActionSheetPositionType)
        }
        if (deltaY < -50) {
            setPosition((prevPosition) => (prevPosition !== 2 ? prevPosition + 1 : prevPosition) as ActionSheetPositionType)
        }
    }

    return {
        adjustPosition,
    }
}