import {memo} from 'react';
import CountryHome from "@Pages/homepage/components/CountryHome";
import {useAppSelector} from "@Hooks/selector/useAppSelector";

function Countries() {
    const {countries} = useAppSelector((state) => state?.page.homepageData);
    const setColumnCounter = Math.round(countries.length / 2) + 1
    const setGlobalStyle = {
        "--column-count": `${setColumnCounter}`,
    };

    return (
        // @ts-ignore
        <div className="gb--cards" style={{...setGlobalStyle}}>
            {countries.map((country) => (
                <CountryHome
                    country={country}
                    key={country?.name}
                />
            ))}
        </div>
    );
}

export default memo(Countries);