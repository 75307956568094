import React from "react";
import TicketRouteType from "@MyTicket/types/TicketRoute.type";
import TicketNotifications from "@MyTicket/features/ticketDetails/components/TicketNotifications";
import TicketTravelDetails from "@MyTicket/features/ticketDetails/components/TicketTravelDetails";

interface TicketDataProps {
    isCanceled: boolean,
    isExchangedForVoucher: boolean,
    isExpired: boolean,
    isMTicket: boolean,
    id: string,
    route: TicketRouteType,
}

function TicketData(props: TicketDataProps) {
    const {
        isCanceled,
        isExchangedForVoucher,
        isExpired,
        isMTicket,
        id,
        route,
    } = props

    return (
        <>
            <TicketNotifications
                isCanceled={isCanceled}
                isExchangedForVoucher={isExchangedForVoucher}
                isExpired={isExpired}
                isMTicket={isMTicket}
            />
            <TicketTravelDetails
                id={id}
                route={route}
            />
        </>
    );
}

export default TicketData;