import {myProfileApi} from "@MyProfile/API/myProfileApi"
import {clearUserData, setLoading} from "@MyProfile/reducers/userProfileSlice";
import {clearLoggedInUserData, updateCsrfToken} from "@CheckOrder/reducers/checkOrderSlice";
import ajaxHandler from "../../../utils/ajax/v2/ajaxHandler";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import {useAppSelector} from "@Hooks/selector/useAppSelector";

/**
 * logout hook variant. has no side effects, only handles redux state.
 */
export default async function useLogout() {
    const dispatch = useAppDispatch()
    const {
        isCheckOrder,
        ticket,
    } = useAppSelector((state) => state?.checkOrder)

    return async () => {
        dispatch(setLoading(true))
        // Check order logout generates new csrf token for form
        const params = isCheckOrder ? {ticket_code: ticket?.ticketCode} : {}

        const response = await ajaxHandler({
            url: `/ajax/logout-user`,
            handle: "ignore",
            params,
            type: 'post',
            stringify: true,
        })

        if (!!response && response?.status === 200) {
            dispatch(clearUserData())
            dispatch(myProfileApi.util.resetApiState())
            if (isCheckOrder) {
                dispatch(clearLoggedInUserData())
                dispatch(updateCsrfToken(response))
            }
        } else {
            // eslint-disable-next-line no-console
            console.log("Server did not respond")
        }
        dispatch(setLoading(false))
    }
}