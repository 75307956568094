import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconCalendar(props: IconType) {
    const {
        width = '16',
        height = '16',
        fill = '#070c63',
        viewBox = '0 0 16 16',
        title = 'IconCalendar',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill={fill} transform="translate(1 1)">
                    <path
                        d="m10.4375 0c.15625 0 .2890625.0546875.3984375.1640625s.1640625.2421875.1640625.3984375v.4375h1.875c.3125 0 .578125.109375.796875.328125s.328125.484375.328125.796875v10.75c0 .3125-.109375.578125-.328125.796875s-.484375.328125-.796875.328125h-11.75c-.3125 0-.578125-.109375-.796875-.328125s-.328125-.484375-.328125-.796875v-10.75c0-.3125.109375-.578125.328125-.796875s.484375-.328125.796875-.328125h1.875v-.4375c0-.15625.0546875-.2890625.1640625-.3984375s.2421875-.1640625.3984375-.1640625.2890625.0546875.3984375.1640625.1640625.2421875.1640625.3984375v.4375h5.75v-.4375c0-.15625.0546875-.2890625.1640625-.3984375s.2421875-.1640625.3984375-.1640625zm2.4375 5h-11.75v7.875h11.75zm-9.875-2.875h-1.875v1.75h11.75v-1.75h-1.875v.3125c0 .15625-.0546875.2890625-.1640625.3984375s-.2421875.1640625-.3984375.1640625-.2890625-.0546875-.3984375-.1640625-.1640625-.2421875-.1640625-.3984375v-.3125h-5.75v.3125c0 .15625-.0546875.2890625-.1640625.3984375s-.2421875.1640625-.3984375.1640625-.2890625-.0546875-.3984375-.1640625-.1640625-.2421875-.1640625-.3984375z"
                    />
                    <path
                        d="m4.5 8c.13541667 0 .25260417-.04947917.3515625-.1484375s.1484375-.21614583.1484375-.3515625-.04947917-.25260417-.1484375-.3515625-.21614583-.1484375-.3515625-.1484375h-1c-.13541667 0-.25260417.04947917-.3515625.1484375s-.1484375.21614583-.1484375.3515625.04947917.25260417.1484375.3515625.21614583.1484375.3515625.1484375z"
                    />
                    <path
                        d="m7.5 8c.13541667 0 .25260417-.04947917.3515625-.1484375s.1484375-.21614583.1484375-.3515625-.04947917-.25260417-.1484375-.3515625-.21614583-.1484375-.3515625-.1484375h-1c-.13541667 0-.25260417.04947917-.3515625.1484375s-.1484375.21614583-.1484375.3515625.04947917.25260417.1484375.3515625.21614583.1484375.3515625.1484375z"
                    />
                    <path
                        d="m10.5 8c.1354167 0 .2526042-.04947917.3515625-.1484375s.1484375-.21614583.1484375-.3515625-.0494792-.25260417-.1484375-.3515625-.2161458-.1484375-.3515625-.1484375h-1c-.13541667 0-.25260417.04947917-.3515625.1484375s-.1484375.21614583-.1484375.3515625.04947917.25260417.1484375.3515625.21614583.1484375.3515625.1484375z"
                    />
                    <path
                        d="m4.5 11.03125c.13541667 0 .25260417-.0494792.3515625-.1484375s.1484375-.2161458.1484375-.3515625-.04947917-.2526042-.1484375-.3515625-.21614583-.1484375-.3515625-.1484375h-1c-.13541667 0-.25260417.0494792-.3515625.1484375s-.1484375.2161458-.1484375.3515625.04947917.2526042.1484375.3515625.21614583.1484375.3515625.1484375z"
                    />
                    <path
                        d="m7.5 11.03125c.13541667 0 .25260417-.0494792.3515625-.1484375s.1484375-.2161458.1484375-.3515625-.04947917-.2526042-.1484375-.3515625-.21614583-.1484375-.3515625-.1484375h-1c-.13541667 0-.25260417.0494792-.3515625.1484375s-.1484375.2161458-.1484375.3515625.04947917.2526042.1484375.3515625.21614583.1484375.3515625.1484375z"
                    />
                </g>
            )}
        />

    );
}

export default IconCalendar;
