import {useEffect} from "react";
import {isFirstCharacterZero} from "@Features/input/phoneField/functions/isFirstCharacterZero";
import PhoneCountryType from "@CheckOrder/types/PhoneCountry.type";
import usePrevious from "@Hooks/refs/usePrevious";
import getCountryBasedOnNumberSetter from "@Features/input/phoneField/functions/getCountryBasedOnNumberSetter";
import getAppendCountryCode from "@Features/input/phoneField/functions/getAppendCountryCode";

interface UseUpdatePhoneNumberAttributes {
    phoneNumber: string,
    setPhoneNumber: (value: string) => void,
    phoneCountryObject: PhoneCountryType,
    setPhoneCountryObject: (value: PhoneCountryType) => void,
    closePhoneCountrySelect: () => void,
    setValidState: () => void,
}

export default function useUpdatePhoneNumber(attributes: UseUpdatePhoneNumberAttributes) {
    const {
        phoneNumber,
        setPhoneNumber,
        phoneCountryObject,
        setPhoneCountryObject,
        closePhoneCountrySelect,
        setValidState,
    } = attributes

    const previousDialCode = usePrevious(phoneCountryObject?.dialCode)
    const setCountryBasedOnNumber = getCountryBasedOnNumberSetter(setPhoneCountryObject, closePhoneCountrySelect)
    const appendCountryCode = getAppendCountryCode(setPhoneNumber)

    useEffect(() => {
        const dialCode = phoneCountryObject?.dialCode

        if (dialCode !== previousDialCode) {
            appendCountryCode(dialCode, previousDialCode, phoneNumber)
        }

        if (phoneNumber && phoneNumber?.length > 1 && !isFirstCharacterZero(phoneNumber) && !dialCode) {
            setCountryBasedOnNumber(phoneNumber)
        }

        if (dialCode && phoneNumber?.length > 8) {
            setValidState()
        }
    }, [phoneCountryObject?.dialCode, phoneNumber])

    useEffect(() => {
        const dialCode = phoneCountryObject?.dialCode
        if (!phoneNumber?.length || (previousDialCode?.length > 0 && dialCode?.length - 1 >= phoneNumber?.length)) {
            setPhoneCountryObject({
                name: "",
                dialCode: "",
                isoCode: "",
            })
            closePhoneCountrySelect()
        }
    }, [phoneNumber])
}