import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconUserOutline(props: IconType) {
    const {
        width = '16',
        height = '16',
        fill = '#070c63',
        viewBox = '0 0 16 16',
        title = 'IconUserOutline',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill={fill} transform="translate(0 .5)">
                    <path
                        d="m.58172568 14.7178784c-.0975461 0-.19509221-.0195092-.29263831-.0780369-.2731291-.1560738-.37067521-.5072398-.21460144-.7998781 1.58024692-2.7703094 4.54564853-4.50663007 7.7451608-4.50663007.33165676 0 .58527664.25361988.58527664.58527664 0 .33165673-.25361988.58527663-.58527664.58527663-2.7703094 0-5.3455266 1.50221-6.7306813 3.9213535-.0975461.1755829-.29263831.2926383-.50723975.2926383z"
                        stroke="#070c63"
                        strokeWidth=".25"
                    />
                    <path
                        d="m8 0c1.10606061 0 2.0492424.39015152 2.8295455 1.17045455.780303.78030303 1.1704545 1.72348484 1.1704545 2.82954545s-.3939394 2.0530303-1.1818182 2.84090909c-.7727273.77272727-1.71212119 1.15909091-2.8181818 1.15909091s-2.04924242-.39015152-2.82954545-1.17045455-1.17045455-1.72348484-1.17045455-2.82954545.39015152-2.04924242 1.17045455-2.82954545 1.72348484-1.17045455 2.82954545-1.17045455zm0 1.33333333c-.73504274 0-1.36324786.26068376-1.88461538.78205129-.52136753.52136752-.78205129 1.14957264-.78205129 1.88461538s.26068376 1.36324786.78205129 1.88461538c.52136752.52136753 1.14957264.78205129 1.88461538.78205129s1.35897436-.25641026 1.87179487-.76923077c.52991453-.52991453.79487183-1.16239316.79487183-1.8974359s-.2606838-1.36324786-.78205132-1.88461538c-.52136752-.52136753-1.14957264-.78205129-1.88461538-.78205129z"
                    />
                    <path
                        d="m7.62100357 14.7178784c-.09754611 0-.19509221-.0195092-.29263832-.0780369-.2731291-.1560738-.3706752-.5072398-.21460143-.7998781 1.58024691-2.7703094 4.54564848-4.50663007 7.74516078-4.50663007.3316568 0 .5852767.25361988.5852767.58527664 0 .33165673-.2536199.58527663-.5852767.58527663-2.7703094 0-5.34552658 1.50221-6.73068128 3.9213535-.09754611.1755829-.29263832.2926383-.50723975.2926383z"
                        stroke="#070c63"
                        strokeWidth=".25"
                        transform="matrix(-1 0 0 1 22.48348 0)"
                    />
                </g>
            )}
        />

    );
}

export default IconUserOutline;
