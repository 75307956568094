import React from 'react';
import {useTranslation} from "@Translation/hooks/useTranslation";
import UserAccount from "@User/components/Modals/UserAccount";
import MobileOverlay from "./MobileOverlay";

function MobileLoginActive() {
    const title = useTranslation("welcome")

    return (
        <MobileOverlay title={title} slideInDirection="down">
            <UserAccount/>
        </MobileOverlay>
    )
}

export default MobileLoginActive;