import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function LogoGetByFerry(props: IconType) {
    const {
        width = '160',
        height = '34',
        viewBox = '0 0 160 34',
        title = 'GetByFerryLogo',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none">
                    <path
                        d="m7.02 7.128c.696 0 1.416.192 2.16.576s1.356 1.08 1.836 2.088h.072v-2.16h4.752v18.396c0 2.496-.618 4.368-1.854 5.616s-3.306 1.872-6.21 1.872c-2.112 0-3.738-.45-4.878-1.35s-1.71-2.178-1.71-3.834h4.752c0 .624.252 1.116.756 1.476.24.168.498.3.774.396s.57.144.882.144c1.008 0 1.716-.324 2.124-.972s.612-1.452.612-2.412v-2.556h-.072c-.504.72-1.122 1.296-1.854 1.728s-1.494.648-2.286.648c-1.968 0-3.45-.75-4.446-2.25s-1.494-4.014-1.494-7.542c0-1.128.066-2.28.198-3.456s.408-2.238.828-3.186 1.032-1.722 1.836-2.322 1.878-.9 3.222-.9zm1.476 3.816c-.552 0-.996.12-1.332.36s-.6.63-.792 1.17-.318 1.26-.378 2.16-.09 1.998-.09 3.294c0 .672.03 1.308.09 1.908s.18 1.134.36 1.602.432.84.756 1.116.75.414 1.278.414.966-.138 1.314-.414.624-.654.828-1.134.348-1.062.432-1.746.126-1.434.126-2.25c0-2.352-.186-4.02-.558-5.004s-1.05-1.476-2.034-1.476z"
                        fill="#7ed321"
                    />
                    <path
                        d="m26.316 7.128c1.44 0 2.622.234 3.546.702s1.65 1.128 2.178 1.98.888 1.884 1.08 3.096.288 2.562.288 4.05v1.116h-9.828v1.548c0 .576.042 1.134.126 1.674s.222 1.02.414 1.44.444.756.756 1.008.696.378 1.152.378c.84 0 1.452-.3 1.836-.9s.648-1.512.792-2.736h4.464c-.096 2.256-.696 3.972-1.8 5.148s-2.844 1.764-5.22 1.764c-1.8 0-3.204-.3-4.212-.9s-1.752-1.392-2.232-2.376-.774-2.088-.882-3.312-.162-2.448-.162-3.672c0-1.296.09-2.544.27-3.744s.54-2.268 1.08-3.204 1.314-1.68 2.322-2.232 2.352-.828 4.032-.828zm-.18 3.384c-.504 0-.918.114-1.242.342s-.582.528-.774.9-.33.786-.414 1.242-.126.912-.126 1.368v.756h5.076c-.024-.744-.072-1.398-.144-1.962s-.198-1.044-.378-1.44-.432-.696-.756-.9-.738-.306-1.242-.306z"
                        fill="#7ed321"
                    />
                    <path
                        d="m41.688 27.108c.576 0 1.122-.018 1.638-.054s1.05-.054 1.602-.054v-3.456c-.168.024-.348.042-.54.054s-.384.018-.576.018c-.72 0-1.194-.162-1.422-.486s-.342-.87-.342-1.638v-10.476h2.88v-3.384h-2.88v-5.544h-4.968v5.544h-2.52v3.384h2.52v11.088c0 .984.06 1.8.18 2.448s.342 1.17.666 1.566.792.66 1.404.792 1.398.198 2.358.198z"
                        fill="#7ed321"
                    />
                    <path
                        d="m52.64 1.296v8.136h.072c.528-.768 1.122-1.344 1.782-1.728s1.458-.576 2.394-.576c2.04 0 3.54.798 4.5 2.394s1.44 4.194 1.44 7.794-.48 6.18-1.44 7.74-2.46 2.34-4.5 2.34c-1.008 0-1.854-.18-2.538-.54s-1.302-.996-1.854-1.908h-.072v2.052h-4.752v-25.704zm2.628 9.648c-1.104 0-1.818.534-2.142 1.602s-.486 2.658-.486 4.77.162 3.702.486 4.77 1.038 1.602 2.142 1.602c1.08 0 1.782-.534 2.106-1.602s.486-2.658.486-4.77-.162-3.702-.486-4.77-1.026-1.602-2.106-1.602z"
                        fill="#f5a623"
                    />
                    <path
                        d="m67.688 33.228c1.152 0 2.106-.084 2.862-.252s1.404-.522 1.944-1.062 1.008-1.308 1.404-2.304.81-2.298 1.242-3.906l4.68-18.072h-4.968l-2.52 13.392h-.072l-2.88-13.392h-5.184l5.436 19.368c-.072.792-.306 1.428-.702 1.908s-1.05.72-1.962.72c-.432 0-.864-.036-1.296-.108v3.708z"
                        fill="#f5a623"
                    />
                    <g fill="#4a79e2">
                        <path
                            d="m88.704 27v-15.984h2.88v-3.384h-2.88v-1.692c0-.552.144-.948.432-1.188s.78-.36 1.476-.36h.468c.216 0 .384.012.504.036v-3.528c-.888-.072-1.776-.108-2.664-.108-.792 0-1.512.078-2.16.234s-1.194.414-1.638.774-.786.84-1.026 1.44-.36 1.332-.36 2.196v2.196h-2.52v3.384h2.52v15.984z"
                        />
                        <path
                            d="m100.296 7.128c1.44 0 2.622.234 3.546.702s1.65 1.128 2.178 1.98.888 1.884 1.08 3.096.288 2.562.288 4.05v1.116h-9.828v1.548c0 .576.042 1.134.126 1.674s.222 1.02.414 1.44.444.756.756 1.008.696.378 1.152.378c.84 0 1.452-.3 1.836-.9s.648-1.512.792-2.736h4.464c-.096 2.256-.696 3.972-1.8 5.148s-2.844 1.764-5.22 1.764c-1.8 0-3.204-.3-4.212-.9s-1.752-1.392-2.232-2.376-.774-2.088-.882-3.312-.162-2.448-.162-3.672c0-1.296.09-2.544.27-3.744s.54-2.268 1.08-3.204 1.314-1.68 2.322-2.232 2.352-.828 4.032-.828zm-.18 3.384c-.504 0-.918.114-1.242.342s-.582.528-.774.9-.33.786-.414 1.242-.126.912-.126 1.368v.756h5.076c-.024-.744-.072-1.398-.144-1.962s-.198-1.044-.378-1.44-.432-.696-.756-.9-.738-.306-1.242-.306z"
                        />
                        <path
                            d="m114.948 27v-11.628c0-.648.108-1.194.324-1.638s.504-.804.864-1.08.756-.474 1.188-.594.864-.18 1.296-.18c.264 0 .522.006.774.018s.51.03.774.054v-4.752c-.144-.024-.294-.042-.45-.054s-.306-.018-.45-.018c-1.032 0-1.908.282-2.628.846s-1.332 1.314-1.836 2.25h-.072v-2.592h-4.752v19.368z"
                        />
                        <path
                            d="m126.936 27v-11.628c0-.648.108-1.194.324-1.638s.504-.804.864-1.08.756-.474 1.188-.594.864-.18 1.296-.18c.264 0 .522.006.774.018s.51.03.774.054v-4.752c-.144-.024-.294-.042-.45-.054s-.306-.018-.45-.018c-1.032 0-1.908.282-2.628.846s-1.332 1.314-1.836 2.25h-.072v-2.592h-4.752v19.368z"
                        />
                        <path
                            d="m135.972 33.228c1.152 0 2.106-.084 2.862-.252s1.404-.522 1.944-1.062 1.008-1.308 1.404-2.304.81-2.298 1.242-3.906l4.68-18.072h-4.968l-2.52 13.392h-.072l-2.88-13.392h-5.184l5.436 19.368c-.072.792-.306 1.428-.702 1.908s-1.05.72-1.962.72c-.432 0-.864-.036-1.296-.108v3.708z"
                        />
                    </g>
                    <path
                        d="m154.8 3.228c.608 0 1.19.106 1.746.318s1.046.512 1.47.9.762.858 1.014 1.41.378 1.172.378 1.86c0 .68-.126 1.296-.378 1.848s-.59 1.024-1.014 1.416-.914.692-1.47.9-1.138.312-1.746.312-1.19-.104-1.746-.312-1.046-.508-1.47-.9-.762-.864-1.014-1.416-.378-1.168-.378-1.848c0-.688.126-1.308.378-1.86s.59-1.022 1.014-1.41.914-.688 1.47-.9 1.138-.318 1.746-.318zm0 .96c-.472 0-.914.088-1.326.264s-.77.42-1.074.732-.544.684-.72 1.116-.264.904-.264 1.416.088.984.264 1.416.416.804.72 1.116.662.556 1.074.732.854.264 1.326.264c.464 0 .902-.088 1.314-.264s.77-.42 1.074-.732.546-.684.726-1.116.27-.904.27-1.416-.09-.984-.27-1.416-.422-.804-.726-1.116-.662-.556-1.074-.732-.85-.264-1.314-.264zm.144.816c.28 0 .542.04.786.12s.462.198.654.354.352.35.48.582.212.5.252.804h-.936c-.064-.28-.208-.5-.432-.66s-.492-.24-.804-.24c-.456 0-.804.158-1.044.474s-.36.726-.36 1.23c0 .232.034.454.102.666s.164.396.288.552.274.28.45.372.372.138.588.138c.336 0 .612-.084.828-.252s.344-.396.384-.684h.972c-.048.296-.138.562-.27.798s-.296.436-.492.6-.416.288-.66.372-.498.126-.762.126c-.392 0-.746-.068-1.062-.204s-.586-.324-.81-.564-.398-.522-.522-.846-.186-.674-.186-1.05c0-.384.058-.74.174-1.068s.284-.612.504-.852.488-.428.804-.564.674-.204 1.074-.204z"
                        fill="#7ed321"
                    />
                </g>
            )}
        />

    );
}

export default LogoGetByFerry;
