import React from 'react';
import AutoSuggest from "@Features/autosuggest/components/AutoSuggest";
import FormSuggestion from "@Form/components/FormSuggestion";
import useFormStationInputAutoSuggest from "@Form/hooks/useFormStationInputAutoSuggest";
import Translation from "@Translation/components/Translation";
import IconLocationMarker from "@Icons/IconLocationMarker";
import StationSwitchButton from "@Form/components/StationSwitchButton";
import {useTranslation} from "@Translation/hooks/useTranslation";
import PopularDestinationsNotice from "@Form/components/PopularDestinationsNotice";
import useIsMobile from "@Hooks/selector/useIsMobile";
import FormInputError from "@Form/components/FormInputError";
import StationInputFieldType from "@Form/types/StationInputField.type";

interface FormStationInputFieldProps {
    stationObject: StationInputFieldType,
    direction: "departure" | "destination",
}

function FormStationInputField({stationObject, direction}: FormStationInputFieldProps) {
    const autoSuggestModel = useFormStationInputAutoSuggest(direction, stationObject)
    const isMobile = useIsMobile()
    const isDeparture = direction === 'departure'
    const directionLabel = isDeparture ? 'form.depart_from' : 'form.travel_to'
    const className = isDeparture ? '--from' : '--to'
    const placeholder = useTranslation(isDeparture ? "form.departure_station" : "form.destination_station")

    return (
        <div className={`search__select search__select${className}`}>
            <span className="search__select--label gb--emphasize gb--emphasize-default">
                <Translation translationKey={directionLabel}/>
            </span>
            <IconLocationMarker className="search__select--icon" height="14"/>
            <AutoSuggest
                autoFocus={isMobile}
                autoSuggestModel={autoSuggestModel}
                placeholder={placeholder}
                suggestionComponent={<FormSuggestion/>}
                decorationComponent={(
                    <PopularDestinationsNotice
                        stationLabel={autoSuggestModel?.updateContext?.label}
                    />
                )}
            />
            <FormInputError
                direction={direction}
            />
            {isDeparture && !isMobile && (
                <StationSwitchButton/>
            )}
        </div>
    );
}

export default FormStationInputField;