import React from 'react';
import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconGoogle(props: IconType) {
    const {
        width = '20',
        height = '20',
        fill = '#9b9b9b',
        viewBox = '0 0 20 20',
        title = 'IconGoogle',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fillRule="evenodd" transform="translate(1 1)">
                    <path
                        d="m9 3.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48c-1.56-1.45-3.59-2.34-6.02-2.34-3.52 0-6.56 2.02-8.04 4.96l2.91 2.26c.73-2.17 2.75-3.74 5.13-3.74z"
                        fill="#ea4335"
                    />
                    <path
                        d="m17.64 9.2c0-.74-.06-1.28-.19-1.84h-8.45v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c1.7-1.57 2.68-3.88 2.68-6.62z"
                        fill="#4285f4"
                    />
                    <path
                        d="m3.88 10.78c-.19-.56-.3-1.16-.3-1.78s.11-1.22.29-1.78l-2.91-2.26c-.61 1.22-.96 2.59-.96 4.04s.35 2.82.96 4.04z"
                        fill="#fbbc05"
                    />
                    <path
                        d="m9 18c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74l-2.91 2.26c1.48 2.94 4.51 4.96 8.03 4.96z"
                        fill="#34a853"
                    />
                    <path d="m0 0h18v18h-18z" fill="none"/>
                </g>
            )}
        />

    );
}

export default IconGoogle;