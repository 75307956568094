import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconClockFull(props: IconType) {
    const {
        width = '16',
        height = '16',
        fill = '#f5a623',
        viewBox = '0 0 16 16',
        title = 'IconClockFull',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <path
                    d="m8 0c1.09090909 0 2.1272727.20606061 3.1090909.61818182.9575758.41212121 1.8060606.98787879 2.5454546 1.72727273.7393939.73939393 1.3090909 1.58787878 1.7090909 2.54545454.4242424.98181818.6363636 2.01818182.6363636 3.10909091s-.2060606 2.1272727-.6181818 3.1090909c-.4121212.9575758-.9878788 1.8060606-1.7272727 2.5454546-.739394.7393939-1.5878788 1.3090909-2.5454546 1.7090909-.9818182.4242424-2.01818181.6363636-3.1090909.6363636s-2.12727273-.2060606-3.10909091-.6181818c-.95757576-.4121212-1.80606061-.9878788-2.54545454-1.7272727-.73939394-.739394-1.30909091-1.5878788-1.70909091-2.5454546-.42424243-.9818182-.63636364-2.01818181-.63636364-3.1090909s.20606061-2.12727273.61818182-3.10909091c.41212121-.95757576.98787879-1.80606061 1.72727273-2.54545454.73939393-.73939394 1.58787878-1.30909091 2.54545454-1.70909091.98181818-.42424243 2.01818182-.63636364 3.10909091-.63636364zm-.14285714 2.78571429c-.17857143 0-.33035715.0625-.45535715.1875s-.1875.27678571-.1875.45535714v4.28571428c0 .35714286.125.66071429.375.91071429s.55357143.375.91071429.375h3.3035714c.1785715 0 .3303572-.0625.4553572-.1875s.1875-.27678571.1875-.45535714-.0625-.33035715-.1875-.45535715-.2767857-.1875-.4553572-.1875h-3.3035714v-4.28571428c0-.17857143-.0625-.33035714-.1875-.45535714s-.27678571-.1875-.45535714-.1875z"
                />
            )}
        />

    );
}

export default IconClockFull;
