import Row from "@Containers/components/Row";
import React from "react";
import Translation from "@Translation/components/Translation";

type SeatPickerTitleProps = {
    isReturn: boolean
}

function SeatPickerTitle({isReturn}: SeatPickerTitleProps) {
    return (
        <div className="gb--seat-picker">
            <h4>
                <Translation translationKey={isReturn ? "check_order.seat_picker.title_return_trip" : "check_order.seat_picker.title"}/>
            </h4>
            <Row justify center className="gb--seat-picker-legends">
                <div>
                    <span className="gb--seat-picker-seat gb--seat-picker-seat-available"/>
                    <small className="gb--emphasize-default">
                        <Translation translationKey="check_order.seat_picker.dialog.legends.available"/>
                    </small>
                </div>

                <div>
                    <span className="gb--seat-picker-seat gb--seat-picker-seat-unavailable"/>
                    <small className="gb--emphasize-default">
                        <Translation translationKey="check_order.seat_picker.dialog.legends.unavailable"/>
                    </small>
                </div>

                <div>
                    <span className="gb--seat-picker-seat gb--seat-picker-seat-selected"/>
                    <small className="gb--emphasize-default">
                        <Translation translationKey="check_order.seat_picker.dialog.legends.selected"/>
                    </small>
                </div>
            </Row>
        </div>
    );
}

export default SeatPickerTitle;