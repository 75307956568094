import React from 'react';
import useIsMobile from "@Hooks/selector/useIsMobile";
import useIsTablet from "@Hooks/selector/useIsTablet";
import {useTranslation} from "@Translation/hooks/useTranslation";
import {useToggle} from "@Hooks/dispatch/useToggle";
import GenericPopover from "@Popover/components/GenericPopover";
import ToggleableComponent from "@Generic/ToggleableComponent";
import EuroConversionNotice from "@Currency/components/EuroConversionNotice";
import TotalLabel from "@CheckOrder/features/paymentSummary/features/totalPrice/components/TotalLabel";
import {useAppSelector} from "@Hooks/selector/useAppSelector";

function FeesNotice() {
    const {
        ticket: {
            paymentCurrency,
            bookingFeeRange,
            totalPrice,
        },
    } = useAppSelector((state) => state?.checkOrder)

    const toggle = useToggle()
    const isMobile = useIsMobile()
    const isTablet = useIsTablet()
    const {total: totalLabel} = useTranslation("sys")
    const checkOrderFeesIncluded = useTranslation("check_order.fees_included")
    const theAbovePricesInclude = useTranslation(
        "sys.the_above_prices_include",
        {
            minValue: `${bookingFeeRange.min} ${paymentCurrency}`,
            maxValue: `${bookingFeeRange.max} ${paymentCurrency}`,
            currency: paymentCurrency,
        }
    )

    const payingInDiffCurrency = useTranslation("check_order.note_you_are_paying_in_diff_currency", {
        currency: paymentCurrency,
        price: totalPrice,
    });

    const Popover = () => (
        <small className="check-order__total-disclaimer">
            *
            {' '}
            {theAbovePricesInclude}
            <br/>
            <span
                /* eslint-disable-next-line react/no-danger */
                dangerouslySetInnerHTML={{__html: (`*${payingInDiffCurrency}`)}}
            />
            <EuroConversionNotice price={parseInt(totalPrice, 10)}/>
        </small>
    )

    if (isMobile || isTablet) {
        return (
            <span
                role="button"
                onClick={toggle.onChange}
                className="gb--column gb--note-price"
            >
                <TotalLabel/>
                <ToggleableComponent display={toggle.value}>
                    <hr/>
                    <Popover/>
                </ToggleableComponent>
            </span>
        )
    }

    return (
        <GenericPopover
            trigger={<TotalLabel/>}
            overlay={<Popover/>}
            id="note-price"
        />
    );
}

export default FeesNotice;