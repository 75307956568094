import React from 'react';
import IntermodalTransportType from "@Intermodal/components/IntermodalTransportType";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";
import RouteType from "@Route/types/Route.type";

interface IntermodalTicketLabelProps {
    /**
     *  RouteWithAddedPropsAndPricesType is added here because of Search Results.
     *
     *  RouteType is added here because of Check Order.
     */
    route: RouteWithAddedPropsAndPricesType | RouteType,
    index: number,
    justified?: boolean,
}

const IntermodalTicketLabel = ({route, index, justified}: IntermodalTicketLabelProps) => (
    <IntermodalTransportType
        justified={justified}
        index={index}
        showLabel
        key={route.id}
        routeTransferType={route?.type?.routeTransferType}
    />
);

export default IntermodalTicketLabel;