import React from 'react';
import getRouteTransferType from "@Route/features/transportType/functions/getRouteTransferType";
import IconPlaneTab from "@Icons/IconPlaneTab";
import IconMinivanTab from "@Icons/IconMinivanTab";
import {RouteTransferTypeKeys} from "@Route/features/transportType/types/routeTransferType.type";
import IconBusFull from '@Icons/IconBusFull';
import IconFerryFull from '@Icons/IconFerryFull';
import IconTrainFull from '@Icons/IconTrainFull';
import {useAppSelector} from "@Hooks/selector/useAppSelector";

type VehicleTypeIconProps = {
    className?: string,
    fill?: string,
    width?: string,
    height?: string,
    transportType?: RouteTransferTypeKeys,
}

const VehicleTypeIcon = ({className, fill, width, height, transportType}: VehicleTypeIconProps) => {
    const transportTypeKeyword = useAppSelector((state) => state?.searchResults.transportTypeKeyword)
    const components = {
        bus: IconBusFull,
        ferry: IconFerryFull,
        train: IconTrainFull,
        plane: IconPlaneTab,
        minivan: IconMinivanTab,
        privateMinivan: IconMinivanTab,
        privateBus: IconBusFull,
    };
    const iconToShow = transportType ? getRouteTransferType(transportType) : transportTypeKeyword
    const IconVehicleFull = components[iconToShow || 'bus'];

    return (
        <IconVehicleFull
            width={width ?? 14}
            height={height ?? 14}
            fill={fill ?? "#fff"}
            className={className ?? "connect--vehicle_icon"}
        />
    )
}

export default VehicleTypeIcon;
