import React from 'react';
import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "@/types/Icon.type";

const IconPenWithNotepad = (props: IconType) => {
    const {
        width = '16',
        height = '16',
        fill = '#fff',
        viewBox = '0 0 20 20',
        title = 'IconPenWithNotepad',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...props}
            viewBox={viewBox}
            fill={fill}
            height={height}
            width={width}
            svgIconPath={(
                <g fill="none" fillRule="evenodd" stroke="#070c63" strokeWidth="1.166667" transform="translate(1.5 1)">
                    <path
                        d="m3.71597191 10.644589h-2.80688228c-.50181748 0-.90908963-.4768776-.90908963-1.0644589v-8.5156712c0-.58758131.40727215-1.0644589.90908963-1.0644589h9.99998587c.5018175 0 .9090896.47687759.9090896 1.0644589v3.22037779"
                        strokeLinecap="round"
                    />
                    <path d="m0 2.333333h11.818165"/>
                    <path d="m2.363633 5.25h6.499991" strokeLinecap="round"/>
                    <path d="m2.363633 7.583333h4.136358" strokeLinecap="round"/>
                    <path
                        d="m7.23862615 13.4370195-2.36363303.5629805.56254466-2.3654642 5.39735602-5.49379068c.3498177-.35955056.9265441-.36309876 1.2810891-.00827912l.6204537.62093435c.3545449.35481964.3509995.93199291-.0082727 1.28208161z"
                        strokeLinejoin="round"
                    />
                </g>
            )}
        />
    );
};

export default IconPenWithNotepad;
