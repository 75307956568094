import DepartureStationFilter
    from "@SearchResults/features/sidebar/features/stationFilters/components/DepartureStationFilter";
import DestinationStationFilter
    from "@SearchResults/features/sidebar/features/stationFilters/components/DestinationStationFilter";
import {SearchResultsRtkQueryResponseType} from "@SearchResults/api/types/SearchResultsRtkQueryResponse.type";
import useActiveTabFromParams from "@SearchResults/hooks/useActiveTabFromParams";

type StationFiltersProps = {
    searchData: SearchResultsRtkQueryResponseType
}

function StationFilters({searchData}: StationFiltersProps) {
    const activeTab = useActiveTabFromParams()

    return (
        <>
            <DepartureStationFilter
                activeTab={activeTab}
                searchData={searchData}
            />
            <DestinationStationFilter
                activeTab={activeTab}
                searchData={searchData}
            />
        </>
    );
}

export default StationFilters;