import React from 'react';
import PropTypes from 'prop-types';
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import IconChevron from "@Icons/IconChevronDown";

function CookieTab({label, children}) {
    return (
        <Card>
            <Accordion.Toggle
                className="gb--row gb--row-middle gb--row-justify"
                as={Card.Header}
                eventKey={label}
            >
                <h4>{label}</h4>
                <IconChevron/>
            </Accordion.Toggle>
            <Accordion.Collapse
                className={'gb--accordion-body'}
                eventKey={label}
            >
                {children}
            </Accordion.Collapse>
        </Card>
    );
}

CookieTab.propTypes = {
    label: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};

export default CookieTab;