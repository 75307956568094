import React from 'react';
import MTicketEcoFriendlyLabel from "@Route/components/MTicket/MTicketEcoFriendlyLabel";
import IconMTicketQrShifted from "@Icons/IconMTicketQrShifted";
import IconPaperTicket from "@Icons/IconPaperTicket";
import NotificationExpandable from "../../features/notification/components/NotificationExpandable";
import {useTranslation} from "@Translation/hooks/useTranslation";

interface TicketTypeProps {
    hasMTicket: boolean,
}

const TicketType = ({hasMTicket}: TicketTypeProps) => {
    const {
        ticket_has_to_be_printed: paperTicketDescription,
        has_mticket_desc: mTicketDescription,
    } = useTranslation("search_results")

    if (hasMTicket) {
        return (
            <NotificationExpandable
                icon={<IconMTicketQrShifted/>}
                title={<MTicketEcoFriendlyLabel/>}
                descriptionText={mTicketDescription}
            />
        )
    }
    return (
        <NotificationExpandable
            icon={<IconPaperTicket/>}
            title="Paper ticket"
            descriptionText={paperTicketDescription}
        />
    )
}

export default TicketType;