import React from "react";
import {SeatDeckType} from "@CheckOrder/components/MainSection/SeatPicker/types/SeatDeck.type";
import SeatRow from "@CheckOrder/components/MainSection/SeatPicker/components/SeatRow";
import SeatHeader from "@CheckOrder/components/MainSection/SeatPicker/components/SeatHeader";
import Grid from "@Containers/components/Grid";

type SeatDeckProps = {
    seatDeck: SeatDeckType,
    isReturn: boolean,
}

function SeatDeck({seatDeck, isReturn}: SeatDeckProps) {
    const SeatColumnLength = seatDeck.seatRows[0].seats.length
    const corridorIndex = Math.floor(SeatColumnLength / 2)

    return (
        <div className="gb--seat-picker-seats">
            <SeatHeader
                corridorIndex={corridorIndex}
                seatColumnLength={SeatColumnLength}
            />
            {seatDeck.seatRows.map((seatRow, index) => (
                <SeatRow
                    isReturn={isReturn}
                    corridorIndex={corridorIndex}
                    key={seatRow.key}
                    seatRow={seatRow}
                    rowIndex={index}
                />
            ))}
        </div>
    );
}

export default SeatDeck;