import IntermodalRouteWithDetailsType from "@Intermodal/types/IntermodalRouteWithDetails.type";

export default function generateIntermodalCheckOrderData(route: IntermodalRouteWithDetailsType) {
    const {
        baseRoutes,
    } = route

    const getSubmissionDataForBaseRoutes = () => (
        baseRoutes.map(({travelInfo, id, status}) => ({
            departure_station_id: travelInfo.departureStation.id,
            destination_station_id: travelInfo.destinationStation.id,
            single_route_id: id,
            single_route_date: travelInfo.departureStation.departureDate,
            json_encoded_api_full_integration_data: status?.jsonEncodedApiFullIntegrationData,
        }))
    )

    return {
        intermodalRouteId: route.id,
        baseRoutesData: getSubmissionDataForBaseRoutes(),
    }
}