import useDebouncedEventListener from "@Hooks/eventListeners/useDebouncedEventListener";
import {useDispatch, useSelector} from "react-redux";
import isEqual from "lodash/isEqual"
import {setPageLayout} from "@Reducers/pageSlice";
import {setFormVisibility} from "@Form/reducers/formSlice";

export default function useSetPageLayout() {
    const dispatch = useDispatch()
    const {
        isMobile,
        isTablet,
        isSmallTablet,
    } = useSelector((state) => state?.page)

    const currentState = {
        isMobile,
        isTablet,
        isSmallTablet,
    }

    const checkSizes = () => ({
        isMobile: window.innerWidth <= 680,
        isTablet: window.innerWidth >= 680 && window.innerWidth <= 992,
        isSmallTablet: window.innerWidth >= 680 && window.innerWidth <= 768,
    })

    const updateLayout = () => {
        if (!isEqual(currentState, checkSizes())) {
            dispatch(setPageLayout(checkSizes()))
            dispatch(setFormVisibility(false))
        }
    }

    useDebouncedEventListener("resize", updateLayout)
}