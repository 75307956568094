import {useState} from "react";
import {VehicleAddonResponseType} from "@CheckOrder/api/types/GetAddonData.type";

export default function useJadrolinijaSelectionOptions(vehicles: VehicleAddonResponseType[]) {
    const getSelectOptions = () => (
        vehicles.map((addon) => ({value: addon.id, label: addon.name, activeGroup: addon}))
    )

    const [activeOption, setActiveOption] = useState(getSelectOptions()[0])

    const getVehicleAutoSuggestModels = () => (
        vehicles.find((vehicle) => vehicle.id === activeOption.value).models
    )

    const setVehicleType = (selectedOption: {
        value: number,
        label: string,
        activeGroup: VehicleAddonResponseType
    }) => {
        const result = getSelectOptions().find((option) => option.value === selectedOption.value)
        setActiveOption(result)
    }

    return {
        selectOptions: getSelectOptions(),
        activeOption,
        setVehicleType,
        autoSuggestModels: getVehicleAutoSuggestModels(),
    }
}