import IconFilterWithCount from "@Icons/IconFilterWithCount";
import Row from "@Containers/components/Row";
import React from "react";
import {isTicketsPage} from "@MyProfile/functions/isActivePage";
import {useTranslation} from "@Translation/hooks/useTranslation";

function MyProfileFilterTitle() {
    const {
        filter_tickets: filterTicketsLabel,
        filter_vouchers: filterVouchersLabel,
    } = useTranslation('my_profile.filter')

    return (
        <Row className="gb--sidebar-section-title filter-title">
            <IconFilterWithCount/>
            <h3>
                {isTicketsPage() ? filterTicketsLabel : filterVouchersLabel}
            </h3>
        </Row>
    );
}

export default MyProfileFilterTitle