import React from 'react';
import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "@/types/Icon.type";

interface IconArrowProps extends IconType {
    direction?: "up" | "down" | "left" | "right"
}

function IconArrow(props: IconArrowProps) {
    const {
        width = '20',
        height = '20',
        fill = '#9b9b9b',
        viewBox = '0 0 20 20',
        title = 'IconArrow',
        direction = "right",
        style,
        ...rest
    } = props

    const orientation = {
        [`${direction === "up"}`]: "90deg",
        [`${direction === "down"}`]: "-90deg",
        [`${direction === "right"}`]: "-180deg",
        [`${direction === "left"}`]: "0deg",
    }

    const orientationStyle = {verticalAlign: "middle", transform: `rotate(${orientation.true})`};

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            style={{...style, ...orientationStyle}}
            {...rest}
            svgIconPath={(
                <path
                    d="m20 9.03125v1.96875h-16.15625l5.578125 5.625-1.40625 1.40625-8.015625-8.015625 8.015625-8.015625 1.453125 1.40625-5.625 5.625z"
                />
            )}
        />

    );
}

export default IconArrow;
