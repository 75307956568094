import {useAppSelector} from "@Hooks/selector/useAppSelector";
import useSearchResults from "@SearchResults/hooks/useSearchResults";

export default function useValidCurrenciesList() {
    const searchResults = useSearchResults()
    const {supportedCurrencies} = useAppSelector((state) => state?.page)

    if (searchResults?.data?.result.currencyRates) {
        return searchResults?.data.result.currencyRates.filter((currency) => supportedCurrencies.includes(currency.val))
    }

    return []
}