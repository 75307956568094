import formatDate from "@DateTime/functions/formatDate";
import React, {MouseEvent, TouchEvent} from "react";
import isSlideDisabled from "@SearchResults/features/dateSlick/functions/isSlideDisabled";
import getSlidesClassName from "@SearchResults/features/dateSlick/functions/getSlidesClassName";
import {useAppSelector} from "@Hooks/selector/useAppSelector";

interface DateSlickSlideProps {
    date: Date,
    isReturn: boolean,
    searchedDate: string,
    setClientXonMouseDown: (value: number) => void,
    setClientXonMouseUp: (value: number) => void,
    selectNewSearchDate: (selectedDate: string) => void
}

function DateSlickSlide(props: DateSlickSlideProps) {
    const {
        date,
        isReturn,
        searchedDate,
        setClientXonMouseUp,
        setClientXonMouseDown,
        selectNewSearchDate,
    } = props

    const {date: singleLegDate} = useAppSelector((state) => (state.form.urlParams))

    const isDisabled = isSlideDisabled(date, singleLegDate, searchedDate, isReturn)

    const formattedDate = formatDate(date, undefined, true)

    const onMouseDown = (e: MouseEvent<HTMLDivElement>) => {
        setClientXonMouseDown(e.clientX)
    }

    const onMouseUp = (e: MouseEvent<HTMLDivElement>) => {
        setClientXonMouseUp(e.clientX)
    }

    const onTouch = (e: TouchEvent<HTMLDivElement>) => {
        setClientXonMouseDown(e.touches[0].clientX)
    }

    return (
        <span key={date.toDateString()}>
            <div
                onClick={isDisabled ? null : (e) => {
                    selectNewSearchDate(formattedDate)
                }}
                className={getSlidesClassName(formattedDate, searchedDate, isDisabled)}
                onMouseDown={(e) => onMouseDown(e)}
                onMouseUp={(e) => onMouseUp(e)}
                onTouchStart={(e) => onTouch(e)}
            >
                <div className="datestrip__content">
                    <span className="datestrip__day">
                        {formatDate(formattedDate, 'eeee', true)}
                    </span>
                    <span>
                        {formatDate(formattedDate, 'dd, MMM', true)}
                    </span>
                </div>
            </div>
        </span>
    )
}

export default DateSlickSlide