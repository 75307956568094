import React from 'react';
import Translation from "@Translation/components/Translation";
import LanguageListItem from "./LanguageListItem";
import useLocale from "@Hooks/selector/useLocale";
import useIsDesktop from "@Hooks/selector/useIsDesktop";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {LocaleType} from "@/types/Locale.type";

interface LanguageDropdownProps {
    linkTranslations: string[]
}

function LanguageDropdown({linkTranslations}: LanguageDropdownProps) {
    const locale = useLocale()
    const isDesktop = useIsDesktop()
    const {activeLanguages} = useAppSelector((state) => state?.page)

    return (
        <ul className="select__list select__list--opened select__list--lang">
            {isDesktop && (
                <li className="select__item select__item--title">
                    <span className="gb--emphasize-default">
                        <Translation translationKey="sys.choose_lang"/>
                    </span>
                </li>
            )}
            {Object.keys(activeLanguages).map((lang: LocaleType) => (
                <LanguageListItem
                    key={lang}
                    linkActiveLanguage={linkTranslations[lang]}
                    isActive={lang === locale}
                    locale={lang}
                />
            ))}
        </ul>
    );
}

export default LanguageDropdown;