import React, {useEffect} from 'react';
import useHumanReadableTimer from "@Features/countdown/hooks/useHumanReadableTimer";

interface CountdownProps {
    timeout: number,
    className?: string,
    onTimeout?: (any?) => any,
}

function Countdown({timeout, className = 'gb--tag gb--tag-orange gb--countdown', onTimeout}: CountdownProps) {
    const {
        hours,
        minutes,
        seconds,
        totalTime,
    } = useHumanReadableTimer(timeout)

    const executeMethodOnTimeout = () => {
        if (onTimeout && totalTime === 0) {
            onTimeout()
        }
    }

    useEffect(executeMethodOnTimeout, [totalTime])

    return (
        <div className={className}>
            {`${hours} : ${minutes} : ${seconds}`}
        </div>
    );
}

export default Countdown;