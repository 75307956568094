import React from 'react';
import Column from "@Containers/components/Column";
import Row from "@Containers/components/Row";
import IntermodalTransportTypeIcons from "@Intermodal/components/IntermodalTransportTypeIcons";
import RouteTotalPrice from "@Route/components/Price/RouteTotalPrice";
import RouteActionButton from "@Route/features/ticketPurchase/components/RouteActionButton";
import IntermodalRouteWithDetailsType from "@Intermodal/types/IntermodalRouteWithDetails.type";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";
import {isIntermodalRoute} from "@SearchResults/functions/isIntermodalRoute";
import ParamsType from "@SearchResults/types/params/Params.type";
import {isSuggestedRoute} from "@SearchResults/functions/isSuggestedRoute";

interface MobileSummarySectionProps {
    route: IntermodalRouteWithDetailsType | RouteWithAddedPropsAndPricesType,
    params: ParamsType,
    isReturn: boolean,
}

function MobileSummarySection({route, params, isReturn}: MobileSummarySectionProps) {
    return (
        <Column justify className="mobile-summary-section">
            <Row justify center>
                <RouteTotalPrice
                    price={route.prices.price}
                    passengersClassName="gb--row gb--row-middle total-passengers"
                    displayLabel
                    displayPassengers
                >
                    {(isSuggestedRoute(route) || isIntermodalRoute(route)) && (
                        <IntermodalTransportTypeIcons
                            routeTransferTypeArray={route?.baseRoutes.map((route) => (
                                {
                                    id: route?.id,
                                    transportTypeId: route?.type?.routeTransferType,
                                }
                            ))}
                        />
                    )}
                </RouteTotalPrice>
                {isSuggestedRoute(route) ? (
                    <Column justify>
                        {route.baseRoutes.map((baseRoute) => (
                            <RouteActionButton
                                hideLabels
                                params={params}
                                route={baseRoute}
                                isReturn={isReturn}
                            />
                        ))}
                    </Column>
                ) : (
                    <RouteActionButton
                        params={params}
                        route={route}
                        isReturn={isReturn}
                    />
                )}
            </Row>
        </Column>
    );
}

export default MobileSummarySection;