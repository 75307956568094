import React from "react";
import {useTranslation} from "@Translation/hooks/useTranslation";
import {RouteTransferTypeKeys} from "@Route/features/transportType/types/routeTransferType.type";
import Column from "@Containers/components/Column";
import IntermodalTransportType from "@Intermodal/components/IntermodalTransportType";
import Row from "@Containers/components/Row";
import {PassengerWithGroupType} from "@MyProfile/types/PassengerWithGroup.type";

interface PassengersListProps {
    passengers: PassengerWithGroupType[],
    transportTypeId?: RouteTransferTypeKeys,

}

function PassengersList({passengers, transportTypeId = null}: PassengersListProps) {
    const passengersListLabel = useTranslation('my_profile.my_tickets.passengers_list')

    return (
        <Column alignEnd className="passengers-list">
            <Row className="passengers-list-title gb--emphasize-default">
                {transportTypeId && (
                    <IntermodalTransportType
                        routeTransferType={transportTypeId}
                    />
                )}
                {passengersListLabel}
            </Row>
            <Column alignEnd className="passengers">
                {passengers.map((passenger) => (
                    <div key={passenger.passengerId} className="passenger">
                        {`${passenger.passengerFirstName} ${passenger.passengerLastName} (${passenger.passengerGroup} x ${passenger.price})`}
                    </div>
                ))}
            </Column>
        </Column>
    );
}

export default PassengersList