import {setShowArchivedFilter, setShowValidFilter} from "@MyProfile/reducers/userProfileSlice";
import {useTranslation} from "@Translation/hooks/useTranslation";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";

export default function useFilter() {
    const {valid: validLabel, archived: archivedLabel} = useTranslation('my_profile.filter')
    const {showValid, showArchived} = useAppSelector((state) => state?.userProfile.filter)
    const dispatch = useAppDispatch()

    const setValid = (value: boolean) => {
        dispatch(setShowValidFilter(value))
    }
    const setArchived = (value: boolean) => {
        dispatch(setShowArchivedFilter(value))
    }

    return [
        {label: validLabel, checked: showValid, disableChange: !showArchived, setFilter: setValid},
        {label: archivedLabel, checked: showArchived, disableChange: !showValid, setFilter: setArchived},
    ];
}