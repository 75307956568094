import TicketType from "@MyTicket/types/Ticket.type";

export default function getUpdateTickets(oldTickets: TicketType[]) {
    const updateTickets = ({tickets, paths}: { tickets: TicketType[], paths: any }) => ({
        paths,
        tickets: [...tickets, ...oldTickets],
    })

    return {
        updateTickets,
    }
}