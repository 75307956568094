import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {FilterPopularFiltersType, setPopularFilter} from "@SearchResults/reducers/filterSlice";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";

export default function usePopularFilters(filterKey: keyof FilterPopularFiltersType) {
    const popularFilters: FilterPopularFiltersType = useAppSelector((state) => state.filter.filterPopularFilters)
    const dispatch = useAppDispatch()

    const setActive = () => {
        dispatch(setPopularFilter(filterKey))
    }

    return {
        isActive: popularFilters[filterKey],
        setActive,
    }
}