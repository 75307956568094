import {useEffect} from "react";
import useIsSafari from "@Hooks/selector/useIsSafari";
import {useScroll} from "@Scroll/hooks/useScroll";

export default function useScrollToTopOnSafari() {
    const isSafari = useIsSafari()
    const {scrollToTop} = useScroll({block: "center", behavior: "instant"})

    const scrollToTopOnSafari = () => {
        if (isSafari) {
            setTimeout(scrollToTop, 50)
        }
    }
    useEffect(scrollToTopOnSafari, [])
}