import useDialog from "@Features/dialog/hooks/useDialog";
import SeatSelectionDialog from "@CheckOrder/components/MainSection/SeatPicker/components/SeatSelectionDialog";
import Translation from "@Translation/components/Translation";
import IconSeatbelt from "@Icons/IconLibrary/RouteAmenities/IconSeatbelt";
import IconChevron from "@Icons/IconChevron";
import Row from "@Containers/components/Row";
import {isEmpty} from "@Array/isEmpty";
import React from "react";
import IconPenWithNotepad from "@Icons/IconPenWithNotepad";
import IconCheckMarkCircle from "@Icons/IconCheckMarkCircle";
import {
    SeatPickerDirectionDataType
} from "@CheckOrder/components/MainSection/SeatPicker/types/SeatPickerDirectionData.type";

type SeatPickerProps = {
    seatPickerDirectionData: SeatPickerDirectionDataType
    isReturn?: boolean
}

function SeatPicker({seatPickerDirectionData, isReturn}: SeatPickerProps) {
    const {dialogRef, openModal} = useDialog()

    return (
        <div>
            {dialogRef && (
                <button
                    type="button"
                    onClick={openModal}
                    className="gb--select gb--select-dropdown gb--select-dropdown-btn gb--seat-picker-cta"
                >
                    {!isEmpty(seatPickerDirectionData.confirmedSeatData.selectedSeats) ? (
                        <>
                            <Row center>
                                <IconCheckMarkCircle height={25} width={25} style={{marginRight: "1rem"}}/>
                                <span className="gb--emphasize gb--emphasize-default">
                                {isReturn ? (
                                    <Translation translationKey="check_order.seat_picker.cta_confirmed_return_trip"/>
                                ) : (
                                    <Translation translationKey="check_order.seat_picker.cta_confirmed"/>
                                )}
                                </span>
                            </Row>
                            <IconPenWithNotepad width={25} height={25}/>
                        </>
                    ) : (
                        <>
                            <Row center className="gb--emphasize gb--emphasize-default">
                                <IconSeatbelt height={25} width={25} style={{marginRight: "1rem"}}/>
                                {isReturn ? (
                                    <Translation translationKey="check_order.seat_picker.cta_return_trip"/>
                                ) : (
                                    <Translation translationKey="check_order.seat_picker.cta"/>
                                )}
                            </Row>
                            <IconChevron chevronOrientation="right"/>
                        </>
                    )}
                </button>
            )}
            <SeatSelectionDialog
                isReturn={isReturn}
                dialogRef={dialogRef}
                seatPickerDirectionData={seatPickerDirectionData}
            />
        </div>
    );
}

export default SeatPicker;