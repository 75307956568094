import {NumberAdditionalFieldType} from "@CheckOrder/features/TicketAdditionalInfo/types/NumberAdditionalField.type";

export default function getNumberFieldValidation(field: NumberAdditionalFieldType) {
    const isNumberFieldValid = (value: string) => (
        !!value && parseInt(value, 10) >= field.min && parseInt(value, 10) <= field.max
    )

    return {
        isNumberFieldValid,
    }
}