import {useParams} from "react-router";
import RouteType from "@Route/types/Route.type";
import {SearchResponseType} from "@SearchResults/types/SearchResponse.type";
import {isEmpty} from "@Array/isEmpty";
import IntermodalRouteType from "@Intermodal/types/IntermodalRoute.type";
import useCompanyMainTransportType from "@Hooks/selector/useCompanyMainTransportType";

export default function useFindSelectedRouteFromSearchParams(searchResults: SearchResponseType, isReturn: boolean): RouteType | IntermodalRouteType {
    const defaultTab = useCompanyMainTransportType()
    const params = useParams()
    const tab = isReturn ? params.returnTab : params.tab ?? defaultTab
    const routeId = isReturn ? params.returnRouteId : params.routeId
    const routeArray: RouteType[] = searchResults.data.result[isReturn ? "returnRoutes" : "singleRoutes"]?.[tab]

    const routeIdSplit = routeId?.split('---')
    // First try finding passenger classes as all they have to be evaluated first
    let singleSelectedRoute = null;
    if (routeIdSplit?.length > 1) {
        const routeWithId = routeArray.find((route) => route.id === routeIdSplit[0])
        if (routeWithId && !isEmpty(routeWithId.passengerClasses)) {
            return routeWithId.passengerClasses[parseInt(routeIdSplit[1], 10)]
        }
    }

    // Match routes with provided key
    singleSelectedRoute = routeArray.find((route) => route.key === routeId)

    // If route does not match the key, try the id
    if (!singleSelectedRoute) {
        singleSelectedRoute = routeArray.find((route) => route.id === routeId)
    }

    // If there is still no match try the hash
    if (!singleSelectedRoute) {
        let {hash} = window.location;
        hash = hash.substring(2);

        if (hash.split('/').length > 1) {
            // eslint-disable-next-line prefer-destructuring
            hash = hash.split('/')[1]
        }

        singleSelectedRoute = routeArray.find((route) => route.id === hash)
    }

    // If there is no regular results try the intermodal
    if (!isEmpty(searchResults.data.result.intermodalRoutes) && !singleSelectedRoute) {
        if (searchResults.data.result.intermodalRoutes.find((route) => route.key === routeId)) {
            return searchResults.data.result.intermodalRoutes.find((route) => route.key === routeId)
        }
    }

    // If there is no regular results and no intermodal result try the suggestions
    if (!isEmpty(searchResults.data.result.suggestedRoutes) && !singleSelectedRoute) {
        if (searchResults.data.result.suggestedRoutes.find((route) => route.key === routeId)) {
            return searchResults.data.result.suggestedRoutes.find((route) => route.key === routeId)
        }
    }

    return singleSelectedRoute
}