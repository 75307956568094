import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconStationList(props: IconType) {
    const {
        width = '35',
        height = '35',
        viewBox = '0 0 35 35',
        title = 'IconStationList',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none">
                    <g transform="translate(5 1)">
                        <g transform="translate(0 1.7)">
                            <path
                                d="m1.15855 30.175c-.4046 0-.73355-.3366-.73355-.75225v-28.2455c0-.41565.3298-.75225.73355-.75225h8.65045c-.02295.1428-.034.2839-.034.425 0 1.6405 1.3345 2.975 2.975 2.975s2.975-1.3345 2.975-2.975c0-.1411-.01105-.2822-.034-.425h8.65045c.4046 0 .73355.3366.73355.75225v28.2455c0 .4148-.3298.75225-.73355.75225z"
                                fill="#bbb"
                            />
                            <path
                                d="m24.3406.85c.17085 0 .3094.14705.3094.32725v28.24635c0 .17935-.13855.3264-.3094.3264h-23.1812c-.17085 0-.3094-.14705-.3094-.32725v-28.2455c0-.1802.13855-.32725.3094-.32725h8.1906c0 1.8751 1.5249 3.4 3.4 3.4s3.4-1.5249 3.4-3.4zm0-.85h-9.197c.0952.2669.1564.54995.1564.85 0 1.40845-1.14155 2.55-2.55 2.55s-2.55-1.14155-2.55-2.55c0-.30005.0612-.5831.1564-.85h-9.197c-.64005 0-1.1594.527-1.1594 1.17725v28.24635c0 .6494.51935 1.1764 1.1594 1.1764h23.18205c.6392 0 1.15855-.527 1.15855-1.17725v-28.2455c0-.65025-.51935-1.17725-1.1594-1.17725z"
                                fill="#070c63"
                            />
                        </g>
                        <path d="m3.4 5.1h18.7v23.8h-18.7z" fill="#f5f5f5"/>
                        <g fill="#070c63">
                            <path
                                d="m14.69565 1.7c.19125.43775.24565.9486.08415 1.48495-.20995.69615-.79645 1.25715-1.50365 1.42715-1.40165.33575-2.65115-.7174-2.65115-2.0621 0-.3026.06545-.58905.17935-.85h-4.00435v3.4c0 .93925.76075 1.7 1.7 1.7h8.5c.93925 0 1.7-.76075 1.7-1.7v-3.4z"
                            />
                            <path
                                d="m12.75.85c.93755 0 1.7.76245 1.7 1.7s-.76245 1.7-1.7 1.7-1.7-.76245-1.7-1.7.76245-1.7 1.7-1.7m0-.85c-1.40845 0-2.55 1.14155-2.55 2.55s1.14155 2.55 2.55 2.55 2.55-1.14155 2.55-2.55-1.14155-2.55-2.55-2.55z"
                            />
                        </g>
                    </g>
                    <g transform="translate(12.5 11)">
                        <path
                            d="m5.25 14.2432203c-.31144068 0-.60211864-.1453389-.78898305-.3737288-1.34957627-1.6194915-4.48474576-5.73050845-4.40169492-8.63728811.08305085-2.90677966 2.36694916-5.19067797 5.19067797-5.19067797s5.1076271 2.28389831 5.190678 5.19067797c.0830508 2.90677966-3.03135597 7.01779661-4.38093224 8.63728811-.20762712.2491526-.49830508.3737288-.80974576.3737288z"
                            fill="#ff7676"
                        />
                        <circle cx="5.25" cy="5.232203" fill="#fff" r="2.076271"/>
                    </g>
                </g>
            )}
        />

    );
}

export default IconStationList;
