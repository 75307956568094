import React from "react";
import {isTicketsPage} from "@MyProfile/functions/isActivePage";
import {useTranslation} from "@Translation/hooks/useTranslation";

function MyProfileFilterSubtitle() {
    const {
        ticket_status: ticketStatusLabel,
        voucher_status: voucherStatusLabel,
    } = useTranslation('my_profile.filter')

    return (
        <div className="section-title">
            {isTicketsPage() ? ticketStatusLabel : voucherStatusLabel}
        </div>
    );
}

export default MyProfileFilterSubtitle