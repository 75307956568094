import React from "react";
import CompanyLogo from "@Route/features/company/components/CompanyLogo";
import useIsMobile from "@Hooks/selector/useIsMobile";
import Column from "@Containers/components/Column";
import IconMTicketQrShifted from "@Icons/IconMTicketQrShifted";
import IconPaperTicket from "@Icons/IconPaperTicket";
import IconTimes from "@Icons/IconTimes";

interface TicketCompanyProps {
    companyName: string,
    companyId: string,
    isMTicket: boolean,
    isCancelled: boolean,
    className?: string,
}

function TicketCompany({companyName, companyId, isMTicket, isCancelled, className}: TicketCompanyProps) {
    const isMobile = useIsMobile()

    const getTagColor = () => {
        if (isCancelled) {
            return "red"
        }
        if (isMTicket) {
            return "green"
        }
        return "orange"
    }

    return (
        <Column className={`ticket-company ${className}`} alignStart>
            {isMobile ? (
                <CompanyLogo
                    companyId={companyId}
                    companyName={companyName}
                    className="gb--company-logo"
                />
            ) : (
                <div className="gb--emphasize-default">
                    {companyName}
                </div>
            )}
            <div className={`my-profile-tag gb--tag-${getTagColor()}`}>
                {/* eslint-disable-next-line no-nested-ternary */}
                {isCancelled ? (
                        <>
                            <IconTimes fill="white"/>
                            Cancelled
                        </>
                    )
                    : isMTicket ? (
                        <>
                            <IconMTicketQrShifted fill="white"/>
                            M-ticket
                        </>
                    ) : (
                        <>
                            <IconPaperTicket fill="white"/>
                            Paper ticket
                        </>
                    )}
            </div>
        </Column>
    );
}

export default TicketCompany;