import {To, useLocation} from "react-router";
import {useNavigate} from "react-router-dom";

export default function useNavigateWithStoredState() {
    const {state: oldState} = useLocation()
    const navigate = useNavigate()

    const navigateWithStoredState = (path: To, state?: object) => {
        navigate(path, {
            state: {
                ...oldState,
                ...state,
            },
        })
    }

    return {
        navigateWithStoredState,
    }
}