import React from 'react';
import PriceFilter from "../features/priceFilters/components/PriceFilter";
import DurationFilter from "../features/departureTimeFilters/components/DurationFilter";
import DepartureTimeFilters
    from "@SearchResults/features/sidebar/features/departureTimeFilters/components/DepartureTimeFilters";
import CompanyFilters from "@SearchResults/features/sidebar/features/companyFilters/components/CompanyFilters";
import {SearchResultsRtkQueryResponseType} from "@SearchResults/api/types/SearchResultsRtkQueryResponse.type";
import StationFilters from "@SearchResults/features/sidebar/features/stationFilters/components/StationFilters";
import PopularFilters from "@SearchResults/features/sidebar/features/popularFilters/components/PopularFilters";

type FiltersProps = {
    searchData: SearchResultsRtkQueryResponseType
}

function Filters({searchData}: FiltersProps) {
    return (
        <>
            <PopularFilters
                searchData={searchData}
            />
            <PriceFilter/>
            <DurationFilter/>
            <DepartureTimeFilters/>
            <CompanyFilters
                searchData={searchData}
            />
            <StationFilters
                searchData={searchData}
            />
        </>
    );
}

export default Filters;