import IconDiamond from "@Icons/FilledOutline/IconDiamond";
import React, {ReactNode} from "react";
import IconSMS from "@Icons/FilledOutline/IconSMS";
import IconFlexCancellation from "@Icons/IconFlexCancellation";
import ancillaryTypeId from "@/constants/AncillaryTypeId";

export default function fetchIconForAncillaryId(ancillaryId: number): ReactNode {
    const {
        premiumSupport,
        smsNotification,
        tier1FlexibleCancellation,
    } = ancillaryTypeId

    const allAncillaryIcons = {
        [`${ancillaryId === premiumSupport}`]: <IconDiamond className="ancillary-icon" width="20" height="20"/>,
        [`${ancillaryId === smsNotification}`]: <IconSMS className="ancillary-icon" width="20" height="20"/>,
        [`${ancillaryId === tier1FlexibleCancellation}`]: <IconFlexCancellation className="ancillary-icon" width="20" height="20"/>,
    }
    return allAncillaryIcons.true ?? null;
}