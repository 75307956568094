import React from 'react';
import PriceWithPossibleDiscount from "@Route/components/Price/PriceWithPossibleDiscount";
import PassengerGroupWithPricesAndCountType from "@SearchResults/types/passenger/PassengerGroupWithPricesAndCount.type";

interface ActivePassengerGroupPriceProps {
    group: PassengerGroupWithPricesAndCountType,
    showPrice?: boolean,
}

const ActivePassengerGroupPrice = ({group, showPrice}: ActivePassengerGroupPriceProps) => {
    const {
        numberOfPassengers,
        priceWithDiscount,
        priceWithoutDiscount,
        name,
    } = group

    return (
        <small className="gb--row">
            <span>
                {numberOfPassengers}
            </span>
            <span>
                {showPrice ? `  ${name} x ` : `${name}`}
            </span>
            {showPrice && (
                <PriceWithPossibleDiscount
                    priceWithoutDiscount={priceWithoutDiscount}
                    priceWithDiscount={priceWithDiscount}
                    priceClassName="route--price-currency"
                />
            )}
        </small>
    )
}

export default ActivePassengerGroupPrice;