import {NavLink, NavLinkProps} from "react-router-dom";
import useIsClientsideRoutingSupported from "@Routing/hooks/useIsClientsideRoutingSupported";

function NavigationContextAwareNavLink(props: NavLinkProps & { routeName: string }) {
    const {routeName, ...rest} = props
    const clientRouted = useIsClientsideRoutingSupported(routeName)
    return (
        <NavLink
            reloadDocument={!clientRouted}
            {...rest}
        />
    );
}

export default NavigationContextAwareNavLink;