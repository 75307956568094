import React from 'react';
import useQuantityAwareTranslation from "@Translation/hooks/useQuantityAwareTranslation";
import useTransportTypeAwareTranslation from "@Translation/hooks/useTransportTypeAwareTranslation";

interface TransportTypeChangeLabelProps {
    numberOfChanges: number,
}

function TransportTypeChangeLabel({numberOfChanges}: TransportTypeChangeLabelProps) {
    const quantityAwareTranslation = useQuantityAwareTranslation("sys.bus_change", numberOfChanges)
    const busChangeLabel = useTransportTypeAwareTranslation("sys.bus_change", quantityAwareTranslation)

    return (
        <>
            {busChangeLabel}
        </>
    );
}

export default TransportTypeChangeLabel;