import Row from "@Containers/components/Row";
import Checkbox from "@Generic/Checkbox";
import {StationType} from "@SearchResults/types/station/Station.type";
import useStationFilters from "@SearchResults/features/sidebar/features/stationFilters/hooks/useStationFilters";
import useDispatchFilterEvents from "@SearchResults/features/sidebar/hooks/useDispatchFilterEvent";

type StationProps = {
    station: StationType
    type: 'departure' | 'destination'
}

function Station({station, type}: StationProps) {
    const {
        isActive,
        onPress,
    } = useStationFilters(type)

    const isChecked = isActive(station)

    const handleOnBlur = useDispatchFilterEvents('StationFilter', station.name);

    return (
        <Row center>
            <Checkbox
                isChecked={isChecked}
                onChange={() => onPress(station)}
                onBlur={handleOnBlur}
            />
            <span>
                {station.name}
            </span>
        </Row>
    );
}

export default Station;