import getAirportSlug from "@Form/functions/getAirportSlug";
import filterDuplicates from "@Array/filterDuplicates";

/**
 * Returns sorted suggestions array
 * Sorts by in priority low to high:
 * * included string
 * * brackets included
 * * exact match
 *
 * if match is exact and station has an airport, airport will be the second result
 * @param array {array}
 * @param inputValue {string}
 * @return {*}
 */
export default function sortSuggestions(array: any[], inputValue: string) {
    if (parseInt(window.searchFormVersion ?? '1', 10) >= 2) {
        // don't use client-side sorting for newer (in favour of server-side) logic GBB_SEARCH_FORM_VERSION >= v2
        return array;
    }

    const includes = ({slug}) => slug.includes(inputValue)
    const equals = ({slug}) => slug === inputValue.toLowerCase().replace(" ", "-")
    const hasBrackets = ({label}) => (label ? label.includes('(') : false)

    // Sorted in priorities, last being highest
    const exactMatch = array.sort((a, b) => {
        let comparison = 0;

        // Move results that include input to top
        if (includes(a) && !includes(b)) {
            comparison = -1;
        } else if (!includes(a) && includes(b)) {
            comparison = 1;
        }

        // Move items in brackets to bottom
        if (hasBrackets(a) && !hasBrackets(b)) {
            comparison = 1;
        } else if (!hasBrackets(a) && hasBrackets(b)) {
            comparison = -1;
        }

        // Move exact results to top
        if (equals(a) && !equals(b)) {
            comparison = -1;
        } else if (!equals(a) && equals(b)) {
            comparison = 1;
        }

        return comparison
    })

    const isThereAirport = (itemToMatch) => exactMatch.findIndex(
        (item) => item?.slug.includes(getAirportSlug(itemToMatch?.slug))
        // item => item?.slug.includes(`${itemToMatch?.slug}-airport`)
    )

    const airportIndex = isThereAirport(exactMatch[0])
    if (airportIndex > 1) {
        exactMatch.splice(1, 0, exactMatch[airportIndex]);
    }

    return filterDuplicates(exactMatch, 'value')
}