import React, {useState} from "react";

/**
 * Common hook for input values
 * @param initialValue
 */
export const useInput = (initialValue) => {
    const [value, setValue] = useState(initialValue);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
    };

    const updateValue = (value) => {
        setValue(value);
    }

    const clear = () => setValue('')

    return {
        value,
        onChange: handleChange,
        updateValue,
        clear,
    };
};