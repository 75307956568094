/**
 * Returns icon component name from hash map number
 * @param iconId {*}
 */
export default function routeIconsHashMapResolver(iconId: number): string {
    const iconNames = {
        [`${iconId === 0}`]: "noInfoAvailableOption",
        [`${iconId === 3}`]: "petsAllowed",
        [`${iconId === 4}`]: "drinksAllowed",
        [`${iconId === 5}`]: "foodAllowed",
        [`${iconId === 6}`]: "handLuggage",
        [`${iconId === 7}`]: "airCondition",
        [`${iconId === 8}`]: "wifiAvailable",
        [`${iconId === 9}`]: "powerSocket",
        [`${iconId === 10}`]: "bicycle",
        [`${iconId === 11}`]: "toilet",
        [`${iconId === 12}`]: "coffeeBreak",
        [`${iconId === 13}`]: "seatbelts",
        [`${iconId === 14}`]: "wheelchairAccess",
        [`${iconId === 15}`]: "multimedia",
        [`${iconId === 16}`]: "specialInfo",
        [`${iconId === 17}`]: "borderCrossing",
        [`${iconId === 18}`]: "highWay",
        [`${iconId === 19}`]: "countryRoute",
        [`${iconId === 20}`]: "picturesqueView",
        [`${iconId === 21}`]: "delayInformation",
    }

    return iconNames.true || "noInfoAvailableOption"
}