import React, {ReactElement} from "react";

interface NoResultsNotificationProps {
    noResultsNotification: ReactElement<any>,
}

function NoResultsNotification({noResultsNotification}: NoResultsNotificationProps) {
    return (
        <>
            {noResultsNotification}
        </>
    );
}

export default NoResultsNotification;