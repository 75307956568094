import React from 'react';
import countBy from "@Array/countBy";
import filterDuplicates from "@Array/filterDuplicates";
import OnePassengerType from "@CheckOrder/features/paymentSummary/features/passengersSummary/components/OnePassengerType"
import PassengerType from "@CheckOrder/types/Passenger.type";
import PassengerGroupType from "@SearchResults/types/passenger/PassengerGroup.type";

interface PassengerTypeListProps {
    passengers: PassengerType[]
    passengerGroups: Record<number, PassengerGroupType>
}

interface PassengerWithPriceGroupType extends PassengerType {
    priceGroup: string,
}

function PassengerTypeList({passengers, passengerGroups}: PassengerTypeListProps) {
    // Generate groups based on ID and price as discounts have to be identifiable
    const groupedPassengers: PassengerWithPriceGroupType[] = passengers.map((passenger): PassengerWithPriceGroupType => ({
        ...passenger,
        priceGroup: `${passenger.price}-${passenger.singlePassengerGroupId}-${passenger.returnPassengerGroupId}`,
    }))

    const numberOfPassengersPerGroup = countBy(groupedPassengers, 'priceGroup')
    const filteredPassengers: PassengerWithPriceGroupType[] = filterDuplicates(groupedPassengers, 'priceGroup')

    return (
        <>
            {filteredPassengers.map((passengerWithPriceGroup: PassengerWithPriceGroupType) => {
                const {
                    hasDiscount,
                    priceGroup,
                    singlePassengerGroupId,
                    passengerId,
                    price,
                    returnPassengerGroupId,
                } = passengerWithPriceGroup
                return (
                    <OnePassengerType
                        key={passengerId}
                        price={price}
                        numberOfPassengersPerGroup={numberOfPassengersPerGroup[priceGroup]}
                        hasDiscount={hasDiscount}
                        passengerGroupName={passengerGroups?.[singlePassengerGroupId]?.name}
                        returnPassengerGroupName={returnPassengerGroupId && passengerGroups?.[returnPassengerGroupId]?.name}
                    />
                )
            })}
        </>
    );
}

export default PassengerTypeList;