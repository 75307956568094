import React from 'react';
import useCheckForExistingImage from "@Images/hooks/useCheckForExistingImage";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import getCompanyLogo from "@Route/features/company/functions/getCompanyLogo";

interface CompanyLogoProps {
    companyId: number | string,
    companyName?: string,
    className?: string,
    logoUrl?: string,
}

function CompanyLogo({companyId, className = "gb--company-logo", companyName, logoUrl}: CompanyLogoProps) {
    const adminAssetsBaseUrl = useAppSelector((state) => state?.page.adminAssetsBaseUrl)
    const logo = logoUrl ?? getCompanyLogo(adminAssetsBaseUrl, +companyId)

    if (useCheckForExistingImage(logo)) {
        return (
            <img
                className={className}
                src={logo}
                alt={companyName || "gb--company-logo"}
            />
        );
    }

    return null
}

export default CompanyLogo;