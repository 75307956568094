import {isEmpty} from "@Array/isEmpty";
import {serialize} from "@Object/serialize";
import {RoutesOrganizedByTransportTypeType} from "@SearchResults/types/RoutesOrganizedByTransportType.type";
import filterDuplicates from "@Array/filterDuplicates";
import filterDuplicateApiRoutes from "@SearchResults/api/functions/filterDuplicateApiRoutes";

export default function addApiRoutes(existingApiRoutes: RoutesOrganizedByTransportTypeType, updatedApiRoutes: RoutesOrganizedByTransportTypeType): RoutesOrganizedByTransportTypeType {
    try {
        return Object.entries(updatedApiRoutes).reduce((acc, {0: key, 1: routes}) => {
            if (!isEmpty(routes)) {
                acc[key] = filterDuplicates(filterDuplicateApiRoutes([...acc[key], ...routes]), "id")
            }

            return acc
        }, serialize(existingApiRoutes))
    } catch (e) {
        return existingApiRoutes
    }
}