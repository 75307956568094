import CheckOrderTitle from "@CheckOrder/components/Title/CheckOrderTitle";
import usePurchasedRoute from "@CheckOrder/hooks/usePurchasedRoute";

function CheckOrderTitleContainer() {
    const {singleRoute} = usePurchasedRoute()

    return (
        <CheckOrderTitle
            singleRoute={singleRoute}
        />
    );
}

export default CheckOrderTitleContainer;