import useFilterDisallowedMixedReturnRoutes from "@RoutesList/hooks/useFilterDisallowedMixedReturnRoutes";
import useFilterBySaleBlock from "@Tabs/hooks/useFilterBySaleBlock";
import useFilterOnlyPossibleReturnRoutes from "@RoutesList/hooks/useFilterOnlyPossibleReturnRoutes";
import ParamsType from "@SearchResults/types/params/Params.type";
import {PreparedTabContentDataType} from "@Tabs/types/PreparedTabContentData.type";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";

export default function usePrepareValidReturnRoutes(params: ParamsType, preparedTabData: PreparedTabContentDataType, singleSelectedRoute: RouteWithAddedPropsAndPricesType) {
    const {filterRoutesBasedOnCompanyRules} = useFilterDisallowedMixedReturnRoutes(singleSelectedRoute)

    const {
        pastDepartures,
        upcomingDepartures,
    } = useFilterBySaleBlock(preparedTabData.returnRoutes, params)

    let possibleReturnRoutes = useFilterOnlyPossibleReturnRoutes({
        returnRoutes: upcomingDepartures,
        params,
        singleSelectedRoute,
    })

    possibleReturnRoutes = filterRoutesBasedOnCompanyRules(possibleReturnRoutes)

    return {
        possibleReturnRoutes,
        pastDepartures,
        upcomingDepartures,
    }
}