import React from 'react';
import Translation from "@Translation/components/Translation";
import IconAlert from "@Icons/IconAlert";
import useIsMobile from "@Hooks/selector/useIsMobile";
import useIsTablet from "@Hooks/selector/useIsTablet";
import Column from "@Containers/components/Column";
import VehicleTypeIcon from "@Generic/VehicleTypeIcon";
import useTransportTypeAwareTranslation from "@Translation/hooks/useTransportTypeAwareTranslation";
import IconPaxWithLuggage from "@Icons/FilledOutline/IconPaxWithLuggage";
import IconSlimArrowRight from "@Icons/IconSlimArrowRight";
import useQuantityAwareTranslation from "@Translation/hooks/useQuantityAwareTranslation";
import Row from "@Containers/components/Row";
import {useTranslation} from "@Translation/hooks/useTranslation";
import {RouteTransferTypeKeys} from "@Route/features/transportType/types/routeTransferType.type";
import IntermodalNotificationWrapper from "@Intermodal/components/IntermodalNotificationWrapper";

interface ConnectingRouteNotificationProps {
    isIntermodal?: boolean,
    departureTransportType?: RouteTransferTypeKeys,
    destinationTransportType?: RouteTransferTypeKeys,
    waitingTimeDuration: string,
    activeHub?: string,
}

function ConnectingRouteNotification(props: ConnectingRouteNotificationProps) {
    const {
        waitingTimeDuration,
        activeHub,
        departureTransportType,
        destinationTransportType,
        isIntermodal,
    } = props

    const isMobile = useIsMobile()
    const isTablet = useIsTablet()
    const quantityAwareTranslation = useQuantityAwareTranslation("sys.bus_change", 1)
    const busChangeLabel = useTransportTypeAwareTranslation("sys.bus_change", quantityAwareTranslation)
    const transitLabel = useTranslation('search_results.transit')
    const label = isIntermodal ? transitLabel : busChangeLabel
    const className = `suggested--notice ${isIntermodal ? 'suggested--notice--intermodal' : ''}`

    return (
        <Row className={className} center justify wrap={isIntermodal}>
            <Row>
                <Column className="gb--column-end">
                <span className="gb--emphasize-default">
                    {label}
                </span>
                    <small className="gb--row gb--row-middle">
                        <VehicleTypeIcon
                            transportType={departureTransportType}
                            width="20"
                            height="15"
                            fill="#070c63"
                            className="vehicle-icon"
                        />
                        <IconSlimArrowRight height="5" fill="#070c63"/>
                        <IconPaxWithLuggage width="20" height="24"/>
                        <IconSlimArrowRight height="5" fill="#070c63"/>
                        <VehicleTypeIcon
                            transportType={destinationTransportType}
                            width="20"
                            height="15"
                            fill="#070c63"
                            className="vehicle-icon"
                        />
                    </small>
                </Column>
                <Column className="suggested--notice__changes">
                    <span className="gb--connect-circle"/>
                    <span className="gb--connect-circle"/>
                    <span className="gb--connect-circle"/>
                </Column>
                <Column>
                    <span className="gb--emphasize-default">{activeHub}</span>
                    <small>
                        {waitingTimeDuration}
                        {" "}
                        <Translation
                            translationKey={`search_results.${isIntermodal ? 'transit_period' : 'waiting_period'}`}
                        />
                    </small>
                </Column>
            </Row>
            {isIntermodal ? (
                <IntermodalNotificationWrapper/>
            ) : (
                <span className="gb--label gb--label-red">
                    <IconAlert fill="#f03a47"/>
                    {!(isMobile || isTablet) && (
                        <Translation translationKey="search_results.connection_not_guaranteed"/>
                    )}
                </span>
            )}
        </Row>
    );
}

export default ConnectingRouteNotification