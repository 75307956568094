import React, {useLayoutEffect} from 'react';
import LoaderBus from "@Generic/Loaders/LoaderBus";
import useIsMobile from "@Hooks/selector/useIsMobile";
import RepeatElement from "@Generic/RepeatElement";
import toggleStaticMainContentElements from "@Features/domElements/functions/toggleStaticMainContentElements";

function SearchResultsPlaceholder() {
    const isMobile = useIsMobile()
    useLayoutEffect(() => {
        toggleStaticMainContentElements(false)
        window.scroll(0, 0)
    }, [])

    return (
        <>
            {isMobile && (
                <div className="header-wrapper">
                    <LoaderBus/>
                </div>
            )}
            <div className="gb--row gb--dateslider" style={{margin: "1em"}}>
                <RepeatElement numTimes={10}>
                    <div className="placeholder-results-item" style={{width: "10%"}}>
                        <div className="animated-background">
                            <div className="background-mask content-first-end"/>
                            <div className="background-mask content-second-line"/>
                            <div className="background-mask content-second-end"/>
                            <div className="background-mask content-third-line"/>
                            <div className="background-mask content-third-end"/>
                        </div>
                    </div>
                </RepeatElement>
            </div>
            <div className="gb--tabs gb--row">
                <RepeatElement numTimes={2}>
                    <div className="placeholder-results-item" style={{width: "50%"}}>
                        <div className="animated-background" style={{height: "60px", margin: "1rem"}}/>
                    </div>
                </RepeatElement>
            </div>
            <div className="placeholder-tabs-wrapper">
                <div className="placeholder-results-wrapper">
                    <RepeatElement numTimes={12}>
                        <div className="placeholder-results-item">
                            <div className="animated-background">
                                <div className="background-mask content-first-end"/>
                                <div className="background-mask content-second-line"/>
                                <div className="background-mask content-second-end"/>
                                <div className="background-mask content-third-line"/>
                                <div className="background-mask content-third-end"/>
                            </div>
                        </div>
                    </RepeatElement>
                </div>
            </div>
        </>
    );
}

export default SearchResultsPlaceholder;