import React from 'react';
import {useTranslation} from "@Translation/hooks/useTranslation";
import TermWithCheckbox from "@Generic/TermWithCheckbox";

interface NewsletterProps {
    value: boolean,
    onClick: () => void,
}

function Newsletter({onClick, value}: NewsletterProps) {
    const newsletterLabel = useTranslation("t_summary.gdpr_consent_email_offers")

    return (
        <TermWithCheckbox
            label={newsletterLabel}
            onClick={onClick}
            value={value}
        />
    );
}

export default Newsletter;