import {KeyboardEvent, MutableRefObject} from "react";
import {
    DateInputFieldTypeType
} from "@CheckOrder/features/TicketAdditionalInfo/features/dateAdditionalField/types/DateInputFieldType.type";
import getFieldLengthPerDateInputType
    from "@CheckOrder/features/TicketAdditionalInfo/features/dateAdditionalField/functions/getFieldLengthPerDateInputType";

interface MoveThroughDateInputAttributes {
    currentType: DateInputFieldTypeType,
    currentValue: string,
    event: KeyboardEvent<HTMLInputElement>,
    currentRef: MutableRefObject<HTMLInputElement>,
    previousRef?: MutableRefObject<HTMLInputElement>,
    nextRef?: MutableRefObject<HTMLInputElement>,
}

export default function moveThroughDateInput(params: MoveThroughDateInputAttributes) {
    const {
        currentRef,
        nextRef,
        previousRef,
        currentType,
        currentValue,
        event,
    } = params

    const fieldLength = getFieldLengthPerDateInputType(currentType)

    const {key} = event
    const {
        selectionStart,
    } = currentRef?.current || {}

    // on moving right (forward), set cursor to the position 0
    if (key === "ArrowRight" && (selectionStart === fieldLength || selectionStart === currentValue.length) && !!nextRef) {
        // eslint-disable-next-line no-multi-assign
        nextRef.current.selectionStart = nextRef.current.selectionEnd = 0
        setTimeout(() => (nextRef?.current.focus()), 5)
        return;
    }

    // on moving left(backwards), set cursor to the end position of an input field
    if ((key === "ArrowLeft" || key === "Backspace") && selectionStart === 0 && !!previousRef) {
        // eslint-disable-next-line no-multi-assign
        previousRef.current.selectionStart = previousRef.current.selectionEnd = fieldLength
        setTimeout(() => (previousRef?.current.focus()), 5)
    }
}