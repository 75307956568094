import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconDelayInformation(props: IconType) {
    const {
        width = '35',
        height = '35',
        viewBox = '0 0 35 35',
        title = 'IconDelayInformation',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none" fillRule="evenodd" transform="translate(4 .25)">
                    <path
                        d="m25.4296875 18.8671875h-1.640625v-3.28125h1.640625c.4921875 0 .8203125.328125.8203125.8203125v1.640625c0 .4921875-.328125.8203125-.8203125.8203125z"
                        fill="#070c63"
                        fillRule="nonzero"
                    />
                    <path
                        d="m19.6875 3.28125h-14.765625c0 1.8046875-4.1015625 9.84375-4.1015625 9.84375v8.203125s4.1015625 8.0390625 4.1015625 9.84375h14.765625c0-1.8046875 4.1015625-9.84375 4.1015625-9.84375v-8.203125s-4.1015625-8.0390625-4.1015625-9.84375z"
                        fill="#6a9fff"
                        fillRule="nonzero"
                    />
                    <circle cx="12.304688" cy="17.226563" fill="#f5f5f5" fillRule="nonzero" r="9.84375"/>
                    <path
                        d="m12.3046875 8.203125c5.0039063 0 9.0234375 4.0195312 9.0234375 9.0234375s-4.0195312 9.0234375-9.0234375 9.0234375c-5.00390625 0-9.0234375-4.0195312-9.0234375-9.0234375s4.01953125-9.0234375 9.0234375-9.0234375m0-1.640625c-5.90625 0-10.6640625 4.7578125-10.6640625 10.6640625s4.7578125 10.6640625 10.6640625 10.6640625 10.6640625-4.7578125 10.6640625-10.6640625-4.7578125-10.6640625-10.6640625-10.6640625z"
                        fill="#ddd"
                        fillRule="nonzero"
                    />
                    <path
                        d="m12.3046875 6.14641659v11.08014591l8.0611662-8.07307771c-1.7354393-1.14802141-3.078967-1.87496425-4.0305831-2.18082854s-2.2951438-.58127751-4.0305831-.82623966z"
                        fill="#ff7676"
                    />
                    <g fillRule="nonzero">
                        <path
                            d="m2.4609375 18.046875h-.8203125c-.4921875 0-.8203125-.328125-.8203125-.8203125s.328125-.8203125.8203125-.8203125h.8203125c.4921875 0 .8203125.328125.8203125.8203125s-.328125.8203125-.8203125.8203125z"
                            fill="#4a79e2"
                        />
                        <path
                            d="m12.3046875 8.203125c-.4921875 0-.8203125-.328125-.8203125-.8203125v-.8203125c0-.4921875.328125-.8203125.8203125-.8203125s.8203125.328125.8203125.8203125v.8203125c0 .4921875-.328125.8203125-.8203125.8203125z"
                            fill="#4a79e2"
                        />
                        <path
                            d="m12.3046875 28.7109375c-.4921875 0-.8203125-.328125-.8203125-.8203125v-.8203125c0-.4921875.328125-.8203125.8203125-.8203125s.8203125.328125.8203125.8203125v.8203125c0 .4921875-.328125.8203125-.8203125.8203125z"
                            fill="#4a79e2"
                        />
                        <path
                            d="m22.96875 18.046875h-.8203125c-.4921875 0-.8203125-.328125-.8203125-.8203125s.328125-.8203125.8203125-.8203125h.8203125c.4921875 0 .8203125.328125.8203125.8203125s-.328125.8203125-.8203125.8203125z"
                            fill="#4a79e2"
                        />
                        <path
                            d="m12.3046875 22.1484375c-.4921875 0-.8203125-.328125-.8203125-.8203125v-4.1015625c0-.2460937.0820313-.4101563.2460938-.5742187l4.1015625-4.1015626c.3281249-.3281249.8203125-.3281249 1.1484375 0 .328125.328125.328125.8203126 0 1.1484376l-3.8554688 3.8554687v3.7734375c0 .4921875-.328125.8203125-.8203125.8203125z"
                            fill="#070c63"
                        />
                        <path
                            d="m12.3046875 6.5625c5.90625 0 10.6640625 4.7578125 10.6640625 10.6640625s-4.7578125 10.6640625-10.6640625 10.6640625-10.6640625-4.7578125-10.6640625-10.6640625 4.7578125-10.6640625 10.6640625-10.6640625m0-1.640625c-6.80859375 0-12.3046875 5.4960938-12.3046875 12.3046875s5.49609375 12.3046875 12.3046875 12.3046875c6.8085938 0 12.3046875-5.4960938 12.3046875-12.3046875s-5.4960937-12.3046875-12.3046875-12.3046875z"
                            fill="#070c63"
                        />
                        <path d="m4.921875 0h14.765625v3.28125h-14.765625z" fill="#2c307a"/>
                        <path d="m4.921875 1.640625h4.921875v1.640625h-4.921875z" fill="#4a79e2"/>
                        <path d="m4.921875 0h4.921875v1.640625h-4.921875z" fill="#070c63"/>
                        <path d="m14.765625 1.640625h4.921875v1.640625h-4.921875z" fill="#4a79e2"/>
                        <path d="m14.765625 0h4.921875v1.640625h-4.921875z" fill="#070c63"/>
                        <path d="m4.921875 31.171875h14.765625v3.28125h-14.765625z" fill="#2c307a"/>
                        <path d="m14.765625 31.171875h4.921875v1.640625h-4.921875z" fill="#4a79e2"/>
                        <path d="m14.765625 32.8125h4.921875v1.640625h-4.921875z" fill="#070c63"/>
                        <path d="m4.921875 31.171875h4.921875v1.640625h-4.921875z" fill="#4a79e2"/>
                        <path d="m4.921875 32.8125h4.921875v1.640625h-4.921875z" fill="#070c63"/>
                    </g>
                </g>
            )}
        />

    );
}

export default IconDelayInformation;
