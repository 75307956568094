import React from 'react';
import PropTypes from 'prop-types';
import Accordion from "react-bootstrap/Accordion";
import {useTranslation} from "@Translation/hooks/useTranslation";
import CookieTab from "./CookieTab";
import AnalyticsCookie from "./AnalyticsCookie";
import HotjarCookie from "./HotjarCookie";

function CookieTabs({hotjarCookie, eventCookie}) {
    const {
        tabs: {
            cookie_policy: cookiePolicy,
            required_cookies: requiredCookies,
            content: {
                cookie_policy: cookiePolicyContent,
                required_cookies: requiredCookiesContent,
            },
        },
    } = useTranslation("cookie.preferences")

    const {
        toggle: toggleEventCookie,
        cookieActive: eventCookieActive,
    } = eventCookie

    const {
        toggle: toggleHotjarCookie,
        cookieActive: hotjarCookieActive,
    } = hotjarCookie

    return (
        <div className="gb--cookie-notice">
            <Accordion defaultActiveKey={cookiePolicy}>
                <CookieTab label={cookiePolicy}>
                    <p>{cookiePolicyContent}</p>
                </CookieTab>
                <hr/>
                <CookieTab label={requiredCookies}>
                    <>
                        <p>{requiredCookiesContent}</p>
                        <ul>
                            <li>Session Cookie</li>
                            <li>Route Search Cookie</li>
                            <li>Google Analytics</li>
                        </ul>
                    </>
                </CookieTab>
                <hr/>
                <HotjarCookie
                    toggle={toggleHotjarCookie}
                    cookieActive={hotjarCookieActive}
                />
                <hr/>
                <AnalyticsCookie
                    cookieActive={eventCookieActive}
                    toggle={toggleEventCookie}
                />
            </Accordion>
        </div>
    );
}

CookieTabs.propTypes = {
    eventCookie: PropTypes.object.isRequired,
    hotjarCookie: PropTypes.object.isRequired,
};

export default CookieTabs;