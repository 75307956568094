import IntermodalRouteWithDetailsType from "@Intermodal/types/IntermodalRouteWithDetails.type";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";

/**
 * Sort routes based on availability
 */

type sortRoutesFunction = (
    a: RouteWithAddedPropsAndPricesType | IntermodalRouteWithDetailsType,
    b: RouteWithAddedPropsAndPricesType | IntermodalRouteWithDetailsType
) => number

export function sortByAvailability(): sortRoutesFunction {
    return function sort(a, b) {
        const unavailable = ({status: {status}}) => (
            status === 'unavailable' || status === 'closed'
        )

        let comparison = 0;
        if (unavailable(a) && !unavailable(b)) {
            comparison = 1;
        } else if (!unavailable(a) && unavailable(b)) {
            comparison = -1;
        }

        return comparison
    };
}