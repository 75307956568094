import React from "react";
import TabType from "@Tabs/types/Tab.type";
import Row from "@Containers/components/Row";
import QuantityAwareTranslation from "@Translation/components/QuantityAwareTranslation";
import {NavLink} from "react-router-dom";
import useGenerateSearchUrl from "@SearchResults/hooks/useGenerateSearchUrl";

type OtherMeansOfTransportationProps = {
    tabsToShow: TabType[]
}

function OtherMeansOfTransportation({tabsToShow}: OtherMeansOfTransportationProps) {
    const {generateSearchUrl} = useGenerateSearchUrl()
    return (
        <Row center>
            {tabsToShow.map((transportType) => {
                if (transportType.routes !== 0) {
                    return (
                        <NavLink
                            to={generateSearchUrl({tab: transportType.tabType})}
                            key={transportType.tabType}
                            className="gb--tag gb--tag-default"
                        >
                            {transportType.routes}
                            {' '}
                            <QuantityAwareTranslation
                                quantity={transportType.routes}
                                translationKey={`search_results.${transportType.tabType}_departures`}
                            />
                        </NavLink>
                    )
                }
            })}
        </Row>
    )
}

export default OtherMeansOfTransportation;