import React from 'react';
import Translation from "@Translation/components/Translation";
import RatingType from "@Route/types/ratings/Rating.type";
import CompanyScoreRow from "@Route/features/company/components/CompanyScoreRow";

interface OverallRatingProps {
    companyRating: RatingType,
    rating: string,
}

const OverallRating = ({companyRating, rating}: OverallRatingProps) => (
    <div className="rating-type-section">
        <CompanyScoreRow rating={rating}>
            <Translation translationKey="c_profile.overall_score"/>
        </CompanyScoreRow>
        <span className="grade">
            <Translation
                translationKey="c_profile.based_on"
                translationVariables={{numberOfRatings: companyRating.reviewCount}}
            />
        </span>
    </div>
);

export default OverallRating;