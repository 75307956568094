import React from 'react';
import RouteOperatingDate from "./RouteOperatingDate";

interface RouteStationProps {
    showOperatingDate?: boolean,
    customClassName: string,
    travelTime: string,
    stationName: string,
    date: string,
    routeId: string,
}

function RouteStation(props: RouteStationProps) {
    const {
        showOperatingDate = true,
        customClassName,
        travelTime,
        stationName,
        date,
        routeId,
    } = props;

    return (
        <div className={customClassName}>
            <RouteOperatingDate
                showOperatingDate={showOperatingDate}
                date={date}
                routeId={routeId}
                travelTime={travelTime}
            />
            <span className="gb--truncate">
                {stationName}
            </span>
        </div>
    );
}

export default RouteStation;