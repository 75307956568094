import BookingFee from "@CheckOrder/features/paymentSummary/features/bookingFeeSummary/components/BookingFee";
import {useTranslation} from "@Translation/hooks/useTranslation";

type ExplicitBookingFeeSummaryProps = {
    singleBookingFee: string
    returnBookingFee: string
}

function ExplicitBookingFeeSummary({singleBookingFee, returnBookingFee}: ExplicitBookingFeeSummaryProps) {
    const bookingFeeLabel = useTranslation("sys.booking_fee")
    const returnLabel = `${useTranslation("sys.return")} ${useTranslation("sys.ticket")} ${bookingFeeLabel}`

    return (
        <>
            {parseFloat(singleBookingFee.split(' ')[0]) > 0 && (
                <BookingFee
                    bookingFeeLabel={bookingFeeLabel}
                    bookingFee={singleBookingFee}
                />
            )}
            {parseFloat(returnBookingFee.split(' ')[0]) > 0 && (
                <BookingFee
                    bookingFeeLabel={returnLabel}
                    bookingFee={returnBookingFee}
                />
            )}
        </>
    );
}

export default ExplicitBookingFeeSummary;