import React from 'react';
import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "@/types/Icon.type";

interface IconAvatarProps extends IconType {
    fillUser?: string,
}

const IconAvatar = (props: IconAvatarProps) => {
    const {
        width = '55',
        height = '55',
        viewBox = '0 0 55 55',
        title = 'IconAvatar',
        fillUser = 'transparent',
        ...rest
    } = props
    return (
        <GenericSvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none">
                    <g transform="translate(9.871795 9.871795)">
                        <path
                            d="m35.3034188 36.9487179c-3.4786325-6.0641025-9.965812-10.1538461-17.4401709-10.1538461-7.474359 0-13.9615385 4.0897436-17.44017098 10.1538461 10.01282048 8.6965812 24.86752138 8.6965812 34.88034188 0z"
                            fill={fillUser}
                        />
                        <circle cx="17.863248" cy="10.811966" fill={fillUser} r="9.401709"/>
                        <g fill="#070c63">
                            <path
                                d="m17.8632479 21.6239316c-5.9700855 0-10.81196585-4.8418803-10.81196585-10.8119658 0-5.97008546 4.84188035-10.8119658 10.81196585-10.8119658 5.9700854 0 10.8119658 4.84188034 10.8119658 10.8119658 0 5.9700855-4.8418804 10.8119658-10.8119658 10.8119658zm0-18.80341878c-4.4188035 0-7.99145303 3.57264957-7.99145303 7.99145298 0 4.4188034 3.57264953 7.991453 7.99145303 7.991453 4.4188034 0 7.991453-3.5726496 7.991453-7.991453 0-4.41880341-3.5726496-7.99145298-7.991453-7.99145298z"
                            />
                            <path
                                d="m31.2136752 33.3290598c-.3290598 0-.6581196-.1410256-.9401709-.3760683-3.3846154-3.0555556-7.8034188-4.7478633-12.4102564-4.7478633-3.3376069 0-6.6282052.8931624-9.49572653 2.5854701-.65811966.3760684-1.55128205.1880342-1.92735043-.4700855-.37606838-.6581196-.18803419-1.551282.47008547-1.9273504 3.29059829-1.974359 7.09829059-3.008547 10.95299149-3.008547 5.3119658 0 10.3888889 1.9273504 14.2905983 5.4529914.5641025.5170941.6111111 1.4102564.094017 1.974359-.2350427.3290598-.6581196.517094-1.034188.517094z"
                            />
                        </g>
                    </g>
                    <path
                        d="m40.3803419 11.0470085c-.7051282 0-1.3162393-.517094-1.4102564-1.22222218-.0940171-.75213675 5.8760683-7.52136752 6.6282051-7.56837606.7991453-.04700855 1.457265.56410256 1.5042735 1.31623931l.3290598 5.31196581c.0470086.75213676-.517094 1.41025642-1.2222222 1.50427352l-5.6410256.6581196c-.0470086 0-.1410257 0-.1880342 0z"
                        fill="#7ed321"
                    />
                    <path
                        d="m27.7350427 54.5299145c-7.0042735 0-13.9615384-2.6794871-19.27350424-7.991453-.56410256-.5641025-.56410256-1.4572649 0-1.9743589.56410257-.5641026 1.45726496-.5641026 1.97435894 0 9.5427351 9.542735 25.0555556 9.542735 34.5512821 0 7.1923077-7.1923077 9.1666667-17.8632479 5.0769231-27.1709402-.3290599-.7051282 0-1.5512821.7051282-1.8803419s1.551282 0 1.8803418.7051282c4.5598291 10.3888889 2.3504274 22.2820513-5.6880341 30.2735043-5.2649573 5.3589744-12.2222223 8.0384615-19.2264958 8.0384615z"
                        fill="#4a79e2"
                    />
                    <path
                        d="m3.9957265 38.9700855c-.56410257 0-1.08119659-.3290599-1.31623932-.8461539-4.46581197-10.3418803-2.20940171-22.1880342 5.78205128-30.13247861 10.62393164-10.62393162 27.92307694-10.62393162 38.54700854 0 .5641026.56410257.5641026 1.45726496 0 1.97435898-.5641026.56410253-1.4572649.56410253-1.974359 0-9.542735-9.4957265-25.0555555-9.4957265-34.5982906 0-7.14529911 7.14529913-9.16666663 17.76923073-5.12393159 27.02991453.32905983.7051282 0 1.551282-.75213675 1.8333333-.18803419.0940171-.37606838.1410257-.56410256.1410257z"
                        fill="#7ed321"
                    />
                    <path
                        d="m9.77777778 52.2735043c-.75213675 0-1.36324787-.5641026-1.41025641-1.3162393l-.32905983-5.3119659c-.04700855-.7521367.51709402-1.4102564 1.22222222-1.5042735l5.64102564-.6581196c.7521368-.0940171 1.457265.4700855 1.5512821 1.2222222.094017.7521368-5.8290599 7.5213675-6.58119663 7.5683761-.04700855 0-.09401709 0-.09401709 0z"
                        fill="#4a79e2"
                    />
                </g>
            )}
        />

    );
}

export default IconAvatar;
