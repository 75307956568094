import {SearchResultsRtkQueryResponseType} from "@SearchResults/api/types/SearchResultsRtkQueryResponse.type";
import FilterSection from "@SearchResults/features/sidebar/components/FilterSection";
import getUniqueStationsForActiveTab
    from "@SearchResults/features/sidebar/features/stationFilters/functions/getUniqueStationsForActiveTab";
import Station from "@SearchResults/features/sidebar/features/stationFilters/components/Station";
import {RouteTransferTypeValuesType} from "@Route/features/transportType/types/routeTransferType.type";

type DestinationStationFilterProps = {
    searchData: SearchResultsRtkQueryResponseType
    activeTab: RouteTransferTypeValuesType,
}

function DestinationStationFilter(props: DestinationStationFilterProps) {
    const activeStations = getUniqueStationsForActiveTab({
        searchData: props.searchData,
        tab: props.activeTab,
        stations: "destinationStation",
    })

    if (activeStations.length < 2) {
        return null;
    }

    return (
        <FilterSection
            filterDataKey="filterDestinationStation"
            translationKey="search_results.filter_by.destination_stations.label"
        >
            {activeStations.map((station) => (
                <Station
                    key={station.name}
                    station={station}
                    type="destination"
                />
            ))}
        </FilterSection>
    );
}

export default DestinationStationFilter;