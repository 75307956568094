import UserProfileStateType from "@MyProfile/reducers/types/UserProfileState.type"
import rootExtractor from "@Dataset/rootExtractor";

export const userProfileInitialState: UserProfileStateType = {
    loading: false,
    user: null,
    filter: {
        showValid: true,
        showArchived: false,
    },
    userFetchingFinished: false,
    paths: JSON.parse(rootExtractor('myProfileRoutes')),
    downloadLinks: JSON.parse(rootExtractor('downloadLinks')),
    errors: [],
    loginModalActive: false,
    activeTicketModal: null,
    activeTicketRelatedToVoucher: null,
    activeSplashLoader: false,
    awaitingUserResponse: true,
}