import useLocale from "@Hooks/selector/useLocale";

/**
 * Checks if locale is supported on getbyferry and returns it, returns 'en' if not supported
 * @return {string|*|string}
 */
export default function useGetByFerrySupportedLocale() {
    const supportedLocales = ['en', 'de', 'fr', 'hr', 'it', 'en-gb', 'en-us'];
    const locale = useLocale()
    const isSupported = supportedLocales.includes(locale)

    return isSupported ? locale : 'en'
}