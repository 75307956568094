import React from 'react';
import Column from "@Containers/components/Column";
import RouteIconType from "@SearchResults/types/routeIcons/RouteIcon.type";
import RouteAmenitiesIcon from "@Route/components/Amenities/RouteAmenitiesIcon";

interface RouteAmenitiesListDetailsProps {
    amenities: number[],
    routeIcons: Record<number, RouteIconType>,
}

const RouteAmenitiesListDetails = ({amenities, routeIcons}: RouteAmenitiesListDetailsProps) => (
    <Column className="gb--amenities-list">
        {amenities.map((icon) => (
            <RouteAmenitiesIcon
                displayLabel
                key={icon}
                icon={routeIcons?.[icon]}
            />
        ))}
    </Column>
);

export default RouteAmenitiesListDetails;