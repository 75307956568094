import React from "react"
import Column from "@Containers/components/Column"
import {isNotEmpty} from "@Array/isNotEmpty"
import {useTranslation} from "@Translation/hooks/useTranslation"
import RouteTicketTypeSection from "@Route/components/Sections/RouteTicketTypeSection"
import RouteNotesSection from "@Route/components/Sections/RouteNotesSection"
import RouteNoteType from "@Route/types/notes/RouteNote.type"

interface RouteNotesAndTicketTypeProps {
    notes: RouteNoteType[],
    hasMTicket: boolean,
    displayTicketType?: boolean,
    isCurrentRouteSingle: boolean,
    displayTicketPrintType?: boolean,
}

const RouteNotesAndTicketType = (props: RouteNotesAndTicketTypeProps) => {
    const {
        hasMTicket,
        notes,
        displayTicketType = true,
        isCurrentRouteSingle,
        displayTicketPrintType = true,
    } = props

    const typeLabel = useTranslation(isCurrentRouteSingle ? 'sys.outbound' : 'sys.return')
    const ticketInfoLabel = useTranslation('sys.ticket_info')
    const ticketType = `${typeLabel} ${ticketInfoLabel.toLowerCase()}`

    return (
        <Column className="route-notes-and-ticket-type">
            {displayTicketType && (
                <small className="gb--emphasize-inactive">
                    {ticketType}
                </small>
            )}
            {displayTicketPrintType && (
                <RouteTicketTypeSection
                    hasMTicket={hasMTicket}
                />
            )}
            <hr/>
            {isNotEmpty(notes) && (
                <RouteNotesSection
                    notes={notes}
                />
            )}
        </Column>
    );
}

export default RouteNotesAndTicketType;