import React from 'react';
import CardNumberedList from "@Generic/Cards/CardNumberedList";
import Translation from "@Translation/components/Translation";
import TravelBenefitType from "@Pages/homepage/types/TravelBenefit.type";

function TravelBenefits() {
    const travelBenefitsData: TravelBenefitType[] = [
        {
            cardColor: 'blue',
            headingTranslationKey: 'home_page.cheaper',
            descriptionTranslationKey: 'home_page.it_is_cheaper',
        },
        {
            cardColor: 'orange',
            headingTranslationKey: 'home_page.safer',
            descriptionTranslationKey: 'home_page.modern_long_distance',
        },
        {
            cardColor: 'green',
            headingTranslationKey: 'home_page.greener',
            descriptionTranslationKey: 'home_page.lowest_carbon',
        },
    ]

    return (
        <section className="travel-benefits">
            <h2>
                <Translation translationKey="home_page.why_travel"/>
            </h2>
            <div className="cards__travel-benefits">
                {travelBenefitsData.map((item) => (
                    <CardNumberedList
                        key={item.cardColor}
                        cardColorClass={item.cardColor}
                        cardHeading={<Translation translationKey={item.headingTranslationKey}/>}
                        cardDescription={<Translation translationKey={item.descriptionTranslationKey}/>}
                    />
                ))}
            </div>
        </section>
    );
}

export default TravelBenefits;