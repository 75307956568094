import processGbbSearchResults from "@SearchResults/functions/processGbbSearchResults";
import {flattenAndConvertToArray} from "@Object/flattenAndConvertToArray";
import useAsyncEffect from "@Hooks/promises/useAsyncEffect";
import {SearchResponseType} from "@SearchResults/types/SearchResponse.type";

export default function useProcessGbbSearchResults(searchQuery: SearchResponseType) {
    const {
        isLoading,
        data: {
            result: {
                singleRoutes,
                params: {
                    departureStation,
                    destinationStation,
                },
                totalApiCount,
                resolvedApiCount,
            },
            token: processGbbResultsToken,
        },
    } = searchQuery

    useAsyncEffect(async () => {
        if (!isLoading && resolvedApiCount === totalApiCount) {
            await processGbbSearchResults(
                departureStation.id,
                destinationStation.id,
                flattenAndConvertToArray(singleRoutes),
                processGbbResultsToken
            )
        }
    }, [isLoading, resolvedApiCount])
}