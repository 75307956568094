import React from 'react'
import Row from "@Containers/components/Row";
import Column from "@Containers/components/Column";
import AppStoreLink from "@Sidebar/components/AppStoreNotice/AppStoreLink";
import AppStoreLinksContainer from "@Sidebar/components/AppStoreNotice/AppStoreLinksContainer";
import useIsMobile from "@Hooks/selector/useIsMobile";
import {useTranslation} from "@Translation/hooks/useTranslation";
import SourcedImage from "@Images/components/SourcedImage";
import useIsSmallTablet from "@Hooks/selector/useIsSmallTablet";
import MobileAppQR from "@Pages/mobileApp/components/MobileAppQR";

const DownloadApp = () => {
    const isMobile = useIsMobile()
    const isSmallTablet = useIsSmallTablet()
    const {
        travel_smart: travelSmart,
        hop_on_board: hopOnBoard,
    } = useTranslation("mobile_app")

    return (
        <Row
            justify
            center
            wrap
            gap
            className="gb-container mobile-app-landing-page-section app-download-section"
        >
            <div className="app-download-content">
                <h2>
                    {travelSmart}
                </h2>
                <p>
                    {hopOnBoard}
                </p>
            </div>
            {!(isMobile || isSmallTablet) && (
                <MobileAppQR/>
            )}
            <AppStoreLinksContainer
                direction={(isMobile || isSmallTablet) ? "row" : "column"}
            />
        </Row>
    )
}

export default DownloadApp