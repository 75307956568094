import Row from "@Containers/components/Row";
import useFixedEurConversion from "@Currency/hooks/useFixedEurConversion";
import useExperiments from "@Hooks/selector/useExperiments";
import BookingFeePerPax
    from "@CheckOrder/features/paymentSummary/features/bookingFeeSummary/components/BookingFeePerPax";
import {useAppSelector} from "@Hooks/selector/useAppSelector";

type BookingFeeProps = {
    bookingFeeLabel: string,
    bookingFee: string,
}

function BookingFee({bookingFeeLabel, bookingFee}: BookingFeeProps) {
    const {bookingFeesPerPax} = useExperiments();
    const {passengers} = useAppSelector((state) => state?.checkOrder);
    const {shouldDisplayPriceInKn, priceInKn} = useFixedEurConversion(
        bookingFee,
        true,
        false
    )

    if (bookingFeesPerPax) {
        return (
            <BookingFeePerPax
                paxCount={passengers.length}
                bookingFeeLabel={bookingFeeLabel}
                bookingFee={bookingFee}
            />
        )
    }

    return (
        <Row justify center>
            <span>
                {bookingFeeLabel}
            </span>
            <span>
                {bookingFee}
                {shouldDisplayPriceInKn && ` ${priceInKn}`}
            </span>
        </Row>
    );
}

export default BookingFee;