import LoadDifferentDepartureTimeslots from "@Tabs/components/LoadDifferentDepartureTimeslots";

type LoadLaterDeparturesProps = {}

function LoadLaterDepartures(props: LoadLaterDeparturesProps) {
    return (
        <LoadDifferentDepartureTimeslots
            timeslots="later"
        />
    );
}

export default LoadLaterDepartures;