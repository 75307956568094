import React from 'react';
import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconPercent(props: IconType) {
    const {
        width = '20',
        height = '20',
        fill = '#9b9b9b',
        viewBox = '0 0 20 20',
        title = 'IconPercent',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill={fill} transform="translate(1 1)">
                    <path
                        d="m1.46626687 17.6206897c-.28985508 0-.54347826-.1086957-.76086957-.326087-.43478261-.4347826-.43478261-1.1231884 0-1.5217391l14.4927536-14.49275365c.4347826-.43478261 1.1231884-.43478261 1.5217392 0 .4347826.4347826.4347826 1.1231884 0 1.52173913l-14.49275367 14.49275362c-.2173913.2173913-.47101449.326087-.76086956.326087z"
                    />
                    <path
                        d="m3.54022989 6.98850575c-1.89655173 0-3.44827587-1.55172414-3.44827587-3.44827586 0-1.89655173 1.55172414-3.44827587 3.44827587-3.44827587 1.89655172 0 3.44827586 1.55172414 3.44827586 3.44827587 0 1.89655172-1.55172414 3.44827586-3.44827586 3.44827586zm0-5.1724138c-.94827587 0-1.72413794.77586207-1.72413794 1.72413794 0 .94827586.77586207 1.72413793 1.72413794 1.72413793.94827586 0 1.72413793-.77586207 1.72413793-1.72413793 0-.94827587-.77586207-1.72413794-1.72413793-1.72413794z"
                    />
                    <path
                        d="m14.1724138 17.908046c-1.8965517 0-3.4482759-1.5517242-3.4482759-3.4482759s1.5517242-3.4482758 3.4482759-3.4482758 3.4482759 1.5517241 3.4482759 3.4482758-1.5517242 3.4482759-3.4482759 3.4482759zm0-5.1724138c-.9482759 0-1.7241379.7758621-1.7241379 1.7241379 0 .9482759.775862 1.7241379 1.7241379 1.7241379s1.7241379-.775862 1.7241379-1.7241379c0-.9482758-.775862-1.7241379-1.7241379-1.7241379z"
                    />
                </g>
            )}
        />

    );
}

export default IconPercent;
