import {useEffect} from "react";
import CheckOrderEventModel from "@Events/models/CheckOrderEventModel";
import useSingleTrackingEvent from "@Events/hooks/useSingleTrackingEvent";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {isEmpty} from "@Array/isEmpty";

/**
 * Dispatches event when user lands on check-order
 */
export default function useCheckOrderLandingEvent() {
    const {
        ticket,
        purchasedRoute,
    } = useAppSelector((state) => state?.checkOrder)

    const {
        singleRoute,
        returnRoute,
    } = purchasedRoute || {}

    let model = null
    if (ticket?.ticketCode) {
        if (ticket.isIntermodal || !isEmpty(purchasedRoute.singleRoutes)) {
            model = new CheckOrderEventModel(ticket?.ticketCode, purchasedRoute.singleRoutes[0])
        } else {
            model = new CheckOrderEventModel(ticket?.ticketCode, singleRoute)
        }
        if (returnRoute) {
            const {
                isChange,
                numOfLeftoverAllotment,
                bookingReference,
                transferId,
                departureTime,
            } = new CheckOrderEventModel(ticket?.ticketCode, returnRoute)
            model = {
                ...model,
                retIsChange: isChange,
                retNumOfLeftoverAllotment: numOfLeftoverAllotment,
                retBookingReference: bookingReference,
                retTransferId: transferId,
                retDepartureTime: departureTime,
            }
        }
    }
    const viewPageEvent = useSingleTrackingEvent("view_checkorder_page", model)

    useEffect(() => {
        if (ticket?.ticketCode) {
            viewPageEvent()
        }
    }, [ticket?.ticketCode])
}