import React from 'react';
import OptionalPopover from "@Popover/components/OptionalPopover";
import useRouteRatings from "@Route/hooks/useRouteRatings";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";
import CompanyRating from "@Route/features/company/components/CompanyRating";
import CompanyDetails from "@Route/features/company/components/CompanyDetails";
import useIsSameCompany from "@Route/features/company/hooks/useIsSameCompany";

interface RouteCompanyProps {
    route: RouteWithAddedPropsAndPricesType,
}

function RouteCompany({route}: RouteCompanyProps) {
    const isSameCompany = useIsSameCompany(route)
    const {displayPopover} = useRouteRatings(route)

    return (
        <OptionalPopover
            condition={displayPopover}
            trigger={(
                <CompanyRating
                    displayedValue={route?.status?.isSuggestion ? "letterCode" : "name"}
                    company={route.company}
                    rating={route?.ratings.rating}
                    highlight={isSameCompany}
                    customMainCompanyName={route.customMainCompanyName}
                />
            )}
            overlay={(
                <CompanyDetails
                    company={route.company}
                    route={route}
                />
            )}
            id="route-suggestion-ratings-popover"
        />
    );
}

export default RouteCompany;