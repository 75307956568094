import React from 'react';
import RepeatElement from "@Generic/RepeatElement";

/**
 * Sidebar widget loader used when data fetched async from API.
 */

interface SidebarSectionLoaderProps {
    /** expected row count to render as many loaders */
    expectedDataCount: number,
}

const SidebarSectionLoader = ({expectedDataCount}: SidebarSectionLoaderProps) => (
    <ul>
        <RepeatElement numTimes={expectedDataCount}>
            <li>
                <div className="animated-background">
                    <div className="background-mask content-first-end"/>
                    <div className="background-mask content-second-line"/>
                    <div className="background-mask content-second-end"/>
                    <div className="background-mask content-third-line"/>
                    <div className="background-mask content-third-end"/>
                </div>
            </li>
        </RepeatElement>
    </ul>
);

export default SidebarSectionLoader;