import {
    JadrolinijaVehicleModelType
} from "@CheckOrder/features/addons/features/jadrolinija/types/JadrolinijaVehicleModel.type";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {useLazySetAddonQuery} from "@CheckOrder/api/checkOrderApi";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import {
    setCheckOrderData,
    setIsBuyingPremiumSupport, setIsBuyingSMSNotification,
    setPassengerDataUpdating
} from "@CheckOrder/reducers/checkOrderSlice";
import {useState} from "react";
import {DimensionOption, VehicleAddonResponseType} from "@CheckOrder/api/types/GetAddonData.type";
import useJadrolinijaSelectionOptions
    from "@CheckOrder/features/addons/features/jadrolinija/hooks/useJadrolinijaSelectionOptions";
import {useToggle} from "@Hooks/dispatch/useToggle";
import useUpdatePassengers from "@CheckOrder/hooks/useUpdatePassengers";
import useActivatePopup from "@Popups/hooks/useActivatePopup"

export default function useHandleJadrolinijaVehicleSelection(vehicles: VehicleAddonResponseType[]) {
    const {
        selectOptions,
        activeOption,
        setVehicleType,
        autoSuggestModels,
    } = useJadrolinijaSelectionOptions(vehicles)

    const {activatePopup} = useActivatePopup()
    const {ticket} = useAppSelector((state) => state.checkOrder)
    const {ticketCode} = ticket
    const {updateAllPassengersAndVerifySubmission} = useUpdatePassengers()
    const dispatch = useAppDispatch()
    const [selectedVehicle, setVehicle] = useState<JadrolinijaVehicleModelType>(undefined)

    const [registrationNumber, setRegistrationNumber] = useState("")
    const [additionalHeight, setHeight] = useState<DimensionOption>(null)
    const [additionalLength, setLength] = useState<DimensionOption>(null)

    const {
        onChange: toggleFlagRental,
        value: isRental,
    } = useToggle(false)

    const [showErrors, setShowErrors] = useState(false)
    const [query] = useLazySetAddonQuery()

    const toggleRental = () => {
        setRegistrationNumber(!isRental ? 'rental' : '')
        toggleFlagRental()
    }

    const updateTicketWithAddon = () => {
        const registrationValid = activeOption.activeGroup.registrationNumberMandatory ? registrationNumber : true
        const selectedVehicleValid = activeOption.activeGroup.models ? selectedVehicle : true
        if (registrationValid && selectedVehicleValid) {
            dispatch(setPassengerDataUpdating(true))
            updateAllPassengersAndVerifySubmission().then((response) => {
                if (response) {
                    query({
                        ticketCode,
                        selectedVehicle,
                        registrationNumber,
                        additionalLength: additionalLength?.value ?? 0,
                        additionalHeight: additionalHeight?.value ?? 0,
                        passengerGroup: activeOption.activeGroup.id,
                    }).then((response) => {
                        if (response.isError) {
                            dispatch(setPassengerDataUpdating(false))
                            activatePopup({
                                title: 'Error',
                                // @ts-ignore
                                message: response.error?.data?.data?.userMessage,
                            })
                        } else {
                            dispatch(setCheckOrderData(response.data.checkOrder))
                            dispatch(setPassengerDataUpdating(false))
                            dispatch(setIsBuyingPremiumSupport(false))
                            dispatch(setIsBuyingSMSNotification(false))
                        }
                    })
                } else {
                    dispatch(setPassengerDataUpdating(false))
                }
            })
        } else {
            setShowErrors(true)
        }
    }

    return {
        updateTicketWithAddon,
        setRegistrationNumber,
        registrationNumber,
        showErrors,
        selectOptions,
        activeOption,
        setVehicleType,
        selectedVehicle,
        setVehicle,
        autoSuggestModels,
        additionalHeight,
        setHeight,
        additionalLength,
        setLength,
        isRental,
        toggleRental,
    }
}