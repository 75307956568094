import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import addOrRemoveObjectToArray from "@Array/addOrRemoveObjectToArray";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {SeatType} from "@CheckOrder/components/MainSection/SeatPicker/types/Seat.type";
import {updateSeatPickerSeats} from "@CheckOrder/reducers/checkOrderSlice";

export default function useUpdateSeatPickerSeats(seat: SeatType, isReturn: boolean) {
    const {
        ticket: {
            seatPickerData: {
                singleSeatPicker: {
                    selectedSeatData: selectedSingleSeats,
                },
                returnSeatPicker: {
                    selectedSeatData: selectedReturnSeats,
                },
            },
        },
        passengers,
    } = useAppSelector((state) => state.checkOrder)
    const dispatch = useAppDispatch()
    const selectedSeats = isReturn ? selectedReturnSeats : selectedSingleSeats

    const getUpdatedArray = (): SeatType[] => (addOrRemoveObjectToArray(selectedSeats, seat, 'seatNumber'))

    const updateSeat = () => {
        dispatch(updateSeatPickerSeats({
            selectedSeats: getUpdatedArray(),
            isReturn,
        }))
    }

    const onSeatClick = () => {
        if (!seat.seatNumber) {
            return
        }

        if (selectedSeats.length === passengers.length) {
            // Allow update if the seat is already selected
            if (selectedSeats.some((s) => s.seatNumber === seat.seatNumber)) {
                updateSeat()
            }

            return
        }

        updateSeat()
    }

    const isActive = () => selectedSeats.some((s) => s.seatNumber === seat.seatNumber)

    return {
        onSeatClick,
        isActive,
    }
}