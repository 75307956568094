import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {filterBounds, FilterBoundsState} from "@SearchResults/features/sidebar/constants/filterBounds";
import {DepartureTimePayloadType} from "@SearchResults/features/sidebar/features/departureTimeFilters/types/DepartureTimePayload.type";
import CompanyType from "@SearchResults/types/company/Company.type";
import {CompanyScoreType} from "@SearchResults/features/sidebar/features/companyFilters/types/CompanyScore.type";
import {StationType} from "@SearchResults/types/station/Station.type";

export type ArrayFilterState = {
    filterCompanies: CompanyType[],
    filterCompanyScores: CompanyScoreType[],
    filterDepartureStation: StationType[],
    filterDestinationStation: StationType[],
}

export type FilterPopularFiltersType = {
    instantConfirmation: boolean,
    mTicket: boolean,
    webDiscount: boolean,
}

export type FilterDepartureTimeSlotsType = {
    morning: boolean,
    afternoon: boolean,
    evening: boolean,
    night: boolean,
}

type FiltersState = ArrayFilterState & {
    filterDuration: number,
    filterRouteDepartureTime: number,
    filterPrice: number,
    filterRating: number,
    filterRouteArrivalTime: number,
    filterRouteChange: any,
    filterDepartureTimeSlots: FilterDepartureTimeSlotsType,
    filterPopularFilters: FilterPopularFiltersType,
}

const filters: FiltersState = {
    filterDuration: null,
    filterRouteDepartureTime: null,
    filterPrice: null,
    filterRating: null,
    filterRouteArrivalTime: null,
    filterRouteChange: null,
    filterDepartureTimeSlots: {
        morning: false,
        afternoon: false,
        evening: false,
        night: false,
    },
    filterCompanies: [],
    filterCompanyScores: [],
    filterDepartureStation: [],
    filterDestinationStation: [],
    filterPopularFilters: {
        instantConfirmation: false,
        mTicket: false,
        webDiscount: false,
    },
}

type SortByType = 'departure' | 'rating' | 'travel_time' | 'price'

type FiltersSliceState = FiltersState & {
    sortBy: SortByType,
    filterDiscounts: any[],
    filterBounds: FilterBoundsState,
    filteredRoutesCount: number,
    totalRoutesCount: number,
    maxRoutePrice: number,
}

const initialState: FiltersSliceState = {
    ...filters,
    sortBy: 'departure',
    filterDiscounts: [],
    filterBounds,
    filteredRoutesCount: 0,
    totalRoutesCount: 0,
    maxRoutePrice: 0,
}

const filterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        setSortBy: (state, action: PayloadAction<SortByType>) => (
            {...state, sortBy: action.payload}
        ),

        addSortingOptions: (state, action) => (
            {...state, sort: action.payload}
        ),

        setFilterPrice: (state, action) => (
            {...state, filterPrice: action.payload}
        ),

        setFilterDuration: (state, action) => (
            {...state, filterDuration: action.payload}
        ),

        setFilterArriveBefore: (state, action) => (
            {...state, filterRouteArrivalTime: action.payload}
        ),

        setFilterDepartAfter: (state, action) => (
            {...state, filterRouteDepartureTime: action.payload}
        ),

        setFilterDiscounts: (state, action) => (
            {...state, filterDiscounts: action.payload}
        ),

        setFilterRating: (state, action) => (
            {...state, filterRating: action.payload}
        ),

        setFilterRouteChange: (state, action) => (
            {...state, filterRouteChange: action.payload}
        ),

        setFilterBoundaries: (state, action) => (
            {...state, filterBounds: action.payload}
        ),

        setTotalRoutesCount: (state, action: PayloadAction<number>) => ({
            ...state,
            totalRoutesCount: action.payload,
        }),

        setFilteredRoutesCount: (state, action: PayloadAction<number>) => ({
            ...state,
            filteredRoutesCount: action.payload,
        }),

        clearAllFilters: (state) => ({
            ...state,
            ...filters,
        }),

        setMaxRoutePrice: (state, action) => (
            {
                ...state,
                maxRoutePrice: action.payload,
            }
        ),

        setDepartureTimeFilter: (state: FiltersSliceState, action: DepartureTimePayloadType) => {
            state.filterDepartureTimeSlots[action.payload.departureTime] = action.payload.isActive
        },

        setCompanyFilter(state: FiltersSliceState, action: PayloadAction<CompanyType[]>) {
            state.filterCompanies = action.payload
        },

        setCompanyScoresFilter(state: FiltersSliceState, action: PayloadAction<CompanyScoreType[]>) {
            state.filterCompanyScores = action.payload
        },

        setDepartureStationFilter(state: FiltersSliceState, action: PayloadAction<StationType[]>) {
            state.filterDepartureStation = action.payload
        },

        setDestinationStationFilter(state: FiltersSliceState, action: PayloadAction<StationType[]>) {
            state.filterDestinationStation = action.payload
        },

        setPopularFilter(state: FiltersSliceState, action: PayloadAction<keyof FilterPopularFiltersType>) {
            state.filterPopularFilters[action.payload] = !state.filterPopularFilters[action.payload]
        },

        clearArrayFilter(state: FiltersSliceState, action: PayloadAction<keyof ArrayFilterState>) {
            state[action.payload] = []
        },

        clear: () => initialState,
    },
})

export const {
    setSortBy,
    addSortingOptions,
    setFilterPrice,
    setFilterDuration,
    setFilterArriveBefore,
    setFilterDepartAfter,
    setFilterDiscounts,
    setFilterRating,
    setFilterRouteChange,
    setFilterBoundaries,
    setTotalRoutesCount,
    setFilteredRoutesCount,
    setMaxRoutePrice,
    setDepartureTimeFilter,
    setCompanyFilter,
    setCompanyScoresFilter,
    setDestinationStationFilter,
    setDepartureStationFilter,
    setPopularFilter,
    clearArrayFilter,
    clearAllFilters,
} = filterSlice.actions

export default filterSlice.reducer