import {useAppSelector} from "@Hooks/selector/useAppSelector"

// get additional passenger field by a field name, for example, "nationality"
export default function useGetAdditionalPassengerFieldByName() {
    const {
        additionalInfoFields,
    } = useAppSelector((state) => state?.checkOrder?.ticket)

    const getAdditionalPassengerFieldByName = (fieldName: string) => {
        const allFields = additionalInfoFields.singleAdditionalFields.additionalPassengerFields.concat(additionalInfoFields.returnAdditionalFields.additionalPassengerFields)
        const allTicketFields = additionalInfoFields.singleAdditionalFields.additionalTicketFields.concat(additionalInfoFields.returnAdditionalFields.additionalTicketFields)
        return allTicketFields.concat(allFields).find((field) => field.key === fieldName)
    }

    return {
        getAdditionalPassengerFieldByName,
    }
}