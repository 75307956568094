import SearchResultsResponseType from "@SearchResults/api/types/SearchResultsResponse.type";
import SearchResultsRequestType from "@SearchResults/api/types/SearchResultsRequest.type";
import updateSearchResults from "@SearchResults/api/functions/updateSearchResults";
import {ThunkDispatch} from "@reduxjs/toolkit";

export default function updateResolvedApiRouteCount(
    dispatch: ThunkDispatch<any, any, any>,
    searchResultsApi: any,
    searchParams: SearchResultsRequestType,
    isReturn: boolean
) {
    const singleUpdateRecipe = (data: SearchResultsResponseType) => ({
        ...data,
        result: {
            ...data.result,
            resolvedApiCount: (data.result?.resolvedApiCount ?? 0) + 1,
            failedApiCount: (data.result?.resolvedApiCount ?? 0) + 1,
        },
    })

    const returnUpdateRecipe = (data: SearchResultsResponseType) => ({
        ...data,
        result: {
            ...data.result,
            resolvedReturnApiCount: (data.result?.resolvedApiCount ?? 0) + 1,
            failedReturnApiCount: (data.result?.resolvedApiCount ?? 0) + 1,
        },
    })

    // @ts-ignore
    updateSearchResults(dispatch, searchResultsApi, searchParams, isReturn ? returnUpdateRecipe : singleUpdateRecipe)
}