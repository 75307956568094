import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconGooglePlaySymbol(props: IconType) {
    const {
        width = '16',
        height = '16',
        viewBox = '0 0 16 16',
        title = 'IconGooglePlaySymbol',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none">
                    <path
                        d="m0 0h16v16h-16z"
                    />
                    <path
                        d="m1.107.246c-.181.192-.288.489-.288.874v13.757c0 .386.107.683.288.874l.046.045 7.706-7.707v-.181z"
                        fill="#00d7ff"
                    />
                    <path
                        d="m11.427 10.662-2.568-2.57v-.182l2.569-2.569.058.033 3.044 1.729c.869.494.869 1.302 0 1.797z"
                        fill="#ffd300"
                    />
                    <path
                        d="m11.486 10.627-2.628-2.627-7.752 7.753c.287.303.76.341 1.293.038z"
                        fill="#f1304b"
                    />
                    <path
                        d="m11.486 5.372-9.087-5.163c-.533-.303-1.006-.265-1.293.039l7.753 7.752z"
                        fill="#00f076"
                    />
                </g>
            )}
        />

    );
}

export default IconGooglePlaySymbol;
