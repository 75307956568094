import React from 'react';
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import Row from "@Containers/components/Row";
import MoreInfoButton from "@MyProfile/components/MoreInfoButton";
import {useTranslation} from "@Translation/hooks/useTranslation";
import VoucherType from "@MyVoucher/types/Voucher.type";
import VoucherTypeInfo from "@MyVoucher/components/VoucherTypeInfo";
import VoucherCodeInfo from "@MyVoucher/components/VoucherCodeInfo";
import VoucherIssuerInfo from "@MyVoucher/components/VoucherIssuerInfo";
import VoucherStatusInfo from "@MyVoucher/components/VoucherStatusInfo";
import VoucherPriceInfo from "@MyVoucher/components/VoucherPriceInfo";
import VoucherDetails from "@MyVoucher/features/voucherDetails/components/VoucherDetails";
import useHandleVoucherCardDisplay from "@MyVoucher/hooks/useHandleVoucherCardDisplay";

interface VoucherProps {
    voucher: VoucherType,
}

function Voucher({voucher}: VoucherProps) {
    const {
        code,
        amount,
        status,
        type,
        createdAt,
        cancelledTicket,
        restrictions,
    } = voucher

    const detailsLabel = useTranslation('my_profile.my_vouchers.details')
    const {
        accordionOpen,
        cardClassName,
        applyCardOpenedClassName,
        applyCardClosedClassName,
    } = useHandleVoucherCardDisplay()

    return (
        <Card className={`panel gb--card ${cardClassName}`}>
            <Accordion.Toggle
                as={Card.Header}
                eventKey={voucher.id}
                className="gb--route gb--route-search panel-heading"
            >
                <Row justify className="gb--route voucher">
                    <VoucherTypeInfo type={type}/>
                    <VoucherCodeInfo
                        code={code}
                        status={status}
                    />
                    <VoucherIssuerInfo
                        issuedOn={createdAt}
                        type={type}
                        ticketCode={cancelledTicket}
                    />
                    <VoucherStatusInfo
                        companyList={restrictions.companyStores}
                        isOpened={accordionOpen}
                    />
                    <VoucherPriceInfo
                        price={amount}
                        status={status}
                    />
                    <MoreInfoButton buttonTitle={detailsLabel}/>
                </Row>
            </Accordion.Toggle>
            <Accordion.Collapse
                eventKey={voucher.id}
                onEnter={applyCardOpenedClassName}
                onExit={applyCardClosedClassName}
            >
                <VoucherDetails voucher={voucher}/>
            </Accordion.Collapse>
        </Card>
    );
}

export default Voucher;