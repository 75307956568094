import React from "react";
import Notification from "@Features/notification/components/Notification";
import IconShieldWithLock from "@Icons/IconShieldWithLock";
import {useTranslation} from "@Translation/hooks/useTranslation";
import Column from "@Containers/components/Column";

function CheckOrderPaymentProviderNotification() {
    const {
        secure_card: secureCard,
        faster_and_safer: saferPayment,
    } = useTranslation('check_order.stripe')
    return (
        <Notification
            notificationType="blue"
            notificationIcon={<IconShieldWithLock width="36" height="36"/>}
            isFullWidth
            isRow
        >
            <Column>
                <span>{secureCard}</span>
                {saferPayment}
            </Column>
        </Notification>
    )
}

export default CheckOrderPaymentProviderNotification;