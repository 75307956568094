import React from 'react';
import {useTranslation} from "@Translation/hooks/useTranslation";
import PassengerInputField from "@CheckOrder/components/MainSection/PassengersInfo/PassengerInputField";

interface SurnameProps {
    value: string,
    userConfirmed: boolean,
    index: number,
}

function Surname({value, userConfirmed, index}: SurnameProps) {
    const label = useTranslation("check_order.last_name")

    return (
        <PassengerInputField
            name="passengerLastName"
            labelText={label}
            value={value}
            showErrorText={!value && userConfirmed}
            index={index}
        />
    );
}

export default Surname;