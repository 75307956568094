import React, {useState} from 'react';
import {useTranslation} from "@Translation/hooks/useTranslation";
import useMultipleGroupSelects
    from "@CheckOrder/features/passengerInfo/features/priceSelect/hooks/useMultipleGroupSelects";
import PassengerType from "@CheckOrder/types/Passenger.type";
import PassengerGroupType from "@SearchResults/types/passenger/PassengerGroup.type";
import CheckOrderInputContainer from "@CheckOrder/components/MainSection/CheckOrderInputContainer";
import usePassengerGroupChanged
    from "@CheckOrder/features/passengerInfo/features/priceSelect/hooks/usePassengerGroupChanged";

interface PriceSelectDropdownProps {
    passenger: PassengerType,
    tripType: 'single' | 'return',
    disabled: boolean,
    index: number,
    passengerGroups: Record<number, PassengerGroupType>,
    hasDuplicateGroups: boolean,
}

function PriceSelectDropdown(props: PriceSelectDropdownProps) {
    const {
        disabled,
        tripType,
        passenger,
        index,
        passengerGroups,
        hasDuplicateGroups,
    } = props;

    const {
        return_ticket_type: returnTicketType,
        outbound_ticket_type: outboundTicketType,
    } = useTranslation("check_order")

    const [passengerGroup, setPassengerGroup] = useState(tripType === "return"
        ? passenger.returnPassengerGroupId
        : passenger.singlePassengerGroupId)

    // Yes this is very confusing
    // Yes the design for this should not have trumped the entire logical process
    const multipleGroupSelects = useMultipleGroupSelects()
    const updateBothTripSegments = hasDuplicateGroups ? false : !multipleGroupSelects
    // const returnLabel = !updateBothTripSegments ? returnTicketType : outboundTicketType
    // const label = tripType === "return" ? returnLabel : outboundTicketType;
    const label = tripType === "return" ? returnTicketType : outboundTicketType;

    const onChange = ({target: {value}}) => {
        if (value !== passengerGroup) {
            setPassengerGroup(value)
        }
    }

    usePassengerGroupChanged({passenger, passengerGroup, tripType, updateBothTripSegments, index})

    return (
        <CheckOrderInputContainer label={label}>
            <select
                value={passengerGroup}
                disabled={disabled}
                onChange={onChange}
            >
                {Object.values(passengerGroups).filter((group) => !group.isVehicle && group).map((group) => (
                    <option
                        key={group.id}
                        value={group.id}
                    >
                        {group.name}
                    </option>
                ))}
            </select>
        </CheckOrderInputContainer>
    );
}

export default PriceSelectDropdown