import {useTranslation} from "@Translation/hooks/useTranslation";
import React from "react";
import PhoneCountryType from "@CheckOrder/types/PhoneCountry.type";
import ClickOutsideAwareComponent from "@Features/clickOutsideAwareComponent/components/ClickOutsideAwareComponent";
import getHandlePhoneInput from "@Features/input/phoneField/functions/getHandlePhoneInput";
import SelectedCountryIcon from "@Features/countrySelect/components/SelectedCountryIcon";
import CountrySelect from "@Features/countrySelect/components/CountrySelect";
import Notification from "@Features/notification/components/Notification";
import {useToggle} from "@Hooks/dispatch/useToggle";
import useUpdatePhoneNumber from "@Features/input/phoneField/hooks/useUpdatePhoneNumber";

interface PhoneInputProps {
    loadingClass?: string;
    phoneCountryObject: PhoneCountryType,
    setPhoneNumber: (value: string) => void
    setPhoneCountryObject: (value: PhoneCountryType) => void,
    phoneNumber: string,
    onBlur?: (any?) => any,
    passengerDataUpdating?: boolean
    setValidState?: () => void,
    htmlId?: string,
    showError?: boolean,
    errorMessage?: string,
}

function Phone(props: PhoneInputProps) {
    const {
        phoneNumber,
        setPhoneNumber,
        phoneCountryObject,
        setPhoneCountryObject,
        loadingClass = '',
        onBlur = () => {
        },
        passengerDataUpdating,
        setValidState = () => {
        },
        htmlId,
        showError,
        errorMessage,
    } = props

    const {
        value: phoneCountrySelectActive,
        set: setPhoneCountrySelect,
        onChange: togglePhoneCountrySelect,
    } = useToggle()

    const closePhoneCountrySelect = () => {
        setPhoneCountrySelect(false)
    }

    useUpdatePhoneNumber({
        phoneNumber,
        setPhoneNumber,
        phoneCountryObject,
        setPhoneCountryObject,
        closePhoneCountrySelect,
        setValidState,
    })
    const handlePhoneInput = getHandlePhoneInput(setPhoneNumber)

    return (
        <>
            <div className={`phone-input ${loadingClass}`}>
                <div
                    className="gb--row gb--row-middle gb--row-justify gb--input__adornment"
                    onClick={togglePhoneCountrySelect}
                >
                    <SelectedCountryIcon selectedCountry={phoneCountryObject}/>
                </div>
                <input
                    id={htmlId}
                    placeholder={useTranslation('check_order.buyer_info_section.phone_field_placeholder')}
                    value={phoneNumber}
                    onChange={handlePhoneInput}
                    autoComplete="tel"
                    type="tel"
                    onBlur={onBlur}
                    disabled={passengerDataUpdating}
                />
            </div>
            {phoneCountrySelectActive && (
                <ClickOutsideAwareComponent onClickOutside={closePhoneCountrySelect}>
                    <CountrySelect
                        setPhoneCountryObject={(country) => {
                            setPhoneCountryObject(country)
                            closePhoneCountrySelect()
                        }}
                    />
                </ClickOutsideAwareComponent>
            )}
            {showError && (
                <Notification notificationType="orange">
                    {errorMessage}
                </Notification>
            )}
        </>
    )
}

export default Phone