import React from 'react';
import FadingError from "@Errors/components/FadingError";
import {useAppSelector} from "@Hooks/selector/useAppSelector";

interface FormInputErrorProps {
    direction: "departure" | "destination"
}

function FormInputError({direction}: FormInputErrorProps) {
    const {
        departureValid,
        destinationValid,
    } = useAppSelector((state) => state?.form.formErrors)

    const hasError = (direction === 'departure') ? !departureValid : !destinationValid

    return (
        <FadingError error={hasError}/>
    );
}

export default FormInputError;