import {useTranslation} from "@Translation/hooks/useTranslation";
import {RouteTransferTypeValuesType} from "@Route/features/transportType/types/routeTransferType.type";

export default function useTabLabelConfigurator(tabType: RouteTransferTypeValuesType) {
    const translations = {
        privateTransfer: 'Private transfer',
        bus: "sys.bus",
        ferry: "sys.ferry",
        train: "sys.train",
        plane: "sys.plane",
        minivan: "sys.minivan",
        privateMinivan: "search_results.transport_labels.minivan.private_minivan",
        privateBus: "Private bus",
    }

    return useTranslation(translations[tabType])
}