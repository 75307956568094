import ModalDataType from "@MyTickets/context/types/ModalData.type";

export const modalDataInitialValue: ModalDataType = {
    ticketId: null,
    ticketCode: "",
    rebookAmount: "",
    isCancelable: null,
    isRebookable: null,
    isCancelled: null,
    singleRoute: {
        companyId: null,
        departure: "",
        destination: "",
        departureDate: "",
        departureTime: "",
    },
    ancillariesData: {
        premiumSupport: {
            currSymbol: "",
            displayCurrencySymbol: "",
            displayPrice: null,
            pricesInOtherCurrencies: "EUR",
            priceFixed: null,
            name: "",
            id: null,
            isEnabled: null,
            price: null,
            pricePercentage: null,
            useFixedPrice: null,
        },
    },
}