import React from 'react';
import useIsMobile from "@Hooks/selector/useIsMobile";
import TicketType from "@/components/Ticket/TicketType";
import TicketTypeNotification from "@/components/Ticket/TicketTypeNotification";

interface RouteTicketTypeSectionProps {
    hasMTicket: boolean,
}

function RouteTicketTypeSection({hasMTicket}: RouteTicketTypeSectionProps) {
    const isMobile = useIsMobile()

    if (!isMobile) {
        return <TicketTypeNotification hasMTicket={hasMTicket}/>
    }

    return <TicketType hasMTicket={hasMTicket}/>
}

export default RouteTicketTypeSection