import ParamsType from "@SearchResults/types/params/Params.type";
import PassengerClass from "@Route/components/PassengerClasses/PassengerClass";
import {PreparedRouteType} from "@Route/types/PreparedRoute.type";
import {Fragment} from "react";

type RoutePassengerClassesContainerProps = {
    passengerClasses: PreparedRouteType[]
    params: ParamsType,
    displayImages?: boolean,
    isReturn: boolean
}

function RoutePassengerClassesContainer({passengerClasses, params, displayImages, isReturn}: RoutePassengerClassesContainerProps) {
    return (
        <div className="gb--passenger_class">
            {passengerClasses.map((passengerClass, index) => (
                <Fragment key={`${passengerClass.id}-${index}`}>
                    <PassengerClass
                        isReturn={isReturn}
                        passengerClass={passengerClass}
                        params={params}
                        displayImages={displayImages}
                        passengerClassIndex={index}
                    />
                    {index !== passengerClasses.length - 1 && (
                        <hr/>
                    )}
                </Fragment>
            ))}
        </div>
    );
}

export default RoutePassengerClassesContainer;