import StationModel from "@Form/models/StationModel";

export default class DestinationStationModel extends StationModel {
    /**
     * Destination Station
     * @param props {object}
     * @param props.payload {object} - updated state
     * @param props.urlParams {object} - existing urlParams state
     */
    constructor(props) {
        super(props);
        const {urlParams} = props

        this.urlParams = {...urlParams}
        this.urlParams.toId = this.station.id
        this.urlParams.toSlug = this.station.slug
        this.destinationStationObject = this.station
    }
}