import {useTranslation} from "@Translation/hooks/useTranslation";
import useMakeDescription from "@MyProfile/hooks/useMakeDescription";
import {isTicketsPage, isVouchersPage, vouchersUrl} from "@MyProfile/functions/isActivePage";
import useCountValidVouchers from "@MyProfile/features/vouchers/hooks/useCountValidVouchers";
import useCountArchivedVouchers from "@MyProfile/features/vouchers/hooks/useCountArchivedVouchers";

export default function useMyVouchersLink() {
    const vouchersLabel = useTranslation("sys.my_vouchers")
    const {makeDescription} = useMakeDescription()

    const {
        validVouchersCount,
        validVouchersCountLoading,
    } = useCountValidVouchers()

    const {
        archivedVouchersCount,
        archivedVouchersCountLoading,
    } = useCountArchivedVouchers()

    const validLabel = useTranslation(
        'my_profile.links.valid_vouchers',
        {
            count: validVouchersCount,
        }
    )
    const archivedLabel = useTranslation(
        'my_profile.links.archived_vouchers',
        {
            count: archivedVouchersCount,
        }
    )

    const vouchersDescription = makeDescription({
        validCountLoading: validVouchersCountLoading,
        archivedCountLoading: archivedVouchersCountLoading,
        showAll: isTicketsPage(),
        allText: `X ${validVouchersCount + archivedVouchersCount}`,
        validText: `X ${validLabel}`,
        archivedText: `X ${archivedLabel}`,
    })

    return {
        url: vouchersUrl,
        isActive: isVouchersPage,
        label: vouchersLabel,
        descriptionText: vouchersDescription,
    }
}