import React, {Fragment} from "react";
import IntermodalTicketLabel from "@Intermodal/components/IntermodalTicketLabel";
import {isLastElement} from "@Array/isLastElement";
import Notification from "@Features/notification/components/Notification";
import IconPaxWithLuggage from "@Icons/FilledOutline/IconPaxWithLuggage";
import NotificationExpandable from "@Features/notification/components/NotificationExpandable";
import SelfTransferNotice from "@CheckOrder/components/Sidebar/SelfTransferNotice";
import CompanyType from "@SearchResults/types/company/Company.type";
import IntermodalRouteType from "@Intermodal/types/IntermodalRoute.type";
import PurchaseRouteInfo from "@CheckOrder/components/Sidebar/PurchaseRouteInfo";


interface IntermodalPurchaseRouteInfoProps {
    singleRoute: IntermodalRouteType,
    companies: Record<number, CompanyType>,
    displayMultiTicketNotice: boolean,
}

const IntermodalPurchaseRouteInfo = (props: IntermodalPurchaseRouteInfoProps) => (
    <>
        {props.displayMultiTicketNotice && (
            <Notification isLarge notificationType="orange">
                <NotificationExpandable
                    icon={(
                        <IconPaxWithLuggage
                            width="20"
                            height="24"
                        />
                    )}
                    title={<SelfTransferNotice/>}
                    descriptionText="These trips are officially independent from one another, but with a friendly alliance between transport companies. In case of a delay or cancellation of any trip leg, we'll offer our assistance at no extra cost to make sure you reach your destination"
                >
                    <hr className="ticket-separator"/>
                </NotificationExpandable>
            </Notification>
        )}
        <hr className="ticket-separator"/>
        {props.singleRoute.baseRoutes.map((baseRoute, index) => (
            <Fragment key={baseRoute.id}>
                <IntermodalTicketLabel
                    justified
                    route={baseRoute}
                    index={index}
                />
                <PurchaseRouteInfo
                    isIntermodal
                    companies={props.companies}
                    singleRoute={baseRoute}
                />
                {!isLastElement(props.singleRoute.baseRoutes, index) && (
                    <hr className="ticket-separator"/>
                )}
            </Fragment>
        ))}
    </>
);

export default IntermodalPurchaseRouteInfo;