/**
 * Extracts data from root react node dataset
 * @param data {string}
 * @param parse {boolean}
 * @returns {*|null}
 */
export default function rootExtractor(data, parse = false) {
    const rootElement = document.getElementById('root');

    if (parse) {
        return JSON.parse(rootElement?.dataset?.[data]) ?? null
    }

    return rootElement?.dataset?.[data] || null
}