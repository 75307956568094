import React, {ReactNode} from 'react';
import IconChevronDown from "@Icons/IconChevronDown";

interface MobileMenuItemProps {
    /** What to do when clicked on item */
    openItem?: (any?) => any,
    /** Text of menu item */
    text: string,
    /** Icon to show */
    icon?: ReactNode,
}

function MobileMenuItem({icon, text, openItem}: MobileMenuItemProps) {
    return (
        <li
            className="gb--menu__item gb--row gb--row-middle gb--row-justify"
            onClick={() => {
                openItem();
            }}
        >
            <span>
                {icon}
                {text}
            </span>
            <IconChevronDown
                width="20"
                height="26"
                style={{
                    transform: 'rotate(-90deg)',
                    stroke: '#9b9b9b',
                    strokeWidth: '1',
                    verticalAlign: 'middle',
                }}
            />
        </li>
    );
}

export default MobileMenuItem;
