/**
 * Checks password strength
 *
 * */

const hasNumber = (value) => {
    // eslint-disable-next-line prefer-regex-literals
    return new RegExp(/[0-9]/).test(value)
}
const hasLowerCase = (value) => {
    // eslint-disable-next-line prefer-regex-literals
    return new RegExp(/[a-z]/).test(value)
}
const hasUpperCase = (value) => {
    // eslint-disable-next-line prefer-regex-literals
    return new RegExp(/[A-Z]/).test(value)
}
const hasSpecialCharacter = (value) => {
    // eslint-disable-next-line prefer-regex-literals
    return new RegExp(/[!#@$%^&*)(+=._-]/).test(value)
}

export const strengthIndicator = (value) => {
    let strengths = 0
    if (value.length > 4) {
        strengths += 1
    }

    if (value.length > 8) {
        strengths += 1
    }

    if (hasNumber(value)) {
        strengths += 1
    }

    if (hasSpecialCharacter(value)) {
        strengths += 1
    }

    if (hasLowerCase(value)) {
        strengths += 1
    }

    if (hasUpperCase(value)) {
        strengths += 1
    }

    return strengths
}

export const strengthColor = (count) => {
    if (count <= 2) {
        return '#f03a47'
    }
    if (count <= 3) {
        return '#f5a623'
    }
    if (count <= 4) {
        return '#F8C777'
    }
    if (count <= 5) {
        return '#C3EE95'
    }
    if (count <= 6) {
        return '#7ed321'
    }
}

export const strengthLength = (count: number) => {
    return `${(count / 6) * 100}%`
}

// TODO: add translation keys
export const strengthLabel = (count: number) => {
    if (count < 1) {
        return ''
    }
    if (count <= 2) {
        return 'Weak'
    }
    if (count === 3) {
        return 'Poor'
    }
    if (count === 4) {
        return 'Fair'
    }
    if (count === 5) {
        return 'Good'
    }
    if (count === 6) {
        return 'Strong'
    }
}
