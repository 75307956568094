import Translation from "@Translation/components/Translation";
import React from "react";

interface ErrorMessageProps {
    fieldLabel: string,
    translationKey: string,
}

const ErrorMessage = ({fieldLabel, translationKey}: ErrorMessageProps) => (
    <Translation
        translationKey={`user.user_messages.error.${translationKey}`}
        translationVariables={{
            fieldLabel,
        }}
    />
)

export default ErrorMessage