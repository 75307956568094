import React, {ReactNode} from 'react';
import Row from "@Containers/components/Row";

interface FilterSliderLabelsProps {
    label: string | ReactNode,
    value: string,
}

const FilterSliderLabels = ({label, value}: FilterSliderLabelsProps) => (
    <Row justify center>
        {typeof label !== "string" ? label : (
            <span>
                {label}
            </span>
        )}
        <span>
            {value}
        </span>
    </Row>
);

export default FilterSliderLabels;