import React from "react";
import Notification from "@Features/notification/components/Notification";
import IconExclamationCircle from "@Icons/IconExclamationCircle";
import {useMailCheck} from "@qb/use-mailcheck";
import CheckOrderErrorLabel from "@CheckOrder/components/MainSection/CheckOrderErrorLabel";
import {useTranslation} from "@Translation/hooks/useTranslation";

interface EmailInputProps {
    emailValue: string,
    handleEmailInput: (value: any) => any,
    emailValidated: boolean,
    setEmail: (value: any) => any,
    onKeyDown?: (...args: any[]) => any,
    onBlur?: (any) => any,
    onFocus?: (any) => any,
    readOnly?: boolean,
    isRequired?: boolean,
    userConfirmed?: boolean,
    htmlId?: string,
    className?: string,
    placeholder?: string,
}

function EmailInput(props: EmailInputProps) {
    const {
        readOnly,
        emailValue,
        handleEmailInput,
        emailValidated,
        setEmail,
        onBlur,
        onFocus,
        onKeyDown,
        isRequired,
        userConfirmed,
        htmlId,
        className,
        placeholder,
    } = props

    const suggestion = useMailCheck({email: emailValue});
    const {invalid_email: invalidEmail} = useTranslation("log_in")

    return (
        <>
            <input
                className={className ?? ""}
                id={htmlId}
                placeholder={placeholder}
                autoComplete="email"
                type="email"
                onBlur={onBlur ?? handleEmailInput}
                value={emailValue}
                onChange={handleEmailInput}
                onFocus={onFocus}
                onKeyDown={onKeyDown}
                readOnly={readOnly}
                required={isRequired ?? true}
                disabled={readOnly}
            />
            {emailValidated && suggestion && (
                <Notification
                    notificationType="orange"
                    notificationIcon={<IconExclamationCircle height="16" fill="#f5a623"/>}
                    isDismissible
                    isFullWidth
                    isRow
                >
                    <button
                        type="button"
                        className="gb--emphasize-warning"
                        onClick={() => setEmail(suggestion.full)}
                    >
                        {`Did you mean ${suggestion.full} ?`}
                    </button>
                </Notification>
            )}
            {!emailValidated && !!emailValue.length && (
                <CheckOrderErrorLabel error={invalidEmail}/>
            )}
            {!emailValue && userConfirmed && (
                <CheckOrderErrorLabel/>
            )}
        </>
    );
}

export default EmailInput