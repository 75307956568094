import IconAvatar from "@Icons/IconAvatar";
import React, {ForwardedRef} from "react";
import Row from "@Containers/components/Row";
import Column from "@Containers/components/Column";
import useAvatarSection from "@MyProfile/features/avatar/hooks/useAvatarSection";
import MyProfileAvatarDescription from "@MyProfile/features/avatar/components/MyProfileAvatarDescription";
import useIsMobile from "@Hooks/selector/useIsMobile";
import MobileAppTitle from "@SearchResults/components/HeadingContent/Mobile/MobileAppTitle";

interface MyProfileAvatarSectionProps {
    avatarRef: ForwardedRef<null>
}

function MyProfileAvatarSection({avatarRef}: MyProfileAvatarSectionProps) {
    const {
        realName,
        username,
        showMoreButton,
        setFiltersTrue,
        descriptionText,
    } = useAvatarSection()

    const isMobile = useIsMobile()

    if (isMobile) {
        return (
            <MobileAppTitle
                titleRef={avatarRef}
                sticky
            >
                <Column className="user-data">
                    <div className="gb--emphasize gb--emphasize-default">{`Hi ${realName}`}</div>
                    <small>{descriptionText()}</small>
                </Column>
            </MobileAppTitle>
        );
    }

    return (
        <div className="avatar-section" ref={avatarRef}>
            <Column className="avatar-content gb--emphasize-default">
                <Row className="basic-info">
                    <IconAvatar/>
                    <Column className="user-data">
                        <h2 className="user-data-username">{realName}</h2>
                        <div>{username}</div>
                    </Column>
                </Row>
                <Row>
                    <MyProfileAvatarDescription
                        showMoreButton={showMoreButton}
                        descriptionText={descriptionText()}
                        setFiltersTrue={setFiltersTrue}
                    />
                </Row>
            </Column>
        </div>
    );
}

export default MyProfileAvatarSection