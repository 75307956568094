import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconSpecialInfo(props: IconType) {
    const {
        width = '35',
        height = '35',
        viewBox = '0 0 35 35',
        title = 'IconSpecialInfo',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none">
                    <g transform="translate(5 1)">
                        <g transform="translate(0 1.7)">
                            <path
                                d="m1.15855 30.175c-.4046 0-.73355-.3366-.73355-.75225v-28.2455c0-.41565.3298-.75225.73355-.75225h8.65045c-.02295.1428-.034.2839-.034.425 0 1.6405 1.3345 2.975 2.975 2.975s2.975-1.3345 2.975-2.975c0-.1411-.01105-.2822-.034-.425h8.65045c.4046 0 .73355.3366.73355.75225v28.2455c0 .4148-.3298.75225-.73355.75225z"
                                fill="#bbb"
                            />
                            <path
                                d="m24.3406.85c.17085 0 .3094.14705.3094.32725v28.24635c0 .17935-.13855.3264-.3094.3264h-23.1812c-.17085 0-.3094-.14705-.3094-.32725v-28.2455c0-.1802.13855-.32725.3094-.32725h8.1906c0 1.8751 1.5249 3.4 3.4 3.4s3.4-1.5249 3.4-3.4zm0-.85h-9.197c.0952.2669.1564.54995.1564.85 0 1.40845-1.14155 2.55-2.55 2.55s-2.55-1.14155-2.55-2.55c0-.30005.0612-.5831.1564-.85h-9.197c-.64005 0-1.1594.527-1.1594 1.17725v28.24635c0 .6494.51935 1.1764 1.1594 1.1764h23.18205c.6392 0 1.15855-.527 1.15855-1.17725v-28.2455c0-.65025-.51935-1.17725-1.1594-1.17725z"
                                fill="#070c63"
                            />
                        </g>
                        <path d="m3.4 5.1h18.7v23.8h-18.7z" fill="#f5f5f5"/>
                        <g fill="#070c63">
                            <path
                                d="m14.69565 1.7c.19125.43775.24565.9486.08415 1.48495-.20995.69615-.79645 1.25715-1.50365 1.42715-1.40165.33575-2.65115-.7174-2.65115-2.0621 0-.3026.06545-.58905.17935-.85h-4.00435v3.4c0 .93925.76075 1.7 1.7 1.7h8.5c.93925 0 1.7-.76075 1.7-1.7v-3.4z"
                            />
                            <path
                                d="m12.75.85c.93755 0 1.7.76245 1.7 1.7s-.76245 1.7-1.7 1.7-1.7-.76245-1.7-1.7.76245-1.7 1.7-1.7m0-.85c-1.40845 0-2.55 1.14155-2.55 2.55s1.14155 2.55 2.55 2.55 2.55-1.14155 2.55-2.55-1.14155-2.55-2.55-2.55z"
                            />
                        </g>
                    </g>
                    <g transform="translate(11 12)">
                        <path
                            d="m10.8 13h-3.2l-1 1-1-1h-3.2c-1.215 0-2.2-.985-2.2-2.2v-8.4c0-1.215.985-2.2 2.2-2.2h8.4c1.215 0 2.2.985 2.2 2.2v8.4c0 1.215-.985 2.2-2.2 2.2z"
                            fill="#4a79e2"
                        />
                        <path
                            d="m8.8 9.3v.8c0 .056-.044.1-.1.1h-3.6c-.056 0-.1-.044-.1-.1v-.8c0-.056.044-.1.1-.1h.8c.056 0 .1-.044.1-.1v-3c0-.056-.044-.1-.1-.1h-.6c-.056 0-.1-.044-.1-.1v-.8c0-.056.044-.1.1-.1h2.4c.056 0 .1.044.1.1v4c0 .056.044.1.1.1h.8c.056 0 .1.044.1.1z"
                            fill="#fefdef"
                        />
                        <g transform="translate(5.8 2.6)">
                            <circle cx="1" cy="1" fill="#fefdef" r="1"/>
                            <path
                                d="m1 2c.55127273 0 1-.4486 1-1s-.44872727-1-1-1-1 .4486-1 1 .44872727 1 1 1zm0-1.8c.45109091 0 .81818182.3588.81818182.8s-.36709091.8-.81818182.8-.81818182-.3588-.81818182-.8.36709091-.8.81818182-.8z"
                                fill="#070c63"
                            />
                        </g>
                        <path
                            d="m6.6 14.2c-.0512 0-.1024-.0196-.1414-.0586l-.9414-.9414h-3.1172c-1.3234 0-2.4-1.0766-2.4-2.4v-8.4c0-1.3234 1.0766-2.4 2.4-2.4h8.4c1.3234 0 2.4 1.0766 2.4 2.4v8.4c0 1.3234-1.0766 2.4-2.4 2.4h-3.1172l-.9414.9414c-.039.039-.0902.0586-.1414.0586zm-4.2-13.8c-1.1028 0-2 .8972-2 2v8.4c0 1.1028.8972 2 2 2h3.2c.053 0 .104.021.1414.0586l.8586.8586.8586-.8586c.0374-.0376.0884-.0586.1414-.0586h3.2c1.1028 0 2-.8972 2-2v-8.4c0-1.1028-.8972-2-2-2z"
                            fill="#070c63"
                        />
                        <path
                            d="m10.8 12.2h-8.4c-.772 0-1.4-.6282-1.4-1.4v-8.4c0-.7718.628-1.4 1.4-1.4h7.5c.0552 0 .1.0448.1.1s-.0448.1-.1.1h-7.5c-.6618 0-1.2.5382-1.2 1.2v8.4c0 .6618.5382 1.2 1.2 1.2h8.4c.6618 0 1.2-.5382 1.2-1.2v-7.07092276c0-.0552.0448-.1.1-.1s.1.0448.1.1v7.07092276c0 .7718-.628 1.4-1.4 1.4z"
                            fill="#fff"
                        />
                        <path
                            d="m8.7 9.2h-.8c-.055 0-.1-.045-.1-.1v-.4c0-.0552-.0448-.1-.1-.1s-.1.0448-.1.1v.4c0 .1654.1346.3.3.3h.7v.6h-3.4v-.6h.7c.1654 0 .3-.1346.3-.3v-3c0-.1654-.1346-.3-.3-.3h-.58662995c-.07558003-.05154803-.11337005-.15154803-.11337005-.3s.03779002-.24845197.11337005-.3h2.28662995v3.5h.2v-3.6c0-.0552-.0448-.1-.1-.1h-2.5c-.0552 0-.2.2-.2.5s.1448.5.2.5h.7c.055 0 .1.045.1.1v3c0 .055-.045.1-.1.1h-.8c-.0552 0-.1.0448-.1.1v.8c0 .0552.0448.1.1.1h3.6c.0552 0 .1-.0448.1-.1v-.8c0-.0552-.0448-.1-.1-.1z"
                            fill="#070c63"
                        />
                    </g>
                </g>
            )}
        />

    );
}

export default IconSpecialInfo;
