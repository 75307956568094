import React from 'react';
import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "@/types/Icon.type";

const IconDownload = (props: IconType) => {
    const {
        width = '20',
        height = '20',
        fill = '#fff',
        viewBox = '0 0 20 20',
        title = 'IconDownload',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <path
                    d="m10.0223214 15.292804c.2083334 0 .3869048-.065264.5357143-.1957919l3.4821429-3.0543526c.1636904-.1305279.2455357-.2871614.2455357-.4699004s-.078125-.3426357-.234375-.47969-.3385417-.2055814-.546875-.2055814-.3869048.0717903-.5357143.215371l-2.1651786 1.8796016v-11.29718888c0-.19579183-.078125-.35895169-.234375-.48947958s-.3385416-.19579184-.546875-.19579184c-.2083333 0-.38690473.06526395-.53571426.19579184-.14880952.13052789-.22321428.29368775-.22321428.48947958v11.29718888l-2.16517857-1.8991808c-.16369048-.1305279-.34970239-.1957918-.55803572-.1957918s-.38690476.0685271-.53571428.2055814c-.14880953.1370543-.22321429.296951-.22321429.47969s.07440476.3393725.22321429.4699004l3.48214285 3.0543526c.14880953.1305279.32738096.1957919.53571426.1957919zm8.59375 3.707196c.3869048 0 .7142857-.1377171.9821429-.4131514.2678571-.2754342.4017857-.5992555.4017857-.971464v-4.9801489c0-.2084367-.0744048-.3870967-.2232143-.5359801-.1488095-.1637717-.3311012-.2456576-.546875-.2456576s-.3980655.0781638-.546875.2344913c-.1488095.1563276-.2232143.3387097-.2232143.5471464v4.3548387c0 .1339951-.0446428.2456576-.1339285.3349876-.0892858.0893301-.2008929.1339951-.3348215.1339951h-15.98214283c-.13392857 0-.24553571-.044665-.33482143-.1339951-.08928571-.08933-.13392857-.2009925-.13392857-.3349876v-4.3548387c0-.2084367-.07440476-.3908188-.22321428-.5471464-.14880953-.1563275-.33110119-.2344913-.546875-.2344913s-.39806548.0781638-.546875.2344913c-.14880953.1563276-.22321429.3387097-.22321429.5471464v4.9801489c0 .3870968.13392857.7146402.40178571.9826303.26785715.2679901.5952381.4019851.98214286.4019851z"
                    fill={fill}
                    transform="translate(0 -1)"
                />
            )}
        />

    );
}

export default IconDownload;
