import React, {ReactNode} from 'react';
import CheckOrderErrorLabel from "@CheckOrder/components/MainSection/CheckOrderErrorLabel";
import Checkbox from "./Checkbox";

interface TermWithCheckboxProps {
    onClick: (any?) => any,
    value: boolean,
    label?: string,
    errorLabelText?: string,
    userConfirmed?: boolean,
    children?: ReactNode,
}

const TermWithCheckbox = ({label, errorLabelText, userConfirmed, children, onClick, value}: TermWithCheckboxProps) => (
    <>
        <Checkbox onChange={onClick} className="term-with-checkbox">
            {children || (
                <span>
                    {label}
                </span>
            )}
        </Checkbox>
        {!value && userConfirmed && errorLabelText && (
            <CheckOrderErrorLabel error={errorLabelText}/>
        )}
    </>
)

export default TermWithCheckbox;