import {SeatDeckType} from "@CheckOrder/components/MainSection/SeatPicker/types/SeatDeck.type";
import React from "react";
import Cabin from "@CheckOrder/components/MainSection/SeatPicker/components/Cabin";
import Grid from "@Containers/components/Grid";

type CabinProps = {
    seatDeck: SeatDeckType,
    isReturn: boolean,
}

function Cabins({seatDeck, isReturn}: CabinProps) {
    return (
        <Grid min="6em" max="7em" style={{justifyContent: 'center'}}>
            {seatDeck.seatRows.map((seatRow, index) => (
                <Cabin
                    key={index}
                    isReturn={isReturn}
                    seatRow={seatRow}
                />
            ))}
        </Grid>
    );
}

export default Cabins;