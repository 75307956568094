import {ReactNode, useState} from "react";
import TicketsContext from "@MyTickets/context/functions/TicketsContext";
import {modalDataInitialValue} from "@MyTickets/context/constants/modalDataInitialValue";
import ModalDataType from "@MyTickets/context/types/ModalData.type";

const {Provider} = TicketsContext

interface TicketsProviderProps {
    children: ReactNode,
}

function TicketsProvider({children}: TicketsProviderProps) {
    const [isDownloadDocumentsModalOpen, setIsDownloadDocumentsModalOpen] = useState(false)
    const [isManageBookingModalOpen, setIsManageBookingModalOpen] = useState(false)
    const [modalData, setModalData] = useState<ModalDataType>(modalDataInitialValue)

    const token = {
        isDownloadDocumentsModalOpen,
        setIsDownloadDocumentsModalOpen,
        isManageBookingModalOpen,
        setIsManageBookingModalOpen,
        modalData,
        setModalData,
    }

    return (
        <Provider value={token}>
            {children}
        </Provider>
    );
}

export default TicketsProvider