import React from 'react';
import {useTranslation} from "@Translation/hooks/useTranslation";
import TravelInfoType from "@Route/types/travelInfo/TravelInfo.type";
import TravelInfoWithDurationType from "@Route/types/travelInfo/TravelInfoWithDuration.type";
import OperationalTimes from "@SearchResults/components/Popover/StationsPopover/OperationalTimes";
import Stations from "@SearchResults/components/Popover/StationsPopover/Stations";

interface StationsPopoverProps {
    travelInfo: TravelInfoWithDurationType | TravelInfoType,
}

function StationsPopover({travelInfo}: StationsPopoverProps) {
    const {
        stations,
        timetable,
        departureStationIndex,
        destinationStationIndex,
    } = travelInfo

    const {station, arrival, departure} = useTranslation('sys')

    return (
        <>
            <span className="gb--stations-details">
                <span className="gb--emphasize gb--emphasize-info">
                    {station}
                </span>
                <span className="gb--emphasize gb--emphasize-info">
                    {arrival}
                </span>
                <span className="gb--emphasize gb--emphasize-info">
                    {departure}
                </span>
            </span>
            <hr/>
            <div className="gb--stations-details">
                <Stations
                    stations={stations}
                    departureStationIndex={departureStationIndex}
                    destinationStationIndex={destinationStationIndex}
                />
            </div>
            <OperationalTimes
                timetable={timetable}
            />
        </>
    );
}

export default StationsPopover;