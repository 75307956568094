import React from 'react';
import BlogPost from "./BlogPost";
import LatestBlogType from "@Sidebar/types/LatestBlog.type";

interface BlogPostsProps {
    blogPosts: LatestBlogType[],
}

const BlogPosts = ({blogPosts}: BlogPostsProps) => (
    <>
        {blogPosts.map((blogPost) => (
            <BlogPost
                blogPost={blogPost}
                key={blogPost.ID}
            />
        ))}
    </>
);

export default BlogPosts;