import {useState} from "react";
import useEventListener from "./useEventListener";

export default function useWindowVisibilityCheck() {
    const [visible, setVisible] = useState(true)
    function handleVisibilityChange() {
        setVisible(document.visibilityState === 'visible')
    }

    useEventListener('visibilitychange', handleVisibilityChange)

    return visible
}