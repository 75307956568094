import React from "react";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import DateInputFieldType
    from "@CheckOrder/features/TicketAdditionalInfo/features/dateAdditionalField/types/DateInputField.type";

interface DateInputProps {
    dateInputField: DateInputFieldType,
    name: string,
    index: number,
}

function DateInput({dateInputField, name, index}: DateInputProps) {
    const {
        value,
        type,
        onChange,
        className = "",
        inputRef,
        onKeyPress,
    } = dateInputField

    const {
        passengerDataUpdating,
    } = useAppSelector((state) => state?.checkOrder)

    const placeholders = {
        year: "YYYY",
        day: "DD",
        month: "MM",
    }

    const preventPropagation = (e: React.MouseEvent<HTMLInputElement>) => {
        e.stopPropagation()
    }

    return (
        <input
            type="text"
            inputMode="numeric"
            ref={inputRef}
            name={name}
            value={value ?? ""}
            onChange={onChange}
            onClick={preventPropagation}
            className={className}
            placeholder={placeholders[type]}
            onKeyDown={onKeyPress}
            aria-describedby={name}
            // @ts-ignore
            tabIndex={index !== 0 ? "-1" : undefined}
            required
            disabled={passengerDataUpdating}
        />
    )
}

export default DateInput