import React from 'react';
import PropTypes from 'prop-types';
import CookieTab from "./CookieTab";
import ToggleButton from "../../../components/Generic/ToggleButton";
import {useTranslation} from "@Translation/hooks/useTranslation";

function HotjarCookie({toggle, cookieActive}) {
    const {
        tabs: {
            performance_cookies: performanceCookies,
            content: {
                performance_cookies: performanceCookiesContent,
            },
        },
    } = useTranslation("cookie.preferences")

    return (
        <CookieTab label={performanceCookies}>
            <>
                <p>{performanceCookiesContent}</p>
                <ToggleButton
                    label={`Hotjar cookie ${cookieActive ? "enabled" : "disabled"}`}
                    onClick={toggle}
                    checked={cookieActive}
                />
            </>
        </CookieTab>
    );
}

HotjarCookie.propTypes = {
    toggle: PropTypes.func.isRequired,
    cookieActive: PropTypes.bool.isRequired,
};

export default HotjarCookie;