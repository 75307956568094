import IntermodalRouteWithDetailsType from "@Intermodal/types/IntermodalRouteWithDetails.type";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";

/**
 * Sort routes by number of slots left
 */

type sortRoutesFunction = (
    a: RouteWithAddedPropsAndPricesType | IntermodalRouteWithDetailsType,
    b: RouteWithAddedPropsAndPricesType | IntermodalRouteWithDetailsType
) => number

export function sortBySeatsLeft(passengers): sortRoutesFunction {
    return function sort(a, b) {
        const allotment = (route) => route?.allotment.totalAllotmentLeft

        let comparison = 0;
        if (allotment(a) < passengers && allotment(b) >= passengers) {
            comparison = 1;
        } else if (allotment(a) >= passengers && allotment(b) < passengers) {
            comparison = -1;
        }

        return comparison
    };
}