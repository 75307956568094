import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "@Translation/hooks/useTranslation";
import {toggleCookiePreferences} from "@Reducers/pageSlice";
import useCookies from "../hooks/useCookies";

function CookieNotice() {
    const {
        cookiesConsent,
        cookiePreferencesActive,
    } = useSelector((state) => state?.page)

    const {acceptAllCookies} = useCookies()

    const dispatch = useDispatch()
    const openCookiePreferences = () => dispatch(toggleCookiePreferences())

    const {
        to_change: toChange,
        if_that_is_ok: ifThatIsOk,
        click_here: clickHere,
        website_uses_cookies: websiteUsesCookies,
        accept_all: acceptAll,
        open_preferences: cookiePreferences,
    } = useTranslation("cookie.notice")

    if (!cookiesConsent && !cookiePreferencesActive) {
        return (
            <div id="cookie-consent-notice" className="cookie-consent cookie-consent-notice">
                <p className="description">
                    {websiteUsesCookies}
                    {` `}
                    <span id="cookie-preferences-modal" onClick={openCookiePreferences}>
                        {clickHere}
                    </span>
                    {` ${toChange} ${cookiePreferences}. ${ifThatIsOk}`}
                </p>
                <button
                    type="button"
                    className="btn btn--green btn--agree"
                    id="accept-all-cookies"
                    onClick={acceptAllCookies}
                >
                    {acceptAll}
                </button>
            </div>
        );
    }

    return null
}

export default CookieNotice