import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconSeatbelt(props: IconType) {
    const {
        width = '16',
        height = '16',
        fill = '#b5b7d1',
        outline = '#070c63',
        viewBox = '0 0 16 16',
        title = 'IconSeatbelt',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            outline={outline}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none" fillRule="evenodd" transform="translate(2.5)">
                    <circle cx="5.433155" cy="3" r="2.479423" stroke={outline} strokeWidth="1.041153"/>
                    <path
                        d="m5.49998956 7c-1.45868194 0-2.85762464.58365374-3.88907699 1.62257065-1.03144191 1.03890639-1.61091257 2.44794155-1.61091257 3.91720095 0 .2541513.20457558.4602284.45692166.4602284.25235653 0 .45692167-.2060771.45692167-.4602284 0-1.2251016.48318813-2.4000252 1.34325569-3.26634861.86006756-.86629182 2.02657029-1.35296623 3.24289054-1.35296623.96298198 0 1.89473664.30505515 2.66793164.86204194l.71593097-.60989991c-.96146761-.75591065-2.15136473-1.17259879-3.38386261-1.17259879zm4.05979336 1.80237004-.69838518.59492014c.78580082.85270062 1.22475896 1.97481632 1.22475896 3.14248142 0 .2541513.204492.4602284.4569216.4602284.2523252 0 .4569217-.2060771.4569217-.4602284 0-1.3861553-.5158254-2.71866106-1.44021708-3.73740156z"
                        fill="#070c63"
                    />
                    <path
                        d="m10.2692899 7.15637-9.31123744 7.3734672c-.22089944.1748583-.09001618.5151263.1981763.5151263h9.17749064"
                        stroke={outline}
                        strokeLinecap="round"
                        strokeWidth="1.041153"
                    />
                    <rect
                        fill={fill}
                        fillRule="nonzero"
                        height="1.91005"
                        rx=".594945"
                        width="2.712939"
                        x="4.143549"
                        y="14.08995"
                    />
                </g>
            )}
        />

    );
}

export default IconSeatbelt;