import {useParams} from "react-router";
import {generatePath} from "react-router-dom";
import {RouteTransferTypeValuesType} from "@Route/features/transportType/types/routeTransferType.type";
import {LocaleType} from "@/types/Locale.type";
import useGeneratePassengersQueryString from "@SearchResults/hooks/useGeneratePassengersQueryString";
import UrlParamsType from "@Form/types/UrlParams.type";
import {useAppSelector} from "@Hooks/selector/useAppSelector";

type SearchUrlParamsType = {
    locale?: LocaleType,
    transportTypeRoute?: string,
    fromSlug?: string,
    toSlug?: string,
    date?: string,
    returnDate?: string,
    tab?: RouteTransferTypeValuesType,
    routeId?: string,
    returnTab?: RouteTransferTypeValuesType,
    returnRouteId?: string,
}

/**
 * Single method to generate valid search url
 * Appends search part of url automatically
 */
export default function useGenerateSearchUrl() {
    const {activeLanguages} = useAppSelector((state) => state.page)
    const oldParams = useParams()
    const passengerQueryString = useGeneratePassengersQueryString()
    const {transportTypeRouteTranslation} = useAppSelector((state) => state.form)

    const validateLocale = (locale: string): LocaleType => {
        if (Object.keys(activeLanguages).includes(locale)) {
            return locale as LocaleType
        }
        return 'en'
    }

    const generateSearchUrl = (params: SearchUrlParamsType) => {
        // @ts-ignore
        const path = generatePath("/:locale/:transportTypeRoute/:fromSlug/:toSlug/:date/:returnDate?/:tab?/:routeId?/:returnTab?/:returnRouteId?", {
            ...oldParams,
            ...params,
            locale: validateLocale(params.locale ?? oldParams?.locale ?? 'en'),
            returnDate: params.returnDate ?? oldParams?.returnDate ?? '-',
            returnTab: (params.returnDate && params.returnDate !== '-') ? params.returnTab ?? oldParams?.returnTab : undefined,
            returnRouteId: (params.returnDate && params.returnDate !== '-') ? params.returnRouteId : undefined,
        })

        return `${path}${passengerQueryString}`
    }

    const parseInitialParams = (params: UrlParamsType, dismissTab = false): SearchUrlParamsType => {
        if (dismissTab && !oldParams?.routeId) {
            // generate url without preselected tab
            return {
                ...oldParams,
                ...params,
                tab: "",
                transportTypeRoute: transportTypeRouteTranslation,
                fromSlug: `${params.fromSlug}-${params.fromId}`,
                toSlug: `${params.toSlug}-${params.toId}`,
                date: params.date,
                returnDate: params.returnDate ?? "-",
            }
        }
        return {
            ...oldParams,
            ...params,
            transportTypeRoute: transportTypeRouteTranslation,
            fromSlug: `${params.fromSlug}-${params.fromId}`,
            toSlug: `${params.toSlug}-${params.toId}`,
            date: params.date,
            returnDate: params.returnDate ?? "-",
        }
    }

    const parseInitialParamsAndGenerateSearchUrl = (params: UrlParamsType, dismissTab = false) => {
        return generateSearchUrl(parseInitialParams(params, dismissTab))
    }

    return {
        parseInitialParamsAndGenerateSearchUrl,
        generateSearchUrl,
    }
}