import {ReactElement} from "react";

interface AllResultsLoadedProps {
    allResultsLoaded: ReactElement<any>,
}

function AllResultsLoaded({allResultsLoaded}: AllResultsLoadedProps) {
    return (
        <>
            {allResultsLoaded}
        </>
    );
}

export default AllResultsLoaded;