type parsedTravelierConnectData = {
    id: string,
    // eslint-disable-next-line camelcase
    integration_booking_id?: string,
    integration?: string
}

export default class SuccessfulPurchaseEventModel {
    bookingReference: string

    departureBookingCode?: string

    returnDepartureBookingCode?: string

    singleIntegration?: string

    returnIntegration?: string

    singleTravelierConnectBookingId?: string

    returnTravelierConnectBookingId?: string

    constructor() {
        this.bookingReference = window.ticketCode
        const singleData = this.parseData(window?.singleIntegrationData)
        const returnData = this.parseData(window?.returnIntegrationData)
        if (singleData) {
            this.departureBookingCode = singleData?.integration_booking_id
            this.singleIntegration = singleData?.integration ?? 'N/A'
            this.singleTravelierConnectBookingId = singleData?.id ?? 'N/A'
        }

        if (returnData) {
            this.returnDepartureBookingCode = returnData.integration_booking_id
            this.returnIntegration = returnData?.integration ?? 'N/A'
            this.returnTravelierConnectBookingId = returnData?.id ?? 'N/A'
        }
    }

    parseData = (data?: string): parsedTravelierConnectData|null => {
        if (data) {
            try {
                return JSON.parse(data)
            } catch (e) {
                return null
            }
        }

        return null
    }
}