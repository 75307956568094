import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconAirCondition(props: IconType) {
    const {
        width = '35',
        height = '35',
        viewBox = '0 0 35 35',
        title = 'IconAirCondition',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none" fillRule="evenodd" transform="translate(1 9)">
                    <path
                        d="m3 0h27c.5522847 0 1 .44771525 1 1v14.5c0 1.1045695-.8954305 2-2 2h-25c-1.1045695 0-2-.8954305-2-2v-14.5c0-.55228475.44771525-1 1-1z"
                        fill="#d8d8d8"
                    />
                    <path d="m0 1h33v15h-33z" fill="#fff"/>
                    <path d="m0 8h33v8h-33z" fill="#f5f5f5"/>
                    <path d="m0 8h33v1h-33z" fill="#cbcbcb"/>
                    <path d="m4 15.2h25v1.5h-25z" fill="#b6b6b6"/>
                    <path d="m9 11h15v1.5h-15z" fill="#4f4f4f" transform="matrix(1 0 0 -1 0 23.5)"/>
                </g>
            )}
        />

    );
}

export default IconAirCondition;
