import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconCarPlot(props: IconType) {
    const {
        width = '70',
        height = '85',
        viewBox = '0 0 70 85',
        title = 'IconCarPlot',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none" fillRule="evenodd">
                    <g transform="translate(3 29)">
                        <g fillRule="nonzero">
                            <path
                                d="m33.5135593 50.9148305c-1.0956356 0-1.9334746-.837839-1.9334746-1.9334746v-10.3118644c0-1.0956356.837839-1.9334746 1.9334746-1.9334746s1.9334746.837839 1.9334746 1.9334746v10.3118644c0 1.0956356-.837839 1.9334746-1.9334746 1.9334746z"
                                fill="#fff"
                                transform="matrix(0 1 -1 0 77.338983 10.311865)"
                            />
                            <path
                                d="m47.8099612 37.3805085h-30.0274461c-.6225202 0-1.670227-.837839-1.670227-1.9334746s1.0477068-1.9334746 1.670227-1.9334746h30.0274461c.6225202 0 1.7240452.837839 1.7240452 1.9334746s-1.101525 1.9334746-1.7240452 1.9334746z"
                                fill="#fff"
                            />
                            <path
                                d="m53.1419462 1.93347458 3.8360892 12.24533902h-48.33686444l3.16700814-12.24533902z"
                                fill="#b5b7d1"
                            />
                            <path
                                d="m41.6398282 1.93387791c0 1.38252082-1.055722 3.08423416-2.4362816 3.08423416h-11.3693143c-1.3805596 0-2.4362817.36652812-2.4362817 1.74904895 0 1.38252082 1.0557221 1.84145326 2.4362817 1.84145326h5.6846571l21.8247284 9.11490262.3454345-8.3333469-3.1549547-9.06792424h-10.8942694z"
                                fill="#070c63"
                            />
                            <path
                                d="m48.415934 8.37838983h-32.3710939c-4.9609322 0-6.43447993 2.98141047-6.37746722 5.91257257l-2.9596765 8.9107325h50.91483052l-2.8407472-8.8716504c.0570127-2.9702442-1.4049135-5.95165467-6.3658457-5.95165467z"
                                fill="#fff"
                            />
                            <path
                                d="m54.4656473 14.1788136h-13.0992066c-1.0787797 0-2.2394169 1.2126795-3.2170301 2.5664129-.6517421.9024889-4.9898225 6.4522423-13.0142411 16.6492601l31.5800847.1190727v-17.4689764c0-1.1422023-1.1708272-1.8657693-2.2496069-1.8657693z"
                                fill="#e9eef4"
                            />
                            <path
                                d="m46.2646032 0h-28.0684322c-4.9609322 0-9.13855936 3.67360169-9.72603818 8.50728814l-1.76243644 14.69440676h50.91483052l-1.6318856-14.62995761c-.5874788-4.8981356-4.7651059-8.57173729-9.7260381-8.57173729z"
                                stroke="#070c63"
                                strokeWidth="3.866949"
                            />
                            <path
                                d="m64.4491525 47.1668624-64.4491525-.118981v-7.7338983l5.1559322-16.2884975h50.6903447c5.7352504 5.0585746 8.6028756 10.4880737 8.6028756 16.2884975 0 5.8004237 0 8.4180501 0 7.8528793z"
                                fill="#ff907c"
                            />
                            <path
                                d="m60.0098775 25.1351695h-30.2297023-11.9159322c-1.4851949 0-2.6209322.7795903-2.6209322 1.7990546s1.1357373 1.7990547 2.6209322 1.7990547h7.368183c.7118857 0 1.288983.5770973 1.288983 1.288983 0 .2506964-.0731057.4959521-.2103651.7057342l-8.1934964 12.5226354-2.8742367 4.1059183h48.4016278v-16.4345339z"
                                fill="#ff6144"
                            />
                        </g>
                        <path
                            d="m9.92516949 23.2016949-7.23239164 4.3578238c-1.27110966.7658977-2.04828633 2.1415339-2.04828633 3.6255551 10.63411018 2.4116457 21.26822038 3.6174686 31.90233048 3.6174686 10.6341102 0 21.2682204-1.2058229 31.9023305-3.6174686 0-1.4840212-.7771766-2.8596574-2.0482863-3.6255551l-7.2323916-4.3578238z"
                            stroke="#070c63"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="3.866949"
                        />
                        <path
                            d="m15.4677966 35.7692797c4.1117898 1.0025423 9.7668232 1.5038135 16.9651002 1.5038135 7.1982769 0 12.71443-.5012712 16.5484591-1.5038135l-3.2682602 3.2682602c-1.2086561 1.2086561-2.8479455 1.887672-4.5572433 1.887672h-17.4372796c-1.5784511 0-3.1020197-.5792598-4.2817687-1.6279256z"
                            fill="#fff"
                        />
                        <path
                            d="m.64449152 30.9355932c10.35053548 3.0076271 20.87723048 4.5114407 31.58008478 4.5114407s21.4443797-1.5038136 32.2245762-4.5114407v16.1122882h-63.80466098z"
                            stroke="#070c63"
                            strokeLinejoin="round"
                            strokeWidth="3.866949"
                        />
                        <path
                            d="m37.0478408 47.0478814h-9.070871c-.1724886 0-1.5528173-.6444916-1.5528173-1.9334746 0-1.2889831 1.3803287-1.9334746 1.5528173-1.9334746h9.070871c.1724886 0 1.4731171.6444915 1.4731171 1.9334746 0 1.288983-1.3006285 1.9334746-1.4731171 1.9334746z"
                            fill="#fff"
                            fillRule="nonzero"
                        />
                        <path
                            d="m62.2520223 25.1351695c-13.300646 0-23.2761305 0-29.9264535 0-6.6952086 0-16.7380215 0-30.1284386 0-1.24504045 0-2.1971302-.837839-2.1971302-1.9334746s.95208975-1.9334746 2.1971302-1.9334746h60.0548921c1.2450405 0 2.1971302.837839 2.1971302 1.9334746s-.9520897 1.9334746-2.1971302 1.9334746z"
                            fill="#070c63"
                            fillRule="nonzero"
                        />
                        <path
                            d="m60.5822034 46.4033898v9.0228814c-1.3572964.3824859-2.7536947.5737288-4.1891949.5737288-1.4355003 0-2.8318986-.1912429-4.1891949-.5737288v-9.0228814"
                            fill="#070c63"
                            fillRule="nonzero"
                        />
                        <path
                            d="m13.2765254 46.4033898v9.0228814c-1.4605446.3824859-2.8784259.5737288-4.25364404.5737288s-2.75013339-.1912429-4.12474577-.5737288v-9.0228814"
                            fill="#070c63"
                            fillRule="nonzero"
                        />
                    </g>
                    <g fill="#fff" fillRule="nonzero">
                        <circle cx="12.022881" cy="68.771186" r="1.933475"/>
                        <circle cx="59.070763" cy="68.771186" r="1.933475"/>
                    </g>
                </g>
            )}
        />

    );
}

export default IconCarPlot;
