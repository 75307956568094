import IntermodalRouteWithDetailsType from "@Intermodal/types/IntermodalRouteWithDetails.type";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";

/**
 * Sorts routes based on departure time
 */

type sortRoutesFunction = (
    a: RouteWithAddedPropsAndPricesType | IntermodalRouteWithDetailsType,
    b: RouteWithAddedPropsAndPricesType | IntermodalRouteWithDetailsType
) => number

export function sortByDeparture(): sortRoutesFunction {
    return function sort(a, b) {
        const getDepartureTime = (route) => route?.travelInfo.departureStation.departureTime
        const getArrivalTime = (route) => route?.travelInfo.destinationStation.arrivalTime
        const departureA = getDepartureTime(a)
        const departureB = getDepartureTime(b)

        let comparison = 0;
        if (departureA === departureB) {
            if (getArrivalTime(a) < getArrivalTime(b)) {
                comparison = -1;
            }
            if (getArrivalTime(a) > getArrivalTime(b)) {
                comparison = 1;
            }
        }
        if (departureA > departureB) {
            comparison = 1;
        } else if (departureA < departureB) {
            comparison = -1;
        }

        return comparison
    };
}