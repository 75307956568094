import {useCountVouchersQuery} from "@MyProfile/API/myProfileApi";

export default function useCountValidVouchers() {
    const {
        data,
        isUninitialized,
        isLoading,
        isFetching,
    } = useCountVouchersQuery({showValid: true})

    const validVouchersCountLoading = isUninitialized || isLoading || isFetching

    return {
        validVouchersCount: data?.count,
        validVouchersCountLoading,
    }
}