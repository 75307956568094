import React from 'react'
import createArrayFromXToY from "@Array/createArrayFromXToY";

interface StationDividerProps {
    numberOfStations: number,
    lineClass?: string
}

const StationsDivider = ({numberOfStations, lineClass}: StationDividerProps) => {
    return (
        <>
            {createArrayFromXToY(0, numberOfStations).map(() => (
                <>
                    <span className="gb--connect-circle gb--connect-circle--blue"/>
                    <span className={`gb--connect-line ${lineClass}`}/>
                </>
            ))}
        </>
    )
}

export default StationsDivider