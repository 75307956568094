import React from 'react';
import FaqPortal from "@Generic/FaqPortal";
import TableOfContentsPortal from "@Generic/TableOfContents/TableOfContentsPortal";

const IslandPage = () => {
    return (
        <>
            <FaqPortal />
            {window.tableOfContents && (<TableOfContentsPortal/>)}
        </>
    )
}

export default IslandPage;