import {useAppSelector} from "@Hooks/selector/useAppSelector";
import useSingleTrackingEvent from "@Events/hooks/useSingleTrackingEvent";
import {ClickBannerEventModel} from "@Events/models/ClickBannerEventModel";

interface UseClickBannerEventProps {
    bannerType: 'banner' | 'snippet'
    bannerName: 'transfer' | 'ferry'
    bannerLink: string
    bannerVehicleType: string | null
    bannerPrice: number | null
}

export const useClickBannerEvent = (props: UseClickBannerEventProps) => {
    const {
        bannerType,
        bannerName,
        bannerLink,
        bannerVehicleType,
        bannerPrice,
    } = props

    const {
        departureStationObject,
        destinationStationObject,
        passengers: {
            passengers,
        },
        urlParams: {
            date,
        },

    } = useAppSelector((state) => (state.form))

    return useSingleTrackingEvent(
        "click_banner",
        ClickBannerEventModel({
            departureStationObject,
            destinationStationObject,
            passengers,
            date,
            bannerType,
            bannerName,
            bannerLink,
            bannerVehicleType,
            bannerPrice,
        })
    )
}