import ajaxHandler from "@Ajax/ajaxHandler";
import PassengerType from "@CheckOrder/types/Passenger.type";
import CheckOrderTicketType from "@CheckOrder/types/CheckOrderTicket.type";
import usePrepareAdditionalInfoForPassengerSubmit
    from "@CheckOrder/features/TicketAdditionalInfo/functions/usePrepareAdditionalInfoForPassengerSubmit";

/**
 * Updates passenger values to redux, server authoritative
 */
interface UpdatePassengerGroupAttributes {
    passenger: PassengerType,
    ticket: CheckOrderTicketType,
    passengerGroup: number,
    tripType: 'single' | 'return',
    updateBothTripSegments: boolean /* passenger number in array */
}

export default function useUpdatePassengerGroup() {
    const {prepareAdditionalInfo} = usePrepareAdditionalInfoForPassengerSubmit()

    const updatePassengerGroup = async (props: UpdatePassengerGroupAttributes) => {
        const {
            passenger,
            ticket,
            passengerGroup,
            tripType,
            updateBothTripSegments,
        } = props

        const group = tripType === 'single' ? 'singlePassengerGroupId' : 'returnPassengerGroupId'
        const params = {
            ticketCode: ticket.ticketCode,
            ...passenger,
            // We only care about the name, ignore the ISO and dial code
            additionalInfo: prepareAdditionalInfo(passenger.additionalInfo),
            nationality: passenger?.nationality?.name ?? passenger?.nationality,
            [group]: passengerGroup,
        };

        if (ticket.isReturn && tripType === 'return' && updateBothTripSegments) {
            // Updates both groups if company is same and has same groups
            params.returnPassengerGroupId = passengerGroup;
        }

        const {data: {data}} = await ajaxHandler(`/ajax/ticket-update-passenger-group`, "log", params, 'post')

        return data
    }

    return {
        updatePassengerGroup,
    }
}