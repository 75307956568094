import TicketsContextType from "@MyTickets/context/types/TicketContext.type";
import {modalDataInitialValue} from "@MyTickets/context/constants/modalDataInitialValue";

export const ticketsContextInitialValue: TicketsContextType = {
    isDownloadDocumentsModalOpen: false,
    setIsDownloadDocumentsModalOpen: () => {
    },
    modalData: modalDataInitialValue,
    setModalData: () => {
    },
    isManageBookingModalOpen: false,
    setIsManageBookingModalOpen: () => {
    },
}