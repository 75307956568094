import React from 'react';
import DateSlick from "./DateSlick";

type DateSlickContainerProps = {
    date: string,
    isReturn: boolean,
    isLoading: boolean,
}

const DateSlickContainer = ({date, isReturn, isLoading}: DateSlickContainerProps) => (
    <div className="gb--dateslider">
        {!isLoading && (
            <DateSlick
                date={date}
                isReturn={isReturn}
            />
        )}
    </div>
);

export default React.memo(DateSlickContainer);