import React from 'react';
import RouteIcons from "@Route/components/Amenities/RouteIcons";
import Row from "@Containers/components/Row";
import RouteIconType from "@SearchResults/types/routeIcons/RouteIcon.type";
import routeIconsHashMapResolver from "@Route/functions/routeIconsHashMapResolver";

interface RouteAmenitiesIconProps {
    icon: RouteIconType,
    displayLabel?: boolean,
}

const RouteAmenitiesIcon = ({icon, displayLabel = false}: RouteAmenitiesIconProps) => (
    <Row className="gb--route-amenities_icons">
        <span>
            <RouteIcons tag={routeIconsHashMapResolver(icon?.id)}/>
        </span>
        {displayLabel && (
            <span>
                {icon?.label}
            </span>
        )}
    </Row>
);

export default RouteAmenitiesIcon
