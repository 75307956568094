import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconDiamond(props: IconType) {
    const {
        width = '16',
        height = '16',
        stroke = '#f6f8fd',
        viewBox = '0 0 16 16',
        title = 'IconDiamond',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            stroke={stroke}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none" stroke={stroke}>
                    <g strokeWidth=".5">
                        <path d="m4.0529525.70380323v4.09882835h-3.33238077z" fill="#7ed321"/>
                        <path d="m7.50729547.25-2.95434297 4.03760205v-4.03760205z" fill="#7ed321"/>
                        <path d="m4.12541196 5.30263158 3.09058738 8.85597062-6.52610024-8.85597062z" fill="#71cb10"/>
                        <path
                            d="m15.5353659.70380323v4.09882835h-3.3323808z"
                            fill="#6a9fff"
                            transform="matrix(-1 0 0 1 27.462902 0)"
                        />
                        <path
                            d="m11.1848308.25-2.954343 4.03760205v-4.03760205z"
                            fill="#6a9fff"
                            transform="matrix(-1 0 0 1 19.658024 0)"
                        />
                        <path
                            d="m11.9124209 5.30263158 3.0905874 8.85597062-6.52610022-8.85597062z"
                            fill="#4a79e2"
                            transform="matrix(-1 0 0 1 23.767496 0)"
                        />
                    </g>
                    <path
                        d="m11.4402831 5.22763158-3.45212238 9.88314772-3.4490552-9.88314772z"
                        fill="#f59223"
                        strokeWidth=".35"
                    />
                    <path d="m7.98973042.296354 3.35215428 4.58127758h-6.7043086z" fill="#f5a623" strokeWidth=".35"/>
                </g>
            )}
        />

    );
}

export default IconDiamond;
