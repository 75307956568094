import {SeatRowType} from "@CheckOrder/components/MainSection/SeatPicker/types/SeatRow.type";
import Seat from "@CheckOrder/components/MainSection/SeatPicker/components/Seat";
import React from "react";
import Column from "@Containers/components/Column";

type CabinProps = {
    seatRow: SeatRowType,
    isReturn: boolean
}

function Cabin({seatRow, isReturn}: CabinProps) {
    return (
        <Column className="gb--seat-picker-cabin">
            <span className="gb--seat-picker-seat-label">
                {seatRow.label}
            </span>
            <div className="gb--seat-picker-cabin-seats">
                {seatRow.seats.map((seat, index) => (
                    <Seat
                        key={index}
                        seat={seat}
                        isReturn={isReturn}
                    />
                ))}
            </div>
        </Column>
    );
}

export default Cabin;