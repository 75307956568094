import React from 'react';
import IconDevices from "@Icons/FilledOutline/IconDevices";
import IconBusStation from "@Icons/FilledOutline/IconBusStation";
import IconPaxWithLuggage from "@Icons/FilledOutline/IconPaxWithLuggage";
import IconTransportMediaSeats from "@Icons/FilledOutline/IconTransportMediaSeats";

const HeroVectorSmall = () => {
    return (
        <div className="hero__icons">
            <IconDevices/>
            <IconPaxWithLuggage/>
            <IconBusStation/>
            <div/>
            <IconTransportMediaSeats/>
        </div>
    );
}

export default HeroVectorSmall;