import {useEffect, useRef, useState} from "react";
import useLocale from "@Hooks/selector/useLocale";
import onYearChangeGetter
    from "@CheckOrder/features/TicketAdditionalInfo/features/dateAdditionalField/functions/onYearChangeGetter";
import onKeyPressGetterDateInput
    from "@CheckOrder/features/TicketAdditionalInfo/features/dateAdditionalField/functions/onKeyPressGetterDateInput";
import onMonthChangeGetter
    from "@CheckOrder/features/TicketAdditionalInfo/features/dateAdditionalField/functions/onMonthChangeGetter";
import onDayChangeGetter
    from "@CheckOrder/features/TicketAdditionalInfo/features/dateAdditionalField/functions/onDayChangeGetter";
import DateInputFieldType
    from "@CheckOrder/features/TicketAdditionalInfo/features/dateAdditionalField/types/DateInputField.type";
import getDateFieldStateInitializer
    from "@CheckOrder/features/TicketAdditionalInfo/features/dateAdditionalField/functions/getDateFieldStateInitializer";

export default function useDateInputFields(onClick: (value: string) => void, value: string | null): DateInputFieldType[] {
    const locale = useLocale()
    const {fieldStateInitializer} = getDateFieldStateInitializer(value)

    const [day, setDay] = useState(fieldStateInitializer("D"))
    const [month, setMonth] = useState(fieldStateInitializer("M"))
    const [year, setYear] = useState(fieldStateInitializer("Y"))

    useEffect(() => {
        if (year || month || day) {
            onClick(`${year}-${month}-${day}`)
        }
    }, [day, month, year]);

    const dayRef = useRef<HTMLInputElement>(null)
    const monthRef = useRef<HTMLInputElement>(null)
    const yearRef = useRef<HTMLInputElement>(null)

    const {getOnYearChange} = onYearChangeGetter({year, setYear})
    const {getOnKeyPress: getOnYearKeyPress} = onKeyPressGetterDateInput({
        type: "year",
        currentValue: year,
        currentRef: yearRef,
    })

    const {getOnMonthChange} = onMonthChangeGetter({month, setMonth})
    const {getOnKeyPress: getOnMonthKeyPress} = onKeyPressGetterDateInput({
        type: "month",
        currentValue: month,
        currentRef: monthRef,
    })

    const {getOnDayChange} = onDayChangeGetter({day, setDay})
    const {getOnKeyPress: getOnDayKeyPress} = onKeyPressGetterDateInput({
        type: "day",
        currentValue: day,
        currentRef: dayRef,
    })

    // for the americans, construct MM/DD/YYYY input
    if (locale === "en-us") {
        const onYearChange = getOnYearChange({})
        const onKeyPressYear = getOnYearKeyPress({previousRef: dayRef})

        const onDayChange = getOnDayChange({nextRef: yearRef, nextFieldOnChange: onYearChange})
        const onKeyPressDay = getOnDayKeyPress({previousRef: monthRef, nextRef: yearRef})

        const onMonthChange = getOnMonthChange({nextRef: dayRef, nextFieldOnChange: onDayChange})
        const onKeyPressMonth = getOnMonthKeyPress({nextRef: dayRef})

        return [
            {
                type: "month",
                value: month,
                onChange: onMonthChange,
                onKeyPress: onKeyPressMonth,
                inputRef: monthRef,
                className: "month-input",
            },
            {
                type: "day",
                value: day,
                onChange: onDayChange,
                onKeyPress: onKeyPressDay,
                inputRef: dayRef,
                className: "day-input",
            },
            {
                type: "year",
                value: year,
                onChange: onYearChange,
                onKeyPress: onKeyPressYear,
                inputRef: yearRef,
                className: "year-input",
            },
        ]
    }

    const onYearChange = getOnYearChange({})
    const onKeyPressYear = getOnYearKeyPress({previousRef: monthRef})

    const onMonthChange = getOnMonthChange({nextRef: yearRef, nextFieldOnChange: onYearChange})
    const onKeyPressMonth = getOnMonthKeyPress({nextRef: yearRef, previousRef: dayRef})

    const onDayChange = getOnDayChange({nextRef: monthRef, nextFieldOnChange: onMonthChange})
    const onKeyPressDay = getOnDayKeyPress({nextRef: monthRef})

    return [
        {
            type: "day",
            value: day,
            onChange: onDayChange,
            onKeyPress: onKeyPressDay,
            inputRef: dayRef,
            className: "day-input",
        },
        {
            type: "month",
            value: month,
            onChange: onMonthChange,
            onKeyPress: onKeyPressMonth,
            inputRef: monthRef,
            className: "month-input",
        },
        {
            type: "year",
            value: year,
            onChange: onYearChange,
            onKeyPress: onKeyPressYear,
            inputRef: yearRef,
            className: "year-input",
        },
    ]
}