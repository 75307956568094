import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconCoffeeBreak(props: IconType) {
    const {
        width = '35',
        height = '35',
        viewBox = '0 0 35 35',
        title = 'IconCoffeeBreak',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none" fillRule="evenodd" transform="translate(1.5 9)">
                    <path
                        d="m4.5 0h16c.5522847 0 1 .44771525 1 1v10c0 3.8659932-3.1340068 7-7 7h-4c-3.86599325 0-7-3.1340068-7-7v-10c0-.55228475.44771525-1 1-1z"
                        fill="#fff"
                    />
                    <path
                        d="m7.5 4h10v7.0588235c0 1.6243669-1.3168096 2.9411765-2.9411765 2.9411765h-4.117647c-1.62436694 0-2.9411765-1.3168096-2.9411765-2.9411765z"
                        fill="#707eff"
                    />
                    <path
                        d="m9.5 6h6v3.94117647c0 1.13705683-.9217667 2.05882353-2.0588235 2.05882353h-1.882353c-1.1370568 0-2.0588235-.9217667-2.0588235-2.05882353z"
                        stroke="#c1c7ff"
                        strokeWidth=".5"
                    />
                    <path
                        d="m31.0281924 12c-.5238117-3.2088.3730208-8-2.533044-8s-2.9060648 4.44448864-.62751 5.42637945c2.2785549.98189085 3.6843657 5.78242055 3.160554 2.57362055z"
                        stroke="#f1f1f1"
                        transform="matrix(-1 0 0 1 57.367662 0)"
                    />
                    <path
                        d="m16.5 1.47058233h13.000024c0 1.68627845-.3333413 3.19608434-1.000024 4.52941767-1 2-1.9136496 8-1.9136496 9.7208801v2.7791199h-7.5283375c0-.6682134 0-1.5852668 0-2.7511601 0-1.7488399-.5580129-7.7488399-1.5580129-9.7488399-.6666667-1.33333333-1-2.84313922-1-4.52941767z"
                        fill="#f1f1f1"
                    />
                    <path
                        d="m4 11c2.90606481 0 2.32512152-1.790861 2.32512152-4s.58094329-4-2.32512152-4-4 1.790861-4 4 1.09393519 4 4 4z"
                        stroke="#fff"
                        strokeWidth="1.5"
                    />
                    <rect fill="#f3f3f3" height="1" rx=".5" width="13" x="16.5" y="18"/>
                    <rect fill="#b0b1b1" height="1" rx=".5" width="11" x="17.5" y="19"/>
                    <rect fill="#a3a3a3" height="1" rx=".5" width="9" x="18.5" y="17"/>
                    <g fill="#714621" transform="translate(19.260175 4.244227)">
                        <path
                            d="m2.96885576 5.87010958c.49091978 0 .14456514-1.23925661.49818155-2.48137627.3536164-1.24211966.11689778-2.49984442-.374022-2.49984442s-1.31523753 1.18464667-1.31523753 2.41194612c0 1.22729944.7001582 2.56927457 1.19107798 2.56927457z"
                            transform="matrix(.81915204 .57357644 -.57357644 .81915204 2.427967 -.941529)"
                        />
                        <path
                            d="m3.4891182 7.49157591c.750197 0 2.31356274-1.21822197 2.31356274-2.44552141 0-1.22729945-1.17857951-2.70384376-1.92877651-2.70384376-.75019699 0-.21338297.97442059-.61437722 2.30258841-.40099426 1.32816783-.520606 2.84677676.22959099 2.84677676z"
                            transform="matrix(.81915204 .57357644 -.57357644 .81915204 3.61091 -1.618556)"
                        />
                    </g>
                </g>
            )}
        />

    );
}

export default IconCoffeeBreak;
