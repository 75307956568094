import React from 'react';
import IconChevron from "@Icons/IconChevron";
import useGoBack from "@Routing/hooks/useGoBack";
import IconTimes from "@Icons/IconTimes";

interface BackButtonProps {
    backClick?: (any?) => any,
    icon?: 'times' | 'back'
}

const BackButton = ({backClick, icon = 'back'}: BackButtonProps) => {
    const {goBack: defaultBackClick} = useGoBack()
    const goBack = backClick ? () => backClick() : () => defaultBackClick()

    return (
        <button onClick={() => goBack()} type="submit">
            {icon === 'back' ? (
                <IconChevron
                    chevronOrientation="left"
                    height="3rem"
                    width="3rem"
                />
            ) : (
                <IconTimes
                    height="3rem"
                    width="3rem"
                />
            )}
        </button>
    )
}

export default BackButton;