import PassengerCountType from "@Form/types/PassengerCount.type";

/**
 * Returns accommodation banner href & image url
 * @param accommodation {object}
 * @param passengers {object}
 * @param currency {string}
 * @param date {string}
 * @param returnDate {string}
 * @param iconPath {string}
 * @returns {{url, bannerUrl}}
 */
export default function getAccommodationMap(
    {lat, lon, url},
    passengers: PassengerCountType,
    currency: string,
    date: string,
    returnDate: string,
    iconPath: string
) {
    let width = "913";
    let height = "245";

    if (window.innerWidth < 360) {
        width = "320";
        height = "140";
    } else if (window.innerWidth < 400) {
        width = "360";
        height = "140";
    } else if (window.innerWidth < 500) {
        width = "414";
        height = "140";
    } else if (window.innerWidth < 600) {
        width = "600";
        height = "200";
    }
    const {
        passengersChildren: children,
        passengers: total,
    } = passengers

    const adults = total - children

    let queryObj;
    queryObj = {
        aid: 'getbybus-search',
        lat,
        lng: lon,
        width,
        height,
        openmenu: "null",
        resolution: "1",
        scroll: "disabled",
        maincolor: '4a79e2', // your brand color in hex (without the #)
        markerimage: iconPath,
        adults,
        children,
        hideenlargemap: true,
        hideshare: true,
        hidesettings: true,
        hidemappanels: true,
        hidenavbuttons: true,
        hidenavbar: true,
        hidefilters: true,
        hidebrandlogo: true,
        hidemapattribution: true,
        hideguestpicker: true,
        hidefooter: true,
        currency,
        checkin: date,
    };

    if (returnDate) {
        queryObj.checkout = returnDate
    }

    // Leave this part intact
    let queryParams = '';
    for (const key in queryObj) {
        if (queryParams) {
            queryParams += '&';
        }
        queryParams += `${key}=${encodeURIComponent(queryObj[key])}`;
    }

    let urlQueryObj;
    urlQueryObj = {
        adults,
        children,
        currency,
        checkin: date,
    };

    if (returnDate) {
        urlQueryObj.checkout = returnDate
    }

    let urlQueryParams = '';
    for (const param in urlQueryObj) {
        if (urlQueryParams) {
            urlQueryParams += '&';
        }
        urlQueryParams += `${param}=${encodeURIComponent(urlQueryObj[param])}`;
    }

    return {
        url: `${url}&${urlQueryParams}`,
        bannerUrl: `https://static.stay22.com/embed/gm?${queryParams}`,
    };
}
