import useActiveFiltersCount from "@SearchResults/hooks/useActiveFiltersCount";
import useQuantityAwareTranslation from "@Translation/hooks/useQuantityAwareTranslation";
import Translation from "@Translation/components/Translation";
import IconTimes from "@Icons/IconTimes";
import useClearAllFilters from "@SearchResults/features/sidebar/hooks/useClearAllFilters";

type ActiveFiltersCountProps = {}

function ActiveFiltersCount(props: ActiveFiltersCountProps) {
    const count = useActiveFiltersCount()
    const {clearFilters} = useClearAllFilters()
    const activeFiltersLabel = useQuantityAwareTranslation("search_results.active_filters_count", count)
    if (count === 0) {
        return null;
    }

    return (
        <button
            type="button"
            onClick={clearFilters}
            className="gb--filter-count-notification"
        >
            <span className="gb--emphasize gb--emphasize-default">
                {`${count} ${activeFiltersLabel} | `}
            </span>
            <span className="gb--emphasize">
                <Translation translationKey="search_results.clear_all"/>
            </span>
            <IconTimes height="10" width="15"/>
        </button>
    );
}

export default ActiveFiltersCount;