import {ChangeEvent} from "react";
import {
    monthFirstDigitMaxValue,
    monthSecondDigitMaxValue,
    numberOfDigits
} from "@CheckOrder/features/TicketAdditionalInfo/features/dateAdditionalField/constants/monthDigitsConstraints";
import GetOnChangeFunctionAttributesType
    from "@CheckOrder/features/TicketAdditionalInfo/features/dateAdditionalField/types/GetOnChangeFunctionAttributes.type";

interface OnMonthChangeGetterAttributes {
    month: string,
    setMonth: (value: string) => void,
}

/*
 * returns getOnMonthChange function, which accepts next input field's ref and on change function and
 * updates the month value according to the new value (max value is 12), triggers next input field change if necessary
 */
export default function onMonthChangeGetter({month, setMonth}: OnMonthChangeGetterAttributes) {
    const getOnMonthChange = (attr: GetOnChangeFunctionAttributesType) => (e: ChangeEvent<HTMLInputElement>, overflowCharacter?: string) => {
        const {
            nextRef,
            nextFieldOnChange,
        } = attr

        if (overflowCharacter) {
            if (parseInt(overflowCharacter, 10) > monthFirstDigitMaxValue) {
                setMonth(`0${overflowCharacter}`)
                return;
            }
            setMonth(overflowCharacter)
            return;
        }

        const {target: {value: newValue}} = e
        const characterIsAdded = newValue.length > month?.length
        const newCharacter = characterIsAdded ? newValue[newValue.length - 1] : ""

        if (characterIsAdded && !month) {
            if (parseInt(newCharacter, 10) > monthFirstDigitMaxValue) {
                setMonth(`0${newCharacter}`)
                return;
            }
            setMonth(newValue)
            return;
        }

        if (characterIsAdded && month.length === numberOfDigits) {
            if (!!nextRef && !!nextFieldOnChange) {
                nextRef.current.focus()
                nextFieldOnChange(e, newCharacter)
            }
            return;
        }

        if (characterIsAdded && month.length === 1 && month === "1") {
            if (parseInt(newCharacter, 10) <= monthSecondDigitMaxValue) {
                setMonth(newValue)
                return;
            }
            setMonth(`0${month}`)
            if (!!nextRef && !!nextFieldOnChange) {
                nextRef.current.focus()
                nextFieldOnChange(e, newCharacter)
            }
            return;
        }

        setMonth(newValue)
    }

    return {
        getOnMonthChange,
    }
}