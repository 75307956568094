import React from 'react';
import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "@/types/Icon.type";

interface IconPartProps extends IconType {
    backgroundFill?: string,
    strokeColor?: string,
}

function IconPart(props: IconPartProps) {
    const {
        width = '20',
        height = '20',
        fill = '#4a79e2',
        backgroundFill = '#ffffff',
        strokeColor = '#070c63',
        viewBox = '0 0 20 20',
        title = 'IconPart',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none" transform="matrix(0 1 -1 0 20 0)">
                    <path
                        d="m18.8542825 10.8286986c-.166852 4.3214683-3.6874304 7.8420467-8.0088987 8.0088987-4.77196889.1835373-8.67630705-3.6040044-8.67630705-8.3259177h6.67408232c.91768631 0 1.66852053-.75083422 1.66852053-1.66852053v-6.67408232c4.7219133 0 8.509455 3.90433816 8.3426029 8.65962185z"
                        fill={backgroundFill}
                    />
                    <path
                        d="m8.84315907 11.0122358h-6.50723026c-.2836485 0-.50055617-.2169077-.50055617-.5005562s.21690767-.5005561.50055617-.5005561h6.50723026c.65072302 0 1.16796443-.51724141 1.16796443-1.16796443v-5.00556174c0-.2836485.2169076-.50055617.5005561-.50055617s.5005562.21690767.5005562.50055617v5.00556174c0 1.20133483-.9677419 2.16907673-2.16907673 2.16907673z"
                        fill={fill}
                    />
                    <path
                        d="m10.5116796 19.3548387c-2.30255835 0-4.47163511-.8676307-6.12347048-2.4694104-1.75194661-1.6852058-2.71968854-3.9543938-2.71968854-6.3737487 0-.2836485.21690767-.5005561.50055617-.5005561s.50055618.2169076.50055618.5005561c0 2.1523916.85094549 4.1546163 2.40266963 5.6562848 1.55172414 1.4849833 3.58731924 2.269188 5.73971074 2.185762 4.0711903-.1668521 7.374861-3.4705228 7.5417131-7.5417131.083426-2.1523915-.7007787-4.1879866-2.185762-5.73971074-1.5016685-1.55172414-3.5038932-2.40266963-5.6562848-2.40266963-.2836485 0-.5005561-.21690768-.5005561-.50055618s.2169076-.50055617.5005561-.50055617c2.4193549 0 4.6885429.96774193 6.3737487 2.70300334 1.6852057 1.7519466 2.5528364 4.054505 2.4694104 6.47385988-.166852 4.5884316-3.9043381 8.3259176-8.4927697 8.4927697-.1167965.0166852-.2335929.0166852-.3503894.0166852z"
                        fill={strokeColor}
                    />
                    <path
                        d="m8.84315907.50055617c-4.6051168 0-8.3426029 3.7374861-8.3426029 8.3426029h6.67408232c.91768632 0 1.66852058-.75083426 1.66852058-1.66852058z"
                        fill={fill}
                    />
                    <path
                        d="m.50055617 9.34371524c-.28364849 0-.50055617-.21690768-.50055617-.50055617 0-4.87208009 3.97107898-8.84315907 8.84315907-8.84315907.28364849 0 .50055617.21690768.50055617.50055617 0 .2836485-.21690768.50055618-.50055617.50055618-4.3214683 0-7.84204672 3.52057842-7.84204672 7.84204672 0 .28364849-.21690768.50055617-.50055618.50055617z"
                        fill={strokeColor}
                    />
                </g>
            )}
        />

    );
}

export default IconPart;
