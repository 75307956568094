/**
 * Returns class names type based on passed params
 * @param type {string}
 * @param activeBooleans {object}
 */
import BadgeType from "@Features/badge/types/Badge.type";

export default function classNameTypeAggregator(type: string, activeBooleans: BadgeType) {
    const isActive = (bool, className) => (activeBooleans?.[bool] ? className : '')
    const success = isActive('success', `${type}-green `)
    const info = isActive('info', `${type}-blue `)
    const warning = isActive('warning', `${type}-orange `)
    const error = isActive('error', `${type}-red `)
    const alignLeft = isActive('left', `${type}-left `)
    const alignRight = isActive('right', '')

    return `${type} ${alignLeft}${alignRight}${warning}${success}${info}${error}`
}