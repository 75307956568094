import React from 'react';
import Translation from "@Translation/components/Translation";
import Row from "@Containers/components/Row";
import Link from "@Generic/Link";
import SidebarSectionContainerItem from "../Sections/SidebarSectionContainerItem";
import ReviewScoreLabelWithStar from "@Generic/ReviewScoreLabelWithStar";
import Column from "@Containers/components/Column";
import IconStarFull from "@Icons/IconStarFull";
import LatestReviewType from "@Sidebar/types/LatestReview.type";

interface ReviewProps {
    review: LatestReviewType,
}

function Review({review}: ReviewProps) {
    const {
        rating,
        Name: companyName,
        widgetText,
        reviewLink,
        real_name: username,
    } = review || {}

    const reviewRating = Math.round(rating * 10) / 10;

    return (
        <SidebarSectionContainerItem className="gb--sidebar-latest-review">
            <span className="icon-container">
                <IconStarFull fill="#f5a623"/>
            </span>
            <Column>
                <Row center justify>
                    <span className="gb--emphasize gb--emphasize-default">
                        {companyName}
                    </span>
                    <ReviewScoreLabelWithStar score={reviewRating}/>
                </Row>
                <span>
                    {widgetText}
                </span>
                <Row center justify>
                    <span>
                        <Translation translationKey="c_profile.by"/>
                        {` ${username}`}
                    </span>
                    <Link
                        href={reviewLink}
                        className="reviews-item__more"
                    >
                        <Translation translationKey="sys.see_more"/>
                    </Link>
                </Row>
            </Column>
        </SidebarSectionContainerItem>
    );
}

export default Review;