/**
 * Replaces parts of string with provided variables
 * @param string {string}
 * @param variableReplacements {object}
 * @param exact {boolean}
 * @param repeat {boolean} - if string uses same variable multiple times, loop again as replace only changes first occurrence
 * @return {string}
 */
export default function stringReplacer(string, variableReplacements, exact = false, repeat = false) {
    let updatedString = string
    const entries = Object.entries(variableReplacements)
    const hasEntries = entries.length > 0
    const replace = (entries) => entries.map(((entry) => {
        const toReplace = exact ? entry[0] : `%${entry[0]}%`
        updatedString = updatedString.replace(toReplace, entry[1])
    }))

    if (hasEntries) {
        replace(entries)
        if (repeat) {
            replace(entries)
        }
    }

    return updatedString
}