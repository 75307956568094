import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconConnection(props: IconType) {
    const {
        width = '32',
        height = '16',
        fill = '#fff',
        viewBox = '0 0 32 16',
        title = 'IconConnection',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none" fillRule="evenodd">
                    <rect fill="#070c63" height="1" rx=".245143" width="31.428571" y="8.285714"/>
                    <circle cx="15.178571" cy="8.464286" fill="#fff" fillRule="nonzero" r="4.464286"/>
                    <g transform="translate(11.071429 4)">
                        <circle cx="4.464286" cy="4.464286" fill="#7ed321" fillRule="nonzero" r="3.627232"/>
                        <g stroke="#070c63" strokeWidth=".714286">
                            <circle cx="4.464286" cy="4.464286" r="4.464286"/>
                            <path
                                d="m2.41815476 4.92931548 1.26023066 1.11607142 2.83203125-3.25520833"
                                strokeLinecap="round"
                            />
                        </g>
                    </g>
                    <g fill="#fff" stroke="#070c63" strokeWidth=".714286">
                        <circle cx="25.357143" cy="8.285714" r="2.857143"/>
                        <circle cx="5.357143" cy="8.285714" r="2.857143"/>
                    </g>
                </g>
            )}
        />
    );
}

export default IconConnection;
