import useSingleSelectedRoute from "@Route/hooks/useSingleSelectedRoute";
import isSameCompany from "../functions/isSameCompany";

/**
 * Checks if single selected route company === route company
 * @param route {object}
 */
export default function useIsSameCompany(route) {
    const singleSelectedRoute = useSingleSelectedRoute()

    return isSameCompany({
        selectedSingleRouteCompanyId: singleSelectedRoute?.companyId,
        routeCompanyId: route.companyId,
        isReturn: route.status.isReturn,
    })
}