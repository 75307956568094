import useHandleThirdPartyLogin from "@CheckOrder/hooks/useHandleThirdPartyLogin";
import AppleLoginButton from "@User/components/AppleLoginButton";

function AppleLogin() {
    const {handleThirdPartyLogin} = useHandleThirdPartyLogin('apple')

    return (
        <AppleLoginButton
            className="btn btn-default btn--superG"
            onClick={handleThirdPartyLogin}
        />
    );
}

export default AppleLogin;