import {useAppSelector} from "@Hooks/selector/useAppSelector";
import MobileMenuItem from "@User/components/Mobile/MobileMenuItem";
import MobileMenuIcons from "@User/components/Mobile/MobileMenuIcons";
import React from "react";
import {PagesType} from "@/types/pageSlice/Pages.type";
import UrlObjectType from "@/types/pageSlice/UrlObject.type";
import {DisplayedPagesType} from "@User/types/DisplayedPages.type";
import useCompanyStyle from "@Hooks/selector/useCompanyStyle";

function MobileMenuItemsList() {
    const urlsObject = useAppSelector((state) => (state.page.urlsObject))
    const {company_id: companyId} = useCompanyStyle()
    const displayFAQ = !(companyId === "815")

    return (
        <>
            {Object.entries(urlsObject).map(([key, urlObject]: [PagesType, UrlObjectType]) => {
                if (urlObject.routeName !== window.currentRoute && urlObject.text !== '') {
                    // on GBB don't display FAQ in menu
                    if (!displayFAQ && urlObject.routeName === "gbb_front_faq") {
                        return
                    }
                    return (
                        <MobileMenuItem
                            key={urlObject.text}
                            text={urlObject.text}
                            icon={<MobileMenuIcons tag={key as DisplayedPagesType}/>}
                            openItem={() => {
                                window.location.href = urlObject.url
                            }}
                        />
                    )
                }
                return null
            })}
        </>
    )
}

export default MobileMenuItemsList