import React from 'react';
import FiltersContainer from "@SearchResults/features/sidebar/components/FiltersContainer";
import Sidebar from "@Sidebar/components/Sidebar";
import useHasSearchResults from "@SearchResults/hooks/useHasSearchResults";
import {SearchResultsRtkQueryResponseType} from "@SearchResults/api/types/SearchResultsRtkQueryResponse.type";


type SearchResultsSidebarProps = {
    searchData: SearchResultsRtkQueryResponseType
}
function SearchResultsSidebar(props: SearchResultsSidebarProps) {
    const hasSearchResults = useHasSearchResults(2)
    return (
        <Sidebar className="">
            {hasSearchResults && (
                <FiltersContainer
                    searchData={props.searchData}
                />
            )}
        </Sidebar>
    );
}

export default SearchResultsSidebar;