import React from 'react';
import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconStationLocator(props: IconType) {
    const {
        width = '20',
        height = '20',
        fill = '#9b9b9b',
        viewBox = '0 0 20 20',
        title = 'IconStationLocator',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill={fill}>
                    <path
                        d="m10 4.03389831c2.3050847 0 4.1694915 1.86440677 4.2372881 4.23728813s-2.4745762 5.72881356-3.5762712 7.05084746c-.1694915.2033898-.4067796.3050847-.6610169.3050847-.25423729 0-.49152542-.118644-.6440678-.3050847-1.10169491-1.3220339-3.66101695-4.6779661-3.59322034-7.05084746s1.93220339-4.23728813 4.23728814-4.23728813zm0 2.54237288c-.93607585 0-1.69491525.7588394-1.69491525 1.69491525s.7588394 1.69491525 1.69491525 1.69491525c.9360758 0 1.6949153-.7588394 1.6949153-1.69491525s-.7588395-1.69491525-1.6949153-1.69491525z"
                    />
                    <path
                        d="m16.9830508 1.28813559c-.0169491-.28813559-.2542372-.49152542-.5423728-.47457627-.2881356.01694916-.4915255.25423729-.4745763.54237288l.0338983.69491526c-1.6949153-1.3220339-3.7966102-2.05084746-6-2.05084746-2.62711864 0-5.10169492 1.01694915-6.94915254 2.88135593-2.88135593 2.86440678-3.69491526 7.13559327-2.06779661 10.86440677.08474576.1864407.27118644.3050848.47457627.3050848.06779661 0 .13559322-.0169492.20338983-.0338983.25423729-.1186441.37288136-.4067797.27118644-.661017-1.47457627-3.3559322-.74576271-7.18644067 1.83050847-9.76271186 1.67796611-1.66101695 3.88135594-2.57627119 6.23728814-2.57627119 1.9830508 0 3.8644068.6440678 5.4067797 1.84745763l-.8983051.10169491c-.2711865.03389831-.4745763.2881356-.440678.55932204.0338983.25423729.2542373.44067796.5084746.44067796h.0677966l2.0338983-.23728813c.2711864-.03389831.4576271-.27118644.440678-.54237288z"
                    />
                    <path
                        d="m19 5.86440678c-.1186441-.25423729-.4067797-.37288136-.6779661-.25423729-.2542373.11864407-.3728814.40677966-.2542373.6779661 1.4745763 3.35593221.7627119 7.20338981-1.8305085 9.79661021-1.6779661 1.6440678-3.8813559 2.559322-6.2372881 2.559322-1.98305085 0-3.86440678-.6440678-5.40677966-1.8474576l.89830508-.1016949c.27118644-.0338984.47457627-.2881356.44067797-.5593221-.03389831-.2711864-.28813559-.4745763-.55932203-.4406779l-2.03389831.2372881c-.27118644.0338983-.45762712.2711864-.44067797.5423729l.11864407 1.9152542c.01694916.2711864.23728814.4745763.50847458.4745763h.0338983c.2881356-.0169492.49152543-.2542373.47457628-.5423729l-.03389831-.6949153c1.69491525 1.3050848 3.79661017 2.0338983 6 2.0338983 2.6271186 0 5.1016949-1.0169491 6.9491525-2.8813559 2.8983051-2.8813559 3.6949153-7.16949151 2.0508475-10.91525422z"
                    />
                </g>
            )}
        />

    );
}

export default IconStationLocator;
