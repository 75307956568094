import {SearchResultsRtkQueryResponseType} from "@SearchResults/api/types/SearchResultsRtkQueryResponse.type";
import {RouteTransferTypeValuesType} from "@Route/features/transportType/types/routeTransferType.type";
import CompanyType from "@SearchResults/types/company/Company.type";

type GetUniqueStationsProps = {
    searchData: SearchResultsRtkQueryResponseType,
    tab: RouteTransferTypeValuesType
}
export default function getUniqueCompaniesForActiveTab(props: GetUniqueStationsProps): CompanyType[] {
    const companyIds = props.searchData.result.singleRoutes[props.tab].map((route) => route.companyId);
    const {companies} = props.searchData.result

    return Object.values(companies).filter((company) => (companyIds.includes(company.id.toString())))
}