import React from 'react';
import MobileSorter from "./MobileSorter";
import MobileCurrencySelect from "./MobileCurrencySelect";
import MobileMenuOverlay from "./MobileMenuOverlay";
import MobileLanguageSwitcher from "./MobileLanguageSwitcher";
import MobileMyTicketsMenu from "./MobileMyTicketsMenu";
import MobileLoginActive from "./MobileLoginActive";
import MobilePassengerPicker from "./MobilePassengerPicker";
import MobileDatePicker from "./MobileDatePicker";
import MobileStationInputContainer from "./MobileStationInputContainer";
import MobileFilters from "./MobileFilters";
import {useAppSelector} from "@Hooks/selector/useAppSelector";

function MobileOverlayContainer() {
    const {
        sorterActive,
        currencySelectActive,
        mobileMenuActive,
        mobileLanguagesActive,
        mobileMyTicketsActive,
        mobileLogInActive,
        mobilePassengerPickerActive,
        singleCalendarActive,
        returnCalendarActive,
        departureStationInputActive,
        destinationStationInputActive,
        filtersOverlayActive,
    } = useAppSelector((state) => state?.mobile)

    const mobileOverlays = {
        [`${sorterActive}`]: <MobileSorter/>,
        [`${currencySelectActive}`]: <MobileCurrencySelect/>,
        [`${mobileMenuActive}`]: <MobileMenuOverlay/>,
        [`${mobileLanguagesActive}`]: <MobileLanguageSwitcher/>,
        [`${mobileMyTicketsActive}`]: <MobileMyTicketsMenu/>,
        [`${mobileLogInActive}`]: <MobileLoginActive/>,
        [`${mobilePassengerPickerActive}`]: <MobilePassengerPicker/>,
        [`${singleCalendarActive || returnCalendarActive}`]: <MobileDatePicker/>,
        [`${departureStationInputActive || destinationStationInputActive}`]: <MobileStationInputContainer/>,
        [`${filtersOverlayActive}`]: <MobileFilters/>,
    }

    return mobileOverlays.true
}

export default MobileOverlayContainer;