import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconGbbGuides(props: IconType) {
    const {
        width = '40',
        height = '40',
        viewBox = '0 0 40 40',
        title = 'IconGbbGuides',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none" transform="matrix(-1 0 0 1 40 7)">
                    <path
                        d="m30.4534884 10.3604651h-21.97674421c-1.72674419 0-3.13953489 1.4127907-3.13953489 3.1395349 0 1.7267442 1.4127907 3.1395349 3.13953489 3.1395349h21.97674421"
                        fill="#6a9fff"
                    />
                    <path
                        d="m30.4534884 16.6395349h-24.80232561c-2.60581395 0-4.70930232 2.1034884-4.70930232 4.7093023 0 2.605814 2.10348837 4.7093023 4.70930232 4.7093023h24.80232561"
                        fill="#bee990"
                        stroke="#4a4a4a"
                        strokeWidth=".84375"
                    />
                    <path
                        d="m30.4534884 25.1162791h-28.56976747c-.53372093 0-.94186046-.4081396-.94186046-.9418605s.40813953-.9418605.94186046-.9418605h28.56976747c.5337209 0 .9418604.4081396.9418604.9418605s-.4081395.9418605-.9418604.9418605z"
                        fill="#7ed321"
                    />
                    <path
                        d="m38.6162791 16.6395349h-24.8023256c-2.605814 0-4.70930234 2.1034884-4.70930234 4.7093023 0 2.605814 2.10348834 4.7093023 4.70930234 4.7093023h24.8023256c-2.605814 0-4.7093024-2.1034883-4.7093024-4.7093023 0-2.6058139 2.1034884-4.7093023 4.7093024-4.7093023z"
                        fill="#fff"
                    />
                    <path d="m5.651163 13.813954h9.418605v1.883721h-9.418605z" fill="#4a79e2"/>
                    <path
                        d="m35.4767442 10.3604651h-19.8367347c-1.8183674 0-3.3061225 1.4127907-3.3061225 3.1395349 0 1.7267442 1.4877551 3.1395349 3.3061225 3.1395349h19.8367347-.3306123c-1.8183673 0-3.3061224-1.4127907-3.3061224-3.1395349 0-1.7267442 1.8183673-3.1395349 3.6367347-3.1395349z"
                        fill="#fff"
                    />
                    <path
                        d="m30.4534884.94186047h-23.54651166c-2.60581395 0-4.70930232 2.10348837-4.70930232 4.70930232 0 2.60581395 2.10348837 4.70930231 4.70930232 4.70930231h23.54651166"
                        fill="#fad291"
                    />
                    <path
                        d="m13.5 9.41860465h-10.04651163c-.53372093 0-.94186046-.40813953-.94186046-.94186046s.40813953-.94186047.94186046-.94186047h10.04651163c.5337209 0 .9418605.40813954.9418605.94186047s-.4081396.94186046-.9418605.94186046z"
                        fill="#f5a623"
                    />
                    <path
                        d="m35.4767442.94186047h-20.4069768c-2.6058139 0-4.7093023 2.10348837-4.7093023 4.70930232 0 2.60581395 2.1034884 4.70930231 4.7093023 4.70930231h20.4069768c-2.605814 0-4.7093023-2.10348836-4.7093023-4.70930231 0-2.60581395 2.1034883-4.70930232 4.7093023-4.70930232z"
                        fill="#fff"
                    />
                    <path
                        d="m35.4767442 11.3023256h-28.56976746c-3.10813953 0-5.65116279-2.54302327-5.65116279-5.65116281 0-3.10813953 2.54302326-5.65116279 5.65116279-5.65116279h23.54651166c.5337209 0 .9418604.40813954.9418604.94186047s-.4081395.94186046-.9418604.94186046h-23.54651166c-2.07209302 0-3.76744186 1.69534884-3.76744186 3.76744186s1.69534884 3.76744186 3.76744186 3.76744186h28.56976746c.5337209 0 .9418605.40813954.9418605.94186045 0 .5337209-.4081396.9418605-.9418605.9418605z"
                        fill="#4a4a4a"
                    />
                    <path
                        d="m8.47674419 17.5828306c-2.26046512 0-4.08139535-1.8209303-4.08139535-4.0813954s1.82093023-4.08139533 4.08139535-4.08139533c.53372093 0 .94186046.40813953.94186046.94186043 0 .533721-.40813953.9418605-.94186046.9418605-1.22441861 0-2.19767442.9732558-2.19767442 2.1976744s.97325581 2.1976744 2.19767442 2.1976744c.53372093 0 .94186046.4081396.94186046.9418605s-.40813953.9418605-.94186046.9418605z"
                        fill="#4a4a4a"
                    />
                    <path
                        d="m38.6162791 17.5813953h-8.1627907c-.533721 0-.9418605-.4081395-.9418605-.9418604s.4081395-.9418605.9418605-.9418605h8.1627907c.5337209 0 .9418604.4081396.9418604.9418605s-.4081395.9418604-.9418604.9418604z"
                        fill="#444b54"
                    />
                    <path
                        d="m35.4767442 1.88372093h-20.4069768c-.5337209 0-.9418604-.40813953-.9418604-.94186046s.4081395-.94186047.9418604-.94186047h20.4069768c.5337209 0 .9418605.40813954.9418605.94186047s-.4081396.94186046-.9418605.94186046z"
                        fill="#444b54"
                    />
                    <path
                        d="m20.3630678 15.0428571h-2.9999546c-.3642802 0-.6428474.2785673-.6428474.6428474v4.3285059c0 .342852.3857084.5571344.6642756.342852l1.2214101-.8571299c.1499977-.1071412.342852-.1071412.4928497 0l1.2214101.8571299c.2785672.1928542.6642756 0 .6642756-.342852v-4.3285059c.0214283-.3642801-.257139-.6428474-.6214191-.6428474z"
                        fill="#f03a47"
                    />
                    <path
                        d="m37.7926184 26.761799h-32.26199132c-3.04184489 0-5.53062708-2.4887822-5.53062708-5.5306271s2.48878219-5.530627 5.53062708-5.530627h8.51540992c.522337 0 .9217712.3994341.9217712.9217711s-.3994342.9217712-.9217712.9217712h-8.51540992c-2.02789659 0-3.68708472 1.6591881-3.68708472 3.6870847s1.65918813 3.6870848 3.68708472 3.6870848h32.26199132c.522337 0 .9217712.3994341.9217712.9217711s-.3994342.9217712-.9217712.9217712z"
                        fill="#4a4a4a"
                    />
                </g>
            )}
        />
    );
}

export default IconGbbGuides;
