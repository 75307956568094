import React from 'react';
import IntermodalRouteWithDetailsType from "@Intermodal/types/IntermodalRouteWithDetails.type";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";
import useRoutePassengerGroups from "@Route/hooks/useRoutePassengerGroups";
import GroupPrice from "@Route/components/Price/PassengerGroup/GroupPrice";

interface GroupPricesProps {
    route: IntermodalRouteWithDetailsType | RouteWithAddedPropsAndPricesType,
}

function GroupPrices({route}: GroupPricesProps) {
    const activeGroupPrices = useRoutePassengerGroups(route)
    return (
        <div className="group-prices">
            {activeGroupPrices.map((group) => (
                <GroupPrice
                    key={group.id}
                    group={group}
                />
            ))}
        </div>
    );
}

export default GroupPrices;