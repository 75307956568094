import React from 'react';
import AncillarySummary from "@CheckOrder/features/paymentSummary/features/ancillariesSummary/components/AncillarySummary";
import {useAppSelector} from "@Hooks/selector/useAppSelector";

const AncillariesSummary = () => {
    const {isBuyingPremiumSupport, isBuyingSMSNotification, isBuyingTier1FlexibleCancellation} = useAppSelector((state) => state.checkOrder.selectedServices);
    const {displayPrice: premiumSupportPrice, displayCurrencySymbol: premiumSupportCurrency} = useAppSelector((state) => state.checkOrder.ticket.ancillariesData.premiumSupport);
    const {displayPrice: smsNotificationPrice, displayCurrencySymbol: smsNotificationCurrency} = useAppSelector((state) => state.checkOrder.ticket.ancillariesData.smsNotification);
    const {displayPrice: tier1FlexibleCancellationPrice, displayCurrencySymbol: tier1FlexibleCancellationCurrency} = useAppSelector((state) => state.checkOrder.ticket.ancillariesData.tier1FlexibleCancellation);

    return (
        <>
            {isBuyingPremiumSupport && (
                <AncillarySummary
                    price={premiumSupportPrice}
                    currencySymbol={premiumSupportCurrency}
                    ancillaryNameKey="sys.premium_support"
                />
            )}
            {isBuyingSMSNotification && (
                <AncillarySummary
                    price={smsNotificationPrice}
                    currencySymbol={smsNotificationCurrency}
                    ancillaryNameKey="sys.sms_notification"
                />
            )}
            {isBuyingTier1FlexibleCancellation && (
                <AncillarySummary
                    price={tier1FlexibleCancellationPrice}
                    currencySymbol={tier1FlexibleCancellationCurrency}
                    ancillaryNameKey="sys.tier1_flexible_cancellation"
                />
            )}
        </>
    );
}

export default AncillariesSummary
