import StationInputFieldType from "@Form/types/StationInputField.type";

interface ClickBannerEventModelProps {
    departureStationObject: StationInputFieldType,
    destinationStationObject: StationInputFieldType | null,
    passengers: number,
    date: string | null,
    bannerType: 'banner' | 'snippet'
    bannerName: 'transfer' | 'ferry'
    bannerLink: string
    bannerVehicleType: string | null
    bannerPrice: number | null
}

export const ClickBannerEventModel = (props: ClickBannerEventModelProps) => {
    const {
        departureStationObject,
        destinationStationObject,
        passengers,
        date,
        bannerType,
        bannerName,
        bannerLink,
        bannerVehicleType,
        bannerPrice
    } = props

    const {
        countryCode: departureStationCountryCode,
        label: departureStationLabel,
        id: departureStationId,
    } = departureStationObject || {}

    const {
        countryCode: destinationStationCountryCode,
        label: destinationStationLabel,
        id: destinationStationId,
    } = destinationStationObject || {}

    return {
        from: departureStationLabel,
        fromId: departureStationId,
        fromCountry: departureStationCountryCode,
        to: destinationStationLabel,
        toId: destinationStationId,
        toCountry: destinationStationCountryCode,
        passengers,
        departureDate: date,
        bannerType,
        bannerName,
        bannerLink,
        bannerVehicleType,
        bannerPrice
    }
}