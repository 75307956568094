import React from "react";
import {PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js";
import Translation from "@Translation/components/Translation";
import LoaderButton from "@Generic/Loaders/LoaderButton";
import Notification from "@Features/notification/components/Notification";
import IconExclamationCircle from "@Icons/IconExclamationCircle";
import CheckOrderPaymentProviderNotification
    from "@CheckOrder/components/MainSection/CheckOrderPaymentProviderNotification";
import Column from "@Containers/components/Column";
import useStripeCheckoutForm from "@Features/stripe/hooks/useStripeCheckoutForm";

export default function CheckoutForm() {
    const stripe = useStripe();
    const elements = useElements();

    const {
        showNotificationNewProvider,
        paymentElementOptions,
        priceString,
        handleSubmit,
        message,
        isLoading,
        companyId,
    } = useStripeCheckoutForm()

    return (
        <div className="gb--card">
            <Column center justify className="gb--column-start gb--card-title">
                <Translation translationKey="check_order.payment_method"/>
                {showNotificationNewProvider && (
                    <div className="gb--card-payment">
                        <br/>
                        <CheckOrderPaymentProviderNotification/>
                        <hr/>
                    </div>
                )}
            </Column>
            <form id="payment-form" onSubmit={handleSubmit}>
                <PaymentElement id="payment-element" options={paymentElementOptions}/>
                {/* Show any error or success messages */}
                {message && (
                    // display generic notification from our end
                    // (check if there's type/ status of msg returned)
                    <Notification
                        notificationType="red"
                        notificationIcon={<IconExclamationCircle fill="#f03a47"/>}
                        isFullWidth
                        isRow
                    >
                        {message}
                    </Notification>
                )}
                <button
                    type="submit"
                    disabled={isLoading || !stripe || !elements}
                    id="submit"
                    className="btn btn--default btn--green btn--mobile btn--confirm"
                >
                    {isLoading ? (
                        <LoaderButton/>
                    ) : (
                        <span>
                            <Translation translationKey="t_summary.confirm_&_pay"/>
                            &nbsp;
                            {priceString}
                        </span>
                    )}

                </button>
            </form>
        </div>
    );
}