import ApiProviderType from "@SearchResults/types/ApiProvider/ApiProvider.type";

/**
 * Gets total count of possible api routes to check
 * @param availableApiProviders
 */
export const getTotalApiProvidersCount = (availableApiProviders: ApiProviderType[]): number => (
    availableApiProviders.map((provider: ApiProviderType) => (
        provider.apiStationCombinations
    )).flat(Infinity).length
)