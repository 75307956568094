import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconMinivanTab(props: IconType) {
    const {
        width = '35',
        height = '35',
        // fill: '#fff',
        viewBox = '0 0 35 35',
        title = 'IconMinivanTab',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none" fillRule="evenodd">
                    <g transform="translate(1 1)">
                        <g fillRule="nonzero">
                            <path
                                d="m16.7669049 31.4862929c-.5981151 0-1.0554972-.3742218-1.0554972-.8635886v-4.6058059c0-.4893669.4573821-.8635886 1.0554972-.8635886s1.0554972.3742217 1.0554972.8635886v4.6058059c0 .4893668-.4573821.8635886-1.0554972.8635886z"
                                fill="#fff"
                                transform="matrix(0 1 -1 0 45.086706 11.552896)"
                            />
                            <path
                                d="m23.1524089 24.8014774h-13.41179267c-.27804936 0-.74600877-.4573821-.74600877-1.0554972s.46795941-1.0554972.74600877-1.0554972h13.41179267c.2780494 0 .7700468.4573821.7700468 1.0554972s-.4919974 1.0554972-.7700468 1.0554972z"
                                fill="#fff"
                            />
                            <path
                                d="m26.0473753.84764543 2.7344828 6.04339797h-24.92892433l2.56203437-6.2631579z"
                                fill="#b5b7d1"
                            />
                            <path
                                d="m21.2760783 1.6720003c0 .89568233-.5314467 1.99815728-1.2264155 1.99815728h-5.7232725c-.6949688 0-1.2264155.23745954-1.2264155 1.13314188 0 .89568233.5314467 1.1930071 1.2264155 1.1930071h2.8616362l11.1671064.89473684-2.9601626-6.2631579h-4.118892z"
                                fill="#070c63"
                            />
                            <path
                                d="m25.2162347 5.99630656h-18.20364564c-2.78974359 0-3.59082383 1.71157331-3.55876315 4.01160304l-1.66435223 6.9920904h28.63157892l-1.5974732-6.9614233c.0320607-2.33069683-.8176011-4.04227014-3.6073447-4.04227014z"
                                fill="#fff"
                            />
                            <path
                                d="m23.4515235.84764543h-14.72676903c-2.60287081 0-4.79476202 2.55-5.10299673 5.90526316l-.9247041 10.20000001h26.71367416l-.8562075-10.15526317c-.3082347-3.4-2.5001259-5.95-5.1029968-5.95z"
                                stroke="#070c63"
                                strokeWidth="2.110994"
                            />
                            <path
                                d="m30.5843225 30.1323137-28.78628674-.083293v-5.4141302l2.30290294-11.4027937h23.6359034c1.8983203 3.5412647 2.8474804 7.342196 2.8474804 11.4027937s0 5.8930721 0 5.4974232z"
                                fill="#777aaa"
                            />
                        </g>
                        <path
                            d="m3.06055243 13.9788615-.87961306 4.0752117c-.0631812.292716-.09504074.5913231-.09504074.8907801v.7023425c4.72907024.9930403 9.46847397 1.4895604 14.21821137 1.4895604 4.7497373 0 9.5098081-.4965201 14.2802125-1.4895604v-.3729058c0-.51557-.094432-1.02678-.2786219-1.5083259l-1.4485553-3.7871026z"
                            stroke="#070c63"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2.110994"
                        />
                        <g fill="#fff" transform="translate(6.678419 23.263158)">
                            <path
                                d="m2.02832605 2.87690642c1.83653558.2441777 4.36236263.36626655 7.57748115.36626655 3.2151185 0 5.6789145-.12208885 7.391388-.36626655l-1.5154432.82636985c-.5167101.28176161-1.0958472.42940116-1.6843869.42940116h-8.3705321c-.53157947 0-1.05624877-.12045422-1.53459742-.35231477z"
                            />
                            <path
                                d="m1.28431126 1.43845321c2.01692901.2441777 4.79085504.36626654 8.32177811.36626654 3.53092303 0 6.23672503-.12208884 8.11740593-.36626654l-1.7898217.88869623c-.4862548.24143898-1.0217864.36707478-1.5646828.36707478h-9.4975392c-.48921886 0-.97306112-.10202617-1.42062615-.29956259z"
                            />
                            <path
                                d="m0 0c2.33207417.2441777 5.53942615.36626654 9.62205594.36626654 4.08262976 0 7.21121336-.12208884 9.38575066-.36626654l-2.2594909.97029188c-.4386027.18834891-.9109448.28547913-1.3882787.28547913h-11.42155147c-.42883475 0-.8540567-.07839811-1.25469578-.23132808z"
                            />
                        </g>
                        <path
                            d="m2.05489801 19.7783934c4.55221983.8568723 9.21855069 1.2853085 13.99899269 1.2853085s9.6135857-.4284362 14.4994312-1.2853085v10.3601108h-28.49842389z"
                            stroke="#070c63"
                            strokeLinejoin="round"
                            strokeWidth="2.110994"
                        />
                        <path
                            d="m18.3454957 30.0789633h-4.0515148c-.0770422 0-.6935676-.3518324-.6935676-1.0554972s.6165254-1.0554972.6935676-1.0554972h4.0515148c.0770422 0 .6579694.3518324.6579694 1.0554972s-.5809272 1.0554972-.6579694 1.0554972z"
                            fill="#fff"
                            fillRule="nonzero"
                        />
                        <path
                            d="m31.013198 14.6373102c-6.6262196 0-11.5958843 0-14.9089941 0-3.3354712 0-8.33867801 0-15.00962044 0-.62026396 0-1.09458346-.4573822-1.09458346-1.0554972 0-.5981151.4743195-1.0554972 1.09458346-1.0554972h29.91861454c.6202639 0 1.0945834.4573821 1.0945834 1.0554972 0 .598115-.4743195 1.0554972-1.0945834 1.0554972z"
                            fill="#070c63"
                            fillRule="nonzero"
                        />
                        <path
                            d="m28.8571453 29.7271309v4.0263158h-3.7422172v-4.0263158"
                            fill="#070c63"
                            fillRule="nonzero"
                        />
                        <path
                            d="m7.72801084 29.7271309v4.0263158h-3.74221729v-4.0263158"
                            fill="#070c63"
                            fillRule="nonzero"
                        />
                    </g>
                    <g fill="#fff" fillRule="nonzero">
                        <circle cx="6.368421" cy="23.368421" r="1"/>
                        <circle cx="28.736842" cy="23.368421" r="1"/>
                    </g>
                </g>
            )}
        />

    );
}

export default IconMinivanTab;
