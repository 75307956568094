import {useEffect, useState} from "react";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";

/**
 * Hook for updating initial values in slider, keeps slider values in sync on result updates
 */

interface UseFilterSliderValueAttributes {
    onChange: (any?) => any,
    boundValue: "Price" | "Rating" | "Duration" | "RouteArrivalTime" | "RouteDepartureTime",
    defaultValue?: "min" | "max",
    override?: number,
    invert?: boolean,
}

export default function useFilterSliderValue(params: UseFilterSliderValueAttributes) {
    const {
        onChange,
        boundValue,
        defaultValue = "max",
        override = undefined,
        invert = false,
    } = params

    const dispatch = useAppDispatch()
    const filterState = useAppSelector((state) => state?.filter)

    const max = filterState?.filterBounds[`max${boundValue}`]
    const min = filterState?.filterBounds[`min${boundValue}`]
    const filterValue = filterState[`filter${boundValue}`]

    const updateValue = ({target: {value}}) => {
        dispatch(onChange(Math.abs(value)))
    }

    const resetFilterValue = () => {
        dispatch(onChange(null))
    }

    const getDefaultValue = () => {
        if (invert) {
            return defaultValue === "max" ? min : max
        }
        return defaultValue === "max" ? max : min
    }

    const defaultUsedValue = override ?? getDefaultValue()

    const value = invert ? -filterValue : filterValue

    // this seems redundant but sliders throw loads of events
    // and we don't want to spam redux store with each of those updates
    // traditional throttle/debounce would NOT work as slider would not update
    // we still require them to be controlled for updating via clear & route updates
    const [filterValueState, setFilterValueState] = useState(value ?? 0)

    /**
     * @param {event: {target: {value}}}
     */
    const setValueState = ({target: {value}}) => setFilterValueState(value)

    // Consider filter reset if the value is equal to default
    useEffect(() => {
        if (Math.abs(parseInt(`${filterValue}`, 10)) === defaultUsedValue) {
            resetFilterValue()
        }
    }, [filterValue])

    // Update state on redux changes
    useEffect(() => {
        setFilterValueState(value ?? getDefaultValue())
    }, [value])

    // Reset value to default when min or max changes,
    useEffect(() => {
        setFilterValueState(value ?? getDefaultValue())
    }, [min, max])

    return {
        setValueState,
        value: filterValueState,
        updateValue,
        max: invert ? -min : max,
        min: invert ? -max : min,
        resetFilterValue,
    }
}