import {useParams} from "react-router";
import useIsMobile from "@Hooks/selector/useIsMobile";
import useGenerateSearchUrl from "@SearchResults/hooks/useGenerateSearchUrl";
import {TabsDisplayedOnSearchResults} from "@Tabs/types/TabsDisplayedOnSearchResults";
import useNavigateWithStoredState from "@Routing/hooks/useNavigateWithStoredState";

export default function useNavigateWithGeneratedSearchParams() {
    const params = useParams()
    const isMobile = useIsMobile()
    const {generateSearchUrl} = useGenerateSearchUrl()
    const {navigateWithStoredState} = useNavigateWithStoredState()

    const navigateWithSearchParams = (tab: TabsDisplayedOnSearchResults, routeId: string, state: object, isReturn: boolean) => {
        const path = generateSearchUrl({
            routeId: isReturn ? params.routeId : routeId,
            tab: isReturn ? <TabsDisplayedOnSearchResults>params.tab : tab,
            // TODO: yeah, just don't do this
            // eslint-disable-next-line no-nested-ternary
            returnTab: (!isMobile && !isReturn) ? tab : isReturn ? tab : <TabsDisplayedOnSearchResults>params.returnTab,
            returnRouteId: isReturn ? routeId : params.returnRouteId,
            returnDate: params.returnDate ?? "-",
        })

        navigateWithStoredState(path, state)
    }

    return {
        navigateWithSearchParams,
    }
}
