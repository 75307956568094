import React, {useEffect} from 'react'
import AppOverview from "@Pages/mobileApp/features/appOverview/AppOverview";
import Form from "@Form/components/Form";
import MobileFormOverlayContainer from "@Form/components/Mobile/MobileFormOverlayContainer";
import useIsMobile from "@Hooks/selector/useIsMobile";
import DownloadApp from "@Pages/mobileApp/components/DownloadApp";
import MobileAppReviews from "@Pages/mobileApp/components/MobileAppReviews";
import MTicketSection from "@Pages/mobileApp/components/MTicketSection";
import BookingsSection from "@Pages/mobileApp/features/bookingSection/components/BookingsSection";
import ErrorBoundary from "@Errors/components/ErrorBoundary";
import {useTranslation} from "@Translation/hooks/useTranslation";

const MobileAppLandingPage = () => {
    const isMobile = useIsMobile()

    const {
        meta_title: metaTitle,
        meta_description: metaDescription,
    } = useTranslation("mobile_app")

    useEffect(() => {
        document.title = metaTitle
        document.head.getElementsByTagName("meta")[2].content = metaDescription
    }, [])

    return (
        <ErrorBoundary shouldReload={false}>
            <div className="mobile-app-landing-page">
                {!isMobile ? (
                    <Form/>
                ) : (
                    <MobileFormOverlayContainer/>
                )}
                <AppOverview/>
                <MTicketSection/>
                <BookingsSection/>
                <MobileAppReviews/>
                <DownloadApp/>
            </div>
        </ErrorBoundary>
    )
}

export default MobileAppLandingPage