import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconToilet(props: IconType) {
    const {
        width = '35',
        height = '35',
        viewBox = '0 0 35 35',
        title = 'IconToilet',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none" fillRule="evenodd" transform="translate(3.5)">
                    <path d="m1.5 1h6v15h-5c-.55228475 0-1-.4477153-1-1z" fill="#f3f3f3"/>
                    <path d="m.5 0h8v1h-8z" fill="#f3f3f3"/>
                    <path d="m13.5 14h14c.5522847 0 1 .4477153 1 1h-16c0-.5522847.4477153-1 1-1z" fill="#844950"/>
                    <path d="m7.5 15h21v1h-21z" fill="#877a7b"/>
                    <path d="m8.5 16h17v17h-17z" fill="#d8d8d8"/>
                    <g fill="#f3f3f3">
                        <path d="m2.5 16h26v2h-24c-1.1045695 0-2-.8954305-2-2z"/>
                        <path d="m12.5 18h16c.2834518 4.6666667-2.3832148 7-8 7s-8.2834518-2.3333333-8-7z"/>
                        <path d="m6.5 32h21c.5522847 0 1 .4477153 1 1v1h-21c-.55228475 0-1-.4477153-1-1z"/>
                        <path d="m-1 23.5h18v2c0 .5522847-.4477153 1-1 1h-17z" transform="matrix(0 1 -1 0 33 17)"/>
                        <path
                            d="m5.5 20c.42625392 0 .83174463-.0888979 1.19894334-.2491648 1.0601072-.4626926 1.80105666-1.5202349 1.80105666-2.7508352 0-1.6568542-1.34314575-3-3-3s-3 1.3431458-3 3 1.34314575 3 3 3z"
                        />
                    </g>
                </g>
            )}
        />

    );
}

export default IconToilet;
