import React from 'react';
import Column from "@Containers/components/Column";
import Time from "@DateTime/components/Time";
import Row from "@Containers/components/Row";
import VehicleTypeIcon from "@Generic/VehicleTypeIcon";
import Date from "@DateTime/components/Date";
import IconMinibus from "@Icons/RouteIcons/IconMinibus";
import Translation from "@Translation/components/Translation";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";
import RouteTravelDuration from "@Route/components/Connection/RouteTravelDuration";
import RouteConnection from "@Route/components/Connection/RouteConnection";
import {calculateTripDuration} from "@Route/functions/calculateTripDuration";
import {mightBeMinibus} from "@Route/features/transportType/functions/mightBeMinibus";
import RouteType from "@Route/types/Route.type";

interface RouteTravelDetailsProps {
    route: RouteWithAddedPropsAndPricesType | RouteType,
    companyLetterCode: string,
}

function RouteTravelDetails({route, companyLetterCode}: RouteTravelDetailsProps) {
    const {
        travelInfo: {
            departureStation: {
                name: departureStationName,
                departureTime,
                departureDate,
            },
            destinationStation: {
                name: destinationStationName,
                arrivalTime,
                arrivalDate,
            },
        },
        type: {
            vehicleType,
            routeTransferType,
        },
    } = route || {};

    const mightBeMinibusTranslationKey = "search_results.might_be_minibus_title";

    // This is usually calculated on routes-list level however on check-order
    // route is not ran through addRouteProperties method
    const calculatedDuration = calculateTripDuration(
        `${departureDate} ${departureTime}`,
        `${arrivalDate} ${arrivalTime}`
    );

    return (
        <Row className="route-travel-details">
            <Column className="travel-time">
                <span>
                    <Date
                        date={departureDate}
                        format="dd MMM | "
                        className="route--date gb--emphasize-info"
                    />
                    <Time
                        time={departureTime}
                        className="gb--emphasize-default"
                    />
                </span>
                <RouteTravelDuration
                    travelDuration={calculatedDuration}
                    customClassName="gb--label gb--label-green"
                />
                <span>
                    <Date
                        date={arrivalDate}
                        format="dd MMM | "
                        className="route--date gb--emphasize-info"
                    />
                    <Time
                        time={arrivalTime}
                        className="gb--emphasize-default"
                    />
                </span>
            </Column>
            <RouteConnection
                vertical
                route={route}
                showVehicleIcon={false}
            />
            <Column className="travel-stations">
                <span className="gb--emphasize-default gb--truncate">
                    {departureStationName}
                </span>
                <span className="gb--label">
                    <span className="gb--label gb--label-default">
                        <VehicleTypeIcon
                            transportType={routeTransferType}
                            width="12"
                            height="12"
                            fill="#070c63"
                            className="vehicle-icon"
                        />
                        {companyLetterCode}
                    </span>
                    {mightBeMinibus(vehicleType, routeTransferType) && (
                        <span className="gb--label gb--label-default">
                            <IconMinibus
                                width="14"
                                height="14"
                            />
                            <Translation translationKey={mightBeMinibusTranslationKey}/>
                        </span>
                    )}
                </span>
                <span className="gb--emphasize-default gb--truncate">
                    {destinationStationName}
                </span>
            </Column>
        </Row>
    );
}

export default RouteTravelDetails;