import React from 'react';
import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconComments(props: IconType) {
    const {
        width = '20',
        height = '20',
        fill = '#9b9b9b',
        viewBox = '0 0 20 20',
        title = 'IconComments',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <path
                    d="m1.84422348 14.5c.33735796-.0434028.66347065-.0976562.96709281-.1736111 1.13577178-.2821181 2.1928267-.7486979 3.12618371-1.3888889.62973485.1085069 1.29320549.1736111 1.97916667.1736111 4.37440813 0 7.91666663-2.484809 7.91666663-5.55555554 0-3.07074653-3.5422585-5.55555556-7.91666663-5.55555556-4.37440815 0-7.91666667 2.48480903-7.91666667 5.55555556 0 1.7578125 1.16950758 3.33116324 2.99124053 4.35112844-.38233901.9006077-.87713068 1.3237848-1.31569602 1.8012153-.12369792.141059-.26988637.2712674-.22490531.4774306.0337358.1844618.19116951.3146701.35984849.3146701zm2.68702652-2.5.37977431-.9459459-1.05251737-.6306307c-1.38888888-.83333331-2.19184027-2.027027-2.19184027-3.26576574 0-2.34234234 2.86458333-4.32432433 6.25-4.32432433 3.38541663 0 6.25000003 1.98198199 6.25000003 4.32432433s-2.8645834 4.32432434-6.25000003 4.32432434c-.56423611 0-1.11762153-.0563063-1.66015625-.1576577l-.57508681-.1126126-.47743055.3490991c-.21701389.1576577-.44487848.3040541-.67274306.4391892zm14.0975765 5.5082986c.042517-.2114731-.0956632-.345035-.212585-.489727-.4145408-.489727-.8822279-.9238032-1.2436225-1.8476064 1.7219388-1.0462349 2.827381-2.6489778 2.827381-4.4631937 0-1.85873654-1.1692177-3.50600006-2.9655612-4.54110483.1594388.54537778.2444728 1.11301588.2444728 1.69178415 0 1.99229844-.9778912 3.82877468-2.7529762 5.18665408-1.647534 1.2465777-3.8052721 1.9366476-6.09056125 1.9366476-.3082483 0-.62712585-.0222603-.93537415-.0445206 1.32865646.912673 3.0931122 1.4691809 5.0170068 1.4691809.6483844 0 1.2755102-.0667809 1.8707483-.1780825.8822279.6566794 1.8813776 1.1352762 2.954932 1.4246603.2869898.0779111.5952381.1335619.9141156.1780826.170068.0222603.3295068-.1224318.3720238-.3227746z"
                />
            )}
        />

    );
}

export default IconComments;
