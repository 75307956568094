import React from 'react';
import Translation from "@Translation/components/Translation";
import stripTags from "@Form/functions/stripTags";

interface PopularDestinationsNoticeProps {
    stationLabel?: string,
}

const PopularDestinationsNotice = ({stationLabel}) => (
    <div className="search__select--popular-destinations">
        <Translation translationKey="succ.popular_destinations_from"/>
        {' '}
        {stripTags(stationLabel)}
    </div>
);

export default PopularDestinationsNotice;