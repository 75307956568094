import React, {useEffect, useLayoutEffect} from 'react';
import useGetCheckOrderData from "@CheckOrder/hooks/useGetCheckOrderData";
import useCheckOrderLandingEvent from "@CheckOrder/hooks/useCheckOrderLandingEvent";
import FullScreenLoader from "@Generic/Loaders/FullScreenLoader";
import {googleTagManagerSelectItemEvent} from "@CheckOrder/functions/googleTagManager";
import {Elements} from "@stripe/react-stripe-js";
import CheckoutForm from "@Features/stripe/components/CheckoutForm";
import IconPoweredByStripe from "@Icons/IconPoweredByStripe";
import useLocale from "@Hooks/selector/useLocale";
import PaymentInfoSection from "@CheckOrder/components/MainSection/PaymentSummary/PaymentInfoSection";
import useCheckOrderStripe from "@CheckOrder/hooks/useCheckOrderStripe";
import CheckOrderSidebar from "@CheckOrder/components/Sidebar/CheckOrderSidebar";
import CheckOrderMainSection from "@CheckOrder/components/MainSection/CheckOrderMainSection";
import CheckOrderModals from "@CheckOrder/components/Modals/CheckOrderModals";
import CheckOrderTitleContainer from "@CheckOrder/components/Title/CheckOrderTitleContainer";
import dismissPlaceholders from "@Features/domElements/functions/dismissPlaceholders";

function CheckOrder() {
    useLayoutEffect(() => {
        dismissPlaceholders()
    }, [])
    useCheckOrderLandingEvent()
    const {
        ticket,
        stripeClientSecret,
        stripePublicApiKey,
    } = useGetCheckOrderData();

    const setLocal = useLocale();

    const {
        stripePromise,
        options,
    } = useCheckOrderStripe(stripePublicApiKey, stripeClientSecret, setLocal)

    useEffect(() => {
        if (ticket?.id) {
            googleTagManagerSelectItemEvent(ticket);
        }
    }, [ticket?.id])

    if (!ticket) {
        return (
            <>
                <CheckOrderModals/>
                <FullScreenLoader/>
            </>
        );
    }

    return (
        <div className="gb--check-order">
            <CheckOrderTitleContainer/>
            <div className="gb-container gb--main-content__container check-order__container">
                {stripeClientSecret ? (
                    <div className="check-order__main">
                        <PaymentInfoSection/>
                        <Elements options={options} stripe={stripePromise}>
                            <CheckoutForm/>
                        </Elements>
                        <IconPoweredByStripe width="125"/>
                    </div>
                ) : (
                    <CheckOrderMainSection
                        seatPickerData={ticket.seatPickerData}
                        hasAddons={!!ticket.addons.singleRouteAddons || !!ticket.addons.returnRouteAddons}
                        isIntermodal={ticket.isIntermodal}
                        additionalTicketFields={ticket.additionalInfoFields}
                    />
                )}
                <CheckOrderModals/>
                <CheckOrderSidebar/>
            </div>
        </div>
    )
}

export default CheckOrder
