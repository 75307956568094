import React from 'react';
import useRemoveVoucher from "@CheckOrder/hooks/useRemoveVoucher";
import Button from "@Generic/Button";
import Translation from "@Translation/components/Translation";

function RemoveVoucherButton() {
    const removeVoucher = useRemoveVoucher()

    return (
        <Button
            onClick={removeVoucher}
            className="orange"
            additionalClasses="btn--inline"
        >
            <Translation translationKey="sys.remove"/>
            &nbsp;
            <Translation translationKey="check_order.voucher.voucher"/>
        </Button>
    );
}

export default RemoveVoucherButton;