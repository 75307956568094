/**
 * Returns seconds in formatted hours, minutes, seconds string object
 */
export default function useTimeUnits(timeInSeconds: number) {
    const seconds = timeInSeconds % 60;
    const minutes = Math.floor(timeInSeconds / 60) % 60;
    const hours = Math.floor(timeInSeconds / 3600) % 60;
    const addLeadingZero = (value): string => (value < 10 ? `0${value}` : value.toString())

    return {
        hours: addLeadingZero(hours),
        minutes: addLeadingZero(minutes),
        seconds: addLeadingZero(seconds),
    }
}