import RouteType from "@Route/types/Route.type";
import {isRoutePastSaleBlockTime} from "@Route/functions/isPastRouteSaleBlockTime";

/**
 * Checks if route is active and adds relevant status text
 */
export default function checkIsRouteActive(route: RouteType, date: string):RouteType {
    return route?.status?.status === "open" && isRoutePastSaleBlockTime({route, date})
        ? {...route, status: {status: "closed"}} as RouteType
        : route;
}