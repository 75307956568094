import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import {useLazyRemoveAddonQuery} from "@CheckOrder/api/checkOrderApi";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {setCheckOrderData, setPassengerDataUpdating} from "@CheckOrder/reducers/checkOrderSlice";
import VehicleAddonType from "@CheckOrder/types/VehicleAddon.type";

export default function useHandleJadrolinijaVehicleRemoval(vehicle: VehicleAddonType) {
    const dispatch = useAppDispatch()
    const [removeAddonQuery] = useLazyRemoveAddonQuery()
    const {ticketCode} = useAppSelector((state) => state.checkOrder.ticket)

    const removeVehicle = () => {
        dispatch(setPassengerDataUpdating(true))
        removeAddonQuery({
            passengerId: vehicle.passengerId,
            ticketCode,
        }).then((response) => {
            dispatch(setCheckOrderData(response.data.checkOrder))
            dispatch(setPassengerDataUpdating(false))
        })
    }

    return {
        removeVehicle,
    }
}