import React from 'react';
import {useTranslation} from "@Translation/hooks/useTranslation";
import Link from "@Generic/Link";
import Countries from "@Pages/homepage/components/Countries";
import Translation from "@Translation/components/Translation";
import useIsMobile from "@Hooks/selector/useIsMobile";
import {useAppSelector} from "@Hooks/selector/useAppSelector";

function CountriesSection() {
    const {countriesUrl} = useAppSelector((state) => state?.page.homepageData)
    const constantlyExpanding = useTranslation('home_page.constantly_expanding')
    const isMobile = useIsMobile()
    const setClassName = () => (!isMobile ? 'gb-container' : 'horizontal-scroll')

    return (
        <section className="countries-network">
            <div className={setClassName()}>
                <h2>
                    <Translation translationKey="home_page.bus_network"/>
                </h2>
                <br/>
                <div
                    className="countries-network__description"
                    dangerouslySetInnerHTML={{__html: constantlyExpanding}}
                />
                <br/>
                <Link href={countriesUrl}>
                    <Translation translationKey="home_page.show_full_list"/>
                </Link>
                <br/>
                <br/>
                <br/>
                <Countries/>
            </div>
        </section>
    );
}

export default CountriesSection;