import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconWifi(props: IconType) {
    const {
        width = '35',
        height = '35',
        viewBox = '0 0 35 35',
        title = 'IconWifi',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none" fillRule="evenodd" transform="translate(0 1)">
                    <g transform="translate(0 18)">
                        <path
                            d="m1 1h17v12h-17c-.55228475 0-1-.4477153-1-1v-10c0-.55228475.44771525-1 1-1z"
                            fill="#69678c"
                        />
                        <g fill="#595777">
                            <path d="m17 1h17c.5522847 0 1 .44771525 1 1v10c0 .5522847-.4477153 1-1 1h-17z"/>
                            <path d="m22 0h11v1h-11z"/>
                        </g>
                    </g>
                    <path
                        d="m27.5 9c.8284271 0 1.5.67157288 1.5 1.5v8.5h-3v-8.5c0-.82842712.6715729-1.5 1.5-1.5z"
                        fill="#595777"
                    />
                    <rect fill="#565475" height="1" rx=".2" width="3" x="26" y="6"/>
                    <rect fill="#565475" height="1" rx=".2" width="5" x="25" y="4"/>
                    <g fill="#f8c939">
                        <rect height="1" rx=".2" width="5" x="10" y="24"/>
                        <rect height="1" rx=".2" width="4" x="4" y="24"/>
                        <rect height="3" rx=".2" width="2" x="5" y="23"/>
                    </g>
                    <rect fill="#565475" height="1" rx=".2" width="7" x="24" y="2"/>
                    <rect fill="#565475" height="1" rx=".2" width="9" x="23"/>
                </g>
            )}
        />

    );
}

export default IconWifi;
