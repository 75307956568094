import convertToServerTime from "@DateTime/functions/convertToServerTime";
import isPast from "date-fns/isPast";
import isAfter from "date-fns/isAfter";
import setHours from "date-fns/setHours";

/*
 * Set slide to be disabled if date is before today or before single
 */

export default function isSlideDisabled(date: Date, singleLegDate: string, searchedDate: string, isReturn: boolean) {
    const parsedSlideDate = setHours(convertToServerTime(date), 22)

    const isInactive = isPast(parsedSlideDate)
    const isBeforeSingle = isAfter(new Date(singleLegDate), parsedSlideDate)

    if (isReturn) {
        return isInactive || isBeforeSingle
    }

    return isInactive
}