import React from 'react';
import Translation from "@Translation/components/Translation";
import Column from "@Containers/components/Column";
import DiscountType from "@Route/types/prices/discounts/Discount.type";
import DiscountIcon from "@Route/components/Price/Discount/DiscountIcon";

interface InactiveDiscountProps {
    discount: DiscountType,
    style: object,
}

const InactiveDiscount = ({discount, style}: InactiveDiscountProps) => (
    <>
        <DiscountIcon
            style={style}
            discountAmount={discount?.discountPercentage}
        />
        <Column className="gb--discount">
            <span className="gb--emphasize-default">
                <Translation translationKey="sys.discount_not_applicable"/>
            </span>
            {/* eslint-disable-next-line react/no-danger */}
            <div
                className="gb--emphasize-inactive"
                dangerouslySetInnerHTML={{
                    __html: discount.description,
                }}
            />
        </Column>
    </>
);

export default InactiveDiscount;