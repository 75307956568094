import React from 'react';
import useIsMobile from "@Hooks/selector/useIsMobile";
import MobileStationInputButton from "@Form/components/Mobile/MobileStationInputButton";
import StationInputFields from "@Form/components/StationInputFields";
import HeroBackground from "./HeroBackground";
import DatePickerInputFields from "./DatePicker/DatePickerInputFields";
import PassengerPickerButton from "./PassengerPicker/PassengerPickerButton";
import SearchButton from "./SearchButton";
import FormContainer from "./FormContainer";
import useFormMethods from "../hooks/useFormMethods";

function Form() {
    const {
        mobileOverlayActive,
        setClassName,
    } = useFormMethods()
    const isMobile = useIsMobile()

    return (
        <div className={setClassName()}>
            <HeroBackground/>
            <FormContainer>
                {!isMobile ? (
                    <StationInputFields/>
                ) : (
                    <>
                        <MobileStationInputButton direction="departure"/>
                        <MobileStationInputButton direction="destination"/>
                    </>
                )}
                {!mobileOverlayActive && (
                    <DatePickerInputFields/>
                )}
                <PassengerPickerButton/>
                <SearchButton/>
            </FormContainer>
        </div>
    );
}

export default Form
