import React, {ReactNode, useState} from 'react';
import Row from "@Containers/components/Row";
import IconInfo from "@Icons/IconInfo";
import Column from "@Containers/components/Column";

interface NotificationExpandableProps {
    children?: ReactNode,
    icon?: any,
    title: any,
    descriptionText?: string,
    setInitialExpand?: boolean,
    toggleIcon?: ReactNode,
}

function NotificationExpandable(props: NotificationExpandableProps) {
    const {
        icon,
        title,
        descriptionText,
        setInitialExpand = false,
        children,
        toggleIcon,
    } = props

    const [expand, setExpanded] = useState(setInitialExpand)
    const expandNotification = () => setExpanded(!expand)
    const fillStyle = !expand ? '#070c63' : '#7ed321'

    return (
        <div className="notification notification--expandable" onClick={expandNotification}>
            <Row center className="notification__message">
                {icon}
                <Column>
                    <span className="gb--emphasize-warning">{title}</span>
                    {expand && (
                        <>
                            {children}
                            <small>
                                {descriptionText}
                            </small>
                        </>
                    )}
                </Column>
            </Row>
            {toggleIcon || (
                <IconInfo
                    fill={fillStyle}
                />
            )}
        </div>
    );
}

export default NotificationExpandable;