import React from 'react';
import Routing from '@Routing/components/Routing';
import useSetPageLayout from "@Hooks/dispatch/useSetPageLayout";
import useLoginListener from "@User/hooks/useLoginListener";
import setDefaultCurrencyIfNoneSet from "@Currency/functions/setDefaultCurrencyIfNoneSet"

export default function App() {
    useLoginListener()
    useSetPageLayout()
    setDefaultCurrencyIfNoneSet()

    return (
        <Routing/>
    );
}
