import {useLocation, useOutletContext, useParams} from "react-router";
import {SearchResponseType} from "@SearchResults/types/SearchResponse.type";
import useFindSelectedRouteFromSearchParams
    from "@SearchResults/features/additionalInfo/hooks/useFindSelectedRouteFromSearchParams";
import useNavigateBackIfProvidedRouteDoesNotExist
    from "@SearchResults/features/additionalInfo/hooks/useNavigateBackIfProvidedRouteDoesNotExist";

export default function useAdditionalInfoProps(isReturn: boolean) {
    const context: SearchResponseType = useOutletContext()
    const location = useLocation()
    const {data} = context
    const singleSelectedRoute = useFindSelectedRouteFromSearchParams(context, isReturn)
    useNavigateBackIfProvidedRouteDoesNotExist(singleSelectedRoute, context)

    return {
        singleSelectedRoute,
        params: data.result.params,
        companies: data.result.companies,
        passengerGroups: data.result.passengerGroups,
        routeIcons: data.result.routeIcons,
    }
}