import useCompanies from "./useCompanies";

/**
 * Returns single company with companyId from companies object in search results
 */
export default function useCompany() {
    const companies = useCompanies()
    const getCompany = (companyId) => companies?.[companyId]

    return {
        getCompany,
    }
}