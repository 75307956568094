import React from 'react';
import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "@/types/Icon.type";

const IconSun = (props: IconType) => {
    const {
        width = '20',
        height = '20',
        fill = '#fff',
        viewBox = '0 0 20 20',
        title = 'IconSun',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...props}
            svgIconPath={(

                <g fill="none" fillRule="evenodd" stroke="#070c63">
                    <circle cx="8" cy="8" r="3.8" strokeWidth="1.2"/>
                    <g fill="#070c63" fillRule="nonzero" strokeWidth=".5">
                        <path
                            d="m8 2c.138 0 .25-.112.25-.25v-1.5c0-.138-.112-.25-.25-.25s-.25.112-.25.25v1.5c0 .138.112.25.25.25z"/>
                        <path
                            d="m8 14c-.138 0-.25.112-.25.25v1.5c0 .138.112.25.25.25s.25-.112.25-.25v-1.5c0-.138-.112-.25-.25-.25z"/>
                        <path
                            d="m2 8c0-.138-.112-.25-.25-.25h-1.5c-.138 0-.25.112-.25.25s.112.25.25.25h1.5c.138 0 .25-.112.25-.25z"/>
                        <path
                            d="m15.75 7.75h-1.5c-.138 0-.25.112-.25.25s.112.25.25.25h1.5c.138 0 .25-.112.25-.25s-.112-.25-.25-.25z"/>
                        <path
                            d="m5.427 2.29875c.03925.0975.133.1565.232.1565.03125 0 .06275-.006.0935-.01825.128-.05175.19-.1975.13825-.3255l-.37475-.927c-.05175-.128-.19725-.1895-.3255-.13825-.128.05175-.19.1975-.13825.3255z"/>
                        <path
                            d="m10.573 13.70125c-.05175-.128-.197-.18925-.3255-.13825-.128.05175-.19.1975-.13825.3255l.37475.927c.03925.0975.133.1565.232.1565.03125 0 .06275-.006.0935-.01825.128-.05175.19-.1975.13825-.3255z"/>
                        <path
                            d="m2.437 10.24775c-.05175-.128-.197-.1895-.3255-.13825l-.927.37475c-.128.05175-.19.1975-.13825.3255.03925.0975.133.1565.232.1565.03125 0 .06275-.006.0935-.01825l.927-.37475c.128-.052.19-.19775.13825-.3255z"/>
                        <path
                            d="m10.24775 2.437c.03075.01225.0625.01825.0935.01825.099 0 .19275-.059.232-.1565l.37475-.927c.05175-.128-.01025-.27375-.13825-.3255-.128-.0515-.27375.01025-.3255.13825l-.37475.927c-.05175.128.01025.27375.13825.3255z"/>
                        <path
                            d="m5.75225 13.563c-.12825-.05175-.27375.01-.3255.13825l-.37475.927c-.05175.128.01025.27375.13825.3255.03075.01225.0625.01825.0935.01825.099 0 .19275-.059.232-.1565l.37475-.927c.05175-.128-.01025-.27375-.13825-.3255z"/>
                        <path
                            d="m14.81575 10.48425-.927-.37475c-.12825-.05175-.27375.01025-.3255.13825s.01025.27375.13825.3255l.927.37475c.03075.01225.0625.01825.0935.01825.099 0 .19275-.059.232-.1565.0515-.128-.01025-.27375-.13825-.3255z"/>
                        <path
                            d="m2.437 5.24775c-.05175-.128-.197-.1895-.3255-.13825l-.927.37475c-.128.05175-.19.1975-.13825.3255.03925.0975.133.1565.232.1565.03125 0 .06275-.006.0935-.01825l.927-.37475c.128-.052.19-.19775.13825-.3255z"
                            transform="matrix(1 0 0 -1 0 11.0576)"/>
                        <path
                            d="m14.81575 5.48425-.927-.37475c-.12825-.05175-.27375.01025-.3255.13825s.01025.27375.13825.3255l.927.37475c.03075.01225.0625.01825.0935.01825.099 0 .19275-.059.232-.1565.0515-.128-.01025-.27375-.13825-.3255z"
                            transform="matrix(1 0 0 -1 0 11.0574)"/>
                        <path
                            d="m3.40375 3.75725c.04875.04875.11275.07325.17675.07325s.128-.0245.17675-.07325c.09775-.09775.09775-.25575 0-.3535l-1.06075-1.06075c-.09775-.09775-.25575-.09775-.3535 0s-.09775.25575 0 .3535z"/>
                        <path
                            d="m12.59625 12.24275c-.09775-.09775-.25575-.09775-.3535 0s-.09775.25575 0 .3535l1.06075 1.06075c.04875.04875.11275.07325.17675.07325s.128-.0245.17675-.07325c.09775-.09775.09775-.25575 0-.3535z"/>
                        <path
                            d="m3.40375 12.24275-1.06075 1.06075c-.09775.09775-.09775.25575 0 .3535.04875.04875.11275.07325.17675.07325s.128-.0245.17675-.07325l1.06075-1.06075c.09775-.09775.09775-.25575 0-.3535s-.25575-.09775-.3535 0z"/>
                        <path
                            d="m12.4195 3.8305c.064 0 .128-.0245.17675-.07325l1.06075-1.06075c.09775-.09775.09775-.25575 0-.3535s-.25575-.09775-.3535 0l-1.06075 1.06075c-.09775.09775-.09775.25575 0 .3535.04875.049.11275.07325.17675.07325z"/>
                    </g>
                </g>
            )}
        />
    );
};

export default IconSun;
