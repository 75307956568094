import React from 'react';
import {useTranslation} from "@Translation/hooks/useTranslation";

interface TicketTypeInfoProps {
    isReturn: boolean,
    isIntermodal: boolean
}

function TicketTypeInfo({isReturn, isIntermodal}: TicketTypeInfoProps) {
    const {
        outbound: singleLabel,
        round_trip: returnLabel,
        multi_ticket_itinerary: intermodalLabel,
    } = useTranslation('sys')

    const ticketTypeLabel = () => {
        if (isReturn) {
            return returnLabel
        }
        if (isIntermodal) {
            return intermodalLabel
        }
        return singleLabel
    }

    return (
        <span className={`gb--badge gb--badge-left gb--badge-${isIntermodal ? "orange" : "blue"}`}>
            {ticketTypeLabel()}
        </span>
    );
}

export default TicketTypeInfo;