import GbtVehicleSnippet from "@Features/getByTransfer/components/GbtVehicleSnippet";
import TransfersVehicleType
    from "@SearchResults/features/banners/features/getByTransferBanner/types/TransfersVehicle.type";

interface GbtVehiclesProps {
    vehicles: TransfersVehicleType[],
    additionalClassName?: string,
}

function GbtVehicles({vehicles, additionalClassName = ""}: GbtVehiclesProps) {
    return (
        <div className={`gb--cards gb--cards-transfer ${additionalClassName}`}>
            {vehicles.map((vehicleData) => (
                <GbtVehicleSnippet
                    vehicleData={vehicleData}
                    key={vehicleData.vehicleGroupName}
                />
            ))}
        </div>
    )
}

export default GbtVehicles