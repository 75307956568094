import React from 'react';
import IconChevronDown from "@Icons/IconChevronDown";
import LanguageFlag from "./LanguageFlag";
import ClickOutsideAwareComponent from "@Features/clickOutsideAwareComponent/components/ClickOutsideAwareComponent";
import LanguageDropdown from "./LanguageDropdown";
import {useToggle} from "@Hooks/dispatch/useToggle";
import useSearchResults from "@SearchResults/hooks/useSearchResults";
import useIsMobile from "@Hooks/selector/useIsMobile";
import useIsTablet from "@Hooks/selector/useIsTablet";
import useLocale from "@Hooks/selector/useLocale";
import {LocaleType} from "@/types/Locale.type";

function LanguageSwitcher() {
    const showLanguageDropdown = useToggle()
    const searchResults = useSearchResults()
    const isMobile = useIsMobile()
    const isTablet = useIsTablet()
    const locale = useLocale() as LocaleType;
    const linkTranslations = searchResults?.data?.routeTranslations ?? JSON.parse(window.routeTranslations)

    const isNotDesktop = isMobile || isTablet

    return (
        <li className={isNotDesktop ? 'gb--menu gb--menu-mobile gb--column' : 'gb--menu__item'}>
            <button
                onClick={showLanguageDropdown.onChange}
                className="gb--emphasize-default"
                type="submit"
            >
                {!isNotDesktop && (
                    <>
                        <LanguageFlag locale={locale}/>
                        <IconChevronDown
                            style={{verticalAlign: 'middle', marginLeft: '.5rem'}}
                            stroke="#070c63"
                        />
                    </>
                )}
            </button>
            {(isNotDesktop || showLanguageDropdown.value) && (
                <ClickOutsideAwareComponent onClickOutside={() => showLanguageDropdown.set(false)}>
                    {linkTranslations && (
                        <LanguageDropdown
                            linkTranslations={linkTranslations}
                        />
                    )}
                </ClickOutsideAwareComponent>
            )}
        </li>
    );
}

export default LanguageSwitcher
