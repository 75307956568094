import React from 'react';
import Translation from "@Translation/components/Translation";
import IconDiscountLabel from "@Icons/IconDiscountLabel";
import Row from "@Containers/components/Row";
import useIsMobile from "@Hooks/selector/useIsMobile";

interface DiscountBadgeProps {
    isActive: boolean,
    discountAmount: number,
}

const DiscountBadge = ({discountAmount, isActive}: DiscountBadgeProps) => {
    const isMobile = useIsMobile()
    const style = !isActive ? {opacity: 0.3} : {}

    return (
        <Row
            center
            className={`${isMobile ? '' : 'route--discount__badge'}`}
            style={style}
        >
            {!isMobile ? (
                <>
                    <span>
                        <Translation translationKey="search_results.web_discount"/>
                    </span>
                    <IconDiscountLabel
                        discountText={`${discountAmount}%`}
                    />
                </>
            ) : (
                <span className="gb--badge gb--badge-right gb--badge-red">
                    {`${discountAmount}% off`}
                </span>
            )}
        </Row>
    )
};

export default DiscountBadge;