import React from 'react';
import IconMinivanOutline from "@Icons/IconMinivanOuline";

function PrivateBusLabel() {
    return (
        <span className="gb--badge gb--badge-left gb--badge-blue">
            <IconMinivanOutline/>
            <span>Private bus</span>
        </span>
    )
}

export default PrivateBusLabel;