import {CompanyScoreType} from "@SearchResults/features/sidebar/features/companyFilters/types/CompanyScore.type";

const poor: CompanyScoreType = {
    label: 'poor',
    from: 0,
    to: 4,
};

const fair: CompanyScoreType = {
    label: 'fair',
    from: 4,
    to: 6,
};

const good: CompanyScoreType = {
    label: 'good',
    from: 6,
    to: 8,
};

const excellent: CompanyScoreType = {
    label: 'excellent',
    from: 8,
    to: 10,
};

export {poor, fair, good, excellent};

export const CompanyScoresArray = [
    excellent,
    good,
    fair,
    poor,
]