import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconPaxWithLuggage(props: IconType) {
    const {
        width = '85',
        height = '85',
        viewBox = '0 0 85 85',
        title = 'IconPaxWithLuggage',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none" transform="translate(13 26)">
                    <path
                        d="m48 12c3.3075 0 6-2.6925 6-6s-2.6925-6-6-6-6 2.6925-6 6 2.6925 6 6 6"
                        fill="#fff"
                        stroke="#070c63"
                        strokeWidth="3"
                    />
                    <path
                        d="m1.012609 42.968875h31.819195v2.999971h-31.819195z"
                        fill="#070c63"
                        transform="matrix(.70710678 -.70710678 .70710678 .70710678 -26.487833 24.990434)"
                    />
                    <path
                        d="m27.0442823 38.436122c-.8051567 0-1.6087285-.3141467-2.2252757-.9473742-1.2473589-1.2763235-1.2600386-3.358573-.0316991-4.6513441l14.6608265-15.4195016c1.2299245-1.2977053 3.2364765-1.3092185 4.4822505-.0328949 1.247359 1.2763235 1.2600386 3.358573.0316991 4.651344l-14.6608265 15.4195017c-.621302.6546092-1.4375534.9802691-2.2569748.9802691z"
                        fill="#fff"
                    />
                    <path
                        d="m32.3914678 43.8833404c-2.7777778 0-5-2.6246071-5-5.905366v-21.5403923c0-1.115458.7222222-1.9684553 1.6666666-1.9684553.9444445 0 1.6666667.8529973 1.6666667 1.9684553v21.5403923c0 1.115458.7222222 1.9684553 1.6666667 1.9684553.9444444 0 1.9935576-.576311 1.9935576-1.691769s.7222222-1.9684553 1.6666667-1.9684553c.9444444 0 1.6666666.8529973 1.6666666 1.9684553 0 3.2807589-2.5491132 5.6286797-5.3268909 5.6286797z"
                        fill="#070c63"
                        transform="matrix(.70710678 .70710678 -.70710678 .70710678 30.165826 -14.474279)"
                    />
                    <path
                        d="m12.75 38c.9603972 0 1.9195294.3669209 2.6518398 1.0981602.4217717.4217716 2.2603348 2.227767 3.4667426 3.4119337l-12.85451409 12.8545142c-.54355814-.5565135-1.24486039-1.2723284-1.98061851-2.0174836l-.53864679-.5444734-.36160426-.3642118-.53503858-.5365995c-.73419344-.7341934-1.09923372-1.696798-1.0981644-2.6591583.00107352-.9576299.36768089-1.9140378 1.0981644-2.6445213l7.49922383-7.4992244c.7330866-.7320149 1.6922188-1.0989358 2.652616-1.0989358z"
                        fill="#f5a623"
                        stroke="#070c63"
                        strokeWidth="3"
                    />
                    <circle cx="7.5" cy="56" fill="#070c63" r="3"/>
                    <path d="m46.29 27.32-2.925 9.66-2.28-2.775 3.06-10.125z" fill="#1976d2"/>
                    <g fill="#fff" stroke="#070c63" strokeWidth="3">
                        <path
                            d="m35.6145 35-2.3985 9.327-5.82 10.185c-.822 1.4385-.3225 3.2715 1.116 4.0935.468.267.981.3945 1.485.3945 1.041 0 2.0535-.5415 2.607-1.512l6-10.5c.12-.21.2145-.432.2805-.663l3.231-11.325z"
                        />
                        <path
                            d="m58.335 29.315-5.28-2.64-5.892-8.7975c-.651-.9915-1.62-1.782-2.8425-2.1525-.5025-.1515-1.0095-.2235-1.509-.2250287-2.262-.0074713-4.35 1.4550287-5.04 3.7260287l-4.5465 15c-.57 1.8825-.0075 3.816 1.257 5.1465 2.0805 2.1885 9.168 7.416 9.168 7.416l1.38 9.63c.21 1.5 1.5 2.58 2.97 2.58.135 0 .285-.015.42-.03 1.5075-.219 2.5875-1.503 2.58-2.97 0-.138-.009-.279-.03-.42l-1.5-10.5c-.075-.57-.315-1.095-.69-1.53l-5.415-6.573 2.9265-9.657 2.2035 3.33c.3.45.69.795 1.17 1.035l6 3c.42.2115.885.3165 1.335.3165 1.095 0 2.16-.6 2.685-1.665.735-1.47.135-3.27-1.35-4.02z"
                            strokeLinejoin="round"
                        />
                    </g>
                </g>
            )}
        />

    );
}

export default IconPaxWithLuggage;
