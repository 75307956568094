export const reviews = [
    {
        username: "James Wetzel",
        content: "Looks great. User friendly. Keeps it simple and intuitive. Great app, I would certainly recommend.",
        store: "google",
    },
    {
        username: "Michelle Harris",
        content: "I love how sleek this app is. It’s so fast and easy to use on an iPhone. Also, love that I can book a bus ticket and a ferry ticket all at one place.",
        store: "apple",
    },
    {
        username: "Livio Pagnotto",
        content: "So easy to use the app and the bus. Great way to travel.",
        store: "google",
    },
    {
        username: "Mirna Novaković",
        content: "I love this app for travel and go. Easy cheap bus and travel for the whole world and more. Either ferry or train or bus, all is included for buy.",
        store: "apple",
    },
]