import React from 'react';
import IconQuestionMarkCircle from "@Icons/IconQuestionMarkCircle";
import GenericPopover from "@Popover/components/GenericPopover";
import Translation from "@Translation/components/Translation";
import useIsCompanyStore from "@Hooks/selector/useIsCompanyStore";
import useIsMobile from "@Hooks/selector/useIsMobile";
import useIsTablet from "@Hooks/selector/useIsTablet";

interface RouteClosedOrUnavailableProps {
    soldOut: boolean,
    closed: boolean,
    unavailable: boolean,
}

function RouteClosedOrUnavailable({soldOut, closed, unavailable}: RouteClosedOrUnavailableProps) {
    const isMobile = useIsMobile()
    const isTablet = useIsTablet()
    const isCompanyStore = useIsCompanyStore()
    const status = {
        [`${soldOut}`]: {
            label: isCompanyStore ? "sys.tickets_sold_out_company_store" : "sys.tickets_sold_out",
            popover: "sys.tickets_sold_out_popup",
        },
        [`${!soldOut && closed}`]: {
            label: "sys.route_not_in_sellable_range",
            popover: "sys.route_not_in_sellable_range_popup",
        },
        [`${!soldOut && unavailable}`]: {
            label: "sys.price_not_set",
            popover: "sys.price_not_set_popup",
        },
    }

    const setClassName = () => {
        let className = 'gb--truncate gb--route-status-closed ';
        if (isMobile || isTablet) {
            className += 'gb--badge gb--badge-right gb--badge-orange'
        } else {
            className += 'gb--label gb--label-orange'
        }
        return className
    }

    const displayStatusTrigger = () => (
        <span className={setClassName()}>
            <Translation translationKey={status.true.label}/>
            &nbsp;
            <IconQuestionMarkCircle width="18" height="18" fill="#f5a623" style={{verticalAlign: 'middle'}}/>
        </span>
    )

    const displayPopover = () => (
        <span>
            <Translation translationKey={status.true.popover}/>
        </span>
    )

    return (
        <GenericPopover
            trigger={displayStatusTrigger()}
            overlay={displayPopover()}
            id="route-closed"
        />
    );
}

export default RouteClosedOrUnavailable
