import React, {ReactNode} from 'react';
import Link from "../Link";
import {NavLink} from "react-router-dom";

interface CardThumbnailLeftProps {
    icon: ReactNode,
    path: string,
    /* Setting greyed out default bg, other options are gbb color set (green, orange, blue, navy) */
    cardColorClass?: "default" | "blue" | "orange" | "green",
    cardHeading: string,
    cardDescription: string,
    cardId: string
}

function CardThumbnailLeft(props: CardThumbnailLeftProps) {
    const {
        icon,
        path,
        cardColorClass = "default",
        cardHeading,
        cardDescription,
        cardId,
    } = props

    if (cardId === "mobile-app") {
        return (
            <NavLink to={path} className="card gb--row">
                <span className={`card__background card__background--${cardColorClass}`}/>
                {icon}
                <div className="card__content gb--column">
                    <h4>
                        {cardHeading}
                    </h4>
                    {cardDescription}
                </div>
            </NavLink>
        )
    }

    return (
        <Link href={path} className="card gb--row">
            <span className={`card__background card__background--${cardColorClass}`}/>
            {icon}
            <div className="card__content gb--column">
                <h4>
                    {cardHeading}
                </h4>
                {cardDescription}
            </div>
        </Link>
    );
}

export default CardThumbnailLeft;