import {createSlice} from '@reduxjs/toolkit'

const overlays = {
    singleCalendarActive: false,
    returnCalendarActive: false,
    stsCalendarActive: false,
    mobileLogInActive: false,
    mobileLanguagesActive: false,
    mobileMenuActive: false,
    mobileMyTicketsActive: false,
    mobilePassengerPickerActive: false,
    sortingOptionsActive: false,
    currencyPickerActive: false,
    mobileOverlayActive: false,
    currencySelectActive: false,
    sorterActive: false,
    departureStationInputActive: false,
    destinationStationInputActive: false,
    filtersOverlayActive: false,
}

const initialState = {
    additionalInfoActive: null,
    formInputSelected: false,
    scrollLocation: 0,
    formAnimationActive: false,
    ...overlays,
}

const mobileSlice = createSlice({
    name: 'mobile',
    initialState,
    reducers: {
        showAdditionalInfo: (state, action) => ({
            ...state,
            additionalInfoActive: action.payload,
        }),

        saveScrollPosition: (state) => ({
            ...state,
            scrollLocation: window.scrollY,
        }),

        activateDepartureStationInput: (state, action) => ({
            ...state,
            formInputSelected: action.payload,
            mobileOverlayActive: action.payload,
            departureStationInputActive: action.payload,
        }),

        activateDestinationStationInput: (state, action) => ({
            ...state,
            formInputSelected: action.payload,
            mobileOverlayActive: action.payload,
            destinationStationInputActive: action.payload,
        }),

        activateSingleCalendar: (state, action) => ({
            ...state,
            singleCalendarActive: action.payload,
            formInputSelected: action.payload,
            mobileOverlayActive: action.payload,
        }),

        activateReturnCalendar: (state, action) => ({
            ...state,
            returnCalendarActive: action.payload,
            formInputSelected: action.payload,
            mobileOverlayActive: action.payload,
        }),

        toggleMobileLogin: (state) => ({
            ...state,
            mobileLogInActive: !state.mobileLogInActive,
            mobileOverlayActive: !state.mobileOverlayActive,
        }),

        activateStsCalendar: (state, action) => ({
            ...state,
            stsCalendarActive: action.payload,
            formInputSelected: action.payload,
        }),

        toggleMobileLanguages: (state) => ({
            ...state,
            mobileLanguagesActive: !state.mobileLanguagesActive,
        }),

        activateMobileMenu: (state, action) => ({
            ...state,
            mobileMenuActive: action.payload,
            mobileOverlayActive: action.payload,
        }),

        toggleMobileMyTickets: (state) => ({
            ...state,
            mobileMyTicketsActive: !state.mobileMyTicketsActive,
            mobileOverlayActive: !state.mobileOverlayActive,
        }),

        toggleMobilePassengerPicker: (state) => ({
            ...state,
            mobilePassengerPickerActive: !state.mobilePassengerPickerActive,
            mobileOverlayActive: !state.mobileOverlayActive,
            formInputSelected: !state.formInputSelected,
        }),

        toggleSortingOptions: (state) => ({
            ...state,
            sortingOptionsActive: !state.sortingOptionsActive,
        }),

        toggleCurrencyPicker: (state) => ({
            ...state,
            currencyPickerActive: !state.currencyPickerActive,
        }),

        toggleMobileOverlay: (state) => ({
            ...state,
            mobileOverlayActive: !state.mobileOverlayActive,
            formInputSelected: !state.mobileOverlayActive,
        }),

        toggleSorter: (state) => ({
            ...state,
            mobileOverlayActive: !state.mobileOverlayActive,
            formInputSelected: !state.formInputSelected,
            sorterActive: !state.sorterActive,
        }),

        toggleCurrencySelect: (state) => ({
            ...state,
            mobileOverlayActive: !state.mobileOverlayActive,
            formInputSelected: !state.formInputSelected,
            currencySelectActive: !state.currencySelectActive,
        }),

        toggleFiltersOverlay: (state) => ({
            ...state,
            mobileOverlayActive: !state.mobileOverlayActive,
            formInputSelected: !state.formInputSelected,
            filtersOverlayActive: !state.filtersOverlayActive,
        }),

        activateFormAnimation: (state, action) => ({
            ...state,
            formAnimationActive: action.payload,
        }),

        dismissAllOverlays: (state) => ({
            ...state,
            additionalInfoActive: null,
            formInputSelected: false,
            ...overlays,
        }),

        clearMobileState: () => initialState,
    },
})

export const {
    showAdditionalInfo,
    activateSingleCalendar,
    activateReturnCalendar,
    activateStsCalendar,
    toggleMobileLogin,
    toggleMobileLanguages,
    activateMobileMenu,
    toggleMobilePassengerPicker,
    toggleMobileMyTickets,
    toggleSortingOptions,
    toggleFiltersOverlay,
    toggleCurrencyPicker,
    toggleMobileOverlay,
    activateDepartureStationInput,
    activateDestinationStationInput,
    toggleSorter,
    toggleCurrencySelect,
    dismissAllOverlays,
    clearMobileState,
    saveScrollPosition,
    activateFormAnimation,
} = mobileSlice.actions

export default mobileSlice.reducer