import React from "react";
import {useTranslation} from "@Translation/hooks/useTranslation";

interface MyProfileAvatarDescriptionProps {
    descriptionText: string,
    showMoreButton?: boolean,
    setFiltersTrue?: () => void,
}

function MyProfileAvatarDescription(props: MyProfileAvatarDescriptionProps) {
    const {
        showMoreButton = false,
        descriptionText,
        setFiltersTrue,
    } = props

    const showAllLabel = useTranslation('my_profile.avatar_section.show_all')

    return (
        <>
            <span>{`${descriptionText}`}</span>
            {showMoreButton && (
                <>
                    <span>&nbsp;|&nbsp;</span>
                    <button
                        type="button"
                        className="gb--emphasize-info"
                        onClick={() => {
                            setFiltersTrue()
                        }}
                    >
                        {showAllLabel}
                    </button>
                </>
            )}
        </>
    );
}

export default MyProfileAvatarDescription