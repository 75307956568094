/**
 * Returns number of occurrences in array based on object property
 * @param array {array}
 * @param property {string}
 */
export default function countBy(array: any[], property: string): number {
    return array.reduce((acc, value) => {
        if (!acc[value[property]]) {
            acc[value[property]] = 1;
        } else {
            acc[value[property]]++;
        }
        return acc;
    }, {});
}