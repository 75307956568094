import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {CompanyScoreType} from "@SearchResults/features/sidebar/features/companyFilters/types/CompanyScore.type";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import {setCompanyScoresFilter} from "@SearchResults/reducers/filterSlice";
import addOrRemoveCompanyScoreFromFilteredList
    from "@SearchResults/features/sidebar/features/companyFilters/functions/addOrRemoveCompanyScoreFromFilteredList";
import {isEmpty} from "@Array/isEmpty";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";

export default function useFilterByCompanyScore() {
    const activeFilters: CompanyScoreType[] = useAppSelector((state) => state.filter.filterCompanyScores)
    const dispatch = useAppDispatch()

    const onPress = (companyScore: CompanyScoreType) => {
        dispatch(setCompanyScoresFilter(
            addOrRemoveCompanyScoreFromFilteredList(activeFilters, companyScore)
        ))
    }

    const isActive = (companyScore: CompanyScoreType) => (
        activeFilters.map(({label}) => label).includes(companyScore.label)
    )

    const filterByCompanyScore = (route: RouteWithAddedPropsAndPricesType) => {
        return activeFilters.some((filter) => (
            parseFloat(route.ratings.rating) >= filter.from && parseFloat(route.ratings.rating) < filter.to
        ))
    }

    return {
        onPress,
        activeFilters,
        isActive,
        filterByCompanyScore,
        hasFilteredCompanyScores: !isEmpty(activeFilters),
    }
}