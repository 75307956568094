import FilterSection from "@SearchResults/features/sidebar/components/FilterSection";
import {SearchResultsRtkQueryResponseType} from "@SearchResults/api/types/SearchResultsRtkQueryResponse.type";
import Company from "@SearchResults/features/sidebar/features/companyFilters/components/Company";
import getUniqueCompaniesForActiveTab
    from "@SearchResults/features/sidebar/features/companyFilters/functions/getUniqueCompaniesForActiveTab";
import useActiveTabFromParams from "@SearchResults/hooks/useActiveTabFromParams";
import useDispatchFilterEvents from "@SearchResults/features/sidebar/hooks/useDispatchFilterEvent";

type CarrierFilterProps = {
    searchData: SearchResultsRtkQueryResponseType
}

function CompanyFilter({searchData}: CarrierFilterProps) {
    const tab = useActiveTabFromParams()
    const companies = getUniqueCompaniesForActiveTab({
        searchData,
        tab,
    })

    if (companies.length < 2) {
        return null
    }

    return (
        <FilterSection
            filterDataKey="filterCompanies"
            isToggleable={companies.length > 10}
            translationKey="search_results.filter_by.companies.label"

        >
            {companies.map((company) => (
                <Company
                    key={company.id}
                    company={company}
                />
            ))}
        </FilterSection>
    );
}

export default CompanyFilter;