import useRoutePriceGenerator from "@RoutesList/hooks/useRoutePriceGenerator";
import PassengerGroupType from "@SearchResults/types/passenger/PassengerGroup.type";
import CurrencyType from "@Currency/types/Currency.type";
import RouteWithAddedPropsType from "@Route/types/RouteWithAddedProps.type";
import {isEmpty} from "@Array/isEmpty";

export default function useRoutesListPriceGenerator(passengerGroups: Record<string, PassengerGroupType>, currency: CurrencyType) {
    const {generatePrices} = useRoutePriceGenerator(passengerGroups, currency)

    const generatePricesForRoutesList = (routes: RouteWithAddedPropsType[]) => (
        routes.map((route) => {
            if (!isEmpty(route?.passengerClasses)) {
                return {
                    ...generatePrices(route),
                    passengerClasses: generatePricesForRoutesList(route.passengerClasses),
                }
            }
            if (!route.isIntermodalRoute) {
                return generatePrices(route)
            }

            return generatePrices(route)
        })
    )

    return {
        generatePricesForRoutesList,
    }
}