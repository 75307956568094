import React from 'react';
import RouteType from "@Route/types/Route.type";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";
import RouteTravelDetailsWithBusChanges
    from "@Route/components/Connection/ExpandedRoute/RouteTravelDetailsWithBusChanges";
import RouteTravelDetails from "@Route/components/Sections/RouteTravelDetails";

interface RouteItineraryProps {
    /**
     *  RouteWithAddedPropsAndPricesType is added here because of Search Results.
     *
     *  RouteType is added here because of Check Order.
     */
    route: RouteWithAddedPropsAndPricesType | RouteType,
    companyLetterCode: string,
}

function RouteItinerary({route, companyLetterCode}: RouteItineraryProps) {
    if (route.travelInfo?.busChange?.length > 0) {
        return (
            <RouteTravelDetailsWithBusChanges
                route={route}
                companyLetterCode={companyLetterCode}
            />
        )
    }

    return (
        <RouteTravelDetails
            route={route}
            companyLetterCode={companyLetterCode}
        />
    )
}

export default RouteItinerary;