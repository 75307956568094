import AdditionalInfoContainer from "@SearchResults/features/additionalInfo/components/AdditionalInfoContainer";
import useAdditionalInfoProps from "@SearchResults/features/additionalInfo/hooks/useAdditionalInfoProps";
import React from "react";
import useIsMobile from "@Hooks/selector/useIsMobile";
import TabContentOutlet from "@RoutesList/components/TabContentOutlet";
import ReturnTabContentOutlet from "@RoutesList/components/ReturnTabContentOutlet";

type AdditionalInfoOutletProps = {
    isReturn?: boolean,
}

function AdditionalInfoOutlet({isReturn}: AdditionalInfoOutletProps) {
    const {
        singleSelectedRoute,
        params,
        companies,
        passengerGroups,
        routeIcons,
    } = useAdditionalInfoProps(isReturn)

    const isMobile = useIsMobile()

    if (isMobile && singleSelectedRoute) {
        return (
            <AdditionalInfoContainer
                isReturn={isReturn}
                passengerGroups={passengerGroups}
                companies={companies}
                params={params}
                singleSelectedRoute={singleSelectedRoute}
            />
        );
    }

    // fallbacks to prevent unmatched routes on desktop
    if (!isMobile && isReturn) {
        return (
            <ReturnTabContentOutlet/>
        )
    }

    return (
        <TabContentOutlet/>
    )
}

export default AdditionalInfoOutlet;