import IconChevron from "@Icons/IconChevron";
import React from "react";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";
import ParamsType from "@SearchResults/types/params/Params.type";
import useActivateRoute from "@Route/hooks/useActivateRoute";

type AdditionalInfoButtonProps = {
    route: RouteWithAddedPropsAndPricesType
    params: ParamsType
    isReturn: boolean,
    passengerClassIndex: number
}

function AdditionalInfoButton({params, route, isReturn, passengerClassIndex}: AdditionalInfoButtonProps) {
    const {
        activateRoute,
        elRef,
        loading,
    } = useActivateRoute({
        route,
        isCurrentRouteSingle: !isReturn,
        params,
        routeExpandable: true,
        passengerClassIndex,
    })

    return (
        <button
            onClick={activateRoute}
            className="btn btn--blue routes-list__cta routes-list__cta--operator"
        >
            Book now
        </button>
    );
}

export default AdditionalInfoButton;