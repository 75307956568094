import React from 'react';
import Translation from "@Translation/components/Translation";
import Column from "@Containers/components/Column";
import AppStoreLinksContainer from "@Sidebar/components/AppStoreNotice/AppStoreLinksContainer";

const AppStoreNoticeContainer = () => (
    <div className="gb--sidebar-section app-store-section">
        <Column>
            <p>
                <Translation translationKey="s_page.download_app"/>
            </p>
            <AppStoreLinksContainer/>
        </Column>
    </div>
);

export default AppStoreNoticeContainer;