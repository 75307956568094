import {calculateRouteTripDuration} from "@Intermodal/functions/calculateRouteTripDuration";
import RouteType from "@Route/types/Route.type";

/**
 * Generates transit periods for each intermodal route combination
 * @param array route[]
 * @return string[]
 */
export const generateTransitPeriods = (array: RouteType[]) => {
    const results = array.reduce((acc, route, currentIndex) => {
        const {
            departureStation,
            destinationStation,
        } = route.travelInfo

        if (route.isSuggestedRoute) {
            acc.travelDuration = [calculateRouteTripDuration(
                acc.previousDestination,
                departureStation
            )]
        } else {
            if (acc.previousDestination.id === departureStation.id || acc.previousDestination?.apiStationName === departureStation?.apiStationName) {
                const travelDuration = calculateRouteTripDuration(
                    acc.previousDestination,
                    departureStation
                )
                // eslint-disable-next-line no-param-reassign
                acc.travelDuration = [...acc.travelDuration, travelDuration]
            }
            acc.previousDestination = destinationStation
        }

        return acc
    }, {
        previousDestination: array[0].travelInfo.destinationStation,
        travelDuration: [],
    })

    return results.travelDuration
}
