import isEqual from "lodash/isEqual";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {
    TicketAdditionalInfoFieldsType
} from "@CheckOrder/features/TicketAdditionalInfo/types/TicketAdditionalInfoFields.type";

export default function useAdditionalFieldFilter(isReturn: boolean, updateAllPassengers: boolean) {
    const {
        ticket: {
            isReturn: isReturnTicket,
            additionalInfoFields,
        },
    } = useAppSelector((state) => state.checkOrder)

    const fieldsHaveSameValues = (field: TicketAdditionalInfoFieldsType) => {
        if (!isReturnTicket) {
            return false
        }

        const fieldTypeToCheck = updateAllPassengers ? 'additionalTicketFields' : 'additionalPassengerFields'
        const legToCheck = !isReturn ? 'returnAdditionalFields' : 'singleAdditionalFields'
        const comparisonField = additionalInfoFields[legToCheck][fieldTypeToCheck].find((comparisonField: TicketAdditionalInfoFieldsType) => comparisonField.key === field.key)

        if (isReturn && field.type === comparisonField?.type && 'options' in field && 'options' in comparisonField) {
            return isEqual(field.options, comparisonField.options)
        }

        if (!isReturn && field.type === comparisonField?.type && 'options' in field && 'options' in comparisonField) {
            return isEqual(field.options, comparisonField.options)
        }

        return field.type === comparisonField?.type && field.key === comparisonField.key;
    }

    return {
        fieldsHaveSameValues,
    }
}