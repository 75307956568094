/**
 * Returns hex color code based on rating provided
 * @param rating {(number|string)}
 * @return {string}
 */
export const calculateCompanyRatingBackgroundColor = (rating: string | number): string => {
    const ratingFloat = typeof rating === 'string' ? parseFloat(rating) : rating
    const red = "#FB744B";
    const orange = "#F5A623";
    const lightGreen = "#ADD421";
    const green = "#7ED321";

    if (ratingFloat >= 8 && ratingFloat <= 10) {
        return green;
    }
    if (ratingFloat >= 7 && ratingFloat <= 7.99) {
        return lightGreen;
    }
    if (ratingFloat >= 5 && ratingFloat <= 6.99) {
        return orange;
    }

    return red;
};
