import React from 'react';
import useLogout from "../hooks/useLogout";
import Translation from "@Translation/components/Translation";
import useDismissAllOverlays from "@MobileOverlays/hooks/useDismissAllOverlays";
import MyProfileLink from "@User/components/MyProfileLink";

interface MyTicketsListItemProps {
    showListItems: string
}

function MyTicketsListItem({showListItems}: MyTicketsListItemProps) {
    const logout = useLogout()
    const dismissAllOverlays = useDismissAllOverlays()
    const handleLogout = async () => {
        await (await logout)()
        dismissAllOverlays()
    }

    return (
        <ul className={showListItems}>
            <li className="select__item">
                <MyProfileLink/>
            </li>
            <li className="select__item">
                <button onClick={handleLogout} type="submit">
                    <Translation translationKey="user.logout"/>
                </button>
            </li>
        </ul>
    );
}

export default MyTicketsListItem;