import React from 'react';
import {useTranslation} from "@Translation/hooks/useTranslation";

type CompanyTermsProps = {
    singleRouteCompanyTermsUrl: null|string,
    returnRouteCompanyTermsUrl: null|string,
    carrierName: string,
}

function CompanySpecificTermsAndConditions({singleRouteCompanyTermsUrl, returnRouteCompanyTermsUrl, carrierName}: CompanyTermsProps) {
    const privacyPolicyLabel = useTranslation('menu.footer.privacy_policy')
    const generalTermsLabel = useTranslation('menu.footer.general_terms')
    const getByBusPrivacyPolicy = () => (`<a target="_blank" href="//getbybus.com/en/privacy-policy">${privacyPolicyLabel}</a>`)
    const getByBusTerms = () => (`<a target="_blank" href="//getbybus.com/en/general-terms">${generalTermsLabel}</a>`)
    const carrierHref = () => (`<a target="_blank" href=${singleRouteCompanyTermsUrl ?? returnRouteCompanyTermsUrl}>${generalTermsLabel}</a>`)

    const terms = useTranslation('check_order.terms_and_conditions_carrier', {
        privacyPolicy: getByBusPrivacyPolicy,
        termsAndConditions: getByBusTerms,
        carrierTermsAndConditions: carrierHref,
        carrierName,
    }, false)

    return (
        <span dangerouslySetInnerHTML={{__html: (`${terms}`)}}/>
    );
}

export default CompanySpecificTermsAndConditions;