import {useEffect} from "react";
import useUser from "@User/hooks/useUser";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {useNavigate} from "react-router-dom";

export default function useHandleNotLoggedInUsers() {
    const user = useUser()
    const {awaitingUserResponse} = useAppSelector((state) => state.userProfile)
    const navigate = useNavigate()

    useEffect(() => {
        if (!user && !awaitingUserResponse) {
            navigate("..")
        }
    }, [user, awaitingUserResponse])
}