import {useOutletContext} from "react-router"
import TabContent from "@Tabs/components/TabContent"
import React from "react"
import DateSlickContainer from "@SearchResults/features/dateSlick/components/DateSlickContainer"
import TabsContainer from "@Tabs/components/TabsContainer"
import MobileFormOverlayContainer from "@Form/components/Mobile/MobileFormOverlayContainer"
import useActiveTab from "@Tabs/hooks/useActiveTab"
import useIsMobile from "@Hooks/selector/useIsMobile"
import useSingleSelectedRoute from "@Route/hooks/useSingleSelectedRoute"
import useTabsToShow from "@Tabs/hooks/useTabsToShow"
import useActiveSliderDate from "@SearchResults/features/dateSlick/hooks/useActiveSliderDate"
import usePrepareTabContentData from "@Tabs/hooks/usePrepareTabContentData"
import {SearchResponseType} from "@SearchResults/types/SearchResponse.type"
import RoutesProgressBar from "@RoutesList/components/RoutesProgressBar"

type SearchResponseWithSuggestionsBoolean = SearchResponseType & {
    suggestionRoutesEnabled: boolean,
}

function TabContentOutlet() {
    const searchQuery: SearchResponseWithSuggestionsBoolean = useOutletContext()

    const {
        data,
        suggestionRoutesEnabled,
    } = searchQuery

    const {
        result: {
            params: {
                isReturn,
            },
            singleRoutes,
        },
    } = data

    const isMobile = useIsMobile()
    const singleSelectedRoute = useSingleSelectedRoute()
    const {getTabsToShow} = useTabsToShow(singleRoutes)
    const tabsToShow = getTabsToShow()
    const activeTab = useActiveTab(tabsToShow)
    const {getActiveSliderDate} = useActiveSliderDate()
    const preparedTabData = usePrepareTabContentData({
        data,
        tabsToShow,
        activeTab,
        suggestionRoutesEnabled,
    })

    const {
        result: {
            resolvedApiCount,
            totalApiCount,
        },
    } = preparedTabData

    return (
        <>
            {(resolvedApiCount < totalApiCount) ? (
                <RoutesProgressBar
                    resolvedCount={resolvedApiCount}
                    totalCount={totalApiCount}
                    queriedRoutesTitle="providers"
                />
            ) : (
                <DateSlickContainer
                    isReturn={false}
                    isLoading={searchQuery.isLoading}
                    date={getActiveSliderDate(searchQuery.data.result.params.date)}
                />
            )}
            <TabsContainer
                activeTab={activeTab}
                tabsToShow={tabsToShow}
                isReturnSearch={isReturn}
                singleSelectedRoute={singleSelectedRoute}
            />
            <TabContent
                preparedTabContentData={preparedTabData}
            />
            {isMobile && (
                <MobileFormOverlayContainer />
            )}
        </>
    )
}

export default TabContentOutlet