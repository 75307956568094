import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {StationType} from "@SearchResults/types/station/Station.type";
import {PreparedRouteType} from "@Route/types/PreparedRoute.type";
import {isEmpty} from "@Array/isEmpty";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import addOrRemoveStationFromFilteredList
    from "@SearchResults/features/sidebar/features/stationFilters/functions/addOrRemoveStationFromFilteredList";
import {setDepartureStationFilter, setDestinationStationFilter} from "@SearchResults/reducers/filterSlice";

export default function useStationFilters(filterType: 'departure' | "destination") {
    const {
        filterDepartureStation,
        filterDestinationStation,
    } = useAppSelector((state) => state.filter)

    const filteredStations = filterType === 'departure' ? filterDepartureStation : filterDestinationStation
    const dispatch = useAppDispatch()

    const isActive = (station: StationType) => {
        return filteredStations.some((s) => s.id === station.id)
    }

    const filterByStation = (route: PreparedRouteType) => {
        if (filterType === 'departure') {
            return filteredStations.map(({name}) => name).includes(route.travelInfo.departureStation.name)
        }
        return filteredStations.map(({name}) => name).includes(route.travelInfo.destinationStation.name)
    }

    const onPress = (station: StationType) => {
        if (filterType === 'departure') {
            dispatch(setDepartureStationFilter(
                addOrRemoveStationFromFilteredList(filterDepartureStation, station)
            ))
        } else {
            dispatch(setDestinationStationFilter(
                addOrRemoveStationFromFilteredList(filterDestinationStation, station)
            ))
        }
    }

    return {
        filteredStations,
        isActive,
        onPress,
        stationFilterActive: !isEmpty(filteredStations),
        filterByStation,
    }
}