import {SearchResultsRtkQueryResponseType} from "@SearchResults/api/types/SearchResultsRtkQueryResponse.type";
import useActiveTabFromParams from "@SearchResults/hooks/useActiveTabFromParams";
import RouteStatusType from "@Route/types/status/RouteStatus.type";

export default function useDisplayPopularFilters(searchData: SearchResultsRtkQueryResponseType) {
    const activeTab = useActiveTabFromParams()

    const hasStatus = (statusKey: keyof RouteStatusType) => (
        searchData.result.singleRoutes[activeTab].some((route) => route.status[statusKey])
    )

    const hasWebDiscounts = searchData.result.singleRoutes[activeTab].some((route) => route.prices?.activeDiscount)

    return {
        displayMTicketFilter: hasStatus('hasMTicket'),
        displayInstantConfirmationFilter: hasStatus('isInstantConfirmation'),
        displayWebDiscountFilter: hasWebDiscounts,
    }
}