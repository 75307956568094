import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconPoweredByStripe(props: IconType) {
    const {
        width = '150',
        height = '34',
        fill = '#635bff',
        viewBox = '0 0 150 34',
        title = 'IconPoweredByStripe',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill={fill}>
                    <path
                        d="m146 0h-142.27a3.73 3.73 0 0 0 -3.73 3.73v26.54a3.73 3.73 0 0 0 3.73 3.73h142.27a4 4 0 0 0 4-4v-26a4 4 0 0 0 -4-4zm3 30a3 3 0 0 1 -3 3h-142.27a2.74 2.74 0 0 1 -2.73-2.73v-26.54a2.74 2.74 0 0 1 2.73-2.73h142.27a3 3 0 0 1 3 3z"
                    />
                    <path
                        d="m17.07 11.24h-4.3v10.76h1.92v-4.16h2.38c2.4 0 3.9-1.16 3.9-3.3s-1.5-3.3-3.9-3.3zm-.1 5h-2.28v-3.3h2.31c1.38 0 2.11.59 2.11 1.65s-.76 1.6-2.11 1.6z"
                    />
                    <path
                        d="m25.1 14a3.77 3.77 0 0 0 -3.8 4.09 3.81 3.81 0 1 0 7.59 0 3.76 3.76 0 0 0 -3.79-4.09zm0 6.67c-1.22 0-2-1-2-2.58s.76-2.58 2-2.58 2 1 2 2.58-.79 2.57-2 2.57z"
                    />
                    <path
                        d="m36.78 19.35-1.41-5.22h-1.48l-1.4 5.22-1.42-5.22h-1.85l2.37 7.88h1.56l1.44-5.16 1.44 5.16h1.56l2.37-7.88h-1.78z"
                    />
                    <path
                        d="m44 14a3.83 3.83 0 0 0 -3.75 4.09 3.79 3.79 0 0 0 3.83 4.09 3.47 3.47 0 0 0 3.41-2.18l-1.49-.62a1.78 1.78 0 0 1 -1.83 1.26 2.12 2.12 0 0 1 -2.17-2.17h5.52v-.6c.02-2.16-1.2-3.87-3.52-3.87zm-1.93 3.13a1.92 1.92 0 0 1 1.93-1.63 1.56 1.56 0 0 1 1.69 1.62z"
                    />
                    <path
                        d="m50.69 15.3v-1.17h-1.8v7.87h1.8v-4.13a1.89 1.89 0 0 1 2-2 4.68 4.68 0 0 1 .66 0v-1.8c-.14 0-.3 0-.51 0a2.29 2.29 0 0 0 -2.15 1.23z"
                    />
                    <path
                        d="m57.48 14a3.83 3.83 0 0 0 -3.75 4.09 3.79 3.79 0 0 0 3.83 4.09 3.47 3.47 0 0 0 3.37-2.18l-1.54-.59a1.78 1.78 0 0 1 -1.83 1.26 2.12 2.12 0 0 1 -2.1-2.17h5.54v-.6c0-2.19-1.24-3.9-3.52-3.9zm-1.93 3.13a1.92 1.92 0 0 1 1.92-1.62 1.56 1.56 0 0 1 1.69 1.62z"
                    />
                    <path
                        d="m67.56 15a2.85 2.85 0 0 0 -2.26-1c-2.21 0-3.47 1.85-3.47 4.09s1.26 4.09 3.47 4.09a2.82 2.82 0 0 0 2.26-1v.82h1.8v-10.76h-1.8zm0 3.35a2 2 0 0 1 -2 2.28c-1.31 0-2-1-2-2.52s.7-2.52 2-2.52c1.11 0 2 .81 2 2.29z"
                    />
                    <path
                        d="m79.31 14a2.88 2.88 0 0 0 -2.31 1v-3.76h-1.8v10.76h1.8v-.83a2.86 2.86 0 0 0 2.27 1c2.2 0 3.46-1.86 3.46-4.09s-1.22-4.08-3.42-4.08zm-.31 6.6a2 2 0 0 1 -2-2.28v-.47c0-1.48.84-2.29 2-2.29 1.3 0 2 1 2 2.52s-.75 2.52-2 2.52z"
                    />
                    <path
                        d="m86.93 19.66-1.93-5.53h-1.9l2.9 7.59-.3.74a1 1 0 0 1 -1.14.79 4.12 4.12 0 0 1 -.6 0v1.51a4.62 4.62 0 0 0 .73.05 2.67 2.67 0 0 0 2.78-2l3.24-8.62h-1.89z"
                    />
                    <path
                        d="m125 12.43a3 3 0 0 0 -2.13.87l-.14-.69h-2.39v12.92l2.72-.59v-3.13a3 3 0 0 0 1.93.7c1.94 0 3.72-1.59 3.72-5.11 0-3.22-1.8-4.97-3.71-4.97zm-.65 7.63a1.61 1.61 0 0 1 -1.28-.52v-4.11a1.64 1.64 0 0 1 1.3-.55c1 0 1.68 1.13 1.68 2.58s-.69 2.6-1.7 2.6z"
                    />
                    <path
                        d="m133.73 12.43c-2.62 0-4.21 2.26-4.21 5.11 0 3.37 1.88 5.08 4.56 5.08a6.12 6.12 0 0 0 3-.73v-2.25a5.79 5.79 0 0 1 -2.7.62c-1.08 0-2-.39-2.14-1.7h5.38c0-.15 0-.74 0-1 .09-2.87-1.27-5.13-3.89-5.13zm-1.47 4.07c0-1.26.77-1.79 1.45-1.79s1.4.53 1.4 1.79z"
                    />
                    <path
                        d="m113 13.36-.17-.82h-2.32v9.71h2.68v-6.58a1.87 1.87 0 0 1 2.05-.58v-2.55a1.8 1.8 0 0 0 -2.24.82z"
                    />
                    <path
                        d="m99.46 15.46c0-.44.36-.61.93-.61a5.9 5.9 0 0 1 2.7.72v-2.63a7 7 0 0 0 -2.7-.51c-2.21 0-3.68 1.18-3.68 3.16 0 3.1 4.14 2.6 4.14 3.93 0 .52-.44.69-1 .69a6.78 6.78 0 0 1 -3-.9v2.69a7.38 7.38 0 0 0 3 .64c2.26 0 3.82-1.15 3.82-3.16-.05-3.36-4.21-2.76-4.21-4.02z"
                    />
                    <path
                        d="m107.28 10.24-2.65.58v8.93a2.77 2.77 0 0 0 2.82 2.87 4.16 4.16 0 0 0 1.91-.37v-2.25c-.35.15-2.06.66-2.06-1v-4h2.06v-2.34h-2.06z"
                    />
                    <path d="m116.25 11.7 2.73-.57v-2.16l-2.73.57z"/>
                    <path d="m116.25 12.61h2.73v9.64h-2.73z"/>
                </g>
            )}
        />

    );
}

export default IconPoweredByStripe;
