import Header from "@Header/components/Header";
import React, {useEffect} from "react";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import {setIs404Page} from "@Reducers/pageSlice";
import Form from "@Form/components/Form";

/**
 * React part of 404 page (fallback route)
 */
function FallbackRoute() {
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(setIs404Page(true))
    }, [])

    return (
        <>
            <Header
                includeOutlet={false}
            />
            <div className="homepage">
                <Form/>
            </div>
        </>
    )
}

export default FallbackRoute