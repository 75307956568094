import React from 'react';
import useLogout from "@User/hooks/useLogout";
import {useAsyncHandler} from "@Hooks/promises/useAsyncHandler";
import Translation from "@Translation/components/Translation";
import IconLogin from "@Icons/IconLogin";

function LogoutButton() {
    const logout = useLogout()
    const handleLogout = useAsyncHandler(logout)

    return (
        <button
            type="button"
            onClick={handleLogout}
        >
            <IconLogin
                width="30"
                height="24"
                style={{
                    verticalAlign: 'middle',
                    transform: 'scale(-1)',
                }}
            />
            <Translation translationKey="user.logout"/>
        </button>
    );
}

export default LogoutButton;