import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import ErrorBoundary from "@Errors/components/ErrorBoundary"

type PlacementType = "top" | "bottom" | "left" | "right"

interface GenericPopoverProps {
    /** Element that triggers overlay */
    trigger: any,
    /** Event type to trigger [hover, focus etc.] */
    triggerOn?: any,
    /** Popover position */
    placement?: PlacementType,
    overlay: any
    /** Required for popover to work properly */
    id: any,
    /** Optional header title */
    title?: any,
    style?: object,
    className?: string,
}

function GenericPopover(props: GenericPopoverProps) {
    const {
        trigger,
        triggerOn = ['hover', 'focus'],
        placement = "top",
        overlay,
        id,
        title,
        style,
        className,
    } = props

    const displayOverlay = () => (
        <Popover
            style={{opacity: 1, ...style}}
            id={id}
            title={title}
            className={className}
        >
            {!!title && (
                <Popover.Title as="h5">
                    {title}
                </Popover.Title>
            )}
            <Popover.Content className="popover-content">
                {overlay}
            </Popover.Content>
        </Popover>
    )

    return (
        <ErrorBoundary shouldReload={false}>
            {overlay ? (
                <OverlayTrigger
                    trigger={triggerOn}
                    delay={{show: 100, hide: 500}}
                    placement={placement}
                    overlay={displayOverlay()}
                >
                    <div>
                        {trigger}
                    </div>
                </OverlayTrigger>
            ) : (
                <div>
                    {trigger}
                </div>
            )}
        </ErrorBoundary>
    )
}

export default GenericPopover