import React from 'react';
import Translation from "@Translation/components/Translation";
import SidebarSectionContainerItem from "../Sections/SidebarSectionContainerItem";
import Link from "@Generic/Link";
import Column from "@Containers/components/Column";
import Row from "@Containers/components/Row";
import IconBookmark from "@Icons/IconBookmark";
import LatestBlogType from "@Sidebar/types/LatestBlog.type";

interface BlogPostProps {
    blogPost: LatestBlogType,
}

function BlogPost({blogPost}: BlogPostProps) {
    const {url, post_title: title} = blogPost

    return (
        <SidebarSectionContainerItem className="gb--sidebar-latest-blog-post">
            <Row>
                <span className="icon-container">
                    <IconBookmark fill="#f5a623" width="10" height="16"/>
                </span>
                <Column>
                    <span>
                        {title}
                    </span>
                    <Link className="gb--emphasize-info" href={url}>
                        <Translation translationKey="sys.see_more"/>
                    </Link>
                </Column>
            </Row>
        </SidebarSectionContainerItem>
    );
}

export default BlogPost;