import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconFriends(props: IconType) {
    const {
        width = '14',
        height = '10',
        fill = '#4a4a4a',
        viewBox = '0 0 14 10',
        title = 'IconFriends',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <path
                    d="m4.602 4.5c.308 0 .595-.07933333.861-.238s.476-.371.63-.637.231-.55766667.231-.875-.077-.609-.231-.875-.364-.47833333-.63-.637-.553-.238-.861-.238-.59733333.07933333-.868.238-.48533333.37333333-.644.644-.238.56-.238.868.07933333.59733333.238.868.37333333.48533333.644.644.56.238.868.238zm4.648 0c.308 0 .59733333-.07933333.868-.238.2706667-.15866667.4853333-.37333333.644-.644s.238-.56.238-.868-.0793333-.59733333-.238-.868-.3733333-.48533333-.644-.644c-.27066667-.15866667-.56-.238-.868-.238s-.59733333.07933333-.868.238-.48533333.37333333-.644.644-.238.56-.238.868.07933333.59733333.238.868.37333333.48533333.644.644.56.238.868.238zm4.102 4.648v-1.456c0-.42-.252-.798-.756-1.134-.4293333-.28-.9846667-.50866667-1.666-.686-.616-.14933333-1.176-.224-1.68-.224-.23333333 0-.41533333.00933333-.546.028.76533333.55066667 1.148 1.22266667 1.148 2.016v1.456zm-4.676 0v-1.456c0-.42-.252-.798-.756-1.134-.42-.28-.97066667-.50866667-1.652-.686-.60666667-.14933333-1.162-.224-1.666-.224s-1.064.07466667-1.68.224c-.68133333.17733333-1.23666667.406-1.666.686-.504.336-.756.714-.756 1.134v1.456z"
                />
            )}
        />

    );
}

export default IconFriends;
