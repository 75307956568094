import React from 'react';
import HeroBackgroundCompanyStore from "./HeroBackgroundCompanyStore";
import HeroBackgroundMain from "./HeroBackgroundMain";
import useIsCompanyStore from "@Hooks/selector/useIsCompanyStore";

function HeroBackground() {
    const isCompanyStore = useIsCompanyStore()

    if (isCompanyStore) {
        return <HeroBackgroundCompanyStore/>
    }

    return <HeroBackgroundMain/>
}

export default HeroBackground
