import Row from "@Containers/components/Row";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import roundToTwoDecimals from "@Number/roundToTwoDecimals";

type BookingFeeProps = {
    bookingFeeLabel: string,
    bookingFee: string,
    paxCount: number,
}

function BookingFeePerPax({bookingFeeLabel, bookingFee, paxCount}: BookingFeeProps) {
    const [price, currency] = bookingFee.split(" ")
    const bookingFeePerPax = roundToTwoDecimals(parseFloat(price) / paxCount);

    return (
        <Row justify center>
            <span>
                {`${paxCount} x `}
                {bookingFeeLabel}
            </span>
            <span>
                {`${bookingFeePerPax} ${currency}`}
            </span>
        </Row>
    );
}

export default BookingFeePerPax;