import {setUserLoginMethods} from "@MyProfile/reducers/userProfileSlice";
import {checkPasswordValidation} from "@CheckOrder/reducers/checkOrderSlice";
import ajaxHandler from "../../../utils/ajax/ajaxHandler";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import CheckUserResponseType from "@User/types/CheckUserResponse.type";

/** Check if user exists and dispatch available login methods to redux store
 * @return {(function(): Promise<void>)|*}
 */
export default function useCheckUser() {
    const email = useAppSelector((state) => state?.checkOrder.email)
    const dispatch = useAppDispatch()

    return async () => {
        const response: CheckUserResponseType = await ajaxHandler(`/ajax/check-user`, "log", {email}, 'get')

        if (response?.status === 200 && response?.data?.data.status) {
            dispatch(setUserLoginMethods(response.data.data))
            if (response?.data?.data?.password) {
                dispatch(checkPasswordValidation(false))
            }
        }

        if (!response?.data?.data.status) {
            dispatch(checkPasswordValidation(true))
        }
    }
}