import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconBusStation(props: IconType) {
    const {
        width = '98',
        height = '102',
        viewBox = '0 0 98 102',
        title = 'IconBusStation',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none" fillRule="evenodd" transform="matrix(1 0 0 -1 0 102)">
                    <g fillRule="nonzero">
                        <path
                            d="m32.9409739 97.3256272c-9.9 0-18-17.28-18-38.4v-57.60000005h36v57.60000005c0 21.12-8.1 38.4-18 38.4z"
                            fill="#fff"
                        />
                        <path
                            d="m20.4094726 19.0885466c-1.4515543.0848804-2.5087219-1.3650355-2.5087219-1.9533872v-12.44817298h-13.06385427-4.49578642c0-.58835164-.75996234-4.69055948 0-4.69055948h21.05542709c.7599624 0 1.34111.44991596 1.34111 1.03826759v16.10046487c0 .5883517-.8766201 1.8685068-2.3281745 1.9533872z"
                            fill="#070c63"
                            transform="matrix(1 0 0 -1 13 20.088546)"
                        />
                        <path
                            d="m36.9374078 99.3256272c12.0980386 0 21.9964338-7.470487 21.9964338-16.6010822h-43.9928677c0 9.1305952 9.8983953 16.6010822 21.9964339 16.6010822z"
                            fill="#6a9fff"
                        />
                        <path
                            d="m25.5597726 95.1753566c-1.3261219 0-2.6522439-.0830054-3.9783658-.2490162.4420406.3320216.9945914.6640433 1.5471423.9960649.5525508.3320217 1.1051016.5810379 1.6576524.8300541h.1105102c.5525508.2490163 1.1051016.4980325 1.6576524.7470487.1105101 0 .1105101.0830054.2210203.0830054.5525508.1660108 1.1051016.4150271 1.6576524.5810379.1105102.0830054.3315305.0830054.4420407.0830054.4420406.1660108.9945915.2490162 1.5471423.4150271.2210203.0830054.4420406.0830054.7735711.1660108.4420406.0830054.8840813.1660108 1.3261219.2490162.3315305.0830054.7735712.0830054 1.1051017.1660108.3315305 0 .6630609.0830055.9945914.0830055.7735712.0830054 1.4366321.0830054 2.2102033.0830054 12.1561178 0 22.1020324-7.470487 22.1020324-16.6010822h-12.0456077c-2.4312235 7.0554599-11.0510162 12.3678062-21.3284613 12.3678062z"
                            fill="#4a79e2"
                        />
                        <path
                            d="m37.7674619 101.815789c-13.9791939 0-25.3166503-8.5495568-25.3166503-19.091244v-9.1305952c0-1.411092.600887-2.4901623 2.4721177-2.4901623 1.8712306 0 2.4721176 1.0790703 2.4721176 2.4901623v9.1305952c0 7.8025086 10.0256101 14.1109198 20.372415 14.1109198s20.3724149-6.3084112 20.3724149-14.1109198c0-5.3332395 0-9.3331691 0-11.9997889 0-2.8670743 0-7.1676857 0-12.9018343 0-1.411092.600887-2.4901623 2.4721177-2.4901623s2.4721176 1.0790703 2.4721176 2.4901623v24.9016232c0 10.5416872-11.3374564 19.091244-25.3166502 19.091244z"
                            fill="#070c63"
                            transform="matrix(-1 0 0 1 75.534924 0)"
                        />
                        <path
                            d="m63.2916257 85.2147073h-51.8783817c-1.763865 0-3.11270293-1.0790703-3.11270293-2.4901623s1.34883793-2.4901623 3.11270293-2.4901623h51.8783817c1.763865 0 3.1127029 1.0790703 3.1127029 2.4901623s-1.3488379 2.4901623-3.1127029 2.4901623z"
                            fill="#070c63"
                        />
                    </g>
                    <g transform="translate(66.404329 47.313084)">
                        <path
                            d="m19.298758 16.6010821h-16.1860551c-1.76386498 0-3.1127029-1.0790703-3.1127029-2.4901623s1.34883792-2.4901623 3.1127029-2.4901623h16.1860551c1.763865 0 3.1127029 1.0790703 3.1127029 2.4901623s-1.3488379 2.4901623-3.1127029 2.4901623z"
                            fill="#070c63"
                            fillRule="nonzero"
                        />
                        <path
                            d="m.83005411 1.66010821h16.60108219c2.2921311 0 4.1502705 1.85813942 4.1502705 4.15027054v6.64043285h-20.75135269z"
                            fill="#7ed321"
                        />
                        <path
                            d="m15.1484875 4.98032464h-12.0357846c-1.76386498 0-3.1127029-1.07907034-3.1127029-2.49016232s1.34883792-2.49016232 3.1127029-2.49016232h12.0357846c1.7638649 0 3.1127029 1.07907034 3.1127029 2.49016232s-1.348838 2.49016232-3.1127029 2.49016232z"
                            fill="#070c63"
                            fillRule="nonzero"
                        />
                    </g>
                    <path
                        d="m14.9409739 80.2343827c-1.2450811 0-2.3241515-.9130595-2.4901623-2.1581407v-74.42400393c-.1660108-1.32808657.7470487-2.65617314 2.1581407-2.82218396 1.3280866-.16601082 2.6561731.74704869 2.822184 2.15814068v74.42400391c.1660108 1.3280866-.7470487 2.6561732-2.1581407 2.822184-.0830054 0-.2490163 0-.3320217 0z"
                        fill="#070c63"
                        fillRule="nonzero"
                    />
                    <path
                        d="m73.8748155 67.2343827c-1.2450811 0-2.3241515-.9130595-2.4901623-2.1581407v-61.42400393c-.1660108-1.32808657.7470487-2.65617314 2.1581407-2.82218396 1.3280866-.16601082 2.6561731.74704869 2.822184 2.15814068v61.42400391c.1660108 1.3280866-.7470487 2.6561732-2.1581407 2.822184-.0830054 0-.2490162 0-.3320217 0z"
                        fill="#070c63"
                        fillRule="nonzero"
                    />
                    <g
                        fill="#b5b7d1"
                        fillRule="nonzero"
                        stroke="#b5b7d1"
                        strokeWidth="2.004449"
                        transform="matrix(1 0 0 -1 18 55)"
                    >
                        <path
                            d="m7.08910562 35.0614896c-2.08186236 0-4.67151851-1.3533487-5.30188321-3.4490349l-1.53931582-13.8237872c-.76860258-2.5601654.68427746-5.2571863 3.24444286-6.0244065 2.55601826-.7713674 5.25718629.6856598 6.02440648 3.2444428l2.20746557 13.8237873c.7686026 2.5601654-.6842775 5.2571863-3.24444289 6.0244065-.46171449.1382378-.93034088.204592-1.39067299.204592z"
                        />
                        <path
                            d="m4.87817175 8.0177966c2.20990519 0 4.0088983-1.79899311 4.0088983-4.0088983s-1.79899311-4.0088983-4.0088983-4.0088983-4.0088983 1.79899311-4.0088983 4.0088983 1.79899311 4.0088983 4.0088983 4.0088983"
                        />
                        <path
                            d="m9.87966747 28.6860238 11.05902983 1.3806106c1.2690236 0 2.3749266.8628816 2.6818147 2.091625l1.5393158 16.5673269c.3704775 1.4800145-.5308334 2.9793576-2.011361 3.3493612-.2253278.0552244-.4506555.0814611-.6732185.0814611-1.2386113.0013755-2.36525-.8366551-2.6790499-2.0916301l-1.6222586-14.9895948h-11.05902978"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="m1.92323846 17.5696695c.45852189 1.5096869 1.14630473 3.7742173 2.0633485 6.7935912l9.85221314 2.4633989c1.1114325.2778581 1.7860333 1.4044967 1.5081752 2.5145469-.3097847 1.2375976-.7341987 1.5717646-2.0099786 1.5717646-.1658855 0-.3345357-.0207357-.5045683-.0622071l-11.05902977-2.7647574c-.74233738-.1852388-1.32293644-.7658378-1.50817519-1.5081752l-.2229413-10.0568303c.64445484-1.162235 1.27144028-.8126788 1.88095632 1.0486684z"
                        />
                    </g>
                </g>
            )}
        />

    );
}

export default IconBusStation;
