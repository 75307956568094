import React from 'react';
import PropTypes from "prop-types";
import CookieTab from "./CookieTab";
import ToggleButton from "../../../components/Generic/ToggleButton";
import useEventTrackingState from "@Events/hooks/useEventTrackingState";

const AnalyticsCookie = ({toggle, cookieActive}) => {
    const {eventsTrackingAlwaysActive} = useEventTrackingState()

    return (
        <CookieTab label="Event cookies">
            <>
                <ToggleButton
                    disabled={eventsTrackingAlwaysActive}
                    label={`Event cookie ${cookieActive ? "enabled" : "disabled"}`}
                    onClick={toggle}
                    checked={cookieActive}
                />
            </>
        </CookieTab>
    );
}

AnalyticsCookie.propTypes = {
    cookieActive: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
};

export default AnalyticsCookie;