import getCookie from "@Cookies/functions/getCookie";
import MetadataEventModel from "./MetadataEventModel";

/**
 * Generic tracking event model
 * @constructor
 */
export default function UserEventModel(eventName: string, accountId: number, experiments: string, properties?: object) {
    return {
        eventName,
        visitorId: getCookie("visitor_id", false),
        timestamp: Date.now(),
        metadata: MetadataEventModel(experiments, accountId),
        properties,
    }
}