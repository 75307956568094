import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import {
    updateAdditionalFieldPassengerValue,
    updateReturnAdditionalFieldPassengerValue
} from "@CheckOrder/reducers/checkOrderSlice";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {ChangeEvent} from "react";
import {AdditionalFieldOptionType} from "@CheckOrder/features/TicketAdditionalInfo/types/AdditionalFieldOption.type";
import {
    TicketAdditionalInfoFieldsType
} from "@CheckOrder/features/TicketAdditionalInfo/types/TicketAdditionalInfoFields.type";
import useAdditionalFieldFilter from "@CheckOrder/features/TicketAdditionalInfo/hooks/useAdditionalFieldFilter";

export default function useUpdateAdditionalFieldPassengerValue(field: TicketAdditionalInfoFieldsType, passengerIndex: number, updateAllPassengers?: boolean, isReturn?: boolean) {
    const {key: fieldName} = field
    const dispatch = useAppDispatch()
    const {
        passengers,
        ticket: {
            isReturn: isReturnTicket,
        },
    } = useAppSelector((state) => state.checkOrder)
    const {fieldsHaveSameValues} = useAdditionalFieldFilter(isReturn, updateAllPassengers)
    const dispatchAction = isReturn ? updateReturnAdditionalFieldPassengerValue : updateAdditionalFieldPassengerValue

    const updateForBothLegs = () => {
        if (!isReturnTicket) {
            return false
        }

        return fieldsHaveSameValues(field)
    }

    const updateReduxStore = (value: any, passengerIndex: number) => {
        if (updateForBothLegs()) {
            dispatch(updateAdditionalFieldPassengerValue(
                {
                    value,
                    fieldName,
                    passengerIndex,
                }
            ))
            dispatch(updateReturnAdditionalFieldPassengerValue(
                {
                    value,
                    fieldName,
                    passengerIndex,
                }
            ))
        } else {
            dispatch(dispatchAction(
                {
                    value,
                    fieldName,
                    passengerIndex,
                }
            ))
        }
    }

    const updateValue = (value: string | number | boolean | AdditionalFieldOptionType, validateFunction: (value: string | number | boolean | AdditionalFieldOptionType) => (boolean) = () => true) => {
        if (validateFunction(value)) {
            if (updateAllPassengers) {
                passengers.map((passenger, index) => {
                    updateReduxStore(value, index)
                })
            } else {
                updateReduxStore(value, passengerIndex)
            }
        }
    }

    const updateAdditionalField = (validateFunction?: (value: string | number | boolean) => (boolean)) => (event: ChangeEvent<HTMLInputElement>) => {
        updateValue(event.target.value, validateFunction)
    }

    const onClick = (value: string) => {
        updateValue(value)
    }

    const onCountrySelect = (value: AdditionalFieldOptionType) => {
        updateValue(value)
    }

    const getFieldValue = () => {
        if (isReturn) {
            return passengers[passengerIndex].returnAdditionalInfo?.[fieldName]
        }
        return passengers[passengerIndex].additionalInfo?.[fieldName]
    }

    return {
        updateAdditionalField,
        onClick,
        onCountrySelect,
        value: getFieldValue(),
        updateValue,
    }
}