import React, {useEffect} from 'react';
import {Outlet} from "react-router";
import SplashLoader from "@Generic/Loaders/SplashLoader";
import Column from "@Containers/components/Column";
import {useScroll} from "@Scroll/hooks/useScroll";
import {isTicketsPage} from "@MyProfile/functions/isActivePage";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import MyProfileAvatarSection from "@MyProfile/features/avatar/components/MyProfileAvatarSection";
import MyProfileLinks from "@MyProfile/features/links/components/MyProfileLinks";
import MyProfileFilter from "@MyProfile/features/filter/components/MyProfileFilter";
import Form from "@Form/components/Form";
import MobileFormOverlayContainer from "@Form/components/Mobile/MobileFormOverlayContainer";
import useIsMobile from "@Hooks/selector/useIsMobile";
import useHandleNotLoggedInUsers from "@MyProfile/hooks/useHandleNotLoggedInUsers";

/**
 * Main view that is injected in body of my tickets twig. Render my tickets by default. Renders all other components
 * and passing them props. Keep current view in state so that we know what view to render.
 */
function MyProfile() {
    const isMobile = useIsMobile()
    const {formInputSelected} = useAppSelector((state) => state?.mobile)
    const {activeSplashLoader} = useAppSelector((state) => state?.userProfile)
    const {elRef: avatarRef, executeScroll} = useScroll({block: "start"})

    useEffect(() => {
        if (avatarRef.current) {
            executeScroll()
        }
    }, [isTicketsPage()])

    useHandleNotLoggedInUsers()

    if (formInputSelected) {
        return null
    }

    return (
        <div className="gb--main-content">
            {!isMobile ? (
                <Form/>
            ) : (
                <MobileFormOverlayContainer/>
            )}
            <div className="my-profile">
                <div>
                    <MyProfileAvatarSection avatarRef={avatarRef}/>
                    <div className="container">
                        <div className="my-profile-main-section">
                            <Column className="links-and-data-container">
                                <MyProfileLinks/>
                                <Outlet/>
                            </Column>
                            <MyProfileFilter/>
                        </div>
                    </div>
                </div>
            </div>
            {activeSplashLoader && (
                <SplashLoader/>
            )}
        </div>
    );
}

export default MyProfile
