import React from 'react';
import Row from "@Containers/components/Row";
import RouteIconType from "@SearchResults/types/routeIcons/RouteIcon.type";
import RouteAmenitiesIcon from "@Route/components/Amenities/RouteAmenitiesIcon";

interface RouteAmenitiesIconsListProps {
    amenities: number[],
    routeIcons: Record<number, RouteIconType>,
}

const RouteAmenitiesIconsList = ({amenities, routeIcons}: RouteAmenitiesIconsListProps) => (
    <Row>
        {amenities.map((icon) => (
            <RouteAmenitiesIcon
                displayLabel={false}
                key={icon}
                icon={routeIcons?.[icon]}
            />
        ))}
    </Row>
);

export default RouteAmenitiesIconsList;