import React from 'react';

interface RouteInfoLabelProps {
    textLabel: string,
    customClassName?: string,
}

function RouteInfoLabel({textLabel, customClassName}: RouteInfoLabelProps) {
    return (
        <span className={(customClassName)}>
            {textLabel}
        </span>
    );
}

export default RouteInfoLabel;
