import React, {useEffect, useState} from "react";
import Translation from "@Translation/components/Translation";
import {StripePaymentElementOptions} from "@stripe/stripe-js";
import isBefore from "date-fns/isBefore";
import {useElements, useStripe} from "@stripe/react-stripe-js";
import useIsMobile from "@Hooks/selector/useIsMobile";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import useLocale from "@Hooks/selector/useLocale";
import useUser from "@User/hooks/useUser";
import useFixedEurConversion from "@Currency/hooks/useFixedEurConversion";
import ajaxHandler from "@Ajax/v2/ajaxHandler";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import {setPaymentPendingLoader} from "@CheckOrder/reducers/checkOrderSlice";
import useGetDisplayPriceWithAncillaries from "@CheckOrder/hooks/useGetDisplayPriceWithAncillaries";

export default function useStripeCheckoutForm() {
    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useAppDispatch()

    const isMobile = useIsMobile();
    const user = useUser()
    const locale = useLocale()

    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const {phoneCountryObject} = useAppSelector((state) => state?.checkOrder)
    const {ticket} = useAppSelector((state) => state?.checkOrder)
    const {ticketCode} = ticket;
    const price = useGetDisplayPriceWithAncillaries(true);

    const successfulUrl = `https://${window.location.host}/successful-purchase?_locale=${locale}&order_number=${ticketCode}`;
    const companyId = ticket.isIntermodal
        ? ticket.baseTickets[0].singleCompanyId
        : ticket.singleCompanyId

    const {shouldDisplayPriceInKn, priceInKn} = useFixedEurConversion(price, true, false)
    const priceString = shouldDisplayPriceInKn ? `${price} ${priceInKn}` : price

    useEffect(() => {
        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
        );

        if (!clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({paymentIntent}) => {
            switch (paymentIntent.status) {
                case "succeeded":
                    setMessage("Payment succeeded!");
                    break;
                case "processing":
                    setMessage("Your payment is processing.");
                    break;
                case "requires_payment_method":
                    setMessage("Your payment was not successful, please try again.");
                    break;
                default:
                    setMessage("Something went wrong.");
                    break;
            }
        });
    }, [stripe]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);
        if (companyId === 446) {
            dispatch(setPaymentPendingLoader(true))
        }

        const {error} = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: successfulUrl,
                payment_method_data: {
                    billing_details: {
                        address: {
                            country: phoneCountryObject?.isoCode,
                            // postalCode: 'null',
                        },
                    },
                },
            },
        });

        if (error && error.code === 'payment_intent_authentication_failure') {
            dispatch(setPaymentPendingLoader(false))
            const data = {
                ticketCode,
                status: error.code,
                paymentIntentId: error.payment_intent.id,
            }

            await ajaxHandler({
                url: '/ajax/three-d-secure-error',
                type: 'post',
                params: data,
                stringify: true,
            })
        }

        /* error codes with generic msg https://stripe.com/docs/declines/codes */
        const errorUnknownCodes = [
            'authentication_required',
            'call_issuer',
            'card_velocity_exceeded',
            'do_not_honor',
            'do_not_try_again',
            'generic_decline',
            'new_account_information_available',
            'no_action_taken',
            'not_permitted',
            'restricted_card',
            'revocation_of_all_authorizations',
            'revocation_of_authorization',
            'security_violation',
            'service_not_allowed',
            'stop_payment_order',
            'transaction_not_allowed',
            'try_again_later',
            'lost_card',
            'stolen_card',
        ];

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.code === 'card_declined' && errorUnknownCodes.includes(error.decline_code)) {
            setMessage(<Translation translationKey="check_order.stripe.generic_decline"/>);
        } else if (error.code === 'payment_intent_authentication_failure') {
            setMessage(<Translation translationKey="check_order.stripe.three_d_secure_error"/>);
        } else {
            setMessage(error.message);
        }

        setIsLoading(false);
        dispatch(setPaymentPendingLoader(false))
    };

    const layoutType: string = !isMobile ? 'accordion' : 'tabs'

    const paymentElementOptions: StripePaymentElementOptions = {
        // @ts-ignore TODO check why layout is not in StripePaymentElementOptions
        layout: {
            type: layoutType,
            defaultCollapsed: false,
            radios: true,
            spacedAccordionItems: false,
        },
        fields: {
            billingDetails: {
                address: {
                    country: 'never',
                    // postalCode: 'never',
                },
            },
        },
        allowedCardBrands: ['visa', 'mastercard'],
    }

    let showNotificationNewProvider = false;
    if (user && user.createdAt) {
        try {
            // (user.id
            //     || (user.loginVia && (user.loginVia.pass === true || user.loginVia.google === true))
            //     || (user.userLoginMethods && user.userLoginMethods.status === true)))
            showNotificationNewProvider = isBefore(new Date(user.createdAt), new Date('2023-03-01'));
        } catch (error) {
        }
    }

    return {
        showNotificationNewProvider,
        handleSubmit,
        paymentElementOptions,
        message,
        isLoading,
        priceString,
        companyId,
    }
}