import useSingleSelectedRoute from "./useSingleSelectedRoute";
import useSearchResults from "../../../hooks/useSearchResults";
import {useLocation} from "react-router";
import useIsMobile from "@Hooks/selector/useIsMobile";
import useSingleConfirmedRoute from "@Route/hooks/useSingleConfirmedRoute";

export default function useIsCurrentRouteSingle() {
    const searchResults = useSearchResults()
    const singleSelectedRoute = useSingleSelectedRoute()
    const singleConfirmedRoute = useSingleConfirmedRoute()
    const isMobile = useIsMobile()
    const {state} = useLocation()

    // A byproduct of using different flows for mobile vs web.
    // TODO: Find a better solution if any is possible
    if (searchResults?.data?.result.params.isReturn) {
        if (!isMobile) {
            return !singleSelectedRoute
        }

        return !singleConfirmedRoute
    }

    return true
}