import useTimeUnits from "@DateTime/hooks/useTimeUnits";
import useCountdown from "@Features/countdown/hooks/useCountdown";

/**
 * Returns human readable timeUnits object based on provided expireTime
 * @param expireTime
 * @returns {{hours: string, minutes: string, seconds: string, totalTime: number}|*}
 */
export default function useHumanReadableTimer(expireTime: number) {
    const countdown = useCountdown(expireTime)
    const timeUnits = useTimeUnits(countdown)

    return {...timeUnits, totalTime: countdown}
}