import React, {ReactNode} from 'react';
import useIsMobile from "@Hooks/selector/useIsMobile";
import useIsTablet from "@Hooks/selector/useIsTablet";
import useIsSmallTablet from "@Hooks/selector/useIsSmallTablet";
import RouteBannerConnection from "@SearchResults/features/banners/components/RouteBannerConnection";
import ServiceListElement from "@SearchResults/features/banners/components/ServiceListElement";
import Link from "../Link";

interface GetByBannerProps {
    displayCondition?: boolean,
    href?: string,
    onClick?: (any?) => any,
    className?: string,
    layoutPosition?: string,
    logo?: ReactNode,
    imagePath?: string,
    imageSize?: string,
    heading?: boolean,
    description?: string,
    price?: ReactNode,
    serviceList?: string[],
    routeConnection?: boolean,
    departureStation?: string,
    destinationStation?: string,
    connectionInfo?: string,
    ctaText?: string,
    includeRel?: boolean,
}

function GetByBanner(props: GetByBannerProps) {
    const {
        displayCondition,
        href,
        onClick,
        className,
        layoutPosition,
        logo,
        imagePath,
        imageSize,
        heading,
        description,
        price,
        serviceList,
        routeConnection,
        departureStation,
        destinationStation,
        connectionInfo,
        ctaText,
        includeRel,
    } = props

    const isTablet = useIsTablet()
    const isSmallTablet = useIsSmallTablet()
    const isMobile = useIsMobile()

    const btnClassName = () => {
        if (isTablet || isSmallTablet || isMobile) {
            return 'btn--banner-label'
        } else if (ctaText.length > 17) {
            return 'btn--banner btn--compact'
        } else {
            return 'btn--banner'
        }
    }

    if (displayCondition) {
        return (
            <Link
                href={href}
                onClick={onClick}
                className={className}
                includeRel={includeRel}
            >
                <div className={`banner__component banner__component--brand banner__component--${layoutPosition}`}>
                    <div className="banner__vehicle">
                    <span className="banner__vehicle-background gb--row gb--row-middle">
                        {price}
                        <img
                            className={`banner__vehicle-image--${imageSize}`}
                            alt="Transfer vehicle"
                            src={imagePath}
                        />
                    </span>
                    </div>
                    <div className="banner__message gb--row gb--row-justify">
                        <div className="banner__heading">
                            {logo}
                            {heading && (
                                <h4>{heading}</h4>
                            )}
                            {serviceList && (
                                <ServiceListElement services={serviceList}/>
                            )}
                            {description && (
                                <div className="banner__text">{description}</div>
                            )}
                            {routeConnection && (
                                <RouteBannerConnection
                                    departureStation={departureStation}
                                    destinationStation={destinationStation}
                                    connectionInfo={connectionInfo}
                                />
                            )}
                        </div>
                        <button className={"btn btn--green " + btnClassName()} type="submit">
                            {ctaText}
                        </button>
                    </div>
                </div>
            </Link>
        )
    } else {
        return null
    }
}

export default GetByBanner;