import React from 'react';
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {LocaleType} from "@/types/Locale.type";

interface LanguageFlagProps {
    locale: LocaleType
}

const LanguageFlag = ({locale}: LanguageFlagProps) => {
    const {langSpritePositions, activeLanguages} = useAppSelector((state) => state?.page)

    return (
        <span>
            <i
                className="gb--lang-flag"
                style={{backgroundPosition: `0 ${-langSpritePositions[locale]}px`}}
            />
            {activeLanguages[locale]}
        </span>
    )
}

export default LanguageFlag;