import React, {ReactNode} from 'react';
import Row from "@Containers/components/Row";
import {isNotEmpty} from "@Array/isNotEmpty";
import SidebarSectionLoader from "./SidebarSectionLoader";
import PromiseAwaitingContainer from "@Containers/components/PromiseAwaitingContainer";

/**
 * Wrapper component used for displaying sidebar widget content.
 */

interface SidebarSectionContainerProps {
    contentArray: any[],
    /** title to display on widget */
    title: string,
    /** icon to use for display */
    icon?: ReactNode,
    children: ReactNode,
}

const SidebarSectionContainer = ({title, icon, contentArray, children}: SidebarSectionContainerProps) => (
    <PromiseAwaitingContainer data={contentArray}>
        <div className="gb--sidebar-section">
            <Row center className="gb--sidebar-section-title">
                <div className="icon-container">
                    {icon}
                </div>
                <h3>
                    {title}
                </h3>
            </Row>
            <div className="gb--sidebar-section-content">
                {isNotEmpty(contentArray) ? (
                    <>
                        {children}
                    </>
                ) : (
                    <SidebarSectionLoader expectedDataCount={4}/>
                )}
            </div>
        </div>
    </PromiseAwaitingContainer>
);

export default SidebarSectionContainer;