import {setCheckOrderPassengers} from "@CheckOrder/reducers/checkOrderSlice";
import updateArrayMember from "@Array/updateArrayMember";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";

/**
 * Updates passenger field in check order slice
 * @param index
 * @param name
 * @param value
 * @return {(function(): void)|*}
 */
export default function useUpdatePassengerField(index, name, value) {
    const passengers = useAppSelector((state) => state?.checkOrder.passengers)
    const dispatch = useAppDispatch()

    return () => {
        dispatch(setCheckOrderPassengers(updateArrayMember(passengers, index, name, value)))
    }
}