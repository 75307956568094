import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconSleepover(props: IconType) {
    const {
        width = '125',
        height = '100',
        viewBox = '0 0 125 100',
        title = 'IconSleepover',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none">
                    <path
                        d="m70.6267777 97.4685087h-68.56968709v-76.553398c0-10.1456311 6.17127184-18.44660196 13.71393739-18.44660196h41.1418123c7.5426656 0 13.7139374 8.30097086 13.7139374 18.44660196z"
                        fill="#fff"
                    />
                    <path
                        d="m60.8898822 42.1703576-1.2342544 1.4399634h10.9711499v-27.4278748c0-7.54266562-6.1712718-13.71393746-13.7139374-13.71393746h-20.5709061c0 16.79957336 9.5311865 32.15918326 24.547948 39.70184886z"
                        fill="#f5a623"
                    />
                    <path
                        d="m62.3984153 35.6562373c-.3428485 0-.6856969-.0685697-1.0285453-.2742787-8.982629-5.279866-15.4967493-14.1253556-17.8281187-24.2736693-.2742787-1.097115.4114181-2.19422997 1.5085331-2.46850872s2.19423.41141812 2.4685088 1.50853312c2.1256603 9.1883381 7.7483746 16.868143 15.9081674 21.5994514.9599756.5485575 1.302824 1.8513816.7542665 2.8113572-.4114181.7542666-1.097115 1.097115-1.7828118 1.097115z"
                        fill="#fff"
                        fillOpacity=".5"
                    />
                    <g transform="translate(0 2.057091)">
                        <path
                            d="m11.7939862 40.113267 1.2342544 1.4399634h-10.97114999v-27.4278749c0-7.54266554 6.17127184-13.71393738 13.71393739-13.71393738h20.5709062c0 16.79957338-9.5311865 32.15918328-24.547948 39.70184888z"
                            fill="#f5a623"
                        />
                        <path
                            d="m15.771028 95.6674116h-13.71393739v-50h8.70835029c4.38846 8.6486487 5.0055871 20.4054054 5.0055871 32.5675676z"
                            fill="#f5a623"
                        />
                        <g fill="#070c63">
                            <path
                                d="m2.05709061 96.6674116c-1.16568468 0-2.05709061-.7567567-2.05709061-3.054054v-44.8918919c0-2.2972973.89140593-3.0540541 2.05709061-3.0540541s2.05709062.7567568 2.05709062 3.0540541v44.8918919c0 2.2972973-.89140594 3.054054-2.05709062 3.054054z"
                            />
                            <path
                                d="m15.771028 97.0381959c-1.1656846 0-2.0570906-.891406-2.0570906-2.0570907v-32.788399c0-4.7998781-1.6456725-9.4626168-4.59416901-13.2339496-.68569687-.8914059-.5485575-2.19423.34284843-2.8799269.89140598-.6856968 2.19422998-.5485575 2.87992688.3428485 3.497054 4.4570296 5.485575 10.079744 5.485575 15.771028v32.788399c0 1.1656847-.891406 2.0570907-2.0570907 2.0570907z"
                            />
                        </g>
                    </g>
                    <path d="m56.91284 41.55323h6.856969" fill="#ff5576"/>
                    <path
                        d="m56.9128403 73.0952865h13.7139374v-25.3707843h-8.7083502c-4.38846 4.38846-5.0055872 10.3540228-5.0055872 16.5252946z"
                        fill="#ff5576"
                    />
                    <path
                        d="m63.769809 73.0952865c-1.1656847 0-2.0570906-.891406-2.0570906-2.0570906v-6.7883991c0-1.1656847.8914059-2.0570906 2.0570906-2.0570906s2.0570906.8914059 2.0570906 2.0570906v6.7883991c0 1.1656846-.8914059 2.0570906-2.0570906 2.0570906z"
                        fill="#db3e64"
                    />
                    <path
                        d="m8.91405933 99.0952865c-1.16568469 0-2.05709062-.891406-2.05709062-2.0570906v-32.7883991c0-1.1656847.89140593-2.0570906 2.05709062-2.0570906 1.16568467 0 2.05709057.8914059 2.05709057 2.0570906v32.7883991c0 1.1656846-.8914059 2.0570906-2.05709057 2.0570906z"
                        fill="#fff"
                        fillOpacity=".5"
                    />
                    <g fill="#070c63">
                        <path
                            d="m60.6841731 39.7704185c-7.6112353-4.1141812-13.7139374-10.285453-17.6224096-18.0338277-.5485575-1.0285453-1.7828119-1.4399634-2.7427875-.8914059-1.0285453.5485575-1.4399634 1.7828118-.8914059 2.7427875 4.3198903 8.4340715 11.0397196 15.2910402 19.3366518 19.7480699.3428484.1371393.6171271.2742787.9599756.2742787.7542665 0 1.4399634-.4114181 1.7828118-1.097115.5485575-.9599756.1371394-2.2627997-.8228362-2.7427875z"
                        />
                        <path
                            d="m56.9128403.41141812h-20.5709061-20.5709062c-8.70835023 0-15.771028 7.06267778-15.771028 15.77102808v25.3707842c0 1.1656847.89140593 2.0570906 2.05709061 2.0570906s2.05709062-.8914059 2.05709062-2.0570906v-25.3707842c0-6.44555063 5.21129622-11.65684685 11.65684677-11.65684685h18.4452459c-.6856969 14.81105245-9.1197684 28.18214145-22.2165787 35.24481915-1.0285453.5485575-1.3713937 1.7828119-.8228362 2.8113572.3428484.6856969 1.097115 1.097115 1.7828119 1.097115.3428484 0 .6856968-.0685697.9599756-.2742788 14.468204-7.7483746 23.656542-22.559427 24.4108086-38.81044286h18.5823852c6.4455506 0 11.6568468 5.21129622 11.6568468 11.65684676v25.3707843c0 1.1656847.891406 2.0570906 2.0570906 2.0570906 1.1656847 0 2.0570906-.8914059 2.0570906-2.0570906v-25.3707843c0-8.7769199-7.0626777-15.83959768-15.771028-15.83959768z"
                        />
                    </g>
                    <path
                        d="m70.6267777 73.0952865c-1.1656846 0-2.0570906-.891406-2.0570906-2.0570906v-21.2566031c0-1.1656846.891406-2.0570906 2.0570906-2.0570906 1.1656847 0 2.0570906.891406 2.0570906 2.0570906v21.2566031c0 1.1656846-.8914059 2.0570906-2.0570906 2.0570906z"
                        fill="#444b54"
                    />
                    <path
                        d="m56.9128403 73.0952865c-1.1656847 0-2.0570906-.891406-2.0570906-2.0570906v-6.7883991c0-5.691284 1.9199512-11.3139983 5.485575-15.771028.6856968-.8914059 1.9885209-1.0285453 2.8799268-.3428484.891406.6856968 1.0285453 1.9885209.3428485 2.8799268-2.9484966 3.7713328-4.5941691 8.4340715-4.5941691 13.2339496v6.7883991c0 1.1656846-.8914059 2.0570906-2.0570906 2.0570906z"
                        fill="#444b54"
                    />
                    <g fill="#cbdeff" transform="matrix(-1 0 0 1 53 32)">
                        <circle cx="15.9375" cy="5.3125" r="5.3125"/>
                        <circle cx="20.625" cy="10.558125" r="4.375"/>
                        <circle cx="4.375" cy="10.625" r="4.375"/>
                        <circle cx="8.4375" cy="5.9375" r="4.0625"/>
                        <circle cx="13.125" cy="9.308125" r="4.375"/>
                        <path
                            d="m1.875 12.5c0 1.380625 1.119375 2.5 2.5 2.5h15.625c1.380625 0 2.5-1.119375 2.5-2.5v-.625c0-1.380625-1.119375-2.5-2.5-2.5h-15.625c-1.380625 0-2.5 1.119375-2.5 2.5z"
                        />
                    </g>
                    <g fill="#f5a623" transform="translate(26 30)">
                        <path
                            d="m4.24584296 5c.08915515 0 .16270815-.03404018.220659-.10212054.05795084-.06808035.08023963-.14676339.06686636-.2360491l-.12035945-.72991072.50484103-.515625c.03789094-.04017857.0624086-.0859375.073553-.13727678.01337327-.05357143.01114439-.10658482-.00668664-.15904018s-.04736367-.09598214-.08859793-.13058036c-.04123426-.03459821-.08859793-.05636161-.14209102-.06529018l-.6853802-.10379464-.30758527-.65290179c-.02451766-.05357142-.06129416-.09486607-.11032949-.12388392-.04680646-.02901786-.09695623-.04352679-.15044932-.04352679s-.10364286.01450893-.15044931.04352679c-.04903533.02901785-.08581183.0703125-.1103295.12388392l-.30758526.65290179-.68538021.10379464c-.05349309.00892857-.10085676.03069197-.14209102.06529018-.04123425.03459822-.0707669.078125-.08859793.13058036s-.02061712.10491071-.00835829.15736607.03733372.09877232.07522466.13895089l.50484103.515625-.12035945.72991072c-.01337328.08928571.00891551.16796875.06686636.2360491.05795084.06808036.13150384.10212054.22065899.10212054.04903533 0 .09584179-.01227679.14041936-.03683036l.60514057-.33482143.60514057.33482143c.04457758.02455357.09138403.03683036.14041936.03683036z"
                        />
                        <path
                            d="m11.7430716 5c.1485919 0 .2711803-.05673363.367765-.17020089s.1337327-.24460566.1114439-.39341518l-.2005991-1.21651786.8414018-.859375c.0631515-.06696428.1040143-.14322917.1225883-.22879464.0222888-.08928572.018574-.17764137-.0111444-.26506697-.0297184-.08742559-.0789395-.15997023-.1476632-.21763392-.0687238-.05766369-.1476632-.09393602-.2368184-.10881697l-1.1423003-.17299107-.5126421-1.08816964c-.0408628-.08928572-.102157-.15811012-.1838825-.20647322-.0780108-.04836309-.1615937-.07254464-.2507489-.07254464-.0891551 0-.1727381.02418155-.2507488.07254464-.0817256.0483631-.1430198.1171875-.1838825.20647322l-.51264214 1.08816964-1.14230034.17299107c-.08915515.01488095-.1680946.05115328-.23681836.10881697s-.11794484.13020833-.14766322.21763392c-.02971838.0874256-.03436188.17485119-.01393049.26227679s.06222286.16462054.12537443.23158482l.84140171.859375-.20059908 1.21651786c-.02228879.14880952.01485919.27994792.11144393.39341518.09658475.11346726.21917308.17020089.36776499.17020089.08172556 0 .15973631-.02046131.23403227-.06138393l1.0085676-.55803571 1.0085676.55803571c.074296.04092262.1523068.06138393.2340323.06138393z"
                        />
                        <path
                            d="m2.99445729 12c.11887353 0 .21694419-.0453869.29421199-.1361607.07726779-.0907738.10698618-.1956845.08915515-.3147322l-.16047927-.9732142.67312137-.68750004c.05052125-.05357143.08321147-.11458334.09807067-.18303572.01783102-.07142857.01485919-.14211309-.00891552-.21205357-.02377471-.06994047-.06315156-.12797619-.11813057-.17410714s-.11813057-.07514881-.18945469-.08705357l-.91384028-.13839286-.41011368-.87053571c-.03269022-.07142858-.08172556-.1264881-.147106-.16517858-.0624086-.03869047-.12927496-.05803571-.20059908-.05803571s-.13819048.01934524-.20059909.05803571c-.06538044.03869048-.11441577.09375-.14710599.16517858l-.41011369.87053571-.91384027.13839286c-.07132412.01190476-.13447569.04092262-.18945469.08705357-.05497901.04613095-.09435587.10416667-.11813058.17410714-.0237747.06994048-.0274895.13988095-.01114439.20982143s.04977829.13169643.10029954.18526786l.67312138.68750004-.16047927.9732142c-.01783103.1190477.01188735.2239584.08915515.3147322.07726779.0907738.17533846.1361607.29421199.1361607.06538044 0 .12778904-.016369.18722581-.0491071l.8068541-.4464286.80685409.4464286c.05943677.0327381.12184537.0491071.18722582.0491071z"
                        />
                    </g>
                    <g transform="translate(33 38)">
                        <path
                            d="m9.00001022 17.5607833c3.32589678 0 6.38393618-1.9531274 7.77902708-4.9776846.1227681-.2678574.0669644-.5915185-.1450894-.8035723-.2120538-.2120539-.5245542-.2678575-.8035724-.1450895-.8147331.3794647-1.6629484.5691971-2.5446459.5691971-3.34821826 0-6.0714358-2.72321752-6.0714358-6.0714358 0-1.71875205.70312584-3.30357537 1.97544879-4.46429104.21205382-.20089309.29017891-.52455419.16741091-.80357238-.11160728-.26785747-.36830401-.44642911-.68080438-.43526838-4.62054122.16741091-8.24777769 3.92857611-8.24777769 8.56027806 0 4.72098774 3.85045102 8.57143884 8.57143879 8.57143884z"
                            fill="#070c63"
                        />
                        <path
                            d="m9.00001022 16.1322101c-3.93973684 0-7.14286566-3.2031288-7.14286566-7.14286564 0-3.236611 2.12053824-5.95982853 5.08929178-6.84152601-.74776875 1.18303712-1.16071567 2.56696734-1.16071567 3.98437975 0 4.1406299 3.35937901 7.5000089 7.50000893 7.5000089.4129469 0 .8258939-.0334821 1.22768-.1004465-1.3392873 1.6183055-3.359379 2.6004495-5.51339938 2.6004495z"
                            fill="#f5a623"
                        />
                    </g>
                    <g transform="translate(44 25)">
                        <path
                            d="m76.9911504 68.6946903h-73.00884952v-16.5929204c0-1.8584071 1.460177-3.3185841 3.31858408-3.3185841h66.37168144c1.8584071 0 3.318584 1.460177 3.318584 3.3185841z"
                            fill="#d8d4ea"
                        />
                        <path
                            d="m76.9911504 68.6946903h-73.00884952v-16.5929204c0-1.8584071 1.460177-3.3185841 3.31858408-3.3185841h66.37168144c1.8584071 0 3.318584 1.460177 3.318584 3.3185841z"
                            fill="#4a79e2"
                        />
                        <path
                            d="m76.3274336 72.6769912h-71.6814159v-4.9778762c0-.5575221 1.43362832-.9955752 3.25824618-.9955752h65.16492352c1.8246179 0 3.2582462.4380531 3.2582462.9955752z"
                            fill="#b5b7d1"
                        />
                        <path
                            d="m76.9911504 48.7831858h-73.00884952v-39.82300881c0-3.65044248 2.98672567-6.63716814 6.63716812-6.63716814h59.7345133c3.6504425 0 6.6371681 2.98672566 6.6371681 6.63716814z"
                            fill="#dbe7fd"
                        />
                        <g fill="#b5b7d1">
                            <path
                                d="m15.9469027 24.8893805s.1327433-.0663717.4646017-.1327433c.2654868-.0663717.7300885-.1659292 1.1946903-.2654868.9955752-.1659292 2.3230088-.2654867 3.6504425-.2654867 1.3274336 0 2.6548672.0995575 3.6504424.2654867.5309735.0663717.9292036.1659292 1.1946903.2654868.2654867.0663716.4646018.1327433.4646018.1327433s-.1327434.0663717-.4646018.1327434c-.2654867.0663717-.7300885.1659292-1.1946903.2654867-.9955752.1659292-2.3230088.2654867-3.6504424.2654867-1.3274337 0-2.6548673-.0995575-3.6504425-.2654867-.5309735-.0663717-.9292035-.1659292-1.1946903-.2654867-.3318584-.0663717-.4646017-.1327434-.4646017-.1327434z"
                            />
                            <path
                                d="m21.2566372 30.199115s-.0663717-.1327433-.1327434-.4646017c-.0663717-.2654868-.1659292-.7300885-.2654867-1.1946903-.1659292-.9955752-.2654867-2.3230088-.2654867-3.6504425 0-1.3274336.0995575-2.6548672.2654867-3.6504424.0663717-.5309735.1659292-.9292036.2654867-1.1946903.0663717-.2654867.1327434-.4646018.1327434-.4646018s.0663716.1327434.1327433.4646018c.0663717.2654867.1659292.7300885.2654868 1.1946903.1659292.9955752.2654867 2.3230088.2654867 3.6504424 0 1.3274337-.0995575 2.6548673-.2654867 3.6504425-.0663717.5309735-.1659292.9292035-.2654868 1.1946903-.0663717.2654867-.1327433.4646017-.1327433.4646017z"
                            />
                            <path
                                d="m35.1769912 24.8893805s.1327433-.0663717.4646017-.1327433c.2654867-.0663717.7300885-.1659292 1.1946903-.2654868.9955752-.1659292 2.3230088-.2654867 3.6504425-.2654867 1.3274336 0 2.6548672.0995575 3.6504424.2654867.5309735.0663717.9292036.1659292 1.1946903.2654868.2654867.0663716.4646018.1327433.4646018.1327433s-.1327434.0663717-.4646018.1327434c-.2654867.0663717-.7300885.1659292-1.1946903.2654867-.9955752.1659292-2.3230088.2654867-3.6504424.2654867-1.3274337 0-2.6548673-.0995575-3.6504425-.2654867-.5309735-.0663717-.9292036-.1659292-1.1946903-.2654867-.3318584-.0663717-.4646017-.1327434-.4646017-.1327434z"
                            />
                            <path
                                d="m40.4867257 30.199115s-.0663717-.1327433-.1327434-.4646017c-.0663717-.2654868-.1659292-.7300885-.2654867-1.1946903-.1659292-.9955752-.2654868-2.3230088-.2654868-3.6504425 0-1.3274336.0995576-2.6548672.2654868-3.6504424.0663717-.5309735.1659292-.9292036.2654867-1.1946903.0663717-.2654867.1327434-.4646018.1327434-.4646018s.0663716.1327434.1327433.4646018c.0663717.2654867.1659292.7300885.2654868 1.1946903.1659292.9955752.2654867 2.3230088.2654867 3.6504424 0 1.3274337-.0995575 2.6548673-.2654867 3.6504425-.0663717.5309735-.1659293.9292035-.2654868 1.1946903-.0663717.2654867-.1327433.4646017-.1327433.4646017z"
                            />
                            <path
                                d="m54.4070796 24.8893805s.1327434-.0663717.4646018-.1327433c.2654867-.0663717.7300885-.1659292 1.1946903-.2654868.9955752-.1659292 2.3230088-.2654867 3.6504425-.2654867 1.3274336 0 2.6548672.0995575 3.6504424.2654867.5309735.0663717.9292036.1659292 1.1946903.2654868.2654867.0663716.4646018.1327433.4646018.1327433s-.1327434.0663717-.4646018.1327434c-.2654867.0663717-.7300885.1659292-1.1946903.2654867-.9955752.1659292-2.3230088.2654867-3.6504424.2654867-1.3274337 0-2.6548673-.0995575-3.6504425-.2654867-.5309735-.0663717-.9292036-.1659292-1.1946903-.2654867-.3318584-.0663717-.4646018-.1327434-.4646018-.1327434z"
                            />
                            <path
                                d="m59.7168142 30.199115s-.0663717-.1327433-.1327434-.4646017c-.0663717-.2654868-.1659292-.7300885-.2654867-1.1946903-.1659292-.9955752-.2654868-2.3230088-.2654868-3.6504425 0-1.3274336.0995576-2.6548672.2654868-3.6504424.0663717-.5309735.1659292-.9292036.2654867-1.1946903.0663717-.2654867.1327434-.4646018.1327434-.4646018s.0663716.1327434.1327433.4646018c.0663717.2654867.1659292.7300885.2654867 1.1946903.1659293.9955752.2654868 2.3230088.2654868 3.6504424 0 1.3274337-.0995575 2.6548673-.2654868 3.6504425-.0663716.5309735-.1659292.9292035-.2654867 1.1946903-.0663717.2654867-.1327433.4646017-.1327433.4646017z"
                            />
                        </g>
                        <path
                            d="m22.8274336 11.6150442h54.1637168c0-3.65044243-2.9867256-9.29203535-6.6371681-9.29203535h-59.7345133c-3.65044245 0-6.63716812 2.98672566-6.63716812 6.63716814v39.82300881h.92920354l4.17699116-28.0752212c.76725412-8.3954646 6.17256632-9.0929204 13.73893802-9.0929204z"
                            fill="#fff"
                        />
                        <path
                            d="m76.9911504 43.8053097c-1.1283185 0-1.9911504-.8628318-1.9911504-1.9911504v-33.18584072c0-2.58849557-2.0575221-4.6460177-4.6460177-4.6460177h-59.7345133c-2.58849555 0-4.64601767 2.05752213-4.64601767 4.6460177v31.85840712c0 1.1283185-.86283186 1.9911504-1.99115045 1.9911504-1.12831858 0-1.99115044-.8628319-1.99115044-1.9911504v-31.85840712c0-4.77876106 3.84955752-8.62831858 8.62831856-8.62831858h59.7345133c4.7787611 0 8.6283186 3.84955752 8.6283186 8.62831858v33.18584072c0 1.1283186-.8628319 1.9911504-1.9911505 1.9911504z"
                            fill="#070c63"
                        />
                        <g fill="#070c63" transform="translate(19.265487 23.230088)">
                            <path
                                d="m2.01215414 3.31858407c-.44807886 0-.89615772-.16802957-1.17620701-.50408872-.33605915-.33605915-.50408872-.72812815-.50408872-1.17620701s.16802957-.89615772.50408872-1.17620701c.61610843-.61610844 1.73630559-.61610844 2.35241402 0 .33605915.33605914.50408872.72812815.50408872 1.17620701s-.16802957.89615772-.50408872 1.17620701c-.28004929.33605915-.72812815.50408872-1.17620701.50408872z"
                            />
                            <path
                                d="m21.2422426 3.31858407c-.4480788 0-.8961577-.16802957-1.176207-.50408872-.3360591-.33605915-.5040887-.72812815-.5040887-1.17620701s.1680296-.89615772.5040887-1.17620701c.6161085-.61610844 1.7363056-.61610844 2.352414 0 .3360592.33605914.5040888.72812815.5040888 1.17620701s-.1680296.89615772-.5040888 1.17620701c-.2800492.33605915-.7281281.50408872-1.176207.50408872z"
                            />
                            <path
                                d="m40.4723311 3.31858407c-.4480788 0-.8961577-.16802957-1.176207-.50408872-.3360591-.33605915-.5040887-.72812815-.5040887-1.17620701s.1680296-.89615772.5040887-1.17620701c.6161085-.61610844 1.7363056-.61610844 2.352414 0 .3360592.33605914.5040888.72812815.5040888 1.17620701s-.1680296.89615772-.5040888 1.17620701c-.2800492.33605915-.7281281.50408872-1.176207.50408872z"
                            />
                        </g>
                        <path
                            d="m73.6725664 45.5343666h-.7300885l1.7920354-1.1793039-2.6548673-1.794593c-8.6283186-5.7939717-22.6327433-5.7939717-31.2610619 0l-.3318584.1538222-.2654868-.2050963c-8.6283185-5.7939717-22.6327433-5.7939717-31.26106191 0l-2.65486726 1.794593 1.7920354 1.1793039h-.79646017c-1.85840708 0-2.95405067 6.5104366-2.95405067 7.946111h28.94325001 41.7099157c0-1.4356744.5309735-7.8948369-1.3274336-7.8948369z"
                            fill="#fff"
                        />
                        <path
                            d="m3.98230088 70.6858407c-1.12831858 0-1.99115044-.8628319-1.99115044-1.9911504v-13.2743363c0-1.1283186.86283186-1.9911505 1.99115044-1.9911505 1.12831859 0 1.99115045.8628319 1.99115045 1.9911505v13.2743363c0 1.1283185-.86283186 1.9911504-1.99115045 1.9911504z"
                            fill="#070c63"
                        />
                        <path
                            d="m78.443031 75c-1.4339049 0-2.5304204-.8628319-2.5304204-1.9911504v-4.6460177h-70.85176989v4.6460177c0 1.1283185-1.09651549 1.9911504-2.53042036 1.9911504-1.43390486 0-2.53042035-.8628319-2.53042035-1.9911504v-6.6371682c0-1.1283186 1.09651549-1.9911504 2.53042035-1.9911504h75.91261065c1.4339048 0 2.5304203.8628318 2.5304203 1.9911504v6.6371682c0 1.1283185-1.0965155 1.9911504-2.5304203 1.9911504z"
                            fill="#070c63"
                        />
                        <path
                            d="m76.9911504 70.6858407c-1.1283185 0-1.9911504-.8628319-1.9911504-1.9911504v-13.2743363c0-1.1283186.8628319-1.9911505 1.9911504-1.9911505 1.1283186 0 1.9911505.8628319 1.9911505 1.9911505v13.2743363c0 1.1283185-.8628319 1.9911504-1.9911505 1.9911504z"
                            fill="#070c63"
                        />
                        <path
                            d="m76.9911504 57.079646c-1.1283185 0-1.9911504-.8628318-1.9911504-1.9911504v-3.3185841c0-.7300885-.5973451-1.3274336-1.3274336-1.3274336h-66.37168144c-.7300885 0-1.32743363.5973451-1.32743363 1.3274336v3.3185841c0 1.1283186-.86283186 1.9911504-1.99115045 1.9911504-1.12831858 0-1.99115044-.8628318-1.99115044-1.9911504v-3.3185841c0-2.920354 2.38938053-5.3097345 5.30973452-5.3097345h66.37168144c2.920354 0 5.3097345 2.3893805 5.3097345 5.3097345v3.3185841c0 1.1283186-.8628319 1.9911504-1.9911505 1.9911504z"
                            fill="#070c63"
                        />
                    </g>
                </g>
            )}
        />

    );
}

export default IconSleepover;
