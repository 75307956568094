import React from 'react';
import MyTicketsMenu from "@User/components/MyTicketsMenu";
import {useTranslation} from "@Translation/hooks/useTranslation";
import MobileOverlay from "./MobileOverlay";

function MobileMyTicketsMenu() {
    const title = useTranslation("sys.my_tickets")

    return (
        <MobileOverlay title={title} slideInDirection="left">
            <MyTicketsMenu/>
        </MobileOverlay>
    );
}

export default MobileMyTicketsMenu;