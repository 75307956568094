import React from 'react';
import Notification from "@Features/notification/components/Notification";

interface AppliedVoucherNotificationProps {
    ticketVoucher: any,
    voucherDetails: any,
}

function AppliedVoucherNotification({ticketVoucher, voucherDetails}: AppliedVoucherNotificationProps) {
    const notificationColor = voucherDetails ? 'green' : 'red'
    const notificationType = ticketVoucher?.userMessage ? notificationColor : 'blue'

    if (ticketVoucher?.userMessage) {
        return (
            <Notification
                isLarge
                notificationType={notificationType}
            >
                {ticketVoucher.userMessage.userMessage}
            </Notification>
        );
    }
    return null;
}

export default AppliedVoucherNotification;