import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";
import {FilterPopularFiltersType} from "@SearchResults/reducers/filterSlice";
import {useAppSelector} from "@Hooks/selector/useAppSelector";

export default function useFilterByPopularFilter() {
    const {
        mTicket,
        instantConfirmation,
        webDiscount,
    }: FilterPopularFiltersType = useAppSelector((state) => state.filter.filterPopularFilters)

    const filterByMTicket = (route: RouteWithAddedPropsAndPricesType) => {
        return route.status.hasMTicket
    }

    const filterByInstantConfirmation = (route: RouteWithAddedPropsAndPricesType) => {
        return route.status.isInstantConfirmation
    }

    const filterByDiscount = (route: RouteWithAddedPropsAndPricesType) => {
        return route.prices?.activeDiscount
    }

    return {
        filterByMTicket,
        filterByInstantConfirmation,
        filterByDiscount,
        instantConfirmation,
        mTicket,
        webDiscount,
    }
}