import React from 'react'
import Row from "@Containers/components/Row";
import Column from "@Containers/components/Column";
import SourcedImage from "@Images/components/SourcedImage";
import AppStoreLinksContainer from "@Sidebar/components/AppStoreNotice/AppStoreLinksContainer";
import {useTranslation} from "@Translation/hooks/useTranslation";
import useIsMobile from "@Hooks/selector/useIsMobile";
import useIsTablet from "@Hooks/selector/useIsTablet";

const AppIntro = () => {
    const {
        title,
        intro_text: introText,
        more_to_come: moreToCome,
    } = useTranslation("mobile_app")

    const isMobile = useIsMobile()
    const isTablet = useIsTablet()

    return (
        <div className="app-overview-intro-container">
            <Row>
                <div>
                    <h1>
                        {title}
                    </h1>
                    <p className="mobile-app-landing-page-description">
                        {introText.planning_a_trip}
                        <b>{introText.number_of_countries}</b>
                        <br/>
                        {introText.seamless_and_hassle_free}
                        <b>{introText.number_of_departures}</b>
                        {introText.choose_from}
                    </p>
                </div>
                {isMobile && (
                    <Column
                        alignStart
                        className="app-overview-img-container"
                    >
                        <div className="app-overview-img">
                            <SourcedImage
                                filePath="images/mobile-app-landing-page/all_in_one_app_hero_img"
                                fileType="png"
                            />
                        </div>
                    </Column>
                )}
            </Row>
            {(isTablet || isMobile) && (
                <AppStoreLinksContainer className="app-overview-links"/>
            )}
        </div>
    )
}

export default AppIntro