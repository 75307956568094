import React from "react";
import useMyProfileLink from "@User/hooks/useMyProfileLink";
import IconUserOutline from "@Icons/IconUserOutline";
import NavigationContextAwareNavLink from "@Routing/components/NavigationContextAwareNavLink";

function MyProfileLink({className, showIcon}: { className?: string, showIcon?: boolean }) {
    const {label, href} = useMyProfileLink()

    return (
        <NavigationContextAwareNavLink
            routeName="myProfile"
            className={className}
            to={href}
        >
            {showIcon && (
                <IconUserOutline
                    width="25"
                    height="20"
                />
            )}
            {label}
        </NavigationContextAwareNavLink>
    );
}

export default MyProfileLink;