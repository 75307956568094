import React from 'react';
import MyTicketsListItem from "./MyTicketsListItem";
import {toggleMobileMyTickets} from "@Reducers/mobileSlice";
import ClickOutsideAwareComponent from "../../clickOutsideAwareComponent/components/ClickOutsideAwareComponent";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";

function MyTicketsMenu() {
    const dispatch = useAppDispatch()
    const onClickOutside = () => dispatch(toggleMobileMyTickets())

    return (
        <ClickOutsideAwareComponent onClickOutside={onClickOutside}>
            <MyTicketsListItem
                showListItems="gb--menu gb--menu-mobile"
            />
        </ClickOutsideAwareComponent>
    );
}

export default MyTicketsMenu
