import {SeatRowType} from "@CheckOrder/components/MainSection/SeatPicker/types/SeatRow.type";
import Seat from "@CheckOrder/components/MainSection/SeatPicker/components/Seat";
import Row from "@Containers/components/Row";
import Corridor from "@CheckOrder/components/MainSection/SeatPicker/components/Corridor";

type SeatRowProps = {
    seatRow: SeatRowType,
    corridorIndex: number,
    rowIndex: number
    isReturn: boolean
}

function SeatRow({seatRow, corridorIndex, rowIndex, isReturn}: SeatRowProps) {
    return (
        <Row justify>
            {seatRow.seats.map((seat, index) => {
                const isCorridor = index === corridorIndex;
                if (isCorridor) {
                    return (
                        <Corridor
                            rowIndex={rowIndex}
                        />
                    )
                }

                return (
                    <Seat
                        seat={seat}
                        key={index}
                        isReturn={isReturn}
                    />
                )
            })}
        </Row>
    );
}

export default SeatRow;