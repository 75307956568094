import React from "react";
import useActivateMobileFormOverlay from "@Form/hooks/useActivateMobileFormOverlay";
import IconPenWithNotepad from "@Icons/IconPenWithNotepad";
import Translation from "@Translation/components/Translation";

function EditSearchButton() {
    const {toggleFormAction} = useActivateMobileFormOverlay()

    return (
        <button
            onClick={toggleFormAction}
            type="button"
            className="gb--emphasize gb--emphasize-default"
        >
            <Translation translationKey="sys.edit"/>
            <IconPenWithNotepad width="18" height="22" viewBox="0 -8 20 22" fill="navy" style={{marginLeft: "3px"}}/>
        </button>
    );
}

export default EditSearchButton;