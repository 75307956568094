import {Children, ReactNode, useState} from "react";
import Row from "@Containers/components/Row";
import useSliderArrows from "@Features/slider/hooks/useSliderArrows";
import SliderArrow from "@Features/slider/components/SliderArrow";
import useMoveSliderByTouch from "@Features/slider/hooks/useMoveSliderByTouch";
import SliderItem from "@Features/slider/components/SliderItem";
import matchMoveDistanceToTransitionDuration from "@Features/slider/functions/matchMoveDistanceToTransitionDuration";

interface SliderProps {
    itemsPerSlide: number;
    children: ReactNode;
    className?: string;
    oneByOne?: boolean;
    dataLength: number;
}

/**
 * Slider component, used for... sliders (carousel)
 * To use it, pass the cards as children of this component
 *
 * TODO fix moveStep = 100
 *
 * itemsPerSlide:
 *      number of items per slide
 *      IF:
 *        - child component has set min-width
 *        - width of of an element calculated to fit itemsPerSlide on screen is lower than child min-width
 *      itemsPerSlide is "ignored" and new wrapper width == child width + defaultGapBetweenSlidesInPercentage
 */
function Slider(props: SliderProps) {
    const {
        itemsPerSlide,
        children,
        className = "",
        oneByOne = true,
        dataLength,
    } = props

    const [slideIndex, setSlideIndex] = useState(0);
    const [itemWidth, setItemWidth] = useState<number>(100 / itemsPerSlide)
    const [translateX, setTranslateX] = useState<number>(0)
    const [moveDistance, setMoveDistance] = useState<number>(null)

    const moveStep = oneByOne ? itemWidth : 100;

    const {
        moveToLeft,
        moveToRight,
    } = useSliderArrows({slideIndex, setSlideIndex, setTranslateX, dataLength, moveStep})

    const {
        sliderRef,
    } = useMoveSliderByTouch({moveStep, setTranslateX, setSlideIndex, setMoveDistance, dataLength})

    return (
        <Row center>
            <SliderArrow
                direction="left"
                move={moveToLeft}
                disabled={slideIndex === 0}
            />
            <div className={`slider ${className}`} ref={sliderRef}>
                <div
                    className="slider-cards"
                    style={{
                        ...(moveDistance ? {transition: `transform ${matchMoveDistanceToTransitionDuration(moveDistance)}ms ease-in-out`} : {}),
                        transform: `translateX(-${translateX}%)`,
                    }}
                >
                    {Children.map(children, (child) => (
                        <SliderItem
                            itemWidth={itemWidth}
                            child={child}
                            setItemWidth={setItemWidth}
                        />
                    ))}
                </div>
            </div>
            <SliderArrow
                direction="right"
                move={moveToRight}
                disabled={slideIndex === dataLength - 1}
            />
        </Row>
    );
}

export default Slider;