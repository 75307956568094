import {useMatches} from "react-router";
import {useAppSelector} from "@Hooks/selector/useAppSelector";

export default function useIsClientsideRoutingSupported(routeName: string) {
    const clientSupportedRoutes = [
        'homepage',
        'searchResults',
        'checkOrder',
        'myProfile',
    ]

    const serverMatchRouteNames = [
        'front_homepage',
        'myProfile',
        'bus_front_slug_search_results',
        'ferry_front_slug_search_results',
        'gbb_front_my_profile',
        'gbf_front_my_profile',
    ]

    const matches = useMatches();
    const is404Page = useAppSelector((state) => (state.page.is404Page))

    const activeMatchedIds = matches.map(({id}) => id)

    // Full clientside routing is not fully supported here however it works via hook that handles dom elements
    // by supporting this we improve the overall ux flow
    // Disabled as of 2024.08.16 -- not reliable enough and causes some back & forward issues with hiding elements
    // if (routeName === 'base_permalink') {
    //     return true
    // }
    // For 404 pages, always use full redirect

    if (is404Page) {
        return false
    }

    return clientSupportedRoutes.some((route) => activeMatchedIds.includes(route)) && serverMatchRouteNames.includes(routeName)
}