import React from 'react';
import StationToStationNotificationsType from "@SearchResults/types/params/StationToStationNotifications.type";
import StationToStationNotification from "./StationToStationNotification";
import CompanyType from "@SearchResults/types/company/Company.type";

interface StationToStatiNotificationsProps {
    stationToStationNotifications: StationToStationNotificationsType[],
    companies: Record<string, CompanyType>
}

const StationToStationNotifications = ({stationToStationNotifications, companies}: StationToStatiNotificationsProps) => (
    <>
        {stationToStationNotifications.map((notification) => (
            <StationToStationNotification
                companies={companies}
                key={notification?.id}
                notification={notification}
            />
        ))}
    </>
)

export default StationToStationNotifications
