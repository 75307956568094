import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconHandLuggage(props: IconType) {
    const {
        width = '35',
        height = '35',
        viewBox = '0 0 35 35',
        title = 'IconHandLuggage',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none" fillRule="evenodd" transform="translate(2 5)">
                    <rect fill="#5e6a74" height="23" rx="1" width="30" x="1" y="3"/>
                    <path
                        d="m11 0h9c1.1045695 0 2 .8954305 2 2v1c0 .55228475-.4477153 1-1 1h-11c-.55228475 0-1-.44771525-1-1v-1c0-1.1045695.8954305-2 2-2z"
                        fill="#bb464b"
                    />
                    <g transform="translate(0 3)">
                        <path
                            d="m1 2h30c.5522847 0 1 .44771525 1 1v7.5131934c-.000017.4517222-.3028565.8473547-.7389099.9652969-8.6797693 2.3476731-13.7667994 3.5215097-15.2610901 3.5215097-1.4942905 0-6.58131918-1.1738362-15.261086-3.5215086-.43606673-.117937-.738914-.5135796-.738914-.9653133v-7.5131781c0-.55228475.44771525-1 1-1z"
                            fill="#40484f"
                        />
                        <path
                            d="m1 1h30c.5522847 0 1 .44771525 1 1v7.51319338c-.000017.4517222-.3028565.84735472-.7389099.96529692-8.6797693 2.3476731-13.7667994 3.5215097-15.2610901 3.5215097-1.4942905 0-6.58131918-1.1738362-15.261086-3.5215086-.43606673-.117937-.738914-.51357959-.738914-.96531329v-7.51317811c0-.55228475.44771525-1 1-1z"
                            fill="#738391"
                        />
                        <path
                            d="m1 0h30c.5522847 0 1 .44771525 1 1v7.51319338c-.000017.4517222-.3028565.8473547-.7389099.96529688-8.6797693 2.34767314-13.7667994 3.52150974-15.2610901 3.52150974-1.4942905 0-6.58131918-1.1738362-15.261086-3.52150864-.43606673-.11793698-.738914-.51357955-.738914-.96531325v-7.51317811c0-.55228475.44771525-1 1-1z"
                            fill="#5e6a74"
                        />
                    </g>
                    <rect fill="#bb464b" height="5" rx="1" width="4" x="14" y="14"/>
                </g>
            )}
        />

    );
}

export default IconHandLuggage;
