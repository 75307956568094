import useSearchResults from "@SearchResults/hooks/useSearchResults";

/**
 * Returns companies object
 */
export default function useCompanies() {
    const {
        data: {
            result: {
                companies,
            },
        },
    } = useSearchResults()

    return companies
}