import RouteType from "@Route/types/Route.type";
import TicketRouteType from "@MyTicket/types/TicketRoute.type";

interface CreateRouteAttributes {
    id: string,
    route: TicketRouteType,
}

export const createRoute = ({id, route}: CreateRouteAttributes): RouteType => (
    {
        id: `${id}`,
        companyId: route.companyId,
        prices: {},
        travelInfo: {
            // @ts-ignore
            departureStation: {
                name: route.departureStation.name,
                departureTime: route.departureStation.departureTime,
                departureDate: route.departureStation.departureDate,
            },
            // @ts-ignore
            destinationStation: {
                name: route.destinationStation.name,
                arrivalTime: route.destinationStation.arrivalTime,
                arrivalDate: route.destinationStation.arrivalDate,
            },
            busChange: [],
            passengerGroups: {},
            departureStationIndex: route.departureStation.id,
            destinationStationIndex: route.destinationStation.id,
            // @ts-ignore
            timetable: {},
        },
        // @ts-ignore
        status: {
            status: "open",
            soldOut: false,
            locked: false,
            isSuggestion: false,
            hasMTicket: route.hasMTicket,
            isApi: route.isApi,
            isApiFullIntegration: false,
            hasRedirectLink: false,
            showStations: false,
            showBuyFromLabel: false,
            isBookAway: false,
            isJadrolinijaStateLine: false,
        },
        allotment: {
            totalAllotmentLeft: 0,
            startTime: "",
        },
// @ts-ignore
        type: {
            routeTransferType: route.transportTypeId,
            vehicleType: {
                isMinibus: false,
                mightBeMinibus: false,
            },
        },
        ratings: {
            companyRating: {
                score: 0,
                reviewCount: 0,
            },
            routeRating: {
                score: 0,
                reviewCount: 0,
            },
            stationRating: {
                score: 0,
                reviewCount: 0,
            },
        },
    }
)