import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconSignpost(props: IconType) {
    const {
        width = '85',
        height = '85',
        fill = '#fff',
        viewBox = '0 0 85 85',
        title = 'IconSignpost',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none">
                    <path d="m46.1637931 45.2844828h-19.5l-6.5-7.3275862 6.5-7.3275863h19.5" fill="#fff"/>
                    <path
                        d="m46.1637931 34.2327586h29.3103448l7.3275862-7.3275862-7.3275862-7.3275862h-29.3103448"
                        fill="#7ed321"
                    />
                    <path
                        d="m46.1637931 24.1810345h-36.63793103l-7.32758621-7.3275862 7.32758621-7.32758623h36.63793103"
                        fill="#fff"
                    />
                    <g fill="#070c63">
                        <path
                            d="m46.1637931 85c-1.2456897 0-2.1982759-.9525862-2.1982759-2.1982759v-80.60344824c0-1.24568965.9525862-2.19827586 2.1982759-2.19827586s2.1982759.95258621 2.1982759 2.19827586v80.60344824c0 1.2456897-.9525862 2.1982759-2.1982759 2.1982759z"
                        />
                        <path
                            d="m38.8362069 26.3793103h-29.31034483c-.5862069 0-1.17241379-.2198275-1.5387931-.6594827l-7.32758621-7.3275862c-.87931035-.8793104-.87931035-2.2715517 0-3.0775862l7.32758621-7.32758623c.43965517-.43965518.9525862-.65948276 1.5387931-.65948276h29.31034483c1.2456897 0 2.1982759.9525862 2.1982759 2.19827586 0 1.24568963-.9525862 2.19827583-2.1982759 2.19827583h-28.4310345l-5.12931033 5.1293104 5.12931033 5.1293103h28.4310345c1.2456897 0 2.1982759.9525862 2.1982759 2.1982759 0 1.2456896-.9525862 2.1982758-2.1982759 2.1982758z"
                        />
                        <path
                            d="m39.0344828 47.4827586h-13.3333334c-.5333333 0-1.0666666-.2198276-1.4-.6594827l-6.6666666-7.3275862c-.8-.8793104-.8-2.2715518 0-3.0775863l6.6666666-7.3275862c.4-.4396551.8666667-.6594827 1.4-.6594827h13.3333334c1.1333333 0 2 .9525862 2 2.1982758 0 1.2456897-.8666667 2.1982759-2 2.1982759h-12.5333334l-4.6666666 5.1293104 4.6666666 5.1293103h12.5333334c1.1333333 0 2 .9525862 2 2.1982759 0 1.2456896-.8666667 2.1982758-2 2.1982758z"
                        />
                        <path
                            d="m75.4741379 36.4310345h-29.3103448c-1.2456897 0-2.1982759-.9525862-2.1982759-2.1982759 0-1.2456896.9525862-2.1982758 2.1982759-2.1982758h28.4310345l5.1293103-5.1293104-5.1293103-5.1293103h-28.4310345c-1.2456897 0-2.1982759-.9525862-2.1982759-2.1982759 0-1.2456896.9525862-2.1982759 2.1982759-2.1982759h29.3103448c.5862069 0 1.1724138.2198276 1.5387931.6594828l7.3275862 7.3275862c.8793104.8793104.8793104 2.2715517 0 3.0775862l-7.3275862 7.3275862c-.3663793.4396552-.9525862.6594828-1.5387931.6594828z"
                        />
                        <path
                            d="m60.8189655 85h-29.3103448c-1.2456897 0-2.1982759-.9525862-2.1982759-2.1982759 0-1.2456896.9525862-2.1982758 2.1982759-2.1982758h29.3103448c1.2456897 0 2.1982759.9525862 2.1982759 2.1982758 0 1.2456897-.9525862 2.1982759-2.1982759 2.1982759z"
                        />
                    </g>
                </g>
            )}
        />

    );
}

export default IconSignpost;
