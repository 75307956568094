import {useAppSelector} from "@Hooks/selector/useAppSelector";
import useGetCheapestGbtVehicle
    from "@SearchResults/features/banners/features/getByTransferBanner/hooks/useGetCheapestGbtVehicle";
import useIsFerry from "@GetByFerry/hooks/useIsFerry";
import {TransfersDataType} from "@SearchResults/features/banners/features/getByTransferBanner/types/TransfersData.type";
import {
    GetByFerryDataType
} from "@SearchResults/features/banners/features/getByTransferBanner/types/GetByFerryData.type";
import {useMemo} from "react";

interface UseGbtBannerReturnType {
    gbtBannerPosition: "top" | "bottom" | false,
    gbtBannerType: "vehicle-snippets" | "banner" | false,
    gbtShowBanner: boolean,
    gbtShowNoDirectRoutesNotification: boolean,
}

interface UseGetBannerAttributes {
    transfersData: TransfersDataType,
    getByFerryData: GetByFerryDataType,
    numberOfResults?: number,
}

/**
 * Returns everything transfer banner related
 */
export default function useGbtBanner(props: UseGetBannerAttributes): UseGbtBannerReturnType {
    const {
        transfersData,
        getByFerryData,
        numberOfResults,
    } = props

    const {
        maxRoutePrice,
    } = useAppSelector((state) => (state.filter))

    const {
        departureStationObject: {
            id: departureStationId,
        },
        destinationStationObject: {
            id: destinationStationId,
        },
    } = useAppSelector((state) => (state.form))

    const isFerry = useIsFerry()

    const {
        departureIsAirport,
        destinationIsAirport,
        minPrice,
        strictPosition,
        noDirectBuses,
        vehicles,
        departureNearbyCity,
        destinationNearbyCity,
    } = transfersData || {}

    const vehicle = useGetCheapestGbtVehicle(vehicles ?? [])

    // SHOW NO DIRECT ROUTES NOTIFICATION IF
    // there are no routes and neither departure nor destination is a nearby city
    //
    // this is memoized as not to change once the departureStationId or destinationStationId is switched in search form,
    // but only to update once the transfersData is updated
    const gbtShowNoDirectRoutesNotification = useMemo(() => (
        numberOfResults === 0
        && destinationNearbyCity?.id !== `${departureStationId}`
        && departureNearbyCity?.id !== `${destinationStationId}`
    ), [transfersData, numberOfResults])

    if (!transfersData || !vehicles || !vehicles.length || isFerry) {
        return {
            gbtBannerPosition: false,
            gbtBannerType: false,
            gbtShowBanner: false,
            gbtShowNoDirectRoutesNotification: false,
        }
    }

    const isAverageInRange = () => (
        maxRoutePrice * 1.5 >= minPrice
    )

    const getGbtBannerPosition = () => {
        if (strictPosition) {
            return strictPosition
        }

        if (getByFerryData) {
            return 'bottom'
        }

        if (departureIsAirport || destinationIsAirport) {
            if (minPrice <= 600 || isAverageInRange()) {
                return 'top'
            }
            return false
        }

        if (numberOfResults === 0) {
            return "top"
        }

        return "bottom"
    }

    const getGbtBannerType = () => {
        if (((departureIsAirport || destinationIsAirport) && noDirectBuses) || !(departureIsAirport || destinationIsAirport)) {
            return "vehicle-snippets"
        }
        return "banner"
    }

    // SHOW BANNER IF
    // there is the cheapest vehicle (that means there are vehicles in general) and price is not bigger than 600 and is AverageInRange
    // or if there are vehicles and strict position is defined
    const showBanner = (): boolean => (
        (!!vehicle && (vehicle?.price <= 600 || isAverageInRange()))
        || (!!vehicle && !!strictPosition)
    )

    return {
        gbtBannerPosition: getGbtBannerPosition(),
        gbtBannerType: getGbtBannerType(),
        gbtShowBanner: showBanner(),
        gbtShowNoDirectRoutesNotification,
    }
}