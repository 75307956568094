import React from 'react';
import Button from "@Generic/Button";
import Translation from "@Translation/components/Translation";
import ApplyVoucherButton from "@CheckOrder/components/MainSection/Voucher/ApplyVoucherButton";
import RemoveVoucherButton from "@CheckOrder/components/MainSection/Voucher/RemoveVoucherButton";

interface VoucherButtonsProps {
    voucherCode?: string,
    voucherDetails?: any,
    onCancelClick: (any?) => any,
    hasValue: boolean,
}

function VoucherButtons({voucherDetails, voucherCode, onCancelClick, hasValue}: VoucherButtonsProps) {
    const CloseButton = () => (
        <Button
            onClick={onCancelClick}
            className="dismiss"
            additionalClasses="btn--inline"
        >
            <Translation translationKey="my_profile.my_tickets.close"/>
        </Button>
    )

    const ActionButton = () => (
        hasValue ? (
            <ApplyVoucherButton voucherCode={voucherCode}/>
        ) : (
            <CloseButton/>
        )
    )

    return (
        <>
            {!voucherDetails?.code ? (
                <ActionButton/>
            ) : (
                <RemoveVoucherButton/>
            )}
        </>
    );
}

export default VoucherButtons;