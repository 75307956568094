import Column from "@Containers/components/Column";
import React from "react";
import useIsMobile from "@Hooks/selector/useIsMobile";
import ModalDataType from "@MyTickets/context/types/ModalData.type";
import createHeaderInfoTexts from "@UserModals/functions/createHeaderInfoTexts";

interface ModalHeaderProps {
    modalData: ModalDataType,
    title: string,
}

function ModalHeader({title, modalData}: ModalHeaderProps) {
    const {
        ticketCode,
        singleRoute,
        returnRoute,
    } = modalData

    const isMobile = useIsMobile()

    const {
        routeDepartureTimesInfo,
        routeEndStationsInfo,
    } = createHeaderInfoTexts(singleRoute, returnRoute)

    return (
        <Column className="tickets-modal-header">
            <h2 className="gb--emphasize gb--emphasize-default">
                {title}
                {isMobile ? <br/> : " "}
                {ticketCode}
            </h2>
            <p>
                {routeEndStationsInfo}
                <br/>
                {routeDepartureTimesInfo}
            </p>
        </Column>
    );
}

export default ModalHeader