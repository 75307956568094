import React, {useEffect, useState} from 'react';
import {useTranslation} from "@Translation/hooks/useTranslation";
import useLogin from "@User/hooks/useLogin";
import {useAsyncHandler} from "@Hooks/promises/useAsyncHandler";
import CheckOrderErrorLabel from "@CheckOrder/components/MainSection/CheckOrderErrorLabel";
import Translation from "@Translation/components/Translation";
import {isNotEmpty} from "@Array/isNotEmpty";
import Row from "@Containers/components/Row";
import LoaderButton from "@Generic/Loaders/LoaderButton";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import {setPassword} from "@CheckOrder/reducers/checkOrderSlice";
import CheckOrderInputContainer from "@CheckOrder/components/MainSection/CheckOrderInputContainer";

function PasswordLogin() {
    const {
        loading: userLoading,
        errors,
        user,
    } = useAppSelector((state) => state.userProfile)

    const {
        password,
        userConfirmed,
        submitTimestamp,
    } = useAppSelector((state) => state.checkOrder)

    const dispatch = useAppDispatch()
    const onChange = ({target: {value}}) => dispatch(setPassword(value))
    const passwordPlaceholder = useTranslation("check_order.buyer_info_section.password_field_placeholder")
    const mustSignInLabel = useTranslation("sys.in_order_to_proceed_gen")
    const login = useLogin()
    const loginHandler = useAsyncHandler(login)
    const [showErrors, setShowErrors] = useState(true)
    const userConfirmedWithoutLogin = userConfirmed && !user?.email
    const userPassword = useTranslation("user.password")

    useEffect(() => {
        setShowErrors(false)
    }, [password, user])

    useEffect(() => {
        if (userConfirmedWithoutLogin) {
            setShowErrors(true)
        }
    }, [submitTimestamp])

    const handleLogin = async () => {
        setShowErrors(true)
        await loginHandler()
    }

    return (
        <CheckOrderInputContainer label={userPassword}>
            <Row>
                <input
                    autoComplete="password"
                    placeholder={passwordPlaceholder}
                    type="password"
                    name="_password"
                    id="buyer-sign-in-password"
                    value={password}
                    onChange={onChange}
                    required
                />
                <button
                    type="button"
                    onClick={!userLoading ? handleLogin : null}
                    className="btn btn--green btn--confirm btn--inline password-btn"
                >
                    {userLoading ? (
                        <LoaderButton style={{marginTop: '-0.5rem'}}/>
                    ) : (
                        <Translation translationKey="user.sign_in"/>
                    )}
                </button>
            </Row>
            {showErrors && !userLoading && (
                <>
                    {isNotEmpty(errors) && (
                        <CheckOrderErrorLabel error={errors[0]}/>
                    )}
                    {userConfirmed && !user?.email && !isNotEmpty(errors) && (
                        <CheckOrderErrorLabel error={mustSignInLabel}/>
                    )}
                </>
            )}
        </CheckOrderInputContainer>
    );
}

export default PasswordLogin;