import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import LazyLoad, {forceCheck} from "react-lazyload";

/**
 * Generic field that includes lazy loaded image along the label
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
class ImageLabelField extends PureComponent {
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.index !== this.props.index) {
            forceCheck()
        }
    }

    render() {
        const {
            imageUrl,
            label,
            imageHeight,
            onClickHandler,
        } = this.props

        return (
            <LazyLoad overflow height={imageHeight} offset={imageHeight + 100} once>
                <div className="gb--image-label" onClick={onClickHandler}>
                    <span className="gb--image-container">
                        <img src={imageUrl} alt={label}/>
                    </span>
                    {label || null}
                </div>
            </LazyLoad>
        );
    }
}

ImageLabelField.propTypes = {
    imageUrl: PropTypes.string.isRequired,
    // Required for lazy-load logic
    imageHeight: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    // Index is required for forceCheck() lazy load method if array changes
    index: PropTypes.number.isRequired,
    // Optional click handler
    onClickHandler: PropTypes.func,
};

export default ImageLabelField;