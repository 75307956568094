import PassengersSummary from "@CheckOrder/features/paymentSummary/features/passengersSummary/components/PassengersSummary";
import AncillariesSummary from "@CheckOrder/features/paymentSummary/features/ancillariesSummary/components/AncillariesSummary";
import BookingFeeSummary from "@CheckOrder/features/paymentSummary/features/bookingFeeSummary/components/BookingFeeSummary";
import VoucherSummary from "@CheckOrder/features/paymentSummary/features/voucherSummary/components/VoucherSummary";
import TotalPrice from "@CheckOrder/features/paymentSummary/features/totalPrice/components/TotalPrice";
import React from "react";

function PaymentSummary() {
    return (
        <>
            <PassengersSummary/>
            <AncillariesSummary/>
            <BookingFeeSummary/>
            <VoucherSummary/>
            <hr/>
            <TotalPrice/>
        </>
    )
}

export default PaymentSummary