import React from 'react';
import {useTranslation} from "@Translation/hooks/useTranslation";
import SidebarSectionContainer from "../Sections/SidebarSectionContainer";
import BlogPosts from "../BlogPosts/BlogPosts";
import IconGbbGuides from "@Icons/IconGbbGuides";
import LatestBlogType from "@Sidebar/types/LatestBlog.type";

interface GuidesContainerProps {
    latestGuides: LatestBlogType[],
}

function GuidesContainer({latestGuides}: GuidesContainerProps) {
    const guidesTitle = useTranslation("search_results.getbybus_guides")

    return (
        <SidebarSectionContainer
            contentArray={latestGuides}
            title={guidesTitle}
            icon={<IconGbbGuides width="45"/>}
        >
            <BlogPosts blogPosts={latestGuides}/>
        </SidebarSectionContainer>
    );
}

export default GuidesContainer;