import {useRef, useState} from "react";
import useIntersectionObserver from "@Scroll/hooks/useIntersectionObserver";

export default function usePopoverObserver() {
    const ref = useRef(null)
    const [direction, setDirection] = useState(null)

    const observeCallback = (entries) => {
        const [entry] = entries
        if (!entry.isIntersecting) {
            setDirection("bottom")
        } else {
            setDirection("top")
        }
    }

    const options = {
        root: null,
        rootMargin: "-475px 0px 0px 0px",
        threshold: 0.5,
    }

    const {tooltipRef} = useIntersectionObserver(ref, observeCallback, options)

    return {
        tooltipRef,
        direction,
    }
}