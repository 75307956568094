import {ReactNode} from "react";
import AddVehicleButton from "@CheckOrder/features/addons/features/jadrolinija/components/AddVehicleButton";

type AddonProps = {
    label: string,
    description: string,
    iconName: string,
    children: ReactNode,
    onClick: () => void,
}

const Addon = ({label, description, children, iconName, onClick}: AddonProps) => (
    <div className="addons">
        {!children ? (
            <AddVehicleButton
                label={description}
                onClick={onClick}
            />
        ) : children}
    </div>
);

export default Addon;