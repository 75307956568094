import {isRoutePastSaleBlockTime} from "@Route/functions/isPastRouteSaleBlockTime";
import NoTicketCodeError from "@Route/features/ticketPurchase/models/NoTicketCodeError";
import IntermodalRouteWithDetailsType from "@Intermodal/types/IntermodalRouteWithDetails.type";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";
import buyTicket from "@Route/features/ticketPurchase/functions/buyTicket";
import PastSaleBlockTimeError from "@Route/features/ticketPurchase/models/PastSaleBlockTimeError";
import AllotmentError from "@Route/features/ticketPurchase/models/AllotmentError";
import RouteError from "@Route/features/ticketPurchase/models/RouteError";
import RouteType from "@Route/types/Route.type";

/**
 * Checks for errors and returns ticket response if buyTicket method is successful,
 * otherwise returns error code
 * @return {{response}|PastSaleBlockTimeError|NoSlotsError|NoTicketCodeError}
 */

interface CheckSaleBlockTimeAndAllotmentLeftAttributes {
    route: IntermodalRouteWithDetailsType | RouteWithAddedPropsAndPricesType,
    singleSelectedRoute: RouteType,
    isReturnSearch: boolean,
    date: string,
    returnDate: string,
    passengers: any,
    experiments: string,
}

export default async function checkSaleBlockTimeAndAllotmentLeft(props: CheckSaleBlockTimeAndAllotmentLeftAttributes) {
    const {
        route,
        singleSelectedRoute,
        isReturnSearch,
        date,
        returnDate,
        passengers,
        experiments,
    } = props

    const pastSaleBlockTimeError = new PastSaleBlockTimeError()

    if (isReturnSearch && singleSelectedRoute) {
        if (
            isRoutePastSaleBlockTime({route: singleSelectedRoute, date})
            || isRoutePastSaleBlockTime({route, date: returnDate})
        ) {
            return pastSaleBlockTimeError
        }
    }

    if (!isReturnSearch && isRoutePastSaleBlockTime({route, date})) {
        return pastSaleBlockTimeError
    }

    const response = await buyTicket({
        route,
        singleSelectedRoute,
        isReturnSearch,
        date,
        returnDate,
        passengers,
        experiments,
    }) ?? false

    if (response) {
        if (response?.code) {
            return new AllotmentError(response)
        }

        if (response?.checkOrder?.passengers === null) {
            return pastSaleBlockTimeError
        }

        if (!response?.checkOrder?.ticket?.ticketCode) {
            return new NoTicketCodeError()
        }

        return response
    }

    return new RouteError("error")
}