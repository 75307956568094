import React, {ReactNode, useState} from 'react';
import {isNotEmpty} from "@Array/isNotEmpty";
import useTimeout from "@Hooks/effects/useTimeout";

interface PromiseAwaitingContainerProps {
    children: ReactNode,
    data: any[],
    timeout?: number,
}

function PromiseAwaitingContainer({children, data, timeout = 3000}: PromiseAwaitingContainerProps) {
    const [visible, setVisible] = useState(true)
    const hideOnTimeout = () => {
        if (!isNotEmpty(data)) {
            setVisible(false)
        }
    }
    useTimeout(hideOnTimeout, timeout, data)

    return visible ? (
        <>
            {children}
        </>
    ) : null
}

export default PromiseAwaitingContainer;