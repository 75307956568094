import React from "react";
import IconChevronDown from "@Icons/IconChevronDown";
import {useTranslation} from "@Translation/hooks/useTranslation";

interface LoadMoreRoutesProps {
    showLoadMore: boolean,
    loadMoreRoutes: (any?) => (any),
    length: number,
}

export default function LoadMoreRoutes({showLoadMore, loadMoreRoutes, length}: LoadMoreRoutesProps) {
    const showAllLabel = useTranslation("search_results.show_all_affiliate_results", {results: length})

    if (showLoadMore) {
        return (
            <div
                onClick={() => loadMoreRoutes()}
                className="gb--row gb--row-middle gb--affiliates-results"
            >
                <IconChevronDown/>
                <div dangerouslySetInnerHTML={{
                    __html: `${showAllLabel} *`,
                }}
                />
                <IconChevronDown/>
            </div>
        )
    }

    return null
}