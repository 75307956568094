import React from 'react';
import {useTranslation} from "@Translation/hooks/useTranslation";
import ScrollToElement from "@Scroll/components/ScrollToElement";
import Notification from "@Features/notification/components/Notification";

interface ErrorLabelProps {
    error: string,
    isWarning: boolean,
    condition: any,
    isAbsolute?: boolean,
}

function ErrorLabel({error, isWarning, condition, isAbsolute}: ErrorLabelProps) {
    const {required} = useTranslation("sys")
    const containerStyle = isAbsolute ? {position: 'relative'} : undefined
    const notificationStyle = isAbsolute ? {position: 'absolute', top: '-6px'} : undefined

    return (
        <ScrollToElement
            condition={condition}
            // @ts-ignore
            style={containerStyle}
        >
            <Notification
                style={notificationStyle}
                notificationType={`${isWarning ? 'orange' : 'red'}`}
                isFullWidth
            >
                {error || required}
            </Notification>
        </ScrollToElement>
    );
}

export default ErrorLabel;