import React, {ReactNode} from 'react';

interface ModalContentProps {
    className?: string,
    children: ReactNode,
    style?: object,
}

function ModalContent({className = 'modal-dialog gb-modal-dialog', children, style}: ModalContentProps) {
    return (
        <div className="modal gb-modal fade in" style={{display: 'block'}}>
            <div
                className={className}
                tabIndex={-1}
                role="dialog"
            >
                <div className="modal-content gb-card gb-modal-content" style={style}>
                    {children}
                </div>
            </div>
        </div>
    );
}

export default ModalContent;
