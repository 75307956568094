import {KeyboardEvent} from "react";
import createArrayFromXToY from "@Array/createArrayFromXToY";

// ignore all characters that are not numbers, except backspace, delete, left arrow and right arrow
export default function filterLetterInput(event: KeyboardEvent<HTMLInputElement>, includedSigns?: string[]) {
    const {key} = event

    if (!createArrayFromXToY(0, 9).includes(parseInt(key, 10)) && key !== "Backspace" && key !== "ArrowRight" && key !== "ArrowLeft") {
        if (!includedSigns || !includedSigns.includes(key)) {
            event.preventDefault();
        }
    }
}