import {TextAdditionalFieldType} from "@CheckOrder/features/TicketAdditionalInfo/types/TextAdditionalField.type";
import React, {ChangeEvent} from "react";
import AdditionalFieldInput from "@CheckOrder/features/TicketAdditionalInfo/components/AdditionalFieldInput";
import {useTranslation} from "@Translation/hooks/useTranslation";
import getTextFieldValidation from "@CheckOrder/features/TicketAdditionalInfo/functions/getTextFieldValidation";

type TextAdditionalFieldProps = {
    field: TextAdditionalFieldType
    onChange: (event: ChangeEvent<HTMLInputElement>) => void,
    value: string,
}

function TextAdditionalField({field, onChange, value}: TextAdditionalFieldProps) {
    const {valueRequired} = useTranslation('sys.required')
    const textTooLongLabel = useTranslation(
        "check_order.passengers_form_section.errors.text_too_long",
        {max: field.maxLength}
    )
    const textTooShortLabel = useTranslation(
        "check_order.passengers_form_section.errors.text_too_short",
        {min: field.minLength}
    )

    const {isTextFieldValid} = getTextFieldValidation(field)

    const showError = !isTextFieldValid(value)

    const getErrorMessage = () => {
        if (!value?.length) {
            return valueRequired
        }

        if (value.length > field.maxLength) {
            return `${field.translations?.title ?? field.label} ${textTooLongLabel}`
        }

        if (value.length < field.minLength) {
            return `${field.translations?.title ?? field.label} ${textTooShortLabel}`
        }

        if (!!field.validationRegex && !new RegExp(field.validationRegex).test(value)) {
            return `${field.translations?.error ?? `Incorrect ${field.label}`}`
        }
    }

    return (
        <AdditionalFieldInput
            field={field}
            onChange={onChange}
            value={value}
            showError={showError}
            type="text"
            errorMessage={getErrorMessage()}
        />
    )
}

export default TextAdditionalField;