import React from 'react';
import MyTicketsButton from "@User/components/MyTicketsButton";
import {activateMobileMenu} from "@/reducers/mobileSlice";
import IconMenu from "@Icons/IconMenu";
import GetByApsLogomark from "@Generic/Logotypes/GetByApsLogomark";
import {useTranslation} from "@Translation/hooks/useTranslation";
import Column from "@Containers/components/Column";
import useScrollToFormAndAnimate from "@Form/hooks/useScrollToFormAndAnimate";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import MobileEditSearchButton from "@Form/components/Mobile/MobileEditSearchButton";
import useRouteMatch from "@Routing/hooks/useRouteMatch";
import useActivateMobileFormOverlay from "@Form/hooks/useActivateMobileFormOverlay";
import {capitalize} from "@String/capitalize";
import Button from "@Generic/Button";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import useStsPageDatePickerClickEvent from "@Events/hooks/useStsPageDatePickerClickEvent";

function MobileNavigation() {
    const {menu, search} = useTranslation('sys')
    const isSearchResults = useRouteMatch('searchResults')
    const isMyProfile = useRouteMatch('myProfile')
    const dispatch = useAppDispatch()
    const displayMenu = () => dispatch(activateMobileMenu(true))
    const {scrollToFormAndAnimate} = useScrollToFormAndAnimate()
    const {toggleFormAction} = useActivateMobileFormOverlay()
    const {mobileStsStickyCta} = useAppSelector((state) => (state?.page.experimentsJson));
    const ctaButtonText = useTranslation('sts_page.sticky_cta_text')
    const clickStickyCtaEvent = useStsPageDatePickerClickEvent("sticky_cta");

    const onClick = () => {
        clickStickyCtaEvent()
    }

    return (
        <>
            {(mobileStsStickyCta && (window.permalinkTypeId == 1 || window.permalinkTypeId == 7)) ? (
                <div className="gb--navigation__sticky--cta">
                    <a href={window.tomorrowSearchLink} onClick={onClick} className="btn btn--green cta--button">
                        {ctaButtonText}
                    </a>
                </div>
            ) : (
                <div className="gb--navigation__bottom">
                    {!isSearchResults && (
                        <>
                            <Column onClick={displayMenu} center>
                                <IconMenu
                                    height="20"
                                    fill="#070c63"
                                />
                                {menu}
                            </Column>
                            <MyTicketsButton/>
                        </>
                    )}
                    {isSearchResults ? (
                        <MobileEditSearchButton/>
                    ) : (
                        <Column center>
                            <button type="button" onClick={!isMyProfile ? scrollToFormAndAnimate : toggleFormAction}>
                                <GetByApsLogomark
                                    width="50"
                                    height="50"
                                    className="gb--logomark__search"
                                />
                                {capitalize(search)}
                            </button>
                        </Column>
                    )}
                </div>
            )}
        </>
    );
}

export default MobileNavigation;