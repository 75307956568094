import rootExtractor from "@Dataset/rootExtractor";
import StationModel from "@Form/models/StationModel";
import UrlParamsModel from "@Form/models/UrlParamsModel";
import ParamsType from "@SearchResults/types/params/Params.type";

/**
 * Resolves urlParams for formSlice via search results params object
 */
export default function resolveUrlParamsFromSearchResults(params: ParamsType) {
    const {
        departureStation,
        destinationStation,
        date: parsedDate,
        returnDate: parsedReturnDate,
    } = params || {}

    const {
        fromId: fallbackFromId,
        toId: fallbackToId,
        departingStationName,
        departingStationSlug,
        destinationStationName,
        destinationStationSlug,
    } = window || {}

    const fromSlug = departureStation?.slug ?? departingStationSlug ?? null
    const fromId = departureStation?.id ?? fallbackFromId ?? null
    const fromLabel = departureStation?.name ?? departingStationName ?? null
    const toSlug = destinationStation?.slug ?? destinationStationSlug ?? null
    const toId = destinationStation?.id ?? fallbackToId ?? null
    const toLabel = destinationStation?.name ?? destinationStationName ?? null
    const date = parsedDate ?? null
    const returnDate = parsedReturnDate ?? null
    const departureValue = fromLabel ?? departingStationName ?? ''
    const destinationValue = toLabel ?? destinationStationName ?? ''

    const {station: departureStationObject} = new StationModel({
        payload: {
            value: fromId,
            label: fromLabel,
            country_short_code: window.departingStationCountryShortCode,
            countryBusRentPaxCountMin: window.countryBusRentPaxCountMin,
            slug: fromSlug,
        },
    })

    const {station: destinationStationObject} = new StationModel({
        payload: {
            value: toId,
            label: toLabel,
            country_short_code: window.destinationStationCountryShortCode,
            slug: toSlug,
        },
    })

    const urlParams = new UrlParamsModel({
        payload: {
            transportType: rootExtractor('transportTypeKeyword'),
            fromSlug,
            fromId,
            toSlug,
            toId,
            date,
            returnDate,
        },
    })

    return {
        urlParams: JSON.parse(JSON.stringify(urlParams)),
        departureValue,
        departureStationObject,
        destinationStationObject,
        destinationValue,
    }
}