import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconPicturesqueView(props: IconType) {
    const {
        width = '35',
        height = '35',
        viewBox = '0 0 35 35',
        title = 'IconPicturesqueView',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none" fillRule="evenodd" transform="translate(1.421843 3.002091)">
                    <path
                        d="m2.08810433 0c3.76461038 1.33193934 8.26129467 1.33193934 13.49005277 0 6.6209939 1.33193934 11.3987717 1.33193934 14.3333334 0 1.8285341 5.91846846 2.8170332 13.5285924 0 18.9134766-1.8780222 3.5899229-6.5692634 7.618067-14.0737236 12.0844324-6.82647692-3.5351115-11.40969778-7.5632557-13.74966257-12.0844324-3.50994718-6.781765-1.9512761-13.53782971 0-18.9134766z"
                        fill="#62cdfc"
                        stroke="#fff"
                    />
                    <path
                        d="m2.08810433 18.9134766 27.82338617.0844324c-.7632272 1.4041217-2.0838519 3.0121171-3.961874 4.8239862-1.8780222 1.8118691-5.2486387 4.2038737-10.1118496 7.1760138-4.4128099-2.5021271-7.78919731-4.8941317-10.1291621-7.1760138s-3.54679828-3.9180216-3.62050047-4.9084186z"
                        fill="#1274e6"
                    />
                    <path d="m4.578157 6.997909h6v12h-6z" fill="#f1ff81"/>
                    <g fill="#fff">
                        <g>
                            <path d="m6.078157 8.497909h1v1h-1z"/>
                            <path d="m8.078157 8.497909h1v1h-1z"/>
                        </g>
                        <g>
                            <path d="m6.078157 10.497909h1v1h-1z"/>
                            <path d="m8.078157 10.497909h1v1h-1z"/>
                        </g>
                        <g>
                            <path d="m6.078157 12.497909h1v1h-1z"/>
                            <path d="m8.078157 12.497909h1v1h-1z"/>
                        </g>
                        <g>
                            <path d="m6.078157 14.497909h1v1h-1z"/>
                            <path d="m8.078157 14.497909h1v1h-1z"/>
                        </g>
                    </g>
                    <path d="m13.778157 7.997909h6v11h-6z" fill="#f8b981"/>
                    <g fill="#fff">
                        <path d="m14.578157 9.497909h1.7v1h-1.7z"/>
                        <path d="m17.278157 9.497909h1.7v1h-1.7z"/>
                    </g>
                    <g fill="#fff">
                        <path d="m14.578157 11.497909h1.7v1h-1.7z"/>
                        <path d="m17.278157 11.497909h1.7v1h-1.7z"/>
                    </g>
                    <g fill="#fff">
                        <path d="m14.578157 13.497909h1.7v1h-1.7z"/>
                        <path d="m17.278157 13.497909h1.7v1h-1.7z"/>
                    </g>
                    <path d="m8.578157 9.997909h6v9h-6z" fill="#ed9f1e"/>
                    <g fill="#fff">
                        <path d="m9.578157 12.997909h1.7v1.7h-1.7z"/>
                        <path d="m11.878157 12.997909h1.7v1.7h-1.7z"/>
                    </g>
                    <g fill="#fff">
                        <path d="m9.578157 10.697909h1.7v1.7h-1.7z"/>
                        <path d="m11.878157 10.697909h1.7v1.7h-1.7z"/>
                    </g>
                    <g fillRule="nonzero">
                        <g transform="translate(21.578157 8.997909)">
                            <path d="m1.97894737 8.18971429v-2.22171429h-.79578948v2.22171429z" fill="#795548"/>
                            <path
                                d="m2 .35945535c-1.19288473.97305259-1.78932709 2.24369365-1.78932709 3.81192321 0 .70243309.41424019 2.06681409.75361275 1.98080798 1.03472064-.26222598 1.35333284.58948889 1.70112161.10744753.39494917-.54740651.55543618-2.75963407-.12516026-3.38567543-.45373096-.41736091-.63381329-1.25552868-.54024701-2.51450329z"
                                fill="#325905"
                                transform="matrix(-1 0 0 1 3.216938 0)"
                            />
                        </g>
                        <g transform="translate(25.578157 9.997909)">
                            <path d="m1.78105263 7.37074286v-1.99954286h-.71621052v1.99954286z" fill="#795548"/>
                            <path
                                d="m1.8.32350982c-1.07359626.87574732-1.61039438 2.01932428-1.61039438 3.43073088 0 .63218979.37281617 1.86013269.67825148 1.78272718.93124857-.23600337 1.21799955.53054001 1.53100944.09670279.35545426-.49266587.49989257-2.48367067-.11264423-3.0471079-.40835786-.37562482-.57043197-1.1299758-.48622231-2.26305295z"
                                fill="#325905"
                            />
                        </g>
                        <g transform="translate(1.078157 10.497909)">
                            <path d="m1.60294737 6.63366857v-1.79958857h-.64458947v1.79958857z" fill="#795548"/>
                            <path
                                d="m1.62.29115883c-.96623663.7881726-1.44935495 1.81739186-1.44935495 3.0876578 0 .56897081.33553456 1.67411942.61042634 1.60445446.83812372-.21240303 1.09619959.47748601 1.3779085.08703251.31990883-.44339928.44990331-2.2353036-.10137981-2.74239711-.36752208-.33806233-.51338877-1.01697822-.43760008-2.03674766z"
                                fill="#325905"
                            />
                        </g>
                    </g>
                    <path d="m2.078157 17.997909h28v1h-28z" fill="#6f6f6f"/>
                    <path d="m1.578157 16.997909h29v1h-29z" fill="#b4b4b4"/>
                    <g fillRule="nonzero" transform="translate(8.078157 20.997909)">
                        <g fill="#795548" transform="translate(.162683 4.908537)">
                            <path
                                d="m5.30121951 1.76707317c-.46788171 0-4.12317073 0-4.12317073 0l-1.17804878-.98170732v-.78536585h7.06829268s-1.17804878 1.76707317-1.76707317 1.76707317z"
                            />
                            <path d="m6.67560976 0h.78536585v.19634146l-.78536585.19634147z"/>
                        </g>
                        <path
                            d="m6.24926829 4.3195122-3.33780488.39268292c.78536586-1.37439024 0-3.92682927 0-3.92682927s2.55243903.39268293 3.33780488 3.53414635z"
                            fill="#bbdefb"
                        />
                        <path
                            d="m6.95236707 5.30121951h-6.78968414v.39268293h6.48555122c.11250365-.1392061.21656463-.27409268.30413292-.39268293z"
                            fill="#5d4037"
                        />
                        <path d="m2.91146341.39268293h-.39268292v4.51585366h.39268292z" fill="#90a4ae"/>
                        <path
                            d="m2.51878049.04810366v.54092073c.76906951-.27821585 1.48905366.44078659 1.96341463-.54092073-.81795853.55682439-1.24343049-.24542683-1.96341463 0z"
                            fill="#ff3d00"
                        />
                    </g>
                    <path
                        d="m2.08810433 0c3.76461038 1.33193934 8.26129467 1.33193934 13.49005277 0 6.6209939 1.33193934 11.3987717 1.33193934 14.3333334 0 1.8285341 5.91846846 2.8170332 13.5285924 0 18.9134766-1.8780222 3.5899229-6.5692634 7.618067-14.0737236 12.0844324-6.82647692-3.5351115-11.40969778-7.5632557-13.74966257-12.0844324-3.50994718-6.781765-1.9512761-13.53782971 0-18.9134766z"
                        stroke="#fff"
                    />
                    <g fillRule="nonzero">
                        <g fill="#ff4081" transform="translate(5.578157 15.497909)">
                            <path
                                d="m.35079546 1.16931818c.23386363 0 .23386363-1.16931818 1.75397727-1.16931818 1.52011363 0 1.45767204 1.08629659 1.8084675 1.20322841v.55074886h-3.56244477z"
                            />
                            <path
                                d="m5.145 2.2509375s0 .20463068-.23386364.20463068c-.35079545 0-4.5603409 0-4.5603409 0-.19375603 0-.35079546-.15715636-.35079546-.35079545v-.58465909s0-.35079546.35079546-.35079546h3.04022727c1.16931818 0 1.75397727.46772727 1.75397727.70159091z"
                            />
                        </g>
                        <g fill="#455a64" transform="translate(6.162816 17.018023)">
                            <path
                                d="m3.97568182.58465909c0 .32284875-.26169341.58465909-.58465909.58465909s-.58465909-.26181034-.58465909-.58465909.26169341-.58465909.58465909-.58465909.58465909.26181034.58465909.58465909"
                            />
                            <path
                                d="m1.16931818.58465909c0 .32284875-.26181034.58465909-.58465909.58465909s-.58465909-.26181034-.58465909-.58465909.26181034-.58465909.58465909-.58465909.58465909.26181034.58465909.58465909"
                            />
                        </g>
                        <g fill="#b0bec5" transform="translate(6.513612 17.368818)">
                            <path
                                d="m3.27409091.23386364c0 .12920966-.10465398.23386363-.23386364.23386363s-.23386363-.10465397-.23386363-.23386363.10465397-.23386364.23386363-.23386364.23386364.10465398.23386364.23386364"
                            />
                            <path
                                d="m.46772727.23386364c0 .12920966-.10465397.23386363-.23386363.23386363s-.23386364-.10465397-.23386364-.23386363.10465398-.23386364.23386364-.23386364.23386363.10465398.23386363.23386364"
                            />
                        </g>
                        <g fill="#ffc107" transform="translate(5.578157 16.901091)">
                            <path
                                d="m.17539773.11693182h-.17539773v.46772727h.17539773c.09693648 0 .17539773-.07857818.17539773-.17539773v-.11693181c0-.09681955-.07846125-.17539773-.17539773-.17539773z"
                            />
                            <path
                                d="m4.79420455.07694114v.33232022c0 .09681955.07857818.17539773.17539772.17539773h.17539773v-.11693182c0-.10453704-.11751648-.25549602-.35079545-.39078613z"
                            />
                        </g>
                        <g fill="#660636" transform="translate(6.162816 15.731773)">
                            <path
                                d="m2.96164909.93545455c-.03426102-.05332091-.06606648-.11050057-.09833966-.16896648-.17949034-.32635671-.40247932-.7280175-1.22626398-.76298012v.9319466z"
                            />
                            <path
                                d="m1.40318182.93545455v-.9315958c-.85138057.03928909-1.08898602.50093591-1.24742864.81174068-.02069693.04045841-.04174466.08103375-.06419557.11985512z"
                            />
                        </g>
                    </g>
                </g>
            )}
        />

    );
}

export default IconPicturesqueView;
