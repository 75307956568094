export default class FormErrorsModel {
    /**
     * Checks if all form fields are properly set
     * @param urlParams {object}
     */
    constructor(urlParams) {
        const {
            fromId,
            toId,
            date,
        } = urlParams

        const hasSameStations = fromId === toId
        this.departureValid = !!fromId && !hasSameStations
        this.destinationValid = !!toId && !hasSameStations
        this.dateValid = !!date
    }
}