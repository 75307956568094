import {useState} from "react";

export default function useCarousel(data) {
    const [orderedData, setOrderedData] = useState(data)

    const centralPosition = Math.floor(data.length / 2)

    const prevButtonClick = () => {
        const newOrderedData = [...orderedData.slice(orderedData.length - 1), ...orderedData.slice(0, orderedData.length - 1)]
        setOrderedData(newOrderedData)
    }

    const nextButtonClick = () => {
        const newOrderedData = [...orderedData.slice(1, orderedData.length), ...orderedData.slice(0, 1)]
        setOrderedData(newOrderedData)
    }

    const mobileSwipe = (deltaX: number, deltaY: number) => {
        if (deltaX < -50) {
            nextButtonClick()
        } else if (deltaX > 50) {
            prevButtonClick()
        }
    }

    return {
        orderedData,
        prevButtonClick,
        nextButtonClick,
        centralPosition,
        mobileSwipe,
    }
}