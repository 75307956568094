import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconTrainFull(props: IconType) {
    const {
        width = '16',
        height = '16',
        fill = '#7ed321',
        viewBox = '0 0 20 20',
        title = 'IconTrainFull',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <path
                    d="m5 0c-.45898417 0-.83333333.37434917-.83333333.83333333h-2.47395834c-.940755 0-1.69270833.75195334-1.69270833 1.69270834v13.28125003c0 .940755.75195333 1.6927083 1.69270833 1.6927083h.33854167l-.88541667 1.328125c-.152995.1855467-.18554666.4427083-.084635.6608075.10091084.2148433.31901.3580725.55664.3613865.24088584.0031968.46224-.1302665.56966167-.3451107l1.328125-2.0052083h6.30208333l1.32812497 2.0052083c.1074217.2148442.3287759.3483075.5696617.3451107.23763-.003314.4557292-.1465432.5566408-.3613865.1009109-.2180992.0683592-.4752608-.0846358-.6608075l-.8854167-1.328125h.3385417c.940755 0 1.6927083-.7519533 1.6927083-1.6927083v-13.28125003c0-.940755-.7519533-1.69270834-1.6927083-1.69270834h-2.47395833c0-.45898416-.37434917-.83333333-.83333334-.83333333-.45898416 0-.83333333.37434917-.83333333.83333333h-1.66666667c0-.45898416-.37434916-.83333333-.83333333-.83333333zm.36458333 1.66666667h2.60416667c.20182333 0 .36458333.16276083.36458333.36458333v.10416667c0 .20182333-.16276.36458333-.36458333.36458333h-2.60416667c-.2018225 0-.36458333-.16276-.36458333-.36458333v-.10416667c0-.2018225.16276083-.36458333.36458333-.36458333zm-2.96875 3.33333333h8.54166667c.3971358 0 .7291667.33203167.7291667.72916667v4.37500003c0 .3971358-.3320309.7291666-.7291667.7291666h-8.54166667c-.397135 0-.72916666-.3320308-.72916666-.7291666v-4.37500003c0-.397135.33203166-.72916667.72916666-.72916667zm.52083334 8.3333333c.69010416 0 1.25.5598959 1.25 1.25 0 .6901042-.55989584 1.25-1.25 1.25-.69010417 0-1.25-.5598958-1.25-1.25 0-.6901041.55989583-1.25 1.25-1.25zm7.50000003 0c.6901041 0 1.25.5598959 1.25 1.25 0 .6901042-.5598959 1.25-1.25 1.25-.6901042 0-1.25000003-.5598958-1.25000003-1.25 0-.6901041.55989583-1.25 1.25000003-1.25z"
                    transform="translate(3.3)"
                />
            )}
        />

    );
}

export default IconTrainFull;
