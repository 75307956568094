import React from 'react';
import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "@/types/Icon.type";

const IconHandPoint = (props: IconType) => {
    const {
        width = '20',
        height = '20',
        fill = '#fff',
        viewBox = '0 0 20 20',
        title = 'IconHandPoint',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <path
                    d="m13.8215715 6.55165178c-.3744-.0156-.7302.1113-1.0011.3687-.0453.0432-.0825.0927-.1209.1413-.2475-.3093-.6237-.5118-1.05-.5118s-.8025.2025-1.05.5121c-.2475-.3096-.62369999-.5121-1.04999999-.5121-.2775 0-.45.0846-.75.2286v-4.3689c0-.7515-.5613-1.3698-1.2777-1.4076-.3774-.0198-.7305.1116-1.0014.369-.2676.2535-.4209.6105-.4209.9789v7.0896c0 .1851-.141.258-.1842.276s-.1947.066-.3255-.0648l-.7062-.7062c-.5397-.54-1.3869-.5808-1.929-.0933-.2829.255-.4443.6045-.4542.984-.0099.37410002.1365.74070002.4011 1.00560002l3.198 3.1983v.1608.6.9c0 .4962.4038.9.9.9h5.69999999.6.3c.4962 0 .9-.4035.9-.9v-.9c0-.0918-.0225-.1782-.0594-.2562.4032-.2715.6594-.7305.6594-1.2393v-5.34480002c0-.7515-.5613-1.3701-1.278-1.4079zm-.222 9.44820002h-.3-.6-5.69999999c-.1653 0-.3-.1344-.3-.3v-.9h6.90449999c.0993 0 .2955-.0126.2955-.0321v.9321c0 .1656-.1347.3-.3.3zm.9-2.6955c0 .4101-.2772.7668-.6741.8679-.072.0183-.1467.0276-.2214.0276h-6.90449999v-.4092l-3.3741-3.3741c-.1509-.1512-.231-.3519-.2256-.56580002.0057-.2133.0966-.4101.2559-.5538.2997-.2694.7944-.237 1.1034.0714l.7062.70620002c.2622.2622.6375.3363.9795.1947.3423-.1413.5547-.45930002.5547-.82980002v-7.0896c0-.2076.0831-.4011.2337-.5439s.3486-.2205.5568-.2049c.3912.0207.7095.3834.7095.8085-.10495757 3.29165896-.10495757 5.12175896 0 5.4903.15743637.55281155.57368233-1.27368233 1.35-.75.77631769.52368232 1.64047899.36662783 2.09999999 0 .4595211-.36662784.9448986.74590141 1.5837.2061.6388014-.53980142.3498-.2163.5568-.2049.3912.0207.7095.3834.7095.8085z"
                    fill={fill}
                    stroke={fill}
                />
            )}
        />

    );
}

export default IconHandPoint;
