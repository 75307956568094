import UrlParamsType from "@Form/types/UrlParams.type";
import useForm from "@Form/hooks/useForm";
import getUnixTime from "date-fns/getUnixTime";
import parseDate from "@DateTime/functions/parseDate";
import useSingleConfirmedRoute from "@Route/hooks/useSingleConfirmedRoute";

export default function useCreateSuggestedDatesArray(dates: number[], searchedDate: string, hasRoutesOnSelectedDate: boolean) {
    const urlParams: UrlParamsType = useForm("urlParams")
    const isSingleRouteConfirmed = useSingleConfirmedRoute()

    let filteredDates = [...dates]

    const searchedDateInUnix = getUnixTime(parseDate(searchedDate))

    if (isSingleRouteConfirmed) {
        const singleDateInUnix = getUnixTime(parseDate(urlParams.date))
        filteredDates = dates.filter((date) => (date >= singleDateInUnix))
    }

    // don't add current date as a suggestion if it has routes
    if (hasRoutesOnSelectedDate) {
        if (filteredDates.includes(searchedDateInUnix)) {
            return {
                appendedDates: filteredDates.filter((date) => (date !== searchedDateInUnix)),
                searchedDateIndex: null,
            }
        }

        return {
            appendedDates: filteredDates,
            searchedDateIndex: null,
        }
    }

    const appendedDates = [...filteredDates, searchedDateInUnix].sort((a, b) => (a - b))
    const searchedDateIndex = appendedDates.indexOf(searchedDateInUnix)

    return {
        appendedDates,
        searchedDateIndex,
    }
}