import toNumber from "lodash/toNumber";
import PassengerCountType from "@Form/types/PassengerCount.type";

export function passengerFetcher(): PassengerCountType {
    let passengers: PassengerCountType;
    if (window.location.search !== "") {
        const searchParams = new URLSearchParams(window.location.search);
        passengers = {
            passengersAdult: toNumber(searchParams.get('adults')) || 0,
            passengersChildren: toNumber(searchParams.get('children')) || 0,
            passengersPensioner: toNumber(searchParams.get('pensioners')) || 0,
            passengersStudent: toNumber(searchParams.get('students')) || 0,
            passengers: toNumber(searchParams.get('adults'))
                + toNumber(searchParams.get('children'))
                + toNumber(searchParams.get('pensioners'))
                + toNumber(searchParams.get('students')),
        }
    } else {
        passengers = {
            passengers: 1,
            passengersAdult: 1,
            passengersChildren: 0,
            passengersPensioner: 0,
            passengersStudent: 0,
        }
    }

    if (passengers.passengers === 0) {
        passengers.passengers = 1;
        if (passengers.passengersAdult === 0
            && passengers.passengersChildren === 0
            && passengers.passengersPensioner === 0
            && passengers.passengersStudent === 0) {
            passengers.passengersAdult = 1;
        }
    }

    return passengers
}