import {useRef, useState} from "react";
import {setLoading, toggleLoginModal} from "@MyProfile/reducers/userProfileSlice";
import useGetCurrentUser from "@User/hooks/useGetCurrentUser";
import ajaxHandler from "@/utils/ajax/v2/ajaxHandler";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";

/**
 * Handles user registration
 */

interface UseRegisterParams {
    firstName: string,
    lastName: string,
    email: string,
    password: string
    confirmPassword: string,
}

export default function useRegister(params: UseRegisterParams) {
    const getCurrentUser = useGetCurrentUser()
    const [errors, setErrors] = useState([])
    const recaptchaRef = useRef<any>()
    const dispatch = useAppDispatch()
    const {
        firstName,
        lastName,
        email,
        password,
        confirmPassword,
    } = params

    const fosParams = {
        fos_user_registration_form: {
            email: {first: email, second: email},
            plainPassword: {first: password, second: confirmPassword},
            real_name: `${firstName} ${lastName}`,
        },
    }

    const post = async (token) => {
        setErrors([])
        dispatch(setLoading(true))

        const response = await ajaxHandler({
            url: "/ajax/register-user",
            type: "post",
            params: {
                ...fosParams,
                "g-recaptcha-response": token,
            },
            stringify: true,
            handle: "ignore",
        })

        if (response?.data?.errors) {
            setErrors(response.data.errors)
        } else {
            await getCurrentUser()
            dispatch(toggleLoginModal())
        }

        dispatch(setLoading(false))
    }

    const submit = async () => {
        await recaptchaRef?.current?.reset()
        const recaptcha = await recaptchaRef?.current?.executeAsync()

        if (recaptcha) {
            try {
                await post(recaptcha)
            } catch (err) {
                setErrors([err])
            }
        } else {
            setErrors(["Recaptcha verification required"])
        }
    }

    return {
        submit,
        recaptchaRef,
        errors,
    }
}