import React, {ReactNode} from 'react';
import ErrorBoundary from "@Errors/components/ErrorBoundary"

interface SidebarContainerProps {
    children: ReactNode,
    className?: string,
}

function SidebarContainer({children, className = 'col-lg-3 visible-lg sidebar-container'}: SidebarContainerProps) {
    if (window.innerWidth > 1243) {
        return (
            <ErrorBoundary shouldReload={false}>
                <div className={className}>
                    {children}
                </div>
            </ErrorBoundary>
        );
    }

    return null
}

export default SidebarContainer;