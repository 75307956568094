import React from 'react'
import ReviewDisplay from "@Pages/mobileApp/components/ReviewDisplay";
import useIsMobile from "@Hooks/selector/useIsMobile";
import SourcedImage from "@Images/components/SourcedImage";
import useIsSmallTablet from "@Hooks/selector/useIsSmallTablet";
import {reviews} from "@Pages/mobileApp/constants/reviews";
import {useTranslation} from "@Translation/hooks/useTranslation";

const MobileAppReviews = () => {
    const isSmallTablet = useIsSmallTablet()
    const isMobile = useIsMobile()
    const {
        title,
        intro,
    } = useTranslation("mobile_app.user_reviews")

    const filePath = `images/mobile-app-landing-page/${(isSmallTablet || isMobile) ? 'our_users_love_us_horizontal' : 'our_users_love_us_vertical'}`

    return (
        <div className="gb-container mobile-app-landing-page-section">
            <h2 className="app-reviews-section-title">
                {title}
            </h2>
            <p className="app-reviews-section-title">
                {intro}
            </p>
            <div className="app-reviews-section-container">
                {reviews.map((review) => (
                    <ReviewDisplay
                        username={review.username}
                        review={review.content}
                        appStore={review.store}
                        key={review.content}
                    />
                ))}
                <div className="app-reviews-section-image">
                    <SourcedImage
                        filePath={filePath}
                        fileType="png"
                    />
                </div>
            </div>
        </div>
    )
}

export default MobileAppReviews