import React from 'react';
import Sorter from "./Sorter";
import useHasSearchResults from "../../../hooks/useHasSearchResults";

interface SortContainerProps {
    isMobile: boolean,
    isSmallTablet: boolean,
}

const SortContainer = ({isMobile, isSmallTablet}: SortContainerProps) => {
    const hasResults = useHasSearchResults()
    if (hasResults) {
        return (
            <Sorter/>
        );
    }

    return (
        <div style={{height: "28.8px"}}/>
    )
}

export default SortContainer;