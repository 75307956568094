import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconLocationMarker(props: IconType) {
    const {
        width = '16',
        height = '16',
        fill = '#070c63',
        viewBox = '0 0 16 16',
        title = 'IconLocationMarker',
        ...rest
    } = props
    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill={fill} transform="translate(2)">
                    <path
                        d="m6.0625 0c.9375 0 1.77604167.16145833 2.515625.484375s1.36197917.76302083 1.8671875 1.3203125c.5052083.55729167.890625 1.20572917 1.15625 1.9453125s.3984375 1.52083333.3984375 2.34375c0 .83333333-.1640625 1.68489583-.4921875 2.5546875s-.7421875 1.7239583-1.2421875 2.5625-1.04166667 1.6328125-1.625 2.3828125-1.13020833 1.4166667-1.640625 2c0 .0104167-.078125.0807292-.234375.2109375s-.33854167.1953125-.546875.1953125h-.046875c-.21875 0-.40364583-.0651042-.5546875-.1953125s-.2265625-.2005208-.2265625-.2109375c-.54166667-.625-1.12760417-1.3072917-1.7578125-2.046875s-1.21354167-1.5182292-1.75-2.3359375-.984375-1.65885417-1.34375-2.5234375-.5390625-1.72916667-.5390625-2.59375c0-.82291667.16145833-1.60416667.484375-2.34375s.76041667-1.38802083 1.3125-1.9453125 1.1953125-.99739583 1.9296875-1.3203125 1.51302083-.484375 2.3359375-.484375zm0 1c-.6875 0-1.33854167.13541667-1.953125.40625s-1.15104167.63802083-1.609375 1.1015625-.82291667 1.00520833-1.09375 1.625-.40625 1.2734375-.40625 1.9609375c0 .73958333.15625 1.48958333.46875 2.25s.70833333 1.5078125 1.1875 2.2421875 1.00520833 1.4427083 1.578125 2.125 1.11458333 1.3203125 1.625 1.9140625l.265625.296875c.01041667.0104167.02083333.0208333.03125.03125l.03125.03125c.01041667-.0104167.0234375-.0208333.0390625-.03125s.0234375-.0208333.0234375-.03125c.79166667-.90625 1.484375-1.7708333 2.078125-2.59375s1.08854167-1.6015625 1.484375-2.3359375c.3958333-.734375.6927083-1.42708333.890625-2.078125s.296875-1.2578125.296875-1.8203125c0-.63541667-.0963542-1.2578125-.2890625-1.8671875s-.4921875-1.15364583-.8984375-1.6328125-.91927083-.86458333-1.5390625-1.15625-1.35677083-.4375-2.2109375-.4375z"
                    />
                    <path
                        d="m6.015625 3.015625c.83333333 0 1.54166667.29427083 2.125.8828125s.875 1.29427083.875 2.1171875c0 .83333333-.29166667 1.54166667-.875 2.125s-1.29166667.875-2.125.875c-.82291667 0-1.52864583-.29166667-2.1171875-.875s-.8828125-1.29166667-.8828125-2.125c0-.82291667.29427083-1.52864583.8828125-2.1171875s1.29427083-.8828125 2.1171875-.8828125zm-.015625.984375c-.55208333 0-1.0234375.1953125-1.4140625.5859375s-.5859375.86197917-.5859375 1.4140625.19791667 1.02604167.59375 1.421875.86979167.59375 1.421875.59375 1.02083333-.19791667 1.40625-.59375.578125-.86979167.578125-1.421875-.1953125-1.0234375-.5859375-1.4140625-.86197917-.5859375-1.4140625-.5859375z"
                    />
                </g>
            )}
        />

    );
}

export default IconLocationMarker;
