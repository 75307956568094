import IconExchange from "@Icons/IconExchange";
import Translation from "@Translation/components/Translation";

const CountryBorder = () => (
    <span className="gb--border-crossing gb--emphasize gb--emphasize-warning">
        <IconExchange
            width="20"
            height="20"
            style={{verticalAlign: 'text-top', marginRight: '.5rem'}}
        />
        <Translation translationKey="search_results.border_crossing_stations"/>
    </span>
)

export default CountryBorder