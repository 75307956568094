import ajaxHandler from "@Ajax/v2/ajaxHandler"

export default function loginAndReturnResponse(email: string, password: string, ticketCode: string, csrfToken: string) {
    return ajaxHandler({
        url: "/ajax/login",
        type: "post",
        stringify: true,
        params: {
            email,
            password,
            ticket_code: ticketCode,
            csrf_token: csrfToken,
        },
        handle: "ignore",
    })
}