import {useAppSelector} from "@Hooks/selector/useAppSelector";
import roundToTwoDecimals from "@Number/roundToTwoDecimals";

export default function useGetDisplayPriceWithAncillaries(returnWithCurrency: boolean = false) {
    const {ticket} = useAppSelector((state) => state?.checkOrder);
    const {isBuyingPremiumSupport, isBuyingSMSNotification, isBuyingTier1FlexibleCancellation} = useAppSelector((state) => state?.checkOrder.selectedServices);
    let priceFloat = parseFloat(ticket.totalPrice.split(" ")[0]);

    if (isBuyingPremiumSupport) {
        priceFloat = roundToTwoDecimals(priceFloat + ticket.ancillariesData.premiumSupport.displayPrice)
    }
    if (isBuyingSMSNotification) {
        priceFloat = roundToTwoDecimals(priceFloat + ticket.ancillariesData.smsNotification.displayPrice)
    }
    if (isBuyingTier1FlexibleCancellation) {
        priceFloat = roundToTwoDecimals(priceFloat + ticket.ancillariesData.tier1FlexibleCancellation.displayPrice)
    }
    return (returnWithCurrency) ? `${priceFloat} ${ticket.ancillariesData.premiumSupport.displayCurrencySymbol}` : `${priceFloat}`;
}