import {useState} from "react";
import {useTranslation} from "@Translation/hooks/useTranslation";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {DownloadOptions} from "@UserModals/features/downloadDocuments/types/TicketDownloadOptions.type";

export default function useDownloadDocumentsModal(ticketCode: string, isCancelled: boolean) {
    const {ticket: ticketLabel} = useTranslation('ticket')
    const {
        download: downloadLabel,
        download_documents: downloadDocumentsLabel,
        boarding_info: boardingInfoLabel,
        invoice: invoiceLabel,
        storno_invoice: stornoInvoiceLabel,
        download_all: downloadAllLabel,
    } = useTranslation('my_profile.my_tickets.download_documents_modal')

    const {
        downloadTicketReceipt: ticketReceiptLink,
        downloadTicketInfo: ticketInfoLink,
        downloadTicket: ticketLink,
    } = useAppSelector((state) => state?.userProfile.downloadLinks)

    const [option, setOption] = useState<DownloadOptions>(null)

    const ticketCodeQueryParam = `ticketCode=${ticketCode}`

    let createDownloadLink;
    let createDownloadLinkOnClick;
    let downloadOptions;

    if (isCancelled) {
        createDownloadLink = {
            invoiceDownload: `${ticketReceiptLink}?${ticketCodeQueryParam}&originalReceipt=1`,
            stornoInvoiceDownload: `${ticketReceiptLink}?${ticketCodeQueryParam}&originalReceipt=0`,
        }

        createDownloadLinkOnClick = {
            downloadAll: (e) => {
                e.preventDefault()
                window.open(createDownloadLink.invoiceDownload)
                window.open(createDownloadLink.stornoInvoiceDownload)
            },
            default: () => {
            },
        }

        downloadOptions = [
            {
                label: invoiceLabel,
                value: "invoiceDownload",
                onClick: () => {
                    setOption("invoiceDownload")
                },
            },
            {
                label: stornoInvoiceLabel,
                value: "stornoInvoiceDownload",
                onClick: () => {
                    setOption("stornoInvoiceDownload")
                },
            },
        ]
    } else {
        createDownloadLink = {
            ticketDownload: `${ticketLink}?${ticketCodeQueryParam}`,
            boardingInfoDownload: `${ticketInfoLink}?${ticketCodeQueryParam}`,
            invoiceDownload: `${ticketReceiptLink}?${ticketCodeQueryParam}&originalReceipt=1`,
            downloadAll: "",
        }

        createDownloadLinkOnClick = {
            downloadAll: (e) => {
                e.preventDefault()
                window.open(createDownloadLink.ticketDownload)
                window.open(createDownloadLink.boardingInfoDownload)
                window.open(createDownloadLink.invoiceDownload)
            },
            default: () => {
            },
        }
        downloadOptions = [
            {
                label: ticketLabel,
                value: "ticketDownload",
                onClick: () => {
                    setOption("ticketDownload")
                },
            },
            {
                label: boardingInfoLabel,
                value: "boardingInfoDownload",
                onClick: () => {
                    setOption("boardingInfoDownload")
                },
            },
            {
                label: invoiceLabel,
                value: "invoiceDownload",
                onClick: () => {
                    setOption("invoiceDownload")
                },
            },
        ]
    }

    downloadOptions.push({
        label: downloadAllLabel,
        value: "downloadAll",
        onClick: () => {
            setOption("downloadAll")
        },
    })

    return {
        option,
        downloadOptions,
        link: createDownloadLink[option],
        onClick: createDownloadLinkOnClick[option],
        downloadLabel,
        downloadDocumentsLabel,
    }
}