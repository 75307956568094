export default class UrlParamsModel {
    transportType: string;
    fromSlug: string;
    fromId: string;
    toSlug: string;
    toId: string;
    date: string;
    returnDate: string;

    /**
     * Url params object
     * @param params {object}
     * @param params.payload {object} - updated state
     * @param params.state {object} - existing state
     */
    constructor(params) {
        const {
            payload,
            state,
        } = params

        const {
            transportType = state.transportType,
            fromSlug = state.fromSlug,
            fromId = state.fromId,
            toSlug = state.toSlug,
            toId = state.toId,
            date = state.date,
            returnDate = state.returnDate,
        } = payload || {}


        this.transportType = transportType
        this.fromSlug = fromSlug
        this.fromId = fromId
        this.toSlug = toSlug
        this.toId = toId
        this.date = date
        this.returnDate = returnDate
    }
}