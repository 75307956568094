import React from 'react';
import useCompanyStyle from "@Hooks/selector/useCompanyStyle";
import Translation from "@Translation/components/Translation";
import {useAppSelector} from "@Hooks/selector/useAppSelector";

function HeroBackgroundCompanyStore() {
    const {centerBackgroundImage} = useCompanyStyle()
    const isBig = useAppSelector((state) => state?.form.isBig)
    const companyStoreUrl = `https://d2bgjx2gb489de.cloudfront.net/admin/public_html/${centerBackgroundImage}`

    return (
        <>
            <picture>
                <img
                    typeof="image/jpeg"
                    src={companyStoreUrl}
                    alt="City landscape"
                />
            </picture>
            {isBig && (
                <h1 className="gb--title-light">
                    <Translation translationKey="form.find_your"/>
                </h1>
            )}
        </>
    )
}

export default HeroBackgroundCompanyStore
