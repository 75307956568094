import {useGetSearchResultsQuery} from "@SearchResults/api/searchResultsApi";
import convertToArrayAndFlatten from "@Object/convertToArrayAndFlatten";
import useSearchParamsExtractedFromRoute from "@SearchResults/hooks/useSearchParamsExtractedFromRoute";
import useActiveCurrency from "@Currency/hooks/useActiveCurrency";
import {useEffect, useState} from "react"
import {SearchResponseType} from "@SearchResults/types/SearchResponse.type";
import toggleStaticMainContentElements from "@Features/domElements/functions/toggleStaticMainContentElements";
import useIsFetchingData from "@SearchResults/api/hooks/useIsFetchingData"

export default function useSearchResultsFetcher(): SearchResponseType {
    const params = useSearchParamsExtractedFromRoute()
    const [oldParams, updateParams] = useState(params)
    const {isFetchingData} = useIsFetchingData()
    const {setActiveCurrency} = useActiveCurrency()

    const {
        data,
        error,
        isLoading,
        status,
        isFetching,
    } = useGetSearchResultsQuery(params)

    // This ensures we're not using placeholder data set as currency
    // load selection from cookie and update redux
    useEffect(() => {
        if (data) {
            toggleStaticMainContentElements(false)
            setActiveCurrency(data.result.currencyRates)
            updateParams(params)
        }
    }, [data])

    return {
        params,
        data,
        error,
        isLoading: isLoading || isFetchingData,
        status,
        singleRouteCount: data?.result ? convertToArrayAndFlatten(data.result.singleRoutes).length : 0,
        returnRouteCount: data?.result ? convertToArrayAndFlatten(data.result.returnRoutes).length : 0,
    }
}