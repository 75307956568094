import React from 'react';
import Ticket from "./Ticket";
import LatestTicketType from "@Sidebar/types/LatestTicket.type";

interface TicketsProps {
    latestTickets: LatestTicketType[],
}

const Tickets = ({latestTickets}: TicketsProps) => (
    <>
        {latestTickets.map((ticket, index) => (
            // We generally do not want to use index as key but ID is always 1 rendering it unusable
            // eslint-disable-next-line react/no-array-index-key
            <Ticket ticket={ticket} key={index}/>
        ))}
    </>
);

export default Tickets;