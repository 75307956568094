import React from "react";
import {isNotEmpty} from "@Array/isNotEmpty";
import SuggestedDates from "@RouteSuggestions/features/suggestedDates/components/SuggestedDates";
import ParamsType from "@SearchResults/types/params/Params.type";
import SuggestionsDataType from "@SearchResults/types/suggestions/SuggestionsData.type";
import useSingleConfirmedRoute from "@Route/hooks/useSingleConfirmedRoute";

interface RouteSuggestionsProps {
    suggestionData: SuggestionsDataType,
    params: ParamsType,
    hasRoutesOnSelectedDate?: boolean,
}

function RouteOtherDatesSuggestions({suggestionData, params, hasRoutesOnSelectedDate = false}: RouteSuggestionsProps) {
    const isSingleRouteConfirmed = useSingleConfirmedRoute()

    if (suggestionData && isNotEmpty(suggestionData?.dates)) {
        return (
            <SuggestedDates
                dates={suggestionData.dates}
                isReturn={params.isReturn}
                searchedDate={isSingleRouteConfirmed ? params.returnDate : params.date}
                hasRoutesOnSelectedDate={hasRoutesOnSelectedDate}
            />
        )
    }

    return null
}

export default RouteOtherDatesSuggestions
