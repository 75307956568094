import axios from "axios";
import qs from "qs";
import postError from "@Errors/functions/postError";

/**
 * @param {string} properties.url - request url
 * @param {object} [properties.params = null] - parameters for post request
 * @param {('log'|'ignore')} [properties.handle = 'ignore'] - log error || return null
 * @param {('get'|'post')} [properties.type = 'get'] - parameters for post request
 * @param {boolean} [properties.stringify = false] - optional stringify, used in some requests
 * @param {object} [properties.cancelToken = null] - leave null if request is not cancellable
 * @param {object} [properties.headers = null] - additional request headers
 * @param {boolean} [properties.withCredentials = false] - additional request headers - cross domain requests
 * @returns {Promise|null}
 */
export default async function ajaxHandler(properties) {
    const {
        url,
        handle = 'ignore',
        params = null,
        type = 'get',
        stringify = false,
        cancelToken = null,
        headers = null,
        withCredentials = false,
    } = properties

    const ajaxConfig = {
        withCredentials,
        headers: {"X-Requested-With": "XMLHttpRequest", ...headers},
        cancelToken,
    };

    try {
        if (type === 'get') {
            return await axios.get(url, {params, ...ajaxConfig});
        }

        if (type === 'post') {
            return await axios.post(url, stringify ? qs.stringify(params) : params, ajaxConfig);
        }
    } catch (error) {
        if (axios.isCancel(error)) {
            // eslint-disable-next-line no-console
            console.log('Request canceled', error?.message);
        }

        if (handle === 'log') {
            await postError({
                error: error.message ?? error,
                info: url,
                postToSlack: true,
                experiments: "not-provided",
                userId: 0,
            })()

            if (error.response) {
                return error.response ?? null
            }
        }

        if (error.response) {
            return error.response ?? null
        }

        return null
    }
}