import {useParams} from "react-router";
import {isMatch} from "date-fns";
import formatDate from "@DateTime/functions/formatDate";

export default function useActiveSliderDate(isReturn = false) {
    const {
        date: paramsSingleDate,
        returnDate: paramsReturnDate,
    } = useParams()

    const getActiveSliderDate = (date: string) => {
        const parsedDate = formatDate(date)
        const paramsDate = isReturn ? paramsReturnDate : parsedDate
        return isMatch(formatDate(date), 'yyyy-MM-dd') ? parsedDate : paramsDate
    }

    return {
        getActiveSliderDate,
    }
}