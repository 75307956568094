/**
 * Replaces array member at given index or update specific key in member
 */
export default function updateArrayMember(array: any[], index: number, name: string, value: any, entireObject: boolean = false): any[] {
    // copy all params of object, append new field
    const copy = entireObject ? {...array[index], ...value} : {...array[index], [name]: value}
    // copy all array objects, push new changed object
    const arrayCopy = [...array]
    arrayCopy.splice(index, 1, copy)

    return arrayCopy
}