import {Outlet, useParams} from "react-router";
import validateLocale from "@Features/locale/functions/validateLocale";
import React from "react";
import FallbackRoute from "@Routing/components/FallbackRoute";

/**
 * Check route locale id if it matches with our predefined locales
 */
function LocaleProtector() {
    const {locale} = useParams()

    if (validateLocale(locale)) {
        return (
            <Outlet/>
        )
    }

    return (
        <FallbackRoute/>
    )
}

export default LocaleProtector