import StationInputFieldType from "@Form/types/StationInputField.type";

interface ClickBusRentEventModelAttributes {
    departureStationObject: StationInputFieldType,
    destinationStationObject: StationInputFieldType | null,
    passengers: number,
    date: string | null,
    returnDate: string | null,
}

export default function ClickBusRentEventModel(props: ClickBusRentEventModelAttributes) {
    const {
        departureStationObject,
        destinationStationObject,
        passengers,
        date,
        returnDate,
    } = props

    const {
        countryCode: departureStationCountryCode,
        label: departureStationLabel,
        id: departureStationId,
    } = departureStationObject || {}

    const {
        countryCode: destinationStationCountryCode,
        label: destinationStationLabel,
        id: destinationStationId,
    } = destinationStationObject || {}

    return {
        from: departureStationLabel,
        fromId: departureStationId,
        fromCountry: departureStationCountryCode,
        to: destinationStationLabel,
        toId: destinationStationId,
        toCountry: destinationStationCountryCode,
        passengers,
        departureDate: date,
        retDepartureDate: returnDate,
    }
}