import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {FilterDepartureTimeSlotsType, setDepartureTimeFilter} from "@SearchResults/reducers/filterSlice";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import {isEmpty} from "@Array/isEmpty";

type TimeslotKeys = keyof FilterDepartureTimeSlotsType;
export default function useLoadDifferentDepartureTimeSlots(timeslotsChecked: 'earlier' | 'later') {
    const dispatch = useAppDispatch()
    const timeslots: FilterDepartureTimeSlotsType = useAppSelector((state) => state.filter.filterDepartureTimeSlots)

    const activeIndexes = Object.entries(timeslots).map((timeslot, index) => (timeslot[1] && index + 1)).filter((index) => (index));
    const maxIndex = Math.max(...activeIndexes);
    const minIndex = Math.min(...activeIndexes);

    const handleEarlierTimeslots = () => {
        dispatch(setDepartureTimeFilter({
            departureTime: Object.entries(timeslots)[minIndex - 2][0] as TimeslotKeys,
            isActive: true,
        }))
    }

    const handleLaterTimeslots = () => {
        dispatch(setDepartureTimeFilter({
            departureTime: Object.entries(timeslots)[maxIndex][0] as TimeslotKeys,
            isActive: true,
        }))
    }

    const onPress = () => {
        if (isEmpty(activeIndexes)) {
            return;
        }
        if (activeIndexes.length === Object.keys(timeslots).length) {
            return;
        }
        if (timeslotsChecked === 'earlier') {
            handleEarlierTimeslots()
        } else {
            handleLaterTimeslots()
        }
    }

    const isVisible = () => {
        if (isEmpty(activeIndexes)) {
            return false;
        }
        if (activeIndexes.length === Object.keys(timeslots).length) {
            return false;
        }
        if (timeslotsChecked === 'earlier') {
            return !activeIndexes.includes(1)
        }

        return !activeIndexes.includes(Object.keys(timeslots).length)
    }

    return {
        isVisible: isVisible(),
        onPress,
    }
}