import {useNavigate} from "react-router-dom";
import {PreparedRouteType} from "@Route/types/PreparedRoute.type";
import useSingleSelectedRoute from "@Route/hooks/useSingleSelectedRoute";
import {useParams} from "react-router";
import {useScroll} from "@Scroll/hooks/useScroll";
import useIsMobile from "@Hooks/selector/useIsMobile";

export default function useConfirmSingleRoute() {
    const navigate = useNavigate()
    const singleSelectedRoute = useSingleSelectedRoute()
    const {tab} = useParams()
    const isMobile = useIsMobile()
    const {scrollToTop} = useScroll({})

    // this is very messy, find a better solution
    const confirmSingleRoute = (singleConfirmedRoute: PreparedRouteType) => {
        navigate(isMobile ? tab : '', {
            state: {
                route: singleSelectedRoute,
                singleConfirmedRoute,
            },
        })
        if (!isMobile) {
            setTimeout(scrollToTop, 1)
        }
    }

    return {
        confirmSingleRoute,
    }
}