import Cookies from "universal-cookie";

/**
 * Sets cookie for 30 years
 * @param name {string}
 * @param value {*}
 */
export default function setCookie(name, value) {
    const expires = new Date()
    expires.setFullYear(expires.getFullYear() + 30)

    new Cookies().set(name, value, {expires, path: "/"})
}