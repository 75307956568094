import {isSameMonth, isSameYear, parseISO} from "date-fns";
import formatDate from "@DateTime/functions/formatDate";

interface DateIntervalFormatterAttributes {
    startDate: string,
    endDate?: string,
    includeHours?: boolean,
}

export default function dateIntervalFormatter(props: DateIntervalFormatterAttributes) {
    const {
        startDate,
        endDate,
        includeHours = false,
    } = props

    const standardDateFormat = "dd MMM, yyyy"

    const createDateFormat = (baseDateFormat: string): string => (
        `${baseDateFormat}${includeHours ? " | HH:mm" : ""}`
    )

    const createDateInterval = (formattedFirstDate: string, formattedSecondDate: string) => (
        `${formattedFirstDate} - ${formattedSecondDate}`
    )

    // If no return date
    if (!endDate) {
        return (
            formatDate(startDate, createDateFormat("MMMM dd, yyyy"))
        );
    }

    // If dates are the same month
    if (isSameYear(parseISO(startDate), parseISO(endDate)) && isSameMonth(parseISO(startDate), parseISO(endDate))) {
        return (
            createDateInterval(
                formatDate(startDate, createDateFormat(includeHours ? "dd MMM" : "dd")),
                formatDate(endDate, createDateFormat(standardDateFormat))
            )
        );
    }

    // If dates are the same year
    if (isSameYear(parseISO(startDate), parseISO(endDate))) {
        return (
            createDateInterval(
                formatDate(startDate, createDateFormat("dd MMM")),
                formatDate(endDate, createDateFormat(standardDateFormat))
            )
        );
    }

    // Display both dates fully
    return (
        createDateInterval(
            formatDate(startDate, createDateFormat(standardDateFormat)),
            formatDate(endDate, createDateFormat(standardDateFormat))
        )
    );
}