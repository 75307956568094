import React from 'react';
import CompanyType from "@SearchResults/types/company/Company.type";
import RouteActiveTicketContextType from "@Route/features/mobile/types/RouteActiveTicketContext.type";
import MobileRouteTravelDetails from "@Route/features/mobile/components/MobileRouteTravelDetails";
import MobileCompanyDetails from "@Route/features/mobile/components/MobileCompanyDetails";
import MobileRouteAmenities from "@Route/features/mobile/components/MobileRouteAmenities";
import MobileRouteStations from "@Route/features/mobile/components/MobileRouteStations";
import MobileDiscountsSection from "@Route/features/mobile/components/MobileDiscountsSection";
import MobileSummarySection from "@Route/features/mobile/components/MobileSummarySection";
import ParamsType from "@SearchResults/types/params/Params.type";
import {useScroll} from "@Scroll/hooks/useScroll";
import usePrepareRouteForAdditionalInfo
    from "@SearchResults/features/additionalInfo/hooks/usePrepareRouteForAdditionalInfo";
import PassengerGroupType from "@SearchResults/types/passenger/PassengerGroup.type";
import RouteType from "@Route/types/Route.type";
import RoutePassengerClassesContainer from "@Route/components/PassengerClasses/RoutePassengerClassesContainer";
import MobileContentContainer from "@Route/features/mobile/components/MobileContentContainer";
import {isEmpty} from "@Array/isEmpty";
import SeatClass from "@Route/components/SeatClass/SeatClass";

interface RouteMobileAdditionalInfoProps {
    route: RouteType
    activeTicketSections: RouteActiveTicketContextType,
    displayActionButton?: boolean,
    params: ParamsType,
    isReturn: boolean,
    companies: Record<number, CompanyType>,
    passengerGroups: Record<number, PassengerGroupType>,
}

const RouteMobileAdditionalInfo = (props: RouteMobileAdditionalInfoProps) => {
    const {
        route,
        activeTicketSections,
        displayActionButton = true,
        params,
        isReturn,
        companies,
        passengerGroups,
    } = props

    const {
        ticketInfoSection,
        companySection,
        amenitiesSection,
        travelInfoSection,
        discountsSection,
        seatClassSection,
    } = activeTicketSections

    const {prepareRoute} = usePrepareRouteForAdditionalInfo({
        companies,
        isReturn,
        passengerGroups,
    })

    const preparedRoute = prepareRoute(route)

    const {scrollToTop} = useScroll({block: "center", behavior: "instant"})
    scrollToTop()
    return (
        <>
            <MobileRouteTravelDetails
                params={params}
                route={preparedRoute}
                company={preparedRoute.company}
                scrollRef={ticketInfoSection.refs.elRef}
                isReturn={isReturn}
            />
            {seatClassSection && (
                <MobileContentContainer
                    title={seatClassSection.title}
                    scrollRef={seatClassSection.refs.elRef}
                >
                    <SeatClass
                        displayImages
                        seatClass={route.travelInfo.seatClass}
                    />
                </MobileContentContainer>
            )}
            <MobileCompanyDetails
                route={preparedRoute}
                company={preparedRoute.company}
                scrollRef={companySection.refs.elRef}
            />
            {amenitiesSection && (
                <MobileRouteAmenities
                    amenitiesHashMap={preparedRoute.amenitiesHashMap}
                    scrollRef={amenitiesSection.refs.elRef}
                />
            )}
            {travelInfoSection && (
                <MobileRouteStations
                    travelInfo={preparedRoute.travelInfo}
                    routeId={preparedRoute.id}
                    scrollRef={travelInfoSection.refs.elRef}
                />
            )}
            {discountsSection && (
                <MobileDiscountsSection
                    discounts={preparedRoute.prices.discounts}
                    scrollRef={discountsSection.refs.elRef}
                />
            )}
            {displayActionButton && (
                <MobileSummarySection
                    isReturn={isReturn}
                    route={preparedRoute}
                    params={params}
                />
            )}
        </>
    )
}

export default RouteMobileAdditionalInfo
