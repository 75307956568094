import React from 'react';
import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "@/types/Icon.type";

const IconFolderArchived = (props: IconType) => {
    const {
        width = '35',
        height = '35',
        viewBox = '0 0 35 35',
        title = 'IconFolderArchived',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none" transform="translate(3 2)">
                    <g
                        opacity=".696669"
                        transform="matrix(.57357644 -.81915204 .81915204 .57357644 6.652291 18.608836)"
                    >
                        <path
                            d="m12.25419 1.67865616v-.67146246c-.2853716 0-.5035969-.2182253-.5035969-.50359685h-9.56834009c-.92326089 0-1.67865616.75539527-1.67865616 1.67865616v2.35011862c1.20863243 0 2.18225301.97362057 2.18225301 2.18225301 0 1.20863243-.97362058 2.18225301-2.18225301 2.18225301v1.67865615c0 .9232609.75539527 1.6786562 1.67865616 1.6786562h9.56834009c0-.2853716.2182253-.5035969.5035969-.5035969v-.6714624c-.2853716 0-.5035969-.2182253-.5035969-.5035969 0-.2853715.2182253-.5035968.5035969-.5035968v-.6714625c-.2853716 0-.5035969-.21822531-.5035969-.50359685 0-.28537155.2182253-.50359685.5035969-.50359685v-.67146246c-.2853716 0-.5035969-.21822149-.5035969-.50359685 0-.28537155.2182253-.50359685.5035969-.50359685v-.67146246c-.2853716 0-.5035969-.21822531-.5035969-.50359685 0-.28537155.2182253-.50359685.5035969-.50359685v-.67146246c-.2853716 0-.5035969-.21822531-.5035969-.50359685 0-.28537155.2182253-.50359685.5035969-.50359685v-.67146246c-.2853716 0-.5035969-.21822531-.5035969-.50359685 0-.28537155.2182253-.50359685.5035969-.50359685z"
                            fill="#fff"
                        />
                        <g fill="#e6e6ef" transform="translate(4.19664 3.357312)">
                            <path
                                d="m5.53956533 1.0071937h-5.03596848c-.28537155 0-.50359685-.2182253-.50359685-.50359685s.2182253-.50359685.50359685-.50359685h5.03596848c.28537154 0 .50359685.2182253.50359685.50359685s-.21822531.50359685-.50359685.50359685z"
                            />
                            <path
                                d="m5.53956533 4.36450602h-5.03596848c-.28537155 0-.50359685-.21822531-.50359685-.50359685 0-.28537155.2182253-.50359685.50359685-.50359685h5.03596848c.28537154 0 .50359685.2182253.50359685.50359685 0 .28537154-.21822531.50359685-.50359685.50359685z"
                            />
                            <path
                                d="m3.86090917 6.04316218h-3.35731232c-.28537155 0-.50359685-.21822531-.50359685-.50359685 0-.28537155.2182253-.50359685.50359685-.50359685h3.35731232c.28537154 0 .50359685.2182253.50359685.50359685 0 .28537154-.21822531.50359685-.50359685.50359685z"
                            />
                            <path
                                d="m5.53956533 6.04316218c-.03357313 0-.06714625 0-.10071937-.01678657-.03357313 0-.06714625-.01678656-.10071937-.03357312s-.05035969-.03357312-.08393281-.05035968c-.03357312-.01678657-.05035968-.03357313-.08393281-.06714625-.08393281-.06714625-.13429249-.20143874-.13429249-.33573123 0-.1342925.05035968-.26858499.15107905-.3525178.18465218-.18465217.52038341-.18465217.70503559 0 .10071937.10071937.15107906.2182253.15107906.3525178 0 .13429249-.05035969.26858498-.15107906.35251779-.08393281.10071937-.2182253.15107906-.35251779.15107906z"
                            />
                            <path
                                d="m2.18225301 2.68584986h-1.67865616c-.28537155 0-.50359685-.21822531-.50359685-.50359685 0-.28537155.2182253-.50359685.50359685-.50359685h1.67865616c.28537154 0 .50359685.2182253.50359685.50359685 0 .28537154-.21822531.50359685-.50359685.50359685z"
                            />
                            <path
                                d="m5.53956533 2.68584986h-1.67865616c-.28537155 0-.50359685-.21822531-.50359685-.50359685 0-.28537155.2182253-.50359685.50359685-.50359685h1.67865616c.28537154 0 .50359685.2182253.50359685.50359685 0 .28537154-.21822531.50359685-.50359685.50359685z"
                            />
                        </g>
                        <path
                            d="m18.9688146 2.18225301c0-.92326089-.7553953-1.67865616-1.6786562-1.67865616h-4.5323716c0 .28537155-.2182253.50359685-.5035968.50359685v.67146246c.2853715 0 .5035968.2182253.5035968.50359685 0 .28537154-.2182253.50359685-.5035968.50359685v.67146246c.2853715 0 .5035968.2182253.5035968.50359685 0 .28537154-.2182253.50359685-.5035968.50359685v.67146246c.2853715 0 .5035968.2182253.5035968.50359685 0 .28537154-.2182253.50359685-.5035968.50359685v.67146246c.2853715 0 .5035968.2182253.5035968.50359685 0 .28537536-.2182253.50359685-.5035968.50359685v.67146246c.2853715 0 .5035968.2182253.5035968.50359685 0 .28537154-.2182253.50359685-.5035968.50359685v.6714625c.2853715 0 .5035968.2182253.5035968.5035968 0 .2853716-.2182253.5035969-.5035968.5035969v.6714624c.2853715 0 .5035968.2182253.5035968.5035969h4.5323716c.9232609 0 1.6786562-.7553953 1.6786562-1.6786562z"
                            fill="#b5b7d1"
                        />
                        <g fill="#070c63">
                            <path
                                d="m17.2901584 12.7577868h-3.3573123c-.2853715 0-.5035968-.2182253-.5035968-.5035968 0-.2853716.2182253-.5035969.5035968-.5035969h3.3573123c.6546759 0 1.1750594-.5203834 1.1750594-1.1750593v-8.39328079c0-.6546759-.5203835-1.17505931-1.1750594-1.17505931h-3.3573123c-.2853715 0-.5035968-.2182253-.5035968-.50359685s.2182253-.50359685.5035968-.50359685h3.3573123c1.2086325 0 2.1822531.97362057 2.1822531 2.18225301v8.39328079c0 1.2086324-.9736206 2.182253-2.1822531 2.182253z"
                            />
                            <path
                                d="m10.5755338 11.7505931h-8.39328079c-.6546759 0-1.17505931-.5203834-1.17505931-1.1750593v-1.22541899c1.24220555-.23501186 2.182253-1.32613837 2.182253-2.63549017 0-1.30935181-.94004745-2.40047831-2.182253-2.63549017v-1.89688146c0-.6546759.52038341-1.17505931 1.17505931-1.17505931h8.39328079c.2853716 0 .5035969-.2182253.5035969-.50359685s-.2182253-.50359685-.5035969-.50359685h-8.39328079c-1.20863244 0-2.18225301.97362057-2.18225301 2.18225301v2.35011862c0 .28537155.2182253.50359685.50359685.50359685.92326089 0 1.67865616.75539527 1.67865616 1.67865616s-.75539527 1.67865616-1.67865616 1.67865616c-.28537155 0-.50359685.2182253-.50359685.50359685v1.67865615c0 1.2086324.97362057 2.182253 2.18225301 2.182253h8.39328079c.2853716 0 .5035969-.2182253.5035969-.5035968 0-.2853716-.2182253-.5035969-.5035969-.5035969z"
                            />
                        </g>
                    </g>
                    <g transform="matrix(.57357644 -.81915204 .81915204 .57357644 .089791 17.202586)">
                        <path
                            d="m13.3942218 1.67865616v-.67146246c-.313058 0-.5524553-.2182253-.5524553-.50359685h-10.49665179c-1.01283483 0-1.84151786.75539527-1.84151786 1.67865616v2.35011862c1.32589286 0 2.39397321.97362057 2.39397321 2.18225301 0 1.20863243-1.06808035 2.18225301-2.39397321 2.18225301v1.67865615c0 .9232609.82868303 1.6786562 1.84151786 1.6786562h10.49665179c0-.2853716.2393973-.5035969.5524553-.5035969v-.6714624c-.313058 0-.5524553-.2182253-.5524553-.5035969 0-.2853715.2393973-.5035968.5524553-.5035968v-.6714625c-.313058 0-.5524553-.21822531-.5524553-.50359685 0-.28537155.2393973-.50359685.5524553-.50359685v-.67146246c-.313058 0-.5524553-.21822149-.5524553-.50359685 0-.28537155.2393973-.50359685.5524553-.50359685v-.67146246c-.313058 0-.5524553-.21822531-.5524553-.50359685 0-.28537155.2393973-.50359685.5524553-.50359685v-.67146246c-.313058 0-.5524553-.21822531-.5524553-.50359685 0-.28537155.2393973-.50359685.5524553-.50359685v-.67146246c-.313058 0-.5524553-.21822531-.5524553-.50359685 0-.28537155.2393973-.50359685.5524553-.50359685z"
                            fill="#fff"
                        />
                        <g fill="#e6e6ef" transform="translate(4.19664 3.357312)">
                            <path
                                d="m5.53956533 1.0071937h-5.03596848c-.28537155 0-.50359685-.2182253-.50359685-.50359685s.2182253-.50359685.50359685-.50359685h5.03596848c.28537154 0 .50359685.2182253.50359685.50359685s-.21822531.50359685-.50359685.50359685z"
                            />
                            <path
                                d="m5.53956533 4.36450602h-5.03596848c-.28537155 0-.50359685-.21822531-.50359685-.50359685 0-.28537155.2182253-.50359685.50359685-.50359685h5.03596848c.28537154 0 .50359685.2182253.50359685.50359685 0 .28537154-.21822531.50359685-.50359685.50359685z"
                            />
                            <path
                                d="m3.86090917 6.04316218h-3.35731232c-.28537155 0-.50359685-.21822531-.50359685-.50359685 0-.28537155.2182253-.50359685.50359685-.50359685h3.35731232c.28537154 0 .50359685.2182253.50359685.50359685 0 .28537154-.21822531.50359685-.50359685.50359685z"
                            />
                            <path
                                d="m5.53956533 6.04316218c-.03357313 0-.06714625 0-.10071937-.01678657-.03357313 0-.06714625-.01678656-.10071937-.03357312s-.05035969-.03357312-.08393281-.05035968c-.03357312-.01678657-.05035968-.03357313-.08393281-.06714625-.08393281-.06714625-.13429249-.20143874-.13429249-.33573123 0-.1342925.05035968-.26858499.15107905-.3525178.18465218-.18465217.52038341-.18465217.70503559 0 .10071937.10071937.15107906.2182253.15107906.3525178 0 .13429249-.05035969.26858498-.15107906.35251779-.08393281.10071937-.2182253.15107906-.35251779.15107906z"
                            />
                            <path
                                d="m2.18225301 2.68584986h-1.67865616c-.28537155 0-.50359685-.21822531-.50359685-.50359685 0-.28537155.2182253-.50359685.50359685-.50359685h1.67865616c.28537154 0 .50359685.2182253.50359685.50359685 0 .28537154-.21822531.50359685-.50359685.50359685z"
                            />
                            <path
                                d="m5.53956533 2.68584986h-1.67865616c-.28537155 0-.50359685-.21822531-.50359685-.50359685 0-.28537155.2182253-.50359685.50359685-.50359685h1.67865616c.28537154 0 .50359685.2182253.50359685.50359685 0 .28537154-.21822531.50359685-.50359685.50359685z"
                            />
                        </g>
                        <path
                            d="m18.9688146 2.18225301c0-.92326089-.7553953-1.67865616-1.6786562-1.67865616h-4.5323716c0 .28537155-.2182253.50359685-.5035968.50359685v.67146246c.2853715 0 .5035968.2182253.5035968.50359685 0 .28537154-.2182253.50359685-.5035968.50359685v.67146246c.2853715 0 .5035968.2182253.5035968.50359685 0 .28537154-.2182253.50359685-.5035968.50359685v.67146246c.2853715 0 .5035968.2182253.5035968.50359685 0 .28537154-.2182253.50359685-.5035968.50359685v.67146246c.2853715 0 .5035968.2182253.5035968.50359685 0 .28537536-.2182253.50359685-.5035968.50359685v.67146246c.2853715 0 .5035968.2182253.5035968.50359685 0 .28537154-.2182253.50359685-.5035968.50359685v.6714625c.2853715 0 .5035968.2182253.5035968.5035968 0 .2853716-.2182253.5035969-.5035968.5035969v.6714624c.2853715 0 .5035968.2182253.5035968.5035969h4.5323716c.9232609 0 1.6786562-.7553953 1.6786562-1.6786562z"
                            fill="#b5b7d1"
                        />
                        <g fill="#070c63">
                            <path
                                d="m17.2901584 12.7577868h-3.3573123c-.2853715 0-.5035968-.2182253-.5035968-.5035968 0-.2853716.2182253-.5035969.5035968-.5035969h3.3573123c.6546759 0 1.1750594-.5203834 1.1750594-1.1750593v-8.39328079c0-.6546759-.5203835-1.17505931-1.1750594-1.17505931h-3.3573123c-.2853715 0-.5035968-.2182253-.5035968-.50359685s.2182253-.50359685.5035968-.50359685h3.3573123c1.2086325 0 2.1822531.97362057 2.1822531 2.18225301v8.39328079c0 1.2086324-.9736206 2.182253-2.1822531 2.182253z"
                            />
                            <path
                                d="m10.5755338 11.7505931h-8.39328079c-.6546759 0-1.17505931-.5203834-1.17505931-1.1750593v-1.22541899c1.24220555-.23501186 2.182253-1.32613837 2.182253-2.63549017 0-1.30935181-.94004745-2.40047831-2.182253-2.63549017v-1.89688146c0-.6546759.52038341-1.17505931 1.17505931-1.17505931h8.39328079c.2853716 0 .5035969-.2182253.5035969-.50359685s-.2182253-.50359685-.5035969-.50359685h-8.39328079c-1.20863244 0-2.18225301.97362057-2.18225301 2.18225301v2.35011862c0 .28537155.2182253.50359685.50359685.50359685.92326089 0 1.67865616.75539527 1.67865616 1.67865616s-.75539527 1.67865616-1.67865616 1.67865616c-.28537155 0-.50359685.2182253-.50359685.50359685v1.67865615c0 1.2086324.97362057 2.182253 2.18225301 2.182253h8.39328079c.2853716 0 .5035969-.2182253.5035969-.5035968 0-.2853716-.2182253-.5035969-.5035969-.5035969z"
                            />
                        </g>
                    </g>
                    <g transform="translate(0 12.015625)">
                        <path
                            d="m22.1953125 3.75h-7.8515625c-1.21875 0-2.34375-.5625-3.09375-1.5234375-.75-.9609375-1.875-1.5234375-3.09375-1.5234375h-6.28125c-.65625 0-1.171875.515625-1.171875 1.171875v15.234375c0 .65625.515625 1.171875 1.171875 1.171875h21.4921875v-13.359375c0-.65625-.5390625-1.171875-1.171875-1.171875z"
                            fill="#b5b7d1"
                        />
                        <path
                            d="m7.734375 17.109375c0 .65625.515625 1.171875 1.171875 1.171875h14.4609375v-2.34375h-14.4609375c-.65625 0-1.171875.515625-1.171875 1.171875z"
                            fill="#777aaa"
                        />
                        <path
                            d="m24.9140625 18.984375h-23.0390625c-1.03125 0-1.875-.84375-1.875-1.875v-15.234375c0-1.03125.84375-1.875 1.875-1.875h6.28125c1.4296875 0 2.7890625.6796875 3.65625 1.8046875.5859375.7734375 1.546875 1.2421875 2.53125 1.2421875h7.8515625c1.03125 0 1.875.84375 1.875 1.875v11.7890625c0 .46875.375.8671875.8671875.8671875.46875 0 .8671875-.375.8671875-.8671875v-12.9609375c0-.3984375.3046875-.703125.703125-.703125s.703125.3046875.703125.703125v12.9609375c-.0234375 1.265625-1.03125 2.2734375-2.296875 2.2734375zm-23.0390625-17.578125c-.2578125 0-.46875.2109375-.46875.46875v15.234375c0 .2578125.2109375.46875.46875.46875h20.953125c-.1171875-.2578125-.1640625-.5625-.1640625-.8671875v-11.7890625c0-.2578125-.2109375-.46875-.46875-.46875h-7.8515625c-1.4296875 0-2.7890625-.6796875-3.65625-1.8046875-.5859375-.7734375-1.546875-1.2421875-2.53125-1.2421875z"
                            fill="#070c63"
                        />
                    </g>
                </g>
            )}
        />

    );
}

export default IconFolderArchived;
