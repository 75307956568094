import PassengerGroupWithPricesAndCountType from "@SearchResults/types/passenger/PassengerGroupWithPricesAndCount.type";

export default function useRoutePassengerPriceBreakdownGeneratorForPrivateVehicles(isPrivateVehicle: boolean, pricesPerGroup: PassengerGroupWithPricesAndCountType[]) {
    const priceDividedPerPassenger = () => pricesPerGroup.map((group) => {
        const priceWithDiscount = group.priceWithDiscount / group.numberOfPassengers
        const priceWithoutDiscount = group.priceWithoutDiscount / group.numberOfPassengers
        return {
            ...group,
            priceWithDiscount,
            priceWithoutDiscount,
        }
    })

    return {
        pricesPerGroup: isPrivateVehicle ? priceDividedPerPassenger() : pricesPerGroup,
    }
}