import {useTranslation} from "./useTranslation";
import {useAppSelector} from "@Hooks/selector/useAppSelector";

/**
 * Returns translation key with replaced %transport-type% based on active tab
 * @param translationKey
 * @param forwardedTranslation {string} - optional, used when translation
 * has to be modified via useQuantityAwareTranslation hook
 * @return {string}
 */
export default function useTransportTypeAwareTranslation(translationKey: string, forwardedTranslation: string = undefined) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const translation = forwardedTranslation ?? useTranslation(translationKey)
    const activeTab = useAppSelector((state) => state?.searchResults.tab?.tabType) ?? 'bus'
    const {
        bus,
        train,
        ferry,
        plane,
    } = useTranslation("sys")

    const translationTypes = {
        [`${activeTab === 'train'}`]: translation.toLowerCase().replace(bus.toLowerCase(), train.toLowerCase()),
        [`${activeTab === 'ferry'}`]: translation.toLowerCase().replace(bus.toLowerCase(), ferry.toLowerCase()),
        [`{$activeTab === 'plane'}`]: translation.toLowerCase().replace(bus.toLowerCase(), plane.toLowerCase()),
    }

    return translationTypes.true ?? translation
}