import React from 'react';
import intToBool from "@Number/intToBool";
import useRouteRatings from "@Route/hooks/useRouteRatings";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";
import RouteBasedRating from "@Route/features/company/components/RouteBasedRating";
import OverallRating from "@Route/features/company/components/OverallRating";

interface RouteRatingInformationProps {
    route: RouteWithAddedPropsAndPricesType,
}

function RouteRatingInformation({route}: RouteRatingInformationProps) {
    const {
        ratings: {
            companyRating,
            routeRating,
            stationRating,
        },
        displayStationRating,
        displayRouteRating,
        displayCompanyRating,
    } = useRouteRatings(route)

    const rating = companyRating?.score !== 'N/A' ? (companyRating.score).toFixed(1) : null

    return (
        <>
            {!!rating && intToBool(rating) && (
                <>
                    {displayRouteRating && (
                        <RouteBasedRating
                            departure={route.travelInfo.departureStation.name}
                            destination={route.travelInfo.destinationStation.name}
                            ratings={routeRating}
                        />
                    )}

                    {displayStationRating && (
                        <RouteBasedRating
                            departure={route.travelInfo.departureStation.name}
                            destination={route.travelInfo.destinationStation.name}
                            ratings={stationRating}
                        />
                    )}

                    {displayCompanyRating && (
                        <OverallRating
                            companyRating={companyRating}
                            rating={rating}
                        />
                    )}
                </>
            )}
        </>
    );
}

export default RouteRatingInformation;