import React from 'react';
import MobileMenu from "@User/components/Mobile/MobileMenu";
import MobileOverlay from "./MobileOverlay";
import useDismissAllOverlays from "@MobileOverlays/hooks/useDismissAllOverlays";

function MobileMenuOverlay() {
    const dismissAllOverlays = useDismissAllOverlays()
    return (
        <MobileOverlay
            title="Menu"
            slideInDirection="left"
            backClick={dismissAllOverlays}
        >
            <MobileMenu/>
        </MobileOverlay>
    );
}

export default MobileMenuOverlay;