import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconWheelchair(props: IconType) {
    const {
        width = '35',
        height = '35',
        viewBox = '0 0 35 35',
        title = 'IconWheelchair',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none" fillRule="evenodd" transform="translate(-1 1.2)">
                    <path d="m6.5 1.3h.5c2.209139 0 4 1.790861 4 4v21" stroke="#363636"/>
                    <g transform="matrix(.81915204 .57357644 -.57357644 .81915204 24.441458 26.621815)">
                        <g fill="#828383">
                            <rect height="1" rx=".175" width="4.9" x=".175" y="2.45"/>
                            <rect
                                height="1"
                                rx=".175"
                                transform="matrix(.70710678 .70710678 -.70710678 .70710678 2.625 -1.087311)"
                                width="4.9"
                                x=".175"
                                y="2.45"
                            />
                            <rect
                                height="1"
                                rx=".175"
                                transform="matrix(0 1 -1 0 5.25 0)"
                                width="4.9"
                                x=".175"
                                y="2.45"
                            />
                            <rect
                                height="1"
                                rx=".175"
                                transform="matrix(-.70710678 .70710678 -.70710678 -.70710678 6.337311 2.625)"
                                width="4.9"
                                x=".175"
                                y="2.45"
                            />
                        </g>
                        <circle cx="2.625" cy="2.625" r="1.77" stroke="#e8e8e8" strokeWidth="1.5"/>
                        <circle cx="2.625" cy="2.625" r="1" stroke="#e8e8e8" strokeWidth=".5"/>
                        <circle cx="2.625" cy="2.625" fill="#cacaca" r="1"/>
                    </g>
                    <path
                        d="m25 23.3c1.1045695 0 2 .8954305 2 2v5"
                        stroke="#363636"
                        strokeLinecap="round"
                        transform="matrix(1 0 0 -1 0 53.6)"
                    />
                    <g fill="#a18250">
                        <path d="m11 13.8h16v6h-16z"/>
                        <path d="m14 7.8h11c.5522847 0 1 .44771525 1 1v2h-13v-2c0-.55228475.4477153-1 1-1z"/>
                        <path
                            d="m9 6.8h5c.5522847 0 1 .44771525 1 1h-7c0-.55228475.44771525-1 1-1z"
                            transform="matrix(0 1 -1 0 18.8 -4.2)"
                        />
                    </g>
                    <rect fill="#ff7676" height="2.5" rx="1" width="6" x="2"/>
                    <path d="m3.25 0h3.5v2.5h-3.5z" fill="#686867"/>
                    <path
                        d="m16 9.8h6c2.7614237 0 5 2.2385763 5 5v11h-16v-11c0-2.7614237 2.2385763-5 5-5z"
                        stroke="#363636"
                    />
                    <path
                        d="m22 10.4c1.2150264 0 2.3150264.4924868 3.1112698 1.2887302s1.2887302 1.8962434 1.2887302 3.1112698v4.4h-14.8v-4.4c0-1.2150264.4924868-2.3150264 1.2887302-3.1112698s1.8962434-1.2887302 3.1112698-1.2887302z"
                        stroke="#363636"
                        strokeWidth="1.2"
                    />
                    <g transform="matrix(.81915204 .57357644 -.57357644 .81915204 9.158183 14.6)">
                        <g fill="#828383">
                            <rect height="1" rx=".5" width="14" x=".5" y="7"/>
                            <rect
                                height="1"
                                rx=".5"
                                transform="matrix(.70710678 .70710678 -.70710678 .70710678 7.5 -3.106602)"
                                width="14"
                                x=".5"
                                y="7"
                            />
                            <rect height="1" rx=".5" transform="matrix(0 1 -1 0 15 0)" width="14" x=".5" y="7"/>
                            <rect
                                height="1"
                                rx=".5"
                                transform="matrix(-.70710678 .70710678 -.70710678 -.70710678 18.106602 7.5)"
                                width="14"
                                x=".5"
                                y="7"
                            />
                        </g>
                        <circle cx="7.5" cy="7.5" r="7.2" stroke="#e8e8e8" strokeWidth="2"/>
                        <circle cx="7.5" cy="7.5" r="1.2" stroke="#e8e8e8" strokeWidth="1.2"/>
                        <circle cx="7.5" cy="7.5" fill="#cacaca" r="1"/>
                    </g>
                    <path
                        d="m27 22.8726431c1.1905985.0009397 2.1317789.4826752 2.8235412 1.4452065.4551206.6332635.4887077 2.7161654.9550534 4.1375265.1616522.4926947.5568709.9864054 1.1856562 1.4811322"
                        stroke="#363636"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <rect
                        fill="#363636"
                        height="2"
                        rx=".5"
                        transform="matrix(.93969262 -.34202014 .34202014 .93969262 -8.073366 13.061133)"
                        width="4"
                        x="31"
                        y="28.423733"
                    />
                </g>
            )}
        />

    );
}

export default IconWheelchair;
