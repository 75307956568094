import {
    TicketAdditionalInfoFieldsType
} from "@CheckOrder/features/TicketAdditionalInfo/types/TicketAdditionalInfoFields.type";
import useUpdateAdditionalFieldPassengerValue
    from "@CheckOrder/features/TicketAdditionalInfo/hooks/useUpdateAdditionalFieldPassengerValue";
import TextAdditionalField from "@CheckOrder/features/TicketAdditionalInfo/components/TextAdditionalField";
import NumberAdditionalField from "@CheckOrder/features/TicketAdditionalInfo/components/NumberAdditionalField";
import SelectAdditionalField from "@CheckOrder/features/TicketAdditionalInfo/components/SelectAdditionalField";
import RadioAdditionalField from "@CheckOrder/features/TicketAdditionalInfo/components/RadioAdditionalField";
import CheckboxAdditionalField from "@CheckOrder/features/TicketAdditionalInfo/components/CheckboxAdditionalField";

import CountryAdditionalField from "@CheckOrder/features/TicketAdditionalInfo/components/CountryAdditionalField";
import allowNumberFieldUpdate from "@CheckOrder/features/TicketAdditionalInfo/functions/allowNumberFieldUpdate";
import DateAdditionalField
    from "@CheckOrder/features/TicketAdditionalInfo/features/dateAdditionalField/components/DateAdditionalField";
import useAdditionalFieldFilter from "@CheckOrder/features/TicketAdditionalInfo/hooks/useAdditionalFieldFilter";

type AdditionalFieldProps = {
    field: TicketAdditionalInfoFieldsType,
    index: number,
    userConfirmed: boolean,
    updateAllPassengers?: boolean,
    isReturn?: boolean
}

function AdditionalField({field, userConfirmed, index, updateAllPassengers, isReturn}: AdditionalFieldProps) {
    const {
        updateAdditionalField,
        value,
        onClick,
        onCountrySelect,
        updateValue,
    } = useUpdateAdditionalFieldPassengerValue(field, index, updateAllPassengers, isReturn)
    const {fieldsHaveSameValues} = useAdditionalFieldFilter(isReturn, updateAllPassengers)

    if (isReturn && fieldsHaveSameValues(field)) {
        return null
    }

    if (field.type === 'select') {
        return (
            <SelectAdditionalField
                userConfirmed={userConfirmed}
                onClick={onClick}
                value={value}
                field={field}
                updateValue={updateValue}
            />
        )
    }

    if (field.type === 'text') {
        return (
            <TextAdditionalField
                onChange={updateAdditionalField()}
                value={value}
                field={field}
            />
        )
    }

    if (field.type === 'number') {
        return (
            <NumberAdditionalField
                onChange={updateAdditionalField(allowNumberFieldUpdate(field))}
                value={value}
                field={field}
            />
        )
    }

    if (field.type === 'radio') {
        return (
            <RadioAdditionalField
                userConfirmed={userConfirmed}
                field={field}
                value={value}
                onClick={onClick}
                index={index}
            />
        )
    }

    if (field.type === 'checkbox') {
        return (
            <CheckboxAdditionalField
                userConfirmed={userConfirmed}
                field={field}
                value={value}
                // @ts-ignore
                onClick={onClick}
                index={index}
            />
        )
    }

    if (field.type === 'date') {
        return (
            <DateAdditionalField
                userConfirmed={userConfirmed}
                field={field}
                value={value}
                onClick={onClick}
            />
        )
    }

    if (field.type === 'country') {
        return (
            <CountryAdditionalField
                field={field}
                value={value}
                onCountrySelect={onCountrySelect}
                userConfirmed={userConfirmed}
            />
        )
    }
}

export default AdditionalField;