import ExplicitBookingFeeSummary from "@CheckOrder/features/paymentSummary/features/bookingFeeSummary/components/ExplicitBookingFeeSummary";
import BookingFee from "@CheckOrder/features/paymentSummary/features/bookingFeeSummary/components/BookingFee";
import React from "react";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import usePurchasedRoute from "@CheckOrder/hooks/usePurchasedRoute";
import {useTranslation} from "@Translation/hooks/useTranslation";
import roundToTwoDecimals from "@Number/roundToTwoDecimals"
import useCarrierBookingFees from "@CheckOrder/hooks/useCarrierBookingFees"

function BookingFeeSummary() {
    const {
        ticket: {
            singleBookingFee,
            returnBookingFee,
            bookingFee,
        },
    } = useAppSelector((state) => state?.checkOrder);

    const {
        returnRoute,
        singleRoute,
    } = usePurchasedRoute();

    const {getCarrierFees} = useCarrierBookingFees()
    const carrierFee = getCarrierFees()
    const displayExplicitBookingFees = (singleRoute.status.isJadrolinijaStateLine || returnRoute?.status?.isJadrolinijaStateLine);
    const bookingFeeLabel = useTranslation("sys.booking_fee");

    return (
        <>
            {carrierFee && (
                <BookingFee
                    bookingFeeLabel="Carrier booking fee"
                    bookingFee={carrierFee}
                />
            )}
            {displayExplicitBookingFees && singleBookingFee && returnBookingFee ? (
                <ExplicitBookingFeeSummary
                    singleBookingFee={singleBookingFee}
                    returnBookingFee={returnBookingFee}
                />
            ) : (
                <BookingFee
                    bookingFeeLabel={bookingFeeLabel}
                    bookingFee={bookingFee}
                />
            )}
        </>
    );
}

export default BookingFeeSummary;