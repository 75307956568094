import React from 'react';
import Translation from "@Translation/components/Translation";
import IconCheckMarkCircle from "@Icons/IconCheckMarkCircle";
import useIsDesktop from "@Hooks/selector/useIsDesktop";
import useIsTablet from "@Hooks/selector/useIsTablet";

interface ServiceListElementProps {
    services: string[]
}

function ServiceListElement({services}: ServiceListElementProps) {
    const isDesktop = useIsDesktop()
    const isTablet = useIsTablet()

    const setClassName = () => {
        if (!isDesktop && !isTablet) {
            return 'gb--column'
        }
        return null
    }
    return (
        <p className={`banner__heading--list ${setClassName()}`}>
            {services.map((service, index) => (
                <span key={index}>
                    <IconCheckMarkCircle/>
                    <Translation translationKey={service}/>
                </span>
            ))}
        </p>
    );
}

export default ServiceListElement;