import RouteType from "@Route/types/Route.type";

/**
 * Returns minimal prices from routes array
 */
export default function getMinimalPrice(routes: RouteType[]) {
    const minRoutePriceRoute = routes.map(
        (route) => (route?.prices?.priceWithDiscount?.[1]?.[1])
    )

    return parseFloat(`${Math.min(...minRoutePriceRoute)}` ?? '0')
}