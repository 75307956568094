import React from 'react';
import Translation from "@Translation/components/Translation";
import IconSignpost from "@Icons/FilledOutline/IconSignpost";
import IconPaxWithLuggage from "@Icons/FilledOutline/IconPaxWithLuggage";
import IconPeopleGrouped from "@Icons/FilledOutline/IconPeopleGrouped";
import TransportationIcons from "@Icons/FilledOutline/TransportationIcons";
import {useTranslation} from "@Translation/hooks/useTranslation";
import useIsMobile from "@Hooks/selector/useIsMobile";

const HeroVectorLarge = () => {
    const mainTitle = useTranslation('sys.redirect_title')
    const isMobile = useIsMobile()
    const style = {fontSize: (!isMobile && mainTitle.length > 30) ? '2vmax' : ''}
    return (
        <>
            <h1 className="hero__title hero__title--container" style={style}>
                {mainTitle}
            </h1>
            <div className="hero__icons">
                <span><IconSignpost viewBox="0 0 85 80" width="100%" preserveAspectRatio="xMinYMax meet"/></span>
                <span><IconPaxWithLuggage viewBox="0 0 85 85" width="100%" preserveAspectRatio="xMinYMax meet"/></span>
                <span/>
                <span><IconPeopleGrouped viewBox="0 0 100 85" width="100%" preserveAspectRatio="xMinYMax meet"/></span>
                <TransportationIcons viewBox="0 0 85 80" width="100%" preserveAspectRatio="xMinYMax meet"/>
            </div>
            <h2 className="hero__subtitle hero__title--container">
                <Translation translationKey="home_page.book_your_ticket"/> &amp;&nbsp;
                <Translation translationKey="home_page.enjoy_your_ride"/>
            </h2>
        </>
    );
}

export default HeroVectorLarge;