import Notification from "@Features/notification/components/Notification";
import {ReactNode} from "react";
import Column from "@Containers/components/Column";

type NotificationType = "red" | "green" | "orange" | "blue"

interface TicketNotificationProps {
    title: string | ReactNode,
    description: string | ReactNode,
    className?: string,
    notificationIcon: ReactNode,
    notificationType: NotificationType,
    showDivider?: boolean,
}

function MyProfileNotification(props: TicketNotificationProps) {
    const {
        title,
        description,
        className = "",
        notificationIcon,
        notificationType,
        showDivider = true,
    } = props

    return (
        <>
            {showDivider && (
                <hr/>
            )}
            <Notification
                isRow
                isBorder
                notificationType={notificationType}
                notificationIcon={notificationIcon}
                additionalClass={className}
            >
                <Column className="details-notification">
                    <div className="gb--emphasize-default">{title}</div>
                    <div>{description}</div>
                </Column>
            </Notification>
        </>
    );
}

export default MyProfileNotification;