import {useChangeCurrency} from "@Currency/hooks/useChangeCurrency";
import loadCurrency from "../functions/loadCurrency";
import useLocale from "@Hooks/selector/useLocale";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import CurrencyType from "@Currency/types/Currency.type";

export default function useActiveCurrency() {
    const setCurrency = useChangeCurrency()
    const locale = useLocale()
    const {supportedCurrencies} = useAppSelector((state) => state.page)

    const activeCurrency = loadCurrency({
        locale,
        supportedCurrencies,
    })

    const getActiveCurrency = (currencyRates: CurrencyType[]) => {
        return currencyRates.find((currency) => currency.val === activeCurrency.val)
    }

    const setActiveCurrency = (currencyRates: CurrencyType[]) => {
        setCurrency(getActiveCurrency(currencyRates))
    }

    return {
        activeCurrency,
        getActiveCurrency,
        setActiveCurrency,
    }
}