import {SearchResponseType} from "@SearchResults/types/SearchResponse.type"
import getSearchResultsCount from "@SearchResults/functions/getSearchResultsCount"
import useIsCompanyStore from "@Hooks/selector/useIsCompanyStore"

export default function hasNoResults(searchQuery: SearchResponseType, isCompanyStore: boolean) {
    const hasResolvedSuggestions = () => {
        if (isCompanyStore) {
            return true
        }
        return searchQuery?.data?.result?.resolvedSuggestionsCount === searchQuery.data.result.totalSuggestionsCount
    }

    return !searchQuery.isLoading
        && getSearchResultsCount(searchQuery.data, true) === 0
        && hasResolvedSuggestions()
        && searchQuery.data.result.resolvedApiCount === searchQuery.data.result.totalApiCount
}