import ConnectingRouteNotification from "@RouteSuggestions/components/ConnectingRouteNotification";
import {calculateTripDuration} from "@Route/functions/calculateTripDuration";
import React from "react";
import TicketRouteType from "@MyTicket/types/TicketRoute.type";
import TicketTravelDetails from "@MyTicket/features/ticketDetails/components/TicketTravelDetails";

interface TicketIntermodalTravelDetailsProps {
    id: string,
    hasTransit: boolean,
    currentRoute: TicketRouteType,
    nextRoute: TicketRouteType,
}

function TicketIntermodalTravelDetails(props: TicketIntermodalTravelDetailsProps) {
    const {
        id,
        currentRoute,
        hasTransit,
        nextRoute,
    } = props

    return (
        <>
            <TicketTravelDetails
                id={id}
                route={currentRoute}
                isIntermodal
            />
            {hasTransit && (
                <ConnectingRouteNotification
                    waitingTimeDuration={calculateTripDuration(currentRoute.destinationStation.arrivalDateTime, nextRoute.departureStation.departureDateTime)}
                    isIntermodal
                    activeHub={currentRoute.destinationStation.name}
                    departureTransportType={currentRoute.transportTypeId}
                    destinationTransportType={nextRoute.transportTypeId}
                />
            )}
        </>
    )
}

export default TicketIntermodalTravelDetails