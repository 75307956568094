import isAfter from "date-fns/isAfter";
import getRouteDateTime from "@Route/functions/getRouteDateTime";
import ParamsType from "@SearchResults/types/params/Params.type";
import RouteType from "@Route/types/Route.type";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";

/**
 * Return only routes that are possible to buy if the date is same (based on departure arrival)
 * @return {array} returnRoutes
 */

interface UseFilterOnlyPossibleReturnRoutesAttributes {
    returnRoutes: RouteType[],
    params: ParamsType,
    singleSelectedRoute: RouteWithAddedPropsAndPricesType
}

export default function useFilterOnlyPossibleReturnRoutes({returnRoutes, params, singleSelectedRoute}: UseFilterOnlyPossibleReturnRoutesAttributes) {
    const {date, returnDate} = params

    if (date === returnDate && singleSelectedRoute) {
        return returnRoutes.filter((route) => {
            const routeDeparture = getRouteDateTime({route, type: 'departure'})
            const singleSelectedArrival = getRouteDateTime({route: singleSelectedRoute, type: 'arrival'})
            if (isAfter(new Date(routeDeparture), new Date(singleSelectedArrival))) {
                return route
            }
        })
    }

    return returnRoutes
}