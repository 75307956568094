import React from 'react';
import {useTranslation} from "@Translation/hooks/useTranslation";
import PartnersLogo from "./PartnersLogo";
import promotedCompaniesData from "../data/promotedCompaniesData.json"
import useIsFerry from "@GetByFerry/hooks/useIsFerry";
import {useAppSelector} from "@Hooks/selector/useAppSelector";

function PartnersSection() {
    const {companiesCount} = useAppSelector((state) => state?.page?.homepageData)
    const isFerry = useIsFerry()
    const teamedUp = useTranslation("home_page.teamed_up", {companiesCount})

    // selected companies to display in homepage partners section
    const promotedCompanies = Object.values(promotedCompaniesData)
    const ferryCompanies = [
        {
            letterCode: "JADL",
        },
        {
            letterCode: "KSC",
        },
    ]
    const busCompanies = promotedCompanies.filter(
        (promotedCompany) => (
            !ferryCompanies.find((ferryCompany) => (
                ferryCompany.letterCode === promotedCompany.letterCode
            ))
        )
    )

    // get random (unique) companies
    const companiesToDisplay = [];
    const getRandomCompany = (companies) => {
        if (companiesToDisplay.length === 0) {
            for (let i = 0; i < companies.length; i++) companiesToDisplay.push(i)
        }

        const getRandomValueIndex = Math.floor(
            Math.random() * companiesToDisplay.length
        )
        const itemIndex = companiesToDisplay[getRandomValueIndex]

        companiesToDisplay.splice(getRandomValueIndex, 1)

        return companies[itemIndex]
    }

    const companies = isFerry ? ferryCompanies : busCompanies

    const companiesList = companies
        .slice(0, 4)
        .map((company, key) => (
            <PartnersLogo
                key={key}
                companyLetterCode={getRandomCompany(companies).letterCode.toLowerCase()}
            />
        ))

    return (
        <section className="partners-promo">
            <div className="gb-container">
                <h3>{teamedUp}</h3>
                <br/>
                <div className="gb--cards gb--cards-company-logos">
                    {companiesList}
                </div>
            </div>
        </section>
    );
}

export default PartnersSection;