import React from 'react';
import ErrorBoundary from "@Errors/components/ErrorBoundary"
import LogoGetByFerry from "@Generic/Logotypes/LogoGetByFerry";
import useSharedImagesPath from "@Images/hooks/useSharedImagesPath";
import GetByBanner from "@Generic/Banners/GetByBanner";

interface GetByFerryBannerProps {
    href: string,
    text: string,
    ctaText: string,
}

function GetByFerryBanner({href, text, ctaText}: GetByFerryBannerProps) {
    const imagePath = useSharedImagesPath(`images/banner/ferry_kkl_square.jpg`)
    const onClick = () => {
        window.dataLayer.push({
            event: 'trackEvent',
            eventCategory: 'Banners',
            eventAction: 'GetbyferryBanner click',
            eventLabel: window.location.href,
        });
    }

    return (
        <ErrorBoundary shouldReload={false}>
            <GetByBanner
                displayCondition
                href={href}
                onClick={onClick}
                className="banner_ferry"
                layoutPosition="left"
                logo={<LogoGetByFerry width="125"/>}
                imagePath={imagePath}
                imageSize="fill"
                heading={false}
                description={text}
                price={null}
                serviceList={null}
                routeConnection={false}
                ctaText={ctaText}
                includeRel={false}
            />
        </ErrorBoundary>
    );
}

export default GetByFerryBanner;