import React, {ReactNode} from 'react';
import Column from "@Containers/components/Column";

interface MobileContentContainerProps {
    title?: string,
    isIntermodal?: boolean,
    children: ReactNode,
    scrollRef?: any,
}

const MobileContentContainer = ({title, children, scrollRef, isIntermodal}: MobileContentContainerProps) => (
    <div className="mobile-content-container" ref={scrollRef}>
        <Column className={`mobile-content-section${isIntermodal ? ' mobile-content-section-intermodal' : ''}`}>
            {title && (
                <h3 className="mobile-content-title">
                    {title}
                </h3>
            )}
            {children}
        </Column>
    </div>
);

export default MobileContentContainer;