import MyProfileNotification from "@MyProfile/components/MyProfileNotification";
import formatDate from "@DateTime/functions/formatDate";
import IconTimeElapse from "@Icons/IconTimeElapse";
import {useTranslation} from "@Translation/hooks/useTranslation";
import dateIntervalFormatter from "@MyProfile/functions/dateIntervalFormatter";
import IconPart from "@Icons/IconPart";
import IconInfoCircle from "@Icons/FilledOutline/IconInfoCircle";
import IconExclusion from "@Icons/IconExclusion";
import useIsMobile from "@Hooks/selector/useIsMobile";
import VoucherType from "@MyVoucher/types/Voucher.type";
import VoucherRestrictions from "@MyVoucher/features/voucherDetails/components/VoucherRestrictions";

interface VoucherNotificationSectionProps {
    voucher: VoucherType,
}

function VoucherNotificationSection({voucher}: VoucherNotificationSectionProps) {
    const {
        expiresAt,
        createdAt,
        useCountLimit,
        buyerName,
        buyerEmail,
        type,
        cancelledTicket,
        restrictions,
    } = voucher

    const {
        valid_time: validTimeLabel,
        valid_one_time: validOneTimeLabel,
        voucher_not_transferable: voucherNotTransferableLabel,
        voucher_usable_for: voucherUsableForLabel,
        voucher_not_usable_for: voucherNotUsableForLabel,
        trips_operated_by: tripsOperatedByLabel,
        expiration: expirationLabel,
    } = useTranslation('my_profile.my_vouchers')

    const validMoreThanOneTimeLabel = useTranslation(
        'my_profile.my_vouchers.valid_more_than_one_time',
        {countLimit: useCountLimit === undefined ? "unlimited number of" : useCountLimit}
    )

    const validityPeriodLabel = useTranslation(
        'my_profile.my_vouchers.validity_period',
        {
            period: dateIntervalFormatter({
                startDate: createdAt,
                endDate: expiresAt,
            }),
        }
    )

    const voucherOwnerLabel = useTranslation(
        'my_profile.my_vouchers.voucher_owner',
        {buyerName, buyerEmail}
    )

    const numberOfUsagesMessage = () => {
        if (useCountLimit === 1) {
            return validOneTimeLabel
        }
        return validMoreThanOneTimeLabel
    }

    const isMobile = useIsMobile()

    return (
        <>
            {type === "promo" ? (
                <MyProfileNotification
                    title={validityPeriodLabel}
                    description={numberOfUsagesMessage()}
                    notificationIcon={!isMobile ? <IconTimeElapse/> : null}
                    notificationType="red"
                    showDivider={false}
                />
            ) : (
                <MyProfileNotification
                    title={`${expirationLabel} ${formatDate(expiresAt, "MMMM, dd, yyyy.")}`}
                    description={validTimeLabel}
                    notificationIcon={!isMobile ? <IconTimeElapse/> : null}
                    notificationType="red"
                    showDivider={false}
                />
            )}
            {type === "promo" && (
                <MyProfileNotification
                    title={voucherOwnerLabel}
                    description={voucherNotTransferableLabel}
                    notificationIcon={!isMobile ? <IconInfoCircle/> : null}
                    notificationType="blue"
                    // showDivider={!isMobile}
                />
            )}
            {type === "promo" && Object.keys(restrictions).some((key) => (restrictions[key].length !== 0)) && (
                <MyProfileNotification
                    title={voucherUsableForLabel}
                    description={<VoucherRestrictions limitations={restrictions}/>}
                    notificationIcon={!isMobile ? <IconInfoCircle/> : null}
                    notificationType="blue"
                    // showDivider={!isMobile}
                />
            )}
            {restrictions.unusableForCompanies.length !== 0 && (
                <MyProfileNotification
                    title={voucherNotUsableForLabel}
                    description={`${tripsOperatedByLabel} ${restrictions.unusableForCompanies.join(", ")}`}
                    notificationIcon={!isMobile ? <IconExclusion/> : null}
                    notificationType="blue"
                    // showDivider={!isMobile}
                />
            )}
            {type === "leftover" && (
                <MyProfileNotification
                    title={`This voucher was issued after rebook voucher ${cancelledTicket} wasn't used in its full amount.`}
                    description="Any leftover voucher is valid until the expiry date of the rebook voucher it was initially created from."
                    notificationIcon={!isMobile ? <IconPart/> : null}
                    notificationType="blue"
                    // showDivider={!isMobile}
                />
            )}
            {!isMobile && (
                <hr/>
            )}
        </>
    );
}

export default VoucherNotificationSection