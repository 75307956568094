import React from 'react'
import SourcedImage from "@Images/components/SourcedImage";

const MobileAppQR = ({className}: {className?: string}) => (
    <div className={`app-download-qr ${className}`}>
        <SourcedImage
            filePath="images/mobile-app-landing-page/GetByBus_homepage_URL_mobile_app"
            fileType="png"
        />
    </div>
)

export default MobileAppQR