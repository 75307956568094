import React from "react";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import useSearchResultsCount from "@SearchResults/hooks/useSearchResultsCount";
import {useTranslation} from "@Translation/hooks/useTranslation";
import formatDate from "@DateTime/functions/formatDate";
import useQuantityAwareTranslation from "@Translation/hooks/useQuantityAwareTranslation";
import useDisplayEditButton from "@SearchResults/hooks/useDisplayEditButton";

function ActiveResultsCountHeading({date}: {date: string}) {
    const {
        filteredRoutesCount,
    } = useAppSelector((state) => state.filter)
    const totalRoutesCount = useSearchResultsCount()
    const {passengers: {passengers}} = useAppSelector((state) => state.form)
    const passengersLabel = useQuantityAwareTranslation('sys.passenger', passengers)
    const displayEditButton = useDisplayEditButton()

    const {
        active: activeLabel,
        results: resultsLabel,
    } = useTranslation("sys")

    const filteredCountLabel = useTranslation("sts_page.showing", {
        count: filteredRoutesCount,
        total: totalRoutesCount,
    })

    const dateLabel = formatDate(date, "E MMM dd", true)
    const routesFiltered = totalRoutesCount !== filteredRoutesCount
    const label = routesFiltered
        ? filteredCountLabel
        : `${filteredRoutesCount} ${resultsLabel}`

    return (
        <small data-notification={label} className="filtered-routes-count">
            {`${dateLabel}, ${passengers} ${passengersLabel}`}
            {displayEditButton ? ` | ${label}` : ""}
        </small>
    );
}

export default ActiveResultsCountHeading;