import formatDuration from "date-fns/formatDuration";
import setLocale from "./setLocale";

/**
 * Returns duration in hours & minutes string
 */
export const getFormattedDuration = (duration: Duration) => (
    formatDuration(duration, {
        locale: setLocale(),
        format: ['hours', 'minutes'],
    })
)
