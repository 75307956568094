import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

interface IconListWithArrowProps extends IconType {
    chevronOrientation?: 'up' | 'right' | 'down' | 'left',
}

function IconListWithArrow(props: IconListWithArrowProps) {
    const {
        width = '50',
        height = '25',
        fill = '#070c63',
        viewBox = '0 0 50 25',
        title = 'IconListWithArrow',
        chevronOrientation = 'down',
        ...rest
    } = props

    const orientation = {
        [`${chevronOrientation === "up"}`]: "-1",
        [`${chevronOrientation === "down"}`]: "1",
    }

    const style = {verticalAlign: "middle", transform: `scaleY(${orientation.true})`};

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            style={style}
            {...rest}
            svgIconPath={(
                <g>
                    <rect
                        fill="#fff"
                        height="24"
                        rx="4"
                        stroke={fill}
                        strokeOpacity=".15"
                        width="49"
                        x=".5"
                        y=".5"
                    />
                    <g>
                        <path d="m30 0h16c2.209139-0 4 1.790861 4 4v17c0 2.209139-1.790861 4-4 4h-16z" fill={fill}/>
                        <path
                            d="m37.499165 10.1261728-.0301613.0233922-2.3148148 2.0126876c-.1035566.0813864-.1541889.2014514-.1541889.3341567 0 .1105878.0351613.2123976.1138476.2961947l2.3550135 2.0505256c.1280201.1016718.279917.1416935.4478056.1416935.1678885 0 .3197855-.0400217.4355025-.1314888.2260556-.1786828.2643034-.4205428.0723823-.6228116l-.0555416-.0514347-1.3823432-1.2072453 7.4114814.0002928c.3588709 0 .6018519-.1920612.6018519-.4757262 0-.2409011-.1777663-.4282519-.4795499-.4680088l-.122302-.0080101h-7.4114814l1.378166-1.2029502c.2477158-.1952946.2477158-.4721423-.0126635-.6779559-.2260557-.17868277-.5320382-.20891527-.7879331-.0572136z"
                            fill="#fff"
                            transform="matrix(0 -1 -1 -0 52.481 52.481)"
                        />
                    </g>
                    <g fill={fill}>
                        <g transform="translate(6 11.9879)">
                            <path
                                d="m18.389002 0h-13.77800404c-.33604888 0-.61099796.20454546-.61099796.45454546v1.09090909c0 .25.27494908.45454545.61099796.45454545h13.77800404c.3360489 0 .610998-.20454545.610998-.45454545v-1.09090909c0-.25-.2749491-.45454546-.610998-.45454546z"
                            />
                            <path
                                d="m1.54545455 0h-1.09090909c-.25 0-.45454546.20454546-.45454546.45454546v1.09090909c0 .25.20454546.45454545.45454546.45454545h1.09090909c.25 0 .45454545-.20454545.45454545-.45454545v-1.09090909c0-.25-.20454545-.45454546-.45454545-.45454546z"
                            />
                        </g>
                        <g transform="translate(6 7.9879)">
                            <path
                                d="m18.389002 0h-13.77800404c-.33604888 0-.61099796.20454546-.61099796.45454546v1.09090909c0 .25.27494908.45454545.61099796.45454545h13.77800404c.3360489 0 .610998-.20454545.610998-.45454545v-1.09090909c0-.25-.2749491-.45454546-.610998-.45454546z"
                            />
                            <path
                                d="m1.54545455 0h-1.09090909c-.25 0-.45454546.20454546-.45454546.45454546v1.09090909c0 .25.20454546.45454545.45454546.45454545h1.09090909c.25 0 .45454545-.20454545.45454545-.45454545v-1.09090909c0-.25-.20454545-.45454546-.45454545-.45454546z"
                            />
                        </g>
                        <g transform="translate(6 15.9879)">
                            <path
                                d="m18.389002 0h-13.77800404c-.33604888 0-.61099796.20454546-.61099796.45454546v1.09090909c0 .25.27494908.45454545.61099796.45454545h13.77800404c.3360489 0 .610998-.20454545.610998-.45454545v-1.09090909c0-.25-.2749491-.45454546-.610998-.45454546z"
                            />
                            <path
                                d="m1.54545455 0h-1.09090909c-.25 0-.45454546.20454546-.45454546.45454546v1.09090909c0 .25.20454546.45454545.45454546.45454545h1.09090909c.25 0 .45454545-.20454545.45454545-.45454545v-1.09090909c0-.25-.20454545-.45454546-.45454545-.45454546z"
                            />
                        </g>
                    </g>
                </g>
            )}
        />
    );
}

export default IconListWithArrow;
