import IconUserFull from "@Icons/IconUserFull";
import React from "react";
import useIsMobile from "@Hooks/selector/useIsMobile";
import Row from "@Containers/components/Row";
import useQuantityAwareTranslation from "@Translation/hooks/useQuantityAwareTranslation";

interface PassengersNumberProps {
    numberOfPassengers: number,
}

function PassengersNumber({numberOfPassengers}: PassengersNumberProps) {
    const passengers = useQuantityAwareTranslation('sys.passenger', numberOfPassengers)
    const isMobile = useIsMobile()

    const size = isMobile ? "20px" : undefined

    return (
        <Row className="passengers-number">
            <IconUserFull fill="#070c63" height={size} width={size}/>
            {`${numberOfPassengers} ${!isMobile ? passengers : ""}`}
        </Row>
    );
}

export default PassengersNumber