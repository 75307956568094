import React from "react";
import AccommodationBanner from "@SearchResults/features/banners/components/AccommodationBanner";
import PassengerCountType from "@Form/types/PassengerCount.type";
import CurrencyType from "@Currency/types/Currency.type";
import {BannerPropsType} from "@SearchResults/features/banners/features/getByTransferBanner/types/BannerProps.type"
import useGbtBanner from "@SearchResults/features/banners/features/getByTransferBanner/hooks/useGbtBanner";
import {
    GetByFerryDataType
} from "@SearchResults/features/banners/features/getByTransferBanner/types/GetByFerryData.type";
import GetByTransferSearchResultsBanner
    from "@SearchResults/features/banners/features/getByTransferBanner/components/GetByTransferSearchResultsBanner";

type BottomBannerProps = BannerPropsType & {
    numberOfResults: number,
    apiRoutesLoading: boolean,
    suggestionRoutesLoading: boolean,
    isCompanyStore: boolean,
    passengers: PassengerCountType,
    currency: CurrencyType,
    getByFerryData: GetByFerryDataType,
}

function BottomBanners(props: BottomBannerProps) {
    const {
        numberOfResults,
        hasActiveRoutes,
        transfersData,
        getByFerryData,
        params,
        apiRoutesLoading,
        suggestionRoutesLoading,
        isCompanyStore,
        passengers,
        currency,
    } = props

    const {
        gbtBannerPosition,
        gbtBannerType,
        gbtShowBanner,
        gbtShowNoDirectRoutesNotification,
    } = useGbtBanner({transfersData, getByFerryData, numberOfResults})

    return (
        <>
            {(hasActiveRoutes && gbtBannerPosition === 'bottom' && gbtShowBanner) && (
                <GetByTransferSearchResultsBanner
                    transfersData={transfersData}
                    bannerType={gbtBannerType}
                    showNoResultsNotification={gbtShowNoDirectRoutesNotification}
                    snippetsAdditionalClassName="gb--cards-transfer-full"
                />
            )}

            {hasActiveRoutes && !apiRoutesLoading && !suggestionRoutesLoading && !isCompanyStore && (
                <AccommodationBanner
                    params={params}
                    passengers={passengers}
                    currency={currency}
                />
            )}
        </>
    );
}

export default BottomBanners;