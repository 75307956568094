import useIsMobile from "@Hooks/selector/useIsMobile";
import useIsTablet from "@Hooks/selector/useIsTablet";

export default function useLimitSlidesToShow() {
    const isMobile = useIsMobile()
    const isTablet = useIsTablet()

    function limitSlidesToShow() {
        if (isMobile) {
            return 3;
        }
        if (isTablet) {
            return 5;
        }
        return 7;
    }

    return limitSlidesToShow;
}