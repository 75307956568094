import React from 'react';
import IconMoonOutline from "@Icons/IconMoonOutline";
import Translation from "@Translation/components/Translation";

const OvernightTravelLabel = () => (
    <span className="gb--badge gb--badge-left gb--badge-blue">
        <IconMoonOutline/>
        <Translation translationKey="search_results.after_midnight_arrival"/>
    </span>
);

export default OvernightTravelLabel;