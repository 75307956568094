import React from 'react';
import useWindowVisibilityCheck from "@Hooks/eventListeners/useWindowVisibilityCheck";
import Translation from "@Translation/components/Translation";
import Countdown from "@Features/countdown/components/Countdown";
import IconWaitingPax from "@Icons/FilledOutline/IconWaitingPax";
import Row from "@Containers/components/Row";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import {setTicketExpired} from "@CheckOrder/reducers/checkOrderSlice";

const Timer = () => {
    const {purchaseMaxTimestamp} = useAppSelector((state) => state?.checkOrder.ticket)
    const ticketTimeout = purchaseMaxTimestamp - Math.floor(Date.now() / 1000);
    const timerVisible = useWindowVisibilityCheck()
    const dispatch = useAppDispatch()
    const onTimeout = () => dispatch(setTicketExpired(true))

    return (
        <Row justify className="check-order__purchase-time">
            <span className="gb--emphasize-default">
                <Translation translationKey="sys.time_left_to_process_credit_card"/>
            </span>
            <div className="check-order__timer">
                <IconWaitingPax height="45"/>
                {timerVisible && (
                    <Countdown
                        timeout={ticketTimeout}
                        onTimeout={onTimeout}
                    />
                )}
            </div>
        </Row>
    )
}

export default Timer