import React from 'react';
import SourcedImage from "@Images/components/SourcedImage";

interface PartnersLogoProps {
    companyName?: string,
    companyLetterCode: string

}

const PartnersLogo = ({companyName, companyLetterCode}: PartnersLogoProps) => (
    <SourcedImage
        filePath={`images/carriers/${companyLetterCode}`}
        fileType="png"
        alt={companyName || `${companyLetterCode}-logo`}
    />
);

export default PartnersLogo;