import React from 'react';
import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconMailing(props: IconType) {
    const {
        width = '30',
        height = '20',
        viewBox = '0 0 30 20',
        title = 'IconMailing',
        fill = "#4a79e2",
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            title={title}
            fill={fill}
            {...rest}
            svgIconPath={(
                <g fill="none">
                    <path
                        d="m8.46702317 18.9393939h17.82531193c1.2254902 0 2.228164-1.0026738 2.228164-2.228164v-13.81461671c0-1.2254902-1.0026738-2.22816399-2.228164-2.22816399h-17.82531193c-1.22549019 0-2.22816399 1.00267379-2.22816399 2.22816399v13.81461671c0 1.2254902 1.0026738 2.228164 2.22816399 2.228164z"
                        fill="#fff"
                    />
                    <g fill="#070c63">
                        <path
                            d="m26.2923351 19.6078431h-25.6238859c-.37878788 0-.6684492-.2896613-.6684492-.6684492 0-.3787878.28966132-.6684492.6684492-.6684492h25.6238859c.868984 0 1.5597148-.7130124 1.5597148-1.3368984v-11.14081992c0-.37878788.2896613-.6684492.6684492-.6684492s.6684492.28966132.6684492.6684492v11.14081992c0 1.426025-1.35918 2.6737968-2.8966132 2.6737968z"
                        />
                        <path
                            d="m16.6140113 10.8143849-7.99633343-3.84859558c-.39056192-.18799484-.55479382-.65700116-.36681468-1.04757063.03649497-.07582661.08494742-.14529036.14350134-.20573038.19186825-.19804858.52763768-.18904637 1.0073083.02700663l7.19094067 3.45618592c.4270555.20525592.9242594.20525592 1.3513148 0l7.1709669-3.44658589c.0166246-.00797129.0333821-.01566273.0502719-.02305528.4635375-.20288629.7892076-.20740342.9770103-.01355138.3017733.3114939.2938933.80864525-.0176006 1.11041861-.0600753.0582006-.1290633.10642948-.2043534.14286796l-7.9504161 3.84717962c-.4280805.2071467-.9272793.2076734-1.355796.0014304z"
                        />
                        <path
                            d="m6.23885918 6.23885918c-.37878788 0-.6684492-.28966132-.6684492-.6684492v-2.67379679c0-1.60427807 1.29233512-2.89661319 2.89661319-2.89661319h17.82531193c1.6042781 0 2.8966132 1.29233512 2.8966132 2.89661319 0 .37878788-.2896613.6684492-.6684492.6684492s-.6684492-.28966132-.6684492-.6684492c0-.86898396-.6907308-1.55971479-1.5597148-1.55971479h-17.82531193c-.86898395 0-1.55971479.69073083-1.55971479 1.55971479v2.67379679c0 .37878788-.28966132.6684492-.6684492.6684492z"
                        />
                        <path
                            d="m6.23885918 10.6951872h-1.55971479c-.37878788 0-.6684492-.2896614-.6684492-.6684492 0-.37878791.28966132-.66844923.6684492-.66844923h1.55971479c.37878788 0 .6684492.28966132.6684492.66844923 0 .3787878-.28966132.6684492-.6684492.6684492z"
                        />
                        <path
                            d="m8.46702317 15.1515152h-5.57040998c-.37878788 0-.6684492-.2896614-.6684492-.6684492 0-.3787879.28966132-.6684492.6684492-.6684492h5.57040998c.37878788 0 .6684492.2896613.6684492.6684492 0 .3787878-.28966132.6684492-.6684492.6684492z"
                        />
                    </g>
                </g>
            )}
        />

    );
}

export default IconMailing;