import {useState} from "react";

export default function useCheckForExistingImage(src: string): boolean {
    const [hasImage, setHasImage] = useState(false)
    try {
        const image = new Image();
        image.src = src
        image.onload = () => setHasImage(true)
        image.onerror = () => setHasImage(false)
    } catch {
        return hasImage
    }

    return hasImage
}