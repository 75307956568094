import React, {ReactNode} from 'react';

interface SidebarSectionContainerItemProps {
    children: ReactNode,
    className?: string,
}

const SidebarSectionContainerItem = ({children, className = ""}: SidebarSectionContainerItemProps) => (
    <div className={`gb--sidebar-section-content-item ${className}`}>
        {children}
    </div>
);

export default SidebarSectionContainerItem;