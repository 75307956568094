import {useEffect} from "react";

/**
 * Executes provided method when value !== comparisonValue, effect runs on each update of comparisonValue
 */
export default function useUpdateOnChange(value: any, comparisonValue: any, method: (any?) => any) {
    useEffect(() => {
        if (value !== comparisonValue) {
            method()
        }
    }, [comparisonValue])
}