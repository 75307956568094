/**
 * used for Slider generic feature for smooth transition animations
 *
 * duration of transition animation should not last the same if user moved 2, 20, 200 pixels.
 * return values can be further adjusted for better visual performance
 */
export default function matchMoveDistanceToTransitionDuration(moveDistance: number) {
    if (moveDistance < 2) {
        return 50
    }
    if (moveDistance < 50) {
        return 100
    }
    if (moveDistance < 100) {
        return moveDistance * 2
    }

    return 250
}