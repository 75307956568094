import React from 'react';
import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconHandRight(props: IconType) {
    const {
        width = '25',
        height = '25',
        viewBox = '0 0 25 25',
        title = 'IconHandRight',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none">
                    <path
                        d="m6.70666667 0c.99 0 1.64888889 1.11111111 1.64888889 1.83v.77277778c0 1.41278437-.20335581 2.8040211-.79367272 4.06435408l10.21589496-.00046519c.9205555 0 1.6666666.74611111 1.6666666 1.66666666l-.006117.14381786c-.0729042.85320892-.7884444 1.52284881-1.6605496 1.52284881l-6.1117778-.001v.001h1.1117778c.8721052 0 1.5876454.6696399 1.6605496 1.5228488l.006117.1438179-.006117.1438178c-.0729042.8532089-.7884444 1.5228488-1.6605496 1.5228488h-.5555556c.9205556 0 1.6666667.7461111 1.6666667 1.6666667l-.006117.1438179c-.0729042.8532089-.7884444 1.5228488-1.6605497 1.5228488l-.5562222-.0006667.1444845.0067837c.8058084.068854 1.4478778.7109234 1.5167318 1.5167318l.006117.1438178-.006117.1438179c-.0729042.8532089-.7884444 1.5228488-1.6605496 1.5228488h-8.35611114c-1.80388889 0-3.26555556-1.4922222-3.26555556-3.3316667v-6.6683333c0-1.10817529.52922848-2.0896254 1.34379497-2.69561835l2.79231614-3.10104832c.51444445-.57222222.87888889-1.265 1.06444445-2.01722222l.40222222-1.64111111c.07777778-.32166667.36055555-.545.68388889-.545z"
                        fill="#ffcc80"
                        stroke="#070c63"
                        transform="translate(5 2)"
                    />
                    <g fill="#f2a25a" stroke="#f5aa5e" strokeWidth=".7" transform="translate(14.444444 11)">
                        <path
                            d="m3.88888889.75h-2.22222222c-.30666667 0-.55555556.2235-.55555556.5s.24888889.5.55555556.5z"
                        />
                        <path d="m3.05555556 4h-1.6c-.2208 0-.4.2235-.4.5s.1792.5.4.5z"/>
                        <path d="m2.5 7.35h-1.6c-.2208 0-.4.2235-.4.5s.1792.5.4.5z"/>
                    </g>
                    <rect
                        fill="#7ed321"
                        height="11.222222"
                        rx="2"
                        stroke="#070c63"
                        width="4.555556"
                        x=".522222"
                        y="9.722222"
                    />
                    <circle cx="2.8" cy="18.666667" fill="#fff" r="1.111111"/>
                </g>
            )}
        />

    );
}

export default IconHandRight;
