import React from 'react';
import GroupDiscount from "@Route/components/Price/PassengerGroup/GroupDiscount";
import PriceWithCurrency from "@Route/components/Price/PriceWithCurrency";

interface PriceWithPossibleDiscountProps {
    priceWithoutDiscount: number,
    priceWithDiscount: number,
    priceClassName?: string,
}

function PriceWithPossibleDiscount(props: PriceWithPossibleDiscountProps) {
    const {
        priceWithDiscount,
        priceWithoutDiscount,
        priceClassName = "currency",
    } = props

    const hasDiscount = priceWithoutDiscount !== priceWithDiscount

    if (hasDiscount) {
        return (
            <GroupDiscount
                priceWithDiscount={priceWithDiscount}
                priceWithoutDiscount={priceWithoutDiscount}
            />
        );
    }
    return (
        <PriceWithCurrency
            linebreak={false}
            price={priceWithDiscount}
            className={priceClassName}
        />
    );
}

export default PriceWithPossibleDiscount;