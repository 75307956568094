import React, {useState} from 'react';
import LoaderButton from "@Generic/Loaders/LoaderButton";
import useLogin from "@User/hooks/useLogin";
import validateEmail from "../../functions/validateEmail";
import InputField from "../../../../components/Generic/FormElements/InputField";
import Link from "@Generic/Link";
import {useTranslation} from "@Translation/hooks/useTranslation";
import useFormFields from "../../../../hooks/state/useFormFields";
import Password from "@Generic/FormElements/Password";
import validatePassword from "../../functions/validatePassword";
import AppleLoginButton from "@User/components/AppleLoginButton";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import ErrorNotifications from "@User/components/ErrorNotifications";
import GoogleSignInButton from "@User/components/GoogleSignInButton";

function SignIn() {
    const {assetsBaseUrl, urlsObject} = useAppSelector((state) => state?.page)
    const {errors, loading} = useAppSelector((state) => state?.userProfile)
    const [fields, handleFieldChange] = useFormFields({
        email: "",
        password: "",
    })
    const [emailError, setEmailError] = useState(false)
    const [passwordError, setPasswordError] = useState(false)
    const login = useLogin(fields)
    const {
        email,
        password,
        sign_in_info: signInInfo,
        sign_in: signIn,
        forgot_password: forgotPass,
    } = useTranslation('user')

    const {
        invalid_email: invalidEmail,
        password_too_short: passwordToShort,
    } = useTranslation('log_in')

    const validateEmailOnBlur = () => setEmailError(!validateEmail(fields.email))
    const validatePasswordOnBlur = () => setPasswordError(!validatePassword(fields.password))

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!emailError && !passwordError) {
            await (await login)()
        }
    };

    return (
        <form onSubmit={(e) => handleSubmit(e)} className="sign-in__form">
            <p>{signInInfo}</p>
            <InputField
                label={email}
                id="email"
                value={fields.email}
                onChange={handleFieldChange}
                onBlur={validateEmailOnBlur}
                showError={emailError}
                errorMessage={invalidEmail}
            />

            <Password
                label={password}
                id="password"
                value={fields.password}
                onChange={handleFieldChange}
                onBlur={validatePasswordOnBlur}
                showError={passwordError}
                errorMessage={passwordToShort}
            />

            {errors?.length > 0 && (
                <ErrorNotifications errors={errors}/>
            )}

            <Link
                href={urlsObject.forgotPassword.url}
                className="forgot_password"
            >
                {forgotPass}
            </Link>

            <button name="_submit" type="submit" className="btn btn--green btn--sign">
                {loading ? (
                    <LoaderButton/>
                ) : (
                    <>{signIn}</>
                )}
            </button>

            <Link href="/login/google">
                <GoogleSignInButton/>
            </Link>
            <Link href="/login/apple">
                <AppleLoginButton/>
            </Link>
        </form>
    );
}

export default SignIn