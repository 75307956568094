/**
 *
 * @param payload {object} - key & value to add
 * @param string {string} - appended on end of key e.g. - suggestions
 * @return {object}
 */
export default function setStringAsKey(payload, string) {
    const {type, value} = payload
    const valueToInsert = `${type}${string}`

    return {[valueToInsert]: value}
}