import React from 'react';
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import IconDiamond from "@Icons/FilledOutline/IconDiamond";
import {
    setIsBuyingPremiumSupport,
    setIsBuyingSMSNotification,
    setIsBuyingTier1FlexibleCancellation
} from "@CheckOrder/reducers/checkOrderSlice";
import IconSMS from "@Icons/FilledOutline/IconSMS";
import AncillaryCard from "@CheckOrder/features/ancillaries/components/AncillaryCard";
import {useTranslation} from "@Translation/hooks/useTranslation";
import IconFlexCancellation from "@Icons/IconFlexCancellation";

const AncillariesSection = () => {
    const {isBuyingPremiumSupport, isBuyingSMSNotification, isBuyingTier1FlexibleCancellation} = useAppSelector((state) => state?.checkOrder.selectedServices);
    const {isCompanyStore} = useAppSelector((state) => state?.page.companyStore);
    const {isIntermodal} = useAppSelector((state) => state?.checkOrder.ticket)
    const {
        smsAncillaryEnabled,
        premiumSupportAncillaryEnabled,
        tier1FlexibleCancellationEnabled,
    } = useAppSelector((state) => state.page.enabledClientsideFeatures)

    const {saleBlockTimesFormatted, isEligibleForPurchase} = useAppSelector((state) => state?.checkOrder.ticket.ancillariesData.tier1FlexibleCancellation)

    const enhanceYourJourney = useTranslation("sys.enhance_your_journey");
    if (!isIntermodal && !isCompanyStore) {
        return (
            <div className="gb--card">
                <span className="gb--card-title">{enhanceYourJourney}</span>
                {premiumSupportAncillaryEnabled && (
                    <>
                        <AncillaryCard
                            ancillaryName="premiumSupport"
                            icon={<IconDiamond className="ancillary-icon" width="20" height="20"/>}
                            cardTitleKey="sys.premium_support"
                            cardTextKey="sys.skip_the_line"
                            cardTextVariables={{}}
                            ancillaryCallback={setIsBuyingPremiumSupport}
                            ancillaryValue={isBuyingPremiumSupport}
                        />
                        {smsAncillaryEnabled && (
                            <hr/>
                        )}
                    </>
                )}
                {smsAncillaryEnabled && (
                    <>
                        <AncillaryCard
                            ancillaryName="smsNotification"
                            icon={<IconSMS className="ancillary-icon" width="20" height="20"/>}
                            cardTitleKey="sys.sms_notification"
                            cardTextKey="sys.stay_in_the_loop"
                            cardTextVariables={{}}
                            ancillaryCallback={setIsBuyingSMSNotification}
                            ancillaryValue={isBuyingSMSNotification}
                        />
                        {tier1FlexibleCancellationEnabled && isEligibleForPurchase && (
                            <hr/>
                        )}
                    </>
                )}
                {tier1FlexibleCancellationEnabled && isEligibleForPurchase && (
                    <AncillaryCard
                        ancillaryName="tier1FlexibleCancellation"
                        icon={<IconFlexCancellation className="ancillary-icon" width="20" height="20"/>}
                        cardTitleKey="sys.tier1_flexible_cancellation"
                        cardTextKey="sys.cancel_or_modify"
                        cardTextVariables={{SaleBlockTime: `${saleBlockTimesFormatted.singleSaleBlockTime}h`}}
                        ancillaryCallback={setIsBuyingTier1FlexibleCancellation}
                        ancillaryValue={isBuyingTier1FlexibleCancellation}
                    />
                )}
            </div>
        );
    }
};

export default AncillariesSection
