import React, {ReactNode} from 'react'
import useIsMobile from "@Hooks/selector/useIsMobile";
import useIsSmallTablet from "@Hooks/selector/useIsSmallTablet";

interface BookingInfoProps {
    title: string,
    label: string,
    color: string,
    children: ReactNode
}

const BookingInfo = ({title, label, color, children}: BookingInfoProps) => {
    const isMobile = useIsMobile()
    const isSmallTablet = useIsSmallTablet()

    return (
        <div>
            {!(isMobile || isSmallTablet) && (
                <>
                <span className="gb--emphasize gb--emphasize-default feature-card-title">
                    {label}
                </span>
                    <div
                        className={`mobile-app-landing-page-line-divider mobile-app-landing-page-line-divider-${color}`}
                    />
                </>
            )}
            <h2>
                {title}
            </h2>
            {children}
        </div>
    )
}

export default BookingInfo