import ajaxHandler from "@Ajax/v2/ajaxHandler";
import {isNotEmpty} from "@Array/isNotEmpty";

/**
 * Sends the number of api routes to server along with departure & destination ID
 */
export default async function processGbbSearchResults(fromId: number, toId: number, singleRoutes: any[], token: string) {
    const countModel = {
        gbbRouteCount: 0,
        apiRouteCount: 0,
        directRouteCount: 0,
        routeWithChangeCount: 0,
    }

    // This destructing may seem strange but it is required
    // engine would just copy over the initialized countModel for each transport type otherwise
    const initialAcc = {
        minPrice: null,
        directRouteCount: 0,
        routeWithChangeCount: 0,
        routeCountPerTransportType: {
            1: {...countModel},
            2: {...countModel},
            3: {...countModel},
            4: {...countModel},
            5: {...countModel},
            6: {...countModel},
            7: {...countModel},
        },
    }

    const getCounts = (routes) => routes.reduce((acc, route) => {
        const {
            type: {
                routeTransferType,
            },
            status: {
                isApi,
            },
            travelInfo: {
                busChange,
            },
            prices: {
                priceWithDiscount,
            },
        } = route

        if (!acc.minPrice || (priceWithDiscount?.[1]?.[1] < acc.minPrice)) {
            acc.minPrice = priceWithDiscount?.[1]?.[1];
        }

        if (isApi) {
            acc.routeCountPerTransportType[routeTransferType].apiRouteCount += 1;
        } else {
            acc.routeCountPerTransportType[routeTransferType].gbbRouteCount += 1;
        }

        if (isNotEmpty(busChange)) {
            acc.routeCountPerTransportType[routeTransferType].routeWithChangeCount += 1;
            acc.routeWithChangeCount += 1;
        } else {
            acc.routeCountPerTransportType[routeTransferType].directRouteCount += 1;
            acc.directRouteCount += 1;
        }

        return acc
    }, {...initialAcc});

    const {
        minPrice,
        directRouteCount,
        routeWithChangeCount,
        routeCountPerTransportType,
    } = getCounts(singleRoutes)

    const params = {
        departureStationId: fromId,
        destinationStationId: toId,
        routeCount: singleRoutes.length,
        routeCountPerTransportType,
        directRouteCount,
        routeWithChangeCount,
        minPrice,
        token,
    }

    await ajaxHandler({
        url: `/ajax/process-gbb-search-results`,
        params,
        type: 'post',
    })
}