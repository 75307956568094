import React from 'react';
import Translation from "@Translation/components/Translation";
import {useTranslation} from "@Translation/hooks/useTranslation";
import IconSafety from "@Icons/IconSafety";
import IconFaq from "@Icons/IconFAQ";
import Link from "@Generic/Link";
import {useAppSelector} from "@Hooks/selector/useAppSelector";

function InfoCardsSection() {
    const {
        safetyAndConduct,
        faq,
    } = useAppSelector((state) => state?.page.urlsObject)
    const pleasantJourney = useTranslation('home_page.pleasant_and_safe_journey')

    return (
        <section className="gb-container gb--row gb--row-wrap">
            <Link href={safetyAndConduct.url} className="gb--column info-cards__item">
                <IconSafety/>
                <br/>
                <h3>
                    <Translation translationKey="home_page.safety_conduct"/>
                </h3>
                <p dangerouslySetInnerHTML={{__html: pleasantJourney}}/>
                <div className="btn btn--green">
                    <Translation translationKey="sys.read_more"/>
                </div>
            </Link>
            <Link href={faq.url} className="gb--column info-cards__item">
                <IconFaq/>
                <br/>
                <h3>
                    <Translation translationKey="home_page.questions_and_answers"/>
                </h3>
                <p>
                    <Translation translationKey="home_page.questions_and_answers_paragaph"/>
                </p>
                <div className="btn btn--green">
                    <Translation translationKey="sys.read_more"/>
                    -FAQ
                </div>
            </Link>
        </section>
    );
}

export default InfoCardsSection;