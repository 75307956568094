import React from 'react';
import LoaderButton from "@Generic/Loaders/LoaderButton";
import {useTranslation} from "@Translation/hooks/useTranslation";
import useSearchResults from "@SearchResults/hooks/useSearchResults";

function SearchButton() {
    const searchButtonLabel = useTranslation("form.search_button")
    const {isLoading} = useSearchResults()
    const buttonClass = "btn btn--green search__select--action";

    if (isLoading) {
        return (
            <span className="btn btn--green btn--inactive-green search__select--action">
                <LoaderButton/>
            </span>
        )
    }

    return (
        <button
            type="submit"
            className={buttonClass}
        >
            {searchButtonLabel}
        </button>
    )
}

export default SearchButton;