import useSearchResults from "@SearchResults/hooks/useSearchResults";
import getSearchResultsCount from "@SearchResults/functions/getSearchResultsCount";

/**
 * Checks if search results has at least N results
 */
export default function useSearchResultsCount(includeUnavailable = true) {
    const searchResults = useSearchResults()

    return getSearchResultsCount(searchResults.data, includeUnavailable)
}