import IntermodalRouteWithDetailsType from "@Intermodal/types/IntermodalRouteWithDetails.type";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";

/**
 * Sort routes by price
 * @return {function({prices: {price: *}}, {prices: {price: *}}): number}
 */

type sortRoutesFunction = (
    a: RouteWithAddedPropsAndPricesType | IntermodalRouteWithDetailsType,
    b: RouteWithAddedPropsAndPricesType | IntermodalRouteWithDetailsType
) => number

export function sortByPrice(): sortRoutesFunction {
    return function sort({prices: {price: a}}, {prices: {price: b}}) {
        let comparison = 0;

        if (a > b) {
            comparison = 1;
        } else if (a < b) {
            comparison = -1;
        }

        return comparison
    }
}