import React, {ReactNode} from 'react';
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import IconChevronDown from "@Icons/IconChevronDown";
import useIsMobile from "@Hooks/selector/useIsMobile";
import RouteInfoLabel from "@Route/components/Labels/RouteInfoLabel";

/**
 * Accepts children <br>
 * Returns accordion item on mobile and regular row with label & children on desktop
 * @constructor
 */

interface AccordionItemProps {
    cardClassName?: string,
    label: string,
    customClassName?: string,
    children: ReactNode,
}

function AccordionItem({label, children, customClassName, cardClassName = 'gb-card'}: AccordionItemProps) {
    const isMobile = useIsMobile()
    if (isMobile) {
        return (
            <div className={cardClassName}>
                <Accordion.Toggle
                    className="gb--row gb--row-middle gb--row-justify"
                    as={Card.Header}
                    eventKey={label}
                >
                    <>
                        <RouteInfoLabel textLabel={label}/>
                        <IconChevronDown/>
                    </>
                </Accordion.Toggle>
                <Accordion.Collapse
                    className="gb--accordion-body"
                    eventKey={label}
                >
                    <>
                        {children}
                    </>
                </Accordion.Collapse>
            </div>
        )
    }

    return (
        <div className={`gb--row gb--row-middle gb--route-accordion_item ${customClassName || ''}`}>
            <RouteInfoLabel textLabel={label}/>
            {children}
        </div>
    );
}

export default AccordionItem