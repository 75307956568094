import FilterButton from "@Generic/FilterButton";
import {ReactNode} from "react";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {DepartureTimeType} from "@SearchResults/features/sidebar/features/departureTimeFilters/types/DepartureTime.type";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import {setDepartureTimeFilter} from "@SearchResults/reducers/filterSlice";
import {useTranslation} from "@Translation/hooks/useTranslation";
import useDispatchFilterEvents from "@SearchResults/features/sidebar/hooks/useDispatchFilterEvent";

type DepartureTimeFilterProps = {
    suffix?: string
    departureTime: DepartureTimeType,
    icon: ReactNode
}

function DepartureTimeFilter({departureTime, icon, suffix}: DepartureTimeFilterProps) {
    const active = useAppSelector((state) => state.filter.filterDepartureTimeSlots[departureTime])
    const dispatch = useAppDispatch()
    const label = useTranslation(`search_results.filter_by.departure_time.times.${departureTime}`)

    const onClick = () => {
        dispatch((setDepartureTimeFilter({
            departureTime,
            isActive: !active,
        })))
    }

    const handleOnBlur = useDispatchFilterEvents('DepartureTimeFilter', suffix)

    return (
        <FilterButton
            label={label}
            active={active}
            onClick={onClick}
            icon={icon}
            suffix={suffix}
            onBlur={handleOnBlur}
        />
    );
}

export default DepartureTimeFilter;