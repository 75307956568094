import React from "react"

function TripleDotLoader() {
    return (
        <div className="redirect-view my-profile-loader">
            <ul className="loading">
                <li />
                <li />
                <li />
            </ul>
        </div>
    )
}

export default TripleDotLoader