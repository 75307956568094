import React, {useContext} from 'react';
import RouteItinerary from "@Route/components/Sections/RouteItinerary";
import Column from "@Containers/components/Column";
import MobileContentContainer from "@Route/features/mobile/components/MobileContentContainer";
import useAdditionalInfoScrollToTopAndCancelRequests
    from "@Route/features/mobile/hooks/useAdditionalInfoScrollToTopAndCancelRequests";
import IntermodalTitle from "@Intermodal/components/mobile/IntermodalTitle";
import {ActiveTicketContext} from "@Route/features/mobile/context/ActiveTicketContext";
import useCompany from "@Route/features/company/hooks/useCompany";
import IntermodalRouteNotification from "@Intermodal/components/IntermodalRouteNotification";
import IntermodalRouteWithDetailsType from "@Intermodal/types/IntermodalRouteWithDetails.type";
import {
    IntermodalRouteActiveTicketContextType
} from "@Route/features/mobile/types/IntermodalRouteActiveTicketContext.type";
import SeatClass from "@Route/components/SeatClass/SeatClass";

interface IntermodalRouteTravelDetailsProps {
    route: IntermodalRouteWithDetailsType,
}

function IntermodalRouteTravelDetails({route}: IntermodalRouteTravelDetailsProps) {
    useAdditionalInfoScrollToTopAndCancelRequests()
    const activeTicketSections = useContext(ActiveTicketContext) as IntermodalRouteActiveTicketContextType
    const {getCompany} = useCompany()

    return (
        <MobileContentContainer isIntermodal>
            {route.baseRoutes.map((baseRoute, index) => (
                <div
                    // @ts-ignore TODO check why this is called tooltipRef as there ain't any tooltipRefs in useScroll return
                    ref={activeTicketSections[index].refs.tooltipRef}
                    key={`${baseRoute.key}-${index}`}
                >
                    <IntermodalTitle
                        route={baseRoute}
                        index={index}
                    />
                    <Column className="gb--route-details">
                        <div className="route-expanded-details-section">
                            <RouteItinerary
                                route={baseRoute}
                                companyLetterCode={getCompany(baseRoute.companyId)?.letterCode}
                            />
                            {route.travelInfo.seatClass && (
                                <div className="gb--column mobile-content-section">
                                    <SeatClass
                                        displayImages
                                        seatClass={route.travelInfo.seatClass}
                                    />
                                </div>
                            )}
                        </div>
                        <IntermodalRouteNotification
                            route={route}
                            baseRoute={baseRoute}
                            index={index}
                        />
                    </Column>
                </div>
            ))}
        </MobileContentContainer>
    );
}

export default IntermodalRouteTravelDetails;