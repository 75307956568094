import useScrollToFormAndAnimate from "@Form/hooks/useScrollToFormAndAnimate";
import FormErrorsModel from "@Form/models/FormErrorsModel";
import useIsMobile from "@Hooks/selector/useIsMobile";
import {setFormErrors} from "../reducers/formSlice";
import {serialize} from "@Object/serialize";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";

export default function useValidateForm() {
    const {urlParams} = useAppSelector((state) => state?.form)
    const {animateForm} = useScrollToFormAndAnimate()
    const dispatch = useAppDispatch()
    const isMobile = useIsMobile()

    const checkForErrors = () => {
        const formErrors = new FormErrorsModel(urlParams)
        dispatch(setFormErrors(serialize(formErrors)))
        const noErrors = Object.values(formErrors).every((field) => field === true)

        if (!noErrors && isMobile) {
            animateForm()
        }

        return noErrors
    }

    return {
        checkForErrors,
    }
}