import useRoutePriceGenerator from "@RoutesList/hooks/useRoutePriceGenerator";
import {SearchResponseType} from "@SearchResults/types/SearchResponse.type";
import {useOutletContext} from "react-router";
import {useAppSelector} from "@Hooks/selector/useAppSelector";

export default function usePrepareSingleSelectedRoute(singleSelectedRoute) {
    const searchQuery: SearchResponseType = useOutletContext()
    const {currency} = useAppSelector((state) => state.searchResults)

    const {
        data: {
            result: {
                passengerGroups,
            },
        },
    } = searchQuery

    const {generatePrices} = useRoutePriceGenerator(passengerGroups, currency)

    return generatePrices(singleSelectedRoute)
}