import CompanyFilter
    from "@SearchResults/features/sidebar/features/companyFilters/components/CompanyFilter";
import CompanyScoreFilter from "@SearchResults/features/sidebar/features/companyFilters/components/CompanyScoreFilter";
import {SearchResultsRtkQueryResponseType} from "@SearchResults/api/types/SearchResultsRtkQueryResponse.type";

type CarrierFiltersProps = {
    searchData: SearchResultsRtkQueryResponseType
}

const CompanyFilters = ({searchData}: CarrierFiltersProps) => (
    <>
        <CompanyFilter
            searchData={searchData}
        />
        <CompanyScoreFilter/>
    </>
);

export default CompanyFilters;