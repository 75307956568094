import parseISO from "date-fns/parseISO";
import getRouteDateTime from "@Route/functions/getRouteDateTime";
import {setFilterBoundaries} from "@SearchResults/reducers/filterSlice";
import {filterBounds} from "@SearchResults/features/sidebar/constants/filterBounds";
import {calculateTravelTime} from "@Route/functions/calculateTravelTime";
import IntermodalRouteWithDetailsType from "@Intermodal/types/IntermodalRouteWithDetails.type";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";

export default function useSetFilterBoundaries(searchDate: string) {
    const date = parseISO(searchDate)
    const dispatch = useAppDispatch()
    const filterBoundsWithAddedDate = {
        ...filterBounds,
        minRouteDepartureTime: Date.parse(`${searchDate}T23:59:00`),
        maxRouteDepartureTime: Date.parse(date.toString()),
        minRouteArrivalTime: Date.parse(`${searchDate}T23:59:00`),
        maxRouteArrivalTime: Date.parse(date.toString()),
    }

    const parseDate = (date) => (Date.parse(date))

    const setMinAndMaxValues = (routes: (RouteWithAddedPropsAndPricesType | IntermodalRouteWithDetailsType)[]) => (
        routes.reduce((acc, route) => {
            if (route?.status?.status === 'open') {
                const routeTravelTime = calculateTravelTime(route)
                const departureTime = getRouteDateTime({route, type: "departure"})
                const arrivalTime = getRouteDateTime({route, type: "arrival"})
                const ceil = (rating) => Math.ceil(parseFloat(rating) * 10)
                const floor = (rating) => Math.floor(parseFloat(rating) * 10)

                const {
                    prices: {
                        price,
                    },
                    ratings: {
                        // @ts-ignore Consulted with Morten & Luka, filter by ratings is, in this state, unavailable on intermodal tickets
                        rating,
                    },
                    travelInfo: {
                        busChange,
                    },
                } = route

                if (price > acc.maxPrice) {
                    acc.maxPrice = Math.round(price)
                }

                if (price < acc.minPrice) {
                    acc.minPrice = Math.round(price)
                }

                if (ceil(rating) > acc.maxRating) {
                    acc.maxRating = ceil(rating)
                }

                if (floor(rating) < acc.minRating) {
                    acc.minRating = floor(rating)
                }

                if (routeTravelTime > acc.maxDuration) {
                    acc.maxDuration = routeTravelTime
                }

                if (routeTravelTime < acc.minDuration) {
                    acc.minDuration = routeTravelTime
                }

                if (routeTravelTime < acc.minDuration) {
                    acc.minDuration = routeTravelTime
                }

                if (busChange?.length > acc.maxChanges) {
                    acc.maxChanges = busChange.length
                }

                const parsedDepartureTime = parseDate(departureTime)
                const parsedArrivalTime = parseDate(arrivalTime)

                if (parsedDepartureTime < acc.minRouteDepartureTime) {
                    acc.minRouteDepartureTime = parsedDepartureTime
                }

                if (parsedDepartureTime > acc.maxRouteDepartureTime) {
                    acc.maxRouteDepartureTime = parsedDepartureTime
                }

                if (parsedArrivalTime < acc.minRouteArrivalTime) {
                    acc.minRouteArrivalTime = parsedArrivalTime
                }

                if (parsedArrivalTime > acc.maxRouteArrivalTime) {
                    acc.maxRouteArrivalTime = parsedArrivalTime
                }
            }

            return acc
        }, {...filterBoundsWithAddedDate}))

    const setFilterLimits = (routes: (RouteWithAddedPropsAndPricesType | IntermodalRouteWithDetailsType)[]) => {
        dispatch(setFilterBoundaries(setMinAndMaxValues(routes)))
    }

    return {
        setFilterLimits,
    }
}