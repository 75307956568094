import React from "react";
import Column from "@Containers/components/Column";

interface MyProfileCodeDisplayProps {
    code: string,
    subTitle: string,
    className?: string,
    isInfoField?: boolean,
    codeClassName?: string,
    codeDescriptionClassName?: string,
}

function MyProfileCodeDisplay(props: MyProfileCodeDisplayProps) {
    const {
        code,
        subTitle,
        className = "",
        isInfoField = true,
        codeClassName = "",
        codeDescriptionClassName = "",
    } = props
    return (
        <Column className={`${isInfoField ? "info-field-small" : ""} ${className}`}>
            <div className={`gb--emphasize-default ${codeClassName}`}>{code}</div>
            <div className={codeDescriptionClassName}>{subTitle}</div>
        </Column>
    );
}

export default MyProfileCodeDisplay;