import React, {ReactNode} from 'react';
import {useAppSelector} from "@Hooks/selector/useAppSelector";

interface StickyContainerAvoidingDivProps {
    className?: string,
    children: ReactNode,
    /** adjustment for possible margins & paddings */
    heightCorrection?: number,
    style?: object,
}

function StickyContainerAvoidingDiv(props: StickyContainerAvoidingDivProps) {
    const {
        className,
        children,
        heightCorrection = 5,
        style
    } = props

    const {activeStickyContainerHeight} = useAppSelector((state) => state?.page)
    const marginStyle = {
        marginTop: `${activeStickyContainerHeight + heightCorrection}px`,
    }

    return (
        <div className={className} style={{...style, ...marginStyle}}>
            {children}
        </div>
    );
}

export default StickyContainerAvoidingDiv;