import React from 'react';
import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "@/types/Icon.type";

interface IconExclusionProps extends IconType {
    backgroundFill?: string,
    strokeColor?: string,
}

function IconExclusion(props: IconExclusionProps) {
    const {
        width = '20',
        height = '20',
        fill = '#f5a623',
        backgroundFill = '#ffffff',
        strokeColor = '#070c63',
        viewBox = '0 0 20 20',
        title = 'IconExclusion',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none" fillRule="evenodd" transform="translate(1 1)">
                    <circle cx="9" cy="9" fill={backgroundFill} fillRule="nonzero" r="9"/>
                    <circle cx="9" cy="9" fill={fill} fillRule="nonzero" r="7.3125"/>
                    <g strokeWidth="1.125">
                        <path
                            d="m6 6 5.656854 5.656854"
                            stroke={backgroundFill}
                            strokeLinecap="round"
                            transform="matrix(.70710678 -.70710678 .70710678 .70710678 -3.656854 8.828427)"
                        />
                        <circle cx="9" cy="9" r="9" stroke={strokeColor}/>
                    </g>
                </g>
            )}
        />

    );
}

export default IconExclusion;
