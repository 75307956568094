/**
 * params similar to ClickTripEventModel
 */
export default class PartnerRedirectEventModel {
    departureDate: string;
    departureTime: string;

    from: string;
    fromCountry: string;
    fromId: string;

    to: string;
    toCountry: string;
    toId: string;

    isReturn: boolean;
    operatorId: string | number;

    pageTitle: string;
    pageUrl: string;
    routeId: string | number;
    supplierId: string | number;
    transferId: string;
    redirectUrl: string;

    constructor(route, isReturn = false) {
        const {
            name: departureName,
            countryCode: departureCountry,
            id: departureId,
        } = route.departureStation

        const {
            name: destinationName,
            countryCode: destinationCountry,
            id: destinationId,
        } = route.destinationStation

        this.departureDate = route.departureDate
        this.departureTime = route.departureTime

        this.from = isReturn ? destinationName : departureName
        this.fromCountry = isReturn ? destinationCountry : departureCountry
        this.fromId = isReturn ? destinationId : departureId

        this.to = isReturn ? departureName : destinationName
        this.toCountry = isReturn ? departureCountry : destinationCountry
        this.toId = isReturn ? departureId : destinationId

        this.isReturn = isReturn
        this.operatorId = route.companyId
        this.pageTitle = document.title
        this.pageUrl = document.URL
        this.routeId = route.id
        this.supplierId = route.companyId
        this.transferId = `${route.companyId}-${this.fromId}-${this.toId}`
        this.redirectUrl = route.redirectUrl
    }
}