import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconCountryRoute(props: IconType) {
    const {
        width = '35',
        height = '35',
        viewBox = '0 0 35 35',
        title = 'IconCountryRoute',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none" fillRule="evenodd" transform="translate(1 3)">
                    <path
                        d="m2.50994719.00209098c3.76461037 1.33193935 8.26129461 1.33193935 13.49005281 0 6.6209938 1.33193935 11.3987716 1.33193935 14.3333333 0 1.8285342 5.91846846 2.8170333 13.52859242 0 18.91347662-1.8780221 3.5899229-6.5692633 7.618067-14.0737235 12.0844324-6.82647697-3.5351116-11.40969782-7.5632557-13.74966261-12.0844324-3.50994719-6.781765-1.9512761-13.53782973 0-18.91347662z"
                        fill="#62cdfc"
                        stroke="#fff"
                    />
                    <path
                        d="m.42184285 11.5302349.7089201-4.30627538h30.38530805l.578644 4.30627538c.7037677 1.3336323.1166405 3.7954099-1.7613817 7.3853327-1.8780221 3.5899229-6.5692633 7.618067-14.0737235 12.0844324-6.82647697-3.5351116-11.40969782-7.5632557-13.74966261-12.0844324-2.33996479-4.5211767-3.03599957-6.9829542-2.08810434-7.3853327z"
                        fill="#1f9680"
                    />
                    <g fillRule="nonzero">
                        <g transform="translate(20 2)">
                            <path
                                d="m5.52631579 9.98571429v-3.08571429h-1.10526316v3.08571429c0 .25714281-.55263158.51428571-.55263158.51428571h2.21052632s-.55263158-.2571429-.55263158-.51428571z"
                                fill="#795548"
                            />
                            <path
                                d="m9.94736842 4.69736842c0-.525-.35921053-.96710526-.85657895-1.07763158-.11052631-.44210526-.46973684-.77368421-.9118421-.82894737.05526316-.13815789.11052631-.27631579.11052631-.44210526 0-.525-.44210526-.96710526-.96710526-.96710526-.16578947 0-.33157895.05526316-.46973684.11052631-.11052632-.3868421-.46973684-.66315789-.91184211-.66315789-.08289473 0-.13815789 0-.19342105.02763158-.16578947-.49736842-.63552631-.85657895-1.18815789-.85657895-.44210527 0-.82894737.22105263-1.05.58026316-.05526316-.02763158-.1381579-.02763158-.19342106-.02763158-.55263158 0-.99473684.41447368-1.07763158.93947368-.13815789-.05526315-.27631578-.11052631-.44210526-.11052631-.525 0-.96710526.44210526-.96710526.96710526 0 .05526316 0 .11052632.02763158.1381579-.49736842.08289473-.85657895.46973684-.85657895.96710526 0 .525.44210526.96710526.96710526.96710526.05526316 0 .11052632 0 .16578948-.02763158-.19342106.19342106-.30394737.44210527-.30394737.71842106 0 .05526315 0 .11052631.02763158.13815789-.49736842.08289474-.85657895.46973684-.85657895.96710526 0 .525.44210526.96710527.96710526.96710527.05526316 0 .11052632 0 .1381579-.02763158.05526316.46973684.46973684.85657894.96710526.85657894.30394737 0 .58026316-.13815789.77368421-.3868421.22105263.22105263.55263158.3868421.88421053.3868421.30394737 0 .58026316-.11052631.80131579-.30394736.11052631 0 .22105263.02763158.30394737.02763158.22105263 0 .41447368-.02763158.63552631-.05526316.24868421.19342105.55263158.33157894.88421053.33157894.46973684 0 .88421052-.24868421 1.13289473-.60789473.19342106.19342105.46973685.33157895.80131579.33157895.60789474 0 1.10526316-.49736843 1.10526316-1.10526316 0-.33157895-.16578947-.63552632-.3868421-.85657895.525-.05526316.93947368-.525.93947368-1.07763158z"
                                fill="#176c5c"
                            />
                        </g>
                        <g transform="translate(9)">
                            <path
                                d="m4.97368421 8.98714286v-2.77714286h-.99473684v2.77714286c0 .23142857-.49736842.46285714-.49736842.46285714h1.98947368s-.49736842-.23142857-.49736842-.46285714z"
                                fill="#795548"
                            />
                            <path
                                d="m8.95263158 4.22763158c0-.4725-.32328947-.87039474-.77092105-.96986842-.09947369-.39789474-.42276316-.69631579-.8206579-.74605263.04973684-.12434211.09947369-.24868421.09947369-.39789474 0-.4725-.39789474-.87039474-.87039474-.87039474-.14921053 0-.29842105.04973684-.42276316.09947369-.09947368-.3481579-.42276316-.59684211-.82065789-.59684211-.07460527 0-.12434211 0-.17407895.02486842-.14921053-.44763158-3.08368421.09947369-3.15828947.57197369-.12434211-.04973685-.24868422-.09947369-.39789474-.09947369-.4725 0-.87039474.39789474-.87039474.87039474 0 .04973684 0 .09947368.02486842.1243421-.44763158.07460527-.77092105.42276316-.77092105.87039474 0 .4725.39789474.87039474.87039474.87039474.04973684 0 .09947368 0 .14921052-.02486842-.17407894.17407894-.27355263.39789473-.27355263.64657894 0 .04973685 0 .09947369.02486842.12434211-.44763158.07460526-.77092105.42276316-.77092105.87039474 0 .4725.39789474.87039473.87039474.87039473.04973684 0 .09947368 0 .1243421-.02486842.04973684.42276316.42276316.77092106.87039474.77092106.27355263 0 .52223684-.12434211.69631579-.3481579.19894737.19894737.49736842.3481579.79578947.3481579.27355263 0 .52223684-.09947369.72118421-.27355264.09947369 0 .19894737.02486842.27355263.02486842.19894737 0 .37302632-.02486842.57197369-.04973684.22381579.17407895.49736842.29842106.79578947.29842106.42276316 0 .79578948-.22381579 1.01960527-.54710527.17407894.17407895.42276315.29842105.72118421.29842105.54710526 0 .99473684-.44763157.99473684-.99473684 0-.29842105-.14921053-.57197368-.3481579-.77092105.4725-.04973684.84552632-.4725.84552632-.96986842z"
                                fill="#037963"
                            />
                        </g>
                        <g transform="translate(22 9)">
                            <path
                                d="m4.97368421 8.98714286v-2.77714286h-.99473684v2.77714286c0 .23142857-.49736842.46285714-.49736842.46285714h1.98947368s-.49736842-.23142857-.49736842-.46285714z"
                                fill="#795548"
                            />
                            <path
                                d="m8.95263158 4.22763158c0-.4725-.32328947-.87039474-.77092105-.96986842-.09947369-.39789474-.42276316-.69631579-.8206579-.74605263.04973684-.12434211.09947369-.24868421.09947369-.39789474 0-.4725-.39789474-.87039474-.87039474-.87039474-.14921053 0-.29842105.04973684-.42276316.09947369-.09947368-.3481579-.42276316-.59684211-.82065789-.59684211-.07460527 0-.12434211 0-.17407895.02486842-.14921053-.44763158-.57197369-.77092105-1.06934211-.77092105-.39789473 0-.74605263.19894737-.945.52223684-.04973684-.02486842-.1243421-.02486842-.17407894-.02486842-.49736842 0-.89526316.37302632-.96986842.84552632-.12434211-.04973685-.24868422-.09947369-.39789474-.09947369-.4725 0-.87039474.39789474-.87039474.87039474 0 .04973684 0 .09947368.02486842.1243421-.44763158.07460527-.77092105.42276316-.77092105.87039474 0 .4725.39789474.87039474.87039474.87039474.04973684 0 .09947368 0 .14921052-.02486842-.17407894.17407894-.27355263.39789473-.27355263.64657894 0 .04973685 0 .09947369.02486842.12434211-.44763158.07460526-.77092105.42276316-.77092105.87039474 0 .4725.39789474.87039473.87039474.87039473.04973684 0 .09947368 0 .1243421-.02486842.04973684.42276316.42276316.77092106.87039474.77092106.27355263 0 .52223684-.12434211.69631579-.3481579.19894737.19894737.49736842.3481579.79578947.3481579.27355263 0 .52223684-.09947369.72118421-.27355264.09947369 0 .19894737.02486842.27355263.02486842.19894737 0 .37302632-.02486842.57197369-.04973684.22381579.17407895.49736842.29842106.79578947.29842106.42276316 0 .79578948-.22381579 1.01960527-.54710527.17407894.17407895.42276315.29842105.72118421.29842105.54710526 0 .99473684-.44763157.99473684-.99473684 0-.29842105-.14921053-.57197368-.3481579-.77092105.4725-.04973684.84552632-.4725.84552632-.96986842z"
                                fill="#037963"
                            />
                        </g>
                        <g transform="translate(6.5 5)">
                            <path
                                d="m5.52631579 9.98571429v-3.08571429h-1.10526316v3.08571429c0 .25714281-.55263158.51428571-.55263158.51428571h2.21052632s-.55263158-.2571429-.55263158-.51428571z"
                                fill="#795548"
                            />
                            <path
                                d="m9.94736842 4.69736842c0-.525-.35921053-.96710526-.85657895-1.07763158-.11052631-.44210526-.46973684-.77368421-.9118421-.82894737.05526316-.13815789.11052631-.27631579.11052631-.44210526 0-.525-.44210526-.96710526-.96710526-.96710526-.16578947 0-.33157895.05526316-.46973684.11052631-.11052632-.3868421-.46973684-.66315789-.91184211-.66315789-.08289473 0-.13815789 0-.19342105.02763158-.16578947-.49736842-.63552631-.85657895-1.18815789-.85657895-.44210527 0-.82894737.22105263-1.05.58026316-.05526316-.02763158-.1381579-.02763158-.19342106-.02763158-.55263158 0-.99473684.41447368-1.07763158.93947368-.13815789-.05526315-.27631578-.11052631-.44210526-.11052631-.525 0-.96710526.44210526-.96710526.96710526 0 .05526316 0 .11052632.02763158.1381579-.49736842.08289473-.85657895.46973684-.85657895.96710526 0 .525.44210526.96710526.96710526.96710526.05526316 0 .11052632 0 .16578948-.02763158-.19342106.19342106-.30394737.44210527-.30394737.71842106 0 .05526315 0 .11052631.02763158.13815789-.49736842.08289474-.85657895.46973684-.85657895.96710526 0 .525.44210526.96710527.96710526.96710527.05526316 0 .11052632 0 .1381579-.02763158.05526316.46973684.46973684.85657894.96710526.85657894.30394737 0 .58026316-.13815789.77368421-.3868421.22105263.22105263.55263158.3868421.88421053.3868421.30394737 0 .58026316-.11052631.80131579-.30394736.11052631 0 .22105263.02763158.30394737.02763158.22105263 0 .41447368-.02763158.63552631-.05526316.24868421.19342105.55263158.33157894.88421053.33157894.46973684 0 .88421052-.24868421 1.13289473-.60789473.19342106.19342105.46973685.33157895.80131579.33157895.60789474 0 1.10526316-.49736843 1.10526316-1.10526316 0-.33157895-.16578947-.63552632-.3868421-.85657895.525-.05526316.93947368-.525.93947368-1.07763158z"
                                fill="#055748"
                            />
                        </g>
                        <g transform="translate(21 16)">
                            <path d="m4.97368421 8.41v-2.2h-.99473684v2.2z" fill="#795548"/>
                            <path
                                d="m8.71230519 3.97894737c0-.4725-.08296308-.62171053-.53059466-.72118421-.09947369-.39789474-.42276316-.69631579-.8206579-.74605263.04973684-.12434211.09947369-.24868421.09947369-.39789474 0-.4725-.39789474-.87039474-.87039474-.87039474-.14921053 0-.29842105.04973684-.42276316.09947369-.09947368-.3481579-.42276316-.59684211-.82065789-.59684211-.07460527 0-.12434211 0-.17407895.02486842-.14921053-.44763158-.57197369-.77092105-1.06934211-.77092105-.39789473 0-.74605263.19894737-.945.52223684-.04973684-.02486842-.1243421-.02486842-.17407894-.02486842-.49736842 0-.89526316.37302632-.96986842.84552632-.12434211-.04973685-.24868422-.09947369-.39789474-.09947369-.4725 0-.87039474.39789474-.87039474.87039474 0 .04973684 0 .09947368.02486842.1243421-.44763158.07460527-.77092105.42276316-.77092105.87039474 0 .4725.39789474.87039474.87039474.87039474.04973684 0 .09947368 0 .14921052-.02486842-.17407894.17407894-.27355263.39789473-.27355263.64657894 0 .04973685 0 .09947369.02486842.12434211-.44763158.07460526-.77092105.42276316-.77092105.87039474 0 .4725.39789474.87039473.87039474.87039473.04973684 0 .09947368 0 .1243421-.02486842.04973684.42276316.42276316.77092106.87039474.77092106.27355263 0 .52223684-.12434211.69631579-.3481579.19894737.19894737.49736842.3481579.79578947.3481579.27355263 0 .52223684-.09947369.72118421-.27355264.09947369 0 .19894737.02486842.27355263.02486842.19894737 0 .37302632-.02486842.57197369-.04973684.22381579.17407895.49736842.29842106.79578947.29842106.42276316 0 2.99256835-2.73552632 2.99256835-3.23289474z"
                                fill="#055748"
                            />
                        </g>
                        <g transform="translate(2 1)">
                            <path
                                d="m3.97894737 7.18971429v-2.22171429h-.79578948v2.22171429c0 .18514285-.39789473.37028571-.39789473.37028571h1.59157895s-.39789474-.18514286-.39789474-.37028571z"
                                fill="#795548"
                            />
                            <path
                                d="m7.16210526 3.38210526c0-.378-.25863158-.69631579-.61673684-.77589473-.07957895-.31831579-.33821053-.55705264-.65652631-.59684211.03978947-.09947368.07957894-.19894737.07957894-.31831579 0-.378-.31831579-.69631579-.69631579-.69631579-.11936842 0-.23873684.03978948-.33821052.07957895-.07957895-.27852632-.33821053-.47747368-.65652632-.47747368-.05968421 0-.09947368 0-.13926316.01989473-.11936842-.35810526-.45757894-.61673684-.85547368-.61673684-.31831579 0-.59684211.1591579-.756.41778947-.03978947-.01989473-.09947369-.01989473-.13926316-.01989473-.39789474 0-.71621053.29842105-.77589474.67642105-.09947368-.03978947-.19894736-.07957895-.31831579-.07957895-.37799999 0-.69631578.31831579-.69631578.69631579 0 .03978948 0 .07957895.01989473.09947369-.35810526.05968421-.61673684.33821052-.61673684.69631579 0 .378.31831579.69631578.69631579.69631578.03978947 0 .07957895 0 .11936842-.01989473-.13926316.13926316-.2188421.31831579-.2188421.51726316 0 .03978947 0 .07957894.01989473.09947368-.35810526.05968421-.61673684.33821053-.61673684.69631579 0 .378.31831579.69631579.69631579.69631579.03978947 0 .07957895 0 .09947368-.01989474.03978948.33821053.33821053.61673684.69631579.61673684.21884211 0 .41778948-.09947368.55705263-.27852631.1591579.15915789.39789474.27852631.63663158.27852631.21884211 0 .41778948-.07957894.57694737-.2188421.07957895 0 .1591579.01989474.21884211.01989474.15915789 0 .29842105-.01989474.45757894-.03978948.17905264.13926316.39789474.23873684.63663158.23873684.33821053 0 .63663158-.17905263.81568421-.43768421.13926316.13926316.33821053.23873685.57694737.23873685.43768421 0 .79578948-.35810527.79578948-.79578948 0-.23873684-.11936842-.45757895-.27852632-.61673684.378-.03978947.67642105-.378.67642105-.77589474z"
                                fill="#055748"
                            />
                        </g>
                        <g transform="translate(0 7)">
                            <path
                                d="m4.97368421 8.98714286v-2.77714286h-.99473684v2.77714286c0 .23142857-.49736842.46285714-.49736842.46285714h1.98947368s-.49736842-.23142857-.49736842-.46285714z"
                                fill="#795548"
                            />
                            <path
                                d="m8.95263158 4.22763158c0-.4725-.32328947-.87039474-.77092105-.96986842-.09947369-.39789474-.42276316-.69631579-.8206579-.74605263.04973684-.12434211.09947369-.24868421.09947369-.39789474 0-.4725-.39789474-.87039474-.87039474-.87039474-.14921053 0-.29842105.04973684-.42276316.09947369-.09947368-.3481579-.42276316-.59684211-.82065789-.59684211-.07460527 0-.12434211 0-.17407895.02486842-.14921053-.44763158-.57197369-.77092105-1.06934211-.77092105-.39789473 0-.74605263.19894737-.945.52223684-.04973684-.02486842-.1243421-.02486842-.17407894-.02486842-.49736842 0-.89526316.37302632-.96986842.84552632-.12434211-.04973685-.24868422-.09947369-.39789474-.09947369-.4725 0-.87039474.39789474-.87039474.87039474 0 .04973684 0 .09947368.02486842.1243421-.44763158.07460527-.77092105.42276316-.77092105.87039474 0 .4725.39789474.87039474.87039474.87039474.04973684 0 .09947368 0 .14921052-.02486842-.17407894.17407894-.27355263.39789473-.27355263.64657894 0 .04973685 0 .09947369.02486842.12434211-.44763158.07460526-.77092105.42276316-.77092105.87039474 0 .4725.39789474.87039473.87039474.87039473.04973684 0 .09947368 0 .1243421-.02486842.04973684.42276316.42276316.77092106.87039474.77092106.27355263 0 .52223684-.12434211.69631579-.3481579.19894737.19894737.49736842.3481579.79578947.3481579.27355263 0 .52223684-.09947369.72118421-.27355264.09947369 0 .19894737.02486842.27355263.02486842.19894737 0 .37302632-.02486842.57197369-.04973684.22381579.17407895.49736842.29842106.79578947.29842106.42276316 0 .79578948-.22381579 1.01960527-.54710527.17407894.17407895.42276315.29842105.72118421.29842105.54710526 0 .99473684-.44763157.99473684-.99473684 0-.29842105-.14921053-.57197368-.3481579-.77092105.4725-.04973684.84552632-.4725.84552632-.96986842z"
                                fill="#037963"
                            />
                        </g>
                        <g transform="translate(1 15)">
                            <path d="m3.97894737 7.18971429v-2.22171429h-.79578948v2.22171429z" fill="#795548"/>
                            <path
                                d="m7.16210526 3.38210526c0-.378-.25863158-.69631579-.61673684-.77589473-.07957895-.31831579-.33821053-.55705264-.65652631-.59684211.03978947-.09947368.07957894-.19894737.07957894-.31831579 0-.378-.31831579-.69631579-.69631579-.69631579-.11936842 0-.23873684.03978948-.33821052.07957895-.07957895-.27852632-.33821053-.47747368-.65652632-.47747368-.05968421 0-.09947368 0-.13926316.01989473-.11936842-.35810526-.45757894-.61673684-.85547368-.61673684-.31831579 0-.59684211.1591579-.756.41778947-.03978947-.01989473-.09947369-.01989473-.13926316-.01989473-.39789474 0-.71621053.29842105-.77589474.67642105-.09947368-.03978947-.7362922 0-.85566062 0-.378 0 1.69123957 4.69515789 1.92997641 4.69515789.21884211 0 .41778948-.07957894.57694737-.2188421.07957895 0 .1591579.01989474.21884211.01989474.15915789 0 .29842105-.01989474.45757894-.03978948.17905264.13926316.39789474.23873684.63663158.23873684.33821053 0 .63663158-.17905263.81568421-.43768421.13926316.13926316.33821053.23873685.57694737.23873685.43768421 0 .79578948-.35810527.79578948-.79578948 0-.23873684-.11936842-.45757895-.27852632-.61673684.378-.03978947.67642105-.378.67642105-.77589474z"
                                fill="#055748"
                            />
                        </g>
                    </g>
                    <path
                        d="m16.2582789 7.5h3.1663494c1.0502478.84045461 1.5753717 2.22025608 1.5753717 4.1394044 0 2.8787225-1.7777486 5.5767458-1.5753717 8.7051314.1349179 2.0855904 1.3692585 3.9707451 3.7030218 5.6554642-2.7805455 2.2971643-5.090696 3.7971643-6.9304514 4.5-2.2091051-.7811768-5.2465136-2.9283549-9.11222543-6.4415342-.38998218-.9998461-.03093818-2.2378228 1.07713199-3.71393 1.66210527-2.2141608 7.83789474-5.8958953 8.86285954-8.8043977.6833098-1.93900157.4277478-3.28571428-.7666859-4.0401381z"
                        fill="#838383"
                        stroke="#795548"
                        strokeWidth=".5"
                    />
                    <path
                        d="m18.23909 7.83c.9124913.99250468 1.2596129 2.2554237 1.0413649 3.788757-.327372 2.3-4.3074352 5.7444295-5.7804549 9.645243-.9820131 2.6005424.0179869 5.679209 3 9.236"
                        stroke="#c1c1c1"
                        strokeLinecap="round"
                        strokeWidth=".7"
                    />
                    <path
                        d="m2.50994719.00209098c3.76461037 1.33193935 8.26129461 1.33193935 13.49005281 0 6.6209938 1.33193935 11.3987716 1.33193935 14.3333333 0 1.8285342 5.91846846 2.8170333 13.52859242 0 18.91347662-1.8780221 3.5899229-6.5692633 7.618067-14.0737235 12.0844324-6.82647697-3.5351116-11.40969782-7.5632557-13.74966261-12.0844324-3.50994719-6.781765-1.9512761-13.53782973 0-18.91347662z"
                        stroke="#fff"
                    />
                </g>
            )}
        />

    );
}

export default IconCountryRoute;
