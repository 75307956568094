import RouteType from "@Route/types/Route.type";
import getRouteDateTime from "@Route/functions/getRouteDateTime";

export default function filterDuplicateApiRoutes(allRoutes: RouteType[]) {
    const getDepartureAndDestinationTimeString = (route: RouteType) => (
        `${getRouteDateTime({route, type: "departure"})}-${getRouteDateTime({route, type: "arrival"})}`
    )

    // Get all departure and destination times for non-api routes
    const routeTimes = allRoutes.map((route) => {
        if (!route.status.isApi && !route.status.isApiFullIntegration) {
            return getDepartureAndDestinationTimeString(route)
        }
    }).filter((item) => item)

    return allRoutes.filter((route) => {
        if (!route.status.isApi && !route.status.isApiFullIntegration) {
            return route
        }

        if (!routeTimes.includes(getDepartureAndDestinationTimeString(route))) {
            return route
        }
    })
}