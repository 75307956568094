import React from 'react';
import {isNotEmpty} from "@Array/isNotEmpty";
import RouteTimetableType from "@Route/types/travelInfo/timetable/RouteTimetable.type";
import OperationalDays from "@SearchResults/components/Popover/StationsPopover/OperationalDays";
import OperationalIntervals from "@SearchResults/components/Popover/StationsPopover/OperationalIntervals";
import ListOfDates from "@SearchResults/components/Popover/StationsPopover/ListOfDates";

interface OperationalTimesProps {
    timetable: RouteTimetableType,
}

function OperationalTimes({timetable}: OperationalTimesProps) {
    const {
        operationalDays,
        operationalIntervals,
        nonOperationalDates,
        operationalDates,
    } = timetable
    const maxNumberOfDates = 5
    const slicedOperationalDates = operationalDates?.slice(0, maxNumberOfDates)
    const slicedNonOperationalDates = nonOperationalDates?.slice(0, maxNumberOfDates)

    return (
        <div>
            {isNotEmpty(Object.keys(operationalDays)) && (
                <OperationalDays operationalDays={operationalDays}/>
            )}
            {isNotEmpty(operationalIntervals) && (
                <OperationalIntervals operationalIntervals={operationalIntervals}/>
            )}
            {isNotEmpty(operationalDates) && (
                <ListOfDates
                    dates={slicedOperationalDates}
                    translationKey="sys.operational_days"
                />
            )}
            {isNotEmpty(nonOperationalDates) && (
                <ListOfDates
                    dates={slicedNonOperationalDates}
                    translationKey="sys.not_operational_days"
                />
            )}
        </div>
    );
}

export default OperationalTimes;