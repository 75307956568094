import React, {ChangeEvent} from "react";
import AdditionalFieldInput from "@CheckOrder/features/TicketAdditionalInfo/components/AdditionalFieldInput";
import {NumberAdditionalFieldType} from "@CheckOrder/features/TicketAdditionalInfo/types/NumberAdditionalField.type";
import {useTranslation} from "@Translation/hooks/useTranslation";
import filterLetterInput from "@Features/input/functions/filterLetterInput";
import getNumberFieldValidation from "@CheckOrder/features/TicketAdditionalInfo/functions/getNumberFieldValidation";

type NumberAdditionalFieldProps = {
    field: NumberAdditionalFieldType
    onChange: (event: ChangeEvent<HTMLInputElement>) => void,
    value: string,
}

function NumberAdditionalField({field, onChange, value}: NumberAdditionalFieldProps) {
    const {valueRequired} = useTranslation('sys.required')
    const numberTooBigLabel = useTranslation(
        "check_order.passengers_form_section.errors.number_too_big",
        {max: field.max}
    )
    const numberTooSmallLabel = useTranslation(
        "check_order.passengers_form_section.errors.number_too_small",
        {min: field.min}
    )

    const {isNumberFieldValid} = getNumberFieldValidation(field)
    const showError = !isNumberFieldValid(value)

    const getErrorMessage = () => {
        if (!value) {
            return valueRequired
        }

        if (parseInt(value, 10) > field.max) {
            return `${field.translations.title ?? field.label} ${numberTooBigLabel}`
        }

        if (parseInt(value, 10) < field.min) {
            return `${field.translations.title ?? field.label} ${numberTooSmallLabel}`
        }
    }

    return (
        <AdditionalFieldInput
            errorMessage={getErrorMessage()}
            field={field}
            onChange={onChange}
            onKeyPress={filterLetterInput}
            value={value}
            showError={showError}
            type="number"
        />
    )
}

export default NumberAdditionalField;