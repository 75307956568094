import React from 'react';
import {useTranslation} from "@Translation/hooks/useTranslation";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import {toggleTripReview} from "@CheckOrder/reducers/checkOrderSlice";
import ToggleButton from "@Generic/ToggleButton";

function TicketTripReview() {
    const checkboxTripReview = useTranslation("t_summary.gdpr_consent_checkbox_trip_review_preselected")
    const {tripReviewAccepted} = useAppSelector((state) => state.checkOrder)
    const dispatch = useAppDispatch()
    const onPress = () => {
        dispatch((toggleTripReview()))
    }

    return (
        <ToggleButton
            slim
            color="green"
            onClick={onPress}
            checked={tripReviewAccepted}
            label={checkboxTripReview}
        />
    );
}

export default TicketTripReview;