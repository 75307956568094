import React, {useState} from 'react';
import Accordion from "react-bootstrap/Accordion";
import RouteItem from "@Route/components/RouteItem";
import ParamsType from "@SearchResults/types/params/Params.type";
import RoutesListTitle from "@RoutesList/components/RoutesListTitle";
import LoadMoreRoutes from "@RoutesList/components/LoadMoreRoutes";
import usePrepareAndSortRoutes from "@RoutesList/hooks/usePrepareAndSortRoutes";
import PassengerGroupType from "@SearchResults/types/passenger/PassengerGroup.type";
import RouteIconType from "@SearchResults/types/routeIcons/RouteIcon.type";
import CompanyType from "@SearchResults/types/company/Company.type";
import RouteType from "@Route/types/Route.type";
import IntermodalRouteType from "@Intermodal/types/IntermodalRoute.type";
import {PreparedRouteType} from "@Route/types/PreparedRoute.type";
import usePassengers from "@Hooks/selector/usePassengers";
import {FeaturedRouteType} from "@SearchResults/features/featuredRoutes/types/FeaturedRoute.type";

interface RoutesListProps {
    routes: RouteType[],
    params: ParamsType,
    routesTitle?: string,
    isReturn?: boolean,
    isPastDeparture?: boolean,
    passengerGroups: Record<string, PassengerGroupType>
    routeIcons: Record<number, RouteIconType>,
    companies: Record<string, CompanyType>,
    intermodalRoutes: IntermodalRouteType[],
    singleSelectedRoute?: PreparedRouteType,
    featuredRoutes?: FeaturedRouteType[],
}

function RoutesList(props: RoutesListProps) {
    const passengers = usePassengers()
    const {
        params,
        isReturn,
        routesTitle,
        routes,
        isPastDeparture = false,
        passengerGroups,
        routeIcons,
        companies,
        intermodalRoutes,
        singleSelectedRoute,
        featuredRoutes,
    } = props;

    const {
        showLoadMoreButton,
        preparedAndSortedRoutes,
        loadMoreRoutes,
        displayLoadMore,
        excludedRouteCount,
    } = usePrepareAndSortRoutes({
        intermodalRoutes,
        companies,
        passengerGroups,
        routes,
        isCurrentRouteSingle: !isReturn,
        isPastDeparture,
        params,
        singleSelectedRoute,
        featuredRoutes,
    })

    const [activeKey, setActiveKey] = useState<string | null>(null)

    return (
        <>
            {routesTitle && (
                <RoutesListTitle
                    title={routesTitle}
                />
            )}

            <Accordion className="panel-group routes-list" onSelect={setActiveKey}>
                {preparedAndSortedRoutes.map((route) => (
                    <RouteItem
                        totalPassengers={passengers.passengers}
                        isActive={route.key === activeKey}
                        key={route.key}
                        routeIcons={routeIcons}
                        route={route}
                        params={params}
                        isReturn={isReturn}
                    />
                ))}
            </Accordion>

            {displayLoadMore() && (
                <LoadMoreRoutes
                    showLoadMore={showLoadMoreButton}
                    loadMoreRoutes={loadMoreRoutes}
                    length={excludedRouteCount}
                />
            )}
        </>
    )
}

export default RoutesList;