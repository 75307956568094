import React from 'react';
import GenericPopover from "./GenericPopover";

interface OptionalPopoverProps {
    id: string,
    condition: boolean,
    trigger: any,
    overlay: any,
}

function OptionalPopover({condition, trigger, overlay, id}: OptionalPopoverProps) {
    if (condition) {
        return (
            <GenericPopover
                trigger={trigger}
                overlay={overlay}
                id={id}
            />
        )
    }

    return trigger
}

export default OptionalPopover;