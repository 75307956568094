import React from 'react';
import IconNoInfoAvailable from "@Icons/RouteIcons/IconNoInfoAvailable";
import IconPets from "@Icons/RouteIcons/IconPets";
import IconDrinks from "@Icons/RouteIcons/IconDrinks";
import IconFood from "@Icons/RouteIcons/IconFood";
import IconHandLuggage from "@Icons/RouteIcons/IconHandLuggage";
import IconAirCondition from "@Icons/RouteIcons/IconAirCondition";
import IconWifi from "@Icons/RouteIcons/IconWifi";
import IconPowerSocket from "@Icons/RouteIcons/IconPowerSocket";
import IconBicycle from "@Icons/RouteIcons/IconBicycle";
import IconToilet from "@Icons/RouteIcons/IconToilet";
import IconCoffeeBreak from "@Icons/RouteIcons/IconCoffeeBreak";
import IconSeatBelt from "@Icons/RouteIcons/IconSeatBelt";
import IconWheelchair from "@Icons/RouteIcons/IconWheelchair";
import IconMultimedia from "@Icons/RouteIcons/IconMultimedia";
import IconSpecialInfo from "@Icons/RouteIcons/IconSpecialInfo";
import IconBorderCrossing from "@Icons/RouteIcons/IconBorderCrossing";
import IconHighway from "@Icons/RouteIcons/IconHighway";
import IconCountryRoute from "@Icons/RouteIcons/IconCountryRoute";
import IconPicturesqueView from "@Icons/RouteIcons/IconPicturesqueView";
import IconDelayInformation from "@Icons/RouteIcons/IconDelayInformation";

interface RouteIconsProps {
    tag?: string
}

function RouteIcons({tag = "info"}: RouteIconsProps) {
    const components = {
        noInfoAvailableOption: IconNoInfoAvailable,
        petsAllowed: IconPets,
        drinksAllowed: IconDrinks,
        foodAllowed: IconFood,
        handLuggage: IconHandLuggage,
        airCondition: IconAirCondition,
        wifiAvailable: IconWifi,
        powerSocket: IconPowerSocket,
        bicycle: IconBicycle,
        toilet: IconToilet,
        coffeeBreak: IconCoffeeBreak,
        seatbelts: IconSeatBelt,
        wheelchairAccess: IconWheelchair,
        multimedia: IconMultimedia,
        specialInfo: IconSpecialInfo,
        borderCrossing: IconBorderCrossing,
        highWay: IconHighway,
        countryRoute: IconCountryRoute,
        picturesqueView: IconPicturesqueView,
        delayInformation: IconDelayInformation,
    };

    const IconRouteAmenities = components[tag];

    return <IconRouteAmenities width="25" height="25" className="gb--route-icons"/>
}

export default RouteIcons;
