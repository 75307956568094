import React from 'react';
import Column from "@Containers/components/Column";
import Row from "@Containers/components/Row";
import IconSelfTransfer from "@Icons/IconSelfTransfer";
import {useTranslation} from "@Translation/hooks/useTranslation";
import IntermodalTransportTypeIcons from "@Intermodal/components/IntermodalTransportTypeIcons";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";

interface IntermodalStatusContainerProps {
    busChangeCount: number,
    baseRoutes: RouteWithAddedPropsAndPricesType[],
}

const IntermodalStatusContainer = ({busChangeCount, baseRoutes}: IntermodalStatusContainerProps) => (
    <div className="gb--route-element gb--route-ratings">
        <Column>
            <Row center className="gb--emphasize-default">
                <IconSelfTransfer/>
                <span>
                    {` ${busChangeCount} ${useTranslation("search_results.self_transfer")}`}
                </span>
            </Row>
            <IntermodalTransportTypeIcons
                routeTransferTypeArray={baseRoutes?.map((route) => (
                    {
                        id: route?.id,
                        transportTypeId: route?.type?.routeTransferType,
                    }
                ))}
            />
        </Column>
    </div>
);

export default IntermodalStatusContainer;