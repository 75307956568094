import React from 'react';
import GenericPopover from "@Popover/components/GenericPopover";
import IconStationList from "@Icons/RouteIcons/IconStationList";
import Translation from "@Translation/components/Translation";
import useIsMobile from "@Hooks/selector/useIsMobile";
import ErrorBoundary from "@Errors/components/ErrorBoundary"
import StationsPopover from "@SearchResults/components/Popover/StationsPopover/StationsPopover";
import Row from "@Containers/components/Row";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";

interface StationsListProps {
    /** Route being rendered */
    route: RouteWithAddedPropsAndPricesType,
}

function StationsList({route}: StationsListProps) {
    const isMobile = useIsMobile()
    const displayTrigger = () => (
        <Row className="gb--stations-list" style={{cursor: "pointer"}}>
            <IconStationList className="gb--route-icons"/>
            <span className="gb--emphasize gb--emphasize-info">
                <Translation translationKey="sys.click_to_see_stations"/>
            </span>
        </Row>
    )

    const displayOverlay = () => (
        <ErrorBoundary>
            <StationsPopover travelInfo={route.travelInfo}/>
        </ErrorBoundary>
    )

    if (!isMobile) {
        return (
            <GenericPopover
                trigger={displayTrigger()}
                overlay={displayOverlay()}
                placement="bottom"
                id={`singleRouteMapToolTip${route.id}`}
            />
        );
    }

    return (
        <>
            {displayOverlay()}
        </>
    );
}

export default StationsList;