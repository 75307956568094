import React from 'react';
import Row from "@Containers/components/Row";
import PassengerGroupWithPricesType from "@SearchResults/types/passenger/PassengerGroupWithPrices.type";
import PriceWithPossibleDiscount from "@Route/components/Price/PriceWithPossibleDiscount";

interface GroupPriceProps {
    group: PassengerGroupWithPricesType,
}

function GroupPrice({group}: GroupPriceProps) {
    const {
        name,
        priceWithDiscount,
        priceWithoutDiscount,
    } = group

    return (
        <Row className="gb--row-justify">
            <div className="gb--column gb--emphasize-default">
                {name}
            </div>
            <div className="gb--column">
                <PriceWithPossibleDiscount
                    priceWithoutDiscount={priceWithoutDiscount}
                    priceWithDiscount={priceWithDiscount}
                />
            </div>
        </Row>
    );
}

export default GroupPrice;