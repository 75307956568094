import React, {useEffect, useState} from 'react';
import DatePicker from "react-datepicker";
import {useTranslation} from "@Translation/hooks/useTranslation";
import useDismissAllOverlays from "@MobileOverlays/hooks/useDismissAllOverlays";
import useSetDate from "@Form/hooks/useSetDate";
import setLocale from "@DateTime/functions/setLocale";
import useForm from "@Form/hooks/useForm";
import usePushNewUrl from "@Routing/hooks/usePushNewUrl";
import formatDate from "@DateTime/functions/formatDate";
import {useHasChanged} from "@Hooks/refs/useHasChanged";
import {getTodayServerDate} from "@DateTime/functions/getTodayServerDate";
import MobileOverlay from "./MobileOverlay";
import {useAppSelector} from "@Hooks/selector/useAppSelector";

function MobileDatePicker() {
    const [redirectDate, setRedirectDate] = useState(null)
    const urlParams = useForm('urlParams')
    const {date, returnDate} = urlParams
    const dateLocale = setLocale()

    const {
        stsCalendarActive,
        singleCalendarActive,
    } = useAppSelector((state) => state?.mobile)
    const isStationToStation = window.currentRoute === 'base_permalink' && stsCalendarActive;
    const changeSingle = useSetDate('single')
    const changeReturn = useSetDate('return')
    const redirect = usePushNewUrl()
    const dateChanged = useHasChanged(redirectDate)
    const redirectToNewDate = (newDate) => {
        const formattedDate = formatDate(newDate)
        setRedirectDate(formattedDate)
    }

    useEffect(() => () => {
        if (isStationToStation) {
            if (redirectDate && dateChanged) {
                redirect(urlParams)
            } else {
                const element = document.getElementsByClassName("station-to-station-results")[0];
                if (element) {
                    setTimeout(() => element.scrollIntoView({behavior: 'smooth'}), 50)
                }
            }
        }
    }, [dateChanged, redirect, isStationToStation, redirectDate])

    const onChange = singleCalendarActive ? changeSingle : changeReturn
    const backClick = useDismissAllOverlays()
    const dateLabel = useTranslation("form.date_select")
    const dateObject = date ? new Date(date) : null
    const returnDateObject = returnDate ? new Date(returnDate) : null
    const today = getTodayServerDate()
    const minDate = singleCalendarActive ? today : dateObject
    const dateFormat = "yyyy-MM-dd"

    return (
        <MobileOverlay title={dateLabel} backClick={backClick}>
            <DatePicker
                locale={dateLocale}
                selected={singleCalendarActive ? dateObject : returnDateObject}
                inline
                onChange={onChange}
                placeholderText={dateLabel}
                className="search-datepicker__outbound"
                calendarClassName="react-search-datepicker"
                minDate={minDate}
                dateFormat={dateFormat}
                onSelect={isStationToStation ? (date) => redirectToNewDate(date) : backClick}
                dropdownMode={null}
                onFocus={(e) => e.target.readOnly = true}
                isClearable
            />
        </MobileOverlay>
    );
}

export default MobileDatePicker
