import React from 'react';
import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconBusTab(props: IconType) {
    const {
        width = '25',
        height = '25',
        fill = '#4a79e2',
        viewBox = '0 0 25 25',
        title = 'IconBusTab',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fillRule="nonzero">
                    <g transform="translate(2.25641 17.487179)">
                        <path
                            d="m3.38461538.56410256c0-.31194871-.25215384-.56410256-.56410256-.56410256h-2.25641026c-.31194871 0-.56410256.25215385-.56410256.56410256v3.38461539c0 .31194872.25215385.56410256.56410256.56410256h2.25641026c.31194872 0 .56410256-.25215384.56410256-.56410256z"
                        />
                        <path
                            d="m15.7948718.56410256c0-.31194871-.2521539-.56410256-.5641026-.56410256h-2.2564102c-.3119487 0-.5641026.25215385-.5641026.56410256v3.38461539c0 .31194872.2521539.56410256.5641026.56410256h2.2564102c.3119487 0 .5641026-.25215384.5641026-.56410256z"
                        />
                    </g>
                    <g>
                        <path
                            d="m17.7692308 0c.7332006 0 1.3354825.55915251 1.4038022 1.2744139l.0064542.13584251-.000141 17.21330769c-.0025385.0964615-.0507693 1.0153846-.9671539 1.1118462l-.1609102.0081794h-15.79487184c-1.07179488 0-1.12538462-1.0182051-1.12806411-1.1200256l-.00014102-17.21330769c0-.7332006.55915251-1.33548257 1.2744139-1.40380224l.13584251-.00645417zm-13.53846157 14.6666667c-.77886311 0-1.41025641.6313933-1.41025641 1.4102564s.6313933 1.4102564 1.41025641 1.4102564 1.41025641-.6313933 1.41025641-1.4102564-.6313933-1.4102564-1.41025641-1.4102564zm11.84615387 0c-.7788631 0-1.4102564.6313933-1.4102564 1.4102564s.6313933 1.4102564 1.4102564 1.4102564 1.4102564-.6313933 1.4102564-1.4102564-.6313933-1.4102564-1.4102564-1.4102564zm1.974359-10.15384619h-15.79487184v6.48717949c0 .7790256.63123077 1.4102564 1.41025641 1.4102564h12.97435893c.7790257 0 1.4102565-.6312308 1.4102565-1.4102564zm-5.0769231-2.25641025h-5.0769231c-.31154525 0-.56410257.25255732-.56410257.56410256s.25255732.56410256.56410257.56410256h5.0769231c.3115452 0 .5641025-.25255732.5641025-.56410256s-.2525573-.56410256-.5641025-.56410256z"
                        />
                        <path
                            d="m2.25641026 10.4353333c0 .467077-.37851282.8461539-.84615385.8461539h-.56410256c-.46764103 0-.84615385-.3790769-.84615385-.8461539v-1.69174356c0-.46764102.37851282-.84615384.84615385-.84615384h.56410256c.46764103 0 .84615385.37851282.84615385.84615384z"
                        />
                        <path
                            d="m20.3076923 10.4353333c0 .467077-.3790769.8461539-.8461538.8461539h-.5641026c-.4670769 0-.8461538-.3790769-.8461538-.8461539v-1.69174356c0-.46764102.3790769-.84615384.8461538-.84615384h.5641026c.4670769 0 .8461538.37851282.8461538.84615384z"
                        />
                    </g>
                </g>
            )}
        />

    );
}

export default IconBusTab;
