import useSearchResults from "@SearchResults/hooks/useSearchResults";

export default function useAsyncLoadedRouteState() {
    const searchResults = useSearchResults()
    if (!searchResults.isLoading && searchResults?.data?.result) {
        const {
            totalApiCount,
            resolvedApiCount,
            totalSuggestionsCount,
            resolvedSuggestionsCount,
        } = searchResults.data.result;

        return {
            totalApiCount,
            resolvedApiCount,
            apiRoutesLoading: resolvedApiCount < totalApiCount,
            totalSuggestionsCount,
            resolvedSuggestionsCount,
            suggestionRoutesLoading: resolvedSuggestionsCount < totalSuggestionsCount,
        }
    }

    return {
        totalApiCount: 0,
        resolvedApiCount: 0,
        apiRoutesLoading: true,
        totalSuggestionsCount: 0,
        resolvedSuggestionsCount: 0,
        suggestionRoutesLoading: true,
    }
}