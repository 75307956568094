import useQuantityAwareTranslation from "@Translation/hooks/useQuantityAwareTranslation";
import getRouteTransferType from "@Route/features/transportType/functions/getRouteTransferType";
import {RouteTransferTypeKeys} from "@Route/features/transportType/types/routeTransferType.type";

export default function useQuantityAwareTransportTypeTranslation(transportTypeId: string, count: number): string {
    function createTranslationKey(transportTypeId: string): string {
        return `sys.ticket_type.${getRouteTransferType(parseInt(transportTypeId, 10) as RouteTransferTypeKeys)}_ticket`
    }

    return `${count} ${useQuantityAwareTranslation(createTranslationKey(transportTypeId), count)}`
}