import useGenerateSearchUrl from "@SearchResults/hooks/useGenerateSearchUrl";
import {RouteTransferTypeValuesType} from "@Route/features/transportType/types/routeTransferType.type";

/**
 * This hook is ONLY used for routeItem onClick in accordion on desktop,
 * use regular useNavigate for all other navigation
 */
export default function useRedirectToUrlWithRouteId() {
    const {generateSearchUrl} = useGenerateSearchUrl()

    const redirectToUrlWithRouteId = (tab: RouteTransferTypeValuesType, routeId: string) => {
        history.replaceState(
            history.state,
            "",
            generateSearchUrl({tab, routeId})
        )
    }

    return {
        redirectToUrlWithRouteId,
    }
}