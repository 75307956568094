import countryData from "@CheckOrder/data/countryData.json";
import colloquialNamesCountryData from "@CheckOrder/data/colloquialNamesCountryData.json"
import {useMemo} from "react";
import {AdditionalFieldOptionType} from "@CheckOrder/features/TicketAdditionalInfo/types/AdditionalFieldOption.type";

export default function useMatchCountryToIsoCode() {
    const {
        countryIsoCodes,
        countryIso3Codes,
        countryLabels,
        colloquialNames,
    } = useMemo(() => {
        const countryIsoCodes = {}
        const countryIso3Codes = {}
        const countryLabels = {}
        const colloquialNames = {}

        countryData.map((country) => {
            countryIsoCodes[country.isoCode] = true
            countryIso3Codes[country.iso3Code] = country.isoCode
            countryLabels[country.name] = country.isoCode
        })

        colloquialNamesCountryData.map((country) => {
            colloquialNames[country.name] = country.isoCode
        })

        return {
            countryIsoCodes,
            countryIso3Codes,
            countryLabels,
            colloquialNames,
        }
    }, [countryData, colloquialNamesCountryData])

    const matchCountryToIsoCode = (value: AdditionalFieldOptionType) => {
        /* TRY our standard 2-letter ISO code */
        // if country's id is an ISO code
        if (countryIsoCodes[value.id]) {
            return value.id
        }

        if (countryIsoCodes[value.value]) {
            return value.value
        }

        if (countryIsoCodes[value.label]) {
            return value.label
        }

        /* TRY matching by 3-letter ISO code */
        // if country's id is a 3-letter ISO code, return 2-letter ISO code
        if (countryIso3Codes[value.id]) {
            return countryIso3Codes[value.id]
        }

        if (countryIso3Codes[value.value]) {
            return countryIso3Codes[value.value]
        }

        if (countryIso3Codes[value.label]) {
            return countryIso3Codes[value.label]
        }

        /* TRY matching by label */
        // if country is matched by label, return 2-letter ISO code
        if (countryLabels[value.id]) {
            return countryLabels[value.id]
        }

        if (countryLabels[value.value]) {
            return countryLabels[value.value]
        }

        if (countryLabels[value.label]) {
            return countryLabels[value.label]
        }

        /* if nothing is matched, try using our own mapping by countries by colloquial name */
        if (colloquialNames[value.id]) {
            return colloquialNames[value.id]
        }

        if (colloquialNames[value.value]) {
            return colloquialNames[value.value]
        }

        if (colloquialNames[value.label]) {
            return colloquialNames[value.label]
        }

        // We give up, there's no saving this flag, display unknown flag
        return 'unknown'
    }

    return {
        matchCountryToIsoCode,
    }
}