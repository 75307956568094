import React from 'react';
import useIsMobile from "@Hooks/selector/useIsMobile";
import useIsSmallTablet from "@Hooks/selector/useIsSmallTablet";
import Column from "@Containers/components/Column";
import useIsTablet from "@Hooks/selector/useIsTablet";
import TravelInfoClickInterceptor from "@SearchResults/components/HeadingContent/Mobile/TravelInfoClickInterceptor";
import useIsCheckOrder from "@CheckOrder/hooks/useIsCheckOrder";
import MobileAppTitle from "./Mobile/MobileAppTitle";
import TravelInfo from "./TravelInfo";
import SortContainer from "../../features/sorting/components/SortContainer";
import MobileActiveTicketSectionButtons from "./Mobile/MobileActiveTicketSectionButtons";
import useHasSearchResults from "../../hooks/useHasSearchResults";
import useActiveParams from "../../hooks/useActiveParams";
import ParamsType from "@SearchResults/types/params/Params.type";
import useRouteMatch from "@Routing/hooks/useRouteMatch";

function TravelInfoContainer({params, isLoading}: { params: ParamsType, isLoading: boolean }) {
    const isMobile = useIsMobile()
    const isTablet = useIsTablet()
    const isSmallTablet = useIsSmallTablet()
    const additionalInfoActive = useRouteMatch('additionalInfo')
    const returnAdditionalInfoActive = useRouteMatch('returnAdditionalInfo')
    const hasResults = useHasSearchResults(2)
    const isCheckOrder = useIsCheckOrder()
    const {
        departureDate,
        departureName,
        destinationName,
    } = useActiveParams(params, isLoading)

    const getActiveSubtitleComponent = () => (
        !(additionalInfoActive || returnAdditionalInfoActive) ? (
            <SortContainer
                isMobile={isMobile}
                isSmallTablet={isSmallTablet}
            />
        ) : (
            <MobileActiveTicketSectionButtons/>
        )
    )

    return (
        <div className="gb--travel-info">
            {(isMobile || isTablet) ? (
                <MobileAppTitle
                    filtersButtonVisible={!additionalInfoActive && hasResults}
                    subtitleComponentVisible
                    subtitleComponent={getActiveSubtitleComponent()}
                >
                    {!additionalInfoActive && !isCheckOrder ? (
                        <TravelInfoClickInterceptor>
                            <TravelInfo
                                departureStationName={departureName}
                                destinationStationName={destinationName}
                                date={departureDate}
                            />
                        </TravelInfoClickInterceptor>
                    ) : (
                        <TravelInfo
                            departureStationName={departureName}
                            destinationStationName={destinationName}
                            date={departureDate}
                        />
                    )}
                </MobileAppTitle>
            ) : (
                <Column className="gb-container">
                    <TravelInfo
                        departureStationName={departureName}
                        destinationStationName={destinationName}
                        date={departureDate}
                    />
                    <SortContainer
                        isMobile={isMobile}
                        isSmallTablet={isSmallTablet}
                    />
                </Column>
            )}
        </div>
    );
}

export default TravelInfoContainer;