import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconTag(props: IconType) {
    const {
        width = '16',
        height = '16',
        fill = '#f5a623',
        viewBox = '0 0 16 16',
        title = 'IconTag',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <path
                    d="m2.46153846 0c-2.12307692.86338462-.73212498 2.35374997-1.05889421 2.97836542-.30153847.57661532-.8087981.72255766-1.40264425.09855766 0 3.84492307 1.84495194 4.3076923 1.84495194 4.3076923l6.15504806-6.15384615s-2.88369231 1.22092308-2.46153846-.61538461c-1.60861539 0-.97866345 1.32536535-1.83774037 1.46875003-1.02153846.17046153-1.23918271-1.46875003-1.23918271-2.08413465zm7.38461539 1.23076923c-.31507693 0-.630192.11996154-.870192.36057692l-7.3846157 7.3846157c-.48061538.48061477-.48061538 1.25976925 0 1.74038395l4.92307723 4.923077c.48061539.4806154 1.25976924.4806154 1.740384 0l7.38461542-7.38461542c.2406154-.24.3605772-.55511507.3605772-.870192v-3.69230769c0-1.35938461-1.1021538-2.46153846-2.4615385-2.46153846zm3.69230765 1.23076923c.68 0 1.2307693.55076923 1.2307693 1.23076923s-.5507693 1.23076923-1.2307693 1.23076923-1.2307692-.55076923-1.2307692-1.23076923.5507692-1.23076923 1.2307692-1.23076923z"
                />
            )}
        />

    );
}

export default IconTag;
