import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconUserFull(props: IconType) {
    const {
        width = '12',
        height = '12',
        fill = '#aaa',
        viewBox = '0 0 10 10',
        title = 'IconUserFull',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <path
                    d="m157.008 739.008c.36 0 .692-.09.996-.27s.546-.422.726-.726.27-.636.27-.996-.09-.696-.27-1.008-.422-.558-.726-.738-.636-.27-.996-.27-.692.09-.996.27-.546.426-.726.738-.27.648-.27 1.008.09.692.27.996.422.546.726.726.636.27.996.27zm4.008 4.008v-1.008c0-.408-.248-.776-.744-1.104-.416-.28-.96-.504-1.632-.672-.592-.144-1.136-.216-1.632-.216s-1.04.072-1.632.216c-.672.168-1.216.392-1.632.672-.496.328-.744.696-.744 1.104v1.008z"
                    transform="translate(-153 -735)"
                />
            )}
        />

    );
}

export default IconUserFull;
