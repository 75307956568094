import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconFallingStars(props: IconType) {
    const {
        width = '40',
        height = '40',
        viewBox = '0 0 40 40',
        title = 'IconFallingStars',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none" fillRule="evenodd" transform="matrix(.8660254 .5 -.5 .8660254 15.893594 .076606)">
                    <path
                        d="m6.68492356 20.0547707c-2.768986 0-5.01369267-2.2447067-5.01369267-5.0136927s2.24470667-5.0136927 5.01369267-5.0136927h-1.67123089c-2.768986 0-5.01369267-2.24470663-5.01369267-5.01369263s2.24470667-5.01369267 5.01369267-5.01369267h16.71230893c2.768986 0 5.0136926 2.24470667 5.0136926 5.01369267s-2.2447066 5.01369263-5.0136926 5.01369263h5.0136926c2.768986 0 5.0136927 2.2447067 5.0136927 5.0136927s-2.2447067 5.0136927-5.0136927 5.0136927h-4.1780772c2.768986 0 5.0136927 2.2447066 5.0136927 5.0136926s-2.2447067 5.0136927-5.0136927 5.0136927h-16.71230889c-2.768986 0-5.01369266-2.2447067-5.01369266-5.0136927s2.24470666-5.0136926 5.01369266-5.0136926z"
                        fill="#fff"
                        stroke="#4a4a4a"
                        strokeLinejoin="round"
                        strokeWidth="1.253423"
                    />
                    <g fillRule="nonzero">
                        <path
                            d="m21.7167384 8.35615445c.1986651 0 .3625639-.075852.4916962-.22755599.1291323-.151704.1787986-.32703402.1489988-.52599008l-.2681979-1.62646578 1.1249412-1.14897124c.0844327-.08953022.1390656-.1914952.1638988-.30589493.0297997-.11937364.0248331-.2375038-.0148999-.35439048-.039733-.11688669-.1055409-.21387777-.1974235-.29097324s-.1974234-.12559101-.3166225-.14548662l-1.5272381-.23128641-.6853947-1.45486618c-.0546329-.11937364-.1365822-.21139081-.2458481-.27605153-.1042991-.06466072-.2160483-.09699108-.3352473-.09699108-.1191991 0-.2309482.03233036-.3352474.09699108-.1092658.06466072-.1912152.15667789-.2458481.27605153l-.6853947 1.45486618-1.5272381.23128641c-.119199.01989561-.2247399.06839115-.3166225.14548662s-.1576904.17408655-.1974235.29097324c-.039733.11688668-.0459413.23377336-.0186248.35066005.0273164.11688668.083191.22009514.1676237.30962536l1.1249412 1.14897124-.2681979 1.62646578c-.0297998.19895606.0198665.37428608.1489988.52599008.1291324.15170399.2930311.22755599.4916962.22755599.1092658 0 .213565-.02735646.3128976-.08206937l1.3484395-.74608522 1.3484395.74608522c.0993325.05471291.2036317.08206937.3128975.08206937z"
                            fill="#f5a623"
                            transform="matrix(.90630779 .42261826 -.42261826 .90630779 3.997854 -8.005768)"
                        />
                        <circle cx="4.139211" cy="4.974827" fill="#f5a623" r="1"/>
                        <path
                            d="m10.9026362 10.047438c-.4624185 0-.8160327-.33127971-.8160327-.76449166l-.0391655-8.49840199c0-.43321195.3536142-.76449168.8160327-.76449168.4624186 0 .8160327.33127973.8160327.76449168l.0391655 8.49840199c0 .43321195-.3536142.76449166-.8160327.76449166z"
                            fill="#f5a623"
                            transform="matrix(0 1 -1 0 15.916798 -5.849308)"
                        />
                        <g transform="translate(8.356154 9.19177)">
                            <g fill="#7ed321">
                                <path
                                    d="m18.3742766 9.19176989c.1986652 0 .3625639-.07585199.4916962-.22755599.1291323-.15170399.1787986-.32703402.1489988-.52599008l-.2681979-1.62646577 1.1249413-1.14897124c.0844326-.08953023.1390655-.19149521.1638987-.30589494.0297998-.11937363.0248331-.23750379-.0148999-.35439048-.039733-.11688668-.1055408-.21387776-.1974235-.29097323-.0918826-.07709548-.1974234-.12559102-.3166225-.14548662l-1.5272381-.23128642-.6853946-1.45486618c-.054633-.11937363-.1365823-.21139081-.2458481-.27605153-.1042992-.06466072-.2160483-.09699108-.3352474-.09699108s-.2309482.03233036-.3352474.09699108c-.1092658.06466072-.1912152.1566779-.2458481.27605153l-.6853946 1.45486618-1.5272381.23128642c-.1191991.0198956-.22474.06839114-.3166226.14548662-.0918826.07709547-.1576904.17408655-.1974234.29097323-.0397331.11688669-.0459413.23377337-.0186249.35066005.0273165.11688669.083191.22009514.1676237.30962537l1.1249412 1.14897124-.2681979 1.62646577c-.0297997.19895606.0198665.37428609.1489989.52599008.1291323.151704.293031.22755599.4916961.22755599.1092658 0 .213565-.02735645.3128976-.08206937l1.3484395-.74608522 1.3484395.74608522c.0993325.05471292.2036317.08206937.3128975.08206937z"
                                    transform="matrix(.90630779 .42261826 -.42261826 .90630779 4.037838 -6.514892)"
                                />
                                <circle cx=".79675" cy="5.810442" r="1"/>
                                <path
                                    d="m7.56017442 10.8830535c-.46241854 0-.81603271-.3312798-.81603271-.7644917l-.03916548-8.49840201c0-.43321195.35361418-.76449167.81603271-.76449167.46241854 0 .81603271.33127972.81603271.76449167l.03916547 8.49840201c0 .4332119-.35361417.7644917-.8160327.7644917z"
                                    transform="matrix(0 1 -1 0 13.409953 -1.671231)"
                                />
                            </g>
                            <path
                                d="m9.60957767 2.94470381c-.40238359 0-.63715701-.25642471-.63715701-.42648125l-.03133832-3.33603177c0-.17005654.23488833-.4155642.64844562-.4155642.41355724 0 .65721264.24550766.65721264.4155642l.0313324 3.33603177c0 .17005654-.2661118.42648125-.66849533.42648125z"
                                fill="#4a79e2"
                                transform="matrix(0 1 -1 0 10.465243 -8.753913)"
                            />
                            <path
                                d="m3.76026955 2.94470381c-.40238358 0-.63715701-.25642471-.63715701-.42648125l-.03133831-3.33603177c0-.17005654.23488832-.4155642.64844561-.4155642s.65721265.24550766.65721265.4155642l.03133238 3.33603177c0 .17005654-.26611174.42648125-.66849532.42648125z"
                                fill="#4a79e2"
                                transform="matrix(0 1 -1 0 4.615935 -2.904605)"
                            />
                            <path
                                d="m9.60957767 12.9720892c-.40238359 0-.63715701-.2564248-.63715701-.4264813l-.03133832-3.33603177c0-.17005654.23488833-.4155642.64844562-.4155642.41355724 0 .65721264.24550766.65721264.4155642l.0313324 3.33603177c0 .1700565-.2661118.4264813-.66849533.4264813z"
                                fill="#f5a623"
                                transform="matrix(0 1 -1 0 20.492629 1.273473)"
                            />
                            <path
                                d="m3.76026955 12.9720892c-.40238358 0-.63715701-.2564248-.63715701-.4264813l-.03133831-3.33603177c0-.17005654.23488832-.4155642.64844561-.4155642s.65721265.24550766.65721265.4155642l.03133238 3.33603177c0 .1700565-.26611174.4264813-.66849532.4264813z"
                                fill="#f5a623"
                                transform="matrix(0 1 -1 0 14.643321 7.122781)"
                            />
                        </g>
                        <g fill="#4a79e2" transform="translate(4.178077 20.054771)">
                            <path
                                d="m18.3742766 8.35615445c.1986652 0 .3625639-.075852.4916962-.22755599.1291323-.151704.1787986-.32703402.1489988-.52599008l-.2681979-1.62646578 1.1249413-1.14897124c.0844326-.08953022.1390655-.1914952.1638987-.30589493.0297998-.11937364.0248331-.2375038-.0148999-.35439048-.039733-.11688669-.1055408-.21387777-.1974235-.29097324-.0918826-.07709547-.1974234-.12559101-.3166225-.14548662l-1.5272381-.23128641-.6853946-1.45486618c-.054633-.11937364-.1365823-.21139081-.2458481-.27605153-.1042992-.06466072-.2160483-.09699108-.3352474-.09699108s-.2309482.03233036-.3352474.09699108c-.1092658.06466072-.1912152.15667789-.2458481.27605153l-.6853946 1.45486618-1.5272381.23128641c-.1191991.01989561-.22474.06839115-.3166226.14548662s-.1576904.17408655-.1974234.29097324c-.0397331.11688668-.0459413.23377336-.0186249.35066005.0273165.11688668.083191.22009514.1676237.30962536l1.1249412 1.14897124-.2681979 1.62646578c-.0297997.19895606.0198665.37428608.1489989.52599008.1291323.15170399.293031.22755599.4916961.22755599.1092658 0 .213565-.02735646.3128976-.08206937l1.3484395-.74608522 1.3484395.74608522c.0993325.05471291.2036317.08206937.3128975.08206937z"
                                transform="matrix(.90630779 .42261826 -.42261826 .90630779 3.684691 -6.593183)"
                            />
                            <circle cx=".79675" cy="4.974827" r="1"/>
                            <path
                                d="m7.54012174 10.0273853c-.46241853 0-.81603271-.33127969-.81603271-.76449163l-.03916547-8.498402c0-.43321194.35361417-.76449167.81603271-.76449167.46241853 0 .8160327.33127973.8160327.76449167l.03916548 8.498402c0 .43321194-.35361417.76449163-.81603271.76449163z"
                                transform="matrix(0 1 -1 0 12.534232 -2.506846)"
                            />
                        </g>
                    </g>
                </g>
            )}
        />
    );
}

export default IconFallingStars;
