import filterDuplicates from "@Array/filterDuplicates";
import sortRoutes from "../../sorting/functions/sortRoutes";
import displayOnlyIdenticalReturnStations from "../functions/displayOnlyIdenticalReturnStations";
import useRoutesListPriceGenerator from "./useRoutesListPriceGenerator";
import useRouteFilters from "../../sidebar/hooks/useRouteFilters";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import useRoutesListSideEffects from "@RoutesList/hooks/useRoutesListSideEffects";
import PassengerGroupType from "@SearchResults/types/passenger/PassengerGroup.type";
import ParamsType from "@SearchResults/types/params/Params.type";
import useAddRouteProperties from "@RoutesList/hooks/useAddRouteProperties";
import CompanyType from "@SearchResults/types/company/Company.type";
import usePassengers from "@Hooks/selector/usePassengers";
import useActiveFilters from "@SearchResults/features/sidebar/hooks/useActiveFilters";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";
import RouteType from "@Route/types/Route.type";
import IntermodalRouteType from "@Intermodal/types/IntermodalRoute.type";
import usePrepareIntermodalRoutes from "@Intermodal/hooks/usePrepareIntermodalRoutes";
import {PreparedRouteType} from "@Route/types/PreparedRoute.type";
import useLoadMoreRoutes from "@RoutesList/hooks/useLoadMoreRoutes";
import {FeaturedRouteType} from "@SearchResults/features/featuredRoutes/types/FeaturedRoute.type";

/**
 * Prepares relevant route data for RoutesList component
 */
type PreparedData = {
    routes: RouteType[],
    intermodalRoutes: IntermodalRouteType[]
    params: ParamsType,
    isCurrentRouteSingle: boolean,
    isPastDeparture: boolean,
    passengerGroups: Record<string, PassengerGroupType>,
    companies: Record<string, CompanyType>,
    singleSelectedRoute: PreparedRouteType,
    featuredRoutes?: FeaturedRouteType[],
}

export default function usePrepareAndSortRoutes(preparedData: PreparedData) {
    const {currency, excludedApiPartnerIds} = useAppSelector((state) => state.searchResults)
    const {
        routes,
        params,
        isCurrentRouteSingle,
        isPastDeparture,
        passengerGroups,
        companies,
        intermodalRoutes,
        singleSelectedRoute,
    } = preparedData

    const preparedIntermodalRoutes = usePrepareIntermodalRoutes({
        passengerGroups,
        currency,
        companies,
        intermodalRoutes,
    })

    const filters = useActiveFilters()
    const passengers = usePassengers()
    const {generatePricesForRoutesList} = useRoutesListPriceGenerator(passengerGroups, currency)
    const {filterRoutes} = useRouteFilters()
    // currently inactive
    // const {filterRoutes: businessFilter} = useBusinessDecisionsRouteFilter()
    const {prepareRouteProperties} = useAddRouteProperties(
        params.isReturn,
        companies,
        preparedData?.featuredRoutes ?? []
    )

    const {
        routes: filteredRoutesByExclusion,
        showLoadMoreButton,
        excludedRouteCount,
        loadMoreRoutes,
        // @ts-ignore
    } = useLoadMoreRoutes(routes)

    const prepareRoutes = (): {preparedAndSortedRoutes: RouteWithAddedPropsAndPricesType[]} => {
        const preparedRoutes = prepareRouteProperties(filterDuplicates(filteredRoutesByExclusion, 'key'))
        const routesWithPrices = generatePricesForRoutesList(preparedRoutes)

        let preparedAndSortedRoutes = sortRoutes({
            routes: routesWithPrices,
            passengers: passengers.passengers,
            sortBy: filters.sortBy,
        })

        if (params.isReturn && !isCurrentRouteSingle) {
            preparedAndSortedRoutes = displayOnlyIdenticalReturnStations({
                routes: preparedAndSortedRoutes,
                singleSelectedRoute,
            })
        }

        const preparedOptionalIntermodalRoutes = params.isReturn ? [] : preparedIntermodalRoutes

        // Filter duplicates is required as route suggestion or api fetches may return already existing routes
        return {
            preparedAndSortedRoutes: filterRoutes([
                ...preparedAndSortedRoutes,
                ...preparedOptionalIntermodalRoutes,
            ]),
        }
    }

    const {preparedAndSortedRoutes} = prepareRoutes()

    useRoutesListSideEffects({
        preparedRoutes: preparedAndSortedRoutes,
        allRoutes: routes,
        isPastDeparture,
        searchDate: params.date,
        currency,
    })

    const displayLoadMore = () => {
        const {isReturn} = params
        if (isReturn) {
            return !(!!singleSelectedRoute && isCurrentRouteSingle);
        }
        return true
    }

    return {
        preparedAndSortedRoutes,
        showLoadMoreButton,
        loadMoreRoutes,
        displayLoadMore,
        excludedApiPartnerIds,
        excludedRouteCount,
    };
}