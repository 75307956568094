import React from 'react';
import useActiveFiltersCount from "@SearchResults/hooks/useActiveFiltersCount";
import IconFilter from "@Icons/IconFilter";
import IconType from "@/types/Icon.type";
import {RotationOrientationType} from "@Features/icons/types/RotationOrientationType";

type IconFilterWIthCountPropsType = IconType & {
    orientation?: RotationOrientationType
}

function IconFilterWithCount(props: IconFilterWIthCountPropsType) {
    const filterCount = useActiveFiltersCount()

    return (
        <span className="gb--icon gb--icon-filter">
            {filterCount > 0 && (
                <span className="gb--label gb--label-orange">
                    {filterCount}
                </span>
            )}
            <IconFilter {...props}/>
        </span>
    );
}

export default IconFilterWithCount;