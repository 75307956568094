import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setCookieState} from "@Reducers/pageSlice";
import getCookie from "../functions/getCookie";
import setCookie from "../functions/setCookie";

/**
 * Handles cookie state interaction
 * @param cookie {string}
 * @param initiallyActive {boolean} - applied if there is no cookie
 */
export default function useCookie(cookie, initiallyActive = true) {
    const isActive = useSelector((state) => state?.page?.[cookie])
    const cookieValue = getCookie(cookie, true)
    const dispatch = useDispatch()
    const setReduxState = (value) => dispatch(setCookieState({[cookie]: value}))
    const [cookieActive, setCookieActive] = useState(cookieValue ?? initiallyActive)

    const set = (value) => {
        setReduxState(value)
        setCookieActive(value)
        setCookie(cookie, value)
    }

    const toggle = () => set(!cookieActive)

    return {
        cookieActive,
        toggle,
        set,
        isActive,
    }
}