import React from 'react';
import {useParams} from "react-router";
import TravelInfoIntermodalType from "@Route/types/travelInfo/TravelInfoIntermodal.type";
import TravelInfoWithDurationType from "@Route/types/travelInfo/TravelInfoWithDuration.type";
import RouteListMustChangeBus from "@Route/components/Connection/RouteListMustChangeBus";
import BusChange from "@Route/components/Connection/BusChange";

interface RouteListBusChangeProps {
    /** Route object to render */
    travelInfo: TravelInfoWithDurationType | TravelInfoIntermodalType,
    activeHub?: string,
    isIntermodal?: boolean,
}

function RouteListBusChange({travelInfo, activeHub, isIntermodal}: RouteListBusChangeProps) {
    // @ts-ignore TODO Luka-this-is-typescript-type-issue-Do-The-Fixing
    const {hideBusChanges} = useParams()
    const {busChangeCount, busChange} = travelInfo

    if (busChange?.length > 0 && !hideBusChanges) {
        return (
            <RouteListMustChangeBus busChange={busChange}/>
        )
    }

    // Some api routes do not have station details
    // GBW-1528
    if (busChangeCount) {
        return (
            <BusChange
                isIntermodal={isIntermodal}
                busChanges={busChangeCount}
            />
        )
    }

    return (
        <span>
            &nbsp;
            {activeHub ? `VIA ${activeHub}` : 'DIRECT'}
        </span>
    );
}

export default RouteListBusChange;
