import React, {useContext} from "react";
import {useTranslation} from "@Translation/hooks/useTranslation";
import Row from "@Containers/components/Row";
import TicketsContext from "@MyTickets/context/functions/TicketsContext";
import {isExpired} from "@MyTicket/features/ticketDetails/functions/isExpired";
import useManageBookingModal from "@UserModals/features/manageBookings/hooks/useManageBookingModal";
import useRebookModal from "@UserModals/features/manageBookings/hooks/useRebookModal";
import ModalHeader from "@UserModals/components/ModalHeader";
import ManageBookingModalBody from "@UserModals/features/manageBookings/components/ManageBookingModalBody";
import ModalCloseButton from "@UserModals/components/ModalCloseButton";
import ManageBookingActionButton from "@UserModals/features/manageBookings/components/ManageBookingActionButton";

function ManageBookingModal() {
    const {setIsManageBookingModalOpen, modalData} = useContext(TicketsContext);
    const {
        ticketId,
        singleRoute: {
            companyId,
            departureDate,
            departureTime,
        },
        ancillariesData,
    } = modalData

    const closeModal = () => {
        setIsManageBookingModalOpen(false)
    }

    const isFirstLegExpired = isExpired(new Date(`${departureDate}T${departureTime}`))
    const isOneCompanyReturnTicket = companyId === modalData?.returnRoute.companyId
    const manageTicketLabel = useTranslation("my_profile.my_tickets.booking_modal.manage_ticket")

    const {
        radioButtonLabels,
        option,
        cancelTicketSelected,
    } = useManageBookingModal({modalData})

    const {
        handleTicketRebook,
        loading,
        voucher,
        resetApiState,
        successfulRebook,
        unsuccessfulRebook,
    } = useRebookModal({ticketId, setIsManageBookingModalOpen})

    return (
        <>
            <ModalHeader
                modalData={modalData}
                title={`${manageTicketLabel} `}
            />
            <ManageBookingModalBody
                loadingData={loading}
                isFirstLegExpired={isFirstLegExpired}
                isOneCompanyReturnTicket={isOneCompanyReturnTicket}
                successfulRebook={successfulRebook}
                unsuccessfulRebook={unsuccessfulRebook}
                voucher={voucher}
                radioButtonLabels={radioButtonLabels}
                option={option}
                hasPremiumSupport={ancillariesData?.premiumSupport?.isEnabled ?? false}
            />
            {!loading && (
                <Row className="button-wrapper">
                    <ModalCloseButton
                        closeModal={closeModal}
                        additionalEffect={resetApiState}
                    />
                    <ManageBookingActionButton
                        cancelTicketOptionSelected={cancelTicketSelected}
                        unsuccessfulRebook={unsuccessfulRebook}
                        successfulRebook={successfulRebook}
                        isOneCompanyReturnTicket={isOneCompanyReturnTicket}
                        isFirstLegExpired={isFirstLegExpired}
                        resetApiState={resetApiState}
                        handleTicketRebook={handleTicketRebook}
                    />
                </Row>
            )}
        </>
    );
}

export default ManageBookingModal