import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Translation from "@Translation/components/Translation";
import SortingOptions from "./SortingOptions";
import ClickOutsideAwareComponent from "@Features/clickOutsideAwareComponent/components/ClickOutsideAwareComponent";
import IconChevron from "@Icons/IconChevron";

function Sort(props) {
    const {
        title,
        sortingOptions,
        selectedLabel,
        selected,
        onSelect,
        value,
        label,
        className,
    } = props

    const [sortActive, setSortActive] = useState(false)
    const toggleSort = () => setSortActive(!sortActive)
    const closeSort = () => setSortActive(false)
    const onSelection = (option) => () => {
        onSelect(option)
        toggleSort()
    }

    return (
        <div className={className}>
            {title && (
                <span>
                    <Translation translationKey={title}/>
                </span>
            )}
            <button
                className="gb--row gb--row-middle"
                type="submit"
                onClick={toggleSort}
            >
                {selectedLabel}
                <IconChevron height="16"/>
            </button>

            {sortActive && (
                <ClickOutsideAwareComponent onClickOutside={closeSort}>
                    <SortingOptions
                        onSelection={(option) => onSelection(option)}
                        sortingOptions={sortingOptions}
                        value={value}
                        label={label}
                        selected={selected?.val}
                    />
                </ClickOutsideAwareComponent>
            )}
        </div>
    );
}

Sort.defaultProps = {
    value: "value",
    label: "label",
    className: "sort",
}

Sort.propTypes = {
    className: PropTypes.string,
    sortingOptions: PropTypes.array.isRequired,
    title: PropTypes.string,
    selectedLabel: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
    value: PropTypes.string,
    label: PropTypes.string,
    selected: PropTypes.any.isRequired,
};

export default Sort;