import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconBorderCrossing(props: IconType) {
    const {
        width = '35',
        height = '35',
        viewBox = '0 0 35 35',
        title = 'IconBorderCrossing',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none" fillRule="evenodd" transform="translate(0 1)">
                    <g fillRule="nonzero" transform="matrix(.9961947 -.08715574 .08715574 .9961947 .856665 2.056988)">
                        <path
                            d="m21.945 28.5285c0 .4389-.2926.7315-.7315.7315 0 0-16.8245 0-19.019 0s-2.1945-2.1945-2.1945-2.1945v-24.871s0-2.1945 2.1945-2.1945h19.019c.4389 0 .7315.2926.7315.7315z"
                            fill="#070c63"
                        />
                        <g fill="#c5c6da">
                            <path
                                d="m11.704 18.2875c-3.6575 0-6.5835-2.926-6.5835-6.5835s2.926-6.5835 6.5835-6.5835 6.5835 2.926 6.5835 6.5835-2.926 6.5835-6.5835 6.5835zm0-11.704c-2.85285 0-5.1205 2.26765-5.1205 5.1205s2.26765 5.1205 5.1205 5.1205 5.1205-2.26765 5.1205-5.1205-2.26765-5.1205-5.1205-5.1205z"
                            />
                            <path
                                d="m11.704 18.2875c-2.12135 0-3.6575-2.85285-3.6575-6.5835s1.53615-6.5835 3.6575-6.5835 3.6575 2.85285 3.6575 6.5835-1.53615 6.5835-3.6575 6.5835zm0-11.704c-1.0241 0-2.1945 2.1945-2.1945 5.1205s1.1704 5.1205 2.1945 5.1205 2.1945-2.1945 2.1945-5.1205-1.1704-5.1205-2.1945-5.1205z"
                            />
                            <path d="m10.9725 5.1205h1.463v12.4355h-1.463z"/>
                            <path d="m5.852 10.9725h11.704v1.463h-11.704z"/>
                            <path
                                d="m18.2875 24.1395c0 .4389-.2926.7315-.7315.7315h-11.704c-.4389 0-.7315-.2926-.7315-.7315v-1.463c0-.4389.2926-.7315.7315-.7315h11.704c.4389 0 .7315.2926.7315.7315z"
                            />
                        </g>
                        <path
                            d="m2.1945 0s-2.1945 0-2.1945 2.1945v24.871c0 2.1945 2.1945 2.1945 2.1945 2.1945z"
                            fill="#7679a9"
                            fillOpacity=".77"
                        />
                    </g>
                    <g transform="translate(12.351665 16.72)">
                        <path
                            d="m21.945 13.585c0 1.15368-.9357975 2.09-2.09 2.09h-17.765c-1.1542025 0-2.09-.93632-2.09-2.09v-11.495c0-1.154725.9357975-2.09 2.09-2.09h17.765c1.1542025 0 2.09.935275 2.09 2.09z"
                            fill="#4a79e2"
                            fillRule="nonzero"
                        />
                        <g transform="translate(2.09 2.35125)">
                            <path
                                d="m9.405 8.151c0 .692208-.40105607 1.254-.89571429 1.254h-7.61357142c-.49465822 0-.89571429-.561792-.89571429-1.254v-6.897c0-.692835.40105607-1.254.89571429-1.254h7.61357142c.49465822 0 .89571429.561165.89571429 1.254z"
                                fill="#e3f2fd"
                                fillRule="nonzero"
                            />
                            <g transform="translate(.36575 .78375)">
                                <path
                                    d="m1.77939824 1.57928864h5.0578c0 2.22227688-.0467814 3.43758869-.14034419 3.64593542-.51832067 1.28509257-1.54102002 2.62406458-2.38855581 2.62406458-.8475358 0-1.67412391-.77415715-2.42025324-2.62406458-.13048921-.41855973-.1667048-1.63387154-.10864676-3.64593542z"
                                    fill="#efb286"
                                />
                                <g fill="#5e2d02">
                                    <path
                                        d="m5.22789824 2.42474483c1.19425209-.07037837 2.07308344-.87625955 2.63649404-2.41764354-1.34737264.38934794-2.22620399.45361039-2.63649404.19278735-.68593346-.43605067-2.299-.1375572-2.299.6897 0 .82725719.50762185 1.64072375 2.299 1.53515619z"
                                    />
                                    <path
                                        d="m1.81273464 2.97435269c.67680305-.03988462 1.24079986-.64262134 1.69199045-1.80821016-.89839475.64494667-1.4790756.7996285-1.74204255.46404551-.471887-.60219451-1.30288254-.07795601-1.30288254.39086476 0 .46118534.51551791 1.22536267 1.49099143 1.18306498.01615004-.00070029-.15459089-.22879072-.13805679-.22976509z"
                                        transform="matrix(-.51503807 .8571673 -.8571673 -.51503807 4.876948 1.6127)"
                                    />
                                    <path
                                        d="m6.56554279 3.02652815c.52423144-.03089344.96108654-.49775531 1.3105653-1.40058562-.69586976.49955644-1.14564781.61936837-1.34933416.3594358-.36550959-.46644189-1.00917393-.0603824-1.00917393.30275218 0 .3572204.39930478.94912935 1.15487746.91636682.01250934-.00054242-.11974149-.17721446-.10693467-.17796918z"
                                        transform="matrix(.51503807 .8571673 .8571673 -.51503807 1.176913 -2.080186)"
                                    />
                                </g>
                                <rect fill="#eeb185" height="1" rx=".2299" width="1.8392" x="3.388698" y="6.177289"/>
                                <rect
                                    fill="#eeb185"
                                    height="1"
                                    rx=".2299"
                                    transform="matrix(0 1 -1 0 11.497147 -2.361169)"
                                    width="1.8392"
                                    x="6.009558"
                                    y="4.338089"
                                />
                                <rect
                                    fill="#eeb185"
                                    height="1"
                                    rx=".2299"
                                    transform="matrix(0 1 -1 0 6.209447 2.926531)"
                                    width="1.8392"
                                    x=".721858"
                                    y="4.338089"
                                />
                                <rect fill="#f5d2b8" height="1" rx=".2299" width="1.8392" x="4.538198" y="4.016229"/>
                                <rect fill="#5e2d02" height="1" rx=".2299" width="1.1495" x="4.860058" y="3.694369"/>
                                <rect fill="#9b663b" height="1" rx=".2299" width="1" x="4.860058" y="3.372509"/>
                                <rect fill="#af774b" height="1" rx=".2299" width="1" x="3.963448" y="5.556559"/>
                                <g transform="matrix(-1 0 0 1 4.216338 3.372509)">
                                    <rect fill="#f5d2b8" height="1" rx=".2299" width="1.8392" y=".64372"/>
                                    <rect fill="#5e2d02" height="1" rx=".2299" width="1.1495" x=".32186" y=".32186"/>
                                    <rect fill="#9b663b" height="1" rx=".2299" width="1" x=".32186"/>
                                </g>
                                <path
                                    d="m3.36917712 6.02449946.93912112-.19184897.95308278.19116797c.04635251.00929732.07639167.05441042.06709436.10076292-.00259334.01292929-.00813875.02508462-.01620363.03551772l-.0153468.01985334c-.04353609.05632032-.11070621.0892962-.18189167.0892962h-1.60490446c-.07598002 0-.14705298-.03753994-.18988611-.1002957l-.00604307-.00885384c-.02735228-.04007442-.01703894-.09473455.02303548-.12208683.0096436-.00658211.0205025-.01117588.031942-.01351281z"
                                    fill="#5e2d02"
                                />
                                <rect fill="#5e2d02" height="1" rx=".2299" width="1" x="3.848498" y="6.637089"/>
                            </g>
                        </g>
                        <path
                            d="m19.855 2.6125h-6.7925c-.2885688 0-.5225.23393122-.5225.5225s.2339312.5225.5225.5225h6.7925c.2885688 0 .5225-.23393122.5225-.5225s-.2339312-.5225-.5225-.5225zm0 2.6125h-6.7925c-.2885688 0-.5225.23393122-.5225.5225s.2339312.5225.5225.5225h6.7925c.2885688 0 .5225-.23393122.5225-.5225s-.2339312-.5225-.5225-.5225zm-3.6575 2.6125h-3.135c-.2885688 0-.5225.23393122-.5225.5225s.2339312.5225.5225.5225h3.135c.2885688 0 .5225-.23393122.5225-.5225s-.2339312-.5225-.5225-.5225zm3.6575 5.225h-17.2425c-.28856878 0-.5225.2339312-.5225.5225s.23393122.5225.5225.5225h17.2425c.2885688 0 .5225-.2339312.5225-.5225s-.2339312-.5225-.5225-.5225z"
                            fill="#e3f2fd"
                            fillRule="nonzero"
                        />
                    </g>
                </g>
            )}
        />

    );
}

export default IconBorderCrossing;
