import {generatePath, useNavigate} from "react-router-dom";
import useIsMobile from "@Hooks/selector/useIsMobile";
import TicketType from "@MyTicket/types/Ticket.type";
import {useParams} from "react-router";

export default function useHandleTicketSelected(ticket: TicketType) {
    const navigate = useNavigate()
    const isMobile = useIsMobile()
    const {locale} = useParams()

    const navigateToSingleTicket = () => {
        if (isMobile) {
            navigate(`${ticket.id}`, {state: ticket})
        } else {
            // we're using raw history here as the method above updates the router state
            // and causes re-render which is not wanted on desktop
            history.replaceState(
                history.state,
                "",
                generatePath("/:locale/my-profile/tickets/:ticket", {
                    ticket: `${ticket.id}`,
                    locale,
                })
            )
        }
    }

    return {
        navigateToSingleTicket,
    }
}