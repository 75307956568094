import React from 'react';
import PropTypes from 'prop-types';
import IntermodalTransportType from "@Intermodal/components/IntermodalTransportType";
import RouteTransferType from "@Route/features/transportType/types/RouteTransfer.type";

interface IntermodalTransportTypeIconsProps {
    routeTransferTypeArray: RouteTransferType[]
}

const IntermodalTransportTypeIcons = ({routeTransferTypeArray}: IntermodalTransportTypeIconsProps) => (
    <div className="gb--intermodal-transport-types">
        {routeTransferTypeArray.map((routeTransferType, index) => (
            <IntermodalTransportType
                key={`${routeTransferType.id}-${index}`}
                routeTransferType={routeTransferType.transportTypeId}
            />
        ))}
    </div>
);

IntermodalTransportTypeIcons.propTypes = {
    routeTransferTypeArray: PropTypes.array.isRequired,
};

export default IntermodalTransportTypeIcons;