import React from "react";
import Row from "@Containers/components/Row";
import {useTranslation} from "@Translation/hooks/useTranslation";
import VoucherType from "@MyVoucher/types/Voucher.type";
import CopyVoucherCodeButton from "@MyVouchers/components/CopyVoucherCodeButton";

interface SuccessfulRebookProps {
    voucher: VoucherType,
}

function SuccessfulRebook({voucher}: SuccessfulRebookProps) {
    const voucherCodeLabel = useTranslation('my_profile.my_vouchers.code')
    const successfulRebookLabel = useTranslation('my_profile.my_tickets.booking_modal.successful_rebook')
    return (
        <div className="manage-booking-modal-body successful-rebook-notice">
            <h3>
                {successfulRebookLabel}
            </h3>
            <Row center className="info-field-small successful-rebook-voucher-code">
                <div className="">{`${voucherCodeLabel}:`}</div>
                <p className="gb--emphasize gb--emphasize-default voucher-code">
                    &nbsp;
                    {voucher.code}
                </p>
                <CopyVoucherCodeButton
                    code={voucher.code}
                    className=" copy-voucher-code-button"
                />
            </Row>
        </div>
    );
}

export default SuccessfulRebook