import useForm from "@Form/hooks/useForm";
import usePushNewUrl from "@Routing/hooks/usePushNewUrl";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import formatDate from "@DateTime/functions/formatDate";
import fromUnixTime from "date-fns/fromUnixTime";
import {FormSliceActionType, setDate, setReturnDate} from "@Form/reducers/formSlice";
import useClickOtherAvailableRouteEvent from "@Events/hooks/useClickOtherAvailableRouteEvent";
import UrlParamsType from "@Form/types/UrlParams.type";
import useSingleConfirmedRoute from "@Route/hooks/useSingleConfirmedRoute";
import parseDate from "@DateTime/functions/parseDate";
import {isAfter} from "date-fns";

export default function useSearchSuggestedDate(isReturn: boolean, hasRoutesOnSelectedDate: boolean) {
    const urlParams: UrlParamsType = useForm("urlParams")
    const pushNewUrl = usePushNewUrl()
    const dispatch = useAppDispatch()
    const isSingleRouteConfirmed = useSingleConfirmedRoute()
    const dispatchClickOtherAvailableRouteEvent = useClickOtherAvailableRouteEvent(hasRoutesOnSelectedDate)

    const updateSearch = (handler: FormSliceActionType, date: string, urlParams: UrlParamsType, eventParams: object) => {
        dispatch(handler(date))
        setTimeout(() => {
            pushNewUrl(urlParams)
            dispatchClickOtherAvailableRouteEvent(eventParams)
        }, 50)
    }

    const searchSuggestedDate = (date: number) => {
        const formattedDate = formatDate(fromUnixTime(date))
        if (!isSingleRouteConfirmed) {
            const newUrlParams = {
                ...urlParams,
                date: formattedDate,
                ...(isReturn && isAfter(parseDate(formattedDate), parseDate(urlParams.returnDate))
                        ? {returnDate: formattedDate}
                        : {returnDate: urlParams.returnDate}
                ),
            }
            updateSearch(
                setDate,
                formattedDate,
                newUrlParams,
                {departureDate: formattedDate, isReturn: false}
            )

            return;
        }

        updateSearch(
            setReturnDate,
            formattedDate,
            {...urlParams, returnDate: formattedDate},
            {retDepartureDate: formattedDate, isReturn: true}
        )
    }

    return {
        searchSuggestedDate,
    }
}