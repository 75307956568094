import React from 'react';
import GenericSvgIcon from "../GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconHeadset(props: IconType) {
    const {
        width = '60',
        height = '60',
        viewBox = '0 0 60 60',
        title = 'IconHeadset',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none" transform="translate(2)">
                    <g transform="translate(5.033549)">
                        <path
                            d="m9.58047036 10.9643161c-1.01127187-1.43707057-.74514769-3.67251366.53224834-4.73701036 3.6725137-3.03381562 8.0369502-4.63056068 12.7739605-4.63056068 4.5773358 0 8.8885475 1.49029539 12.5078363 4.41766134 1.2773961 1.01127187 1.5435202 3.24671495.5854732 4.7370103-.7983725 1.2241713-2.2354431 1.5435203-3.2999398.6919229-2.8209163-2.28866792-6.1740809-3.4596143-9.7933697-3.4596143-3.7257385 0-7.1321279 1.22417122-10.006269 3.6192888-1.0644968.8515974-2.5015673.5854732-3.29993984-.638698z"
                            fill="#fff"
                        />
                        <path
                            d="m38.162207 9.31434619c.2128993-1.75641957-.4257987-3.51283914-1.7564196-4.57733584-3.9386378-3.08704045-8.6224233-4.73701035-13.5191082-4.73701035-5.0563594 0-9.8465945 1.70319473-13.78523235 5.00313452-1.33062089 1.06449671-1.91609408 2.87414111-1.70319473 4.57733584-4.68378552 4.89668484-7.39825212 12.45461144-7.39825212 21.82218254 0 .9048222.69192286 1.596745 1.59674506 1.596745s1.59674506-.6919228 1.59674506-1.596745c0-7.8772757 2.07576858-14.3174807 5.80150706-18.7351421.58547318.5322484 1.27739602.8515974 2.02254372.9580471.1596745 0 .319349.0532248.5322484.0532248.8515973 0 1.7031947-.319349 2.3951176-.8515974 2.5547921-2.1822182 5.5886077-3.24671494 8.9417723-3.24671494 3.246715 0 6.2273057 1.01127184 8.7820978 3.08704044.8515974.6919229 1.8628693.9580471 2.8741411.7983725.7451477-.1064496 1.4370706-.4790235 2.0225438-.958047 3.8321881 4.3112117 6.0144064 10.8578664 6.0144064 18.8948166 0 .9048222.6919228 1.596745 1.596745 1.596745s1.5967451-.6919228 1.5967451-1.596745c0-9.5272456-2.7676914-17.138397-7.6111514-22.08830671z"
                            fill="#070c63"
                        />
                        <path
                            d="m34.6493678 9.84659454c-.1596745.26612416-.3725738.42579866-.5854732.42579866-.1596745 0-.2661241 0-.4257987-.1596745-3.0870404-2.44834241-6.8127789-3.72573846-10.7514167-3.72573846-4.0450875 0-7.8772756 1.38384572-11.0175409 3.93863786-.1596745.1064496-.2661242.1596745-.4257987.1596745-.2128993-.0532249-.4257987-.1596745-.5854732-.4257987-.5322483-.74514771-.4257987-2.0757686.2128994-2.60801695 3.4596143-2.82091627 7.3982521-4.25798683 11.7626886-4.25798683 4.2579868 0 8.1433998 1.38384572 11.4965644 4.04508749.7451477.53224835.8515974 1.8096444.319349 2.60801693z"
                            fill="#fff"
                        />
                    </g>
                    <path
                        d="m48.6322924 49.681582c-.9048222 0-1.596745.6919229-1.596745 1.5967451 0 1.1709464-.9580471 2.1289934-2.1289935 2.1289934h-13.0400846c-.638698-1.8628692-2.4483424-3.1934901-4.524111-3.1934901h-2.1289934c-2.6612418 0-4.7902352 2.1289934-4.7902352 4.7902352 0 2.6612417 2.1289934 4.7902352 4.7902352 4.7902352h2.1289934c2.0757686 0 3.8321881-1.3306209 4.524111-3.1934902h13.0400846c2.927366 0 5.3224836-2.3951176 5.3224836-5.3224835 0-.9048222-.6919229-1.5967451-1.5967451-1.5967451z"
                        fill="#070c63"
                    />
                    <path
                        d="m27.3423583 56.6008106h-2.1289934c-.9048222 0-1.5967451-.6919228-1.5967451-1.596745s.6919229-1.5967451 1.5967451-1.5967451h2.1289934c.9048222 0 1.596745.6919229 1.596745 1.5967451s-.6919228 1.596745-1.596745 1.596745z"
                        fill="#fff"
                    />
                    <g fill="#7ed321" stroke="#070c63" strokeWidth="3.25863">
                        <path
                            d="m50.5233565 46.4867056-3.2359101-.5802126c-.4178181-.0749166-.6977821-.4718021-.628189-.8905397l2.4546919-14.7697345c.0702243-.4225352.4696848-.7081397.89222-.6379154.0032456.0005394.0064878.0010995.0097264.0016802l3.2164778.5767283c1.7239199.306583 2.8567816 2.0438864 2.5612525 3.8322869l-1.6254102 9.7595574c-.2955292 1.7884006-1.9209394 3.0147324-3.6448593 2.7081494z"
                        />
                        <path
                            d="m3.98964327 46.4867056-3.23591003-.5802126c-.41781809-.0749166-.69778213-.4718021-.62818901-.8905397l2.45469189-14.7697345c.07022426-.4225352.46968474-.7081397.89221995-.6379154.00324566.0005394.00648785.0010995.00972638.0016802l3.21647786.5767283c1.72391993.306583 2.85678161 2.0438864 2.56125247 3.8322869l-1.62541022 9.7595574c-.29552913 1.7884006-1.92093935 3.0147324-3.64485929 2.7081494z"
                            transform="matrix(-1 0 0 1 9.306742 0)"
                        />
                    </g>
                </g>
            )}
        />

    );
}

export default IconHeadset;
