import IconMinus from "@Icons/IconMinus";
import IconPlus from "@Icons/IconPlus";
import React from "react";
import useIsMobile from "@Hooks/selector/useIsMobile";

interface CountControlProps {
    handleIncrement: () => void,
    incrementClassName: string,
    handleDecrement: () => void,
    decrementClassName: string,
    count: number,
    countClassName?: string
}

/*
 * component used to increment count gradually, eg. "- {count} +". Doesn't have its own wrapper
 */
function CountControl(props: CountControlProps) {
    const {
        handleIncrement,
        incrementClassName,
        handleDecrement,
        decrementClassName,
        count,
        countClassName = "",
    } = props
    const isMobile = useIsMobile()

    return (
        <>
            <button
                className={incrementClassName}
                type="button"
                onClick={handleDecrement}
            >
                <IconMinus
                    width={isMobile ? "40" : "20"}
                />
            </button>
            <span className={countClassName}>
                {count}
            </span>
            <button
                className={decrementClassName}
                type="button"
                onClick={handleIncrement}
            >
                <IconPlus
                    width={isMobile ? "40" : "20"}
                />
            </button>
        </>
    )
}

export default CountControl