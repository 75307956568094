import {RouteTransferTypeValuesType} from "@Route/features/transportType/types/routeTransferType.type";
import TabType from "@Tabs/types/Tab.type";

/*
    Generate empty tab object for provided tabType
 */
export const generateEmptyMainTransportTypeTab = (tabType: RouteTransferTypeValuesType): TabType => (
    {
        tabType,
        routes: 0,
        minPrice: 0,
        fastestRoute: "0",
        availableRoutes: 0,
    }
)