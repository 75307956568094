import {JadrolinijaVehicleModelType} from "@CheckOrder/features/addons/features/jadrolinija/types/JadrolinijaVehicleModel.type";
import filterByKeys from "@Array/filterByKeys";
import {useState} from "react";
import {VehicleAutoSuggestPropsType} from "@CheckOrder/features/addons/features/jadrolinija/features/vehicleModel/types/VehicleAutoSuggestProps.type";

export default function useVehicleAutoSuggest({models, selectedVehicle, setVehicle}: VehicleAutoSuggestPropsType) {
    const [selection, setSelection] = useState(selectedVehicle?.name)

    /**
     * Fetches new suggestions array
     * @param input
     * @returns {Promise<*[]|*>}
     */
    const fetchSuggestions = async (input) => {
        if (input) {
            return filterByKeys(models, input, ['name'])
        }
    }

    const onSuggestionClick = (vehicle: JadrolinijaVehicleModelType) => {
        setSelection(vehicle.name)
        setVehicle(vehicle)
    }

    return ({
        // @ts-ignore
        suggestionsHandler: fetchSuggestions,
        selection,
        suggestionClickHandler: onSuggestionClick,
        updateContext: null,
        updateContextHandler: null,
        clearHandler: null,
        inputHandler: null,
        decorationDisplayHandler: null,
        suggestionLengthFetchLimit: null,
        focusHandler: null,
        selectedVehicle,
    })
}