import React from 'react';
import Translation from "@Translation/components/Translation";
import {useTranslation} from "@Translation/hooks/useTranslation";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import {setSortBy} from "@SearchResults/reducers/filterSlice";
import sortingOptions from "@SearchResults/features/sorting/constants/sortingOptions";
import SortOption from "@SearchResults/features/sorting/components/SortOption";
import SortIcons from "@SearchResults/features/sorting/components/SortIcons";
import Row from "@Containers/components/Row";
import ActiveFiltersCount from "@SearchResults/components/HeadingContent/ActiveFiltersCount";
import useIsDesktop from "@Hooks/selector/useIsDesktop";
import MobileFiltersButton from "@SearchResults/components/HeadingContent/Mobile/MobileFiltersButton";
import useIsMobile from "@Hooks/selector/useIsMobile";
import useSingleTrackingEvent from "@Events/hooks/useSingleTrackingEvent";
import SearchResultsSortingOptionsEventModel from "@Events/models/SearchResultsSortingOptionsEventModel";

function Sorter({hideFilterButton} : {hideFilterButton?: boolean}) {
    const isDesktop = useIsDesktop()
    const sortByLabel = useTranslation("sys.sort_by")
    const selectedSortKey = useAppSelector((state) => state?.filter.sortBy)
    const dispatch = useAppDispatch()
    const isMobile = useIsMobile()
    const onSortingChange = (sortingMethod) => dispatch(setSortBy(sortingMethod))

    const dispatchUseSearchResultsSortingOptions = useSingleTrackingEvent("use_search_results_sorting_options", SearchResultsSortingOptionsEventModel(selectedSortKey))
    const handleOnBlur: React.FocusEventHandler<HTMLInputElement> = (event) => {
        dispatchUseSearchResultsSortingOptions()
    }

    return (
        <Row justify center>
            <Row className="gb--sorter" center>
                {isDesktop && (
                    <span className="sort-by-label">
                        {`${sortByLabel}: `}
                    </span>
                )}
                {sortingOptions.map((tag) => (
                    <SortOption
                        key={tag.label}
                        onClick={onSortingChange}
                        value={tag.value}
                        label={<Translation translationKey={tag.label}/>}
                        icon={<SortIcons tag={tag.value}/>}
                        active={tag.value === selectedSortKey}
                        onBlur={handleOnBlur}
                    />
                ))}
            </Row>
            {!hideFilterButton && (
                <>
                    {isMobile ? (
                        <MobileFiltersButton/>
                    ) : (
                        <ActiveFiltersCount/>
                    )}
                </>
            )}
        </Row>
    )
}

export default Sorter;