import {setAwaitingUserResponse, setUser} from "@MyProfile/reducers/userProfileSlice";
import {checkEmailValidation, setCheckOrderDataFromUser, setEmail} from "@CheckOrder/reducers/checkOrderSlice";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import {useLazyGetCurrentUserQuery} from "@MyProfile/API/myProfileApi";

export default function useGetCurrentUser() {
    const existingUser = useAppSelector((state) => state?.userProfile.user)
    const dispatch = useAppDispatch()
    const awaitingUserResponse = (response) => dispatch(setAwaitingUserResponse(response))
    const [getUser] = useLazyGetCurrentUserQuery()

    async function setCurrentUser() {
        const response = await getUser(null).unwrap()
        const {user} = response || {}

        if (user) {
            dispatch(setUser({...existingUser, ...user}))
            dispatch(setCheckOrderDataFromUser(user))
            dispatch(setEmail(user?.email))
            dispatch(checkEmailValidation(true))
        }
        awaitingUserResponse(false)
    }

    return setCurrentUser
}