import React from 'react';
import {useTranslation} from "@Translation/hooks/useTranslation";
import Link from "@Generic/Link";

function GbbTermsAndConditions() {
    const readAndAgreedToLabel = useTranslation("t_summary.read_and_agreed_to_the")
    const privacyPolicyLabel = useTranslation('menu.footer.privacy_policy')
    const generalTermsLabel = useTranslation('menu.footer.general_terms')
    const andLabel = useTranslation('sys.and')
    const privacyPolicyUrl = "privacy-policy"
    const generalTermsUrl = "general-terms"

    return (
        <span>
            {`${readAndAgreedToLabel} `}
            <Link opensInNewWindow href={`//${window.location.host}/en/${generalTermsUrl}`} className="tos-link">
                {generalTermsLabel}
            </Link>
            {` ${andLabel} `}
            <Link href={`//${window.location.host}/en/${privacyPolicyUrl}`} className="tos-link">
                {privacyPolicyLabel}
            </Link>
        </span>
    );
}

export default GbbTermsAndConditions;