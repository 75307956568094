import {useState} from "react";
import ajaxHandler from "../../utils/ajax/v2/ajaxHandler";
import useOnMount from "../lifecycle/useOnMount";

export default function useSidebarData() {
    const {
        latestTickets = [],
        latestBlogPages = [],
        latestBlogPosts = [],
        latestReviews = [],
    } = window?.sidebarWidgetData || {}

    const initialData = {
        latestTickets,
        latestBlogPages,
        latestBlogPosts,
        latestReviews,
    }

    const [sidebarData, setSidebarData] = useState(initialData)
    const fetchData = async () => {
        const {data} = await ajaxHandler({url: "/ajax/get-widget-data"})
        setSidebarData(data)
    }

    const fetchDataIfNoneExists = async () => {
        if (!window.sidebarWidgetData) {
            await fetchData()
        }
    }

    useOnMount(fetchDataIfNoneExists)

    return sidebarData
}