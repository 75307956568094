import IntermodalRouteWithDetailsType from "@Intermodal/types/IntermodalRouteWithDetails.type";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";
import {isIntermodalRoute} from "@SearchResults/functions/isIntermodalRoute";

/**
 * Sorts routes based on rating
 * @return {function(a, b): number}
 */

type sortRoutesFunction = (
    a: RouteWithAddedPropsAndPricesType | IntermodalRouteWithDetailsType,
    b: RouteWithAddedPropsAndPricesType | IntermodalRouteWithDetailsType
) => number

export function sortByRating(): sortRoutesFunction {
    return function sort(a, b) {
        const firstRouteRating = !isIntermodalRoute(a) ? a.ratings.rating : null;
        const secondRouteRating = !isIntermodalRoute(b) ? b.ratings.rating : null;

        let comparison = 0;
        if (firstRouteRating < secondRouteRating) {
            comparison = 1;
        } else if (firstRouteRating > secondRouteRating) {
            comparison = -1;
        }

        return comparison
    }
}