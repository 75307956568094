import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconFlexCancellation(props: IconType) {
    const {
        width = '26',
        height = '16',
        viewBox = '0 0 26 16',
        title = 'IconFlexCancellation',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none" fillRule="evenodd" transform="translate(.5 1)">
                    <path
                        d="m6.13362218 4.60989169 15.95579002-4.533328c1.0207-.290004 2.0903.267618 2.4369 1.270518l.1102.3188c.0398.11534-.0097.24296-.1128.30827-.5048.31975-1.856 1.33544-1.4183 2.81903"
                        fill="#f5a623"
                        fillRule="nonzero"
                    />
                    <path
                        d="m6.13362218 4.60989169 15.95579002-4.533328c1.0207-.290004 2.0903.267618 2.4369 1.270518l.1102.3188c.0398.11534-.0097.24296-.1128.30827-.5048.31975-1.856 1.33544-1.4183 2.81903"
                        stroke="#fff"
                    />
                    <g fillRule="nonzero">
                        <path
                            d="m5.92215218 11.8210917c0 1.3807 1.11929 2.5098 2.49996 2.5098h13.98560002c1.3833 0 2.5036-1.1232 2.5000086-2.5065v-.0479c-.0008086-.2614-.2063086-.482-.4459086-.5866-.4286-.187-1.0172-.6529-1.0508-1.80440001-.0353-1.21182.638-1.72845 1.0929-1.93857.2227-.10288.4025-.31416.4024-.55949v-.0746c-.0002-1.38055-1.1194-2.49957-2.5-2.49957h-13.99560002c-1.38123 0-2.50074 1.12399-2.4999604 2.50525 0 .27371.2259704.50267.4778804.60972.43931.18669 1.02292.67584 1.0224 1.95726-.00051 1.19630001-.56342 1.65020001-.99685 1.82240001-.25881.1028-.49203.3347-.49203.6132z"
                            fill="#4a79e2"
                            stroke="#fff"
                        />
                        <path
                            d="m10.6400122 8.72539169c-.008-.24.02-.462.084-.666.064-.208.16-.388.288-.54.128-.156.288-.276.48-.36.196-.088.42-.132.672-.132.192 0 .374.03.546.09.176.06.33.146.462.258s.236.25.312.414c.08.164.12.348.12.552 0 .212-.034.394-.102.546s-.158.288-.27.408c-.112.116-.24.222-.384.318-.14.096-.282.192-.426.288-.144.092-.284.19200001-.42.30000001s-.256.234-.36.378h1.986v.732h-3.084c0-.244.034-.456.102-.636.072-.18.168-.34.288-.48.12-.144.26-.27600001.42-.39600001.164-.12.336-.242.516-.366.092-.064.19-.128.294-.192.104-.068.198-.142.282-.222.088-.08.16-.17.216-.27.06-.1.09-.214.09-.342 0-.204-.06-.362-.18-.474-.116-.116-.266-.174-.45-.174-.124 0-.23.03-.318.09-.084.056-.152.132-.204.228-.052.092-.09.196-.114.312-.02.112-.03.224-.03.336zm4.984-.546h-.018l-1.086 1.458h1.104zm0 2.16000001h-1.776v-.78000001l1.824-2.448h.762v2.526h.558v.70200001h-.558v.972h-.81zm1.786-3.31200001h.852v1.614h.018c.108-.18.246-.31.414-.39.168-.084.332-.126.492-.126.228 0 .414.032.558.096.148.06.264.146.348.258.084.108.142.242.174.402.036.156.054.33.054.522v1.90800001h-.852v-1.75200001c0-.256-.04-.446-.12-.57-.08-.128-.222-.192-.426-.192-.232 0-.4.07-.504.21-.104.136-.156.362-.156.678v1.62600001h-.852z"
                            fill="#fff"
                        />
                        <path
                            d="m.32214218 2.11975169c-.13431.05023-.13982.23816-.00869.29618l1.21403.53715c.11132.04926.12874.20075.03529.27877-1.46273 1.22126-1.58891 2.55894-1.55922 3.94682.04117 1.93813 1.00342 3.67642001 1.5039 4.43222001.08577.1295.19952.0712.16367-.08-.30224-1.2742-.85177-4.80998001 1.61652-7.09432001.06774-.06269.17538-.05294.23155.02029l.97014 1.26464c.08703.11345.26783.06447.28569-.0774l.65268-5.18356c.01503-.11936-.10211-.211993-.21479-.169851z"
                            fill="#7ed321"
                        />
                    </g>
                </g>
            )}
        />

    );
}

export default IconFlexCancellation;
