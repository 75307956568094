import React from 'react';
import Notification from "@Features/notification/components/Notification";
import IconExclamationCircle from "@Icons/IconExclamationCircle";
import {useTranslation} from "@Translation/hooks/useTranslation";
import StationToStationNotificationsType from "@SearchResults/types/params/StationToStationNotifications.type";
import CompanyType from "@SearchResults/types/company/Company.type";

interface StationToStationNotificationProps {
    notification: StationToStationNotificationsType,
    companies: Record<string, CompanyType>,
}

function StationToStationNotification({notification, companies}: StationToStationNotificationProps) {
    const {
        notification: notificationText,
        headline,
        company,
        dateValidFrom,
        dateValidTo,
    } = notification || {}

    const fromToLabel = useTranslation("search_results.notification_from_to", {
        date1: dateValidFrom,
        date2: dateValidTo,
    })
    const fromLabel = useTranslation("search_results.notification_from", {
        date: dateValidFrom,
    })
    const toLabel = useTranslation("search_results.notification_to", {
        date: dateValidTo,
    })

    let notificationHeadlineBottom = '';
    // If notification is linked to company
    // display it only if company is in companies object
    // TODO: companies should not be an object but an array of objects!
    const shouldDisplay = company?.id
        ? !!companies?.[company?.id]
        : true

    if (!shouldDisplay) {
        return null
    }

    // TODO: make sure to update this sts model with new JSON cleanup,
    //  we should NEVER return object & array as same key. send NULL instead
    if (company?.name && company?.letterCode) {
        const {name, letterCode} = company
        notificationHeadlineBottom += ` | ${name} - ${letterCode}`;
    }

    if (dateValidFrom && dateValidTo) {
        notificationHeadlineBottom += ` (${fromToLabel})`;
    } else if (dateValidFrom && !dateValidTo) {
        notificationHeadlineBottom += ` (${fromLabel})`;
    } else if (!dateValidFrom && dateValidTo) {
        notificationHeadlineBottom += ` (${toLabel})`;
    }

    return (
        <Notification
            notificationIcon={<IconExclamationCircle width="18" height="18" fill="#4a79e2"/>}
            isRow
        >
            <div>
                <h4>
                    {headline}
                    {/* eslint-disable-next-line react/no-danger */}
                    <span dangerouslySetInnerHTML={{__html: notificationHeadlineBottom}}/>
                </h4>
                {notificationText}
            </div>
        </Notification>
    );
}

export default StationToStationNotification;