import {loadStripe, StripeElementsOptions} from "@stripe/stripe-js";

export default function useCheckOrderStripe(stripePublicApiKey, stripeClientSecret, setLocal) {
    // Stripe beta reference: blocked_card_brands_beta_1 => https://stripe.com/docs/payments/customize-payment-element#filter-card-brands
    const stripePromise = stripePublicApiKey ? loadStripe(stripePublicApiKey, {
        betas: ['blocked_card_brands_beta_1'],
    }) : null;

    const options: StripeElementsOptions = {
        clientSecret: stripeClientSecret,
        locale: setLocal,
        appearance: {
            theme: 'stripe',
            variables: {
                colorPrimary: '#4a79e2',
                colorText: '#4a4a4a',
                colorSuccess: '#7ed321',
                colorWarning: '#f5a623',
                colorDanger: '#f03a47',
                borderRadius: '4px',
                fontFamily: 'fsjoey-medium, sans-serif',
                fontSizeBase: '16px',
            },
            rules: {
                '.Input--empty': {
                    border: '1px solid #dde3e8',
                    boxShadow: 'none',
                },
                '.Input:focus': {
                    border: '1px solid var(--colorPrimary)',
                    boxShadow: '0 0 7px #e4ebfb',
                },
                '.AccordionItem': {
                    backgroundColor: '#fafbfc',
                    borderBottom: '1px solid #ddd',
                },
                '.AccordionItem--selected': {
                    color: 'var(--colorPrimary)',
                    border: '1px solid #fff',
                    boxShadow: 'none',
                },
            },
        },
    };

    return {
        stripePromise,
        options,
    }
}