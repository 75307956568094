import React from 'react';
import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "@/types/Icon.type";

const IconCopy = (props: IconType) => {
    const {
        width = '9',
        height = '10',
        fill = '#fff',
        viewBox = '0 0 9 10',
        title = 'IconCopy',
        ...rest
    } = props
    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <path
                    d="m6.89324619 12.2766885v-6.36165582h5.44662311v-.91503268h-5.44662311c-.24691358 0-.45751634.09077705-.63180828.27233115-.17429194.18155411-.26143791.39578795-.26143791.64270153v6.36165582zm6.80827891 2.7233115c.2469135 0 .4611474-.0907771.6427015-.2723312s.2723311-.3957879.2723311-.6427015v-6.36165575c0-.24691358-.090777-.45751634-.2723311-.63180828s-.395788-.26143791-.6427015-.26143791h-4.97821355c-.24691358 0-.46114743.08714597-.64270153.26143791s-.27233115.3848947-.27233115.63180828v6.36165575c0 .2469136.09077705.4611474.27233115.6427015s.39578795.2723312.64270153.2723312zm0-.9150327h-4.97821355v-6.36165575h4.97821355z"
                    fill="#070c63"
                    transform="translate(-6 -5)"
                />
            )}
        />

    );
}

export default IconCopy;
