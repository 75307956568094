import addDays from "date-fns/addDays";

export default function createNewSliderDates(centralDate, daysLimit) {
    const slides: Date[] = [];
    // eslint-disable-next-line no-plusplus
    for (let i = daysLimit.min; i <= daysLimit.max; i++) {
        const dateToShow = addDays(new Date(centralDate), i);
        slides.push(dateToShow);
    }

    return slides
}