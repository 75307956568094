import Column from "@Containers/components/Column";
import React from "react";
import VoucherStatusType from "@MyVoucher/types/VoucherStatus.type";
import VoucherTypeLabel from "@MyVoucher/components/VoucherTypeLabel";

interface VoucherPriceProps {
    price: number,
    status: VoucherStatusType,
}

function VoucherPriceInfo({price, status}: VoucherPriceProps) {
    const {isCancelled} = status

    return (
        <Column alignEnd className="voucher-price-info info-field-very-small">
            <div className={`gb--emphasize-default voucher-price ${isCancelled ? "price-refunded" : ""}`}>
                {`${isCancelled ? "- " : ""}${price}`}
            </div>
            <VoucherTypeLabel status={status}/>
        </Column>
    );
}

export default VoucherPriceInfo