import {useTranslation} from "@Translation/hooks/useTranslation";
import useUser from "@User/hooks/useUser";
import useIsMobile from "@Hooks/selector/useIsMobile";
import useLocale from "@Hooks/selector/useLocale";

/**
 * My profile label & link (prevents some duplication)
 * @returns {{href, label: (string|*)}}
 */
export default function useMyProfileLink() {
    const locale = useLocale()
    const isMobile = useIsMobile()
    const user = useUser()
    let myTicketsLinkText = '';
    const myTickets = useTranslation("sys.my_tickets")

    if (user !== null) {
        myTicketsLinkText = `${myTickets} - ${user?.realName || user?.email}`;
    }
    const href = `/${locale}/my-profile/tickets`
    const label = !isMobile ? myTicketsLinkText : user?.realName || user?.email

    return {
        href,
        label,
    }
}