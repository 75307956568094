import Notification from "@Features/notification/components/Notification";
import IconExclamationCircle from "@Icons/IconExclamationCircle";
import React from "react";

interface ErrorNotificationsProps {
    errors: string[]
}

function ErrorNotifications({errors}: ErrorNotificationsProps) {
    return (
        <>
            <br/>
            {errors.map((error) => (
                <Notification
                    key={error}
                    notificationIcon={<IconExclamationCircle fill="#f03a47"/>}
                    notificationType="red"
                >
                    {error}
                </Notification>
            ))}
        </>
    )
}

export default ErrorNotifications