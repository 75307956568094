import stripTags from "@Form/functions/stripTags";

export default class StationModel {
    station: {
        slug: string;
        id: number | null;
        countryCode: string;
        countryBusRentPaxCountMin: number | null;
        label: string;
    }

    /**
     * Url params object
     * @param props {object}
     * @param props.payload {object} - updated state
     */
    constructor(props) {
        const {
            value = null,
            label = '',
            country_short_code: countryCode = null,
            countryBusRentPaxCountMin = null,
            slug = null,
        } = props.payload || {}

        this.station = {
            slug,
            id: value ? parseInt(value, 10) : null,
            countryCode,
            countryBusRentPaxCountMin,
            label: stripTags(label) ?? '',
        }
    }
}