import useIsCurrentRouteSingle from "@Route/hooks/useIsCurrentRouteSingle";
import ParamsType from "@SearchResults/types/params/Params.type";
import {useAppSelector} from "@Hooks/selector/useAppSelector";

/**
 * Returns search results params if the page is loaded,
 * otherwise returns form data while page is being loaded
 */
export default function useActiveParams(params: ParamsType, isLoading: boolean) {
    const {
        departureStation: paramsDepartureStation,
        destinationStation: paramsDestinationStation,
        date: paramsDate,
        returnDate: searchReturnDate,
    } = params

    const {
        urlParams,
        departureStationObject: formDepartureStation,
        destinationStationObject: formDestinationStation,
    } = useAppSelector((state) => state.form)

    const isCurrentRouteSingle = useIsCurrentRouteSingle()
    const departureStationName = isLoading ? formDepartureStation?.label : paramsDepartureStation.name
    const destinationStationName = isLoading ? formDestinationStation?.label : paramsDestinationStation.name
    const returnDate = (isLoading ? urlParams.returnDate : searchReturnDate) ?? searchReturnDate
    const date = (isLoading ? urlParams.date : paramsDate) ?? paramsDate
    const departureName = isCurrentRouteSingle ? departureStationName : destinationStationName
    const destinationName = isCurrentRouteSingle ? destinationStationName : departureStationName
    const departureDate: string = isCurrentRouteSingle ? date : returnDate

    return {
        departureName,
        destinationName,
        departureDate,
    }
}