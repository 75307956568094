import {useAppSelector} from "@Hooks/selector/useAppSelector";
import PassengerAdditionalFields from "@CheckOrder/features/TicketAdditionalInfo/components/PassengerAdditionalFields";
import React from "react";
import Grid from "@Containers/components/Grid";
import {
    TicketAdditionalFieldsPerDirectionType
} from "@CheckOrder/features/TicketAdditionalInfo/types/TicketAdditionalFieldsPerDirection.type";
import {isEmpty} from "@Array/isEmpty";

type AdditionalTicketInfoProps = {
    additionalTicketFields: TicketAdditionalFieldsPerDirectionType
}

function AdditionalTicketInfo({additionalTicketFields}: AdditionalTicketInfoProps) {
    const {
        userConfirmed,
    } = useAppSelector((state) => state?.checkOrder)

    return (
        <div className="gb--card">
            {!isEmpty(additionalTicketFields.singleAdditionalFields.additionalTicketFields) && (
                <>
                    <span className="gb--card-title">
                        Transport details
                    </span>
                    <Grid>
                        <PassengerAdditionalFields
                            updateAllPassengers
                            additionalInfoFields={additionalTicketFields.singleAdditionalFields.additionalTicketFields}
                            userConfirmed={userConfirmed}
                            index={0}
                        />
                    </Grid>
                </>
            )}
            {!isEmpty(additionalTicketFields.returnAdditionalFields.additionalTicketFields) && (
                <>
                    <span className="gb--card-title">
                        Return Transport details
                    </span>
                    <Grid>
                        <PassengerAdditionalFields
                            updateAllPassengers
                            additionalInfoFields={additionalTicketFields.returnAdditionalFields.additionalTicketFields}
                            userConfirmed={userConfirmed}
                            index={0}
                            isReturn
                        />
                    </Grid>
                </>
            )}
        </div>
    );
}

export default AdditionalTicketInfo;