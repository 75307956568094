import React from 'react';
import PassengerPicker from "./PassengerPicker";
import useQuantityAwareTranslation from "@Translation/hooks/useQuantityAwareTranslation";
import usePassengers from "@Hooks/selector/usePassengers";
import useTogglePassengerPicker from "../../hooks/useTogglePassengerPicker";
import IconPassengersAdd from "@Icons/IconPassengersAdd";
import Translation from "@Translation/components/Translation";
import {useAppSelector} from "@Hooks/selector/useAppSelector";

function PassengerPickerButton() {
    const passengerPickerActive = useAppSelector((state) => state?.form.passengerPickerActive)
    const {passengers} = usePassengers()
    const label = useQuantityAwareTranslation("sys.passenger", passengers)
    const onClick = useTogglePassengerPicker()

    return (
        <div className="search__select search__select--pax">
            <span className="search__select--label gb--emphasize gb--emphasize-default">
                <Translation translationKey={'sys.passengers'}/>
            </span>
            <IconPassengersAdd className="search__select--icon"/>
            <input
                type="text"
                name="passengers"
                className="gb--input-standalone"
                placeholder={label}
                value={`${passengers} ${label}`}
                onClick={onClick}
                readOnly
            />
            {passengerPickerActive && (
                <PassengerPicker/>
            )}
        </div>
    );
}

PassengerPickerButton.propTypes = {};

export default PassengerPickerButton;