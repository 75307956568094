import React, {useState} from "react";
import {PagesType} from "@/types/pageSlice/Pages.type";
import UrlObjectType from "@/types/pageSlice/UrlObject.type";
import NavigationContextAwareNavLink from "@Routing/components/NavigationContextAwareNavLink";

interface MenuItemProps {
    entry: [PagesType, UrlObjectType],
    currentRoute: string,
    visible?: boolean,
}

function MenuItem({entry, currentRoute, visible = false}: MenuItemProps) {
    const [key, urlObject] = entry
    const visibleClassName = visible ? "gb--menu" : "select"
    // TODO: at some point create full clientside routing
    return (
        <li
            key={key}
            className={
                urlObject.routeName === currentRoute
                    ? `${visibleClassName}__item ${visibleClassName}__item--selected`
                    : `${visibleClassName}__item`
            }
        >
            <NavigationContextAwareNavLink
                routeName={urlObject.routeName}
                to={urlObject.url}
            >
                {urlObject.text}
            </NavigationContextAwareNavLink>
        </li>
    )
}

export default MenuItem