import React from 'react'
import SourcedImage from "@Images/components/SourcedImage";
import Column from "@Containers/components/Column";

interface CarouselImageProps {
    imagePath: string,
    imagePosition: number,
    centralPosition: number
}

const CarouselImage = ({imagePath, imagePosition, centralPosition}: CarouselImageProps) => {
    if (imagePosition === centralPosition) {
        return (
            <div className="image-carousel-item">
                <SourcedImage
                    filePath={imagePath}
                    fileType="png"
                />
            </div>
        )
    }
    return (
        <Column
            alignEnd={imagePosition > centralPosition}
            alignStart={imagePosition < centralPosition}
            className="image-carousel-item-container"
        >
            <div className="image-carousel-item-side">
                <SourcedImage
                    filePath={imagePath}
                    fileType="png"
                />
            </div>
        </Column>
    )
}

export default CarouselImage