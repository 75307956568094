import {ReactNode} from "react";
import Row from "@Containers/components/Row";

type FilterButtonProps = {
    icon?: ReactNode
    label: string
    suffix?: string
    active: boolean,
    onClick: () => void
    onBlur?: (event) => void
}

function FilterButton({icon, suffix, label, active, onClick, onBlur}: FilterButtonProps) {
    const className = active ? 'gb--filter-button-active' : '';
    return (
        <button
            type="button"
            className={`gb--row gb--row-middle gb--row-justify gb--filter-button ${className}`}
            onClick={onClick}
            onBlur={onBlur}
        >
            <Row center>
                {icon}
                {label}
            </Row>
            {suffix && (
                <span className="gb--filter-button-suffix">
                    {suffix}
                </span>
            )}
        </button>
    );
}

export default FilterButton;