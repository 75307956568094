import React from 'react';
import {useTranslation} from "@Translation/hooks/useTranslation";
import Row from "@Containers/components/Row";
import useFixedEurConversion from "@Currency/hooks/useFixedEurConversion";

interface OnePassengerTypeProps {
    price: string,
    hasDiscount: boolean,
    passengerGroupName: string,
    returnPassengerGroupName: string | undefined,
    numberOfPassengersPerGroup: number,
}

function OnePassengerType(props: OnePassengerTypeProps) {
    const {
        price,
        hasDiscount,
        numberOfPassengersPerGroup,
        passengerGroupName,
        returnPassengerGroupName,
    } = props

    const webDiscountLabel = useTranslation("search_results.web_discount")
    const {shouldDisplayPriceInKn, priceInKn} = useFixedEurConversion(
        price,
        true,
        false
    )
    const groupName = returnPassengerGroupName
        ? `${passengerGroupName} / ${returnPassengerGroupName}`
        : passengerGroupName

    return (
        <Row justify center>
            <span>
                {`${numberOfPassengersPerGroup} x `}
                {groupName}
                {hasDiscount ? ` (${webDiscountLabel})` : ''}
            </span>
            <span>
                {price}
                {shouldDisplayPriceInKn && ` ${priceInKn}`}
            </span>
        </Row>
    )
}

export default OnePassengerType;