import useIsFetchingData from "@SearchResults/api/hooks/useIsFetchingData"
import RouteType from "@Route/types/Route.type"
import IntermodalRouteWithDetailsType from "@Intermodal/types/IntermodalRouteWithDetails.type"
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type"
import SuggestedRouteType from "@Route/types/SuggestedRoute.type"

export default function useIsPassengerCountBeingUpdatedForFlixbusPrice() {
    const {
        params: {pax},
        oldParams: {pax: previousPax},
    } = useIsFetchingData()

    const shouldDisplayPlaceholder = (route: IntermodalRouteWithDetailsType | RouteWithAddedPropsAndPricesType | SuggestedRouteType) => {
        if (route.companyId !== "488") {
            return false
        }

        return pax !== previousPax
    }

    return {
        shouldDisplayPlaceholder,
    }
}