import getCookie from "@Cookies/functions/getCookie";
import {useAppSelector} from "@Hooks/selector/useAppSelector";

/**
 * GBW-1414
 */
export default function useEventTrackingState() {
    const {alwaysDispatchTrackingEvents} = useAppSelector((state) => state?.page)

    return {
        eventTrackingActive: alwaysDispatchTrackingEvents || getCookie("event_cookie"),
        eventsTrackingAlwaysActive: alwaysDispatchTrackingEvents,
    }
}