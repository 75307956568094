import React, {useState} from 'react';
import PortalMap from "@Portals/components/PortalMap";
import FaqCard from "./FaqCard";

function FaqPortal() {
    const [activeIndex, setActiveIndex] = useState(-1);

    return (
        <PortalMap
            className="gb__faq-question"
        >
            <FaqCard activeIndex={activeIndex} setActiveIndex={setActiveIndex}/>
        </PortalMap>
    );
}

export default FaqPortal;