import React from "react";
import AutoSuggest from "@Features/autosuggest/components/AutoSuggest";
import useVehicleAutoSuggest
    from "@CheckOrder/features/addons/features/jadrolinija/features/vehicleModel/hooks/useVehicleAutoSuggest";
import VehicleSuggestion
    from "@CheckOrder/features/addons/features/jadrolinija/features/vehicleModel/components/VehicleSuggestion";
import {
    VehicleAutoSuggestPropsType
} from "@CheckOrder/features/addons/features/jadrolinija/features/vehicleModel/types/VehicleAutoSuggestProps.type";

function VehicleAutoSuggest({models, setVehicle, selectedVehicle}: VehicleAutoSuggestPropsType) {
    const autoSuggestModel = useVehicleAutoSuggest({models, setVehicle, selectedVehicle})

    return (
        <AutoSuggest
            autoSuggestModel={autoSuggestModel}
            suggestionComponent={(
                // @ts-ignore
                <VehicleSuggestion/>
            )}
        />
    );
}

export default VehicleAutoSuggest;