import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconPets(props: IconType) {
    const {
        width = '35',
        height = '35',
        viewBox = '0 0 35 35',
        title = 'IconPets',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none" fillRule="evenodd" transform="translate(2 1)">
                    <path
                        d="m15 15.726328v15.3173495h10.0941482v-26.20000002z"
                        fill="#ac7645"
                        transform="matrix(-1 0 0 1 40.094148 0)"
                    />
                    <path d="m5 15.726328v15.3173495h10.0941482v-26.20000002z" fill="#cb8b52"/>
                    <path
                        d="m15 .04367748 14.3401013 14.88118632c.3832273.3976874.3715051 1.0307439-.0261822 1.4139712-.0149488.0144052-.0303426.0283415-.0461589.0417885l-1.2828697 1.0906922c-.4059216.3451134-1.0111437.311359-1.376172-.0767517l-11.6087185-12.34279086z"
                        fill="#40464c"
                    />
                    <path
                        d="m0 0 14.3401013 14.8811863c.3832273.3976874.3715051 1.0307439-.0261822 1.4139712-.0149488.0144053-.0303426.0283415-.0461589.0417885l-1.2828697 1.0906922c-.4059216.3451134-1.0111437.3113591-1.376172-.0767516l-11.6087185-12.34279095z"
                        fill="#4c545b"
                        transform="matrix(-1 0 0 1 15.078612 0)"
                    />
                    <path
                        d="m16.9495173 14.5689307c0 .2808255.2276541.5084796.5084796.5084796h.4854763c.4962552 0 .8985494-.4022942.8985494-.8985493 0-.0301798-.0015205-.0603405-.0045557-.0903672l-.1034911-1.0238274.1034911-1.0238274c.0499085-.4937391-.3098874-.9344525-.8036265-.9843609-.0300267-.0030352-.0601874-.0045557-.0903672-.0045557h-.5686348c-.2348983 0-.4253211.1904228-.4253211.4253212 0 .2348983-.1904228.4253211-.4253211.4253211h-3.1727282c-.2348984 0-.4253211-.1904228-.4253211-.4253211 0-.2348984-.1904228-.4253212-.4253212-.4253212h-.5055922c-.4590733 0-.8312257.3721525-.8312257.8312258 0 .0295149.001572.0590089.0046923.0883585.0597005.5584573.0895507.9228439.0895507 1.0931597s-.0298502.5347023-.0895507 1.0931597c-.048781.4564742.2817044.8660768.7381768.9148749.0293477.0031373.0588417.0047093.0883566.0047093h.4224337c.2808256 0 .5084797-.2276541.5084797-.5084796 0-.2808256.2276541-.5084797.5084796-.5084797h3.0064112c.2808255 0 .5084796.2276541.5084796.5084797z"
                        fill="#fff"
                    />
                    <path
                        d="m10.0470741 31.036408v-10.855148c.6769978-2.1331026 2.3279731-3.1996538 4.9529259-3.1996538s4.3073108 1.0665512 5.0470741 3.1996538v10.855148z"
                        fill="#4c545b"
                    />
                    <g fill="#d8d8d8" transform="translate(11 26)">
                        <g stroke="#979797" strokeWidth=".1">
                            <g transform="matrix(-1 0 0 1 4.5 2)">
                                <circle cx=".75" cy="1.75" r="1"/>
                                <circle cx="1.75" cy=".75" r="1"/>
                                <circle cx="1.75" cy="1.75" r="1"/>
                            </g>
                            <g transform="matrix(-1 0 0 1 6.5 1)">
                                <circle cx=".75" cy=".75" r="1"/>
                                <circle cx="1.75" cy=".75" r="1"/>
                                <circle cx="1.75" cy="1.75" r="1"/>
                            </g>
                            <circle cx="1.75" cy="2.25" r="1"/>
                            <circle cx="2.75" cy="1.25" r="1"/>
                            <circle cx="3.75" cy="2.25" r="1"/>
                        </g>
                        <circle cx="1.75" cy="3.25" r="1"/>
                        <circle cx="1.75" cy="3.25" r="1" stroke="#979797" strokeWidth=".1"/>
                        <circle cx=".75" cy="3.25" r="1" stroke="#979797" strokeWidth=".1"/>
                        <g transform="matrix(-.8660254 -.5 .5 -.8660254 6.732051 5.732051)">
                            <circle cx="1.75" cy="1.75" r="1" stroke="#979797" strokeWidth=".1"/>
                            <circle cx="2.75" cy=".75" r="1" stroke="#979797" strokeWidth=".1"/>
                            <circle cx="1.75" cy="2.75" r="1"/>
                            <g stroke="#979797" strokeWidth=".1">
                                <circle cx="1.75" cy="2.75" r="1"/>
                                <circle cx=".75" cy="2.75" r="1"/>
                            </g>
                        </g>
                    </g>
                    <path
                        d="m8.96147751 33.0826382h6.04707409c-.0057011-1.3333333-.0085516-2.4473494-.0085516-3.3420482-2.6551692 0-4.3061446-.2193172-4.9529259-.6579518z"
                        fill="#59b294"
                    />
                    <path
                        d="m15 33.0826382h6.0385225c.0057011-1.3037195.0085516-2.4177356.0085516-3.3420482-2.6551693 0-4.3375273-.2193172-5.0470741-.6579518z"
                        fill="#2f8a6b"
                        transform="matrix(-1 0 0 1 36.047074 0)"
                    />
                </g>
            )}
        />

    );
}

export default IconPets;
