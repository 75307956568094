import IconAppleSignIn from "@Icons/IconAppleSignIn";
import Translation from "@Translation/components/Translation";
import React from "react";

const AppleLoginButton = ({onClick, className = "btn btn--apple"}: {onClick?: (e: any) => void, className?: string}) => (
    <button
        onClick={onClick}
        type="button"
        className={className}
        style={{marginBottom: "1rem", boxShadow: "none", borderRadius: "4px"}}
    >
        <IconAppleSignIn
            fill="#fff"
            style={{
                marginRight: "1.2rem",
                verticalAlign: "text-bottom",
            }}
        />
        <small
            className="gb--emphasize"
        >
            <Translation translationKey="user.sign_in_with"/>
            {` Apple`}
        </small>
    </button>
);

export default AppleLoginButton;