import {dismissModals, setErrors, setLoading, setUser} from "@MyProfile/reducers/userProfileSlice";
import {checkPasswordValidation, setCheckOrderDataFromUser} from "@CheckOrder/reducers/checkOrderSlice";
import {dismissAllOverlays} from "@/reducers/mobileSlice";
import ajaxHandler from "../../../utils/ajax/ajaxHandler";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import {useAppSelector} from "@Hooks/selector/useAppSelector";

/**
 * Single hook that handles entire user login process.
 * @param params {object}
 * @return {function}
 */

interface UseLoginAttributes {
    email?: string
    password?: string
}

export default async function useLogin(params: UseLoginAttributes = {}) {
    const dispatch = useAppDispatch()
    const {
        isCheckOrder,
        ticket,
        email,
        password,
        tokens,
    } = useAppSelector((state) => state?.checkOrder)

    const postParams = {
        email: params?.email ?? email,
        password: params?.password ?? password,
        ticket_code: ticket?.ticketCode,
        csrf_token: tokens?.csrfTokenBuyerInfo,
    };

    const newParams = isCheckOrder ? postParams : params

    return async () => {
        dispatch(setLoading(true))
        dispatch(setErrors([]))
        const response = isCheckOrder
            ? await ajaxHandler(`/ajax/login`, "log", newParams, 'post')
            : await ajaxHandler(`/ajax/form-log-in`, "log", newParams, 'post')
        dispatch(setLoading(false))

        if (response?.status === 200) {
            if (response?.data?.user || response?.data?.data?.user) {
                const {user} = isCheckOrder ? response?.data?.data : response?.data
                dispatch(setUser(user))
                dispatch(dismissAllOverlays())
                dispatch(dismissModals())
                dispatch(setCheckOrderDataFromUser(user))
                dispatch(checkPasswordValidation(true))
            }

            if (response?.data?.error) {
                dispatch(setErrors(Object.values(response?.data?.error)))
            }

            // fallback for backend buggy response.
            if (response?.data?.data?.errors) {
                dispatch(setErrors(Object.values(response?.data?.data?.errors)))
            }
        }
    }
}