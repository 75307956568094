import {setPassengerDataUpdating, setTicket} from "@CheckOrder/reducers/checkOrderSlice";
import ajaxHandler from "@Ajax/ajaxHandler";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import {useAppSelector} from "@Hooks/selector/useAppSelector";

/**
 * Updates ticket object from voucher ajax request
 */
export default function useUpdateTicketFromVoucher(url: '/ajax/remove-voucher' | '/ajax/apply-voucher', params) {
    const dispatch = useAppDispatch()
    const {ticket} = useAppSelector((state) => state?.checkOrder)
    return async () => {
        dispatch(setPassengerDataUpdating(true))
        const response = await ajaxHandler(url, "log", params, 'post')
        if (response?.data?.ticket) {
            dispatch(setTicket({
                ...ticket,
                ...response.data.ticket,
            }))
        }
        dispatch(setPassengerDataUpdating(false))
    }
}