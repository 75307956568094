import React from 'react';
import Tooltip from "@Popover/components/Tooltip";
import usePopoverObserver from "@Popover/hooks/usePopoverObserver";
import PricesWithTotalPriceType from "@Route/types/prices/PricesWithTotalPrice.type";
import PricesIntermodalType from "@Route/types/prices/PricesIntermodal.type";
import DiscountDetails from "@Route/components/Price/Discount/DiscountDetails";
import DiscountBadge from "@Route/components/Price/Discount/DiscountBadge";

interface DiscountProps {
    prices: PricesIntermodalType | PricesWithTotalPriceType,
}

function Discount({prices}: DiscountProps) {
    const {
        discounts,
        activeDiscount: {
            isActive,
            discountPercentage,
        },
    } = prices

    const {
        tooltipRef,
        direction,
    } = usePopoverObserver()

    return (
        <Tooltip
            tooltipRef={tooltipRef}
            direction={direction}
            className="route--discount"
            tooltipContent={(
                <DiscountDetails
                    discounts={discounts}
                />
            )}
        >
            <DiscountBadge
                isActive={isActive}
                discountAmount={discountPercentage}
            />
        </Tooltip>
    )
}

export default Discount