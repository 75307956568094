import {useContext, useEffect} from "react";
import {PromiseTokenContext} from "@SearchResults/context/PromiseTokenContext";
import useScrollToTopOnSafari from "@Hooks/effects/useScrollToTopOnSafari";
import {useScrollToTopOnMount} from "@Scroll/hooks/useScrollToTopOnMount";

export default function useAdditionalInfoScrollToTopAndCancelRequests() {
    const {cancelRequest} = useContext(PromiseTokenContext)
    useScrollToTopOnSafari()
    useScrollToTopOnMount()
    useEffect(cancelRequest, [])
}