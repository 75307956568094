import {useTranslation} from "@Translation/hooks/useTranslation";
import FilterButton from "@Generic/FilterButton";
import React from "react";
import IconBolt from "@Icons/IconBolt";
import usePopularFilters from "@SearchResults/features/sidebar/features/popularFilters/hooks/usePopularFilters";
import useDispatchFilterEvents from "@SearchResults/features/sidebar/hooks/useDispatchFilterEvent";

function InstantConfirmationFilter() {
    const label = useTranslation("search_results.filter_by.popular_filters.filters.instant_confirmation")
    const {isActive, setActive} = usePopularFilters("instantConfirmation")

    const handleOnBlur = useDispatchFilterEvents('instantConfirmationFilter', isActive.toString());

    return (
        <FilterButton
            label={label}
            active={isActive}
            icon={<IconBolt width="15" height="15" fill="#fff"/>}
            onClick={setActive}
            onBlur={handleOnBlur}
        />
    );
}

export default InstantConfirmationFilter;