import useSingleTrackingEvent from "@Events/hooks/useSingleTrackingEvent";
import useForm from "@Form/hooks/useForm";

export default function useClickOtherAvailableRouteEvent(hasRoutesOnSelectedDate: boolean) {
    const {date, returnDate, fromId, toId} = useForm("urlParams")
    const from = useForm("departureValue")
    const to = useForm("destinationValue")

    const model = {
        departureDate: date,
        retDepartureDate: returnDate,
        hasRoutesOnSelectedDate,
        from,
        fromId,
        to,
        toId,
    }

    return useSingleTrackingEvent("click_other_available_route", model)
}